import axios from "axios";
const jsonConfig = require("../../Config.json");

const UpdateTestIspublished = async (payload) => {
    let allResponse = {}
    try {
        const getallresponse = await axios.put(
            jsonConfig.apiUrl + "UpdateTestIspublished/" + payload.id, payload.data,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          )
        allResponse = getallresponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default UpdateTestIspublished;