import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './emailConfiguration.css';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from '@material-ui/core/TextField';
import AdminHeader from '../../components/adminHeader/adminHeader';
import { CircularProgress } from "@material-ui/core";
import {
  NotificationContainer, NotificationManager
} from "react-notifications";
import { UserTypeContext } from "../../AppRoute";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  bodyForSupport: {
    padding: '0px 30px!important'
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important"
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: '0px 30px!important'
  }
});


const EmailConfiguration = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const classes = useStyles();
  const jsonConfig = require('../../Config.json');
  const [distrubutionList, setDistrubutionList] = useState([]);
  const [supportList, setSupportList] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [testResultList, setTestResultList] = useState([]);
  const [testDialogOpen, setTestDialogOpen] = useState(false);
  const [resumeReportOpen, setResumeReportOpen] = useState(false);
  const [testModalValue, setTestModalValue] = useState({});
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedbackModalValue, setFeedbackModalValue] = useState({});
  const [demoList, setDemoList] = useState([]);
  const [resumeReportList, setResumeReportList] = useState([]);
  const [demoModalValue, setDemoModalValue] = useState({});
  const [resumeModalValue, setResumeModalValue] = useState({});
  const [disbutionDialogOpen, setDistrubutionDialogOpen] = useState(false);
  const [demoDialogOpen, setDemoDialogOpen] = useState(false);
  const [distributionModalValue, setDistributionModalValue] = useState({});
  const [supportListDialogOpen, setSupportListDialogOpen] = useState(false);
  const [supportListModalValue, setSupportListModalValue] = useState({});
  const [isDataLoaded, setisDataLoaded] = useState(false);


  const supportListEdit = (index) => {
    setSupportListDialogOpen(true);
    setSupportListModalValue(supportList[index])
  }
  const supportListClose = () => {
    setSupportListDialogOpen(false);
    getAllEmailConfiguration();

  }
  const supportListUpdate = async (supportListModal) => {
    const payLoad =
    {
      code: supportListModal.code,
      name: supportListModal.name,
      emails: supportListModal.emails,
      country: supportListModal.country,
      category: supportListModal.category
    }

    try {
      const allEmailConfiguration = await axios.put(
        jsonConfig.apiUrl + "emailconfiguration/" + supportListModal.id,
        payLoad,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
      getAllEmailConfiguration();
      setSupportListDialogOpen(false);
      NotificationManager.success("Support emails updated", "Success")
    } catch (err) {
      console.error(err);
      NotificationManager.error("Support emails Updation Faild", "Error", 2500)

    }
  }

  const onChangeUpdateEmail = (event, emailIndex) => {
    const value = event.target.value;
    const mutatedValue = { ...distributionModalValue }
    mutatedValue.emails[emailIndex] = value;
    setDistributionModalValue(mutatedValue);
  }

  const onChangeUpdateSupportEmail = (event, emailIndex) => {
    const value = event.target.value;
    const mutatedValue = { ...supportListModalValue }
    mutatedValue.emails[emailIndex] = value;
    setSupportListModalValue(mutatedValue);
  }
  const onChangeDemoEmail = (event, emailIndex) => {
    const value = event.target.value;
    const mutatedValue = { ...demoModalValue }
    mutatedValue.emails[emailIndex] = value;
    setDemoModalValue(mutatedValue);
  }
  const onChangeResumeEmail = (event, emailIndex) => {
    const value = event.target.value;
    const mutatedValue = { ...resumeModalValue }
    mutatedValue.emails[emailIndex] = value;
    setResumeModalValue(mutatedValue);
  }
  const onChangeFeedbackMail = (event, emailIndex) => {
    const value = event.target.value;
    const mutatedValue = { ...feedbackModalValue }
    mutatedValue.emails[emailIndex] = value;
    setFeedbackModalValue(mutatedValue);
  }
  const onChangeTestResultMail = (event, emailIndex) => {
    const value = event.target.value;
    const mutatedValue = { ...testModalValue }
    mutatedValue.emails[emailIndex] = value;
    setTestModalValue(mutatedValue);
  }

  const distributionListEdit = (list) => {
    setDistrubutionDialogOpen(true);
    setDistributionModalValue(list);
  }

  const distributionClose = () => {
    setDistrubutionDialogOpen(false);
    getAllEmailConfiguration()
  }
  const demoListEdit = (list) => {
    setDemoDialogOpen(true);
    setDemoModalValue(list);
  }

  const feedbackListEdit = (list) => {
    setFeedbackDialogOpen(true);
    setFeedbackModalValue(list);
  }
  const testResultEdit = (list) => {
    setTestDialogOpen(true);
    setTestModalValue(list)
  }

  const feedbackDialogClose = async () => {
    await getAllEmailConfiguration();
    await setFeedbackDialogOpen(false);
  }
  const testDialogClose = async () => {
    await getAllEmailConfiguration();
    await setTestDialogOpen(false);
  }
  const resumeDialogEdit = (list) => {
    setResumeReportOpen(true);
    setResumeModalValue(list)
  }
  const resumeDialogClose = async () => {
    await getAllEmailConfiguration();
    setResumeReportOpen(false);
  }
  const demoDialogClose = () => {
    setDemoDialogOpen(false);
    getAllEmailConfiguration();
  }
  const testResultDilogUpdate = async (testResultList) => {
    const payLoad =
    {
      code: testResultList.code,
      name: testResultList.name,
      emails: testResultList.emails,
      country: testResultList.country,
      category: testResultList.category
    }

    try {
      const allEmailConfiguration = await axios.put(
        jsonConfig.apiUrl + "emailconfiguration/" + testResultList.id,
        payLoad,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      getAllEmailConfiguration();
      setTestDialogOpen(false);
      NotificationManager.success("Test result emails updated", "Success", 2500)
    } catch (err) {
      console.error(err);
      NotificationManager.error("Test Result Updation Faild", "Error", 2500)
    }
  }
  const resumeReportDilogUpdate = async (resumeReportList) => {
    const payLoad =
    {
      code: resumeReportList.code,
      name: resumeReportList.name,
      emails: resumeReportList.emails,
      country: resumeReportList.country,
      category: resumeReportList.category
    }

    try {
      const allEmailConfiguration = await axios.put(
        jsonConfig.apiUrl + "emailconfiguration/" + resumeReportList.id,
        payLoad,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      getAllEmailConfiguration();
      setResumeReportOpen(false);
      NotificationManager.success("Resume report emails updated", "Success", 2500)

    } catch (err) {
      console.error(err);
      NotificationManager.error("Resume report Updation Faild", "Error", 2500)
    }
  }
  const feedbackDialogUpdate = async (feedbackList) => {
    const payLoad =
    {
      code: feedbackList.code,
      name: feedbackList.name,
      emails: feedbackList.emails,
      country: feedbackList.country,
      category: feedbackList.category
    }

    try {
      const allEmailConfiguration = await axios.put(
        jsonConfig.apiUrl + "emailconfiguration/" + feedbackList.id,
        payLoad,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      getAllEmailConfiguration();
      setFeedbackDialogOpen(false);
      NotificationManager.success("Feedback List Updated", "Success", 2500)
    } catch (err) {
      console.error(err);
      NotificationManager.error("Feedback List Updation Faild", "Error", 2500)
    }
  }
  const demoDialogUpdate = async (demoList) => {
    const payLoad =
    {
      code: demoList.code,
      name: demoList.name,
      emails: demoList.emails,
      country: demoList.country,
      category: demoList.category
    }

    try {
      const allEmailConfiguration = await axios.put(
        jsonConfig.apiUrl + "emailconfiguration/" + demoList.id,
        payLoad,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      getAllEmailConfiguration();
      setDemoDialogOpen(false);
      NotificationManager.success("Request demo emails updated", "Success", 2500)
    } catch (err) {
      console.error(err);
      NotificationManager.error("Request demo emails Updation Faild", "Error", 2500)
    }
  }
  const distributionListUpdate = async (distrubutionList) => {
    const payLoad =
    {
      code: distrubutionList.code,
      name: distrubutionList.name,
      emails: distrubutionList.emails,
      country: distrubutionList.country,
      category: distrubutionList.category
    }

    try {
      const allEmailConfiguration = await axios.put(
        jsonConfig.apiUrl + "emailconfiguration/" + distrubutionList.id,
        payLoad,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
      getAllEmailConfiguration();
      setDistrubutionDialogOpen(false);
      NotificationManager.success("Distribution list emails updated", "Success", 2500)
    } catch (err) {
      console.error(err);
      NotificationManager.error("Distribution list emails Updation Faild", "Error", 2500)
    }
  }
  const removeDistributionEmail = (index) => {
    const removeArr = distributionModalValue.emails.filter((_, indexArr) => indexArr !== index);
    setDistributionModalValue(prevState => ({
      ...prevState,
      emails: removeArr,
    }));
  }
  const removeDemoEmail = (index) => {
    const removeArr = demoModalValue.emails.filter((_, indexArr) => indexArr !== index);
    setDemoModalValue(prevState => ({
      ...prevState,
      emails: removeArr,
    }));
  }
  const removeFeedbackEmail = (index) => {
    const removeArr = feedbackModalValue.emails.filter((_, indexArr) => indexArr !== index);
    setFeedbackModalValue(prevState => ({
      ...prevState,
      emails: removeArr,
    }));
  }
  const removeTestResultEmail = (index) => {
    const removeArr = testModalValue.emails.filter((_, indexArr) => indexArr !== index);
    setTestModalValue(prevState => ({
      ...prevState,
      emails: removeArr,
    }));
  }
  const removeResumeReportEmail = (index) => {
    const removeArr = resumeModalValue.emails.filter((_, indexArr) => indexArr !== index);
    setResumeModalValue(prevState => ({
      ...prevState,
      emails: removeArr,
    }));
  }

  const removeSupportEmail = (index) => {
    const removeArr = supportListModalValue.emails.filter((_, indexArr) => indexArr !== index);
    setSupportListModalValue(prevState => ({
      ...prevState,
      emails: removeArr,
    }));
  }
  const addDemoEmail = () => {
    const currentArr = demoModalValue.emails;
    const mutatedValue = { ...demoModalValue }
    if (mutatedValue.emails[(currentArr.length) - 1] == '') {
      NotificationManager.error("Email field can not be blank", "Error", 2500)
    }
    else {
      mutatedValue.emails[currentArr.length] = '';
      setDemoModalValue(mutatedValue);
    }
  }
  const addResumeEmail = () => {
    const currentArr = resumeModalValue.emails;
    const mutatedValue = { ...resumeModalValue }
    if (mutatedValue.emails[(currentArr.length) - 1] == '') {
      NotificationManager.error("Email field can not be blank", "Error", 2500)

    }
    else {
      mutatedValue.emails[currentArr.length] = '';
      setResumeModalValue(mutatedValue);
    }
  }
  const addFeedbackEmail = () => {
    const currentArr = feedbackModalValue.emails;
    const mutatedValue = { ...feedbackModalValue }
    if (mutatedValue.emails[(currentArr.length) - 1] == '') {
      NotificationManager.error("Email field can not be blank", "Error", 2500)
    }
    else {
      mutatedValue.emails[currentArr.length] = '';
      setFeedbackModalValue(mutatedValue);
    }
  }
  const addTestResultEmail = () => {
    const currentArr = testModalValue.emails;
    const mutatedValue = { ...testModalValue }
    if (mutatedValue.emails[(currentArr.length) - 1] == '') {
      NotificationManager.error("Email field can not be blank", "Error", 2500)
    }
    else {
      mutatedValue.emails[currentArr.length] = '';
      setTestModalValue(mutatedValue);
    }
  }

  const addDistributionEmail = () => {
    const currentArr = distributionModalValue.emails;
    const mutatedValue = { ...distributionModalValue }
    if (mutatedValue.emails[(currentArr.length) - 1] == '') {
      NotificationManager.error("Email field can not be blank", "Error", 2500)
    }
    else {
      mutatedValue.emails[currentArr.length] = '';
      setDistributionModalValue(mutatedValue);
    }
  }

  const addSupportEmail = () => {
    const currentArr = supportListModalValue.emails;
    const mutatedValue = { ...supportListModalValue }
    if (mutatedValue.emails[(currentArr.length) - 1] == '') {
      NotificationManager.error("Email field can not be blank", "Error", 2500)
    }
    else {
      mutatedValue.emails[currentArr.length] = '';
      setSupportListModalValue(mutatedValue);
    }
  }

  const getAllEmailConfiguration = useCallback(async () => {

    try {
      const allEmailConfiguration = await axios.get(
        jsonConfig.apiUrl + "emailconfiguration",
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
      const distributionArr = allEmailConfiguration.data.data.filter(item => item.category === 'Distributionlists');
      const supportArr = allEmailConfiguration.data.data.filter(item => item.category === 'support');
      const demoArr = allEmailConfiguration.data.data.filter(item => item.code === 'CTDemoEmail');
      const feedbackArr = allEmailConfiguration.data.data.filter(item => item.code === 'CTFeedbackEmail');
      const testResultArr = allEmailConfiguration.data.data.filter(item => item.code === 'CTTestResultEmail');
      const resumeReportArr = allEmailConfiguration.data.data.filter(item => item.code === 'CTResumeReportEmail');
      setisDataLoaded(true);
      setTestResultList(testResultArr);
      setFeedbackList(feedbackArr);
      setDemoList(demoArr);
      setDistrubutionList(distributionArr);
      setSupportList(supportArr);
      setResumeReportList(resumeReportArr);

    } catch (err) {
      console.error(err);
      setisDataLoaded(true);
    }

  }, [jsonConfig.apiUrl, jsonConfig.key])


  useEffect(() => {

    getAllEmailConfiguration();

  }, [getAllEmailConfiguration]);

  return (
    <>
      <AdminHeader uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className='section-wrapper'>
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt_100">
            {!isDataLoaded ?
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
              :
              <> {
                userType === "Admin" ? (
                  <>
                    <div className='section-wrapper email-configuration-container'>
                      <section className='email-configuration-wrapper'>
                        <div className='test-result-list'>
                          <h3 className='fs-semi-bold fs-30 f-color mt-10'>Test Result Email</h3>
                          <TableContainer component={Paper} className='distrubution-table'>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  {/* <TableCell align="left">Code</TableCell> */}
                                  {/* <TableCell align="left">Country</TableCell> */}
                                  <TableCell align="left">Emails</TableCell>
                                  <TableCell align="left">Edit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {testResultList && testResultList.map((list, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{list.name}</TableCell>
                                    {/* <TableCell>{list.code}</TableCell> */}
                                    {/* <TableCell align="left">{list.country}</TableCell> */}
                                    <TableCell align="left">{list.emails.map((item, index) => (
                                      <div key={index}>
                                        <span></span>
                                        <span>{item}</span><br />
                                      </div>
                                    ))}</TableCell>

                                    <TableCell align="left" className='cp' onClick={() => testResultEdit(list)}><EditIcon /></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>

                        {/* <div className='feedback-list pt-24'>
                    <h3 className='fs-semi-bold fs-30 f-color mt-10'> Feedback Email</h3>
                    <TableContainer component={Paper} className='distrubution-table'>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="left">Emails</TableCell>
                            <TableCell align="left">Edit</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {feedbackList && feedbackList.map((list, index) => (
                            <TableRow key={index}>
                              <TableCell>{list.name}</TableCell>
                              <TableCell align="left">{list.emails.map((item,index) => (
                                <>
                                <span key={index}></span>
                                <span>{item}</span><br />
                                </>
                              ))}</TableCell>

                              <TableCell align="left" className='cp' onClick={() => feedbackListEdit(list)}><EditIcon /></TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div> */}

                        <div className='demo-list pt-24'>
                          <h3 className='fs-semi-bold fs-30 f-color mt-10'>Request Demo Email</h3>
                          <TableContainer component={Paper} className='distrubution-table'>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  {/* <TableCell align="left">Code</TableCell> */}
                                  {/* <TableCell align="left">Country</TableCell> */}
                                  <TableCell align="left">Emails</TableCell>
                                  <TableCell align="left">Edit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {demoList && demoList.map((list, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{list.name}</TableCell>
                                    {/* <TableCell>{list.code}</TableCell> */}
                                    {/* <TableCell align="left">{list.country}</TableCell> */}
                                    <TableCell align="left">{list.emails.map((item, index) => (
                                      <div key={index}>
                                        <span></span>
                                        <span>{item}</span><br />
                                      </div>
                                    ))}</TableCell>

                                    <TableCell align="left" className='cp' onClick={() => demoListEdit(list)}><EditIcon /></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <div className='demo-list pt-24'>
                          <h3 className='fs-semi-bold fs-30 f-color mt-10'>Resume upload report</h3>
                          <TableContainer component={Paper} className='distrubution-table'>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell align="left">Emails</TableCell>
                                  <TableCell align="left">Edit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                {resumeReportList && resumeReportList.map((list, index) => (
                                  <TableRow key={index}>
                                    <TableCell>CT resume report</TableCell>

                                    <TableCell align="left">{list.emails.map((item, index) => (
                                      <div key={index}>
                                        <span>{item}</span>
                                        <span></span><br />
                                      </div>
                                    ))}</TableCell>

                                    <TableCell align="left" className='cp' onClick={() => resumeDialogEdit(list)}><EditIcon /></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>

                        <div className='support-list pt-24'>
                          <h3 className='fs-semi-bold fs-30 f-color mt-10'> Support Email</h3>
                          <TableContainer component={Paper} className='distrubution-table'>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  {/* <TableCell align="left">Code</TableCell> */}
                                  {/* <TableCell align="left">Country</TableCell> */}
                                  <TableCell align="left">Emails</TableCell>
                                  <TableCell align="left">Edit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {supportList && supportList.map((list, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{list.name}</TableCell>
                                    {/* <TableCell>{list.code}</TableCell> */}
                                    {/* <TableCell align="left">{list.country}</TableCell> */}
                                    <TableCell align="left">{list.emails.map((item, index) => (
                                      <div key={index}>
                                        <span></span>
                                        <span>{item}</span><br />
                                      </div>
                                    ))}</TableCell>

                                    <TableCell align="left" className='cp' onClick={() => supportListEdit(index)}><EditIcon /></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>

                        <div className='distrubution-list pt-24'>
                          <h3 className='fs-semi-bold fs-30 f-color mt-10'> Distribution List</h3>
                          <TableContainer component={Paper} className='distrubution-table'>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  {/* <TableCell align="left">Code</TableCell> */}
                                  <TableCell align="left">Country</TableCell>
                                  <TableCell align="left">Emails</TableCell>
                                  <TableCell align="left">Edit</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {distrubutionList && distrubutionList.map((list, outerIndex) => (
                                  <TableRow key={outerIndex}>
                                    <TableCell>{list.name}</TableCell>
                                    {/* <TableCell>{list.code}</TableCell> */}
                                    <TableCell align="left">{list.country}</TableCell>
                                    <TableCell align="left">{list.emails.map((item, index) => (
                                      <div key={index}>
                                        <span></span>
                                        <span>{item}</span><br />
                                      </div>
                                    ))}</TableCell>

                                    <TableCell align="left" className='cp' onClick={() => distributionListEdit(list)}><EditIcon /></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </section>

                    </div>
                  </>) : userType === "" ? (
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        Loading...
                      </p>
                    </div>
                  )
                  : (
                    <>
                      <div className="row padding_80 text-center">
                        <p className="fs-semi-bold fs-40 f-color">You Are Not Authorized To Visit This Page</p>
                      </div>
                    </>)
              }
              </>
            }
          </div>
        </div>
      </div>
      <>
        {/* test dialog open  */}
        <Dialog
          open={testDialogOpen}
          onClose={testDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title" className={`${classes.title} responsive_title`}>
            Test Result Email
          </DialogTitle>

          <DialogContent className={`${classes.bodyForSupport} responsive_Support`}>

            <div className="dropdown_width_outer mt_12">
              <div className="select customized-select" >
                <legend>Name</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-demo-email"
                  value={testModalValue.name}
                  disabled={true}
                />
              </div>
            </div>
            <legend className='mb_0 mt_16'>Emails</legend>
            {

              testModalValue.emails && testModalValue.emails.map((item, index) => (
                <div key={index}>
                  <span></span>
                  <div className="select customized-select mt_12" >

                    <div className='distribution-email-count-wrapper'>
                      <input
                        type="text"
                        className="text_box_cleartalent"
                        value={item}
                        // onChange={(event) => setDistrubutionEmail(event.target.value)}
                        onChange={(event) => onChangeTestResultMail(event, index)}
                      />
                      {/* <p>{item}</p> */}
                      <span onClick={() => removeTestResultEmail(index)}><CloseIcon /></span>
                    </div>
                  </div>
                </div>
              ))
            }
            <>
              <button className='btn-big btn-skill cp mt_18' onClick={() => addTestResultEmail()}>Add</button>
            </>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <button type="button" className="btn-big btn-skill cp" onClick={() => testDialogClose()}>Cancel</button>
            <button type="button" className="btn-big btn_primary cp" onClick={() => testResultDilogUpdate(testModalValue)}>Submit</button>
          </DialogActions>
        </Dialog>
        {/* resume upload dialog open  */}
        <Dialog
          open={resumeReportOpen}
          onClose={resumeDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title" className={`${classes.title} responsive_title`}>
            Resume upload report
          </DialogTitle>

          <DialogContent className={`${classes.bodyForSupport} responsive_Support`}>

            <div className="dropdown_width_outer mt_12">
              <div className="select customized-select" >
                <legend>Name</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-demo-email"
                  value={resumeModalValue.name}
                  disabled={true}
                />
              </div>
            </div>
            <legend className='mb_0 mt_16'>Emails</legend>
            {

              resumeModalValue.emails && resumeModalValue.emails.map((item, index) => (
                <div key={index}>
                  <span></span>
                  <div className="select customized-select mt_12" >

                    <div className='distribution-email-count-wrapper'>
                      <input
                        type="text"
                        className="text_box_cleartalent"
                        value={item}
                        onChange={(event) => onChangeResumeEmail(event, index)}
                      />
                      <span onClick={() => removeResumeReportEmail(index)}><CloseIcon /></span>
                    </div>
                  </div>
                </div>
              ))
            }
            <>
              <button className='btn-big btn-skill cp mt_18' onClick={() => addResumeEmail()}>Add</button>
              {/* <button className='btn-big btn-skill cp mt_18'>Add</button> */}
            </>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <button type="button" className="btn-big btn-skill cp" onClick={() => resumeDialogClose()}>Cancel</button>
            <button type="button" className="btn-big btn_primary cp" onClick={() => resumeReportDilogUpdate(resumeModalValue)}>Submit</button>
          </DialogActions>
        </Dialog>

        {/* feedback dialog open  */}
        <Dialog
          open={feedbackDialogOpen}
          onClose={feedbackDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title" className={`${classes.title} responsive_title`}>
            Feedback Email
          </DialogTitle>

          <DialogContent className={`${classes.bodyForSupport} responsive_Support`}>

            <div className="dropdown_width_outer mt_12">
              <div className="select customized-select" >
                <legend>Name</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-demo-email"
                  value={feedbackModalValue.name}
                  disabled={true}
                />
              </div>
            </div>
            <legend className='mb_0 mt_16'>Emails</legend>
            {

              feedbackModalValue.emails && feedbackModalValue.emails.map((item, index) => (
                <div key={index}>
                  <span></span>
                  <div className="select customized-select mt_12" >

                    <div className='distribution-email-count-wrapper'>
                      <input
                        type="text"
                        className="text_box_cleartalent"
                        value={item}
                        // onChange={(event) => setDistrubutionEmail(event.target.value)}
                        onChange={(event) => onChangeFeedbackMail(event, index)}
                      />
                      {/* <p>{item}</p> */}
                      <span onClick={() => removeFeedbackEmail(index)}><CloseIcon /></span>
                    </div>
                  </div>
                </div>
              ))
            }
            <>
              <button className='btn-big btn-skill cp mt_18' onClick={() => addFeedbackEmail()}>Add</button>
            </>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <button type="button" className="btn-big btn-skill cp" onClick={() => feedbackDialogClose()}>Cancel</button>
            <button type="button" className="btn-big btn_primary cp" onClick={() => feedbackDialogUpdate(feedbackModalValue)}>Submit</button>
          </DialogActions>
        </Dialog>

        {/* Demo dialog open  */}
        <Dialog
          open={demoDialogOpen}
          onClose={demoDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title" className={`${classes.title} responsive_title`}>
            Request Demo Email
          </DialogTitle>

          <DialogContent className={`${classes.bodyForSupport} responsive_Support`}>

            <div className="dropdown_width_outer mt_12">
              <div className="select customized-select" >
                <legend>Name</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-demo-email"
                  value={demoModalValue.name}
                  disabled={true}
                />
              </div>
            </div>
            <legend className='mb_0 mt_16'>Emails</legend>
            {

              demoModalValue.emails && demoModalValue.emails.map((item, index) => (
                <div key={index}>
                  <span></span>
                  <div className="select customized-select mt_12" >

                    <div className='distribution-email-count-wrapper'>
                      <input
                        type="text"
                        className="text_box_cleartalent"
                        value={item}
                        // onChange={(event) => setDistrubutionEmail(event.target.value)}
                        onChange={(event) => onChangeDemoEmail(event, index)}
                      />
                      {/* <p>{item}</p> */}
                      <span onClick={() => removeDemoEmail(index)}><CloseIcon /></span>
                    </div>
                  </div>
                </div>
              ))
            }
            <>
              <button className='btn-big btn-skill cp mt_18' onClick={() => addDemoEmail()}>Add</button>
            </>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <button type="button" className="btn-big btn-skill cp" onClick={() => demoDialogClose()}>Cancel</button>
            <button type="button" className="btn-big btn_primary cp" onClick={() => demoDialogUpdate(demoModalValue)}>Submit</button>
          </DialogActions>
        </Dialog>

        {/* distribution dialog open  */}
        <Dialog
          open={disbutionDialogOpen}
          onClose={distributionClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title" className={`${classes.title} responsive_title`}>
            Distribution List
          </DialogTitle>

          <DialogContent className={`${classes.bodyForSupport} responsive_Support`}>

            <div className="dropdown_width_outer mt_12">
              <div className="select customized-select dropdown_width" >
                <legend>Name</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-distribution-email"
                  value={distributionModalValue.name}
                  disabled={true}
                />
              </div>
              <div className="select customized-select dropdown_width zipgap" >
                <legend>Country</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-distribution-email"
                  value={distributionModalValue.country}
                  disabled={true}
                />
              </div>
            </div>
            <legend className='mb_0 mt_16'>Emails</legend>
            {

              distributionModalValue.emails && distributionModalValue.emails.map((item, index) => (
                <div key={index}>
                  <span></span>
                  <div className="select customized-select mt_12" >

                    <div className='distribution-email-count-wrapper'>
                      <input
                        type="text"
                        className="text_box_cleartalent"
                        value={item}
                        // onChange={(event) => setDistrubutionEmail(event.target.value)}
                        onChange={(event) => onChangeUpdateEmail(event, index)}
                      />
                      {/* <p>{item}</p> */}
                      <span onClick={() => removeDistributionEmail(index)}><CloseIcon /></span>
                    </div>
                  </div>
                </div>
              ))
            }
            <>
              <button className='btn-big btn-skill cp mt_18' onClick={() => addDistributionEmail()}>Add</button>
            </>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <button type="button" className="btn-big btn-skill cp" onClick={() => distributionClose()}>Cancel</button>
            <button type="button" className="btn-big btn_primary cp" onClick={() => distributionListUpdate(distributionModalValue)}>Submit</button>
          </DialogActions>
        </Dialog>

        {/* support list dialog open */}
        <Dialog
          open={supportListDialogOpen}
          onClose={supportListClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title" className={`${classes.title} responsive_title`}>
            Support Email
          </DialogTitle>

          <DialogContent className={`${classes.bodyForSupport} responsive_Support`}>

            <div className="dropdown_width_outer mt_12">
              <div className="select customized-select" >
                <legend>Name</legend>
                <input
                  type="text"
                  className="text_box_cleartalent custom-text-width-demo-email"
                  value={supportListModalValue.name}
                  disabled={true}
                />
              </div>
              {/* <div className="select customized-select dropdown_width zipgap" >
              <legend>Country</legend>
              <input
                type="text"
                className="text_box_cleartalent w-94"
                value={supportListModalValue.country}
                disabled={true}
              />
            </div> */}
            </div>
            {/* <div className="select customized-select mt_12" >
              
          <legend>Emails</legend>
          <input
            type="text"
            className="text_box_cleartalent "
            value={item}
            // onChange={(event) => setDistrubutionEmail(event.target.value)}
            onChange={(event) => onChangeUpdateEmail(event, index)}
          />
          </div> */}
            <legend className='mb_0 mt_16'>Emails</legend>
            {

              supportListModalValue.emails && supportListModalValue.emails.map((item, index) => (
                <div key={index}>
                  <span></span>
                  <div className="select customized-select mt_12" >

                    <div className='distribution-email-count-wrapper'>
                      <input
                        type="text"
                        className="text_box_cleartalent"
                        value={item}
                        // onChange={(event) => setDistrubutionEmail(event.target.value)}
                        onChange={(event) => onChangeUpdateSupportEmail(event, index)}
                      />
                      <span onClick={() => removeSupportEmail(index)}><CloseIcon /></span>
                    </div>
                  </div>
                </div>
              ))
            }
            <>
              <button className='btn-big btn-skill cp mt_18' onClick={() => addSupportEmail()}>Add</button>
            </>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <button type="button" className="btn-big btn-skill cp" onClick={supportListClose}>Cancel</button>
            <button type="button" className="btn-big btn_primary cp" onClick={() => supportListUpdate(supportListModalValue)}>Submit</button>
          </DialogActions>
        </Dialog>
      </>
      <NotificationContainer />
    </>
  )
}
export default EmailConfiguration
