import {

  useEffect,
  useState,
  createContext
} from "react";
import { Switch } from "react-router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PendingResumeValidationsPage from "./pages/pendingResumeValidations/pendingResumeValidationsPage.js";
import AddQuestion from "./pages/addQuestion/addQuestion";
import SearchRequisitions from "./pages/Searchrequisitions/searchRequisitions.js";
// import Activity from "./pages/myTalentCommunity/activity/activity.js";
import Activity from "./pages/activity/activity.js";
import Home from "./pages/home/home";
import Referral from "./pages//referral/referral"
import ReferralInvite from "./pages//referral/referralInvite"

import Landing from "./pages/landing/landing";

// import PrimarySkill from "./pages/primarySkill/primarySkill";
import Profile from "./pages/profile/profile";

import Search from "./pages/search/search";
import AddPrimarySkill from "./pages/primarySkill/addPrimarySkill";
import Subskill from "./pages/subSkill/subskill";

import AdminProfile from "./pages/adminApplication/AdminApplication";
import TestResult from "./components/testResult/TestResult";
import { TermsAndCondition } from "./components/termsAndCondition/termsAndCondition";
import PrivacyPolicy from "./components/privacyPolicy/privacyPolicy";
import UserList from "./pages/userList/userList";

import RegisteredStartTest from "./components/registeredStartTest/registeredStartTest";
// import { TermsAndCondition } from "./components/termsAndCondition/termsAndCondition";

import EmailConfiguration from "./pages/emailConfiguration/emailConfiguration";
import QuestionReport from "./pages/questionReport/questionReport";
import QuestionsList from "./pages/questionsList/questionsList";
import EditQuestion from "./pages/editQuestion/editQuestion";
import Domain from "./pages/domain/domain";
import EmployerPage from "./pages/home/EmployerPage/EmployerPage";

import AdminLanding from "./pages/adminLanding/adminLanding";
import CandidateSignIn from "./pages/candidateSignIn/candidateSign";

import ProtectedRoute from "./protectedRoute";
import FormOne from "./components/basicInfoForm/FormOne";
import SkillTestConfigaration from "./pages/globalConfiguration/globalConfiguration";
import SharedAdminApplication from "./pages/adminApplication/sharedAdminApplication";
import RecruiterSignUp from "./pages/recruiterSignUp/recruiterSignUp";

import Recruiters from "./pages/recruiters/recruiters";
import AccountSetting from "./pages/myTalentCommunity/accountSetting";
import Invite from "./components/invite/invite";
import RecruiterPrivateSkillAccess from "./pages/recruiterPrivateSkillAccess/recruiterPrivateSkillAccess";
import RecruitmentPartnersAndClients from "./pages/RecruitmentPartnersAndClients/RecruitmentPartnersAndClients";
import Candidate from "./pages/candidate/candidate";
import InviteCandidates from "./pages/InviteCandidates/inviteCandidates";
import AllTests from "./pages/allTests/AllTests";
import DemoCandidate from "./components/createDemoCandidate/demoCandidate";
import jwt_decode from "jwt-decode";
import InviteTestLanding from "./pages/inviteTestLanding/inviteTestLanding";
import PendingInvites from "./pages/myTalentCommunity/pendingInvites";
import MyTalentReferral from "./pages/myTalentCommunity/myTalentReferral";

import { DateTime } from "luxon";
import Error404 from "./pages/errorPage/error404";
import SessionTimeout from "./pages/sessionTimeout/sessionTimeout.js"
import AdminReferral from "./pages/adminReferral/AdminReferral";
import TestLandingPage from "./components/basicInfoForm/TestLandingPage";
import CandidateTestLanding from "./components/profile/testLandingPage/CandidateTestLandingPage";
import axios from "axios";
import AdvanceSearchTalentCommunity from "./pages/myTalentCommunity/advanceSearch/AdvanceSearchTalentCommunity.js";
import OnlyCandidateProfile from "./components/candidateProfile/onlycandidateProfile/candidateprofile.js";
import RequisitionDetails from "./pages/RequisitionDetails/RequisitionDetails.js";
import CreateRequisition from "./pages/createRequisition/createRequisition.js";
import Notification from "./pages/notification/notification.js";
import RequisitionReports from "./components/RequisitionReports/RequisitionReports.js";
import TrackCandidate from "./pages/TrackCandidate/TrackCandidate.js";
import ManageDivision from "./pages/organisationAdmin/manageDivision.js";
import ManageClient from "./pages/organisationAdmin/manageClient.js";
import ManageSpoc from "./pages/organisationAdmin/manageSpoc.js";
import RequisitionDashboard from "./pages/RequisitionDashboard/RequisitionDashboard.js";
import RecruitmentLeaderDash from "./pages/ATS_Dashboard/Dashboard.js";
import Dashboard from "./pages/ATS_Dashboard/Dashboard.js";
import RequisitionReportDetails from "./pages/RequisitionReportDetails/RequisitionReportDetails.js";
import ManageTeam from "./pages/organisationAdmin/manageTeam.js";
export const UserTypeContext = createContext();
const AppRoute = () => {

  const [idContextTest, setIdContextTest] = useState(null);
  const [testInfo, settestInfo] = useState({});
  const token = localStorage.getItem("token");
  const [currSession, setcurrSession] = useState(false)
  const [userType, setuserType] = useState("");
  const [userIdType, setuserIdType] = useState("");
  const [userRecruitmentpartnerId, setuserRecruitmentpartnerId] = useState("");
  const [userRecords, setUserRecords] = useState({})
  const jsonConfig = require("./Config.json");
  useEffect(() => {
    (async () => {
      if (token) {
        pareentCallback(token)
      }
    })();
    return () => {
      setcurrSession(false);
      setIdContextTest(null);
    }
  }, []);

  const getUser = async (id) => {
    try {
      const userResponse = await axios.get(
        jsonConfig.apiUrl + "users/" + id,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
      setuserIdType(userResponse.data.data[0]?.id);
      setuserRecruitmentpartnerId(userResponse.data.data[0]?.recruitmentpartnerId);
      setuserType(userResponse.data.data[0]?.role?.roleName);
      setUserRecords(userResponse?.data?.data[0])
    } catch (error) {
      console.error("%c" + error, "color:red");
    }
  };
  const pareentCallback = async (data, test) => {
    if (data) {
      await tokenExpireVerification(data)
    }
    if (test) {
      settestInfo(test);
    }
  };

  const tokenExpireVerification = async (data) => {
    try {
      const decodedJwt = jwt_decode(data);
      if (decodedJwt) {

        if (decodedJwt.exp < DateTime.now().toUnixInteger()) {
          // alert("okkkkkk")
          setcurrSession(false);
          localStorage.clear();
          window.location.href = "/sessionExpired";
        } else {
          setIdContextTest(decodedJwt.id);
          setcurrSession(true)
          await getUser(decodedJwt.id)
        }
      } else {
        setcurrSession(false);
      }
    } catch (error) {
      console.log("erreeeeeeor", error);
    }
  };


  return (
    <UserTypeContext.Provider value={{ userType, userIdType, userRecruitmentpartnerId, userRecords }}>
      <Router>
        {idContextTest && userType && (
          <Switch>
            <ProtectedRoute
              exact
              path="/landing"
              component={() => <Landing idContextTest={idContextTest} />}
            />
            {/* <ProtectedRoute
            exact
            path="/recruiters"
            component={() => <Recruiters idContextTest={idContextTest} />}
            /> */}
            <ProtectedRoute
              exact
              path="/sign-up-requests"
              component={() => <Recruiters idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              exact
              path="/sign-up-page"
              component={() => <Recruiters idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              exact
              path="/profile"
              component={() => <Profile idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path={"/create-requisition"}
              exact
              component={(props) => <CreateRequisition idContextTest={idContextTest} {...props} />}

            />

            <ProtectedRoute
              path={"/all-notification"}
              exact
              component={() => <Notification id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} />}
            />
            <ProtectedRoute
              path={"/pending-invites"}
              exact
              component={(props) => <PendingInvites {...props} />}
            />
            <ProtectedRoute
              path={"/mytalent-referral"}
              exact
              component={() => <MyTalentReferral />}
            />
            <ProtectedRoute
              path={"/account-settings"}
              exact
              component={() => (
                <AccountSetting idContextTest={idContextTest} />
              )}
            />
            <ProtectedRoute
              path={"/pending-resume-validations"}
              exact={true}
              component={(props) => <PendingResumeValidationsPage id={idContextTest} userRecords={userRecords} userType={userType} userRecruitmentpartnerId={userRecruitmentpartnerId} {...props} />}
            />
            <ProtectedRoute
              path={"/invite-candidates"}
              exact={true}
              component={() => <InviteCandidates idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path={"/invite-candidates/:email"}
              exact={true}
              component={(props) => <InviteCandidates idContextTest={idContextTest} {...props} />}
            />
            <ProtectedRoute
              exact
              path="/admin-home"
              component={() => <AdminLanding idContextTest={idContextTest} />}
            />

            <ProtectedRoute
              // exact={true}
              exact
              path="/users"
              component={() => <UserList idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path="/emailconfiguration"
              component={() => (
                <EmailConfiguration idContextTest={idContextTest} />
              )}
            />
            <ProtectedRoute
              exact
              path="/globalconfiguration"
              component={() => (
                <SkillTestConfigaration idContextTest={idContextTest} />
              )}
            />
            <ProtectedRoute
              path="/search"
              component={() => <Search idContextTest={idContextTest} />}
            />

            <ProtectedRoute
              path="/domain"
              component={() => <Domain idContextTest={idContextTest} />}
            />

            <ProtectedRoute
              path="/skills"
              component={() => <AddPrimarySkill idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path="/recruitment-organizations-customers-and-agencies"
              component={() => (
                <RecruitmentPartnersAndClients idContextTest={idContextTest} />
              )}
            />
            <ProtectedRoute
              path="/admin-referral"
              component={() => (
                <AdminReferral idContextTest={idContextTest} />
              )}
            />
            <ProtectedRoute
              path="/subskill"
              component={() => <Subskill idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              exact
              path="/questions"
              component={() => <QuestionsList idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path="/addquestion"
              component={() => <AddQuestion idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path="/reported-question"
              component={() => <QuestionReport idContextTest={idContextTest} />}
            />
            {/* <ProtectedRoute
              // exact
               path='questions/:id'

                component={(props) => <EditQuestion idContextTest={idContextTest} {...props} />}
               / > */}
            <ProtectedRoute
              path={"/requisition-details/:id"}
              exact
              component={(props) => <RequisitionDetails  {...props} />}
            />
            <ProtectedRoute
              path={"/candidate-history"}
              exact
              component={() => <TrackCandidate id={idContextTest} />}
            />
            <ProtectedRoute
              exact
              path={"/questions/:id"}
              component={(props) => (
                <EditQuestion idContextTest={idContextTest} {...props} />
              )}
            />


            <ProtectedRoute

              path={'/candidate/profile/:id/:recruitmentpartnerId'}
              component={(props) => <AdminProfile idContextTest={idContextTest}  {...props} />
              } />

            <ProtectedRoute
              path={"/client-specific-test-access"}
              component={() => (
                <RecruiterPrivateSkillAccess idContextTest={idContextTest} />
              )}
            />

            <ProtectedRoute
              path="/pending-candidate-invites"
              component={() => <Candidate idContextTest={idContextTest} />}
            />

            <ProtectedRoute
              path="/tests"
              component={() => <AllTests idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path={"/create-demo-candidate"}
              exact={true}
              component={() => <DemoCandidate idContextTest={idContextTest} />}
            />
            <ProtectedRoute
              path={"/invitedtest-landing"}
              exact={true}
              component={() => (
                <InviteTestLanding
                  idContextTest={idContextTest}
                  testInfo={testInfo}
                />
              )}
            />

            <ProtectedRoute
              path={"/candidate-test-landing"}
              exact={true}
              component={(props) => (
                <CandidateTestLanding
                  idContextTest={idContextTest}
                  {...props}
                />
              )}
            />
            <ProtectedRoute
              path={"/candidate-search-talent-community"}
              exact
              component={(props) => <AdvanceSearchTalentCommunity  {...props} />}
            />
            <ProtectedRoute
              path="/candidate-search-talent-community/:requisitionId"
              exact
              component={(props) => {
                let { match } = props;
                const { requisitionId } = match.params; // Retrieve the 'id' from the URL
                return <AdvanceSearchTalentCommunity requisitionIdVal={requisitionId}  {...props} />;
              }}
            />
            <ProtectedRoute
              path="/only-candidate-profile/:id"
              component={(props) => <OnlyCandidateProfile idContextTest={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} {...props} />}
            />
            <ProtectedRoute
              path={'/candidate/profile/:id'}
              component={
                (props) => <AdminProfile idContextTest={idContextTest}  {...props} />
              }
            />
            <ProtectedRoute
              path={'/requisition-reports'}
              component={
                (props) => <RequisitionReports id={idContextTest} userType={userType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords={userRecords} {...props} />
              }
            />
            <ProtectedRoute
              path={"/search-requisitions"}
              exact
              component={(props) => <SearchRequisitions id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} userType={userType} userRecords={userRecords} {...props} />}
            />
            <ProtectedRoute
                path={"/requisition-report-details"} exact component={(props) => <RequisitionReportDetails userType={userType}  {...props} />}
              />
            <ProtectedRoute
              path={"/activity/:id"}
              exact
              component={(props) => <Activity  {...props} />}
            />
            <ProtectedRoute
              path={'/orgManageDivision'}
              component={
                (props) => <ManageDivision {...props} />
              }
            />
            <ProtectedRoute
              path={'/orgManageClient'}
              component={
                (props) => <ManageClient {...props} />
              }
            />
            <ProtectedRoute
              path={'/orgManageSpoc'}
              component={
                (props) => <ManageSpoc {...props} />
              }
            />
            <ProtectedRoute
              path={'/orgManageTeam'}
              component={
                (props) => <ManageTeam  {...props}/>
              }
            />
            <ProtectedRoute
              path={'/dashboard'}
              component={
                (props) => <Dashboard id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} userType={userType} userRecords={userRecords} {...props} />
              }
            />
            <ProtectedRoute
              path={'/requisition-dashboard'}
              component={
                (props) => <RequisitionDashboard id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} userType={userType} userRecords={userRecords} {...props} />
              }
            />
            {/* Adding Public Routes to access it from proctected routes */}
            <Route path={"/signIn"} />
            <Route exact path={"/referral"} />
            <Route exact path={"/recruiter-signup"} />
            <Route exact path={"/basic-info-form"} />
            <Route exact={true} path={"/terms-of-use"} />
            <Route path={"/privacy-policy"} />
            <Route exact={true} path={"/video-skill-test-registered"} />
            <Route exact={true} path={"/demo"} />
            <Route exact={true} path={"/test/:id"} />
            <Route exact={true} path={"/profile/shared/:id"} />
            <Route exact path={"/invite/:id"} component={Invite} />
            <Route exact path={"/referral/:id"} component={ReferralInvite} />
            <Route exact path={"/sessionExpired"} component={SessionTimeout} />
            <Route exact path={"/recruiter-signup"} />
            {localStorage.getItem('token') ? <ProtectedRoute exact path="*" component={() => (
              <Error404
                idContextTest={idContextTest}
                info={'Protected'}
              />
            )} /> : <></>}
          </Switch>
        )}
        <Switch>
          <Route exact path={"/"} component={Home} />
          {/* <Route exact path={"/signIn"} component={CandidateSignIn} /> */}
          <Route
            path={"/signIn"}
            component={() => (
              <CandidateSignIn pareentCallback={pareentCallback} />
            )}
          />
          <Route
            exact
            path={"/referral"}
            component={Referral}
          />
          <Route exact path={"/recruiter-signup"} component={RecruiterSignUp} />
          <Route exact path={"/basic-info-form"} component={FormOne} />
          <Route
            exact={true}
            path={"/terms-of-use"}
            component={TermsAndCondition}
          />
          <Route path={"/privacy-policy"} component={PrivacyPolicy} />
          <Route
            exact={true}
            path={"/video-skill-test-registered"}
            component={RegisteredStartTest}
          />
          <Route
            exact={true}
            path={"/demo"}
            render={(props) => {
              return <EmployerPage {...props} />;
            }}
          />
          <Route
            exact={true}
            path={"/test/:id"}
            render={(props) => {
              return <TestResult {...props} />;
            }}
          />
          {/* <Route
          exact={true}
          path={"/candidate/profile/:id"}
          render={(props) => {
            return <AdminProfile {...props} />;
          }}
          /> */}
          <Route
            exact={true}
            path={"/profile/shared/:id"}
            render={(props) => {
              return <SharedAdminApplication {...props} />;
            }}
          />
          <Route exact path={"/invite/:id"} component={Invite} />
          <Route exact path={"/referral/:id"} component={ReferralInvite} />
          <Route exact path={"/sessionExpired"} component={SessionTimeout} />
          <Route
            exact={true}
            path={"/test-landing"}
            render={(props) => {
              return <TestLandingPage {...props} />;
            }}
          />
          {((currSession === false) && (!localStorage.getItem('token'))) && (
            <Switch>

              <ProtectedRoute
                exact
                path="/landing"
                component={() => <Landing idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                exact
                path="/sign-up-requests"
                component={() => <Recruiters idContextTest={idContextTest} />}
              />
              <ProtectedRoute
              exact
              path="/sign-up-page"
              component={() => <Recruiters idContextTest={idContextTest} />}
            />
              <ProtectedRoute
                exact
                path="/profile"
                component={() => <Profile idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path={"/create-requisition"}
                exact
                component={(props) => <CreateRequisition idContextTest={idContextTest} {...props} />}
              />
              <ProtectedRoute
                path={"/all-notification"}
                exact
                component={() => <Notification id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} />}
              />
              <ProtectedRoute
                path={"/requisition-details/:id"}
                exact
                component={(props) => <RequisitionDetails {...props} />}
              />
              <ProtectedRoute
                path={"/candidate-history"}
                exact
                component={() => <TrackCandidate id={idContextTest} />}
              />
              <ProtectedRoute
                path={"/pending-invites"}
                exact
                component={(props) => <PendingInvites {...props} />}
              />
              <ProtectedRoute
                path={"/mytalent-referral"}
                exact
                component={() => <MyTalentReferral />}
              />
              <ProtectedRoute
                path={"/account-settings"}
                exact
                component={() => (
                  <AccountSetting idContextTest={idContextTest} />
                )}
              />
              <ProtectedRoute
                path={"/invite-candidates"}
                exact={true}
                component={() => <InviteCandidates idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path={"/invite-candidates/:email"}
                exact={true}
                component={(props) => <InviteCandidates idContextTest={idContextTest} {...props} />}
              />
              <ProtectedRoute
                exact
                path="/admin-home"
                component={() => <AdminLanding idContextTest={idContextTest} />}
              />

              <ProtectedRoute
                // exact={true}
                exact
                path="/users"
                component={() => <UserList idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path="/emailconfiguration"
                component={() => (
                  <EmailConfiguration idContextTest={idContextTest} />
                )}
              />
              <ProtectedRoute
                exact
                path="/globalconfiguration"
                component={() => (
                  <SkillTestConfigaration idContextTest={idContextTest} />
                )}
              />
              <ProtectedRoute
                path="/search"
                component={() => <Search idContextTest={idContextTest} />}
              />

              <ProtectedRoute
                path="/domain"
                component={() => <Domain idContextTest={idContextTest} />}
              />

              <ProtectedRoute
                path="/skills"
                component={() => <AddPrimarySkill idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path="/recruitment-organizations-customers-and-agencies"
                component={() => (
                  <RecruitmentPartnersAndClients idContextTest={idContextTest} />
                )}
              />

              <ProtectedRoute
                path="/subskill"
                component={() => <Subskill idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                exact
                path="/questions"
                component={() => <QuestionsList idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path="/addquestion"
                component={() => <AddQuestion idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path="/reported-question"
                component={() => <QuestionReport idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                exact
                path={"/questions/:id"}
                component={(props) => (
                  <EditQuestion idContextTest={idContextTest} {...props} />
                )}
              />
              <ProtectedRoute

                path={'/candidate/profile/:id/:recruitmentpartnerId'}
                component={(props) => <AdminProfile idContextTest={idContextTest}  {...props} />
                } />
              <ProtectedRoute
                path={"/client-specific-test-access"}
                component={() => (
                  <RecruiterPrivateSkillAccess idContextTest={idContextTest} />
                )}
              />
              <ProtectedRoute
                path="/pending-candidate-invites"
                component={() => <Candidate idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path="/tests"
                component={() => <AllTests idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path={"/create-demo-candidate"}
                exact={true}
                component={() => <DemoCandidate idContextTest={idContextTest} />}
              />
              <ProtectedRoute
                path={"/invitedtest-landing"}
                exact={true}
                component={() => (
                  <InviteTestLanding
                    idContextTest={idContextTest}
                    testInfo={testInfo}
                  />
                )}
              />
              <ProtectedRoute
                path={"/candidate-search-talent-community"}
                exact
                component={(props) => <AdvanceSearchTalentCommunity  {...props} />}
              />
              <ProtectedRoute
                path="/candidate-search-talent-community/:requisitionIdVal"
                component={(props) => {
                  let { match } = props;
                  const { requisitionId } = match.params; // Retrieve the 'id' from the URL
                  return <AdvanceSearchTalentCommunity requisitionIdVal={requisitionId}  {...props} />;
                }}
              />
              <ProtectedRoute
                path={"/pending-resume-validations"}
                exact={true}
                component={(props) => <PendingResumeValidationsPage id={idContextTest} userRecords={userRecords} userType={userType} userRecruitmentpartnerId={userRecruitmentpartnerId} {...props} />}
              />
              <ProtectedRoute
                path="/only-candidate-profile/:id"
                component={(props) => <OnlyCandidateProfile idContextTest={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId}  {...props} />}
              />
              <ProtectedRoute
                path={'/candidate/profile/:id'}
                component={
                  (props) => <AdminProfile idContextTest={idContextTest}  {...props} />
                }
              />
              <ProtectedRoute
                path={"/search-requisitions"}
                exact
                component={(props) => <SearchRequisitions id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} userType={userType} userRecords={userRecords} {...props} />}
              />
              <ProtectedRoute
                path={"/requisition-report-details"} exact component={(props) => <RequisitionReportDetails userType={userType}  {...props} />}
              />
              <ProtectedRoute
                path={"/activity/:id"}
                exact
                component={(props) => <Activity  {...props} />}
              />
              <ProtectedRoute
                path={'/requisition-reports'}
                component={
                  (props) => <RequisitionReports id={idContextTest} userType={userType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords={userRecords}  {...props} />
                }
              />
              <ProtectedRoute
                path={'/dashboard'}
                component={
                  (props) => <Dashboard id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} userType={userType} userRecords={userRecords} {...props} />
                }
              />
              <ProtectedRoute
                path={'/requisition-dashboard'}
                component={
                  (props) => <RequisitionDashboard id={idContextTest} userRecruitmentpartnerId={userRecruitmentpartnerId} userType={userType} userRecords={userRecords} {...props} />
                }
              />

              <ProtectedRoute
                path={'/orgManageDivision'}
                component={
                  (props) => <ManageDivision {...props} />
                }
              />

              <ProtectedRoute
                path={'/orgManageClient'}
                component={
                  (props) => <ManageClient {...props} />
                }
              />
              <ProtectedRoute
                path={'/orgManageSpoc'}
                component={
                  (props) => <ManageSpoc {...props} />
                }
              />
              <ProtectedRoute
              path={'/orgManageTeam'}
              component={
                (props) => <ManageTeam  {...props} />
              }
            />
              {localStorage.getItem('token') ? <></> : <Route exact path="*" component={() => (<Error404 info="Public" />)} />}
            </Switch>
          )}
          <Route exact path={"/error404"} component={Error404} />
        </Switch>
      </Router>
    </UserTypeContext.Provider>
  );
};
export default AppRoute;
