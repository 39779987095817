import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Button, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { DateTime } from "luxon";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";


import getSkills from "../../apis/Tests/getSkills";

import addReferralProgram from "../../apis/adminReferralAPI/addReferralProgram";
import updateReferralProgram from "../../apis/adminReferralAPI/updateReferralProgram";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { AddCircle } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Close from "@material-ui/icons/Close";
import { Loader } from '@progress/kendo-react-indicators';
function AdminReferralKendo({ userType, userIdType, isDataLoaded, setisDataLoaded, allPublicSkill, allRecruitmentOrgs, allData, getData }) {

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);


  const [allPrivateSkill, setallPrivateSkill] = useState([]);

  const [skillAndLocation, setSkillAndLocation] = useState([
    {
      skill: null,
      location: [],
    },
  ]);

  const [editData, setEditData] = useState([]);

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedIsActive, setSelectedIsActive] = useState(null);

  const theme = useTheme();
  const filter = createFilterOptions();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [atvBtn, setAtvBtn] = useState(false);
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [countryList, setCountryList] = useState([
    {
      currency: "USD",
      flag: "",
      isoCode: "US",
      latitude: "38.00000000",
      longitude: "-97.00000000",
      name: "United States",
      phonecode: "+1",
    },
    {
      currency: "CAD",
      flag: "",
      isoCode: "CA",
      latitude: "60.00000000",
      longitude: "-95.00000000",
      name: "Canada",
      phonecode: "+1",
    },
    {
      currency: "INR",
      flag: "",
      isoCode: "IN",
      latitude: "20.00000000",
      longitude: "77.00000000",
      name: "India",
      phonecode: "+91",
    },
  ]);

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    position: "absolute",
    top: "-50px",
    right: "0",
  };

  const btnSavegreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "1px solid #2AD156",
  };

  const closeButton = {
    position: "absolute",
    right: "7px",
    top: "12px",
    color: "grey",
  };

  // functions





  const getFilteredSkills = () => {
    let newList = allPublicSkill;
    for (let i = 0; i < selectedSkills.length; i++) {
      newList = newList.filter(
        (item) => item.id !== (selectedSkills[i].id ? selectedSkills[i].id : "")
      );
    }

    return newList;
  };

  const getFilteredCountryList = (index) => {
    let newList = countryList;
    for (let i = 0; i < skillAndLocation[index]?.location?.length; i++) {
      newList = newList.filter(
        (item) => item.name !== skillAndLocation[index]?.location[i]?.name
      );
    }

    return newList;
  };

  const openModal = (e, client) => {
    setmodalIsOpen(true);
  };

  const handelEditClick = (data) => {
    setEditModalIsOpen(true);
    setEditData(data);
    setSelectedOrg(data?.recruitmentpartner);
    setSelectedIsActive(data?.isActive);

    // filtering Skills and Locations

    let skillsArr = [];
    let locationsArr = [];
    for (let i = 0; i < data.referralskills.length; i++) {
      skillsArr.push(data?.referralskills[i].skill);
    }

    skillsArr = skillsArr.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    for (let i = 0; i < skillsArr.length; i++) {
      locationsArr[i] = [];
      for (let j = 0; j < data.referralskills.length; j++) {
        if (skillsArr[i].id === data.referralskills[j].skillId) {
          locationsArr[i][locationsArr[i].length] = JSON.parse(
            data.referralskills[j].location
          );
        }
      }
    }

    let skillnLocation = [];
    for (let i = 0; i < skillsArr.length; i++) {
      skillnLocation.push({
        skill: skillsArr[i],
        location: locationsArr[i],
      });
    }

    setSkillAndLocation(skillnLocation || []);
  };

  const addRow = () => {
    const row = {
      skill: null,
      location: [],
    };

    setSkillAndLocation([...skillAndLocation, row]);
  };

  const closeExtraRow = (i) => {
    let data = skillAndLocation.filter((item, index) => index !== i);

    setSkillAndLocation(data);
  };

  const closeModal = () => {
    setSelectedOrg(null);
    setSelectedSkills([]);
    setSelectedLocations([]);
    setSelectedIsActive(null);
    let row = {
      skill: null,
      location: [],
    };
    setSkillAndLocation([row]);

    setmodalIsOpen(false);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setSelectedOrg(null);
    setSelectedIsActive(null);
    let row = {
      skill: null,
      location: [],
    };
    setSkillAndLocation([row]);
  };

  const orgChangeHandler = (event, newValue) => {
    if (typeof newValue === "string") {
      setSelectedOrg({
        name: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setSelectedOrg({
        name: newValue.inputValue,
      });
    } else {
      setSelectedOrg(newValue);
    }
  };

  const skillChangeHandler = (event, newValue, i) => {
    let originalData = [...skillAndLocation];
    let dataToBeChange = originalData[i];
    dataToBeChange.skill = newValue;
    originalData[i] = dataToBeChange;
    setSkillAndLocation(originalData || []);
  };

  const locationChangeHandler = (newValue, i) => {
    let originalData = [...skillAndLocation];
    let dataToBeCHanged = originalData[i];
    dataToBeCHanged.location = newValue;
    originalData[i] = dataToBeCHanged;
    setSkillAndLocation(originalData || []);
  };

  const addReferralProgramFunc = async () => {
    setAtvBtn(true)
    let skillsArr = [];
    let flag = 0;
    for (let i = 0; i < skillAndLocation?.length; i++) {
      if (
        skillAndLocation[i]?.location?.length !== 0 &&
        skillAndLocation[i].skill !== null
      ) {
        for (let j = 0; j < skillAndLocation[i]?.location?.length; j++) {
          skillsArr.push({
            skillid: skillAndLocation[i].skill?.id,
            location: JSON.stringify(skillAndLocation[i]?.location[j]),
          });
        }
      } else {
        flag = 1;
      }
    }

    let agencyArr = [];

    for (
      let i = 0;
      i < selectedOrg?.agencyrecruitmentorganizations?.length;
      i++
    ) {
      agencyArr.push(
        '"' + selectedOrg?.agencyrecruitmentorganizations[i]?.id + '"'
      );
    }

    let payload = {
      recruitmentpartnerId: selectedOrg?.id,
      agencyId: agencyArr.length === 0 ? "[]" : `[${agencyArr}]`,
      createdBy: userIdType,
      updatedBy: userIdType,
      skills: skillsArr,
      isActive: selectedIsActive,
    };

    if (selectedOrg === null || selectedIsActive === null || flag === 1) {
      setAtvBtn(false)
      NotificationManager.error("Please select all the fields", "Error", 3000);
    } else {
      try {
        let response = await addReferralProgram(payload);
        if (response.status === 200) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Recruitment organization already exists in Referral Program",
            "Error",
            4000
          );
        } else {
          closeModal();
          getData();
          setAtvBtn(false)
          NotificationManager.success(
            "Referral program created successfully",
            "Success",
            3000
          );
        }
      } catch (err) {
        console.log("Error", err);
        setAtvBtn(false)
      }
    }
  };

  const editReferralProgramFunc = async () => {
    setAtvBtn(true)
    let skillsArr = [];
    let flag = 0;
    for (let i = 0; i < skillAndLocation?.length; i++) {
      if (
        skillAndLocation[i]?.location?.length !== 0 &&
        skillAndLocation[i].skill !== null
      ) {
        for (let j = 0; j < skillAndLocation[i]?.location?.length; j++) {
          skillsArr.push({
            skillId: skillAndLocation[i].skill?.id,
            location: JSON.stringify(skillAndLocation[i]?.location[j]),
          });
        }
      } else {
        flag = 1;
      }
    }

    let agencyArr = [];

    for (
      let i = 0;
      i < selectedOrg?.agencyrecruitmentorganizations?.length;
      i++
    ) {
      agencyArr.push(
        '"' + selectedOrg?.agencyrecruitmentorganizations[i]?.id + '"'
      );
    }

    let payload = {
      referralpartnerId: editData.referralskills[0].referralpartnerId,
      recruitmentpartnerId: selectedOrg?.id,
      agencyId: agencyArr.length === 0 ? "[]" : `[${agencyArr}]`,
      updatedBy: userIdType,
      skills: skillsArr,
      isActive: selectedIsActive,
    };

    if (selectedOrg === null || selectedIsActive === null || flag === 1) {
      setAtvBtn(false)
      NotificationManager.error("Please select all the fields", "Error", 3000);
    } else {
      try {
        let response = await updateReferralProgram(payload);
        if (
          response.data?.message ===
          "This recruitmentpartner name already exists in referral Please use a different recruitmentpartner name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Recruitment organization already exists in Referral Program",
            "Error",
            4000
          );
        } else {
          closeEditModal();
          getData();
          setAtvBtn(false)
          NotificationManager.success(
            "Referral program updated successfully",
            "Success",
            3000
          );
        }
      } catch (err) {
        console.log("Error", err);
        setAtvBtn(false)
      }
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });



  return (
    <>
      <div className="containerMain">
        <GridDiv
          pageable={{
            buttonCount: 10,
            info: true,
            previousNext: true,
            pageSizes: true,
            serverPaging: true,
            serverFiltering: true,
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          className="style_grid"
          style={{
            overflow: "hidden",
          }}
          data={process(allData, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >
          {!isDataLoaded && (
            <GridNoRecords>
              <div className='col-4'>
                <br />
                <Loader size='large' themeColor="info" type="converging-spinner" />
              </div>
            </GridNoRecords>)}
          <GridColumn
            title="Actions"
            width="180px"
            filterable={false}
            cell={(event) => {
              return (
                <td className="editCenter">
                  <Button
                    onClick={() => handelEditClick(event.dataItem)}
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                </td>
              );
            }}
          />
          <GridColumn
            field="recruitmentpartner.name"
            title="Recruitment Organization"
            filterable={true}
            sortable={true}
          />
          <GridColumn
            field="skills"
            title="Skills"
            filterable={true}
            sortable={true}
          />
          <GridColumn
            field="locations"
            title="Locations"
            filterable={true}
            sortable={true}
          />
          <GridColumn
            field="formattedDate"
            title="Created At"
            filterable={true}
            sortable={true}
            filter={"date"}
            format="{0:d},{0:t}"
            cell={(e) => {
              return (
                <td>
                  {DateTime.fromISO(e.dataItem.createdAt)
                    .toFormat("MM/dd/yyyy")
                    .toString()}
                </td>
              );
            }}
          />
          <GridColumn
            title="Is Active"
            field="isActive"
            width="180px"
            filterable={true}
            filter={"boolean"}
            cell={(event) => {
              return <td>{event.dataItem?.isActive ? "True" : "False"}</td>;
            }}
          />
        </GridDiv>
        <Button
          variant="outlined"
          style={btnGreen}
          color="primary"
          onClick={openModal}
        >
          Add Referral Program
        </Button>
      </div>

      {/* Add Referral form */}
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={modalIsOpen}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle
            id="customized-dialog-title"
            className="admindailog_title_modal"
            onClose={closeModal}
          >
            Add Referral Program
            <IconButton
              aria-label="close"
              style={closeButton}
              onClick={closeModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <div className="optionsStyle">
            <Typography gutterBottom>Recruitment Organization*</Typography>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                filterSelectedOptions
                options={allRecruitmentOrgs}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Recruitment Organization"
                  />
                )}
                onChange={(event, newValue) =>
                  orgChangeHandler(event, newValue)
                }
              />
            </FormControl>
          </div>

          <div className="skilLocationContainer">
            {skillAndLocation.map((item, i) => {
              return (
                <div key={i}>
                  <div className="optionsStyle df">
                    <div className="skillLocation">
                      <Typography gutterBottom>Skills*</Typography>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          id="combo-box-demo"
                          filterSelectedOptions
                          value={item.skill || null}
                          options={allPublicSkill}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Skills"
                            />
                          )}
                          onChange={(event, newValue) =>
                            skillChangeHandler(event, newValue, i)
                          }
                        />
                      </FormControl>
                    </div>

                    <div className="skillLocation locationCss">
                      <Typography gutterBottom>Locations*</Typography>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="locationCloseBtnStyle"
                      >
                        <Autocomplete
                          multiple
                          id="combo-box-demo"
                          className="locationField"
                          value={skillAndLocation[i].location}
                          options={getFilteredCountryList(i)}
                          autoHighlight
                          filterOptions={filterOptions}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newValue) => {
                            locationChangeHandler(newValue, i);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              required={true}
                              placeholder="Select Locations"
                            />
                          )}
                        />
                        <div className="closeBtnStyle">
                          {i !== 0 && (
                            <CancelRoundedIcon
                              onClick={() => closeExtraRow(i)}
                            />
                          )}
                        </div>
                      </FormControl>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="optionsStyle addButton">
            <AddCircleIcon onClick={addRow} className="addBtn" />
          </div>
          <div className="optionsStyle">
            <Typography gutterBottom>Is Active*</Typography>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                filterSelectedOptions
                // options={getFilteredTests()}
                options={[{ testName: "True" }, { testName: "False" }]}
                getOptionLabel={(option) =>
                  option.testName ? option.testName : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Status"
                  />
                )}
                onChange={(e, newValue) => {
                  newValue?.testName === "True"
                    ? setSelectedIsActive(true)
                    : newValue?.testName === "False"
                      ? setSelectedIsActive(false)
                      : setSelectedIsActive(null);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={closeModal}>
              Cancel
            </Button>
            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={addReferralProgramFunc}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>

          </DialogActions>
        </Grid>
      </Dialog>

      {/* Edit Referral form */}
      <Dialog
        onClose={closeEditModal}
        aria-labelledby="customized-dialog-title"
        open={editModalIsOpen}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle
            id="customized-dialog-title"
            className="admindailog_title_modal"
            onClose={closeEditModal}
          >
            Edit Referral Program
            <IconButton
              aria-label="close"
              style={closeButton}
              onClick={closeEditModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <div className="optionsStyle">
            <Typography gutterBottom>Recruitment Organization*</Typography>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                filterSelectedOptions
                value={selectedOrg}
                options={allRecruitmentOrgs}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Recruitment Organization"
                  />
                )}
                onChange={(event, newValue) =>
                  orgChangeHandler(event, newValue)
                }
              />
            </FormControl>
          </div>

          <div className="skilLocationContainer">
            {skillAndLocation.map((item, i) => {
              return (
                <div key={i}>
                  <div className="optionsStyle df">
                    <div className="skillLocation">
                      <Typography gutterBottom>Skills*</Typography>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          id="combo-box-demo"
                          filterSelectedOptions
                          value={skillAndLocation[i].skill}
                          options={allPublicSkill}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Skills"
                            />
                          )}
                          onChange={(event, newValue) =>
                            skillChangeHandler(event, newValue, i)
                          }
                        />
                      </FormControl>
                    </div>

                    <div className="skillLocation locationCss">
                      <Typography gutterBottom>Locations*</Typography>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="locationCloseBtnStyle"
                      >
                        <Autocomplete
                          multiple
                          id="combo-box-demo"
                          className="locationField"
                          value={skillAndLocation[i].location}
                          options={getFilteredCountryList(i)}
                          autoHighlight
                          filterOptions={filterOptions}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, newValue) => {
                            locationChangeHandler(newValue, i);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              required={true}
                              placeholder="Select Locations"
                            />
                          )}
                        />
                        <div className="closeBtnStyle">
                          <CancelRoundedIcon onClick={() => closeExtraRow(i)} />
                        </div>
                      </FormControl>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="optionsStyle addButton">
            <AddCircleIcon onClick={addRow} className="addBtn" />
          </div>
          <div className="optionsStyle">
            <Typography gutterBottom>Is Active*</Typography>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                id="combo-box-demo"
                filterSelectedOptions
                // options={getFilteredTests()}
                value={
                  selectedIsActive
                    ? { testName: "True" }
                    : { testName: "False" }
                }
                options={[{ testName: "True" }, { testName: "False" }]}
                getOptionLabel={(option) =>
                  option.testName ? option.testName : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Status"
                  />
                )}
                onChange={(e, newValue) => {
                  newValue?.testName === "True"
                    ? setSelectedIsActive(true)
                    : newValue?.testName === "False"
                      ? setSelectedIsActive(false)
                      : setSelectedIsActive(null);
                }}
              />
            </FormControl>
          </div>
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={closeEditModal}>
              Cancel
            </Button>
            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={editReferralProgramFunc}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
      </Dialog>
      <NotificationContainer />
    </>
  );
}

export default AdminReferralKendo;
