import React, { useState, useEffect, createContext } from 'react';
import './RecLead.css';
import AtsDashNav from '../../components/AtsDashNav/AtsDashNav';
import RecLeadFeed from './RecLeadFeed';
import getAllReferralSkiilsByRecruiterPartners from '../../apis/referral/referralbyrecruitmentpartner';

// Create the ShowResumeModalContext
export const ShowResumeModalContext = createContext();

function RecLeadBody(props) {
  const { id, userType, userRecruitmentpartnerId, userRecords, userRole } = props;
  const [orgReferral, setorgReferral] = useState("");
  const [recruiterAgencyType, setRecruiterAgencyType] = useState("");
  const [recruitementId, setRecruitementId] = useState("");
  const [showResumeModal, setShowResumeModal] = useState(false);


  useEffect(() => {
    
  const getCommunityStaticsData = async () => {
    try {
      let _recruitmentpartnerId = userRecruitmentpartnerId ? userRecruitmentpartnerId : "";
      setRecruiterAgencyType(userRecords?.agency?.agencyType?.code)
      setRecruitementId(_recruitmentpartnerId)
      if (_recruitmentpartnerId) {
        let response = await getAllReferralSkiilsByRecruiterPartners(_recruitmentpartnerId);
        setorgReferral(response?.data?.data[0]?.isActive)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

    (async () => {
      await getCommunityStaticsData();
    })()
    return () => {
      return;
    }
  }, [ userRecords?.agency?.agencyType?.code , userRecruitmentpartnerId])

  return (
    <ShowResumeModalContext.Provider value={{ showResumeModal, setShowResumeModal }}>
      <div className='ats-dash-container'>
        <div className='ats-dash-nav' style={{ height: "80vh", marginBottom: "40px", marginRight: "40px" }}>
          <AtsDashNav
            idContextTest={id}
            userRole={userRole}
            orgReferral={orgReferral}
            recruitementId={recruitementId}
            recruiterAgencyType={recruiterAgencyType}
            setShowResumeModal={setShowResumeModal} // Pass setShowResumeModal as a prop
            userRecords={userRecords}
            userType={userType}
          />
        </div>
        <div className='ats-dash-body' style={{ marginLeft: '10px' }}>
          <RecLeadFeed
            showResumeModal={showResumeModal}
            setShowResumeModal={setShowResumeModal}
            id={id}
            userType={userType}
            userRole={userRole}
            userRecruitmentpartnerId={userRecruitmentpartnerId}
            userRecords={userRecords}
          />
        </div>
      </div>
    </ShowResumeModalContext.Provider>
  );
}

export default RecLeadBody;
