import React, { useState, useEffect, useContext, useRef } from "react";
import { DateTime } from "luxon";
import Header from "../../components/poweResumeHeader/header.js";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ProfileCompletion from "../../components/profile/profileCompletion/profileCompletion";
import Resume from "../../components/profile/resume/resume";
// import csc from "../../../utilities/country-state-city/dist"
import TextField from "@material-ui/core/TextField";
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import csc from "../../utilities/country-state-city/dist"
import Testimonials from "../../components/profile/testimonials/testimonials";
import InterviewDateTime from "../../components/profile/interviewDatetime/interviewDatetime";
import PrimarySkills from "../../components/profile/primarySkills/primarySkills";
import OtherSkills from "../../components/profile/otherSkills/otherSkills";
import RecordVideoIntro from "../../components/profile/recordVideointro/recordVideointro";
import SkillVideoTest from "../../components/profile/skillVideotest/skillVideotest";
import Footer from "../../components/adminFooter/Footer";
import "./profile.css";
import updateLastlogedin from '../../apis/profile/updatelastlogedin';
import { UserTypeContext } from "../../AppRoute";
import getInitiatedOrCompletedUserTests from "../../apis/userTest/getInitiatedOrCompletedUserTests";
// import location from "../../components/profileCompletion/images/location.png";
import location from "../../components/profile/profileCompletion/images/location.png"
import mobile from "../../components/profile/profileCompletion/images/mobile.png"
import cross from "../../components/profile/profileCompletion/images/cross.svg"
import pencil from "../../components/profile/profileCompletion/images/pencil.png";
// import LinkedIn from "./images/linkedIn.svg";
import PersonalInformation from "../personalInformation/personalInformation.js";
import LinkedIn from "../../components/profile/resume/images/linkedIn.svg"
import profileById from '../../apis/profile/profileById'
// import profileResponse from "../../apis/profile/profileById";
// import updateProfile from "../../apis/profile/updateProfile";
// import phoneNumberExistResponse from "../../apis/users/getUserDataByPhoneNumber";
// import updateUserResponse from "../../apis/users/updateUsers";
// import getUsersById from "../../apis/users/getUserById";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { DialogContent, DialogActions } from "@material-ui/core";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  buttonAlignForRemove: {
    padding: "32px!important",
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important",
  },
}));
const Profile = ({ idContextTest, props }) => {

  const jsonConfig = require("../../Config.json");
  const { userType, userIdType, userRecords } = useContext(UserTypeContext);
  const [lastUpdate, setLastUpdate] = useState("");
  const [primarySkills, setPrimarySkills] = useState("");
 
  useEffect(() => {
    // updateLastlogedinProfile({ userid: idContextTest })
    getProfileData();
    // getUserTests();
    // getProfile();
    // getUserEmail();
  }, [])

  useEffect(() => {
    getProfileData();
    // getUserTests();
  }, [lastUpdate])

  const [displayname, setdisplayname] = useState("");
  const [mailData, setMailData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });
  const handleCallback = (data) => {
    setPrimarySkills(data);
  };
  const [profileData, setProfileData] = useState(null);
  const [locationCountry, setLocationCountry] = useState("");
  const [locationState, setLocationState] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [openLinkedIn, setOpenLinkedIn] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const classes = useStyles();
  const [editprofileModal, setEditprofileModal] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  // const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
  const [modalProfilePictureUrl, setModalProfilePictureUrl] = useState(null);
  const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
  // const [editprofileModal, setEditprofileModal] = useState(false);
  const [loadingController, setLoadingController] = useState(false);
  // const [locationCountry, setLocationCountry] = useState("");
  // const [locationState, setLocationState] = useState("");
  // const [locationCity, setLocationCity] = useState("");
  // const [crop, setCrop] = useState({ x: 0, y: 0 })
  // const [rotation, setRotation] = useState(0)
  // const [zoom, setZoom] = useState(1)
  // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [userCountry, setUserCountry] = useState({});
  const [userState, setUserState] = useState({});
  const [userCity, setUserCity] = useState({});
  const [modalFirstName, setModalFirstName] = useState("");

  const [modalLastNames, setModalLastNames] = useState("");
  const [modalMiddleInitial, setmodalMiddleInitial] = useState("");
  const [totalExpYearSelected, setTotalExpYearSelected] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [availablephnno, setavailablephnno] = useState("");
  const [email, setemail] = useState("")
  const [previousImageURL, setPreviousImageURL] = useState('')
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  // const [phoneError, setPhoneError] = useState(false);
  const [phoneNumberEditModal, setPhoneNumberEditModal] = useState("");
  const [countryCodeEditModal, setCountryCodeEditModal] = useState("");
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false)
  const [defaultCountryCode, setDefaultCountryCode] = useState("")
  // const [countryCode, setcountryCode] = useState("")
  const [userEmail, setUserEmail] = useState("");
  const [modalCountryObject, setModalCountryObject] = useState({});
  // const [modalCountryObject, setModalCountryObject] = useState({});
  const [stateList, setStateList] = useState(Array);
  const [modalStateObject, setModalStateObject] = useState({});
  const [cityList, setCityList] = useState(Array);
  const [countryCode, setcountryCode] = useState("")
  const [modalCityObject, setModalCityObject] = useState({});
  const [display, setdisplay] = useState(false);
  const [isEmailCopied, setisEmailCopied] = useState(false)
  const userCameraStreamRef = useRef(null);
  const [isDefaultProfile, setisDefaultProfile] = useState(false)
  const [videoTest, setVideoTest] = useState({});





  const getProfileData = async () => {
    try {
      const profileResponse = await profileById(idContextTest);

      setLoadingController(true);
      var profileDataParsed = profileResponse.data.data[0];
      setProfileData(profileDataParsed);

    } catch (error) {
    }
  };

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: "#1A8FFF",
    },
    tooltip: {
      backgroundColor: "#1A8FFF",
      padding: "4px 13px",
      fontFamily: "Segoe-Semibold",
      fontSize: "13px"

    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }


  return (
    <>
      {userType === "Candidate" ? (
        profileData ?
          (
            <div className="footerflex powres_maincon">
              <div >
                <Header
                  userId={idContextTest}
                  mailData={mailData}
                  setMailData={setMailData}
                  lastUpdate={lastUpdate}
                  profileData={profileData}
                />
                <div className="section-wrapper">
                  <div className="container flex-to-footer powres_maintop " >
                    <div className="powerresumetop_adjust powres_main ">
                      <div className="pd_20">
                        <div className="fs-Bold-light  f-color ">

                          <div className="powerResume_section">
                            <div className="fs-regular powres_fs powres_fs_color" >PoweResume</div>
                            <div className="PowerResume_Copy fs-regular powres_fs_color ">&#8482;</div>
                          </div>
                        </div>
                        {!!lastUpdate && (
                          <div className="aaa">
                            {" "}
                            <span className="last-update-text f-color  fs-semi-bold ">
                              Last updated &nbsp;
                               {DateTime.fromISO(profileData?.updatedAt).toFormat("dd-LLL-yyyy, hh:mm:ss a")}
                            </span>
                          </div>
                        )}
                      </div>
                      <div >
                        <ProfileCompletion
                          setLastUpdate={setLastUpdate}
                          userId={idContextTest}
                          profileData={profileData}
                          className="camera_icon_recruiter cp"
                        />
                      </div>
                    </div>
                    <div className="powres_card fle">
                      <div >
                        <div className="powres_contain">
                          <div className="mr_20">
                            <Resume setLastUpdate={setLastUpdate} userId={idContextTest}
                            />

                          </div>

                          <div>
                            <PersonalInformation
                              idContextTest={idContextTest}
                              setLastUpdate={setLastUpdate}
                              profileData={profileData}


                            />
                          </div>


                          <div >
                            <RecordVideoIntro
                              setLastUpdate={setLastUpdate}
                              userId={idContextTest}
                              profileData={profileData}
                            />
                          </div>
                        </div>
                        <div className="powres_card mt_20 powres_prim">
                          <PrimarySkills
                            setLastUpdate={setLastUpdate}
                            userId={idContextTest}
                            parentCallback={handleCallback}
                          />
                        </div>

                        <div className="powres_card mt_20 powres_other">
                          <OtherSkills
                            setLastUpdate={setLastUpdate}
                            userId={idContextTest}
                          />

                        </div>

                        <SkillVideoTest userIdSkillVideo={idContextTest} email={userRecords?.email} />
                        
                      </div>
                      <div className="powres_inter_box" >
                        <InterviewDateTime
                          setLastUpdate={setLastUpdate}
                          userId={idContextTest}
                          profileData={profileData}
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="profile_footer powres_maincon">
                <Footer />
              </div>
            </div>
          )
          :
          <div className="row padding_80 text-center">
            <p className="fs-semi-bold fs-40 f-color">
              Loading...
            </p>
          </div>
      ) : (<>
        <div className="row padding_80 text-center">
          <p className="fs-semi-bold fs-40 f-color">
            You Are Not Authorized To Visit This Page
          </p>
        </div>
      </>)
      }
    </>

  );
};
export default Profile;