import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import emailConfigurationByCodeResponse from "../../apis/email/emailConfigurationByCode";
import sendEmailResponse from "../../apis/email/sendEmail";
import validateEmail from '../../utilities/validateEmail';

const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
}));

// modalClose props is used here as callback for closing the modal
const GeneralSupportModal = ({ modalOpen, modalClose }) => {
  const jsonConfig = require("../../Config.json");
  const classes = useStyles();
  const [modalFirstName, setmodalFirstName] = useState("");
  const [modalLastName, setmodalLastName] = useState("");
  const [modalemail, setmodalemail] = useState("");
  const [mailQuestion, setmailQuestion] = useState("");
  const [allSupportMail, setallSupportMail] = useState([]);
  const getAllMail = async () => {
    try {
      var codeForMail = {
        code: "CTsupportemail",
      };
      const getAllMailResponse = await emailConfigurationByCodeResponse(
        codeForMail
      );
      setallSupportMail(getAllMailResponse.data.data[0].emails);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllMail();
  }, [modalOpen]);

  const mailSend = async () => {
    const mailValidationHandle = validateEmail(modalemail);
    if (
      modalemail === "" ||
      modalFirstName === "" ||
      modalLastName === "" ||
      mailQuestion === ""
    ) {
      NotificationManager.error(
        "Please enter the required fields",
        "Error",
        2500
      );
    } else {
      if (!mailValidationHandle) {
        NotificationManager.error(
          "Please enter a correct email",
          "Error",
          2500
        );
      } else {
        if (jsonConfig.sendEmail) {
          try {
            const message = {
              to: allSupportMail,
              from: jsonConfig.sendGridFromEmail,
              subject: `Message from ${modalFirstName} ${modalLastName}`,
              html: `${mailQuestion}`,
            };
            const supportEmailResponse = await sendEmailResponse(message);          
            NotificationManager.success(
              "Done! Your concern has been noted. Someone from our support team will reach out to you shortly.",
              "Success",
              2500
            );
            setmodalFirstName("");
            setmodalLastName("");
            setmodalemail("");
            modalClose();
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };

  const handleClose = () => {
    modalClose();
  };

  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          Contact us
        </DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="dropdown_width_outer mt_12">
            <div className="select customized-select dropdown_width">
              <legend>First Name*</legend>
              <input
                type="text"
                className="text_box_cleartalent w-94"
                value={modalFirstName}
                onChange={(event) => setmodalFirstName(event.target.value)}
                placeholder="Enter your First Name"
                required
              />
            </div>
            <div className="select customized-select dropdown_width zipgap">
              <legend>Last Name*</legend>
              <input
                type="text"
                className="text_box_cleartalent w-94"
                value={modalLastName}
                onChange={(event) => setmodalLastName(event.target.value)}
                placeholder="Enter your Last Name"
                required
              />
            </div>
          </div>
          <div className="select customized-select mt_12">
            <legend>Email*</legend>
            <input
              type="text"
              className="text_box_cleartalent "
              value={modalemail}
              onChange={(e) => setmodalemail(e.target.value)}
              required
            />
          </div>
          <div className="select customized-select mt_12">
            <legend>Question*</legend>
            <textarea
              type="text"
              className="text_area_cleartalent_support_home"
              rows={4}
              placeholder="Please enter your question here"
              onChange={(e) => setmailQuestion(e.target.value)}
              required
            />
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn-big btn-skill cp"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-big btn-green cp"
            onClick={mailSend}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default GeneralSupportModal;
