import axios from "axios";
const jsonConfig = require("../../Config.json");

const revokeInviteCandidateResponse = async (recruterInviteId,payload) => {
    let allResponse = {}
    try {
        const getallresponse =await axios.put(
            jsonConfig.apiUrl + "revokeRecruiterInvites/" + recruterInviteId,
            payload,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
        allResponse = getallresponse;
        return Promise.resolve(allResponse)
    }
    catch (error) {
      console.log("Error->", error);
        return Promise.reject(error)
    }
}

export default revokeInviteCandidateResponse;