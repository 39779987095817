import React, { useEffect, useState } from 'react'
import './requisitionDashboardCount.css';
import getAllRequisitionStatusAndCounts from "../../apis/requisitionDetails/getAllRequisitionStatusAndCounts";
import CircularProgress from '@material-ui/core/CircularProgress';
import getAllRecruiterRequisitionStatusAndCounts from '../../apis/requisitionDetails/getAllRecruiterRequisitionStatusAndCounts';
import getAllRecruiterLeaderRequisitionStatusAndCounts from '../../apis/requisitionDetails/getAllRecruiterLeaderRequisitionStatusAndCounts';
import getAllTeamLeaderRequisitionStatusAndCounts from '../../apis/requisitionDetails/getAllTeamLeaderRequisitionStatusAndCounts';

const RequisitionDashboardCount = (props) => {
    const { statusChange, filterChange, requisitionStatus, userType, idContextTest, userRecruitmentpartnerId, userRole } = props;
    const [statusCounts, setStatusCounts] = useState({});
    const [loading, setLoading] = useState(true);


    const changeStatus = (e, val) => {
        e.preventDefault();
        if (val === "High") {
            statusChange("ALL");
            filterChange("priority", "High");
        }
        else if (val === "ALL") {
            statusChange("ALL");
            filterChange("priority", "");
        }
        else {
            let arr = requisitionStatus.filter((item, i) => item.name === val);
            statusChange(arr[0]?._id);
            if(userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")){
                filterChange("priority", "");
            }
        }
    };
    useEffect(() => {
        let isMounted = true;

        const getReqStatusAndCounts = async () => {
            try {
                let payload = {
                    isDashBoardCount: false,
                    isOrgAdmin: userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin") ? true : false,
                    isMISOperator: userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator") ? true : false,
                    recruitmentpartnerId:userRecruitmentpartnerId
                };
                setLoading(true);
                const response =
                    userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")
                        ? await getAllRecruiterRequisitionStatusAndCounts(idContextTest, payload)
                        : userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")
                            ? await getAllRequisitionStatusAndCounts(userRecruitmentpartnerId, payload)
                            : userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")
                                ? await getAllRequisitionStatusAndCounts(userRecruitmentpartnerId, payload)
                                : userType === "Recruitment Leader" || (userType === "Admin" && userRole === "Recruitment Leader")
                                    ? await getAllRecruiterLeaderRequisitionStatusAndCounts(idContextTest, payload)
                                    : userType === "Team Leader" || (userType === "Admin" && userRole === "Team Leader")
                                        ? await getAllTeamLeaderRequisitionStatusAndCounts(idContextTest, payload)
                                        : null;

                if (isMounted) {
                    setStatusCounts(response?.data?.data || []);
                    setLoading(false);
                }
            } catch (err) {
                console.log(err, "error");
            }
        };

        getReqStatusAndCounts();

        return () => {
            isMounted = false;
        };
    }, [idContextTest, userRecruitmentpartnerId, userRole, userType]);


    return (
        <>
            <div className="thirdCard cardBlock">
                <div className="t_card_heading">
                    <div className="colorBox"></div>
                    <div className="thirdCardHeading">Requisition Dashboard </div>
                </div>
                {/* options */}
                <div className="t_card_content">
                    <table>
                        <tbody>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, "ALL")}>Total Requisitions</td>
                                {
                                    !loading ?
                                        <td className="tableCell cellQuantity">{statusCounts?.totalCount || 0}</td>
                                        :
                                        <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>
                                }
                            </tr>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, "High")}>High Priority Requisitions</td>
                                {!loading ? <td className="tableCell cellQuantity">{statusCounts?.highPriorityRequisitonCount || 0}</td>
                                    :
                                    <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>}
                            </tr>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, 'OPEN')}>Open</td>
                                {!loading ? <td className="tableCell cellQuantity">{statusCounts?.statusBasedCount['OPEN'] || 0}</td>
                                    :
                                    <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>}
                            </tr>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, 'CLOSED')}>Closed</td>
                                {!loading ? <td className="tableCell cellQuantity">{statusCounts?.statusBasedCount['CLOSED'] || 0}</td>
                                    :
                                    <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>}
                            </tr>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, 'ON HOLD')}>On Hold</td>
                                {!loading ? <td className="tableCell cellQuantity">{statusCounts?.statusBasedCount['ONHOLD'] || 0}</td>
                                    :
                                    <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>}
                            </tr>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, 'FILLED')}>Filled</td>
                                {!loading ? <td className="tableCell cellQuantity">{statusCounts?.statusBasedCount['FILLED'] || 0}</td>
                                    :
                                    <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>}
                            </tr>
                            <tr className="third_col_styles">
                                <td className="tableCell requisitionType" onClick={(e) => changeStatus(e, 'CANCELLED')}>Cancelled</td>
                                {!loading ? <td className="tableCell cellQuantity">{statusCounts?.statusBasedCount['CANCELLED'] || 0}</td>
                                    :
                                    <td className="tableCell cellQuantity"><CircularProgress style={{ width: "25px", height: "25px", color: "#393939" }} /></td>}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default RequisitionDashboardCount