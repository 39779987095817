import { Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect } from 'react'

function AutoComplete({key,title,options,...props}) {
    
    return (
        <>
            <span className='spaceOut'>{title}</span>
            <Autocomplete
                id="submission-type-autocomplete"
                options={options || []}
                size="small"
                filterSelectedOptions
                PaperComponent={({ children }) => (
                    <Paper style={{ fontSize: "16px", fontFamily: 'Segoe UI' }}>
                        {children}
                    </Paper>
                )}
                renderInput={(params) => (
                    <TextField {...params} placeholder={'Select '+title} variant="outlined" />
                )}
                
                {...props}
            />
        </>
    )
}

export default AutoComplete
