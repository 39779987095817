import axios from "axios";
const jsonConfig = require("../../Config.json");

const getTotalYearsExperience = async () => {
   let totalExp = [];
   try {
      const totalYearsExperience = await axios.get(
         jsonConfig.apiUrl + "TotalExperience",
         {
            headers: {
               "x-functions-key": jsonConfig.key,
            },
         }
      );
      totalExp = totalYearsExperience.data.data;
      return Promise.resolve(totalExp);
   }
   catch (error) {
      return Promise.reject(error);
   }
}

export default getTotalYearsExperience;