import axios from "axios";
const jsonConfig = require("../../Config.json");

const verifyOtpResponse = async (payLoad) => {
    let allResponse = {}
    try {
        const response = await axios.post(
            jsonConfig.apiUrl + "VerifyVerificationCode", payLoad,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr", error);      
        return Promise.reject(error);
    }
}

export default verifyOtpResponse;