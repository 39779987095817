import React, { useEffect, useState } from "react";
import "./testimonials.css";
import pdfimage from "./images/pdf.png";
import docimage from "./images/doc.png";
import imgimage from "./images/Group 453.png";
import axios from "axios";
import Skeleton from '@material-ui/lab/Skeleton';
import { BlobServiceClient } from "@azure/storage-blob";
import { FilePicker } from "react-file-picker";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
// import Modal from 'react-modal';
import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import Typography from "@material-ui/core/Typography";

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import testimonialsByProfileIdResponse from "../../../apis/profile/testimonialsByProfileId";
import createTestimonial from "../../../apis/profile/createTestimonial";
import deleteTestimonial from "../../../apis/profile/deleteTestimonial";


const useStyles = makeStyles((theme) => ({

  buttonAlignForRemove: {
    padding: "32px!important"
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important"
  }

}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0.5)
  }
});
const CloseAction = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    <MuiDialogActions className={classes.root}>
      {onClose ? (
        <IconButton
          aria-label="close"
          // className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogActions>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    // padding: "0px 30px 0px 30px!important",
  }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);
const Testimonials = ({userId}) => {
  const jsonConfig = require("../../../Config.json");
  const classes = useStyles();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [uploadTestimonialButton, setUploadtestimonialButton] = useState(true)
  const [testimonialData, setTestimonialData] = useState([])
  const [isTestimonialDataAvailable, setIsTestimonialDataAvailable] = useState(false);
  const [testimonialUrl, setTestimonialUrl] = useState("");
  const [openDeleteDialogue, setopenDeleteDialogue] = useState(false);
  // const [removeTestimonialId, setremoveTestimonialId] = useState("");
  const [removeTestimonialId, setremoveTestimonialId] = useState([])
  const [fileType, setfileType] = useState("");
  const [loadingController, setLoadingController] = useState(false);
  const getTestimonialData = async () => {

    // const authResponse = await instance.acquireTokenSilent({
    //   ...loginRequest,
    //   account: account,
    // });


    try {
      const id = userId;
      const testimonailResponse = await testimonialsByProfileIdResponse(id);
      setIsTestimonialDataAvailable(true);
      let tempTestimonialData = [];
      if (testimonailResponse.data) {
        if (testimonailResponse.data.data) {
          tempTestimonialData = testimonailResponse.data.data;
        }
      }
      setTestimonialData(tempTestimonialData);
      setUploadtestimonialButton(true);
      setLoadingController(true);
    } catch (error) {
      console.log('errofdsfdsfdsfsdfdsfdsfr', error);
      if (error) {
        setLoadingController(true)
      }
      setIsTestimonialDataAvailable(false);
      setTestimonialData([])
    }
  };
  const viewTestimonal = (event, url) => {
    window.open(url, "_blank");
    setTestimonialUrl(url)
  }
  const uploadTestimonial = async (file) => {
    await uploadToBlobStorage(file)
  }
  const uploadToBlobStorage = async (blob) => {
    // const authResponse = await instance.acquireTokenSilent({
    //   ...loginRequest,
    //   account: account,
    // });
    const blobaccount = jsonConfig.blobStorageAccountName;
    const sas = jsonConfig.sasToken;
    const bsClient = new BlobServiceClient(
      `https://${blobaccount}.blob.core.windows.net${sas}`
    );

    const containerClient = bsClient.getContainerClient(
      "testimonials"
    );

    const blobName = userId + "/" + blob.name;
    setfileType(blob.type)
    const url = 'https://' + blobaccount + '.blob.core.windows.net' + '/testimonials/' + blobName;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    const response = await blobClient.uploadBrowserData(blob);
    var dt = new Date();
    var testimonialData = {
      "profileId": userId,
      "fileUrl": url,
      "fileName": blob.name,
      "createdBy": userId,
      "updatedBy": userId
    }
    const res = await createTestimonial(testimonialData);
    NotificationManager.success('Testimonial uploaded', 'Success');
    await getTestimonialData();
  };
  const openDialoue = (event, id) => {
    const filtered = testimonialData.filter(item => item.isChecked);    
    if (filtered.length) {
      setopenDeleteDialogue(true);
    } else {
      NotificationManager.error("Please Select File To Remove", "Error");
    }
    setremoveTestimonialId(filtered);
  }
  const closeDialogue = async () => {
    setopenDeleteDialogue(false)
  }
  const removeTestimonial = async () => {
    if (removeTestimonialId.length) {
      removeTestimonialId.forEach(async item => {
          try {
              const deleteTestimonialResponse = await deleteTestimonial(item.id);        
              NotificationManager.success('Testimonial deleted', 'Success');
              await getTestimonialData();
              await closeDialogue();
          }
          catch (error) {
              console.log(error);
          }
      })

  }
  }
  const handleChange = (event) => {
    testimonialData[event].isChecked = !testimonialData[event].isChecked
    setTestimonialData(testimonialData);
  };
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  useEffect(() => {
    // instance.acquireTokenSilent({
    //   ...loginRequest,
    //   account: account
    // }).then((response) => {
      getTestimonialData();
    // })

  }, [userId]);
  return (
    <>
      {
        loadingController ?
          <>
            <div className="card mt_10">
              <div className="row">
                <p className="fs-semi-bold fs-18 f-color">Testimonials/certifications</p>
                <p className="fs-regular fs-12 f-color">
                  (Allowed file types: .pdf, .docx, .jpg, jpeg, .png)
                </p>

                {!isTestimonialDataAvailable ? (
                  <>

                  </>
                ) :
                  (
                    testimonialData?.map((value, index) => (
                      <div key={index}>
                        <div className="df mt_6">
                          <div className="testimonial_chkbox">
                            <input type="checkbox" id={value.id} key={value.id} value={value.id} onClick={(e) => handleChange(index)} />
                            <label htmlFor={value.id}>
                              <div className="df">
                                {
                                  value.fileName.split('.')[1] === "docx" ?
                                    (
                                      <div>
                                        <img src={docimage} alt="" />
                                      </div>
                                    )
                                    : value.fileName.split('.')[1] === "pdf" ?
                                      (
                                        <div>
                                          <img src={pdfimage} alt="" />
                                        </div>
                                      ) : (
                                        <div>
                                          <img src={imgimage} alt="" />
                                        </div>
                                      )
                                }
                                <div
                                  className="fs-semi-bold fs-14 upload_text br_word"
                                  onClick={(e) => viewTestimonal(e, value.fileUrl)}
                                >
                                  {value.fileName}
                                </div>

                              </div>
                            </label>
                          </div>


                        </div>
                        {index + 1 === testimonialData.length ?

                          <button className="btn btn-resume-testimonial mt_15 cp" onClick={(e) => openDialoue(e, value.id)}>
                            Remove File
                         </button>
                          : null
                        }
                      </div>
                    ))
                  )
                }
                {uploadTestimonialButton === true ? (
                  <FilePicker
                    extensions={["pdf", "docx", "jpg", "jpeg", "png"]}
                    onChange={uploadTestimonial}
                    onError={(errMsg) => {
                      NotificationManager.error(errMsg, "Error");
                    }}
                  >

                    {testimonialData.length > 0 ?
                      <button className="btn btn-resume-testimonial mt_15 cp">Add another Testimonial</button>
                      :
                      <button className="btn btn-resume-testimonial mt_15 cp">Upload Testimonial</button>
                    }
                  </FilePicker>
                ) : (
                  ""
                )}
              </div>
              {/* <Dialog
                open={openDeleteDialogue}
                onClose={closeDialogue}
                aria-labelledby="customized-dialog-title"
              >
                <DialogContent>
                  <div className="text-center fs-semi-bold fs-18 f-color">Are you sure want to delete ?</div>
                </DialogContent>
                <DialogActions>
                  <div className="remove_modal">
                    <button type="button" className="btn_remove btn-skill cp" onClick={closeDialogue}>No</button>
                    <button type="button" className="btn2 btn-green ml_5 cp" onClick={removeTestimonial}>Yes</button>
                  </div>
                </DialogActions>
              </Dialog> */}
              <Dialog
                open={openDeleteDialogue}
                onClose={closeDialogue}
                aria-labelledby="customized-dialog-title"
              >
                <DialogContent className={`${classes.bodyForRemove} removemodal_responsive`}>
                  <div className="text-center fs-semi-bold f-color fs-18">Are you sure want to delete ?</div>
                </DialogContent>
                <DialogActions className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}>
                  <div className="remove_modal">
                    <button type="button" className="btn_remove btn-skill cp" onClick={closeDialogue}>No</button>
                    <button type="button" className="btn2 btn-green ml_5 cp" onClick={removeTestimonial}>Yes</button>
                  </div>
                </DialogActions>
              </Dialog>
              <NotificationContainer />
            </div>
          </>
          :

          <>
            <div className="card mt_10">
              <div className="row">
                <p className="fs-semi-bold fs-18 f-color">
                  <Skeleton variant="text" animation="wave" />
                </p>
                <p className="fs-regular fs-12 f-color">
                  <Skeleton variant="text" animation="wave" />
                </p>

                <div className="df mt_6">
                  <Skeleton variant="rect" animation="wave" height={100} width={250} />
                </div>

                <p className="fs-regular fs-12 f-color mt_16">
                  <Skeleton variant="text" animation="wave" />
                </p>

                <p className="fs-regular fs-12 f-color">
                  <Skeleton variant="text" animation="wave" />
                </p>

              </div>
            </div>
          </>

      }
    </>
  );
};
export default Testimonials;
