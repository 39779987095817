import React, { useState, useEffect, useContext, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import DialogTitle from "@material-ui/core/DialogTitle";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import "./recruiterPrivateSkillAccess.css";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Header from "../../components/adminHeader/adminHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import getRecruiterPrivateTestAccessResponse from "../../apis/recruiterPrivateTestAccess/recruiterPrivateTestAccess";
import getAllRecruitersResponse from "../../apis/recruiters/getAllRecruiters";
import addRecruiterPrivateTestAccessResponse from "../../apis/recruiterPrivateTestAccess/addRecruiterPrivateTestAccess";
import updateRecruiterPrivateTestAccessResponse from "../../apis/recruiterPrivateTestAccess/updateRecruiterPrivateTestAccess";
import GetTestByRecruitmentPartner from "../../apis/recruiterPrivateSkillAccess/getTestByRecruitmentPartner";
import getAllTests from "../../apis/Tests/getAllTests";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Checkbox, Popper } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';

const RecruiterPrivateSkillAccess = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const [modalIsEditOpen, setmodalIsEditOpen] = useState(false);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [
    allRecruiterPrivateSkillAccess,
    setAllRecruiterPrivateSkillAccess,
  ] = useState([]);
  const [privateTestId, setPrivateTestId] = useState([]);
  const [testList, setTestList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [allrecruiters, setAllrecruiters] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [recruiterId, setRecruiterId] = useState("");
  const [recruiterName, setRecruiterName] = useState("");
  const [recruiter, setRecruiter] = useState([]);
  const [displayRecruiter, setDisplayRecruiter] = useState(false);
  const [allTestArr, setallTestArr] = useState([]);
  const [dropDownValue, setDropDownValue] = useState([]);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });
  const [addModel, setAddModel] = useState(false);
  const [allActiveTest, setAllActiveTest] = useState([]);
  const [recruitmentOrg, setRecruitmentOrg] = useState([]);
  const [recruiterData, setRecruiterData] = useState([]);
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const [selectRecruiterEmail, setSelectedRecruiterEmail] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState("");
  const [selectedEmailId, setSelectedEmailId] = useState([]);
  const timer = useRef(-1);
  const [atvBtn, setAtvBtn] = useState(false);



  const setOpenByTimer = (isOpen) => {
    clearTimeout(timer.current);
    timer.current = window.setTimeout(() => {
      setOpen(isOpen);
    }, 200);
  };


  const [dataResult, setDataResult] = useState(
    process(allrecruiters, dataState)
  );

  const dataStateChange = (event) => {
    setDataResult(process(allrecruiters, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {


    setDataResult(process(allrecruiters, dataState));


  }, [allrecruiters]);

  useEffect(() => {

    if (allRecruiterPrivateSkillAccess.length > 0) {

      setAllrecruiters(allRecruiterPrivateSkillAccess);

    }

  }, [allRecruiterPrivateSkillAccess, allRecruiterPrivateSkillAccess.length]);
  const getUserNameAndSkill = async (allrecruiters) => {

    let arr = [];
    if (allrecruiters.length > 0) {
      for (let index = 0; index < allrecruiters.length; index++) {
        let a = allrecruiters[index].Test.map((i) => i.testName).join();
        let name =
          allrecruiters[index]?.user?.firstName +
          " " +
          allrecruiters[index]?.user?.lastName;
        let recruitmentPartner = allrecruiters[index].Test.map(
          (item) => item?.recruitmentpartner?.name
        );
        recruitmentPartner = recruitmentPartner.filter(
          (item, index) => recruitmentPartner.indexOf(item) === index
        );
        recruitmentPartner = recruitmentPartner.filter(Boolean).join(", ");
        let customers = allrecruiters[index].Test.map(
          (item) => item?.customer?.name
        );

        customers = customers.filter(
          (item, index) => customers.indexOf(item) === index
        );
        customers = customers.filter(Boolean).join(", ");

        arr.push(
          Object.assign(
            allrecruiters[index],
            { allTests: a },
            { alluser: name },
            { recruitmentAndPartner: recruitmentPartner },
            { customer: customers }
          )
        );
      }


      setAllrecruiters(arr);

    }

  };
  // useEffect(() => {

  //   if (userType === "Admin" && isDataLoaded) {

  //     getAllrecruiterPrivateSkillAccess();

  //   }

  // }, [isDataLoaded]);
  useEffect(() => {

    (async () => {
      try {
        const allRecruiterData = await getAllRecruitersResponse();
        if (allRecruiterData.status === 200) {


          setRecruiterList(
            allRecruiterData.data.data.filter((item) => item.isApproved)
          );

        }
        let result = await getAllTests();
        result = result.data.data;
        result = result.filter((item) => item.isActive === true && item.testtype.code === "Client-Specific");

        setAllActiveTest(result);
        if (userType === "Admin")
          await getAllrecruiterPrivateSkillAccess();

      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true)
      }
    })()

  }, []);

  const getTestsData = async (recruiterPartnerId) => {
    try {
      let res = await GetTestByRecruitmentPartner(recruiterPartnerId);
      setTestList(
        res.data.data
          ? res.data.data.filter(
            (item) =>
              item.isActive === true && (item.customer === null || item.customer.isActive === true)
          )
          : []
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getAllrecruiterPrivateSkillAccess = async () => {

    let allrecruiterPrivateSkillAccess = await getRecruiterPrivateTestAccessResponse();
    if (allrecruiterPrivateSkillAccess.data.data) {

      setAllRecruiterPrivateSkillAccess(
        allrecruiterPrivateSkillAccess.data.data
      );

      getUserNameAndSkill(allrecruiterPrivateSkillAccess.data.data);
    } else {
      NotificationManager.error(
        allrecruiterPrivateSkillAccess.data.message,
        "Error",
        2500
      );
    }

  };



  const closeAddModel = () => {
    setAddModel(false);
    setRecruitmentOrg([]);
    setSelectedTestId("");
    setSelectedEmailId([]);
    setSelectedRecruiterEmail([]);
  }

  const openAddModel = () => {
    setAddModel(true);
  }

  const getRecruiterByTestId = (testId, recruiterListData) => {
    let tempRecruiterListData = recruiterListData;
    for (let i = 0; i < recruiterListData.length; i++) {
      let temprecruiterId = recruiterListData[i].id;
      let index = allrecruiters.findIndex((item) => item.recruiterid === temprecruiterId);
      if (index !== -1) {
        let testIndex = allrecruiters[index].Test.findIndex((item) => item.id === testId);
        if (testIndex !== -1) {
          tempRecruiterListData = tempRecruiterListData.filter((item) => item.id !== temprecruiterId);
        }
      }
    }
    return tempRecruiterListData;
  }

  const testChangeHandler = (e, value) => {
    if (value) {
      clearClick();
      let recruiterListData = recruiterList.filter((item) => item.recruitmentpartner && item.recruitmentpartner.id === value.recruitmentpartner?.id);
      setRecruitmentOrg(value.recruitmentpartner);
      setSelectedTestId(value.id);

      let recruiterAccessList = getRecruiterByTestId(value.id, recruiterListData);
      setRecruiterData(recruiterAccessList);
    } else {
      setRecruitmentOrg([]);
      setSelectedTestId("");
    }
  }

  const recruiterChangeHandler = (e, value) => {
    let emailIds = value.map((item) => item.email)
    setSelectedEmailId(emailIds);
    setSelectedRecruiterEmail(value);
  }


  const addAllClick = () => {
    setOpenByTimer(false);
    let emailIds = recruiterData.map((item) => item.email)
    setSelectedEmailId(emailIds);
    setSelectedRecruiterEmail(recruiterData);
  }
  const clearClick = () => {
    clearTimeout(timer.current);
    setOpenByTimer(false);
    setSelectedRecruiterEmail([]);
    setSelectedEmailId([]);
  }
  const recruiterEmailDatas = function (props) {
    return (
      <>
        <Popper {...props}>
          <div className="poppercolor">
            <Button color="primary" variant="contained" onClick={addAllClick} className="popperbutton">
              Select All
            </Button>
            <Button color="primary" variant="contained" onClick={clearClick} className="popperbutton">
              Clear All
            </Button>
          </div>
          {props.children}
        </Popper>
      </>
    );
  };



  const privateSkillChangeHandler = async (e, value) => {
    if (value) {
      setDropDownValue(value)
      setPrivateTestId([]);
      var arr = [];
      for (var i = 0; i < value.length; i++) {
        arr.push(value[i].id);
      }
      setPrivateTestId(arr);
    } else {
      setPrivateTestId([]);
      setDropDownValue([])
    }
  };


  const addRecruiterPrivateTest = async () => {
    setAtvBtn(true);

    if (selectedTestId !== "" && selectedEmailId.length > 0) {
      try {
        let payload = {
          recruiterEmailIds: selectedEmailId,
          testId: selectedTestId,
          createdBy: userIdType,
          updatedBy: userIdType,
        };
        await addRecruiterPrivateTestAccessResponse(payload);
        getAllrecruiterPrivateSkillAccess();
        NotificationManager.success(
          "Client-Specific skill added",
          "Success",
          2500
        );
        setAtvBtn(false);
        closeAddModel();
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setAtvBtn(false);
      NotificationManager.error(
        "Please fill all the  mandatory fields",
        "Error",
        2500
      );
    }
  };

  const openEditModal = (recruiterId, event) => {
    let EditData = event.dataItem;
    let userName = EditData.user.firstName + " " + EditData.user.lastName;
    setRecruiterName(userName);
    setRecruiterId(recruiterId);
    setSelectedTests(event.dataItem.Test);
    getTestsData(event.dataItem.Test[0].recruitmentpartner.id);
    setmodalIsEditOpen(true);
  };
  const closeEditModal = () => {
    setPrivateTestId([]);
    setRecruiterId("");
    setSelectedTests([]);
    setTestList([]);
    setmodalIsEditOpen(false);
  };
  const updateRecruiterPrivateSkill = async () => {
    setAtvBtn(true);
    let payload = {
      recruiterId: recruiterId,
      testId: privateTestId[0]?.id
        ? privateTestId.map((e) => e?.id)
        : privateTestId,
      createdBy: userIdType,
      updatedBy: userIdType,
    };
    try {
      await updateRecruiterPrivateTestAccessResponse(
        recruiterId,
        payload
      );
      getAllrecruiterPrivateSkillAccess();
      setAtvBtn(false);
      NotificationManager.success(
        "Client-Specific skill updated",
        "Success",
        2500
      );
      setallTestArr([]);
      setDropDownValue([]);
      setDisplayRecruiter(false);
      setRecruiter([])
    } catch (error) {
      setAtvBtn(false);
      NotificationManager.error(
        "Client-Specific skill update failed",
        "Error",
        2500
      );
    }
    setPrivateTestId([]);
    setRecruiterId("");
    setSelectedTests([]);
    setTestList([]);
    setmodalIsEditOpen(false);
  };


  const closeButton = {
    position: "absolute",
    right: "7px",
    top: "12px",
    color: "grey",
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    float: "right",
    marginTop: "20px",
  };
  const btnSavegreen = {
    width: "115px"
  };


  const getValuesEdit = () => {
    let arr = [];
    for (let i = 0; i < selectedTests.length; i++) {
      for (let j = 0; j < testList.length; j++) {
        if (selectedTests[i].id === testList[j].id) {
          arr.push(testList[j]);
        }
      }
    }
    return arr;
  };





  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <Container component={Paper} className="allignmentFix">
                    <Grid container spacing={3}>
                      <Grid item xs>
                        <h2 className="fs-semi-bold fs-30 f-color">
                          Client-Specific Test Access
                        </h2>
                      </Grid>
                      <Grid item xs>
                        <Button
                          variant="outlined"
                          style={btnGreen}
                          color="primary"
                          onClick={openAddModel}
                        >
                          Add
                        </Button>
                      </Grid>

                      <GridDiv
                        style={{
                          maxHeight: "70vh",
                          width: "100%",
                          overflow: "hidden",
                        }}
                        filter={dataState.filter}
                        filterable={true}
                        reorderable={true}
                        resizable={true}
                        pageable={{
                          buttonCount: 10,
                          info: true,
                          previousNext: true,
                          pageSizes: true,
                        }}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={dataResult}
                        onDataStateChange={dataStateChange}
                      // className="margin_grid"
                      > {!isDataLoaded && (
                        <GridNoRecords>
                          <div className='col-4'>
                            <br />
                            <Loader size='large' themeColor="info" type="converging-spinner" />
                          </div>
                        </GridNoRecords>
                      )}
                        <GridColumn
                          field="id"
                          title="Action"
                          width="90px"
                          className="actions__cell"
                          resizable={false}
                          filterable={false}
                          cell={(event) => {
                            return (
                              <td align="center">
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  onClick={(e) =>
                                    openEditModal(
                                      event.dataItem.recruiterid,
                                      event
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              </td>
                            );
                          }}
                        />
                        <GridColumn
                          field="alluser"
                          title="Recruiter Name"
                          width="200px"
                        />
                        <GridColumn
                          field="user.email"
                          title="Email"
                          width="200px"
                        />
                        <GridColumn
                          field="allTests"
                          title="Test Name"
                          width="350px"
                        />
                        <GridColumn
                          field="recruitmentAndPartner"
                          title="Recruitment Organization"
                          width="200px"
                        />
                        <GridColumn
                          field="customer"
                          title="Customer"
                          width="200px"
                        />
                      </GridDiv>
                    </Grid>
                  </Container>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/*  Add Client-Specific Modal  */}

      <Dialog
        onClose={closeAddModel}
        aria-labelledby="customized-dialog-title"
        open={addModel}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle
            id="customized-dialog-title"
            className="admindailog_title_modal"
          >
            Add Client-Specific Test Access
            <IconButton
              aria-label="close"
              style={closeButton}
              onClick={closeAddModel}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography gutterBottom>Test Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              filterSelectedOptions
              options={allActiveTest}
              getOptionLabel={(option) => option.testName || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Test"
                />
              )}
              onChange={testChangeHandler}
            />
          </FormControl>
          &nbsp;
          {recruitmentOrg.name ?
            <>
              <Typography gutterBottom>Recruitment Organization*</Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  filterSelectedOptions
                  options={recruitmentOrg}
                  value={recruitmentOrg}
                  disabled
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Recruitment Organization"
                    />
                  )}
                />
              </FormControl>
              &nbsp;
              <Typography gutterBottom>Recruiter Email*</Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  multiple
                  options={recruiterData}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.email}
                  value={selectRecruiterEmail}
                  PopperComponent={recruiterEmailDatas}
                  onOpen={(e) => {
                    setOpenByTimer(true);
                  }}
                  onClose={(e) => { setOpenByTimer(false) }}
                  open={open}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.email}
                    </>
                  )}
                  popper="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Recruiter Email"
                    />
                  )}
                  onChange={recruiterChangeHandler}
                />
              </FormControl>
            </>
            : ''}
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={closeAddModel}>
              Cancel
            </Button>

            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={addRecruiterPrivateTest}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div>
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
      </Dialog>
      {/*  Edit Client-Specific Modal  */}
      <Dialog
        onClose={closeEditModal}
        aria-labelledby="customized-dialog-title"
        open={modalIsEditOpen}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle
            id="customized-dialog-title"
            className="admindailog_title_modal"
            onClose={closeEditModal}
          >
            Edit Client-Specific Test Access
            <IconButton
              aria-label="close"
              style={closeButton}
              onClick={closeEditModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography gutterBottom>Recruiter*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              className="admin_textbox"
              defaultValue={recruiterName}
              disabled={true}
            />
          </FormControl>
          &nbsp;
          <Typography gutterBottom>Test*</Typography>
          {testList.length > 0 ? (
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                multiple
                id="combo-box-demo"
                options={testList}
                getOptionLabel={(option) => option.testName}
                defaultValue={getValuesEdit()}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Client-Specific Test"
                  />
                )}
                onChange={privateSkillChangeHandler}
              />
            </FormControl>
          ) : (
            ""
          )}
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={closeEditModal} style={btnSavegreen}>
              Cancel
            </Button>
            <button
              variant="outlined"
              style={btnSavegreen}
              className="btn3 btn_primary cp"
              color="primary"
              onClick={updateRecruiterPrivateSkill}
              disabled={atvBtn}
            >
              <div className="btn_loader">
                Update
                &nbsp;
                {atvBtn &&
                  <div>
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
      </Dialog>

      <NotificationContainer />
    </>
  );
};

export default RecruiterPrivateSkillAccess;
