import React, { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import "./questionsList.css";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { useHistory } from "react-router";
import incorrect from "../../components/profile/skillVideotest/images/incorrect.png";
import correct from "../../components/profile/skillVideotest/images/correct.png";
import { Button, CircularProgress, TextareaAutosize } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AdminHeader from "../../components/adminHeader/adminHeader";
import Checkbox from "@material-ui/core/Checkbox";
import { NavLink } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import getAllQuestionResponse from "../../apis/question/getAllQuestions";
import getSkillResponse from "../../apis/skills/getActiveSkills";
import getQuestionBySkillIdResponse from "../../apis/question/getQuestionBySkillId";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import testQuestionDescription from "../../utilities/questionSyntaxHighlight";
import Prism from "prismjs";
import { filterBy } from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn,
  GridToolbar,
  GridNoRecords
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import formatRelativeWithOptions from "date-fns/fp/formatRelativeWithOptions/index.js";
import getAllTests from "../../apis/Tests/getAllTests";
import getQuestionBytestIdResponse from "../../apis/question/getQuestionByTestId";
import getQuestionBySubSKillIdResponse from "../../apis/question/getQuestionBySubSkillId"
import { UserTypeContext } from "../../AppRoute";
import getSubskills from "../../apis/Tests/getSubskills";
import VirtualizedBox from "../../components/VirtualizedList/VirtualizedBox";
const filterSkillOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.testName,
});

const filterSubSkillOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 42px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
  fontRoboto: {
    fontFamily: "Roboto,sans-serif",
  },
  hK: {
    height: "320px",
  },
});
const initialDataState = {
  skip: 0,
  take: 10,
};
const QuestionsList = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const history = useHistory();
  const classes = useStyles();
  const jsonConfig = require("../../Config.json");
  const [questionsList, setQuestionsList] = useState([]);
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
  const [questionDialogModalValue, setQuestionDialogModalValue] = useState({});
  const [subSkillsList, setSubSkillsList] = useState([]);
  const [testList, settestList] = useState([]);
  const [loadingController, setLoadingController] = useState(false);
  const [testId, setTestId] = useState('');
  const [subSkillId, setSubSkillId] = useState('');
  const [TotalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  //const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setorder] = useState(1);
  const [skillId, setskillId] = useState("");
  const [questionStatus, setquestionStatus] = useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [newId, setNewId] = useState([]);

  const [data, setdata] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true
        }
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const filterOperators = {
    text: [
      {
        text: 'grid.filterContainsOperator',
        operator: 'contains',
      },
    ],
    numeric: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
    date: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
    boolean: [
      {
        text: 'grid.filterEqOperator',
        operator: 'eq',
      },
    ],
  };

  const [dataResult, setDataResult] = useState(process(questionsList, data));
  const [disable, setDisable] = useState({ testDropDown: false, subSkillDropDown: false });
  const [noRecordMessage, setNoRecordMessage] = useState("No records available");
  useEffect(() => {

    (async () => {
      try {

        const allTestData = await getAllTests();
        if (allTestData.status === 200) {

          settestList(allTestData.data.data);


        }
        const allSubSkillData = await getSubskills();
        if (allSubSkillData.status === 200) {
          setSubSkillsList(allSubSkillData.data.data);
        }

      } catch (error) {
        setLoadingController(true)
      } finally {
        setLoadingController(true)
      }

    })()

  }, []);
  const dataStateChange = (event) => {
    setDataResult(process(questionsList, event.dataState));
    setdata(event.dataState);
  };
  const addQuestions = () => {
    history.push("/addquestion");
  };
  const viewDialogOpen = (list) => {
    setQuestionDialogOpen(true);
    setQuestionDialogModalValue(list);
  };
  const viewDialogClose = () => {
    setQuestionDialogOpen(false);
  };
  const allQuestionsList = async (order, rowsPerPage) => {
    try {
      const allQuestionsListData = await getAllQuestionResponse(
        order,
        rowsPerPage
      );
      setQuestionsList(allQuestionsListData.data.data);
      setDataResult(process(allQuestionsListData.data.data, data));
    } catch (err) {
      console.error(err);
    }
  };
  const getQuestionBySkill = async (id, selectedDropdown) => {
    if (id) {
      try {
        let skillChangeData = [];
        if (selectedDropdown === "test") {
          setTestId(id);
          skillChangeData = await getQuestionBytestIdResponse(id);
        } else if (selectedDropdown === "subSkill") {
          setSubSkillId(id);
          skillChangeData = await getQuestionBySubSKillIdResponse(id);
        }

        let arr = [];
        let newQuesId = "";
        setQuestionsList(skillChangeData.data.data);
        setDataResult(process(skillChangeData.data.data, data));
        let skillChangeDataArr = skillChangeData?.data?.data;

        if (skillChangeDataArr.length > 0) {
          for (let i = 0; i < skillChangeDataArr.length; i++) {
            arr.push(Object.assign(skillChangeDataArr[i], {
              Id: i + 1,
            }));
            newQuesId = arr.map(e => e.Id);
          }
          setNewId(newQuesId);
        } else {
          if (selectedDropdown === "test") {
            setNoRecordMessage("No record available for selected test")
          } else if (selectedDropdown === "subSkill") {
            setNoRecordMessage("No record available for selected sub skill")
          }
        }

      } catch (err) {
        console.error(err);
      }
    }
  }

  const skillChangeHandler = async (e, value) => {
    let order = 1;
    let rowsPerPage = 10;
    // setPage(0);

    if (value) {
      setDisable({ testDropDown: false, subSkillDropDown: true })
      setskillId(value.id);
      getQuestionBySkill(value.id, "test");
    } else {
      // if (questionStatus === "") {
      //   allQuestionsList(order, rowsPerPage);
      // } else {
      //   await getQuestionBySkill(questionStatus, "", order, rowsPerPage);
      // }
      setDisable({ testDropDown: false, subSkillDropDown: false });
      setskillId("");
      setDataResult(process([], data));
      setNoRecordMessage("No records available")
    }
  };


  const subSkillChangeHandler = async (e, value) => {
    if (value) {
      setDisable({ testDropDown: true, subSkillDropDown: false });
      getQuestionBySkill(value.id, "subSkill");
    } else {
      setDisable({ testDropDown: false, subSkillDropDown: false });
      setDataResult(process([], data));
      setNoRecordMessage("No records available")
    }
  }
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   setorder(newPage + 1);
  //   // gotoSearch(newPage+1,rowsPerPage);
  //   if (skillId) {
  //     getQuestionBySkill(questionStatus, skillId, newPage + 1, rowsPerPage);
  //   } else {
  //     allQuestionsList(newPage + 1, rowsPerPage);
  //   }
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   const v = parseInt(event.target.value, 10);
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   const order = 1;
  //   // gotoSearch(order,v);

  //   setPage(0);
  //   if (skillId) {
  //     getQuestionBySkill(questionStatus, skillId, order, v);
  //   } else {
  //     allQuestionsList(order, v);
  //   }
  // };
  // const handelcallback = async (childdata) => {
  //   // setisDataLoaded(true);
  //   if (childdata != undefined) {
  //     //   alert("ok")
  //     setuserType(childdata);
  //     setLoadingController(true);
  //   }
  // };
  const QuestionStatusChangeHandler = async (e, value) => {
    setquestionStatus(value);
  };
  const allQuestionStatus = [{ isActive: "True" }, { isActive: "False" }];
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchKey(value);
  };
  const searchQuestion = () => {
    if (searchKey === "") {
      return NotificationManager.error(
        "Please fill all the mandatory fields",
        "Error",
        2500
      );
    }
    getQuestionBySkill();
  };
  // const pageChange = (event) => {
  //   setPage(event.page);
  // };
  const handleQuestion = (props) => {
    return <td>{testQuestionDescription(props.dataItem.questionDesc)}</td>;
  };

  setTimeout(() => Prism.highlightAll(), 0);

  const newFunc = (event) => {
    console.log(event);
    var arr = questionsList.filter((item, i) => item.isActive === true);
    history.push({
      pathname: `questions/${event.dataItem.id}`,
      state: {
        testId: testId,
        subSkillId: subSkillId,
        numberOfQuestionsArr: arr,
      }
    });
  }

  return (
    <>
      <AdminHeader uid={idContextTest} isDataLoaded={loadingController} />
      <div className="section-wrapper">
        <div className="tcContainer container w1200 flex-to-footer">
          <div className="height_adjust mt_100">
            {!loadingController ? (
              <div className="row circular-progress-center-question text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <>
                    <div className="question-list-section-wrapper">
                      <div className="question-list-section-heading">
                        <h2 className="fs-semi-bold fs-30 f-color">
                          Questions
                        </h2>
                      </div>

                      <div className="subkill-filter-question subkill-filter-question-addquestion">
                        <div className="subkill-filter-question-wrapper">

                          <label className="field_name" htmlFor="country">
                            Tests*
                          </label>
                          <Autocomplete
                            id="combo-box-demo"
                            className="mt_9"
                            options={testList}
                            autoHighlight
                            filterOptions={filterSkillOptions}
                            autoSelect
                            disabled={disable.testDropDown}
                            getOptionLabel={(option) => option.testName}
                            onChange={skillChangeHandler}
                            ListboxComponent={VirtualizedBox} // Use a custom listbox component
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required
                                placeholder="Select a test"
                              />
                            )}
                          />
                        </div>
                        <div className="subkill-filter-question-wrapper">

                          <label className="field_name" htmlFor="country">
                            Sub Skills*
                          </label>
                          <Autocomplete
                            id="combo-box-demo"
                            className="mt_9"
                            options={subSkillsList}
                            autoHighlight
                            filterOptions={filterSubSkillOptions}
                            autoSelect
                            disabled={disable.subSkillDropDown}
                            getOptionLabel={(option) => option.name}
                            onChange={subSkillChangeHandler}
                            ListboxComponent={VirtualizedBox} // Use a custom listbox component
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required
                                placeholder="Select a Sub Skill"
                              />
                            )}
                          />
                        </div>
                        <button
                          className="btn_question btn_primary cp mt_24"
                          onClick={() => addQuestions()}
                        >
                          Add Question
                        </button>
                      </div>

                      <KendoGrid
                        // className={classes.fontRoboto && classes.hK}
                        filter={data.filter}
                        filterable={true}
                        sort={data.sort}
                        sortable={true}
                        // groupable={true}
                        style={{
                          maxHeight: "70vh",
                          overflow: 'hidden',
                          width: "100%",
                        }}
                        filterOperators={filterOperators}
                        reorderable={true}
                        pageable={{
                          buttonCount: 10,
                          info: true,
                          previousNext: true,
                          pageSizes: true,
                        }}
                        resizable={true}
                        skip={data.skip}
                        take={data.take}
                        data={dataResult}
                        onDataStateChange={(e) => dataStateChange(e)}
                      >
                        <GridNoRecords>
                          {noRecordMessage}
                        </GridNoRecords>
                        <GridColumn
                          className={classes.fontRoboto}
                          field="Id"
                          title="Id"
                          width="50px"
                        />
                        <GridColumn
                          className={classes.fontRoboto}
                          field="questionDesc"
                          title="Question"
                          width="400px"
                          cell={handleQuestion}
                        />
                        <GridColumn
                          className={classes.fontRoboto}
                          title="Edit"
                          // sortable={formatRelativeWithOptions}
                          width="50px"
                          filterable={false}
                          cell={(event) => {
                            return (
                              <td style={{ textAlign: "center" }}>
                                <Button onClick={() => newFunc(event)}
                                  variant="contained"
                                  color="primary"
                                >Edit</Button>
                              </td>
                            );
                          }}
                        />
                        <GridColumn
                          className={classes.fontRoboto}
                          title="View"
                          width="50px"
                          filterable={false}
                          cell={(event) => {
                            return (
                              <td>
                                <ViewIcon
                                  className="cursorChange"
                                  onClick={() => viewDialogOpen(event.dataItem)}
                                />
                              </td>
                            );
                          }}
                        />
                        <GridColumn
                          className={classes.fontRoboto}
                          title="Is Active"
                          field="isActive"
                          width="50px"
                          filterable={true}
                          filter={"boolean"}
                          cell={(event) => {
                            return (
                              <td>
                                {/* <Checkbox 
                                  color="black"
                                  checked={event.dataItem?.isActive ? true : false}
                                /> */}
                                {event.dataItem?.isActive ? "True" : "False"}
                              </td>
                            );
                          }}
                        />
                      </KendoGrid>
                    </div>
                  </>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>) : (
                  <>
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        You Are Not Authorized To Visit This Page
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <>
        <Dialog
          open={questionDialogOpen}
          onClose={viewDialogClose}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            id="form-dialog-title"
            className={`${classes.title} responsive_title`}
          >
            {/* Answer Set */}
          </DialogTitle>
          <div className="answer-set-container">
            {/* <h2> Question Render</h2> */}
            <span className="fs-semi-ui-class fs-18 f-color mb_12">
              {testQuestionDescription(questionDialogModalValue.questionDesc)}
            </span>
            {questionDialogModalValue &&
              questionDialogModalValue.questionAnswer?.map((item, index) => (
                <div className="answer-set-list mb_12" key={"ans_" + index}>
                  {/* <span key={index}></span> */}
                  <FormControlLabel
                    value="disabled"
                    disabled
                    control={<Radio />}
                  />
                  <span className="fs-regular fs-16 f-color" dangerouslySetInnerHTML={{
                    __html: item.answerText
                  }}>
                    {/* {item.answerText} */}
                  </span>
                  <span className="ml_17">
                    {item.isCorrect && (
                      <>
                        {/* <img src = {correct} alt='correct'/> */}
                        <span className="fs-semi-bold fs-16 f-color correct-answer-layout">
                          Correct
                        </span>
                      </>
                    )}
                  </span>
                </div>
              ))}
            <Divider />
            <h2> Question Details</h2>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom>Subject Matter Expert:</Typography>
                <Typography gutterBottom>Created At:</Typography>
                <Typography gutterBottom>Updated At:</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography gutterBottom>
                  {" "}
                  {questionDialogModalValue.subjectMatterExpert ||
                    "SME Not Specified"}{" "}
                </Typography>
                {questionDialogModalValue?.createdAt ? (
                  <>
                    <Typography gutterBottom>
                      {new Intl.DateTimeFormat("en", { month: "long" }).format(
                        new Date(questionDialogModalValue?.createdAt)
                      ) +
                        "-" +
                        new Intl.DateTimeFormat("en", {
                          day: "2-digit",
                        }).format(
                          new Date(questionDialogModalValue?.createdAt)
                        ) +
                        "-" +
                        new Intl.DateTimeFormat("en", {
                          year: "numeric",
                        }).format(
                          new Date(questionDialogModalValue?.createdAt)
                        ) +
                        " " +
                        new Intl.DateTimeFormat("en", {
                          hour: "numeric",
                          minute: "numeric",
                        }).format(
                          new Date(questionDialogModalValue?.createdAt)
                        )}
                    </Typography>
                    <Typography gutterBottom>
                      {new Intl.DateTimeFormat("en", { month: "long" }).format(
                        new Date(questionDialogModalValue.updatedAt)
                      ) +
                        "-" +
                        new Intl.DateTimeFormat("en", {
                          day: "2-digit",
                        }).format(
                          new Date(questionDialogModalValue.updatedAt)
                        ) +
                        "-" +
                        new Intl.DateTimeFormat("en", {
                          year: "numeric",
                        }).format(
                          new Date(questionDialogModalValue.updatedAt)
                        ) +
                        " " +
                        new Intl.DateTimeFormat("en", {
                          hour: "numeric",
                          minute: "numeric",
                        }).format(new Date(questionDialogModalValue.updatedAt))}
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </div>
          <DialogContent
            className={`${classes.bodyForSupport} responsive_Support`}
          ></DialogContent>

          <DialogActions className={`${classes.buttonForSupport} `}>
            <button
              type="button"
              className="btn-big btn-skill cp"
              onClick={() => viewDialogClose()}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      </>
      <NotificationContainer />
    </>
  );
};
export default QuestionsList;
