import { useState, useCallback, useEffect } from "react";
import {
    Grid as GridDiv,
    GridColumn,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import debounce from "lodash/debounce";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import * as XLSX from "xlsx";
import { NotificationManager } from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import hiringReports from "../apis/hiringReport/hiringReport";
import { convertDate } from "./AcitivityModal/assistingFunctions";
import { codeTofield } from "./GlobalAssistingFunctions/GlobalAssistingFunctions";
const useStyles = makeStyles((theme) => ({
    odd: {
        background: "#F6F6F6 !important",
    },
    even: {
        background: "#fffff !important",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    waitingText: {
        marginLeft: "5px",
    },
}));
const HiringReport = (props) => {
    const {
        userType,
        userIdType,
        userRecruitmentpartnerId,
        startSelectedDate,
        endSelectedDate,
        filter,
        exports,
        setTriggerHiring
    } = props;

    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [isExporting, setIsExporting] = useState(false);
    const classes = useStyles();

    const [dataResult, setDataResult] = useState({});
    const [loading, setLoading] = useState(true)

    const columns = [
        { title: "Job Id (Internal)", "field": "reqreferenceId", "width": "180px" },
        { title: "Placement Type", "field": "placementType", "width": "180px" },
        { title: "Quoted Bill Rate", "field": "billRate", "width": "180px" },
        { title: "Fee", "field": "fee", "width": "180px" },
        {
            "field": "Est End Date", "width": "180px",
            cell: (e) => {
                return (
                    <td>
                        {
                           convertDate(e.dataItem.endDate)
                        }

                    </td>
                );
            },
        },
        { title: "Job Priority", "field": "priority", "width": "180px" },
        { title: "Optional Job Ref-Job Id", "field": "clientJobId", "width": "180px" },
        { title: "Job Division", "field": "divisionName", "width": "180px" },
        { title: "Department", "field": "divisionName", "width": "180px" },
        { title: "Hiring manager", "field": "spocName", "width": "180px" },
        // { title: "Hiring Email", "field": "spocEmail", "width": "180px" },
        {
            title: "Current Status", "field": "currentStatus", "width": "180px", cell: (e) => {
                return (
                    <td>
                        {
                            codeTofield(e.dataItem.currentStatus)
                        }
                    </td>
                )
            }
        },
        {
            title: "Primary Sales - Start Record", "field": "startDate", "width": "180px",
            cell: (e) => {
                return (
                    <td>
                        {
                            convertDate(e.dataItem.endDate)
                        }

                    </td>
                );
            }
        },
        { title: "Hourly Margin", "field": "hourlyMargin", "width": "180px" },
        { title: "Hourly Margin Percentage", "field": "hourlyPayRate", "width": "180px" },
        { title: "Cand Email", "field": "candEmail", "width": "180px" },
        {
            title: "Selected Date", "field": "selectedDate", "width": "180px",
            cell: (e) => {
                return (
                    <td>
                        {
                            convertDate(e.dataItem.endDate)
                        }

                    </td>
                );
            }
        },
        { title: "Client Name", "field": "clientName", "width": "180px" },
        { title: "Source", "field": "candSource", "width": "180px" },
        { title: "Agreed Bill Rate", "field": "salarySubmission", "width": "180px" },
        { title: "Final Salary", "field": "salarySelected", "width": "180px" },
        { title: "Primary Sales - Recruitment Leader", "field": "recruitmentLeader", "width": "180px" },
        { title: "TL Name", "field": "teamLeader", "width": "180px" },
        { title: "Primary Recruiter", "field": "recruiter", "width": "180px" },
        { title: "Referrer", "field": "referredBy", "width": "180px" },
    ]

    const debouncedSearch = useCallback(
        debounce(
            async (
                page,
                filter,
                startSelectedDate,
                endSelectedDate
            ) => {

                try {
                    setDataResult({});
                    let filters = {
                        "placementType": filter?.placementType || [],
                        "clientName": filter?.clientName || [],
                        "divisionId": filter?.divisionId || []
                    }

                    let payload = {
                        "orgId": userRecruitmentpartnerId,
                        "page": page.skip / page.take + 1,
                        "size": page.take,
                        "formattedDatestart": startSelectedDate,
                        "formattedDateend": endSelectedDate,
                        "isExport": false,
                        "userId": userIdType,
                        "role": userType,
                        "additionalFilter": filters
                    }

                    const search = await hiringReports(payload);

                    if (search?.totalCount[0]?.total > 0) {

                        let total = search?.totalCount[0].total
                        let data = search?.data?.map(elem =>
                        ({
                            ...elem, billRate: elem?.billRate?.maximum
                        }))

                        setDataResult({ data, total });
                    }
                    else setDataResult({ data: null })
                }
                catch (error) {
                    setDataResult({ data: null })
                    setLoading(false)

                }
                setLoading(false)
            },
            1000
        ),
        []
    );


    useEffect(() => {
        const startDate = convertDate(startSelectedDate);
        const endDate = convertDate(endSelectedDate);

        if (!startSelectedDate || !endSelectedDate || startDate > endDate) {
            setDataResult({})
            NotificationManager.error("Please enter a valid date", "Error", 2500);
        } else {
            setLoading(true)
            debouncedSearch(page, filter, startDate, endDate);
        }
    }, [page, startSelectedDate, endSelectedDate, filter]);

    useEffect(() => {
        if (exports)
            callApiToGenerateExcel()
    }, [exports])

    const callApiToGenerateExcel = async () => {
        setIsExporting(true);
        setTriggerHiring(false)
      
        let filters = {
            "placementType": filter?.placementType || [],
            "clientName": filter?.clientName || [],
            "divisionId": filter?.divisionId || []
        }

        let payload = {
            "orgId": userRecruitmentpartnerId,
            "page": 1,
            "size": 100,
            "formattedDatestart": startSelectedDate,
            "formattedDateend": endSelectedDate,
            "isExport": true,
            "userId": userIdType,
            "role": userType,
            "additionalFilter": filters
        }



        let search = await hiringReports(payload);
        if (search?.totalCount[0]?.total > 0) {

            let total = search?.totalCount[0].total
            let data = search?.data?.map(elem =>
            ({
                ...elem, billRate: elem?.billRate?.maximum
            }))

            // setDataResult({ data, total });
            search=data || []
        }
        else 
        // setDataResult({ data: null })
        search=[]


          if (search.length < 0) {
            NotificationManager.error("No data available", "Error", 2500);
            setIsExporting(false);
            return;
        }


        let exportData = search
        const ws = XLSX.utils.json_to_sheet(exportData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "hiring  report details.xlsx");

        setIsExporting(false);
    };

    const hiringReportSkeleton = () => {
        let arr = [];
        for (let i = 0; i <= 10; i++) {
            arr.push(
                <Skeleton
                    key={"HiringReportSkeleton" + i}
                    className={i % 2 === 0 ? classes.odd : classes.even}
                    variant="rect"
                    width="100%"
                    height="50px"
                />
            );
        }
        return arr;
    };

    const changePageSize = (e) => {
        if (loading)
            return
        setPage((elem) => {
            return {
                ...elem,
                take: e.dataState.take
            }
        });
    };

    const handlePageChange = (e) => {
        if (loading)
            return
        setPage({
            skip: e.page.skip,
            take: e.page.take
        });
    };


    return (
        <>
            <Backdrop className={classes.backdrop} open={isExporting}>
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}>
                    {" "}
                    Please wait ...
                </Typography>
            </Backdrop>
            <GridDiv
                filterable={false}
                ortable={true}
                resizable={true}
                reorderable={true}
                pageable={{
                    pageSizes: [10, 20, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10,
                }}
                skip={page.skip}
                take={page.take}
                data={dataResult?.data}
                total={dataResult?.total || 0}
                onPageChange={handlePageChange}
                onDataStateChange={changePageSize}
                className="kendo_reports"
            >
                <GridNoRecords>
                    {loading ? hiringReportSkeleton() : "No results found !"}
                </GridNoRecords>

                {columns && columns.map((column) => (
                    <GridColumn
                        key={column.field}
                        field={column.field}
                        // title={column.title}
                        width={column.width}
                        filterable={false}
                        title={column.title}
                        {...(column.cell && { cell: column.cell })}
                        {...(column.format && { format: column.format })}
                    />
                ))}
            </GridDiv>
        </>
    );
};


export default HiringReport
