import axios from "axios";
const jsonConfig = require("../../Config.json");

const sendEmailResponse = async (message) => {    
    let allResponse = {}
    try {
        const emailResponse = await axios.post(
            jsonConfig.emailUrl,
            message,
            {
              headers: {
                "x-functions-key": jsonConfig.bulkEmailToken,
              }
            }
          );
        
        allResponse = emailResponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error);     
        return Promise.reject(error);
    }
}

export default sendEmailResponse;