import React from 'react'
import Footer from '../adminFooter/Footer'
import './incompatibleBrowser.css'
import recruiter_header_logo from "../basicInfoForm/images/logo_recruiter_header.png";
import chromeIcon from "../basicInfoForm/images/chrome.svg";
import edgeIcon from "../basicInfoForm/images/edge.svg";
import { Grid, makeStyles, Typography } from '@material-ui/core';
const useStyles = makeStyles({
    otherTexts: {
        fontFamily: 'Manrope-Medium',
        fontSize: '17px',
        marginTop: '11px'
    },
    browserLogo: {
        height: '90px',
        width: '90px'
    },
    gridContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign:'center'
    }
})


const IncompatibleBrowser = () => {
    const goToHomePage = () => {
        window.open("/", "_self");
    };
    const classes = useStyles();
    return (
        <>
            <div className='MainContainer_INC'>
                <div className='containerTop_INC'>
                    <div className="nav_bg_home home-specific-header">
                        <div className="container w1200 nav_bg_home_section">
                            <div className="nav_bg_left">

                                <li className='cp headerListStyleNone' onClick={() => goToHomePage()}>
                                    <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                                </li>

                            </div>
                        </div>
                    </div>


                    <div className='nav_bg_home_responsive'>
                        <div className="nav_bg_home_responsive-wrapper">
                            <div className="home-top-nav">
                                <li className='cp headerListStyleNone' onClick={() => goToHomePage()}>
                                    <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                                </li>
                            </div>
                        </div>
                    </div>
                    <div className='mainDiv_top'>
                        <Grid container>
                            <Grid item xs={12} className={classes.gridContent}>
                                <span className='topHeading_inc'>Browser not Supported</span>
                            </Grid>
                            <Grid item xs={12} className={classes.gridContent}>
                                <Typography className={classes.otherTexts}>The web browser you are using is not supported by ClearedTalent.</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridContent}>
                                <Typography className={classes.otherTexts}>To improve your experience please download from a list of supported browsers</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridContent}>
                                <Typography className={classes.otherTexts}>Here are some that work.</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridContent}>
                                <div className='browserContainer_INC'>
                                    <img src={chromeIcon} className={classes.browserLogo} />
                                    <a href='https://www.google.com/intl/en_in/chrome/' className='browserLinks'>Google Chrome</a>
                                </div>
                                <div className='browserContainer_INC'>
                                    <img src={edgeIcon} className={classes.browserLogo} />
                                    <a href='https://www.microsoft.com/en-us/edge?form=MA13FJ' className='browserLinks'>Microsoft Edge</a>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className='footerContainer_inc'>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default IncompatibleBrowser