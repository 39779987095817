import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import DialogTitle from '@material-ui/core/DialogTitle';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import Divider from '@material-ui/core/Divider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import axios from 'axios';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import "./subSkill.css";
import FormControl from '@material-ui/core/FormControl';
import Header from "../../components/adminHeader/adminHeader";
import CircularProgress from '@material-ui/core/CircularProgress';
import getAllSkillsResponse from '../../apis/skills/getAllSkills';
import getSubSkillsBySkillIdResponse from '../../apis/skills/getSubSkillBySkillId';
import getSubSkillsResponse from '../../apis/skills/getSubSkills';
import addSubSkillsResponse from '../../apis/skills/addSubSkills';
import updateSubSkillsResponse from '../../apis/skills/updateSubSkills';
import addSkillSubSkillStructureResponse from '../../apis/skills/addSkillSubSkillStructure';
import { process } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-grid";
import { Grid as KendoGrid, GridNoRecords } from "@progress/kendo-react-grid";
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';
const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.name,
});

function SubSkill({ idContextTest }) {
    const { userType, userIdType } = useContext(UserTypeContext);
    // const jsonConfig = require("../../Config.json");
    const [allActivePrimarySkill, setallActivePrimarySkill] = useState([]);
    // const [allSubSkillBySkillId, setAllSubSkillBySkillId] = useState([]);
    const [openAddModal, setopenAddModal] = useState(false);
    // const [skillName, setskillName] = useState("");
    const [skillId, setSkillId] = useState("");
    const [subSkillName, setsubSkillName] = useState("");
    const [openEditModal, setopenEditModal] = useState(false);
    const [isLinkSubSkillModalOpen, setisLinkSubSkillModalOpen] = useState(false);
    const [activeStateForLink, setactiveStateForLink] = useState(Boolean);
    const [subSkillForLink, setsubSkillForLink] = useState({});
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [editSubSkillModalValue, seteditSubSkillModalValue] = useState({});
    const [SubSkillNameForEdit, setSubSkillNameForEdit] = useState("");
    const [allSubSkill, setallSubSkill] = useState([]);
    const [skillIdForLink, setskillIdForLink] = useState("");
    const [allSubSkillForCard, setallSubSkillForCard] = useState([]);
    // const [subSkillForAdd, setsubSkillForAdd] = useState({});
    const [subSkillId, setsubSkillId] = useState("");
    const [atvBtn, setAtvBtn] = useState(false);

    // creating filter for kendoGrid
    const [dataState, setDataState] = React.useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    // fetching data(allSubSkillForCard) from database , changing state of data using useState (starts) 

    const [result, setResult] = useState(allSubSkillForCard);
    const dataStateChange = (event) => {
        setResult(process(allSubSkillForCard, event.dataState));
        setDataState(event.dataState);
    }
    useEffect(() => {


        setResult(process(allSubSkillForCard, dataState));

    }, [allSubSkillForCard]);
    useEffect(() => {
        const fetchAllData = async () => {
            try {
                await getAllSkills();
                await getAllSubSkills()
            } catch (error) {
                setisDataLoaded(true);
            } finally {
                setisDataLoaded(true);
            }
        }
        if (userType === "Admin") {
            fetchAllData();

        } else {
            setisDataLoaded(true);
        }

        fetchAllData();

    }, []);
    // (ends)


    const getAllSkills = async () => {

        try {
            const SkillResponse = await getAllSkillsResponse();
            if (SkillResponse.status === 200) {


                setallActivePrimarySkill(SkillResponse.data.data)

            }
        }
        catch (error) {
            console.log(error);
        }


    }

    const getAllSubSkillsBySkillId = async (skillid) => {

        try {
            const getAllSubSkillsBySkillIdResponse = await getSubSkillsBySkillIdResponse(skillid);
            // const getAllSubSkillsBySkillIdResponse = await axios.get(
            //     jsonConfig.apiUrl + "subskillbyskillidadmin/" + skillid, {
            //     headers: {
            //         "x-functions-key": jsonConfig.key,
            //     },
            // }
            // );


            // setAllSubSkillBySkillId(getAllSubSkillsBySkillIdResponse.data.data);
            setallSubSkillForCard(getAllSubSkillsBySkillIdResponse.data.data);

        } catch (error) {

            // setAllSubSkillBySkillId([])
            setallSubSkillForCard([])

        }

    }
    const getAllSubSkills = async () => {

        try {
            const getAllSkillsResponse = await getSubSkillsResponse();
            //    const getAllSkillsResponse=await axios.get(
            //         jsonConfig.apiUrl + "subSkills" , {
            //         headers: {
            //             "x-functions-key": jsonConfig.key,
            //         },
            //     }
            //     );

            setallSubSkill(getAllSkillsResponse.data.data)
            setallSubSkillForCard(getAllSkillsResponse.data.data)


        } catch (error) {
            setallSubSkillForCard([])
        }

    }
    const openAdd = () => {
        setopenAddModal(true);

    }
    const closeAddModal = () => {
        setopenAddModal(false);
    }
    const subSkillChange = (e) => {
        const regex = /^\s*$/g
        const subSkillText = e.target.value
        if (subSkillText === '' || regex.test(subSkillText)) {
            setsubSkillName('')
        }
        else if (subSkillText) {
            setsubSkillName(subSkillText)
        }
    }
    const saveSubSkill = async () => {
        setAtvBtn(true);
        if (subSkillName === '') {
            setAtvBtn(false);
            return NotificationManager.error('Please add a sub-skill')
        }
        try {
            var payload = {
                "name": subSkillName.trim(),
                // "skillId": skillId,
                "createdBy": userIdType,
                "updatedBy": userIdType
            }
            const addSubSkill = await addSubSkillsResponse(payload);
            // const addSubSkill = await axios.post(
            //     jsonConfig.apiUrl + "subSkills", payload, {
            //     headers: {
            //         "x-functions-key": jsonConfig.key,
            //     },
            // }
            // );
            if (addSubSkill.data.message?.includes('This Subskill name already')) {
                setAtvBtn(false);
                return (
                    NotificationManager.error('The sub-skill already exists in the system. Please use a different sub-skill name', 'Error')
                )
            }

            getAllSubSkills();
            // setsubSkillForAdd({})
            NotificationManager.success('Sub-skill  added', 'Success');
            setAtvBtn(false);
            closeAddModal();

        } catch (error) {
            NotificationManager.error('Sub-Skill addition failed', 'Error', 2500);
            setAtvBtn(false);
        }
    }

    // for edit subSkill
    const openEdit = (valueFromEdit) => {
        setopenEditModal(true)
        seteditSubSkillModalValue(valueFromEdit)
        setSubSkillNameForEdit(valueFromEdit.name)
        if (valueFromEdit.subSkillId) {
            setsubSkillId(valueFromEdit.subSkillId)
        }
        else {
            setsubSkillId(valueFromEdit.id)
        }
    }
    const subSkillChangeHandlerForEdit = (e) => {
        setSubSkillNameForEdit(e.target.value)
    }
    // save updated skill
    const updateSkill = async () => {
        setAtvBtn(true);
        var payload = {
            "name": SubSkillNameForEdit,
            "skillId": editSubSkillModalValue.skillId,
            "updatedBy": userIdType
        }
        try {
            await updateSubSkillsResponse(subSkillId, payload);
            // const updateSubskillResponse = await axios.put(
            //     jsonConfig.apiUrl + "subSkills/" + subSkillId, editSubSkillData, {
            //     headers: {
            //         "x-functions-key": jsonConfig.key,
            //     },
            // }
            // );
            if (skillId) {
                await getAllSubSkillsBySkillId(skillId)
            }
            else {
                await getAllSubSkills()
            }
            setAtvBtn(false);

            NotificationManager.success('Sub-Skill updated', 'Success', 2500);
            setopenEditModal(false)
        } catch (error) {
            NotificationManager.error('Sub-Skill update failed', 'Error', 2500);
            setAtvBtn(false);
        }
    }
    const closeEditModal = () => {
        setopenEditModal(false)
    }
    // const subSkillChangeHandler = (e, value) => {
    //     if (value) {
    //         subSkillChangeHandler(value)
    //     }
    // }
    // for link subskill
    // const openLinkSubSkillModal = async () => {

    //     setisLinkSubSkillModalOpen(true)
    // }
    // close LinkSub Skill Modal
    const closeLinkSubSkillModal = () => {
        setisLinkSubSkillModalOpen(false)
    }
    // const activeHandlerForLink = (e, value) => {
    //     if (value) {
    //         setactiveStateForLink(value.name)
    //     }
    // }
    const subSkillChangeHandlerForLink = (e, value) => {
        if (value) {
            setsubSkillForLink(value)
        }
    }
    const SkillChangeHandlerForLink = (e, value) => {
        if (value) {
            setskillIdForLink(value.id)
        }
    }
    const LinkSubSkill = async () => {
        var payload =
        {
            "code": subSkillForLink.name,
            "name": subSkillForLink.name,
            "skillId": skillIdForLink,
            "subSkillId": subSkillForLink.id,
            "isActive": activeStateForLink,
            "createdBy": userIdType,
            "updatedBy": userIdType
        }

        await addSkillSubSkillStructureResponse(payload);
        // const linkSubSkillResponse = await axios.post(
        //     jsonConfig.apiUrl + "skillsubskillstructure", linkSubskilldata, {
        //     headers: {
        //         "x-functions-key": jsonConfig.key,
        //     },
        // }
        // );
        NotificationManager.success('The selected skill and subskill are linked', 'Success', 2500);
        setisLinkSubSkillModalOpen(false)
    }



    const btnGreen = {
        color: '#fff',
        backgroundColor: '#1885e6',
        border: 'none',
        padding: '7px 23px 7px 23px',
        float: 'right',
        top: "20px",

    };
    const closeButton = {
        position: 'absolute',
        right: 0,
        top: 0,
        color: 'grey',
    };
    const btnSavegreen = {
        color: '#fff',
        backgroundColor: '#2AD156',
        border: '1px solid #2AD156',
    };
    // const isActive = [
    //     { "isActive": true },
    //     { "isActive": false }
    // ]

    // editing button on click
    const handleEdit = (props) => {
        return (
            <td className="actionContainer">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openEdit(props.dataItem)}
                >Edit
                </Button>
            </td>
        );
    };

    return (
        <>
            <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
            <div className='section-wrapper'>
                <div className="container w1200 flex-to-footer">
                    <div className="height_adjust mt_110">
                        {!isDataLoaded ?
                            <div className="row padding_80 text-center">
                                <CircularProgress />
                            </div>
                            :
                            <>
                                {
                                    userType === "Admin" ?
                                        <Container component={Paper} className="alignment">
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                    <h2 className='fs-semi-normal fs-30 f-color marginHeading'>Sub-Skills</h2>
                                                </Grid>
                                                <div className="admin_subskill_topsection">
                                                    <div className="admin_subskill_leftsection">
                                                    </div>
                                                    <div className="admin_subskill_rightsection">
                                                        <Button variant="outlined" style={btnGreen} onClick={openAdd} >
                                                            Add Sub-Skill
                                                        </Button>

                                                    </div>

                                                </div>
                                                <Grid item xs={12} md={12}>
                                                </Grid>
                                                {/* kendo table */}
                                                <KendoGrid
                                                    className='kw'
                                                    filter={dataState.filter}
                                                    resizable={true}
                                                    filterable={true}
                                                    sort={dataState.sort}
                                                    pageable={{
                                                        buttonCount: 10,
                                                        info: true,
                                                        previousNext: true,
                                                        pageSizes: true,
                                                    }}
                                                    style={{
                                                        maxHeight: "70vh",
                                                        width: "100%",
                                                        overflow: 'hidden',
                                                    }}
                                                    skip={dataState.skip}
                                                    take={dataState.take}
                                                    data={result}
                                                    onDataStateChange={(e) => dataStateChange(e)}
                                                >{!isDataLoaded && (
                                                    <GridNoRecords>
                                                        <div className='col-4'>
                                                            <br />
                                                            <Loader size='large' themeColor="info" type="converging-spinner" />
                                                        </div>
                                                    </GridNoRecords>)}
                                                    <GridColumn
                                                        title='Edit'
                                                        width='40px'
                                                        resizable={true}
                                                        filterable={false}
                                                        cell={handleEdit}
                                                    />
                                                    <GridColumn
                                                        field='name'
                                                        title='Sub-Skill'
                                                        width='200px'
                                                        resizable={true}
                                                        filterable={true}
                                                    />
                                                </KendoGrid>
                                            </Grid>
                                            <NotificationContainer />
                                        </Container>
                                        : userType === "" ? (
                                            <div className="row padding_80 text-center">
                                                <p className="fs-semi-bold fs-40 f-color">
                                                    Loading...
                                                </p>
                                            </div>
                                        )
                                            : (<div className="row padding_80 text-center">
                                                <p className="fs-semi-bold fs-40 f-color">You Are Not Authorized To Visit This Page</p>
                                            </div>)
                                }
                            </>
                        }
                    </div>
                </div>
            </div>

            <Dialog
                onClose={closeAddModal}
                aria-labelledby="customized-dialog-title"
                open={openAddModal}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}
            >
                <DialogTitle id="customized-dialog-title"
                    onClose={closeAddModal}
                    className="admindailog_title_modal"
                >
                    Add Sub-Skill
                    <IconButton
                        aria-label="close"
                        style={closeButton}
                        onClick={closeAddModal}>
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent >
                    <Typography gutterBottom>
                        Sub-Skill
                    </Typography>

                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Enter Sub-Skill"
                            className="admin_textbox"
                            onChange={(e) => subSkillChange(e)}
                        />
                    </FormControl>
                </DialogContent>
                <Grid item xs={12} md={12} className="user_pop_button" >
                    <DialogActions>
                        <Button variant="outlined" className="btn3 cp" color="primary" onClick={closeAddModal}>
                            Cancel
                        </Button>

                        <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={saveSubSkill}>
                            <div className="btn_loader">
                                Save
                                &nbsp;
                                {atvBtn &&
                                    <div >
                                        <CircularProgress size={13} />
                                    </div>
                                }
                            </div>
                        </button>
                    </DialogActions>
                </Grid>
            </Dialog>




            {/* modal  for edit*/}

            <Dialog
                onClose={closeEditModal}
                aria-labelledby="customized-dialog-title"
                open={openEditModal}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}
            >
                <DialogTitle id="customized-dialog-title"
                    onClose={closeEditModal}
                    className="admindailog_title_modal"
                >
                    Edit Sub-Skill
                    <IconButton
                        aria-label="close"
                        style={closeButton}
                        onClick={closeEditModal}>
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent className="userlist_dailog_content">
                    {/* <Grid item xs={8} md={8} style={{ backgroundColor: "#D1E8FF" }}>
                        <Typography variant="body1" component="h2" style={{ padding: "10px" }}>
                            Primary Skill:{skillName}
                        </Typography>

                    </Grid>
                    &nbsp; */}
                    <Typography gutterBottom>
                        Sub-Skill
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Name"
                            className="admin_textbox"
                            defaultValue={SubSkillNameForEdit}
                            onChange={subSkillChangeHandlerForEdit}
                        />
                    </FormControl>

                </DialogContent>
                <Grid item xs={12} md={12} className="user_pop_button" >
                    <DialogActions>
                        <Button variant="outlined" color="primary" className="btn3  cp" onClick={closeEditModal}>
                            Cancel
                        </Button>

                        <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={(e) => updateSkill()}>
                            <div className="btn_loader">
                                Save
                                &nbsp;
                                {atvBtn &&
                                    <div>
                                        <CircularProgress size={13} />
                                    </div>
                                }
                            </div>
                        </button>
                    </DialogActions>
                </Grid>
            </Dialog>

            {/* to link subskill in skillsubskill */}
            <Dialog
                onClose={closeLinkSubSkillModal}
                aria-labelledby="customized-dialog-title"
                open={isLinkSubSkillModalOpen}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}
            >
                <DialogTitle id="customized-dialog-title"
                    onClose={closeLinkSubSkillModal}
                    className="admindailog_title_modal"
                >
                    Link Sub-Skill
                    <IconButton
                        aria-label="close"
                        style={closeButton}
                        onClick={closeLinkSubSkillModal}>
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent className="userlist_dailog_content">
                    <Grid item xs={12} md={12}>
                        <Typography gutterBottom>
                            Sub-Skill
                        </Typography>
                        <Autocomplete
                            autoSelect
                            id="combo-box-demo"
                            options={allSubSkill}
                            getOptionLabel={(option) => option.name}
                            filterOptions={filterOptions}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Sub-Skill" />}


                            onChange={
                                (e, value) => subSkillChangeHandlerForLink(e, value)
                            }
                        />
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography gutterBottom>
                            Skill
                        </Typography>
                        <Autocomplete
                            autoSelect
                            id="combo-box-demo"
                            options={allActivePrimarySkill}
                            getOptionLabel={(option) => option.name}
                            filterOptions={filterOptions}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Skill" />}


                            onChange={
                                (e, value) => SkillChangeHandlerForLink(e, value)
                            }
                        />
                    </Grid>

                </DialogContent>
                <Grid item xs={12} md={12} className="user_pop_button" >
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={closeLinkSubSkillModal}>
                            Cancel
                        </Button>
                        <Button variant="outlined" style={btnSavegreen} color="primary" onClick={(e) => LinkSubSkill()}>
                            Save
                        </Button>
                    </DialogActions>
                </Grid>
            </Dialog>





        </>
    )
}

export default SubSkill;
