import React, { useState, useRef, useEffect } from 'react';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';
import Logo from '../../images/logo.png';
import HomeContactUsModal from '../homeContactUsModal/homeContactUs';
import { loginRequest, signUpRequest } from "../../../../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useHistory } from "react-router";
const Header = () => {
  const history = useHistory();
  const { instance, accounts } = useMsal();
  const hamburger = useRef(0);
  const [open, setOpen] = useState(false);
  const activeClass = useRef(0);
  const gotoEmployer = () => {
    history.push('/demo', "_self");

  }
  const goToHomePage = () => {
    history.push('/', "_self");
  }
  const openSupportModal = () => {
    setOpen(true);
  }

  const _modalClose = () => {
    setOpen(false);
  }
  const hamburgerClicked = () => {
    const x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
  const signIn = async () => {
    await history.push('/');
    await instance.loginRedirect(loginRequest);
  }
  const gotoSignin = () => {
    history.push('/signIn');
  }
  const goToRecruiterSignUp = () => {
    history.push('/recruiter-signup')
  }
  const goToReferral = () => {
    history.push('/referral')
  }

  return (
    <>
      <div className="nav_bg_home home-specific-header">
        <div className="container w1200 nav_bg_home_section">
          <div className="nav_bg_left">

            <li className='cp' onClick={() => goToHomePage()} style={{ listStyle: 'none' }}>
              <a><img src={Logo} className="nav_logo_home" alt='img' /></a>
            </li>

          </div>
          <div className="nav_bg_right">
            <ul className="navbar_home">
              {/* <li onClick={() => instance.loginRedirect(signUpRequest)}> */}
              <NavLink to='basic-info-form'>
                {/* <a href rel='noreferrer' target="_blank"> */}
                <button type="button" className="btn-home btn-start">Register & Take Test
                </button>
                {/* </a> */}
              </NavLink>
              <li onClick={gotoSignin} className={window.location.href.includes('signIn') ? "active-link special_hover cp" : "special_hover cp"} >
                <a>Sign-In</a>
              </li>
              <li onClick={goToRecruiterSignUp} className={window.location.href.includes('recruiter-signup') ? "active-link special_hover cp" : "special_hover cp"} >
                <a>Customer Sign-Up</a>
              </li>
              <li className={window.location.href.includes('employer') ? "active-link special_hover cp" : "special_hover cp"} onClick={() => gotoEmployer()}
                ref={activeClass}
              >
                <a>Request Demo</a>
              </li>
              <li onClick={goToReferral} className={window.location.href.includes('referral') ? "active-link special_hover cp" : "special_hover cp"}>
                <a>Referrals</a>
              </li>
              <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>


      <div className='nav_bg_home_responsive'>
        <div className="nav_bg_home_responsive-wrapper">
          <div className="home-top-nav">

            <li className='cp' onClick={() => goToHomePage()} style={{ listStyle: 'none' }}>
              <a><img src={Logo} className="nav_logo_home" alt='img' /></a>
            </li>

            <div id="myLinks" ref={hamburger}>
              <ul className="navbar_home-responsive">
                <li>
                  <NavLink to='basic-info-form'><button type="button" className="btn-home btn-start">Register & Take Test</button></NavLink>
                </li>
                <li onClick={() => history.push('/signIn')} className={window.location.href.includes('signIn') ? "active-link special_hover cp" : "special_hover cp"} >
                  <a>Sign-In</a>
                </li>
                <li onClick={goToRecruiterSignUp} className= "special_hover cp" >
                  <a>Customer Sign-Up</a>
                </li>
                <li className={window.location.href.includes('employer') ? "active-link special_hover cp" : "special_hover cp"} onClick={() => gotoEmployer()}
                  ref={activeClass}
                >
                  <a>Request Demo</a>
                </li>
                <li onClick={goToReferral} className={window.location.href.includes('referral') ? "active-link special_hover cp" : "special_hover cp"}>
                  <a>Referrals</a>
                </li>
                <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
              </ul>
            </div>
            <a className="icon" onClick={hamburgerClicked}>
              <i className="fa fa-bars"></i>
            </a>
          </div>
        </div>
      </div>
      <HomeContactUsModal
        modalOpen={open}
        modalClose={_modalClose}
      />
    </>
  )
}
export default Header