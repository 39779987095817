import axios from "axios";
const jsonConfig = require("../../Config.json");

const companyMySkill = async (data) => {
  let allResponse = {}
    try {
        const response = await axios.post(
            jsonConfig.apiUrl + "CompanyMySkill", data,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }
          );
        
        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error);     
        return Promise.reject(error);
    }
}

export default companyMySkill;