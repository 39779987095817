import React, { useState, useEffect, useRef } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import "./registeredStartTest.css";
import outlineSvg from "../profile/otherSkills/images/outline.svg";
import fillSvg from "../profile/otherSkills/images/fill.svg";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Timer from "react-timer";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router";
import Logo from "../../components/header/images/logo.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import formatQuestionWithCode from "../../utilities/formatQuestionWithCode";

import createB2cMapping from "../../apis/b2cMapping/createB2cMapping";
import createProfile from "../../apis/profile/createProfile";
import createMySkill from "../../apis/skills/createMyskill";
import userTestFeedback from "../../apis/userTest/userTestFeedback";
import getSkillBySkillId from "../../apis/skills/getSkillBySkillId";
import getUserTestDynamicQuestion from "../../apis/userTest/getUserTestDynamicQuestion";
import createUserTestRequest from "../../apis/userTest/createUserTest";
import createUserRequest from "../../apis/users/createUser";
import getUserTestQuestionAnswer from "../../apis/userTest/userTestQuestionAnswerByTestId";
import putUserTestQuestionAnswerResponse from "../../apis/userTest/putUserTestQuestionAnswerByTestId";
import sendEmailResponse from "../../apis/email/sendEmail";
import updateAbortedTest from "../../apis/userTest/updateAbortedTest";
import updateTestTakenResponse from "../../apis/userTest/updateTestTaken";
import testQuestionDescription from "../../utilities/questionSyntaxHighlight";
import Prism from "prismjs";
import insertIntoMyCandidate from "../../apis/userTest/insertCandidateInMyCandidate";
import postUserTestQuestionResponse from "../../apis/userTest/postUserTestQuestion";
import getAllMailforRecruiterResponse from "../../apis/recruiters/getAllMailForRecruiter";
import emailConfigurationByCodeResponse from "../../apis/email/emailConfigurationByCode";
import generateUserCredentials from "../../apis/users/generateUserCredentials";
import additionalTestDetails from "../../apis/userTest/additionalTestDetails";
import getAllReferralSkiilsByRecruiterPartners from "../../apis/referral/referralbyrecruitmentpartner";
import Draggable from "react-draggable";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import getAllQuestionDetailsDynamic from '../../apis/userTest/getAllQuestionDetailsDynamic'
import NextIcon from "../../components/basicInfoForm/images/nextIcon.svg";
import insertIntoCandidateResumeMappingPowerResume from "../../apis/candidateResume/insertIntoCandidateResumeMappingPowerResume";
import createCandidateProfileAtSelfTest from "../../apis/candidateResume/createCandidateProfileAtSelfTest";

import "./style_RgTest.css";
import putUserTestDetails from "../../apis/userTest/putUserTestDetails";
import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import recruiter_sign_thankyou_icon from "../../components/basicInfoForm/images/successBlueTick.png";
import { Mic, Videocam } from "@material-ui/icons";
import myCandidate from "../../apis/recruiters/myCandidate";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
      float: "left",
    },
    "& input": {
      padding: "15px",
    },
  },
  spinnerclass: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  buttonAlignForRemove: {
    padding: "32px!important",
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important",
  },
  LinearProgress: {
    height: "18px",
    width: "260px",
    backgroundColor: "transparent",
    border: "0.5px solid #0397DA",
    borderRadius: "10px",
    zIndex: 2,
    position: "absolute",
    opacity: 0.8,
  },
  LinearProgress1: {
    height: "18px",
    width: "260px",
    backgroundColor: "transparent",
    border: "0.5px solid #0397DA",
    borderRadius: "10px",
    position: "absolute",
    opacity: 0.8,
    zIndex: 1,
  },
  progrssLabel_ans: {
    fontFamily: "Manrope-Regular",
    fontSize: "14px",
    color: "#0397DA",
    letterSpacing: "1",
  },
  progrssLabel_viewed: {
    fontFamily: "Manrope-Regular",
    fontSize: "14px",
    color: "#0397DA",
    letterSpacing: "1",
    opacity: "0.8",
  },
  progrssLabel_total: {
    fontFamily: "Manrope-Regular",
    fontSize: "14px",
    letterSpacing: "1",
    opacity: "0.6",
  },
  testQuestAnsCard: {
    margin: "0 11%",
    marginTop: "10px",
    height: "66vh",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
  },
  rightDiv: {
    paddingLeft: "30px",
    paddingRight: "20px",
  },
  quesCardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "625px",
  },
  nextQ: {
    fontFamily: "Manrope-Semibold",
    fontSize: "26px",
    color: "#1885E6",
    marginTop: "-5px",
  },
  topVideo: {
    height: "90%",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  grdidContentCenter: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  absoluteGrid: {
    position: "absolute",
  },
  progressGap: {
    marginTop: "20px",
  },
  cardAfterQuestion: {
    margin: "0 5%",
    marginTop: "120px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
    minHeight: "66vh",
    marginBottom: "15px",
  },
}));

const RegisteredStartTest = (props) => {
  const { location } = props;
  const pageName = location.state?.pageName;
  const recruiterPartnerId = location?.state?.recruitmentpartnerId;

  const [userId, setuserId] = useState("");
  const classes = useStyles();
  const jsonConfig = require("../../Config.json");
  const OPTIONS = {
    direction: "backward",
    prefix: "seconds elapsed!",
    delay: 100,
  };
  const history = useHistory();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [radioButtonChecked, setRadioButtonChecked] = useState(null);
  const [preInvterviewState, setPreInterviewState] = useState(true);
  const [errorAlert, setErrorAlert] = useState(false);
  const [processAnime, setProcessAnime] = useState(false);
  const [cheatAlert, setCheatAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [mins, setMins] = useState("");
  const [postInterviewState, setPostInterviewState] = useState(false);
  const [questionTimer, setQuestionTimer] = useState(5);
  const [userQuestions, setUserQuestions] = useState([]);
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [skillSets, setSkillSets] = useState("");
  const [timerOn, setTimerOn] = useState(false);
  const [isUserTestAvailable, setIsUserTestAvailable] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [testOnGoing, setTestOnGoing] = useState(false);
  const [testOnGoingModal, setTestOnGoingModal] = useState(false);
  const userCameraPermissionRef = useRef(false);
  const videoRef = useRef(null);
  const userCameraStreamRef = useRef(null);
  const streamRecorderRef = useRef(null);
  const currentQuestionIndexRef = useRef(0);
  const isTestCompletedRef = useRef(false);
  const currentTimeoutIdRef = useRef(null);
  const userTestIdRef = useRef("");
  const userIdRef = useRef("");
  const userQuestionsRef = useRef([]);
  const startGivingTestRef = useRef(false);
  const userAnswersResponseRef = useRef({});
  const uploadedQuestionCounterRef = useRef(0);
  const cheatCounter = useRef(0);
  const [star, setStar] = useState("");
  const [comment, setComment] = useState("");
  const [starToggle, setStarToggle] = useState(true);
  const [isreviewSubmited, setisreviewSubmited] = useState(false);
  const [isEmailSend, setisEmailSend] = useState(false);
  const spaceRef = useRef(null);
  const [finishStatus, setfinishStatus] = useState(false);
  const [referralProgram, setReferralProgram] = useState("");
  const [candidateResumeIds, setCandidateResumeIds] = useState("");
  const regex = /^\s*$/g;
  const emptySpaceValidate = regex.test(comment);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm(
          "Exiting during a test will be recorded & retesting will not be allowed. Would you like to exit?"
        )
      ) {
        var testId = userTestIdRef.current;
        var payload = {
          isAborted: true,
        };
        try {
          let inviteResponse = updateAbortedTest(testId, payload);
          updateTestTaken(
            props.location.state.pageName.replace("/invite/", ""),
            false
          );
          setTestOnGoing(false);
          userAnswerSave();
          stopRecording();
          clearTimeout(currentTimeoutIdRef.current);
          userCameraStreamRef.current
            .getTracks()
            .forEach((track) => track.stop());
          setPostInterviewState(true);
          setSuccessAlert(true);
          getUserTestDetails();
          setfinishStatus(true);
        } catch (error) {
          if (localStorage.getItem("token")) {
            window.location.pathname = "/landing";
          }
        }
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onUnload = (event) => {
    event.returnValue = " Are you sure you want to exit?";
  };

  const createUserData = async () => {
    if (!props.location.state.userId) {
      const userDataRequest = {
        roleId: jsonConfig.candidateRoleId,
        firstName: props.location.state.firstName,
        lastName: props.location.state.lastName,
        MiddleInitial: props.location.state.MiddleInitial,
        phoneNumber: props.location.state.phoneNumber,
        countryCode: props.location.state.countryCode,
        email: props.location.state.email,
        password: props.location.state.password,
        createdBy: "NewUser",
        updatedBy: "NewUser",
        isActive: true,
      };

      try {
        const createUser = await createUserRequest(userDataRequest);


        const user_id = createUser?.data?.data?.id;
        setuserId(user_id);
        userIdRef.current = createUser.data?.data?.id;
        if (userIdRef.current) {
          await b2cMapping(createUser.data?.data?.id);
          await profileCreated(createUser.data?.data?.id);
          await AddPrimarySkill(createUser.data?.data?.id);
          // await generateUserCredentials({createUser.data?.data?.id})
          sendUserEmail(createUser.data?.data?.id)
          // await goToTestQuestions();
          if (!pageName) {
            //first time self test
            await insertIntoMyCandidate(createUser.data?.data?.id);
            const candidateResumePayload = {
              powerResumeId: createUser.data?.data?.id,
              email: createUser.data?.data?.email,
              recruitmentpartnerId: null,
              uploadedType: "Candidate Upload",
              uploadedBy: createUser.data?.data?.id,
              isReferred: 0,
              skillId: props.location.state.skillId,
              testType: "self"
            }
            let candidateResumeResponse = await insertIntoCandidateResumeMappingPowerResume(candidateResumePayload)
            setCandidateResumeIds(candidateResumeResponse.data)
            console.log("UserID ---->", userId);
            console.log("Props Location State");
          }
          else {
            const inviteId = props?.location?.state?.inviteId
            const recruiterId = props?.location?.state?.recruiterId
            if (inviteId && recruiterId) {
              //first time invidetest
              await MyCandidatePost(inviteId, true, user_id);
              let profileId = user_id ? user_id : location?.state?.userId;
              console.log(props.location.state.skillId, "nnnnn")
              const candidateResumePayload = {
                powerResumeId: profileId,
                email: "",
                recruitmentpartnerId: location?.state?.recruitmentpartnerId,
                uploadedType: "Recruiter Upload",
                uploadedBy: props?.location?.state?.recruiterId,
                isReferred: 0,
                skillId: props.location.state.skillId,
                testType: "invited"
              }
              let candidateResumeResponse = await insertIntoCandidateResumeMappingPowerResume(candidateResumePayload)
              setCandidateResumeIds(candidateResumeResponse.data)
            }

          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      //poweResume aldready created
      userIdRef.current = props.location.state.userId;

      // console.log("")

      let questionResponse = await getSkillTestQuestions(props.location.state.allTestId);
      const inviteId = props?.location?.state?.inviteId
      const recruiterId = props?.location?.state?.recruiterId
      let is_invited_by_recruiter = false;
      let profileId = location?.state?.userId;
      if (inviteId && recruiterId) {
        await MyCandidatePost(inviteId, true);
        is_invited_by_recruiter = true;
      }
      if (questionResponse.data.message) {
        if (userCameraStreamRef.current) {
          await userCameraStreamRef.current.getTracks().forEach((track) => {
            track.stop();
            if (localStorage.getItem("token")) {
              window.location.pathname = "/landing";
            } else {
              history.push("/");
            }
          });
        }
        return NotificationManager.error(
          "Question Bank For This Skill Is Being Updated – Please Try Later",
          "Error",
          3000
        );
      } else {
        await createUserTest(
          props.location.state.userId,
          props.location.state.test.skillId,
          props.location.state.test.id
        );
        await postUserInitialAnswerResponse(props.location.state.userId);
        if (is_invited_by_recruiter) {
          console.log(props.location.state.skillId, "ghhhhhh")
          const candidateResumePayload = {
            powerResumeId: profileId,
            email: "",
            recruitmentpartnerId: location?.state?.recruitmentpartnerId,
            uploadedType: "Recruiter Upload",
            uploadedBy: props?.location?.state?.recruiterId,
            isReferred: 0,
            skillId: props.location.state.skillId,
            testType: "invited",
          }
          let candidateResumeResponse = await insertIntoCandidateResumeMappingPowerResume(candidateResumePayload)
          setCandidateResumeIds(candidateResumeResponse.data)
        } else {
          const candidateResumePayload = {
            powerResumeId: profileId,
            email: location?.state?.email,
            uploadedType: "Candidate Upload",
            uploadedBy: profileId,
            isReferred: 0,
            testType: "self",
          }
          let candidateResumeResponse = await createCandidateProfileAtSelfTest(candidateResumePayload)


          setCandidateResumeIds(candidateResumeResponse.data)

        }
        setSpinnerStatus(false);
        setIsDataLoaded(true);
      }
    }
  };

  const b2cMapping = async (id) => {
    const b2cMappingPayload = {
      email: props.location.state.email,
      b2cid: null,
      userid: id,
      isCreated: false,
      createdBy: id,
      updatedBy: id,
    };
    try {
      const b2cMappingData = await createB2cMapping(b2cMappingPayload);
    } catch (err) {
      console.log(err);
    }
  };

  const profileCreated = async (id) => {
    const profileCreatedPayload = {
      id: id,
      locationCountry: props.location.state.locationCountry,
      locationState: props.location.state.locationState,
      locationCity: props.location.state.locationCity,
      b2cId: null,
      createdBy: id,
      updatedBy: id,
    };
    try {
      const profileCreatedData = await createProfile(profileCreatedPayload);
    } catch (err) {
      console.log(err);
    }
  };
  const AddPrimarySkill = async (id) => {
    const skillData = {
      skillId: props.location.state.skillId,
      profileId: id,
      isPrimary: true,
      createdBy: id,
      updatedBy: id,
      subSkillsJson: props.location.state.subSkillsJson,
    };

    try {
      const primarySkillResponse = await createMySkill(skillData);
      await createUserTest(
        id,
        primarySkillResponse.data.data.skillId,
        props.location.state.test.id
      );
      await getSkillTestQuestions(props.location.state.allTestId);
      await postUserInitialAnswerResponse(id);

      await skillSet(primarySkillResponse.data.data.skillId);
      setSpinnerStatus(false);
      setIsDataLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fnstarRatingCaluculation = () => {
    const starArr = [
      outlineSvg,
      outlineSvg,
      outlineSvg,
      outlineSvg,
      outlineSvg,
    ];
    setStar(starArr);
  };
  const [starRating, setstarRating] = useState(0);
  const starClicked = (clickIndex) => {
    setStarToggle((prev) => !prev);
    const starArr = [
      outlineSvg,
      outlineSvg,
      outlineSvg,
      outlineSvg,
      outlineSvg,
    ];
    if (starToggle && clickIndex) {
      const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
      setStar(arr);
      setstarRating(clickIndex + 1);
    } else {
      const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
      setStar(arr);
      setstarRating(clickIndex + 1);
    }
  };
  const reviewSubmit = async () => {
    if (starRating === 0 || starRating === null) {
      NotificationManager.error("Select a rating", "Error");
      return;
    }

    const reviewSubmitPayLoad = {
      candidateTestRating: starRating,
      candidateComment: comment,
    };
    try {
      const reviewSubmitResponse = await userTestFeedback(
        userTestIdRef.current,
        reviewSubmitPayLoad
      );
      setisreviewSubmited(true);
      if (referralProgram[0]?.isActive === true && recruiterPartnerId !== undefined) {
        setTimeout(() => {
          goToReferralPage();
        }, 3000);
      }
      if (recruiterPartnerId === undefined) {
        setTimeout(() => {
          // goToReferralPage();
          goToProfilePage()
        }, 3000);
      }
      if (referralProgram.length === 0 && recruiterPartnerId !== undefined) {
        setTimeout(() => {
          goToReferralPage();
        }, 3000);
      }
      if (props.location.state.userId) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openReferral = async () => {
    await goToReferralPage();
  }

  useEffect(() => {
    fnstarRatingCaluculation();
    // Show alert on close tab / browser
    window.addEventListener("beforeunload", onUnload);

    //cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  // useEffect(() => {
  //   if (userId === undefined) {
  //     history.push("/");
  //   }
  // }, [userId]);

  // Go To Start Questions
  const goToTestQuestions = async () => {
    setSpinnerStatus(true);
    await initializeUserCamera();

    await initializeUserCameraRecorder();
    if (userCameraPermissionRef.current) {
      await createUserData();
      if (streamRecorderRef.current) {
        startRecording();
        firstQuestionStart();
      }
    } else {
      if (!props.location.state.userId) {
        var redirectPageName = !props.location.state.pageName
          ? "/test-landing"
          : props.location.state.pageName;
        history.push({
          pathname: redirectPageName,
          state: {
            ...props.location.state,
            text: `Please click on the lock icon at the top left of the browser's address bar and allow permissions for camera and microphone.
          `,
          },
        });
      } else {
        history.push({
          pathname: "/profile",
          state: {
            ...props.location.state,
            text: `Please click on the lock icon at the top left of the browser's address bar and allow permissions for camera and microphone.
          `,
          },
        });
      }

      return NotificationManager.error(
        "This test cannot be taken without activating camera and microphone. Please allow camera and microphone access.",
        "Error",
        3000
      );
    }
  };

  const skillSet = async (skillid) => {
    const skillSet = await getSkillBySkillId(skillid);
    setSkillSets(skillSet.data.data[0]);
  };
  // Remove Error Alert
  const handleClose = () => {
    setErrorAlert(false);
  };

  const handleSuccessClose = () => {
    setSuccessAlert(false);
  };

  // Start video recording
  const startRecording = () => {
    if (userQuestionsRef.current.length > 0) {
      const streamRecorder = streamRecorderRef.current;
      if (streamRecorder && streamRecorder.state === "inactive") {
        streamRecorder.start();
      }
    }
  };

  // Stop video recording
  const stopRecording = () => {
    if (userQuestions.length > 0) {
      const streamRecorder = streamRecorderRef.current;
      if (streamRecorder && streamRecorder.state === "recording") {
        streamRecorder.stop();
      }
    }
  };

  // Initialize user camera
  const initializeUserCamera = async () => {
    if (!userCameraStreamRef.current) {
      const getWebcamStream = async () => {
        const webcamStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        userCameraStreamRef.current = webcamStream;
        if (
          webcamStream.getVideoTracks().length > 0 &&
          webcamStream.getAudioTracks().length > 0
        ) {
          userCameraPermissionRef.current = true;
        } else {
          setErrorAlert(true);
        }
      };
      try {
        await getWebcamStream();
      } catch (error) {
        setErrorAlert(true);
      }
    }
  };

  // Initialize user camera recorder
  const initializeUserCameraRecorder = async () => {
    if (userCameraStreamRef.current) {
      const options = {
        audioBitsPerSecond: 64000,
        videoBitsPerSecond: 500000,
        mimeType: "video/webm; codecs=vp8,opus",
      };
      if (!streamRecorderRef.current) {
        streamRecorderRef.current = new MediaRecorder(
          userCameraStreamRef.current,
          options
        );
        if (streamRecorderRef.current) {
          streamRecorderRef.current.onerror = function (event) {
            console.error(event);
          };
          streamRecorderRef.current.ondataavailable = async function (event) {
            const tempQuestionIndex = uploadedQuestionCounterRef.current;
            uploadedQuestionCounterRef.current =
              uploadedQuestionCounterRef.current + 1;
            if (userAnswersResponseRef.current.question[tempQuestionIndex]) {
              await uploadVideoToBlobStorage(
                event.data,
                userAnswersResponseRef.current.question[tempQuestionIndex]
                  .questionid,
                tempQuestionIndex
              );
            }

          };
        }
      }
    }
  };

  // Upload video to storage
  const uploadVideoToBlobStorage = async (
    videoBlob,
    questionId,
    questionIndex
  ) => {
    const sas = jsonConfig.sasToken;
    const bsClient = new BlobServiceClient(
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClient = await bsClient.getContainerClient(
      "videointerviewsession"
    );
    const blobName =
      userIdRef.current +
      "/" +
      userTestIdRef.current +
      "/" +
      questionId +
      ".webm";
    const blobClient = await containerClient.getBlockBlobClient(blobName);
    await blobClient.uploadBrowserData(videoBlob, {
      blobHTTPHeaders: {
        blobContentType: "video/webm",
      },
    });
  };

  // User answer handler
  const onRadioChange = (e) => {
    let userOption = e.target.value;
    userAnswersResponseRef.current.question[
      currentQuestionIndexRef.current
    ].answers = [];
    userAnswersResponseRef.current.question[
      currentQuestionIndexRef.current
    ].answers.push({
      answer: userOption,
      submittedData: "",
    });
    setRadioButtonChecked(e.target.value);
  };

  // Set question timer
  const addQuestionTimeout = () => {
    setTimerOn(true);
    setUserQuestions(userQuestionsRef.current);
    const timeLimit =
      userQuestionsRef.current[currentQuestionIndexRef.current]?.timeLimit;
    const tempTimeLimit = +timeLimit;
    const actualTimeLimit = tempTimeLimit * 1000;
    if (actualTimeLimit) {
      const second = actualTimeLimit / 1000;
      setMins(second);
    }
    setQuestionTimer(actualTimeLimit);
    if (currentTimeoutIdRef.current) {
      clearTimeout(currentTimeoutIdRef.current);
    }
    currentTimeoutIdRef.current = setTimeout(() => {
      const nextButton = document.getElementById("toggle-question");
      if (nextButton) {
        nextButton.click();
      }
    }, actualTimeLimit);
  };
  // minute second calculation

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      (minutes < 10 ? "0" : "") +
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds
    );
  };

  useEffect(() => {
    let interval = null;
    if (timerOn) {
      interval = setInterval(() => {
        setQuestionTimer((prevTime) => prevTime - 1000);
      }, 1000);
    } else if (!timerOn) {
      clearInterval(interval);
    }
  }, [timerOn]);

  const getReferralProgramDetails = async () => {
    try {
      const res = await getAllReferralSkiilsByRecruiterPartners(
        recruiterPartnerId
      );
      setReferralProgram(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (props.location.state) {
      goToTestQuestions();
    }
    getReferralProgramDetails();
  }, []);

  // Start first question
  const firstQuestionStart = () => {
    setUserQuestions(userQuestionsRef.current);
    startGivingTestRef.current = true;
    setPreInterviewState(false);
    if (videoRef.current) {
      if (videoRef.current.srcObject !== userCameraStreamRef.current) {
        videoRef.current.srcObject = userCameraStreamRef.current;
      }
      videoRef.current.play();
      videoRef.current.volume = 0;
    }
    addQuestionTimeout();
  };

  // Question handler
  const goToNextQuestion = async (e) => {
    setTimerOn(true);
    const wrapper = document.getElementsByClassName("react-timer");
    const actualWrapper = wrapper[0];
    const timerLabel = actualWrapper.children[0];
    const timeStringValue = timerLabel.innerHTML;
    const timerCalc = document.getElementsByClassName("timer-calc");
    userAnswerTimer(timeStringValue);
    const resetButton = actualWrapper.children[2];
    resetButton.click();
    setRadioButtonChecked(null);
    // answer-checks
    let inputCheckboxes = document.querySelectorAll(".answer-checks");
    for (let i = 0; i < inputCheckboxes.length; i++) {
      inputCheckboxes[i].checked = false;
    }
    if (
      currentQuestionIndexRef.current + 1 ===
      userQuestionsRef.current.length
    ) {
      // updateTestTaken(props.location.state.pageName?.replace("/invite/",""),true)
      isTestCompletedRef.current = true;
      setTestOnGoing(false);
      userAnswerSubmit();
      stopRecording();
      clearTimeout(currentTimeoutIdRef.current);
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
      setPostInterviewState(true);
      setSuccessAlert(true);
    } else {
      userAnswerSave();
      stopRecording();
      currentQuestionIndexRef.current = currentQuestionIndex + 1;
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      addQuestionTimeout();
      startRecording();
    }
  };

  const goToProfilePage = async (e) => {
    if (testOnGoing) {
      await onBackButtonEvent(e);
    } else {
      history.push("/");
    }
  };
  const stopAndGoProfile = async () => {
    await userCameraStreamRef.current
      .getTracks()
      .forEach((track) => track.stop());
    if (localStorage.getItem("token")) {
      window.location.pathname = "/landing";
    } else {
      history.push("/");
    }
  };

  // Start Skill Test Server
  const getSkillTestQuestions = async (allTestIds) => {

    const skillTestResponse = await getAllQuestionDetailsDynamic(allTestIds);

    if (skillTestResponse.data.data) {
      setUserQuestions([...skillTestResponse.data.data]);
      userQuestionsRef.current = skillTestResponse.data.data;
    }

    return skillTestResponse;
  };

  // Create User Test
  const createUserTest = async (userId, skillId, testId) => {
    let payload = {
      user: userId,
      industry: jsonConfig.userIndustryId,
      skill: skillId,
      isVideoAvailable: true,
      createdBy: userId,
      testId: testId,
    };
    const createTestResponse = await createUserTestRequest(payload);
    setTestOnGoing(true);
    userTestIdRef.current = createTestResponse.data.data.id;
  };

  // Post User Response
  const postUserInitialAnswerResponse = async (userId) => {
    const blobStorageAccount = jsonConfig.blobStorageAccountName;
    let tempUserQuestion = [];
    tempUserQuestion = userQuestionsRef.current;
    const questionTempArr = [];
    tempUserQuestion.forEach((el, index) => {
      const tempObjData = {
        questionid: el.id,
        userTimeTaken: "0",
        questionNumber: index + 1,
        videoURL:
          `${jsonConfig.ctResourcesCdn}/videointerviewsession/` +
          userId +
          "/" +
          userTestIdRef.current +
          "/" +
          tempUserQuestion[index].id +
          ".webm",
        createdBy: el.createdBy,
        updatedBy: el.updatedBy,
        answers: [],
      };
      questionTempArr.push(tempObjData);
    });
    const tempResponseData = {
      usertest: userTestIdRef.current,
      updatedBy: userId,
      question: questionTempArr,
      isCompleted: false,
    };
    userAnswersResponseRef.current = tempResponseData;
    const userInitialResponseLocal = await postUserTestQuestionResponse(
      tempResponseData
    );
    const postUserQuestionTempResponse = userInitialResponseLocal.data.data;
    postUserQuestionTempResponse.forEach((el, index) => {
      userAnswersResponseRef.current.question[index].id = el.id;
    });
  };

  // On change textbox
  const handleCheckbox = (event, answerId) => {
    if (event.target.checked === true) {
      userAnswersResponseRef.current.question[
        currentQuestionIndexRef.current
      ].answers.push({
        answer: answerId,
        submittedData: "",
      });
    } else {
      var uncheckedArrElemIndex = -1;
      for (let i = 0; i < userAnswersResponseRef.current.question[currentQuestionIndexRef.current].answers.length; i++) {
        if (userAnswersResponseRef.current.question[currentQuestionIndexRef.current].answers[i].answer === answerId &&
          userAnswersResponseRef.current.question[currentQuestionIndexRef.current].answers[i].submittedData === "") {
          uncheckedArrElemIndex = i;
          break;
        }
      }
      if (uncheckedArrElemIndex > -1) {
        userAnswersResponseRef.current.question[
          currentQuestionIndexRef.current
        ].answers.splice(uncheckedArrElemIndex, 1);
      }
    }
  };

  // Put user testtoken
  const updateTestTaken = async (invitedId, payloadVal) => {
    var payload = {
      testTaken: payloadVal,
    };
    try {
      await updateTestTakenResponse(invitedId, payload);
    }
    catch (e) {
      console.log(e);
    }
  };

  const MyCandidatePost = async (invitedId, payloadVal, user_Id) => {
    var payload = {
      candidateid: user_Id ? user_Id : location?.state?.userId,
      recruiterid: location?.state?.recruiterId,
      recruitmentpartnerId: location?.state?.recruitmentpartnerId
    }
    try {
      const myCandidatePost = await myCandidate(payload);
    } catch (error) {
      console.log(error);
    }
  }



  // Put user answer save
  const userAnswerSave = async () => {
    let result = await putUserTestQuestionAnswerResponse(
      userTestIdRef.current,
      userAnswersResponseRef.current
    );
    if (result.status === 201) {
      const inviteId = props?.location?.state?.inviteId
      const recruiterId = props?.location?.state?.recruiterId;
      // const userId = props.location.state.userId
      let isInvited = false;
      // let isManyCandidateProfileId = false
      if (inviteId && recruiterId) {
        isInvited = true;
      }
      // if(userId && inviteId && recruiterId){
      //   isManyCandidateProfileId = true
      // }

      await putUserTestDetails(
        userTestIdRef.current,
        userAnswersResponseRef.current,
        result.data.data,
        isInvited,
        candidateResumeIds
      )
    }

  };

  // Submit user answer save
  const userAnswerSubmit = async () => {
    userAnswersResponseRef.current.isCompleted = true;

    let result = await putUserTestQuestionAnswerResponse(
      userTestIdRef.current,
      userAnswersResponseRef.current
    );

    await getUserTestDetails();


    const inviteId = props?.location?.state?.inviteId
    let isInvited = false;
    if (inviteId) {
      await updateTestTaken(inviteId, true);
      isInvited = true;
    }
    if (result.status === 201) {

      await putUserTestDetails(
        userTestIdRef.current,
        userAnswersResponseRef.current,
        result.data.data,
        isInvited,
        candidateResumeIds
      )
    }

  };
  // get user test detail
  const getUserTestDetails = async () => {
    try {
      const userTestDetails = await getUserTestQuestionAnswer(
        userTestIdRef.current
      );
      const tempUsersTestDetails = userTestDetails.data.data;
      let userTestScore = 0;
      tempUsersTestDetails.forEach((el) => {
        if (el.isCorrect) {
          userTestScore = userTestScore + 1;
        }
      });
      await getAllMailforRecruiter(userTestScore, tempUsersTestDetails.length);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMailforRecruiter = async (userTestScore, noOfQuestion) => {
    let skillTestEmails = [];
    try {
      var getByCodeandCountryData = {
        code: "RecruitmentTeam",
        country: JSON.parse(props.location.state.locationCountry).name,
      };
      const response = await getAllMailforRecruiterResponse(
        getByCodeandCountryData
      );
      response.data.data[0].emails.forEach(function (emailId) {
        skillTestEmails.push(emailId);
      });
    } catch (error) {
      console.log(error);
    }
    try {
      var codeForMail = {
        code: "CTTestResultEmail",
      };
      const getTestResultEmail = await emailConfigurationByCodeResponse(
        codeForMail
      );
      getTestResultEmail.data.data[0].emails.forEach(function (emailId) {
        skillTestEmails.push(emailId);
      });
    } catch (error) {
      console.log(error);
    }
    await recruiterEmail(skillTestEmails, userTestScore, noOfQuestion);
    // if(!props?.location?.state?.isCandidateExist){
    //   await sendUserEmail();
    // }
  };

  // user email after test

  const sendUserEmail = async (userId) => {
    const data = {
      userid: userId,
    };
    if (jsonConfig.sendEmail) {
      try {
        const sendUserEmailResponse = await generateUserCredentials(data);
      } catch (error) {
        console.log("email send error", error);
      }
    }
  };

  // email that will send to recruiter
  const recruiterEmail = async (
    allrecruiterEmail,
    userTestScore,
    noOfQuestion
  ) => {
    if (!props.location.state.userId) {
      if (jsonConfig.sendEmail) {
        const message = {
          to: allrecruiterEmail,
          from: jsonConfig.sendGridFromEmail,
          subject:
            "Skill Test on " +
            props.location.state.test.testName +
            " completed by " +
            props.location.state.firstName +
            " " +
            props.location.state.lastName +
            ", " +
            JSON.parse(props.location.state.locationCountry).name,
          html: `<p>Email: ${props.location.state.email}</p><p>Test: ${props.location.state.test.testName
            }</p><p>Skill: ${skillSets.name}</p><p>Location: ${props.location.state.locationCity
              ? JSON.parse(props.location.state.locationCity).name
                ? JSON.parse(props.location.state.locationCity).name + ","
                : ""
              : ""
            } 
            ${props.location.state.locationState
              ? JSON.parse(props.location.state.locationState).name + ","
              : ""
            } 
            ${JSON.parse(props.location.state.locationCountry).name
            }</p><p>Test Score: ${userTestScore}/${noOfQuestion}
            </p>The Skill Test Link is: <a href="https://${window.location.host
            }/test/${userTestIdRef.current}" >https://${window.location.host
            }/test/${userTestIdRef.current} <a/>
        `,
        };
        let recruterEmailResponse = await sendEmailResponse(message);
        setisEmailSend(true);
      }
    } else {
      setisEmailSend(true);
    }
  };

  // Save User Answer Time
  const userAnswerTimer = (timeString) => {
    const tempTimeArr = timeString.split(":");
    let timeVal =
      tempTimeArr[0] * 3600 +
      tempTimeArr[1] * 60 +
      Number(tempTimeArr[2].split(".")[0]);
    userAnswersResponseRef.current.question[
      currentQuestionIndexRef.current
    ].userTimeTaken = timeVal.toString();
    userAnswersResponseRef.current.question[
      currentQuestionIndexRef.current
    ].questionNumber = currentQuestionIndexRef.current + 1;
  };

  const handleCheatClose = () => {
    setCheatAlert(false);
  };

  const handleCheatOpen = () => {
    setCheatAlert(true);
  };

  const browserDetection = () => {
    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) != -1
    ) {
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    } else if (
      navigator.userAgent.indexOf("MSIE") != -1 ||
      !!document.documentMode == true
    ) {
      //IF IE > 10
    } else {
    }
  };

  function checkFocus(cheatTimer) {
    //Better Cheat Detectoion Fires Every Time It Seems , Global Variable or callback needed
    if (document.hasFocus()) {
      return cheatTimer;
    } else {
      cheatTimer = cheatTimer + 1;
      return cheatTimer;
    }
  }
  var cheatTimer = 0;
  // Handle question card index
  useEffect(() => {
    currentQuestionIndexRef.current = currentQuestionIndex;
  }, [currentQuestionIndex]);

  // To Set Questions Use Effect
  useEffect(() => {
    setUserQuestions(userQuestionsRef.current);
  }, [userQuestionsRef.current]);

  useEffect(() => {
    setInterval(checkFocus(cheatTimer), 300);
    document.addEventListener("visibilitychange", async (event) => {
      let status = event.target.hidden;
      if (status == false) {
          if (isTestCompletedRef.current === false) {
            cheatCounter.current = cheatCounter.current + 1;
            handleCheatOpen();
            const payload = {
              userId: userTestIdRef.current,
              data: {
                NumberofViolations: cheatCounter.current,
              },
            };
            const updateUsertestsResponse = await additionalTestDetails(
              payload
            );
          }
      }
    });
    browserDetection();
  }, []);
  setTimeout(() => Prism.highlightAll(), 0);


  // Timer style
  const timerStyle = {
    fontSize: "100%",
    padding: "0",
  };

  // Timer Wrapper Style
  const timerWrapperStyle = {
    display: "inline-block",
    margin: "0",
  };

  // Question Divider Style
  const questionCardDividerStyle = {
    height: "320px",
  };

  window.onload = async function () {
    window.open("/", "_self");
  };
  const goToReferralPage = () => {
    if (referralProgram.length === 0 && recruiterPartnerId !== undefined) {
      history.push({
        pathname: "/referral",
      });
    } else {
      history.push({
        pathname: "/referral",
        state: {
          recruiterPartnerId,
        },
      });
    }
  };
  return (
    <>
      <div className="mainContainer_Test">
        <div className="nav_bg_home home-specific-header">
          <div className="container w1200 nav_bg_home_section">
            <div className="nav_bg_left">
              <li className="cp headerListStyleNone">
                <a>
                  <img
                    src={recruiter_header_logo}
                    className="nav_logo_home"
                    alt="img"
                    onClick={(e) => goToProfilePage(e)}
                  />
                </a>
              </li>
            </div>
          </div>
        </div>
        <div className="nav_bg_home_responsive">
          <div className="nav_bg_home_responsive-wrapper">
            <div className="home-top-nav">
              <li className="cp headerListStyleNone">
                <a>
                  <img
                    src={recruiter_header_logo}
                    className="nav_logo_home"
                    alt="img"
                    onClick={(e) => goToProfilePage(e)}
                  />
                </a>
              </li>
            </div>
          </div>
        </div>
        {preInvterviewState === false && postInterviewState === false ? (
          <>
            <Snackbar
              open={cheatAlert}
              autoHideDuration={6000}
              onClose={handleCheatClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={handleCheatClose} severity="error">
                Violation Detected and Reported
              </Alert>
            </Snackbar>
          </>
        ) : (
          ""
        )}
        {isDataLoaded ? (
          <></>
        ) : (
          <div className="containerfor_circularprogress">
            <CircularProgress />
          </div>
        )}
        {preInvterviewState === false && postInterviewState === false ? (
          <>
            <div className="testHeader_Test">
              <div className="testInfoContainer">
                <span className="testNameHeader">
                  {props.location.state.test.testName}
                </span>

                <div className="timeleftContainer">
                  <div className="timeLeft">
                    <span>Time Left</span>
                    <span className="timeCount">
                      <span>
                        <Timer
                          style={timerStyle}
                          options={OPTIONS}
                          initialTime={questionTimer}
                          direction="forward"
                        />
                      </span>
                      <span className="timer-calc">
                        {millisToMinutesAndSeconds(questionTimer)}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="progressBarContainer">
                  <Grid container>
                    <Grid item xs={12} className={classes.absoluteGrid}>
                      <LinearProgress
                        variant="determinate"
                        value={
                          (currentQuestionIndex * 100) / userQuestions.length
                        }
                        maxvalue={0}
                        className={classes.LinearProgress}
                      />
                      <LinearProgress
                        variant="determinate"
                        id="backProgress"
                        value={
                          ((currentQuestionIndex + 1) * 100) /
                          userQuestions.length
                        }
                        className={classes.LinearProgress1}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.progressGap}
                      >
                        <Grid item xs={4}>
                          <div className="progressLabel_allignment_start">
                            <Typography className={classes.progrssLabel_ans}>
                              {currentQuestionIndex}
                            </Typography>
                            <Typography className={classes.progrssLabel_ans}>
                              Answered
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="progressLabel_allignment_center">
                            <Typography className={classes.progrssLabel_viewed}>
                              {currentQuestionIndex + 1}
                            </Typography>
                            <Typography className={classes.progrssLabel_viewed}>
                              Viewed
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="progressLabel_allignment_end">
                            <Typography className={classes.progrssLabel_total}>
                              {userQuestions.length}
                            </Typography>
                            <Typography className={classes.progrssLabel_total}>
                              Total
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="videoCam_Drag">
              <Draggable>
                <div className="videoScreen_main">
                  <video className={classes.topVideo} ref={videoRef} />
                  <div className="bottomDiv_video">
                    <Videocam />
                    <Mic className="micIcon" />
                  </div>
                </div>
              </Draggable>
            </div>
            <Card className={classes.testQuestAnsCard}>
              <div className="mainTestContainer">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="questionContainer">
                      <Grid container>
                        <Grid item xs={2} container spacing={4}>
                          <div className="questionNoContainer">
                            <span className="questionNo">
                              {currentQuestionIndex + 1}
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={10}>
                          <div className="questionNameContainer">
                            <span className="questionName" id="testQuestionDescp">
                              {userQuestions.length > 0 ? (
                                <>
                                  {testQuestionDescription(
                                    userQuestions[currentQuestionIndex + 1 - 1]
                                      .questionDesc
                                  )}
                                </>
                              ) : (
                                <p>No questions found</p>
                              )}
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} className={classes.rightDiv}>
                    <Grid container>
                      <Grid item xs={12} md={3}></Grid>
                      <Grid item xs={12} md={9}>
                        <Grid container>
                          <Grid item xs={12}>
                            <div className="answerHeading">
                              <span>Please select the right answer</span>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            {userQuestions[currentQuestionIndex + 1 - 1]
                              ?.questionAnswertype?.code === "SingleChoice"
                              ? userQuestions[currentQuestionIndex + 1 - 1]
                                ? userQuestions[
                                  currentQuestionIndex + 1 - 1
                                ]?.questionAnswer.map((options, i) => (
                                  <div
                                    className="questionoption-alignment_card questionoption "
                                    key={i}
                                  >
                                    <label
                                      className="container_radio_skillTest"
                                      htmlFor={options.id}
                                    >
                                      <div className="block-answer-wrapper">
                                        {options.answerText ? (
                                          options.answerText.split("\n")
                                            .length === 1 ? (
                                            <p className="without-linebreak" dangerouslySetInnerHTML={{
                                              __html: options.answerText
                                            }}></p>
                                          ) : (
                                            options.answerText
                                              .split("\n")
                                              .map((item, idx) => (
                                                <p
                                                  key={idx}
                                                  className="block-answer"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item
                                                  }}
                                                ></p>
                                              ))
                                          )
                                        ) : options.answerText.split("\\n")
                                          .length === 1 ? (
                                          <p className="without-linebreak" dangerouslySetInnerHTML={{
                                            __html: options.answerText
                                          }}>
                                          </p>
                                        ) : (
                                          options.answerText
                                            .split("\\n")
                                            .map((item, idx) => (
                                              <span
                                                key={idx}
                                                className="block-answer"
                                                dangerouslySetInnerHTML={{
                                                  __html: item
                                                }}
                                              ></span>
                                            ))
                                        )}
                                      </div>
                                      <input
                                        type="radio"
                                        name="radio-group"
                                        id={options.id}
                                        checked={
                                          radioButtonChecked === options.id
                                        }
                                        value={options.id}
                                        key={options.id}
                                        onChange={onRadioChange}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                ))
                                : null
                              : userQuestions[currentQuestionIndex + 1 - 1]
                                ? userQuestions[
                                  currentQuestionIndex + 1 - 1
                                ].questionAnswer.map((options, i) => (
                                  <form key={i} className="questionoption-alignment_card">
                                    <label
                                      className="container_skillTest"
                                      htmlFor={options.id}
                                    >
                                      <div className="block-answer-wrapper">
                                        {options.answerText ? (
                                          options.answerText.split("\n")
                                            .length === 1 ? (
                                            <p key={i} className="without-linebreak" dangerouslySetInnerHTML={{
                                              __html: options.answerText
                                            }}></p>
                                          ) : (
                                            options.answerText
                                              .split("\n")
                                              .map((item, idx) => (
                                                <p
                                                  key={idx}
                                                  className="block-answer"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item
                                                  }}
                                                >
                                                </p>
                                              ))
                                          )
                                        ) : options.answerText.split("\\n")
                                          .length === 1 ? (
                                          <p className="without-linebreak"
                                            dangerouslySetInnerHTML={{
                                              __html: options.answerText
                                            }}
                                          >
                                          </p>
                                        ) : (
                                          options.answerText
                                            .split("\\n")
                                            .map((item, idx) => (
                                              <span
                                                key={idx}
                                                className="block-answer"
                                                dangerouslySetInnerHTML={{
                                                  __html: item
                                                }}
                                              >
                                              </span>
                                            ))
                                        )}
                                      </div>
                                      <input
                                        type="checkbox"
                                        id={options.id}
                                        key={options.id}
                                        value={options.id}
                                        onClick={(event) =>
                                          handleCheckbox(event, options.id)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </form>
                                ))
                                : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div className="nextButtonContainer">
                <div
                  className="bottomContainer_quesCard"
                  id="toggle-question"
                  onClick={(e) => goToNextQuestion(e)}
                >
                  <Typography className={classes.nextQ}>
                    {currentQuestionIndex + 1 === userQuestions.length
                      ? "End Test"
                      : "Next Question"}
                  </Typography>
                  <img src={NextIcon} />
                </div>
              </div>
            </Card>
          </>
        ) : null}
        {preInvterviewState === false && postInterviewState === true ? (
          <>
            {!isreviewSubmited ? (
              <>
                {!isEmailSend ? (
                  <>
                    <Card className={classes.cardAfterQuestion}>
                      <div className="cardContainer_review">
                        <p className="f-color fs-30 fs-semi-bold mb_16 text-center">
                          Please wait while we submit your test result
                        </p>
                        <div className="containerfor_circularPrg">
                          <CircularProgress />
                        </div>
                      </div>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card className={classes.cardAfterQuestion}>
                      <div className="cardContainer_review">
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            className={classes.grdidContentCenter}
                          >
                            <span className="fs-semi-bold fs-16 f-color text-center">
                              Thank you for taking the skill test. The test
                              result has been sent to your reviewer.
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.grdidContentCenter}
                          >
                            <span className="fs-semi-bold fs-16 f-color text-center mb_12">
                              Please provide your rating and feedback for the
                              test.
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={classes.grdidContentCenter}
                          >
                            <p className="mt_12 end-test-star">
                              {star &&
                                star.map((image, index) => (
                                  <img
                                    src={image}
                                    alt="starImage"
                                    className="cp"
                                    key={index}
                                    onClick={() => starClicked(index)}
                                  />
                                ))}
                            </p>
                          </Grid>
                          <Grid item md={4} xs={1}></Grid>
                          <Grid item md={4} xs={10}>
                            <legend>Comment</legend>
                            <textarea
                              type="text"
                              className="text_area_skillTest"
                              placeholder=""
                              rows={4}
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                            <div className="SubmitButtonWrapper">
                              <button
                                className="btn btn-green_form cp"
                                onClick={() => reviewSubmit()}
                              >
                                Submit
                              </button>
                            </div>
                          </Grid>
                          <Grid item md={4} xs={1}></Grid>
                          {recruiterPartnerId !== undefined &&
                            recruiterPartnerId !== null ? (
                            referralProgram[0]?.isActive === true ||
                              referralProgram.length === 0 ? (
                              <Grid
                                item
                                xs={12}
                                className={classes.grdidContentCenter}
                              >
                                <a className="registerstartTestRefferal">
                                  We are hiring! Please click <span onClick={() => openReferral()} className="registerstartTestcolor"
                                  ><u><i>here</i></u></span> to refer people in your network .
                                </a>
                              </Grid>
                            ) : (
                              <></>
                            )
                          ) : <></>}
                        </Grid>
                      </div>
                    </Card>
                  </>
                )}
              </>
            ) : (
              processAnime === false ?
                <Card className={classes.cardAfterQuestion}>
                  <div className="start_test_text_top after_endtest_submit">
                    <div className="registered_img">
                      <img
                        src={recruiter_sign_thankyou_icon}
                        className="successImagess"
                        alt="img"
                      />
                    </div>
                    <p className="fs-semi-bold fs-22 f-color mb_16 text-center">
                      Thank you for using ClearedTalent.
                    </p>
                    <p className="fs-semi-bold fs-18 f-color mb_16 text-center">
                      Your feedback is very important to us. Please visit us at
                      <a
                        className="ml_10"
                        href="https://www.clearedtalent.com/"
                        target="_blank"
                      >
                        www.clearedtalent.com
                      </a>
                    </p>
                    <p className="fs-semi-bold fs-18 f-color text-center">
                      Your test result is sent to the reviewer.
                    </p>
                  </div>
                  {recruiterPartnerId !== undefined ? (referralProgram[0]?.isActive === true ||
                    referralProgram.length === 0 ? (
                    <div className="containerfor_circul">
                      <CircularProgress />
                    </div>
                  ) : null)
                    : <div className="containerfor_circul">
                      <CircularProgress />

                    </div>}
                </Card>
                :
                <Card className={classes.cardAfterQuestion}>
                  <div className="cardContainer_review">
                    <p className="f-color fs-30 fs-semi-bold mb_16 text-center">
                      Please wait ...
                    </p>
                    <div className="containerfor_circularPrg">
                      <CircularProgress />
                    </div>
                  </div>
                </Card>
            )}
          </>
        ) : null}
        <Dialog
          open={testOnGoingModal}
          onClose={() => setTestOnGoingModal(false)}
          aria-labelledby="customized-dialog-title"
        >
          <DialogContent
            className={`${classes.bodyForRemove} removemodal_responsive`}
          >
            <div className="text-center fs-semi-bold f-color fs-18">
              Do you want to stop the test?
            </div>
            <p className="f-color fs-16 fs-semi-bold mt-10">
              Note : Your test result may not be saved.
            </p>
          </DialogContent>
          <DialogActions
            className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}
          >
            <div className="remove_modal">
              <button
                type="button"
                className="btn_remove btn-skill cp"
                onClick={() => setTestOnGoingModal(false)}
              >
                No
              </button>
              <button
                type="button"
                className="btn2 btn-green ml_5 cp"
                onClick={() => stopAndGoProfile()}
              >
                Yes
              </button>
            </div>
          </DialogActions>
        </Dialog>
        <NotificationContainer />
      </div>
    </>
  );
};

export default RegisteredStartTest;