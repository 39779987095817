import Header from '../../components/headerAdmin/Header'
import Footer from '../../components/adminFooter/Footer'
import RequisitionDetailsBody from './RequisitionDetailsBody.js';
import "../RequisitionDetails/RequisitionDetails.css"

function RequisitionDetails(props) {
  const { match } = props;
  const requisitionId = match?.params?.id

  return (
    <div  className='mainContainer_requisitionDetails'
    >
      <div className='topContainer_requisitionDetails'
      >
        <Header />    

        <RequisitionDetailsBody
          location={{state:props?.location?.state,requisitionId}}
        />
      </div>
      <Footer />
    </div>
  )
}

export default RequisitionDetails;



