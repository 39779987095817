import React, { useEffect, useState } from 'react'
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import './manageDivisionTable.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { filter } from '@progress/kendo-data-query/dist/npm/transducers';
import { Skeleton } from '@material-ui/lab';
import { Button, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {
        background: '#fffff !important'
    },
}))


const ManageDivisionTable = (props) => {

    const { divisionData, isDataLoading, onEdit, userType } = props;
    const [allDivisionData, setAllDivisionData] = useState([]);
    const [result, setResult] = useState([]);

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                },
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const dataStateChange = (event) => {
        setResult(process(allDivisionData, event.dataState));
        setDataState(event.dataState);
    };

    const classes = useStyles()

    useEffect(() => {
        setAllDivisionData(divisionData);
        setResult(process(divisionData, dataState));
    }, [divisionData]);


    const handleEditClick = (rowData) => {
        onEdit(rowData);
    };

    const getSkeleton_kendo = () => {
        let arr = []
        for (let i = 0; i < 21; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="40px" />)
        }
        return arr;
    }

    return (
        <>
            <div className='tableGrid kendoGrid_manageDivision'>
                <GridDiv
                    filterable={true}
                    filter={dataState.filter}
                    sort={dataState.sort}
                    sortable={true}
                    resizable={true}
                    reorderable={true}
                    pageable={{
                        pageSizes: [10, 20, 50, 100],
                        info: true,
                        previousNext: true,
                        buttonCount: 10
                    }}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={result}
                    onDataStateChange={(e) => dataStateChange(e)}
                >
                    <GridNoRecords>
                        {isDataLoading ? getSkeleton_kendo() : "No results found !"}
                    </GridNoRecords>
                    <Column
                        field="divisionName"
                        title="Division"
                        width="150px"
                        filterable={true}
                    />
                    <Column
                        field="recruiterLeadreEmails"
                        title="Recruitment Leaders"
                        width="180px"
                        filterable={true}

                    />
                    <Column
                        field='teamLeaderEmails'
                        title="Team Leaders"
                        width="180px"
                        filterable={true}
                    />
                    <Column
                        field="isActive"
                        title="Is Active"
                        width="80px"
                        filter={"boolean"}
                        cell={(props) => {
                            const active = props.dataItem.isActive ? "True" : "False";
                            return (
                                <td>
                                    {active}
                                </td>
                            );
                        }}
                        filterable={true}
                    />
                    <Column
                        title="Action"
                        filterable={false}
                        width="80px"
                        cell={(props) => {
                            return (

                                <td >
                                    <div className='actionbutton'>
                                        <Button
                                            className='editBtn_manageDivision'
                                            onClick={() => handleEditClick(props.dataItem)}
                                            disabled={userType === 'Admin' ? true : false}
                                        >
                                        Edit
                                        </Button>
                                    </div>
                                </td>
                            );
                        }}
                    />
                </GridDiv>
            </div>
        </>
    )
}

export default ManageDivisionTable;