import React, { useState, useEffect, useRef } from "react";
const testQuestionDescription = (questionDesc) => {
  function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  if (questionDesc) {
    const codeWithJsx = "<pre class='line-numbers language-js'}><code className={`language-js`}>";
    const codeSlasWithJsx = "</code></pre>";
    const tableWithJsx = "<table style='border: 1px solid black;border-collapse:collapse'>"
    const tdWithJsx = "<td style='border: 1px solid black;border-collapse:collapse'>"
    let str = questionDesc;
    str = replaceAll(str, '<code>', codeWithJsx);
    str = replaceAll(str, '</code>', codeSlasWithJsx);
    str = replaceAll(str, '<pre class="ql-syntax" spellcheck="false">', codeWithJsx);
    str = replaceAll(str, '</pre>', codeSlasWithJsx);
    // str = replaceAll(str, '<table>', tableWithJsx);
    // str = replaceAll(str, '<td>', tdWithJsx);
    let questionText = <p className='without-line-break' dangerouslySetInnerHTML={{
      __html: str
    }}></p>
    return questionText
  }

    // let questionText = ""
    // if (questionDesc) {
    //   let v = questionDesc
    //   let codeStart = v.search('<code>')
    //   let codeEnd = v.search('</code>')

    //   let substringCode = v.substr(codeStart + 6, codeEnd - codeStart - 6).trim();

    //   if (substringCode) {

    //     let beforeCode = v.substr(0, codeStart).trim();
    //     let afterCode = v.substr(codeEnd + 7, (v.length - codeStart - 6)).trim();
    //     let beforeCodeJsx = <p className='without-line-break'>Question: {beforeCode}</p>
    //     let afterCodeJsx = <p className='without-line-break'> {afterCode}</p>

    //     let beforeCodeJsxs = beforeCode.split('\n').map((item, i) => <p className='without-line-break'>Question: {item}</p>);
    //     let afterCodeJsxs = afterCode.split('\n').map((item, i) => <p className='without-line-break'> {item}</p>);

    //     // language-javascript seems to highlight correctly for java, javascript, html & Python (tested myself)
    //     // We probably can get away with wrapping everything with the <code> tag instead of having a different tag for each language.
    //     const codeWithJsx =
    //       <pre className="line-numbers">
    //         <code className={`language-js`}>
    //           {substringCode}
    //         </code>
    //       </pre>

    //     questionText = [...beforeCodeJsxs, codeWithJsx, ...afterCodeJsxs];

    //   } else {

    //     questionText = questionDesc
    //       .split('\n').map((item, i) => i===0 ? <p className='without-line-break'>Question: {item}</p> : <p className='without-line-break'> {item}</p> );
    //   }
    // }


    // return questionText
  }
  export default testQuestionDescription;