import React, { useState, useEffect, useCallback, useContext } from "react";
import Header from "../../components/adminHeader/adminHeader";
import Headers from "../../components/headerAdmin/Header.js"
import getAllRecruitersResponsetab from "../../apis/recruiters/getAllRecruiterstab";
import approveRecruitersResponse from "../../apis/recruiters/approveRecruiters";
import AllRevokedRecruitersKendoTable from "./AllRevokedRecruitersKendoTable";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AllNotApprovedRecruitersKendoTable from "./AllNotApprovedRecruitersKendoTable";
import AllapprovedRecruitersKendoTable from "./AllapprovedRecruitersKendoTable";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import getAllActiveRecruitmentpartner from "../../apis/organization/getAllActiveRecruitmentpartner";
import getActiveRoles from "../../apis/users/getActiveRoles";
import { getAgencyType } from "../../apis/agency/agencyApi";
import getAgencyByAgencytypeResponse from "../../apis/recruiters/getAgencyByAgencytype";
import { UserTypeContext } from "../../AppRoute";
import { CircularProgress } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const Recruiters = ({ idContextTest }) => {
  const history = useHistory();
  const { userType, userRecords } = useContext(UserTypeContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [allapprovedRecruiters, setallapprovedRecruiters] = useState([]);
  const [allNotApprovedRecruiters, setallNotApprovedRecruiters] = useState([]);
  const [allRevokedRecruiters, setallRevokedRecruiters] = useState([]);
  const [tabName, settabName] = useState("Pending");
  const [openApproveDialogue, setOpenApproveDialogue] = useState(false);
  const [openRevokeDialogue, setOpenRevokeDialogue] = useState(false);
  const [recruiterData, setRecruiterData] = useState({});
  const [userRole, setUserRole] = useState([]);
  const [AllOrganization, setAllOrganization] = useState([]);
  const [email, setEmail] = useState("");
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [agencyTypeData, setAgencTypeData] = useState([]);
  const [selectAgencyType, setSelectAgencyType] = useState("");
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [RecruitmentParterId, setRecruitmentParterId] = useState("");
  const [apvBtn, setApvBtn] = useState(false);
  const [recruiterInfo, setrecruiterInfo] = useState({
    agencyId: "",
    patnerId: "",
    orgCode: "",
  })
  const closeButton = {
    position: "absolute",
    right: "7px",
    top: "12px",
    color: "grey",
  };

  // ------ Getting all active roles and setting them in state -----
  const getAllRoles = async () => {

    try {
      const allRoleResponse = await getActiveRoles();

      setUserRole(allRoleResponse.data.data);

    } catch (error) {
      console.log(error);
    }

  };

  // Getting agency data based on agency type when userType is 'Admin'
  const getAllAgencyTypesData = useCallback(async () => {
    if (userType === "Admin") {
      try {
        const allData = await getAgencyByAgencytypeResponse(RecruitmentParterId, {
          type: selectAgencyType,
        });

        setAgencTypeData(allData?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
  }, [RecruitmentParterId, selectAgencyType]);

  const geAllAgencyType = async () => {

    try {
      const allAgency = await getAgencyType();

      setAgencies(allAgency.data.data);

    } catch (error) {
      console.log(error);
    }

  };

  // useEffect to trigger fetching agency data when selectAgencyType changes
  useEffect(() => {
    if (selectAgencyType) {
      getAllAgencyTypesData();
    }
  }, [getAllAgencyTypesData, selectAgencyType, RecruitmentParterId]);


  // Getting all recruiters and filter them based on approval status and userType
  const getAllRecruiters = async (tab) => {

    const payload = {
      isPending: false,
      isApproved: false,
      isRevoked: false,
      code: "",

    }
    tab === "Revoked" ? payload.isRevoked = true : tab === "Approved" ? payload.isApproved = true : payload.isPending = true
    let approved = "", revoked = "", notApproved = "";
    if (userType === "Admin") {
      setisDataLoaded(false);


      try {
      let allRecruiters = await getAllRecruitersResponsetab(payload);

      if (tab === "Approved") {
        approved = allRecruiters.data.data;;
      }
      if (tab === "Revoked") {
        revoked = allRecruiters.data.data;;
      }
      if (tab === "Pending") {
        notApproved = allRecruiters.data.data;
      }
    }
    catch (error) {
      console.log("error",error);
    }

      setallNotApprovedRecruiters(notApproved);
      setallapprovedRecruiters(approved);
      setallRevokedRecruiters(revoked);
      setisDataLoaded(true);
    }
    else {
      setisDataLoaded(false);
      payload.code = userRecords?.recruitmentpartner?.code;
      try {
      let allRecruiters = await getAllRecruitersResponsetab(payload);
      setAllOrganization(userRecords?.recruitmentpartner?.name);
      setSelectedAgency(userRecords?.agency?.agencyName);
      setSelectAgencyType(userRecords?.agency?.agencyType?.code)
      setrecruiterInfo({ ...recruiterInfo, patnerId: userRecords?.recruitmentpartner?.id, agencyId: userRecords?.agency?.id, orgCode: userRecords?.recruitmentpartner?.code })

      if (tab === "Approved") {
        approved = allRecruiters.data.data;
      }
      if (tab === "Revoked") {
        revoked = allRecruiters.data.data;
      }
      if (tab === "Pending") {
        notApproved = allRecruiters.data.data;
      }
    }
    catch (error) {
      console.log("error",error);
    }

      setallNotApprovedRecruiters(notApproved);
      setallapprovedRecruiters(approved);
      setallRevokedRecruiters(revoked);
      setisDataLoaded(true);
    }

  };


  // Approving a recruiter based on the user's role (Admin or Recruiter)
  const approveRecruiterHandler = async () => {
    setApvBtn(true);
    let payload = {
      userid: recruiterData.id,
      approval: true,
      recruitmentpartnerId: userType === "Admin" ? recruiterData.organizationId : recruiterInfo.patnerId,
      roleId: recruiterData.userRole,
      roleCode: recruiterData.roleCode,
      agencyId: userType === "Admin" ? selectedAgency?.id : recruiterInfo.agencyId,
      approvedBy: idContextTest,
      recruitmentpartnerCode: recruiterInfo.orgCode,
      userType: userType,
    };

    try {
      if (
        // isRecruiter &&
        payload.recruitmentpartnerId &&
        payload.roleId &&
        selectAgencyType &&
        selectedAgency
      ) {
        let acceptRecruiter = await approveRecruitersResponse(payload);
        await getAllRecruiters(tabName);
        setOpenApproveDialogue(false);
        if (acceptRecruiter.status === 208 || acceptRecruiter.status === 200 || acceptRecruiter.status === 202 || acceptRecruiter.status === 500) {
          NotificationManager.error(
            `${acceptRecruiter.data.message}`,
            "Error",
            2500
          );
          setApvBtn(false);
        } else {
          NotificationManager.success(
            `${acceptRecruiter.data.message}`,
            "Success",
            2500
          );
          setIsRecruiter(false);

          if (userType === "Admin") {
            setAllOrganization([]);
            setSelectedAgency(null);
            setSelectAgencyType(null);
            setSelectedAgency(null);
            setAgencTypeData([]);
            setSelectedAgency(null);
          }
        }
      }
      else {
        setOpenApproveDialogue(true);
        NotificationManager.error("Please select all fields", "Error", 2500);
        setApvBtn(false);
      }
    } catch (error) {
      setOpenApproveDialogue(false);
      NotificationManager.error("Approval failed", "Error", 2500);
      setApvBtn(false);
    }
  };

  // ------------------- Revoke Recuiter Dialog Box Button Function -------------

  const revokeRecruiterHandler = async () => {
    setApvBtn(true);
    let payload = {
      userid: recruiterData.id,
      approval: false,
      recruitmentpartnerId: recruiterData.organizationId,
      //new parameters
      roleId: recruiterData.userRole,
      roleCode: recruiterData.code,
      approvedBy: idContextTest,
    };
    try {
      // Sending false value to isApproved via approveRecruiter API
      // let revokeRecruiter =
      await approveRecruitersResponse(payload);
      await getAllRecruiters(tabName);
      NotificationManager.success("Recruiter revoked", "Success", 2500);
    } catch (error) {
      NotificationManager.error("Revoking failed", "Error", 2500);
    }
    setOpenRevokeDialogue(false);
    setApvBtn(false);
  };

  //-------------------------------- END -------------------------------

  const tabClicked = (name) => {
    settabName(name);
    getAllRecruiters(name)
  };

  const openApproveModal = async (e, value) => {
    setRecruiterData(value);

    // If userType is Admin, get all organization else it will use the selected organization
    if (userType === "Admin") {
      await getOrganization();
    }
    setEmail(value.email);
    setApvBtn(false);
    setOpenApproveDialogue(true);
  };


  const closeApproveModal = () => {
    setRecruiterData({});
    setOpenApproveDialogue(false);
    // Removing the selected values when the modal is closed only for admin 
    if (userType === "Admin") {
      setSelectedAgency(null);
    }
    setIsRecruiter(false);
    setRecruitmentParterId("");
  };

  // --------------------- Revoke Modal Open----------------

  const openRevokeModal = async (e, value) => {
    setRecruiterData(value);
    if (userType === "Admin") {
      await getOrganization();
    }
    setEmail(value.email);
    setApvBtn(false);
    setOpenRevokeDialogue(true);
  };
  const closeRevokeModal = () => {
    setRecruiterData({});
    setOpenRevokeDialogue(false);
  };
  //-------------------------------- END -------------------------------

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getAllRoles();
        await getAllRecruiters(tabName);
        await geAllAgencyType();
      } catch (error) {
        setisDataLoaded(true);
      }
    }
    fetchAllData();

  }, []);

  const userRoleChangeHandler = (e, value) => {
    /* Get User role from here */
    if (value) {
      setRecruiterData((item) => ({
        ...item,
        userRole: value.id,
        roleCode: value.code
      }));
    }
  };

  const userRoleChanger = (e, value) => {
    if (value === "Recruiter") {
      setIsRecruiter(true);
    } else {
      setIsRecruiter(false);
    }

    if (value === "") {
      setRecruiterData((item) => ({
        ...item,
        userRole: undefined,
      }));
    }
  };

  const organizationChangeHandler = (e, value) => {
    /** Get user recruitment parner id from here */
    if (value) {
      setRecruitmentParterId(value?.id);
      setrecruiterInfo({ ...recruiterInfo, orgCode: value.code })
      setRecruiterData((item) => ({
        ...item,
        organizationId: value.id,
      }));
      setSelectedAgency(null)
    }
  };
  const onAgencyChange = (e, value) => {
    setSelectAgencyType(value?.code || "");
    setSelectedAgency(null)
  };

  const organizationChanger = (e, value) => {
    if (value === "") {
      setRecruiterData((item) => ({
        ...item,
        organizationId: undefined,
      }));
    }
  };

  useEffect(() => {
    if (!selectAgencyType) {
      setSelectedAgency(null);
    }
  }, [selectAgencyType]);
  const internalExternalChangeHandler = (e, value) => {
    setSelectedAgency(value);
  };

  const getOrganization = async () => {
    let allOrganization = await getAllActiveRecruitmentpartner();
    setAllOrganization(allOrganization.data.data);
  };

  const goToDashboard = () => {
    // Move to dashboard based on the user type
    if (userType === "Admin") {
      history.push("/admin-home");
    }
    else {
      history.push({
        pathname: "/dashboard",
      });
    }
  }

  return (
    <>
      {userType === "Admin" ? <Header uid={idContextTest} isDataLoaded={true} /> : <Headers />}
      {userType === "Admin" || userType === "MIS Operator" || userType === "Organization Admin" ? (
        <div>
          <div className="section-wrapper">
            <div className="container w1200 flex-to-footer">
              <div className="height_adjust mt_100">
                <div className="container w1200 pageWidth">
                  <div className="updatedCard">
                    <div className='titleContainer_approvalrecruiter'>
                      <span className='title'>Sign-Up Requests</span>
                    </div>
                    <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                      <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                    </div>

                    <div className="tab_header">

                      <div className="df ">
                        <div
                          className={`tab_button_approval ${tabName === "Pending" ? "tabs_requisitionReports_select" : "tabs_requisitionRepor"
                            }`}
                          onClick={() => tabClicked("Pending")}
                        >
                          Pending
                        </div>
                        <div
                          className={`tab_button_approval ${tabName === "Approved" ? "tabs_requisitionReports_select" : "tabs_requisitionRepor"
                            }`}
                          onClick={() => tabClicked("Approved")}
                        >
                          Approved
                        </div>
                        <div
                          className={`tab_button_approval ${tabName === "Revoked" ? "tabs_requisitionReports_select" : "tabs_requisitionRepor"
                            }`}
                          onClick={() => tabClicked("Revoked")}
                        >
                          Revoked
                        </div>
                      </div>
                      <div>
                        {/* <div className='my_talent_search_container' id="tabOneDisplay"><input type="text" placeholder="Search.." /></div> */}
                      </div>
                    </div>
                    {tabName === "Approved" ? (
                      <AllapprovedRecruitersKendoTable
                        allapprovedRecruiters={allapprovedRecruiters}
                        isDataLoaded={isDataLoaded}
                        userType={userType}
                      />
                    ) : tabName === "Revoked" ? (
                      <AllRevokedRecruitersKendoTable
                        allRevokedRecruiters={allRevokedRecruiters}
                        isDataLoaded={isDataLoaded}
                      />
                    ) : (
                      <AllNotApprovedRecruitersKendoTable
                        allNotApprovedRecruiters={allNotApprovedRecruiters}
                        approveRecruiterHandler={openApproveModal}
                        revokeRecruiterHandler={openRevokeModal}
                        isDataLoaded={isDataLoaded}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            open={openApproveDialogue}
            onClose={closeApproveModal}
            aria-labelledby="customized-dialog-title"
            className="approveRecruiterModal"
          >
            <Grid item xs={12}>
              <div className="Recruiter_Private_Skill_Modal_">
                <DialogTitle
                  id="customized-dialog-title"
                  className="admindailog_title_modal"
                  onClose={closeApproveModal}
                >
                  <div className="dialog-title-container">

                    <VerifiedUserIcon className="iconStyle" />
                    <span className="dialog-title-text">Approve Sign-up Request</span>
                  </div>
                </DialogTitle>
              </div>
            </Grid>

            {
              userType === "Admin" ?
                <>
                  <DialogContent
                    className={`${classes.bodyForRemove} removemodal_responsive`}
                  >
                    <>
                      <div className="select customized-select options">
                        <label className="field_name" htmlFor="country">
                          User Role*
                        </label>
                        <Autocomplete
                          autoSelect
                          id="combo-box-demo"
                          className="mt_8"
                          options={userRole}
                          getOptionLabel={(option) => option.roleName || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select User Type"
                            />
                          )}
                          onChange={userRoleChangeHandler}
                          onInputChange={userRoleChanger}
                        />
                      </div>
                      <div className="select customized-select options">
                        <label className="field_name" htmlFor="country">
                          Recruitment Organization*
                        </label>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_8"
                          options={AllOrganization}
                          autoHighlight
                          // defaultValue={RecruitmentParterId}
                          filterOptions={filterOptions}
                          getOptionLabel={(option) => option.name || ""}
                          onChange={organizationChangeHandler}
                          onInputChange={organizationChanger}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              required={true}
                              placeholder="Select Recruitment Organization"
                            />
                          )}
                        />
                      </div>

                      {/* {isRecruiter && (
          <> */}
                      <div className="select customized-select options">
                        <label className="field_name" htmlFor="country">
                          Agency Type*
                        </label>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_8"
                          options={agencies}
                          autoHighlight
                          filterOptions={filterOptions}
                          getOptionLabel={(option) => option.code || ""}
                          onChange={onAgencyChange}
                          onInputChange={organizationChanger}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              required={true}
                              placeholder="Select Agency Type"
                            />
                          )}
                        />
                      </div>
                      <div className="select customized-select options">
                        <label className="field_name" htmlFor="country">
                          Agency*
                        </label>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_8"
                          options={agencyTypeData ? agencyTypeData : []}
                          value={selectedAgency}
                          autoHighlight
                          // filterOptions={filterOptions}
                          getOptionLabel={(option) => option?.agencyName || ""}
                          onChange={internalExternalChangeHandler}
                          // onInputChange={organizationChanger}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              required={true}
                              placeholder={` select ${selectAgencyType} Agency `}
                            />
                          )}
                        />
                      </div>
                      {/* </>
        )} */}
                    </>
                    <div className="text-center fs-semi-bold f-color fs-18 mt_20">
                      Do you want to approve the Sign Up of the customer ({email}) ?
                    </div>
                  </DialogContent>
                </> :
                <>
                  <DialogContent
                    className={`${classes.bodyForRemove} removemodal_responsive`}
                  >
                    <>
                      <div className="select customized-select options">
                        <label className="field_name MuiTypography-root field-title-team-dialog MuiTypography-body1" htmlFor="country">
                          User Role*
                        </label>
                        <Autocomplete
                          autoSelect
                          id="combo-box-demo"
                          className="mt_10"
                          options={userRole}
                          getOptionLabel={(option) => option.roleName || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select User Type"
                            />
                          )}
                          onChange={userRoleChangeHandler}
                          onInputChange={userRoleChanger}
                        />
                      </div>
                      <div className="select customized-select options">
                        <label className="field_name MuiTypography-root field-title-team-dialog MuiTypography-body1" htmlFor="country">
                          Recruitment Organization*
                        </label>

                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            placeholder="Org code"
                            className="userDetails_textbox_outline mt_8"
                            value={AllOrganization}
                            // onChange={(e) => firstNameChange(e)}
                            disabled={true}
                          />
                        </FormControl>

                      </div>


                      <div className="select customized-select options">
                        <label className="field_name MuiTypography-root field-title-team-dialog MuiTypography-body1" htmlFor="country">
                          Agency Type*
                        </label>

                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            placeholder="Org code"
                            className="userDetails_textbox_outline mt_8"
                            value={selectAgencyType}
                            // onChange={(e) => firstNameChange(e)}
                            disabled={true}
                          />
                        </FormControl>

                      </div>
                      <div className="select customized-select options">
                        <label className="field_name MuiTypography-root field-title-team-dialog MuiTypography-body1" htmlFor="country">
                          Agency*
                        </label>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            placeholder="Org code"
                            className="userDetails_textbox_outline mt_8"
                            value={selectedAgency}
                            // onChange={(e) => firstNameChange(e)}
                            disabled={true}
                          />
                        </FormControl>

                      </div>

                    </>
                    <div className="text-center fs-semi-bold f-color fs-18 mt_20">
                      Do you want to approve the Sign Up of the customer ({email}) ?
                    </div>
                  </DialogContent>
                </>
            }

            <DialogActions
              className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button `}
            >
              <div className="remove_modal revoke_adjust">
                <button
                  type="button"
                  className="btn_remove btn4 btn-skill cp"
                  onClick={closeApproveModal}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn4 btn_primary ml_5 cp"
                  onClick={approveRecruiterHandler}
                  disabled={apvBtn}
                >
                  <div className="btn_loader">
                    Yes
                    &nbsp;
                    {apvBtn &&
                      <div>
                        <CircularProgress size={13} />
                      </div>
                    }
                  </div>
                </button>
              </div>
            </DialogActions>
          </Dialog>

          {/* -------------------    Confirmation Dialog Box for Revoking Recruiters -------------- */}

          <Dialog
            open={openRevokeDialogue}
            onClose={closeRevokeModal}
            aria-labelledby="customized-dialog-title"
          >
            <DialogContent
              className={`${classes.bodyForRemove} removemodal_responsive`}
            >
              <div className="text-center fs-semi-bold f-color fs-18 mt_20">
                Do you want to revoke this customer ({email}) ?
              </div>
            </DialogContent>
            <DialogActions
              className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button `}
            >
              <div className="remove_modal revoke_adjust">
                <button
                  type="button"
                  className="btn_remove btn4 btn-skill cp"
                  onClick={closeRevokeModal}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn4 btn_primary ml_5 cp"
                  onClick={revokeRecruiterHandler}
                  disabled={apvBtn}
                >
                  <div className="btn_loader">
                    Yes
                    &nbsp;
                    {apvBtn &&
                      <div>
                        <CircularProgress size={13} />
                      </div>
                    }
                  </div>
                </button>
              </div>
            </DialogActions>
          </Dialog>

          <NotificationContainer />
        </div>
      ) : (
        <>
          <div className="row padding_80 text-center ">
            <p className="fs-semi-bold fs-40 f-color authorize">
              You Are Not Authorized To Visit This Page
            </p>
          </div>
        </>
      )
      }
    </>
  );
};

export default Recruiters;