import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllActiveOrganizationResponse = async () => {  
    let allResponse = {};
    try {
        const Response = await  axios.get(
            jsonConfig.apiUrl + "GetAllActiveRecruitmentpartner", 
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
        allResponse = Response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr", error);      
        return Promise.reject(error)
    }
}

export default getAllActiveOrganizationResponse;