import { TextField } from "@material-ui/core";
import { candidateRequisitionStatusCode } from "../../Config.json"
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { convertDate } from "../AcitivityModal/assistingFunctions";
//  give it code and it returns field name
export const camelCase=(statusName)=>{
   return statusName?.split("_").map(elem => elem.slice(0, 1).toUpperCase() + elem.slice(1).toLowerCase()).join(" ")
}
export const codeTofield = (value) => {
    let statusName = Object.keys(candidateRequisitionStatusCode).find(key => candidateRequisitionStatusCode[key] === value)
    statusName =camelCase(statusName) 
    return statusName
}

//  give it data type data ans updaterfunction get back form fields
export const renderField = (field, selectedEvent, updateEvent,minDate,disabled=false) => {
    switch (field.dataType) {
        case 'Text':
            return (
                <textarea
                    type="text"
                    rows="5"
                    key={field.name}
                    label={field.label}
                    value={selectedEvent[field.altName] || ""}
                    onChange={(e) => updateEvent(field.altName, e.target.value)}
                    className='activity_autocom activity_textarea  mb_10'
                    placeholder={"Add "+ field?.name}
                    disabled={disabled}
                />
            );
        case 'Date':
            return (
                <DatePicker
                    key={field.name}
                    selected={selectedEvent[field.altName] ? DateTime.fromISO(selectedEvent[field.altName]).toJSDate() : null}
                    onChange={(date) => updateEvent(field.altName, convertDate(date))}
                    className="actionFieldheightWidth"
                    minDate={minDate ? DateTime.now().toJSDate() : undefined} 
                    disabled={field.altName === "displayDate" || disabled}
                    dateFormat="MM/dd/yyyy"
                    showIcon={true}
                    placeholder={"Select "+ field?.name}
                />
            );
        case 'Number':
            return (
                <TextField
                    key={field.name}
                    label={field.label}
                    type="number"
                    value={selectedEvent[field.altName] || ""}
                    onChange={(e) => updateEvent(field.altName, e.target.value)}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        style: {
                            height: 40, // Custom height
                        },
                    }}
                    placeholder={"Enter "+field.name}
                    disabled={disabled}
                />
            );
        default:
            return null;
    }
};


export const filterFunc=(data,func,match="")=>{
      let resp=[]
      for(let elem of data){
            if(func(elem,match))
                resp.push(elem)
      }
      return resp
}

// data
// filterFunc(data,(elem)=>elem.id===match.id,match)
// filterFunc(data,(elem)=>elem.isActive)

export const mapFunc=(data,func)=>{
    let resp=[]
    
    for(let [index,elem] of data.entries())
        resp.push(func(elem,index))
    
    return resp
}

// data
// mapFunc(data,elem=>elem.id)