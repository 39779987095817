import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import "./Header.css";
import profile from "../../components/basicInfoForm/images/avatar.png";
import { Button, Card, CardActionArea, CardActions, CardContent, ClickAwayListener, Dialog, makeStyles, Menu, MenuItem, MenuList, Paper, Typography } from "@material-ui/core";
import { ArrowDownward, ArrowDropDown, ExitToApp, HeadsetMic, Person, SupportAgentIcon } from "@material-ui/icons";
import SupportIconNew from "../../components/basicInfoForm/images/supportIconNew.svg";
import SignOutIcon from "../../components/basicInfoForm/images/signOutIcon.svg";
import personIcon from "../../components/basicInfoForm/images/Person.svg";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import HomeContactUsModal from "../../pages/home/homeHeader/homeContactUsModal/homeContactUs";
import bellIcon from "../../components/basicInfoForm/images/bellIcon.png";
import requisitionIcon from "../../components/basicInfoForm/images/requisitionIcon.png"
import { UserTypeContext } from "../../AppRoute";
import getAllNotification from "../../apis/getAllNotification/getAllNotification";
import Badge from '@material-ui/core/Badge';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import updateNotification from "../../apis/updateNotification/updateNotification";
import userSessionLogs from "../../apis/userSessionLogs";
import customLogoutResponse from "../../apis/logout/customLogout";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  dialog: {
    position: "absolute",
    right: "2.5%",
    top: "6%",
    borderRadius: "10px",
  },
}));


const Header = ({ isNotificationPage }) => {
  const goToHomePage = () => {
    window.open("/landing", "_self");
  };
  const { userType, userIdType, userRecords, userRecruitmentpartnerId } = useContext(UserTypeContext);
  const history = useHistory();
  const [response, setResponse] = useState("");
  const [profilePicture, setProfilePicture] = useState(profile);
  const [userfirstname, setUserFirstName] = useState("");
  const [userlastname, setUserLastName] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logo, setLogo] = useState("");
  const [recruiter, setRecruiter] = useState("");
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isNavigationOpen, setisNavigationOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const [mailData, setMailData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });
  const [allNotifications, setAllNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState([])
  const [newNotificationCount, setNewNotificationCount] = useState(0);



  const classes = useStyles();

  const handleClose = () => {
    setDialogOpen(false);

  };

  const handleOpen = () => {
    setDialogOpen(true);
  }

  const openSupportModal = () => {
    setOpen(true);
  }

  const _modalClose = () => {
    setOpen(false);
  }

  const divClose = () => {
    setisNavigationOpen(false);
  }

  const notificationModuleClose = () => {
    setIsNotificationOpen(false)
  }

  const gotoAllNotification = () => {
    history.push({
      pathname: "/all-notification",
    });
  }

  const getCustomizedFirstName = (fullName) => {
    if (fullName) {
      const names = fullName.split(" ");

      let firstName = names[0];

      if (firstName.length > 15) {
        firstName = `${firstName.slice(0, 15)}...`;
      }
      return firstName;
    }
  };

  useEffect(() => {
    let mounted = true;
    const getUserData = async () => {
      if (mounted) {
        setUserFirstName(userRecords?.firstName);
        setUserLastName(userRecords?.lastName);
        setLogo(userRecords?.recruitmentpartner?.recruitmentpartnerLogoUrl);
        setProfilePicture(userRecords?.profile?.profilePicUrl);
        setLoader(true);
        setRecruiter(userRecords?.role?.roleName);
      }
    }
    getUserData();
    return () => mounted = false;
  }, [])

  const signOut = async () => {
    await customLogoutResponse({ userId: localStorage.getItem('userID'), recruitementPartnerId: userRecruitmentpartnerId })
    await userSessionLogs(localStorage.getItem('userID'))
    localStorage.removeItem("userID");
    localStorage.removeItem("role");
    localStorage.removeItem("token")
    window.open("/", "_self");
  };

  const goToAccountSetting = () => {
    window.open("/account-settings", "_self");
  }


  const allNotification = async () => {
    if (userType === "Recruiter" || userType === "Recruitment Leader" || userType === "Team Leader") {
      let payload = {
        id: userIdType,
        role: userType,
        skip: 0,
        size: 5,
        isViewed: false
      }
      try {
        const response_unRead = await getAllNotification(payload)
        setNewNotification(response_unRead?.data?.data?.notifications)
        setNewNotificationCount(response_unRead?.data?.data?.totalCount)

        payload.isViewed = "all"
        const response_All = await getAllNotification(payload)
        setAllNotifications(response_All?.data?.data?.notifications)

      } catch (error) {
        console.log(error, "error");
      }
    }

  }

  const readedNotificationId = async (item) => {
    let payload = {
      _id: item._id,
      userId: item.receiverData.id,
      updateAll: false
    }
    try {
      const response = await updateNotification(payload)
      if (userType === "Recruitment Leader") {
        if (item?.requisitionDetails?.assignedTo === userIdType) {
          history.push(`/requisition-details/${item.requisitionId}`)
          setIsNotificationOpen(!isNotificationOpen)
        }
        else {
          if (item?.receiverData?.isViewed === false) {
            const index = allNotifications.findIndex(dataitem => dataitem._id === item._id);
            const updatedAllNotifications = allNotifications.map((dataitem, idx) =>
              idx === index
                ? { ...dataitem, receiverData: { ...dataitem.receiverData, isViewed: true } }
                : dataitem
            );
            setAllNotifications(updatedAllNotifications);
            setNewNotificationCount(newNotificationCount - 1)
          }
        }
      }
      else if (userType === 'Recruiter') {
        let data = item?.recruiterRequisition?.filter((dataitem) => dataitem.recruiterId === userIdType && dataitem?.isActive === true)
        if (data.length > 0) {
          history.push(`/requisition-details/${item.requisitionId}`)
          setIsNotificationOpen(!isNotificationOpen)
        }
        else {
          if (item?.receiverData?.isViewed === false) {
            const index = allNotifications.findIndex(dataitem => dataitem._id === item._id);
            const updatedAllNotifications = allNotifications.map((dataitem, idx) =>
              idx === index
                ? { ...dataitem, receiverData: { ...dataitem.receiverData, isViewed: true } }
                : dataitem
            );
            setAllNotifications(updatedAllNotifications);
            setNewNotificationCount(newNotificationCount - 1)
          }
        }
      }
      else if (userType === "Team Leader") {
        history.push(`/requisition-details/${item.requisitionId}`)
        setIsNotificationOpen(!isNotificationOpen)
      }
    } catch (error) {
      console.log(error, "error");
    }
  }




  useEffect(() => {
    allNotification()
  }, [])



  return (
    userfirstname === "Admin" ?
      <>
        <div className="nav_bg_home home-specific-header">
          <div className="container w1200 nav_bg_home_section">
            <div className="nav_bg_left">
              <li
                className="cp"
                onClick={() => goToHomePage()}
                style={{ listStyle: "none" }}
              >
                <img
                  src={recruiter_header_logo}
                  className="nav_logo_home"
                  alt="img"
                />
              </li>
            </div>
            <div className="signOut">
              <button className="signoutButton" onClick={() => signOut()}>
                Sign Out
              </button>
            </div>
          </div>
        </div>
        <div className='nav_bg_home_responsive'>
          <div className="nav_bg_home_responsive-wrapper">
            <div className="home-top-nav">
              <li className='cp headerListStyleNone' onClick={() => goToHomePage()}>
                <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
              </li>
              <div className="signOut">
                <button className="signoutButton" onClick={() => signOut()}>
                  Sign Out
                </button>
              </div>
            </div>

          </div>
        </div>
      </>
      :
      <>
        <div className="nav_bg header_fixed profile_headshadow">
          <div className="container w1200 nav_bg_home_sectio home-specific-header resume_head">
            <div className="nav_bg_left">
              <li
                className="cp"
                onClick={() => goToHomePage()}
                style={{ listStyle: "none" }}
              >
                <img
                  src={recruiter_header_logo}
                  className="nav_logo_home"
                  alt="img"
                />
              </li>
            </div>
            <div className="notification_profile_mainContainer_header">
              {
                loader ? userType === "MIS Operator" || userType === "Organization Admin" ? "" : isNotificationPage ? "" :
                  <div className="notification_container_header">
                    <Badge badgeContent={newNotificationCount} color="secondary"><div className="notificationIcon_header" onClick={(e) => setIsNotificationOpen(!isNotificationOpen)}><img src={bellIcon} className="bellIcon_Img" /></div></Badge>
                    {isNotificationOpen ? (
                      <ClickAwayListener onClickAway={(e) => notificationModuleClose()}>
                        <div className={userType === "Recruiter" ? "notification_contain_header_recruiter" : "notification_contain_header"}>
                          <div className="notification_titleContainer_header">
                            <div className="notification_text_header">Notifications</div>
                          </div>
                          <div className="notification_sms_container_header">

                            {allNotifications.map((item, i) => {

                              return (
                                <>

                                  <div className={item?.receiverData?.isViewed === false ? "notification_mainDiv_header_Unread" : "notification_mainDiv_header"} onClick={(e) => readedNotificationId(item)}>
                                    <div><InfoOutlinedIcon className="iconColor_header" /></div>
                                    <div className="requitionNumber_notificationcontainer_header">
                                      <div className="requitionno_text_header">{item?.receiverData?.message}</div>
                                    </div>
                                  </div>


                                </>
                              )
                            })}
                            {
                              allNotifications.length === 0 ? <div className="NoRecentText">No recent notifications !!!</div> : ""
                            }

                          </div>
                          <div className="viewallContainer_notifacation_header" onClick={(e) => gotoAllNotification()}>
                            <span className="viewAllText_header">View All</span>
                          </div>
                        </div>
                      </ClickAwayListener>

                    ) : null}
                  </div>
                  : null}

              <div className="profile_dropdown" onClick={(e) => setisNavigationOpen(!isNavigationOpen)}>
                {loader ?
                  <div className="header_profile_pic">
                    <div className="header_nav" >
                      <div className="headerUserTitle">
                        <Typography className=" cp" > Hi, {getCustomizedFirstName(userfirstname)}</Typography>
                      </div>

                      <div>
                        <ArrowDropDown className="cp" />
                      </div>
                    </div>
                    {profilePicture ?
                      <>
                        <img
                          src={profilePicture}
                          alt="profile picture"
                          className="profileImg cp"
                        /></>
                      :
                      <>
                        <img
                          src={profile}
                          alt="profile picture"
                          className="profileImg cp"
                        />
                      </>}

                    {isNavigationOpen ? (
                      <ClickAwayListener onClickAway={divClose}>
                        <div className="profile_drop_menu_headers">
                          <Card className="main_card">
                            <CardContent className="all_profile">
                              {profilePicture ? <>
                                <img
                                  src={profilePicture}
                                  alt=""
                                  className="edit_profile"
                                /></>
                                :
                                <>
                                  <img
                                    src={profile}
                                    alt=""
                                    className="edit_profile"
                                  />
                                </>}
                              <Typography className="recruiter_name">{userfirstname} {userlastname}</Typography>
                              <Typography className="user_roll">{recruiter}</Typography>
                              <div className="edit_header_logo">
                                <img
                                  src={logo}
                                  alt=""
                                  className="edit_logos"
                                />
                              </div>
                            </CardContent>
                            <CardActions className="cardAction">
                              <MenuList className="menulist">
                                <MenuItem onClick={goToAccountSetting} className="card_text"><img src={personIcon} className="menu-icon" />&nbsp;<span className="account-text">Account Settings</span></MenuItem>
                                <MenuItem onClick={openSupportModal} className="card_text"><img src={SupportIconNew} className="menu-icon" />&nbsp; Support</MenuItem>
                                <MenuItem onClick={signOut} className="card_text"><img src={SignOutIcon} className="menu-icon" />&nbsp; Sign Out</MenuItem>
                              </MenuList>
                            </CardActions>
                          </Card>

                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </div>
                  : ''}
              </div>
            </div>

          </div>
        </div>
        <HomeContactUsModal
          modalOpen={open}
          modalClose={_modalClose}
        />
      </>
  );
};

export default Header;
