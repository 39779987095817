
import '@progress/kendo-theme-default/dist/all.css';
import AppRoute from './AppRoute';
import 'react-notifications/lib/notifications.css';
import './App.css';
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";



// export const idContext = createContext();

const App = () =>  {
 
    return (
      <AppRoute> </AppRoute>
    
    );
  
}

export default App;