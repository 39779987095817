import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllRecruitersResponsetab = async (payload) => {
    let allResponse = {};
    try {
        const Response = await axios.post(    
            jsonConfig.apiUrl+"Getallrecruitertab",payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = Response;

        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr", error);
        return Promise.reject(error);
    }
}

export default getAllRecruitersResponsetab;