import axios from "axios";
const jsonConfig = require("../Config.json");

const createDemoCandidate = async (candidateData) => {
  try {
    const getAllResponse = await axios.post(
      jsonConfig.createDemoCandidateUrl,
      candidateData,
      {
        headers: {
          "x-functions-key": jsonConfig.createDemoCandidateKey,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default createDemoCandidate;
