import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import csc from "../../utilities/country-state-city/dist";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Button, Container } from "react-bootstrap";
// import Header from "../../components/adminHeader/adminHeader";
import "./demoCandidate.css";
import getAllTests from "../../apis/Tests/getAllTests";
import getAllRecruitersResponse from "../../apis/recruiters/getAllRecruiters";
import createDemoCandidate from "../../apis/createDemoCandidate";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AdminHeader from "../../components/adminHeader/adminHeader";
import { CircularProgress } from "@material-ui/core";
import { UserTypeContext } from "../../AppRoute";
const DemoCandidate = ({ idContextTest }) => {
  const { userType } = useContext(UserTypeContext);
  const [countryObject, setcountryObject] = useState({});
  const [stateObject, setstateObject] = useState({});
  const [cityObject, setcityObject] = useState({});
  const [test, setTest] = useState({});
  const [recruiter, setRecruiter] = useState({});
  const [candidates, setCandidates] = useState("");
  const [maxscore, setMaxscore] = useState("");
  const [minscore, setMinscore] = useState("");
  const [loadingController, setLoadingController] = useState(false);
  const [countryList, setCountryList] = useState([
    {
      currency: "USD",
      flag: "",
      isoCode: "US",
      latitude: "38.00000000",
      longitude: "-97.00000000",
      name: "United States",
      phonecode: "+1",
    },
    {
      currency: "CAD",
      flag: "",
      isoCode: "CA",
      latitude: "60.00000000",
      longitude: "-95.00000000",
      name: "Canada",
      phonecode: "+1",
    },
    {
      currency: "INR",
      flag: "",
      isoCode: "IN",
      latitude: "20.00000000",
      longitude: "77.00000000",
      name: "India",
      phonecode: "+91",
    },
  ]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
 
  useEffect(() => {
    
    const getData = async () => {
      try {
        const allTestData = await getAllTests();
        const allRecruiter = await getAllRecruitersResponse();
       
          setTestList(allTestData.data.data);
       
        let data = allRecruiter.data.data;
        for (let i = 0; i < data.length; i++) {
          data[i].name = data[i].firstName + " " + data[i].lastName;
        }
       
          setRecruiterList(data);
          
       
      } catch (error) {
        console.error(error);
        setLoadingController(true)
      } finally {
        setLoadingController(true)
      }
    };
    getData();
    
  }, []);

  const countryChangeHandler = (event, value) => {
    if (value) {
      setStateList(csc.getStatesOfCountry(value.isoCode));
      setcountryObject(value);
    } else {
      setcountryObject({});
      setStateList([]);
      setstateObject({});
      setCityList([]);
      setcityObject({});
    }
  };
  const stateChangeHandler = (event, value) => {
    if (value) {
      setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode));
      setstateObject(value);
    } else {
      setstateObject({});
      setCityList([]);
      setcityObject({});
    }
  };

  const cityChangeHandler = (event, value) => {
    if (value) {
      setcityObject(value);
    } else {
      setcityObject({});
    }
  };
  const testChangeHandler = (event, value) => {
    if (value) {
      setTest(value);
    } else {
      setTest({});
    }
  };

  const recruiterChangeHandler = (event, value) => {
    if (value) {
      setRecruiter(value);
    } else {
      setRecruiter({});
    }
  };

  const candidateChangeHandler = (e) => {
    setCandidates(e.target.value);
  };

  const maxscoreChangeHandler = (e) => {
    setMaxscore(e.target.value);
  };

  const minscoreChangeHandler = (e) => {
    setMinscore(e.target.value);
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "none",
    padding: "7px 23px 7px 23px",
    marginBottom: "12px",
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.name,
  });


  const submitHandler = async () => {
    const body = {
      country: JSON.stringify(countryObject),
      state: JSON.stringify(stateObject),
      city: JSON.stringify(cityObject),
      testId: test.id ? test.id : "",
      numberOfCandidates: candidates ? candidates : "",
      RecruiterId: recruiter.id ? recruiter.id : "",
      maxscore: maxscore ? Number(maxscore) : 0,
      minscore: minscore ? Number(minscore) : 0,
    };

    try {
      const res = await createDemoCandidate(body);
      if (res.data) {
        setcountryObject({});
        setstateObject({});
        setcityObject({});
        setTest({});
        setRecruiter({});
        setCandidates("");
        setMaxscore("");
        setMinscore("");
        NotificationManager.success(
          "The Candidate has been added successfully",
          "Success",
          2500
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const handelcallback = async (childdata) => {
  //   // setisDataLoaded(true);
  //   if (childdata != undefined) {
  //     //   alert("ok")
  //     setuserType(childdata);
  //     setLoadingController(true);
  //   }
  // };

  return (
    <>
      <AdminHeader uid={idContextTest} isDataLoaded={loadingController} />

      <div className="section-wrapper">
        <div className="container w1004 flex-to-footer">
          <div className="height_adjust mt_100">
            {!loadingController ? (
              <div className="row circular-progress-center-question text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <>
                    <Container className="w1004 background">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <h2 className="fs-semi-bold fs-30 f-color">
                            Create Demo Candidates
                          </h2>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h2">
                            Country
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            value={countryObject}
                            options={countryList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Country"
                              />
                            )}
                            onChange={countryChangeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h2">
                            State
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            value={stateObject}
                            options={stateList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select State"
                              />
                            )}
                            onChange={stateChangeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h2">
                            City
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            value={cityObject}
                            options={cityList}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select City"
                              />
                            )}
                            onChange={cityChangeHandler}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h2">
                            Candidates
                          </Typography>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              className="admin_textbox"
                              placeholder="Enter Number of Candidates"
                              value={candidates}
                              onChange={candidateChangeHandler}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h2">
                            Maxscore
                          </Typography>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              className="admin_textbox"
                              placeholder="Enter Maxscore"
                              value={maxscore}
                              onChange={maxscoreChangeHandler}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" component="h2">
                            Minscore
                          </Typography>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              className="admin_textbox"
                              placeholder="Enter Minscore"
                              value={minscore}
                              onChange={minscoreChangeHandler}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body1" component="h2">
                            Test
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            value={test}
                            options={testList}
                            getOptionLabel={(option) => option.testName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Test"
                              />
                            )}
                            onChange={testChangeHandler}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body1" component="h2">
                            Recruiter
                          </Typography>
                          <Autocomplete
                            id="combo-box-demo"
                            value={recruiter}
                            options={recruiterList}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Recruiter"
                              />
                            )}
                            onChange={recruiterChangeHandler}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            style={btnGreen}
                            color="primary"
                            onClick={submitHandler}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  </>
                ) : userType === "" ? (<div className="row padding_80 text-center">
                  <p className="fs-semi-bold fs-40 f-color">
                    Loading...
                  </p>
                </div>) : (
                  <>
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        You Are Not Authorized To Visit This Page
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <NotificationContainer />
    </>
  );
};

export default DemoCandidate;
