import axios from "axios";
const jsonConfig = require("../../Config.json");

const getQuestionalCountByTestId = async (id) => {
  let allResponse = {};
  try {
    const getallresponse = await axios.get(
      jsonConfig.apiUrl + "gettestquestionscount/"+id,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allResponse = getallresponse;
    return Promise.resolve(allResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getQuestionalCountByTestId;
