import React, { useState, useEffect, useCallback, useRef } from "react";
import "date-fns";
import "react-dropdown/style.css";
import axios from "axios";
import "./powerresume.css";
import noProfilePictureImage from "../profileCompletion/images/no-user.png";
import camera from "../profileCompletion/images/camera.png";
import pdf from "../profileCompletion/images/pdf.png";
import mask_group_23 from "../profileCompletion/images/mask_group_23.png";
import MobileStepper from "@material-ui/core/MobileStepper";
import backArrow from "../profileCompletion/images/backarrow.png";
import nextArrow from "../profileCompletion/images/nextarrow.png";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import outlineSvg from "../profileCompletion/images/outline.svg";
import fillSvg from "../profileCompletion/images/fill.svg";
import calendar from "../profileCompletion/images/calendar.png";
import pencil from "../profileCompletion/images/pencil.png";
import { DateTime } from "luxon";
import VideoIntro from "../../../pages/adminApplication/components/videoIntro/VideoIntro.js";
// APIs
import profileResponse from "../../../apis/profile/profileById.js";
import otherMySkillsByProfileIdResponse from "../../../apis/profile/otherMySkillsByProfileId.js";
import pimaryMySkillByProfileIdResponse from "../../../apis/profile/primaryMySkillByProfileId.js";
import pdfIcon from "../../../pages/myTalentCommunity/icons/pdf.svg"
import docIcon from "../../../pages/myTalentCommunity/icons/doc.png"
import Thumbnail from "../../../components/profile/recordVideointro/images/videoIntro.jpg";
import { Dialog, DialogContent, DialogTitle, IconButton, Radio, Typography } from "@material-ui/core";
import { Rating, Skeleton } from "@material-ui/lab";
import mobileIcon from "../../../pages/adminApplication/components/profileCompletion/images/mobile.png"
import locationIcon from "../../../pages/adminApplication/components/profileCompletion/images/location.png"
import linkdinIcon from "../../../pages/adminApplication/components/profileCompletion/images/linkedin.png"
import { ArrowBackIos, ArrowForwardIos, ArrowLeft, ArrowRight, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import CandidateTest from "./CandidateTest";
import { useHistory } from "react-router";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
    btnpostion: {
        display: "flex",
        padding: "0px!important",
        //   marginTop: "20px",
        background: "none!important",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "flex-start!important",
    },
    btnstyle: {
        padding: "0px!important",
        background: "none!important",
        maxWidth: "unset !important",
    },
}));
const Powerresume = ({ props, profileCompletion, primarySkillsData, otherSkills, lastUpdate, isShare }) => {
    const jsonConfig = require("../../../Config.json");
    const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
    const [interViewSchedule, setInterViewSchedule] = useState([]);
    const [interviewDataLoaded, setInterviewDataLoaded] = useState(false);
    const [recordedVideoOpen, setRecordedVideoOpen] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps, setmaxSteps] = useState(1)
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    // const {isShare}=props
    const times = [
        {
            label: "Early Morning",
            time: "7 AM - 9 AM local",
            name: "Early Morning"
        },
        {
            label: "Morning",
            time: "9 AM - 12 PM local",
            name: "morning"
        },
        {
            label: "Afternoon",
            time: "12 PM - 5 PM local",
            name: "afternoon"
        },
        {
            label: "Evening",
            time: "5 PM - 8 PM local",
            name: "evening"
        },
    ];
    const moments = ['Early Morning', 'morning', 'afternoon', 'evening'];


    useEffect(() => {
        if (profileCompletion?.interviewPreference) {
            const scheduleTime = JSON.parse(profileCompletion?.interviewPreference);
            setInterViewSchedule(scheduleTime);
        }
    }, [profileCompletion?.interviewPreference]);

    const starRatingCaluculation = (starRating) => {
        const starArr = [
            outlineSvg,
            outlineSvg,
            outlineSvg,
            outlineSvg,
            outlineSvg,
        ];
        if (starRating) {
            const arr = starArr.fill(fillSvg, 0, starRating);
            return arr.map((item, i) => <img src={item} alt="item" key={i} className="starRatingHeight_poweResumeRight" />);
        } else {
            return starArr.map((item, i) => <img src={item} alt="item" key={i} className="starRatingHeight_poweResumeRight" />);
        }
    };

    const getCandidateAddress = () => {
        let city = JSON.parse(profileCompletion?.locationCity)?.name;
        let state = JSON.parse(profileCompletion?.locationState)?.name;
        let country = JSON.parse(profileCompletion?.locationCountry)?.name;
        let fulladdress = (city ? city + ", " : '') + (state ? state + ", " : '') + country;
        return fulladdress;
    }

    const generateTimesBtn = (timeObj) => {
        let ckeachedarr = []
        if (timeObj) {
            return times.map((item, index) => {
                const isChecked = timeObj[item.name];
                ckeachedarr.push(isChecked)
                if (isChecked)
                    return (
                        <div className="weekTiming_poweResumeRight">
                            {`${item.label} (${item.time})`}
                        </div>
                    );
            })
        }
    }

    const getResume_poweResumeRight = () => {
        let resumeURL = profileCompletion?.resumeUrl;
        if (resumeURL) {
            let resumeExtension = resumeURL.split('.');
            resumeExtension = resumeExtension[resumeExtension.length - 1];
            if (resumeExtension === 'pdf') {
                return (<img src={pdfIcon} alt="pdf" />)
            }
            else if (resumeExtension === 'doc' || resumeExtension === 'docx') {
                return (<img src={docIcon} alt="doc" />)
            }
        }
        else {
            return (<span className="personalInfocardItems_poweResumeCard">No Resume available</span>)
        }
    }

    const recordedVideoModal = (e) => {
        setRecordedVideoOpen(true);
    }
    const recordedVideoClose = (e) => {
        setRecordedVideoOpen(false);
    }
    const levelCreation = (starval) => {
        starval = parseInt(starval);
        let level = '';
        if (starval === 1) {
            level = 'Novice';
        }
        else if (starval === 2) {
            level = 'Beginner';
        }
        else if (starval === 3) {
            level = 'Competent';
        }
        else if (starval === 4) {
            level = 'Proficient';
        }
        else if (level === 5) {
            level = 'Expert';
        }
        else level = ''
        return (
            <span className="level_Skill_poweResumeRight">{level}</span>
        )
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
        <>
            <div className="mainContainer_poweResumeRight">
                <div className="headeings_poweResumeRight">
                    {Object.keys(profileCompletion).length > 0 ?
                        <div className="profileHeaderDiv">
                            <div>
                                <img
                                    src={profileCompletion.profilePicUrl || profilePicture}
                                    alt="Candidate Profile Picture"
                                    className="profileImage_powerResume"
                                />
                            </div>
                            <div className="nameEmailDiv_poweResumeRight">
                                <span> {profileCompletion?.user?.displayName}  </span>
                                <span className="powerResume_email"> {profileCompletion?.user?.email}</span>
                            </div>
                        </div>
                        :
                        <div className="profileHeaderDiv">
                            <Skeleton variant="circle" width={40} height={40} />
                            <div className="nameEmailDiv_poweResumeRight">
                                <Skeleton variant="rectangle" height={13} width={110} />
                                <Skeleton variant="rectangle" height={13} width={160} />
                            </div>
                        </div>
                    }
                    <div className="rightCorner_poweResumeRight">
                        <span className="poweResumeHeading_poweResumeRight">PoweResume&#8482;</span>

                        {lastUpdate ? <span className="poweResumeLastUpdated_poweResumeRight">
                            Last updated {lastUpdate ? DateTime.fromISO(lastUpdate).toFormat("dd-LLL-yyyy, hh:mm:ss a") : ''}</span>
                            : <Skeleton variant="rectangle" height={13} width={230} />}
                    </div>
                </div>
                <div className="poweResumeInfoCard_poweResumeRight">
                    <div className="leftSide_poweResumeInfoCard">
                        <div className="topContainerone_leftSide_poweResumeInfoCard">
                            {Object.keys(profileCompletion).length > 0 ?
                                <div className="resumeCard_leftSide_poweResumeInfoCard">
                                    <div className="resumeHeading_resumeBox">Resume</div>
                                    {getResume_poweResumeRight()}
                                    {profileCompletion?.resumeUrl ?
                                        <Button className="ReplaceFileButton" onClick={(e) => window.open(profileCompletion?.resumeUrl, "_blank")}>View Resume</Button>
                                        :
                                        <div></div>
                                    }
                                </div>
                                :
                                <div className="resumeCard_leftSide_poweResumeInfoCard">
                                    <div className="resumeHeading_resumeBox">Resume</div>
                                    <Skeleton variant="rectangle" height={13} width={50} />
                                    <Skeleton variant="rectangle" height={13} width={150} />
                                </div>
                            }
                            {
                                Object.keys(profileCompletion).length > 0 ?
                                    <div className="personalInfoCard_leftSide_poweResumeInfoCard">
                                        <div className="personalInfoCard_title_poweResumeInfoCard">Personal Information</div>
                                        <div className="personalInfocardItems_poweResumeCard"><img src={mobileIcon} className="icons_personalInfo_poweResumeRight" /> <span className="ml-3px">{profileCompletion?.user?.phoneNumber ? profileCompletion?.user?.countryCode + " " + profileCompletion?.user?.phoneNumber : 'Phone number not available'}</span></div>
                                        <div className="personalInfocardItems_poweResumeCard"><img src={locationIcon} className="locationicons_personalInfo_poweResumeRight" /> <span>{Object.keys(profileCompletion).length > 0 ? getCandidateAddress() : ''} </span></div>
                                        <div className="personalInfocardItems_poweResumeCard"><img src={linkdinIcon} className="linkedinicons_personalInfo_poweResumeRight" /> <div className="linkedinText_poweResumeRight ml-3px">{Object.keys(profileCompletion).length > 0 ? profileCompletion?.linkedInUrl ? profileCompletion?.linkedInUrl : 'LinkedIn URL not available' : ''}</div></div>
                                    </div>
                                    :
                                    <div className="personalInfoCard_leftSide_poweResumeInfoCard">
                                        <div className="personalInfoCard_title_poweResumeInfoCard">Contact Detalis</div>
                                        <Skeleton variant="rectangle" height={13} width={188} />
                                        <Skeleton variant="rectangle" height={13} width={180} />
                                        <Skeleton variant="rectangle" height={13} width={180} />
                                    </div>
                            }
                            {
                                Object.keys(profileCompletion).length > 0 ?
                                    <div className="videoIndtroCard_leftSide_poweResumeInfoCard">
                                        <span className="resumeHeading_resumeBox">Video Introduction</span>
                                        {profileCompletion?.videoIntroUrl ?
                                            <video
                                                className="videoIntroThumbnail_poweResumeRight"
                                                controls={false}
                                                onClick={recordedVideoModal}
                                                poster={Thumbnail}
                                            />
                                            : <div className="noVideoText_poweResume">No video introduction available</div>}
                                    </div>
                                    :
                                    <div className="videoIndtroCard_leftSide_poweResumeInfoCard">
                                        <span className="resumeHeading_resumeBox">Video Introduction</span>
                                        <Skeleton variant="rectangle" height={120} width={190} />
                                    </div>
                            }
                        </div>

                        <div className={!isShare ? "primarySkillContainer_poweResumeRight" : "primarySkillContainer_poweResumeRight_shared"}>
                            <span className="primarySkillTitle_poweResumeRight">Primary Skill</span>
                            {Object.keys(primarySkillsData).length > 0 ?
                                <div className="primarySKillMainContainer_poweResumeRight">
                                    <div className="primarySKillItemOne_poweResumeRight">
                                        <span className="PrimarySkillName_poweResumeRight">{primarySkillsData?.skill?.name ? primarySkillsData?.skill?.name : ''}</span>
                                        <span className="primarySkillExp_poweResumeRight">{primarySkillsData?.totalexperiencename ? primarySkillsData?.totalexperiencename : ''}</span>
                                    </div>
                                    <div className="primarySKillItemTwo_poweResumeRight">
                                        {/* <Rating name="read-only" value={3} readOnly /> */}

                                        <span className="SelfAssesmentTitle_poweResumeRight">Self Assessment</span>
                                        <div className="starRatingContainer_poweResumeRight">
                                            {starRatingCaluculation(primarySkillsData?.selfRating)}
                                        </div>
                                        {levelCreation(primarySkillsData?.selfRating)}
                                    </div>

                                </div>
                                :
                                <div className="primarySKillMainContainer_poweResumeRight">
                                    <div className="primarySKillItemOne_poweResumeRight">
                                        <span className="PrimarySkillName_poweResumeRight"><Skeleton variant="rectangle" height={15} width={100} /></span>
                                        <span className="primarySkillExp_poweResumeRight"><Skeleton variant="rectangle" height={13} width={100} /></span>
                                    </div>
                                    <div className="primarySKillItemTwo_poweResumeRight">
                                        {/* <Rating name="read-only" value={3} readOnly /> */}

                                        <span className="SelfAssesmentTitle_poweResumeRight"><Skeleton variant="rectangle" height={13} width={100} /></span>
                                        <div className="starRatingContainer_poweResumeRight">
                                            <Skeleton variant="circle" height={26} width={26} />
                                            <Skeleton variant="circle" height={26} width={26} />
                                            <Skeleton variant="circle" height={26} width={26} />
                                            <Skeleton variant="circle" height={26} width={26} />
                                            <Skeleton variant="circle" height={26} width={26} />
                                        </div>
                                        <Skeleton variant="rectangle" height={13} width={100} />
                                    </div>

                                </div>
                            }
                        </div>

                        <div className={!isShare ? "primarySkillContainer_poweResumeRight" : "primarySkillContainer_poweResumeRight_shared"}>
                            <span className="primarySkillTitle_poweResumeRight">Additional Skills</span>
                            {
                                Object.keys(profileCompletion).length > 0 ?
                                    otherSkills.length > 0 ?
                                        <>
                                            <AutoPlaySwipeableViews
                                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                index={activeStep}
                                                enableMouseEvents
                                            >
                                                {otherSkills.map((step, index) => (
                                                    <div key={step.label}>
                                                        {Math.abs(activeStep - index) <= 2 ? (
                                                            <div className="primarySKillMainContainer_poweResumeRight">
                                                                <div className="primarySKillItemOne_poweResumeRight">
                                                                    <span className="PrimarySkillName_poweResumeRight">{step?.skill?.name}</span>
                                                                    <span className="primarySkillExp_poweResumeRight">{step?.totalExperience ? step?.totalExperience?.name : ''}</span>
                                                                </div>
                                                                <div className="primarySKillItemTwo_poweResumeRight">

                                                                    <span className="SelfAssesmentTitle_poweResumeRight">Self Assessment</span>
                                                                    <div className="starRatingContainer_poweResumeRight">
                                                                        {starRatingCaluculation(step?.selfRating)}
                                                                    </div>
                                                                    {levelCreation(step?.selfRating)}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </AutoPlaySwipeableViews>
                                            <div className="additionalSKillScroll_poweResumeRight">
                                                <IconButton className="arraowIconContainer_powerResumeRight" onClick={() => handleBack()} disabled={activeStep === 0}><ArrowBackIos className="arraowIcon_powerResumeRight" /></IconButton>
                                                <div className="skillItemCountContainer_poweResumeRight">{activeStep + 1}<span className="skillItemCountContainer_poweResumeRight_opacity"> out of {otherSkills.length}</span></div>
                                                <IconButton className="arraowIconContainer_powerResumeRight" onClick={() => handleNext()} disabled={activeStep === otherSkills.length - 1}><ArrowForwardIos className="arraowIcon_powerResumeRight" /></IconButton>
                                            </div>
                                        </> : <p className="PrimarySkillName_poweResumeRight noAdditionalSkill">No additional skills available</p>
                                    :
                                    <div className="primarySKillMainContainer_poweResumeRight">
                                        <div className="primarySKillItemOne_poweResumeRight">
                                            <span className="PrimarySkillName_poweResumeRight"><Skeleton variant="rectangle" height={15} width={100} /></span>
                                            <span className="primarySkillExp_poweResumeRight"><Skeleton variant="rectangle" height={13} width={100} /></span>
                                        </div>
                                        <div className="primarySKillItemTwo_poweResumeRight">
                                            {/* <Rating name="read-only" value={3} readOnly /> */}

                                            <span className="SelfAssesmentTitle_poweResumeRight"><Skeleton variant="rectangle" height={13} width={100} /></span>
                                            <div className="starRatingContainer_poweResumeRight">
                                                <Skeleton variant="circle" height={26} width={26} />
                                                <Skeleton variant="circle" height={26} width={26} />
                                                <Skeleton variant="circle" height={26} width={26} />
                                                <Skeleton variant="circle" height={26} width={26} />
                                                <Skeleton variant="circle" height={26} width={26} />
                                            </div>
                                            <Skeleton variant="rectangle" height={13} width={100} />
                                        </div>

                                    </div>
                            }

                        </div>
                        <div className={!isShare ? "skillTestContainer_poweResumeRight" : "skillTestContainer_poweResumeRight_shared"}>
                            <CandidateTest
                                profileId={props.candidateId}
                                profileData={profileCompletion}
                                testAccessData={props.testAccess}
                                isShare={props.isShare}
                            />
                        </div>
                    </div>
                    <div className="rightSide_poweResumeInfoCard">
                        <div className="UpperSection_rightSide_poweResumeInfoCard">
                            <span className="manrope-18_poweResumeRight">Interview Information</span>
                            <span className="availabilty_poweResumeRight">Actively Seeking Opportunity</span>
                            {Object.keys(profileCompletion).length > 0 ?
                                <div className="radioOptionsContainer_poweResumeRight">
                                    <div className='radioOptions_poweResumeRight'>
                                        <Radio
                                            checked={profileCompletion?.activelySeekingOpportunity ? true : false}
                                            value="a"
                                            name="test3"
                                            inputProps={{ 'aria-label': 'A' }}
                                            color="primary"
                                            disabled
                                        />
                                        <label htmlFor="test3" className="radioLabel_poweResumeRight">Yes</label>
                                    </div>
                                    <div className="radioOptions_poweResumeRight">
                                        <Radio
                                            checked={profileCompletion?.activelySeekingOpportunity ? false : true}
                                            value="a"
                                            name="test4"
                                            inputProps={{ 'aria-label': 'A' }}
                                            color="primary"
                                            disabled
                                        />
                                        <label htmlFor="test4" className="radioLabel_poweResumeRight">No</label>
                                    </div>
                                </div>
                                :
                                <div className="radioOptionsContainer_poweResumeRight_skeleton">
                                    <Skeleton variant="rect" height={20} width={100} animation="wave" />
                                    <Skeleton variant="rect" height={20} width={100} animation="wave" />
                                </div>
                            }
                            <span className="weektitle_poweResumeRight">Weekdays</span>
                            {Object.keys(profileCompletion).length > 0 ?
                                generateTimesBtn(interViewSchedule[0]) ? (
                                    generateTimesBtn(interViewSchedule[0]).filter(
                                        (i) => i === undefined
                                    ).length === 4 ? (
                                        <p className="fs-regular fs-14 f-color df mt_12">
                                            Information not available
                                        </p>
                                    ) : (
                                        generateTimesBtn(interViewSchedule[0])
                                    )
                                ) : (
                                    <p className="fs-regular fs-14 f-color df mt_12">
                                        Information not available
                                    </p>
                                ) :
                                <>
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                </>
                            }
                            <span className="weektitle_poweResumeRight">Weekends</span>
                            {Object.keys(profileCompletion).length > 0 ?
                                generateTimesBtn(interViewSchedule[1]) ? (
                                    generateTimesBtn(interViewSchedule[1]).filter(
                                        (i) => i === undefined
                                    ).length === 4 ? (
                                        <p className="fs-regular fs-14 f-color df mt_12">
                                            Information not available
                                        </p>
                                    ) : (
                                        generateTimesBtn(interViewSchedule[1])
                                    )
                                ) : (
                                    <p className="fs-regular fs-14 f-color df mt_12">
                                        Information not available
                                    </p>
                                ) :
                                <>
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                    <Skeleton variant="rect" height={15} width={160} animation="wave" className="interViewTimeSkeleton" />
                                </>
                            }
                        </div>
                        <div className="dateTimeButtonContainer_poweResumeRight">
                            <Button className="dateTimeButton_poweResumeRight" disabled>
                                <img src={calendar} alt="img" />
                                <span className="modifyDateTimeBtnText">Manage Interview Date/Time</span>
                            </Button>
                            


                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                onClose={recordedVideoClose}
                open={recordedVideoOpen}
                className='recorded-video-container'
                aria-labelledby="customized-dialog-title"
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title" onClose={recordedVideoClose}
                    className='video-intro-heading'
                >
                    Video Introduction
                </DialogTitle>
                <DialogContent>
                    <video
                        className="recorded-video-intro"
                        src={profileCompletion?.videoIntroUrl ? profileCompletion?.videoIntroUrl : ''}
                        controls={true}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Powerresume;