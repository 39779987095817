import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Logo from "../../components/header/images/logo.png";

import getAllReferralSkiilsByRecruiterPartners from "../../apis/referral/referralbyrecruitmentpartner";
const ReferralInvite = (props) => {
  const { match } = props;
  const history = useHistory();
  const [isPageDisplay, setIsPageDisplay] = useState(false);



  useEffect(() => {
    const getAllReferralsByPartners = async () => {
      try {
        let data = match.params.id;
        const response = await getAllReferralSkiilsByRecruiterPartners(
          data
        );
        if (response.data.data.length !== 0) {
          history.push({
            pathname: "/referral",
            state: {
              recruiterPartnerId: data
            },
          });
        }
        else {
          setIsPageDisplay(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getAllReferralsByPartners();
  }, []);
  const goToHome = async (e) => {
    history.push("/");
  };
  return (
    <>
      {isPageDisplay ? (
        <>
          <div className="nav_bg">
            <div className="container register-test-container nav_menu_adjust">
              <div className="nav_bg_left">
                <img
                  src={Logo}
                  className="nav_logo cp"
                  onClick={(e) => goToHome(e)}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="container register-test-container">
            <div className="card cardheight cardheight_endtest success-card mt_10 text-center">
              <div className="end-test-container">
                <div className="start_test_text_top">
                  <div className="start_test_text_top after_endtest_submit">
                    <p className="fs-semi-bold fs-22 f-color mb_16 text-center">
                      The Invited Referral link is invalid
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ReferralInvite;
