import React, { useState, useEffect, useRef } from "react";
import "./skillVideotest.css";
import { useHistory } from "react-router-dom";
import Group174img from "./images/Group 174-disabled.png";
import Group175img from "./images/Group 175.png";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import backArrow from "./images/backarrow.png";
import nextArrow from "./images/nextarrow.png";
import incorrect from "./images/incorrect.png";
import correct from "./images/correct.png";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import cross from "./images/cross.svg";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import getSkillResponse from "../../../apis/skills/getActiveSkills";
import getAllNotDeletedUserTest from "../../../apis/userTest/getActiveCompletedUserTest";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import deleteUserSkill from "../../../apis/skills/deleteUserSkill";
import getSkillTestEligibility from "../../../apis/userTest/verifySkillTestEligibility";
import getSkillsByPopularityResponse from "../../../apis/skills/getSkillsByPopularity";
import formatQuestionWithCode from "../../../utilities/formatQuestionWithCode";
import getCompletedUserTestByUserId from "../../../apis/userTest/completedUserTestByUserId";
import getUserTestQuestionAnswer from "../../../apis/userTest/userTestQuestionAnswerByTestId";
import getUserTestByTestId from "../../../apis/userTest/userTestByTestId";
import getUserTestLimitResponse from "../../../apis/userTest/getUserTestLimit";
import getInitiatedOrCompletedUserTests from "../../../apis/userTest/getInitiatedOrCompletedUserTests";
import questionReportResponse from "../../../apis/questionReport/addQuestionReport";
import testQuestionDescription from "../../../utilities/questionSyntaxHighlight.js";
import Prism from "prismjs";
import getAllPublicTest from "../../../apis/Tests/getAllPublicTest";
import UpdateTestIspublished from "../../../apis/userTest/publishTestByTestID"
import { DateTime } from "luxon";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const filter = createFilterOptions();
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});
const filterSkillOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.testName,
});

const useStyles = makeStyles((theme) => ({
  btnpostion: {
    display: "flex",
    padding: "0px!important",
    marginTop: "20px",
    background: "none!important",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end!important",
  },
  btnstyle: {
    padding: "0px!important",
    background: "none!important",
    maxWidth: "unset !important",
  },
  swipeablecontainer: {
    width: "99.9%",
  },
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const SkillVideoTest = (props) => {
  const { userIdSkillVideo, mailData ,email} = props;

  const img_url = "http://localhost:3000/uploads/";
  const jsonConfig = require("../../../Config.json");

  let history = useHistory();
  const [modalStatus, setModalStatus] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  const [questionTime, setQuestionTime] = useState("");
  const [answerTime, setAnswerTime] = useState("");
  const [userAnswersResponse, setUserAnswerResponse] = useState([]);
  const [userAnswerList, setUserAnswerList] = useState([]);
  const [skillTestDate, setSkillTestDate] = useState("");
  const userAnswerResponseRef = useRef(0);
  const [loadingController, setLoadingController] = useState(false);
  const [isReportModalOpen, setisReportModalOpen] = useState(false);
  const [reportDescription, setreportDescription] = useState("");
  const [QuestionIndexForReport, setQuestionIndexForReport] = useState("");
  const [userId, setuserId] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skillName, setSkillName] = useState("");
  const [userTestId, setuserTestId] = useState("");
  const [isSharebtnClicked, setisSharebtnClicked] = useState(false);
  const textAreaRef = useRef(null);
  const [isPublishedChecked, setIsPublishedChecked] = useState();
  const [skillSelectModalShow, setskillSelectModalShow] = useState(false);
  const [deleteSkillTestModal, setdeleteSkillTestModal] = useState(false);
  const [confirmDeleteModal, setconfirmDeleteModal] = useState(false);
  const [testForDelete, settestForDelete] = useState({});
  const [allSkillArr, setallSkillArr] = useState([]);
  const [allUserTest, setallUserTest] = useState([]);
  const [selectedSkillForTest, setselectedSkillForTest] = useState({});
  const [skillArrForDelete, setskillArrForDelete] = useState([]);
  const [blockDevices, setBlockDevices] = useState("");
  const [maxSteps, setMaxSteps] = useState(0);
  const [skillTestLimit, setskillTestLimit] = useState("");
  const [totalTakenTest, settotalTakenTest] = useState("")
  // props.location.state ? props.location.state.text ? true : false : ''

  //const maxSteps = userAnswersResponse.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    // alert(step)
  };
  // Open Skill Test Modal
  const openSkilltestModal = async () => {
    await getTestDetails(
      userAnswersResponse[userAnswerResponseRef.current]
        ? userAnswersResponse[userAnswerResponseRef.current].id
          ? userAnswersResponse[userAnswerResponseRef.current].id
          : ""
        : ""
    );
    setModalStatus(true);
  };

  // Close Skill Test Modal
  const closeSkillTestModal = (event) => {
    setModalStatus(false);
  };

  // Toggle Questions
  const questionsHandler = (index) => {
    let inputCheckboxes = document.querySelectorAll(".clearcheckbox");
    for (let i = 0; i < inputCheckboxes.length; i++) {
      inputCheckboxes[i].checked = false;
    }
    setQuestionIndex(index);
    setQuestionNumber(index + 1);
  };

  // Get all user test
  const getUserTests = async () => {
    try {
      const userId = userIdSkillVideo;
      setuserId(userIdSkillVideo);
      //const userTestApiResponse =await getCompletedUserTestByUserId(userIdSkillVideo)
      const userTestApiResponse = await getInitiatedOrCompletedUserTests(
        userIdSkillVideo
      );    
      setMaxSteps(userTestApiResponse.data.data.length);
      setUserAnswerResponse(userTestApiResponse.data.data);
      setSkillName(
        userTestApiResponse.data.data.map((it) => it.test.testName).toString()
      );
      setLoadingController(true);
    } catch (error) {
      setLoadingController(true);
      console.error(error);
    }
  };
  const questionCardSliderHandler = async (action) => {
    if (action == "increment") {
      handleNext();
      if (
        userAnswerResponseRef.current + 1 <= userAnswersResponse.length &&
        userAnswerResponseRef.current + 1 >= 0
      ) {
        userAnswerResponseRef.current = userAnswerResponseRef.current + 1;
        await getTestDetails(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
        await getSkillName(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
      }
    }
    if (action == "decrement") {
      handleBack();
      if (
        userAnswerResponseRef.current - 1 <= userAnswersResponse.length &&
        userAnswerResponseRef.current - 1 >= 0
      ) {
        userAnswerResponseRef.current = userAnswerResponseRef.current - 1;
        await getTestDetails(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
        await getSkillName(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
      }
    }
  };

  // Get Test Details
  const getTestDetails = async (testId) => {
    // setLoadingController(false);
    try {
      if (testId) {
        const userTestDetails = await getUserTestQuestionAnswer(testId);
        const tempUsersTestDetails = userTestDetails.data.data;      
        let totalQuestionSecondsAlloted = 0;
        let totalQuestionMinutesAlloted = 0;
        tempUsersTestDetails.forEach((el) => {
          totalQuestionSecondsAlloted =
            totalQuestionSecondsAlloted +
            Number.parseInt(el.userTestQuestionQuestion.timeLimit);
        });
        if (totalQuestionSecondsAlloted > 60) {
          totalQuestionMinutesAlloted = totalQuestionSecondsAlloted / 60;
          const time =
            totalQuestionMinutesAlloted % 1 === 0
              ? totalQuestionMinutesAlloted
              : parseFloat(totalQuestionMinutesAlloted).toFixed(1);
          setQuestionTime(time + " Minutes");
        } else {
          setQuestionTime(totalQuestionSecondsAlloted + " Seconds");
        }

        let totalAnswerSecondsAlloted = 0;
        let totalAnswerMinutesAlloted = 0;
        tempUsersTestDetails.forEach((el) => {
          totalAnswerSecondsAlloted =
            totalAnswerSecondsAlloted + Number.parseInt(el.userTimeTaken);
        });
        if (totalAnswerSecondsAlloted > 60) {  
          let minute = ~~((totalAnswerSecondsAlloted % 3600) / 60);
          let second = totalAnswerSecondsAlloted % 60;
          setAnswerTime(
            minute +
              (minute === 1 ? " minute" : " minutes") +
              " " +
              second +
              " seconds"
          );
        } else {
          setAnswerTime(totalAnswerSecondsAlloted + " Seconds");
        }
        setTotalQuestions(tempUsersTestDetails.length);
        let userTestScore = 0;
        tempUsersTestDetails.forEach((el) => {
          if (el.isCorrect) {
            userTestScore = userTestScore + 1;
          }
        });
        setTotalCorrectAnswers(userTestScore);
        // setSkillTestDate(formatDate(tempUsersTestDetails[0].createdAt));
        setSkillTestDate(
          new Intl.DateTimeFormat("en", { month: "long" }).format(
            new Date(tempUsersTestDetails[0].createdAt)
          ) +
            "-" +
            new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
              new Date(tempUsersTestDetails[0].createdAt)
            ) +
            "-" +
            new Intl.DateTimeFormat("en", { year: "numeric" }).format(
              new Date(tempUsersTestDetails[0].createdAt)
            ) +
            " " +
            new Intl.DateTimeFormat("en", {
              hour: "numeric",
              minute: "numeric",
            }).format(new Date(tempUsersTestDetails[0].createdAt))
        );
        setUserAnswerList(userTestDetails.data.data);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  // skillName

  const getSkillName = async (testId) => {
    try {
      if (testId) {
        const userTestSkill = await getUserTestByTestId(testId);
        // setSkillName(userTestSkill.data.data);
        setuserTestId(userTestSkill.data.data.id);
        setIsPublishedChecked(userTestSkill.data.data.isPublished);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // shrare btn clicked
  const sharebtnClickedHandler = (e) => {
    setisSharebtnClicked(true);
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    // setLinkToShare('Copied!');

    setTimeout(sharebtnTxtChange, 5000);
  };
  const sharebtnTxtChange = () => {
    setisSharebtnClicked(false);
  };

  // Format Date
  const formatDate = (dateVal) => {
    let date = new Date(dateVal);

    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  };

  // Take Skill Test
  const takeSkillTest = async (event) => {
    let SkillTestEligibility = await getSkillTestEligibility(userIdSkillVideo);
    if (SkillTestEligibility.isEligibleToTakeTest) {
      const allUserTestResponse = await getAllNotDeletedUserTest(
        userIdSkillVideo
      );
      settotalTakenTest(allUserTestResponse.length)
      let getSkillTestLimit = await getUserTestLimitResponse();
      setskillTestLimit(getSkillTestLimit.data.data[0].value)

      if (allUserTestResponse.length >= getSkillTestLimit.data.data[0].value) {
        setdeleteSkillTestModal(true);
        setallUserTest(allUserTestResponse);
      } else {
        setskillSelectModalShow(true);
        let allSkillResponse = await getAllPublicTest();  
        setallSkillArr(allSkillResponse);
      }
    } else {
      let nextEligibleDate =
        new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
          new Date(SkillTestEligibility.testEligibilityDate)
        ) +
        "-" +
        new Intl.DateTimeFormat("en", { month: "long" }).format(
          new Date(SkillTestEligibility.testEligibilityDate)
        ) +
        "-" +
        new Intl.DateTimeFormat("en", { year: "numeric" }).format(
          new Date(SkillTestEligibility.testEligibilityDate)
        );
      NotificationManager.error(
        `You have taken a skill test recently. You can only take a skill test after ${SkillTestEligibility.skillTestInterval} days of the last test.`,
        "Error",
        8000
      );
    }
  };

  const deleteSkillTestModalClose = () => {
    setdeleteSkillTestModal(false);
  };
  const skillSelectModalClose = () => {
    setskillSelectModalShow(false);
  };
  const testChangeHandler = (e, value) => {
    if (value) {
      setselectedSkillForTest(value);
    } else {
      setselectedSkillForTest({});
    }
  };
  const sartUserTestHandler = () => {
    if (selectedSkillForTest.id) {
      history.push({
        pathname: "/candidate-test-landing",
        state: {
          userId: userIdSkillVideo,
          test: selectedSkillForTest,
          email:email
        },
      });
    } else {
      NotificationManager.error("Select a skill to take test", "Error");
    }
  };
  const deleteChkBoxHandler = (e, userTest) => {
    // setskillArrForDelete([skillId])
    settestForDelete(userTest);
  };

  const deleteskillHandler = async () => {
    if (testForDelete?.userTest?.id) {
      setconfirmDeleteModal(true);
      setskillArrForDelete([testForDelete.userTest?.id]);
    }
  };
  const closeConfirmModal = () => {
    setconfirmDeleteModal(false);
  };
  const confirmDeleteHandler = async () => {
    let deletedTestResponse = await deleteUserSkill(skillArrForDelete);
    if (deletedTestResponse.includes("updated successfully")) {
      NotificationManager.success("Test deleted", "Success");
      await getUserTests();
      setdeleteSkillTestModal(false);
      setconfirmDeleteModal(false);
    }
  };

  // report this question
  const goToReportQuestion = (index) => {
    setQuestionIndexForReport(index);
    setisReportModalOpen(true);
  };
  const closeReportModal = () => {
    setisReportModalOpen(false);
    setreportDescription("");
  };
  const saveReport = async () => {
    var reportdata = {
      userId: userId,
      questionId: userAnswerList[QuestionIndexForReport].questionId,
      description: reportDescription,
      testquestionId: userAnswerList[QuestionIndexForReport].id,
      reportStatus: "Unresolved",
    };

    try {
      const saveReportResponse = await questionReportResponse(reportdata);   
      setisReportModalOpen(false);
      NotificationManager.success("Question Reported", "Success");
    } catch (error) {
      console.log(error);
      NotificationManager.error("Question report failed", "error");
    }
  };

  // Use Effect Get Test
  useEffect(() => {
    getUserTests();
  }, []);

  // Use Effect Get Test Details
  useEffect(() => {
    getTestDetails(
      userAnswersResponse[0]
        ? userAnswersResponse[0].id
          ? userAnswersResponse[0].id
          : ""
        : ""
    );

    getSkillName(
      userAnswersResponse[0]
        ? userAnswersResponse[0].id
          ? userAnswersResponse[0].id
          : ""
        : ""
    );
  }, [userAnswersResponse]);

  const responsive = {
    0: {
      items: 3,
    },
    600: {
      items: 3,
    },
    1024: {
      items: 12,
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const timeLimitStyle = {
    fontSize: "12px",
  };

  const handleIsPublished = async (e, testId) => {
    let checkPublished;
    if (e.target.checked === true) {
      setIsPublishedChecked(true);
      checkPublished = true;
    } else {
      setIsPublishedChecked(false);
      checkPublished = false;
    }
    const data = {
      isPublished: checkPublished,
    };
    try {
      const payload = {
        id: testId,
        data: data,
      }
      const publish = await UpdateTestIspublished(payload);
      if (checkPublished) {
        NotificationManager.success("Skill test published", "Success");
      } else if (!checkPublished) {
        NotificationManager.success("Skill test unpublished", "Success");
      }
    } catch (error) {
      setIsPublishedChecked(false);
      console.log(error);
    }
  };
  const convertSecondToMinutes = (sec) => {
    let convertedTime;
    if (sec > 60) {
      let min = (sec / 60).toString().split(".")[0];
      let Second = Number((sec / 60).toFixed(2).toString().split(".")[1]) * 0.6;
      convertedTime = `${min} Minutes ${Second} seconds`;
    } else if (sec === 60) {
      convertedTime = `1 Minute`;
    } else {
      convertedTime = `${sec} seconds`;
    }
    return convertedTime;
  };

  useEffect(() => {
    const fetchAllVideos = async () => {
      if (userAnswerList) { 
        for (const answer of userAnswerList) {
          if (answer && answer.videoURL) {
            await fetch(answer.videoURL);
          }
        }
      }
    };
    fetchAllVideos();
  }, [userAnswerList]);
  setTimeout(() => Prism.highlightAll(), 0);
  return (
    <>
      {loadingController ? (
        <>
          <div>
            <div className="powres_card mt_20">
              <div className="row">
                <div className="skillvideo_title">
                  <p className="mn_normal fs-25 f-color">Skill Video Test</p>

                  {/* { userAnswerList.length===0?
                  null:
                    <p className="fs-regular fs-14 f-color">

                      <button
                        type="button"
                        className="btn btn-skill cp"
                        onClick={(e) => takeSkillTest(e)}
                      >
                        Take Skill Test (For Testing)
</button>
                    </p>} */}
                </div>

                {/* {userAnswerList.length > 0 ? */}
                {/* ( */}
                <>
                  {/* for test */}

                  <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    className={classes.swipeablecontainer}

                    // onChangeIndex={handleStepChange}
                    // enableMouseEvents
                  >
                    {userAnswersResponse.map((step, index) => (
                      <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          // <img className={classes.img} src={step.imgPath} alt={step.label} />
                          <div className="individual-skill-videos">
                            <div className="row df">
                              <div className="skill_video_sec_right ml_22 skillvideocodingtest">
                                <div className="fs-18 fs-semi-bold test-status-wrapper">
                                  <p
                                    style={
                                      step.userTestTestStatus.code ===
                                      "completed"
                                        ? { color: "#2AD156" }
                                        : { color: "#FF4141" }
                                    }
                                  >
                                    {step.userTestTestStatus.code ===
                                    "completed"
                                      ? "Completed"
                                      : "Exited before completion"}
                                  </p>
                                </div>
                                <p className="fs-semi-bold fs-18 f-color">
                                  {/* React Native - Advance coding test */}
                                  {step?.test?.testName}
                                  {step?.test?.testCode &&
                                    `(${step?.test?.testCode})`}
                                </p>
                                <p className="fs-regular fs-12 f-color mt_6">
                                  <span>
                                    Skill Test Taken On:

                                        {" "+DateTime.fromISO(step.createdAt).toFormat('dd-LLL-yyyy')}
                                  </span>
                                  <br className="responsiveSkillTestInfo"></br>
                                  <span className="ml_12">
                                    Skill Test Duration: {questionTime}
                                  </span>
                                  <br className="responsiveSkillTestInfo"></br>
                                  <span className="ml_12">
                                    {" "}
                                    Time Taken By Candidate: {answerTime}
                                  </span>
                                </p>
                                <p className="fs-semi-bold fs-16 f-color mt_6">
                                  Skills Test Score
                                </p>
                                <p className="fs-regular fs-14 f-color mt_6">
                                 <div className="skillScore">
                                 <span>Total Questions</span>
                                  <span className="fs-semi-bold fs-18 ml_21">
                                    {totalQuestions}
                                  </span>
                                  <br className="skillTestResponsiveScore"></br>
                                  <span className="fs-regular fs-14 ml_25 skillTestResponsive ">
                                    Skill Test Score
                                  </span>
                                  <span className="fs-semi-bold fs-18 ml_5 green_text ml_25">
                                    {totalCorrectAnswers}
                                  </span>
                                  <span className="fs-regular fs-18 ml_5">
                                    out of
                                  </span>
                                  <span className="fs-semi-bold fs-18 ml_5">
                                    {totalQuestions}
                                  </span>{" "}
                                 </div>

                                </p>
                                <hr className="new1 mt_12" />
                                <div className="video-interview-chkbox">
                                  <input
                                    type="checkbox"
                                    key={step.id}
                                    id={step.id}
                                    defaultChecked={isPublishedChecked}
                                    value={true}
                                    onClick={(e) =>
                                      handleIsPublished(e, step.id)
                                    }
                                  />
                                  <label htmlFor={step.id}>
                                    Publish on my profile
                                  </label>
                                </div>

                                {/* <div className="skill_video_test_btnsection">
                                  <div className="add_email_box_skillvideo_test mt_33">
                                    <input
                                      type="text"
                                      className="search-input"
                                      value={
                                        "https://" +
                                        window.location.host +
                                        "/test/" +
                                        userTestId
                                      }
                                      ref={textAreaRef}
                                    />

                                    <button
                                      className="search-button"
                                      onClick={(e) => sharebtnClickedHandler(e)}
                                    >
                                      {isSharebtnClicked
                                        ? "Copied"
                                        : "Copy Link"}
                                    </button>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-green pull-right cp"
                                    onClick={(e) => openSkilltestModal(e)}
                                  >
                                    View Skill Test
                                  </button>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </AutoPlaySwipeableViews>
                  <div className="powres_togle">                  
                    <div>
                    <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    className={classes.btnpostion}
                    nextButton={
                      <Button
                        className={classes.btnstyle}
                        size="small"
                        onClick={() => questionCardSliderHandler("increment")}
                        disabled={activeStep === maxSteps - 1}
                      >
                        {theme.direction === "rtl" ? (
                          <img className="cp" src={backArrow} />
                        ) : (
                          <img className="cp" src={nextArrow} />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        className={classes.btnstyle}
                        size="small"
                        onClick={() => questionCardSliderHandler("decrement")}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <img className="cp" src={nextArrow} />
                        ) : (
                          <img className="cp" src={backArrow} />
                        )}
                      </Button>
                    }
                  />
                    
                    </div>
<div className="skill_video_next mt_12">
                  {blockDevices && (
                    <span className="newskilltest_block_note fs-semi-bold block-device-color fs-12 mt-10">
                      Please click on the lock icon
                      <i class="fa fa-lock ml_5 mr_5" aria-hidden="true"></i>
                      at the top left of the browser's address bar and allow
                      permissions for camera and microphone.
                    </span>
                  )}
                  <p className="fs-regular fs-14 f-color pt_13">
                    <button
                      type="button"
                      className="btn btn-skill cp pull-right"
                      onClick={(e) => takeSkillTest(e)}
                    >
                      Take Skill Test
                    </button>
                  </p>
                </div>
                </div>
                </>
                {/* ) :
                    ( */}
                {/* <>  */}
                {/* <p className="fs-regular fs-16 mt_12">Take your first skill video test</p> */}
                {/* <div className="skill_video_next mt_12">
                  {blockDevices && (
                    <span className="newskilltest_block_note fs-semi-bold block-device-color fs-12 mt-10">
                      Please click on the lock icon
                      <i class="fa fa-lock ml_5 mr_5" aria-hidden="true"></i>
                      at the top left of the browser's address bar and allow
                      permissions for camera and microphone.
                    </span>
                  )}
                  <p className="fs-regular fs-14 f-color pt_13">
                    <button
                      type="button"
                      className="btn btn-skill cp pull-right"
                      onClick={(e) => takeSkillTest(e)}
                    >
                      Take Skill Test
                    </button>
                  </p>
                </div> */}
                {/* </>
                    )
                  } */}
              </div>
            </div>
            <Dialog
              fullScreen
              open={modalStatus}
              onClose={closeSkillTestModal}
              aria-labelledby="customized-dialog-title"
              TransitionComponent={Transition}
              // maxWidth="sm"
              //  fullWidth={true}
              className="fullscreen-width full_screen_from_top"
            >
              {/* <DialogTitle onClose={closeSkillTestModal}>Skill Video Test</DialogTitle> */}
              <div
                className="skill-video-crossFormodal"
                onClick={closeSkillTestModal}
              >
                <img className="closeicon_set" src={cross} alt=""></img>
              </div>
              <DialogContent className="mt_20">
                <div className="playbuttonsection">
                  {/* <div>
                      <img src={Group121} alt="" className="playButton" />
                    </div> */}
                  <div>
                    <p className="fs-semi-bold fs-16 f-color mb_3">
                      {/* React Native - Advance coding test */}
                      {skillName?.userTestSkill?.name}
                    </p>
                    <p className="fs-regular fs-14 f-color mb_3">
                      Skill Test Taken On: {skillTestDate}
                    </p>
                    <p className="fs-regular fs-14 f-color mb_3">
                      Skill Test Duration: {questionTime}
                    </p>
                    <p className="fs-regular fs-14 f-color mb_3">
                      Time Taken By Candidate: {answerTime}
                    </p>
                  </div>
                </div>
                <hr className="new1" />
                <p className="fs-semi-bold fs-16 f-color">Score Card</p>
                <div className="scorecardsection">
                  <div>
                    <p className="fs-regular fs-14 f-color">Total Questions</p>
                    <p className="fs-semi-bold fs-18">{totalQuestions}</p>
                  </div>
                  <div className="ml_41">
                    <p className="fs-regular fs-14 f-color">
                      Total Correct Answers
                    </p>
                    <span className="fs-semi-bold fs-18 ml_5 green_text">
                      {totalCorrectAnswers}
                    </span>
                    <span className="fs-regular fs-18 ml_5">out of</span>
                    <span className="fs-semi-bold fs-18 ml_5">
                      {totalQuestions}
                    </span>
                  </div>
                  <div className="ml_41">
                    <p className="fs-regular fs-14 f-color">Skill Test Score</p>
                    <span className="fs-semi-bold fs-18 ml_5 green_text">
                      {totalCorrectAnswers}
                    </span>
                    <span className="fs-regular fs-18 ml_5">out of</span>
                    <span className="fs-semi-bold fs-18 ml_5">
                      {totalQuestions}
                    </span>
                  </div>

                  <div className="ml_41">
                    <p className="fs-regular fs-14 f-color">
                      Number of Violation
                    </p>
                    <span className="fs-semi-bold fs-18 ml_5 green_text">
                      {skillName.NumberofViolations
                        ? skillName.NumberofViolations
                        : 0}
                    </span>
                  </div>
                </div>
                <hr className="new1 " />
                <p className="fs-semi-bold fs-16 f-color">Questions</p>

                <div className="row mt_17">
                  <AliceCarousel responsive={responsive}>
                    {userAnswerList.map((question, index) =>
                      userAnswerList[Number(index)]
                        ?.userTestQuestionUserTestQuestionAnswer?.length ===
                      0 ? (
                        <button
                          type="button"
                          className={
                            questionIndex === index
                              ? "btnquestion btnquestion-questionselected  cp"
                              : "btnquestion btnquestion-questiondisable  cp"
                          }
                          key={index}
                          onClick={() => questionsHandler(index)}
                        >
                          <span>{index + 1}</span>
                          <span className="btn-icon">
                            <img
                              src={
                                questionIndex === index
                                  ? Group175img
                                  : Group174img
                              }
                              alt=""
                            />
                          </span>
                        </button>
                      ) : question.isCorrect === true ? (
                        <button
                          type="button"
                          className={
                            questionIndex === index
                              ? "btnquestion btnquestion-questionselected  cp"
                              : "btnquestion btnquestion-questionright  cp"
                          }
                          key={index}
                          onClick={() => questionsHandler(index)}
                        >
                          <span>{index + 1}</span>
                          <span className="btn-icon">
                            <img src={Group175img} alt="" />
                          </span>
                        </button>
                      ) : question.isCorrect === false ? (
                        <button
                          type="button"
                          className={
                            questionIndex === index
                              ? "btnquestion btnquestion-questionselected  cp"
                              : "btnquestion btnquestion-questionwrong  cp"
                          }
                          key={index}
                          onClick={() => questionsHandler(index)}
                        >
                          <span>{index + 1}</span>
                          <span className="btn-icon">
                            <img src={Group175img} alt="" />
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={
                            questionIndex === index
                              ? "btnquestion btnquestion-questionselected  cp"
                              : "btnquestion btnquestion-questiondisable  cp"
                          }
                          key={index}
                          onClick={() => questionsHandler(index)}
                        >
                          <span>{index + 1}</span>
                          <span className="btn-icon">
                            <img src={Group175img} alt="" />
                          </span>
                        </button>
                      )
                    )}
                  </AliceCarousel>
                </div>
                <hr className="new1 mt_17" />
                <div className="row df skillvideo_btm_adjust">
                  <div className="mw">
                    <div className="df">
                      <div>
                        <p className="fs-regular fs-14 f-color">
                          Video response time limit:
                        </p>
                        <p className="fs-semi-bold fs-16 f-color">
                          Response time:
                        </p>
                      </div>

                      <div className="text-right">
                        <p className="fs-regular fs-14 f-color">
                          {userAnswerList[questionIndex]
                            ?.userTestQuestionQuestion?.timeLimit + " seconds"}
                        </p>
                        <p className="fs-semi-bold fs-14 f-color">
                          {userAnswerList[questionIndex]?.userTimeTaken +
                            " seconds"}
                        </p>
                      </div>
                    </div>
                    <video
                      autoPlay
                      src={
                        userAnswerList.length > 0
                          ? userAnswerList[questionIndex].videoURL
                            ? userAnswerList[questionIndex].videoURL
                            : ""
                          : ""
                      }
                      className="video-interview-test mt_17"
                      controls={true}
                    />
                  </div>
                  <div className="vl"></div>
                  <div className="mw ml_22 scrollHeight1 viewtestresults-questionside">
                    <div className="fs-semi-ui-class fs-16 f-color videobuttomquestion">
                      <span className="fs-20">Q.{questionNumber}</span>
                      <span className="pt_4  pl_6" id="testQuestionDescp">
                        {testQuestionDescription(
                          userAnswerList[Number(questionIndex)]
                            ? userAnswerList[Number(questionIndex)]
                                .userTestQuestionQuestion
                              ? userAnswerList[Number(questionIndex)]
                                  .userTestQuestionQuestion.questionDesc
                                ? userAnswerList[Number(questionIndex)]
                                    .userTestQuestionQuestion.questionDesc
                                : null
                              : null
                            : null
                        )}
                      </span>
                    </div>
                    {userAnswerList[questionIndex]
                      ? userAnswerList[questionIndex].questionimg === ""
                        ? ""
                        : // (
                          //   <div className="mt_16">
                          //     <img
                          //       src={
                          //         img_url + userAnswerList[questionIndex].questionimg
                          //       }
                          //       alt=""
                          //     />
                          //   </div>
                          // )
                          null
                      : null}
                    {userAnswerList[questionIndex]
                      ? userAnswerList[questionIndex].userTestQuestionQuestion
                        ? userAnswerList[questionIndex].userTestQuestionQuestion
                            .questionAnswer
                          ? userAnswerList[questionIndex]
                              .userTestQuestionQuestion.questionAnswer.length >
                            0
                            ? userAnswerList[
                                questionIndex
                              ].userTestQuestionQuestion.questionAnswer.map(
                                (options, index) => (
                                  <p
                                    className={
                                      userAnswerList[
                                        Number(questionIndex)
                                      ]?.userTestQuestionUserTestQuestionAnswer.find(
                                        (el) =>
                                          el.userTestQuestionAnswerAnswer
                                            .isCorrect === true
                                      )
                                        ? "questionoption questionoption-alignment right-question-option"
                                        : "questionoption questionoption-alignment wrong-question-option"
                                    }
                                    key={"v-ops-" + index}
                                  >
                                    {userAnswerList[Number(questionIndex)]
                                      .userTestQuestionQuestion
                                      .questionAnswertype.code ==
                                    "SingleChoice" ? (
                                      <>
                                        <input
                                          type="radio"
                                          name="radio-group"
                                          id={options.id + "-radio-" + index}
                                          checked={
                                            userAnswerList[
                                              Number(questionIndex)
                                            ]
                                              ?.userTestQuestionUserTestQuestionAnswer[0]
                                              ?.answerId == options.id
                                          }
                                          //  == userAnswerList[Number(questionIndex)].userTestQuestionUserTestQuestionAnswer[0].id
                                          value={options.id}
                                          disabled={true}
                                        />
                                        <label
                                          htmlFor={options.id}
                                          className="fs-semi-ui-class fs-18 f-color"
                                        >
                                          {options.answerText}
                                        </label>
                                      </>
                                    ) : (
                                      <>
                                        <form>
                                          <div className="interview-chkbox">
                                            <input
                                              type="checkbox"
                                              className={
                                                userAnswerList[
                                                  Number(questionIndex)
                                                ].isCorrect
                                                  ? "right-checkbox clearcheckbox"
                                                  : "wrong-checkbox clearcheckbox"
                                              }
                                              id={
                                                options.id +
                                                "-checkbox-" +
                                                index
                                              }
                                              checked={userAnswerList[
                                                Number(questionIndex)
                                              ].userTestQuestionUserTestQuestionAnswer.find(
                                                (el) =>
                                                  el.answerId == options.id
                                              )}
                                              value={options.id}
                                              disabled={true}
                                            />
                                            <label htmlFor={options.id}>
                                              {options.answerText}
                                            </label>
                                          </div>
                                        </form>
                                      </>
                                    )}
                                  </p>
                                )
                              )
                            : null
                          : null
                        : null
                      : null}
                    <div className="report_question">
                      {userAnswerList[Number(questionIndex)] ? (
                        userAnswerList[Number(questionIndex)].isCorrect ? (
                          <div className="">
                            {userAnswerList[Number(questionIndex)]
                              ?.isCorrect === true ? (
                              <img src={correct} />
                            ) : null}
                          </div>
                        ) : (
                          <div className="">
                            {userAnswerList[Number(questionIndex)]
                              ?.isCorrect === false ? (
                              <img src={incorrect} />
                            ) : null}
                          </div>
                        )
                      ) : null}
                      <button
                        type="button"
                        id="toggle-question"
                        className="btn btn-blue btn-blue_adjust ml_25 cp"
                        onClick={(e) => goToReportQuestion(questionIndex)}
                      >
                        Report Question
                      </button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <Dialog
            open={isReportModalOpen}
            onClose={closeReportModal}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              id="form-dialog-title"
              className={`${classes.title} responsive_title`}
            ></DialogTitle>

            <DialogContent
              className={`${classes.bodyForSupport} responsive_Support`}
            >
              <legend>Description*</legend>
              <textarea
                type="text"
                className="text_area_cleartalent_support"
                rows={4}
                placeholder="Provide more details on the report"
                onChange={(event) => setreportDescription(event.target.value)}
              />
            </DialogContent>
            <DialogActions className={`${classes.buttonForSupport} `}>
              <button
                type="button"
                className="btn-big btn-skill cp"
                onClick={closeReportModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-big btn_powres cp"
                onClick={(e) => saveReport()}
              >
                Submit
              </button>
            </DialogActions>
          </Dialog>
          {/* skills dropdown modal */}
          <Dialog
            open={skillSelectModalShow}
            onClose={skillSelectModalClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              id="form-dialog-title"
              className={`${classes.title} responsive_title`}
            >
              {/* Select Skill */}
            </DialogTitle>

            <DialogContent
              className={`${classes.bodyForSupport} responsive_Support`}
            >
              <div className="select customized-select mt_19">
                <legend>Tests*</legend>
                <Autocomplete
                  id="combo-box-select-primary-skill"
                  options={allSkillArr}
                  filterOptions={filterSkillOptions}
                  getOptionLabel={(option) => option.testName}
                  onChange={testChangeHandler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select test"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </DialogContent>
            <DialogActions className={`${classes.buttonForSupport} `}>
              <button
                type="button"
                className="btn-big btn-skill cp"
                onClick={skillSelectModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-big btn_powres cp"
                onClick={(e) => sartUserTestHandler()}
              >
                Start Test
              </button>
            </DialogActions>
          </Dialog>
          {/* delete skilltest modal */}
          <Dialog
            open={deleteSkillTestModal}
            onClose={deleteSkillTestModalClose}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              id="form-dialog-title"
              className={`${classes.title} responsive_title`}
            >
              {/* YOU HAVE ALREADY TAKEN 3 TESTS  */}
            </DialogTitle>

            <DialogContent
              className={`${classes.bodyForSupport} responsive_Support`}
            >
              <p className="fs-semi-bold fs-18 f-color pb_10">
                YOU HAVE {totalTakenTest} { totalTakenTest>1?`TESTS`:`TEST`}  ALREADY IN YOUR POWERESUME
           
              </p>
              <p className="fs-semi-bold fs-14 f-color pb_40">
                You can have a maximum of {skillTestLimit }  {skillTestLimit>1?`tests`:`test` } on your PoweResume. Select a
                test to delete before taking another.
              </p>

              {allUserTest.map((test, index) => (
                <div className="skill_video_test_deletemodal" key={index}>
                  <div className="row df">
                    <p className="skill_delete_radio">
                      <input
                        type="radio"
                        id={test[0]?.userTestId + "/deletetest"}
                        name="radio-group"
                      />
                      <label
                        htmlFor={test[0]?.userTestId + "/deletetest"}
                        className="fs-semi-bold fs-18 f-color"
                        onClick={(e) => deleteChkBoxHandler(e, test[0])}
                      ></label>
                    </p>

                    <div className="skill_video_sec_right ml_22 skillvideocodingtest">
                      <p className="fs-semi-bold fs-20 f-color">
                        {test[0]?.userTest?.test?.testName}
                      </p>
                      <p className="fs-regular fs-14 f-color mt_6">
                        <span>
                          Skill Test Taken On:
                          {" " +
                            new Intl.DateTimeFormat("en", {
                              day: "2-digit",
                            }).format(new Date(test[0]?.userTest?.createdAt)) +
                            "-" +
                            (new Intl.DateTimeFormat("en", {
                              month: "long",
                            }).format(new Date(test[0]?.userTest?.createdAt)) +
                              "-" +
                              new Intl.DateTimeFormat("en", {
                                year: "numeric",
                              }).format(
                                new Date(test[0]?.userTest?.createdAt)
                              ))}
                        </span>
                        <span className="ml_12">
                          Skill Test Duration: {test[0]?.questionNumber.toFixed(1)} Minutes{" "}
                        </span>
                        <span className="ml_12">
                          {" "}
                          Time Taken By Candidate:{" "}
                          {convertSecondToMinutes(
                            Number(test[0]?.userTimeTaken)
                          )}{" "}
                        </span>
                      </p>
                      <hr className="new1 mt_12" />
                      <p className="fs-semi-bold fs-16 f-color mt_6">
                        Skills Test Score
                      </p>
                      <p className="fs-regular fs-14 f-color mt_6">
                        <span>Total Questions</span>
                        <span className="fs-semi-bold fs-18 ml_21">
                          {test[0]?.questionNumber}
                        </span>
                        <span className="fs-regular fs-14 ml_25">
                          Skill Test Score
                        </span>
                        <span className="fs-semi-bold fs-18 ml_5 green_text ml_25">
                          {test[0]?.totalScore}
                        </span>
                        <span className="fs-regular fs-18 ml_5">out of</span>
                        <span className="fs-semi-bold fs-18 ml_5">
                          {/* {totalQuestions} */}
                          {test[0]?.questionNumber}
                        </span>{" "}
                      </p>
                      <hr className="new1 mt_12" />
                    </div>
                  </div>
                </div>
              ))}
            </DialogContent>
            <DialogActions className={`${classes.buttonForSupport} `}>
              <button
                type="button"
                className="btn-big btn-skill cp"
                onClick={deleteSkillTestModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-big btn_red cp"
                onClick={(e) => deleteskillHandler()}
              >
                Delete
              </button>
            </DialogActions>
          </Dialog>
          {/* confirm Delete Modal */}
          <Dialog
            open={confirmDeleteModal}
            onClose={closeConfirmModal}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle
              id="form-dialog-title"
              className={`${classes.title} responsive_title`}
            ></DialogTitle>

            <DialogContent
              className={`${classes.bodyForSupport} responsive_Support`}
            >
              <p className="fs-semi-bold fs-16 f-color">
                Are you sure you want to delete the score on{" "}
                {testForDelete.userTest?.skill?.name}
                {testForDelete?.userTest?.createdAt
                  ? ` (Test taken on: ${
                      new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                        new Date(testForDelete?.userTest?.createdAt)
                      ) +
                      "-" +
                      (new Intl.DateTimeFormat("en", { month: "long" }).format(
                        new Date(testForDelete?.userTest?.createdAt)
                      ) +
                        "-" +
                        new Intl.DateTimeFormat("en", {
                          year: "numeric",
                        }).format(new Date(testForDelete?.userTest?.createdAt)))
                    } )?`
                  : ""}
              </p>
            </DialogContent>
            <DialogActions className={`${classes.buttonForSupport} `}>
              <button
                type="button"
                className="btn-big btn-skill cp"
                onClick={closeConfirmModal}
              >
                No
              </button>
              <button
                type="button"
                className="btn-big btn_powres cp"
                onClick={(e) => confirmDeleteHandler()}
              >
                Yes
              </button>
            </DialogActions>
          </Dialog>
          <NotificationContainer />
        </>
      ) : (
        <>
          <div className="card  mt_10">
            <Skeleton variant="text" animation="wave" />
            <Skeleton
              variant="rect"
              animation="wave"
              height={200}
              className="mt_16"
            />
            <Skeleton variant="text" animation="wave" className="mt_16" />
          </div>
        </>
      )}
    </>
  );
};

export default SkillVideoTest;
