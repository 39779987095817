import axios from "axios";
const jsonConfig = require("../../Config.json");

const userTestQuestionInfo = async (testid) => {
  let allResponse = {};
  try {
    const getallresponse = await axios.get(
      jsonConfig.apiUrl + "usertestquestioninfo/" + jsonConfig.userIndustryId + "/" + testid,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = getallresponse;

    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default userTestQuestionInfo;