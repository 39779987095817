import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from '@material-ui/core/styles';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import axios from "axios";
import './homeContactUs.css';
import emailConfigurationByCodeResponse from '../../../../apis/email/emailConfigurationByCode';
import circleBlue from "../../../../components/basicInfoForm/images/circle_blue.svg";
import { Button, Grid, OutlinedInput } from '@material-ui/core';
import sendEmailResponse from '../../../../apis/email/sendEmail';
import validateEmail from "../../../../utilities/validateEmail";

const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: '0px 30px!important'
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    paddingRight: "40px",
    paddingBottom: "20px",
    paddingTop: "14px",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: '0px 30px!important'
  },
  btnCancel: {
    width: "149px",
    height: "37px",
    backgroundColor: "#FFFFFF",
    color: "#1885E6",
    border: "1px solid #1885E6",
    borderRadius: "5px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#D1E8FF"
    }
  },
 
  btnSave: {
    width: "149px",
    height: "37px",
    backgroundColor: "#1885E6",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "5px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#1885E6",
    }
  }

}));

// modalClose props is used here as callback for closing the modal
const HomeContactUsModal = ({ modalOpen, modalClose }) => {
  const jsonConfig = require('../../../../Config.json');
  const classes = useStyles();
  const [modalFirstName, setmodalFirstName] = useState('');
  const [modalLastName, setmodalLastName] = useState('');
  const [modalemail, setmodalemail] = useState('');
  const [modalPhone, setModalPhone] = useState('');
  const [mailQuestion, setmailQuestion] = useState('');
  const [allSupportMail, setallSupportMail] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  // Empty space validation in Question field
  const regex = /^\s*$/g
  const emptySpaceValidate = regex.test(mailQuestion)
  const getAllMail = async () => {
    try {
      var codeForMail = {
        "code": "CTsupportemail"
      }
      const getAllMailResponse = await emailConfigurationByCodeResponse(codeForMail);
      if(getAllMailResponse.status===200 && getAllMailResponse.data.data[0].emails){
        await mailSend(getAllMailResponse.data.data[0].emails)
      setallSupportMail(getAllMailResponse.data.data[0].emails)
      }
    } catch (error) {
      console.log(error)
    }
  }
 

  const mailSend = async (supportmails) => {

    const mailValidationHandle = await validateEmail(email)
    if (email === "" || name === "" || mailQuestion === "" || emptySpaceValidate === true) {
      NotificationManager.error("Please enter the required fields", "Error", 2500);
      return;
    }
    else {

      if (!mailValidationHandle) {
        NotificationManager.error("Please Enter Correct Email", "Error", 2500);
      }
      else {
        try {
          const message = {
            to: supportmails,
            from: jsonConfig.sendGridFromEmail,
            subject: `Message from ${name}`,
            html: `Name: ${name} <br />
                   Email: ${email} <br/>
                   Question: ${mailQuestion}
                    `
          }
          if (jsonConfig.sendEmail) {
            const supportEmailResponse = await sendEmailResponse(message);
            NotificationManager.success('Done! Your concern has been noted. Someone from our support team will reach out to you shortly.', 'Success', 2500);
          }
          setName('');
          setEmail('');
          modalClose();

        } catch (err) {
          console.log(err)
        }
      }

    }
  }

  const handleClose = () => {
    modalClose()
  };

  const NameChangeHandler = (event) => {
    setName(event.target.value);

  }

  const emailChangeHander = (event) => {
    setEmail(event.target.value);
  }

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}

      >
        <DialogTitle>
          <div className='support_line'>Contact Us</div>
          <img
            src={circleBlue}
            alt='img'
            className='blue'
          />
        </DialogTitle>
        <DialogContent className='support_con'>
          <div className='support_header'>
            <Grid container spacing={2} className='support_grid'>
              <Grid item xs={6}><div className='support_form_leb'>Name</div></Grid>
              <Grid item xs={6}><div className='support_form_leb'>Email</div></Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  placeholder="Please enter your full name"
                  className="Support_textbox"
                  // defaultValue={`${mailData.firstName} ${mailData.lastName}`}
                  onChange={NameChangeHandler}
                />
              </Grid>
              <Grid item xs={6} className="support_mail">
                <OutlinedInput
                  margin='dense'
                  placeholder="Please enter your email"
                  className="Support_textbox"
                  onChange={emailChangeHander}
                />
              </Grid>
              <Grid item xs={12} className="support_grid_message">
                <div id="mes_lable">Your Message</div>
              </Grid>
              <Grid item xs={12}>
                <textarea
                  type="text"
                  className="support_message"
                  // rows={10}
                  // onChange={(event) => setMailQuestion(event.target.value)}
                  // onChange={(event) => setmailQuestion(event.target.value)}
                  onChange={(event) => setmailQuestion(event.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <Button className={classes.btnCancel} id="buton" onClick={handleClose}>Cancel</Button>
          <Button className={classes.btnSave} id="buton" onClick={getAllMail}
          >Send</Button>
        </DialogActions>
      </Dialog>
      <NotificationContainer />
    </>
  )
}
export default HomeContactUsModal
