import axios from "axios";
const jsonConfig = require("../../Config.json");

const deleteUserSkill = async (skillArr) => {
    let allSkillsList=[]
    try {
       let payload={
            usertestid:skillArr,
            isDeleted:true
        }
       const getallskillresponse = await axios.put(
          jsonConfig.apiUrl + "UpdateisDeletedUsertestByTestIds" ,payload,
          {
             headers: {
                "x-functions-key": jsonConfig.key,
             },
          }
       );
        allSkillsList = getallskillresponse.data.message;

    return  Promise.resolve(allSkillsList) 
    }
    catch (error) {
    //    console.error(error);
    return   Promise.reject(error)
    }
 }

 export default deleteUserSkill;