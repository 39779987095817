import React, { useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  useGoogleReCaptcha,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import verifyTokenResponse from "../../apis/captcha/verifyToken";
import {
  NotificationManager,
} from "react-notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  otpStyle: {
    caretColor: "transparent",
  },
}));
const SendmailModal = (props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const classes = useStyles();
  const {
    putEmailPage,
    open,
    putOtp,
    userEmail,
    setUserEmail,
    setVerifyOtp,
    buttonFocus,
    reSendOtp,
    millisToMinutesAndSeconds,
    otpVerification,
    verifyOtp,
    counter,
    btnRef,
    isInviteSend,
    handleCloseDialog,
    otpSent,
    isEmailVerified,
    startProgress,
    sendOtp,
    setPutEmailPage,
    setPutOtp,
  } = props;

  const handleTokenSend = useCallback(
    async (e) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");

        return;
      }

      try {
        const token = await executeRecaptcha("submit");

        const payload = {
          recaptcharesponse: token,
        };
        try {
          const response = await verifyTokenResponse(payload);
          console.log("response11111", response.data.data);

          if (response.data.data.score > 0.3) {
            sendOtp();
            setPutEmailPage(true);
            setPutOtp(true);
          } else {
            console.log("botttt");
          }
        } catch (error) {
          console.log("error", error);
        }
      } catch (error) {
        console.log("errrrr", error);
      }
    },
    [executeRecaptcha, sendOtp, setPutEmailPage, setPutOtp]
  );

  const nextButtonHandler = () => {
    if (
      userEmail !== "" &&
      userEmail.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      handleTokenSend();
    } else {
      NotificationManager.error(
        "Please enter a valid email address",
        "Error"
      );
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <form>
          {putEmailPage === true && putOtp === false ? (
            <>
              <div className="dialogHeading">
                <div className="body_text mb_16">
                  Please enter your email, you will receive an OTP
                </div>
                <div className="dialogBody">
                  <div>Email* (Required for OTP Verification)</div>
                  <div>
                    <input
                      type="text"
                      className="text_box_cleartalent_form_recruiter mt_8 emailInputNew"
                      id="email"
                      name="email"
                      value={userEmail}
                      placeholder="Please Enter email address"
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {putEmailPage === true && putOtp === true ? (
            <>
              <div className="dialog1">
                <div className="body_text mb_16">Please enter the OTP</div>
                <div className="dialogBody1">
                  <div>
                    Please enter the One Time Password (OTP) that we have sent
                    to your email
                  </div>
                  <div>({userEmail})</div>
                  <div className="inputTagMargin">
                    <input
                      id="otp-in"
                      type="tel"
                      maxLength="6"
                      value={verifyOtp}
                      onChange={(e) => {
                        setVerifyOtp(e.target.value);
                        buttonFocus(e.target.value);
                      }}
                      className={verifyOtp.length === 6 ? classes.otpStyle : ""}
                      disabled={!otpSent ? true : isEmailVerified}
                    />
                  </div>
                </div>
                <>
                  <>
                    <div className="resend_otp_section_referral">
                      {!counter ? (
                        <button
                          className="resendOTP cp"
                          onClick={(e) => reSendOtp(e)}
                        >
                          Resend OTP
                        </button>
                      ) : (
                        <>
                          {startProgress ? (
                            <CircularProgress />
                          ) : (
                            !!counter && (
                              <>
                                <div>
                                  <button
                                    disabled={true}
                                    className="resendOTP cp"
                                  >
                                    Resend OTP
                                  </button>
                                </div>
                                <p className="resendotp-timer fs-semi-bold">
                                  {millisToMinutesAndSeconds(counter)}{" "}
                                </p>
                              </>
                            )
                          )}
                        </>
                      )}
                    </div>
                  </>
                </>
              </div>
            </>
          ) : null}
          <div className="dialogAction">
            <div>
              <div className="underline_boxnew df">
                <div className="button_boxnew">
                  {putEmailPage === true && putOtp === true ? (
                    <button
                      type="button"
                      className="btn-invite_next btn-invite_next_blue cp btn_style"
                      onClick={otpVerification}
                      disabled={isInviteSend}
                      ref={btnRef}
                    >
                      Verify
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn-invite_next btn-invite_next_blue cp btn_style"
                      onClick={nextButtonHandler}
                      disabled={isInviteSend}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            {putEmailPage === true && putOtp === true ? (
              !isInviteSend ? (
                <div onClick={handleCloseDialog} className="cancelText cp">
                  Cancel
                </div>
              ) : (
                <div className="cancelText cp"></div>
              )
            ) : (
              <div onClick={handleCloseDialog} className="cancelText cp">
                Cancel
              </div>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withGoogleReCaptcha(SendmailModal);
