import axios from "axios";
const jsonConfig = require("../../Config.json");


const updateCandidateRequisition = async (payload) => {
    try {
        const resp = await axios.put(jsonConfig.apiUrl + `updateCandidateRequisition`, payload, {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
        });
        return Promise.resolve(resp);
    }
    catch(err) {
        return Promise.reject(err);
    }
}

export default updateCandidateRequisition;