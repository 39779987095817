import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import "./editQuestion.css";
import AdminHeader from "../../components/adminHeader/adminHeader";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Container from "@material-ui/core/Container";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useHistory } from "react-router";
import Creatable from "react-select/creatable";

/*** APIS */
import getDistinctSMES from "../../apis/getDistinctSMEs";
import testQuestionDescription from "../../utilities/questionSyntaxHighlight";
import getAllTests from "../../apis/Tests/getAllTests";
import Prism from "prismjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import { UserTypeContext } from "../../AppRoute";
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["code-block"],
    [{ 'color': [] }, { 'background': [] }],
  ],
  clipboard: {
    matchVisual: false,
  }
};
const answerModules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ 'color': [] }],
  ],
  clipboard: {
    matchVisual: false,
  }
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "indent",
  "image",
  "code-block",
  "color",
  "background"
];
const filter = createFilterOptions();
const EditQuestion = (props) => {
  const testId = props.location.state.testId;
  const subSkillId = props.location.state.subSkillId;
  const numberOfQuestions = props.location.state.numberOfQuestionsArr;
  const { userType, userIdType } = useContext(UserTypeContext);
  const jsonConfig = require("../../Config.json");
  const history = useHistory();
  const { match } = props;
  const [question, setQuestion] = useState({});
  const [loadingController, setLoadingController] = useState(false);
  const [questionComplexity, setquestionComplexity] = useState([]);
  const [questionComplexityId, setquestionComplexityId] = useState("");
  const [allAnsType, setallAnsType] = useState([]);
  const [ansTypeId, setansTypeId] = useState("");
  const [mins, setMins] = useState("");
  const [secs, setSecs] = useState("");
  const [changedMins, setChangedMins] = useState();
  const [changedSecs, setChangedSecs] = useState();
  const [timeLimit, setTimeLimit] = useState("");
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [questionSme, setQuestionSme] = useState({});
  const [questionsmeNew, setQuestionSmeNew] = useState("");
  const [questionDesc, setQuestionDesc] = useState("");
  const [allSubSkillArr, setallSubSkillArr] = useState([]);
  const [subskillId, setsubskillId] = useState("");
  const [reasonDropDown, setReasonDropDown] = useState("");
  const [reasonComment, setReasonComment] = useState("");
  const reasonDisableData = ["Retired", "Replaced", "Other"];
  const [selectedAnsType, setselectedAnsType] = useState({});
  const [smes, setSmes] = useState([]);
  const [isMinuteChanged, setIsMinuteChanged] = useState(false)
  const [isSecondChanged, setIsSecondChanged] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [atvBtn, setAtvBtn] = useState(false);

  const trueFalse = [
    {
      isActive: false,
    },
    {
      isActive: true,
    },
  ];

  const getSmeOpts = async () => {

    const res = await getDistinctSMES();
    const dataFormatted = res.data.data.filter(
      (val) => val.subjectMatterExpert !== null
    );

    setSmes(dataFormatted);

  };

  const addchoiceHandler = () => {
    const newObj = {
      id: "",
      isActive: true,
      isCorrect: false,
      answerText: "",
    };
    const newArr = [...questionAnswer, newObj];
    setQuestionAnswer(newArr);
  };

  const handleChangeCheckbox = (event, item, index) => {
    let newAnsArr = [...questionAnswer];
    newAnsArr[index].isCorrect = !newAnsArr[index].isCorrect;
    setQuestionAnswer(newAnsArr);
  };
  const isActiveChangeHandler = (event, value) => {
    if (value) {
      setQuestion((prevState) => ({
        ...prevState,
        isActive: value.isActive,
      }));
    }
  };
  const removeAnswer = (item, index) => {
    const curArr = [...questionAnswer];
    let removeArr = curArr.filter((itemArr, indexArr) => indexArr !== index);
    setQuestionAnswer(removeArr);
  };

  const answerHandler = (event, itemIndex) => {
    const newAnsArr = [...questionAnswer];
    const regex = /(<([^>]+)>)/ig; //Remove Html Tags
    const textValue = event;
    const hasText = !!textValue.replace(regex, "").trim().length;
    if (hasText)
      newAnsArr[itemIndex].answerText = textValue; // for onchanging we need to update the same index value
    else
      newAnsArr[itemIndex].answerText = "";
    setQuestionAnswer(newAnsArr);
  };
  const reasonChangeHandler = (e, value) => {
    setReasonDropDown(value);
  };
  const reasonCommentHandler = (e, value) => {
    setReasonComment(e.target.value);
  };
  const questionHandler = (value) => {
    const regex = /(<([^>]+)>)/ig; //Remove Html Tags
    const textValue = value;
    const hasText = !!textValue.replace(regex, "").trim().length;
    if (hasText) {
      setQuestionDesc(textValue);
    } else {
      setQuestionDesc("");
    };
  };
  const getIndividualQuestion = async () => {

    try {
      const indiVidualQuestionData = await axios.get(
        jsonConfig.apiUrl + "questionsById/" + match.params.id,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      setQuestion(indiVidualQuestionData.data.data);
      setTimeLimit(indiVidualQuestionData.data.data.timeLimit);
      setQuestionDesc(indiVidualQuestionData.data.data.questionDesc);
      setQuestionAnswer(indiVidualQuestionData.data.data.questionAnswer);
      setselectedAnsType(indiVidualQuestionData.data.data.questionAnswertype);
      setQuestionSmeNew(indiVidualQuestionData.data.data.subjectMatterExpert);
      setQuestionSme({
        label: indiVidualQuestionData.data.data.subjectMatterExpert,
        value: indiVidualQuestionData.data.data.subjectMatterExpert,
      });
      setIsDataLoaded(true)

    } catch (err) {
      console.error(err);
    }

  };
  const anstypeChangeHandler = (e, value) => {
    if (value) {
      setansTypeId(value.id);
    }
  };

  const getQuestionComplexity = async () => {

    try {
      const questionComplexity = await axios.get(
        jsonConfig.apiUrl + "questioncomplexity",
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      setquestionComplexity(questionComplexity.data.data);
    } catch (error) {
      // do nothing
      console.log(error);
    }

  };
  const getAllAnsType = async () => {

    try {
      const allAnsType = await axios.get(
        jsonConfig.apiUrl + "getActiveAnswerTypes",
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      setallAnsType(allAnsType.data.data);
    } catch (error) {
      console.log("subskillerror", error);
    }

  };

  const handleUpdateOrders = (e, index) => {
    const newAnsArr = [...questionAnswer];
    const value = e.target.value;
    newAnsArr[index].order = value;
    setQuestionAnswer(newAnsArr);
  };

  const questionSMEHandler = (val) => {
    if (val) {
      setQuestion({ ...question, subjectMatterExpert: val.value });
    }
    setQuestionSme(val);
  };

  const questionComplexityHandler = (e, value) => {
    if (value) {
      setquestionComplexityId(value.id);
    }
  };
  const minuteHandler = (event, value) => {
    if (event.target.value || event.target.value === 0) {
      setChangedMins(parseInt(event.target.value));
      setIsMinuteChanged(true)
    }
    else
      setChangedMins("")
  };
  const secHandler = (event, value) => {
    if (event.target.value || event.target.value === 0) {
      setChangedSecs(parseInt(event.target.value))
      setIsSecondChanged(true)
    }
    else
      setChangedSecs("");
  };
  const millisToMinutesAndSeconds = (time) => {
    const timeLimitNumber = parseInt(time);
    const timeLimitMills = timeLimitNumber * 1000;
    let minutes = Math.floor(timeLimitMills / 60000);
    let seconds = ((timeLimitMills % 60000) / 1000).toFixed(0);
    if (seconds) {
      setSecs(seconds);
    } else {
      setSecs("00");
    }
    setMins(minutes);
  };

  const updateQuestion = async () => {
    setAtvBtn(true);
    const SingleSelect = allAnsType.filter(
      (item) => item.code === "SingleChoice"
    );
    const MultiSelect = allAnsType.filter(
      (item) => item.code === "MultipleChoice"
    );
    const isCorrectFilter = questionAnswer.filter((item) => item.isCorrect);

    const res = await getAllTests();
    var maxNumber = 0;
    var msg = '';
    var subSkill = '';
    for (let i = 0; i < res.data.data.length; i++) {
      for (let j = 0; j < res.data.data[i].testsubskillsmappings.length; j++) {
        if (res.data.data[i].testsubskillsmappings[j].subskillId === question.subSkillsId) {
          if (res.data.data[i].testsubskillsmappings[j].noOfQuestion >= maxNumber) {
            if (res.data.data[i].testsubskillsmappings[j].noOfQuestion === maxNumber && msg) {
              msg = msg + ', ' + `"${res.data.data[i].testName}"`;
            }
            else {
              msg = `"${res.data.data[i].testName}"`;
            }
            maxNumber = res.data.data[i].testsubskillsmappings[j].noOfQuestion;
            subSkill = res.data.data[i].testsubskillsmappings[j].subSkill.name;
          }
        }
      }
    };

    var questionsByTestSubSkillId = numberOfQuestions.filter((item, i) => item.subSkillsId === question.subSkillsId);

    if (isCorrectFilter.length > 1 && SingleSelect[0].id === ansTypeId) {
      setAtvBtn(false);
      NotificationManager.error(
        "You can choose only one correct option for a single choice question",
        "Error",
        2500
      );
    }
    else if (
      MultiSelect[0].id === ansTypeId ||
      MultiSelect[0].id === question.answerTypeId ||
      (isCorrectFilter.length === 1 &&
        (SingleSelect[0].id === question.answerTypeId ||
          SingleSelect[0].id === ansTypeId))
    ) {
      if (changedMins < 0 || changedMins === "") {
        setAtvBtn(false);
        NotificationManager.error(
          "Minute value must be grater than  0",
          "Error",
          2500
        );
      }
      else if (changedSecs < 0 || changedSecs >= 60 || changedSecs === "") {
        setAtvBtn(false);
        NotificationManager.error(
          "Second value must be grater than or equal to 0 and less than 60",
          "Error",
          2500
        );
      }
      else if ((subSkillId && !testId) && (question.isActive === false && numberOfQuestions.length <= maxNumber)) {
        setAtvBtn(false);
        NotificationManager.error(
          `Not enough questions of subskill "${subSkill}" for test ${msg}`,
          "Error",
          3000
        );
      }
      else if ((!subSkillId && testId) && (question.isActive === false && questionsByTestSubSkillId.length <= maxNumber)) {
        setAtvBtn(false);
        NotificationManager.error(
          `Not enough questions of subskill "${subSkill}" for test ${msg}`,
          "Error",
          3000
        );
      }
      else {
        const defaulttimeLimit = mins * 60 + parseInt(secs);
        let changedTimeLimit;
        if (isMinuteChanged && !isSecondChanged) {
          changedTimeLimit = changedMins * 60 + parseInt(secs);
        } else if (isSecondChanged && !isMinuteChanged) {
          changedTimeLimit = mins * 60 + changedSecs;
        } else if (isMinuteChanged && isSecondChanged) {
          changedTimeLimit = changedMins * 60 + changedSecs;
        }
        if ((isMinuteChanged || isSecondChanged) && changedTimeLimit === 0) {
          setAtvBtn(false);
          NotificationManager.error(
            "Total time limit must be grater than 0",
            "Error",
            2500
          );
        }
        else {

          const payLoad = {
            question: {
              questionDesc: questionDesc ? questionDesc : question.questionDesc,
              ReasonForDisabling: reasonDropDown
                ? reasonDropDown
                : question.ReasonForDisabling,
              Comments: reasonComment,
              subskill: subskillId ? subskillId : question.subSkillsId,
              industry: question.industryId,
              questionComplexity: questionComplexityId
                ? questionComplexityId
                : question.questionComplexityId,
              questionType: question.questionTypeId,
              answerType: ansTypeId ? ansTypeId : question.answerTypeId,
              timeLimit: changedTimeLimit ? changedTimeLimit : defaulttimeLimit,
              isActive: question.isActive,
              answers: questionAnswer,
              updatedBy: question.updatedBy,
              subjectMatterExpert: question.subjectMatterExpert,
            },
          };
          try {
            await axios.put(
              jsonConfig.apiUrl + "questionsById/" + match.params.id,
              payLoad,
              {
                headers: {
                  "x-functions-key": jsonConfig.key,
                },
              }
            );
            await NotificationManager.success("Question updated", "Success", 1500);
            setAtvBtn(false);
            setTimeout(async () => {
              await history.push("/questions");
            }, 3000);
          } catch (err) {
            console.error(err);
            setAtvBtn(false);
          }

        }
      }
    } else {
      setAtvBtn(false);
      NotificationManager.error(
        "You can choose only one correct option for a single choice question",
        "Error",
        2500
      );
    }
    setAtvBtn(false);
  };
  const getAllsubskill = async () => {

    try {
      const getAllSubskillResponse = await axios.get(
        jsonConfig.apiUrl + "subSkills",

        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      setallSubSkillArr(getAllSubskillResponse.data.data);
    } catch (error) { }

  };
  const subSkillChangeHandler = (e, value) => {
    if (value) {
      setsubskillId(value.id);
    } else {
      setsubskillId("");
    }
  };
  useEffect(() => {

    millisToMinutesAndSeconds(timeLimit);

  });

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getIndividualQuestion();
        await getQuestionComplexity();
        await getAllAnsType();
        await getAllsubskill();
        await getSmeOpts();
      } catch (error) {
        setLoadingController(true);
      } finally {
        setLoadingController(true);
      }

    }
    fetchAllData();
  }, []);
  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 24px 7px 23px",
  };
  const btnSubmit = {
    marginTop: "15px",
    marginBottom: "10px",
  };
  const btnCancel = {
    padding: "7px 24px 7px 23px",
    marginTop: "15px",
    marginBottom: "10px",
    marginRight: "1rem",
  };
  setTimeout(() => Prism.highlightAll(), 0);

  return (
    <>
      <AdminHeader uid={props.idContextTest} isDataLoaded={loadingController} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt_100">
            {!loadingController ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <>
                    <div className="edit-question-wrapper">
                      {/* <Container component={Paper}> */}
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <h1> Edit Question </h1>
                        </Grid>
                        <Divider style={{ width: "100%" }} />
                        <h2> Question Details (mutable) </h2>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="body1"
                            component="h2"
                            className=""
                          >
                            Sub Skill
                          </Typography>
                          {question.questionSubSkill && (
                            <Autocomplete
                              id="combo-box-demo"
                              options={allSubSkillArr}
                              defaultValue={question?.questionSubSkill}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select sub-skill"
                                />
                              )}
                              onChange={subSkillChangeHandler}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <h2 className="MuiTypography-root  MuiTypography-body1">
                            Question
                          </h2>
                          <FormControl fullWidth variant="outlined">
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              formats={formats}
                              value={questionDesc}
                              onChange={questionHandler}
                            />
                          </FormControl>
                        </Grid>
                        {isDataLoaded ?
                          <>
                            <Grid item xs={6}>
                              <Typography variant="body1" component="h2">
                                Maximum Time
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1" component="h2">
                                Complexity
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <OutlinedInput
                                placeholder="Minute"
                                className="admin_textbox widthFix"
                                type="number"
                                defaultValue={mins}
                                inputProps={{ min: 0 }}
                                onChange={(event) => minuteHandler(event)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <OutlinedInput
                                placeholder="Second"
                                className="admin_textbox widthFix"
                                inputProps={{ min: 0, max: 59 }}
                                type="number"
                                defaultValue={secs}
                                onChange={secHandler}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                id="combo-box-demo"
                                options={questionComplexity}
                                defaultValue={
                                  question.questionQuestionComplexity
                                }
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select complexity"
                                  />
                                )}
                                onChange={questionComplexityHandler}
                              />
                            </Grid>
                          </> : ""
                        }
                        {Object.keys(selectedAnsType).length > 0 ? (
                          <>
                            <Grid item xs={12} md={6}>
                              <Typography variant="body1" component="h2">
                                Answer Type
                              </Typography>
                              <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                  options={allAnsType}
                                  defaultValue={selectedAnsType}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Select answer type"
                                    />
                                  )}
                                  onChange={anstypeChangeHandler}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Typography variant="body1" component="h2">
                                Is Active
                              </Typography>
                              <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                  options={trueFalse}
                                  defaultValue={{
                                    isActive: question?.isActive,
                                  }}
                                  getOptionLabel={(option) =>
                                    option.isActive
                                      ?.toString()
                                      .charAt(0)
                                      .toUpperCase() +
                                    option.isActive?.toString().slice(1)
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Select Is Active"
                                    />
                                  )}
                                  onChange={isActiveChangeHandler}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="body1" component="h2">
                                Reason For Disabling
                              </Typography>
                              <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                  options={reasonDisableData}
                                  defaultValue={question.ReasonForDisabling}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Reason for disabling"
                                    />
                                  )}
                                  onChange={reasonChangeHandler}
                                />
                              </FormControl>
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                        {/* </>
                          )} */}

                        {question && smes && (
                          <Grid item xs={12} md={6}>
                            <Typography variant="body1" component="h2">
                              Subject Matter Expert
                            </Typography>
                            {Object.keys(question).length > 0 ? (
                              <Autocomplete
                                defaultValue={question.subjectMatterExpert}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  return option.subjectMatterExpert;
                                }}
                                onChange={(event, newValue) =>
                                  questionSMEHandler(newValue)
                                }
                                filterSelectedOptions
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  if (params.inputValue !== "") {
                                    filtered.push({
                                      subjectMatterExpert: params.inputValue,
                                      title: `Add "${params.inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                id="free-solo-with-text-demo"
                                options={smes}
                                renderOption={(option) =>
                                  option.subjectMatterExpert
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Subject matter expert"
                                    variant="outlined"
                                  />
                                )}
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <h2 className="MuiTypography-root  MuiTypography-body1">
                            Comments
                          </h2>
                          <FormControl fullWidth variant="outlined">
                            <TextareaAutosize
                              className="admin_textbox"
                              minRows={3}
                              placeholder="Enter Comments"
                              onChange={reasonCommentHandler}
                              defaultValue={question.Comments}
                            />
                          </FormControl>
                        </Grid>

                        {question.createdAt && (
                          <>
                            <Divider style={{ width: "100%" }} />
                            <Grid item xs={12} md={12}>
                              <h2> Question Details (Immutable) </h2>
                            </Grid>
                            <Grid item xs={12} md={4} className="mt_10">
                              <Typography variant="body1" component="h2">
                                Created At
                              </Typography>
                              <TextField
                                variant="outlined"
                                defaultValue={
                                  new Intl.DateTimeFormat("en", {
                                    month: "long",
                                  }).format(new Date(question.createdAt)) +
                                  "-" +
                                  new Intl.DateTimeFormat("en", {
                                    day: "2-digit",
                                  }).format(new Date(question.createdAt)) +
                                  "-" +
                                  new Intl.DateTimeFormat("en", {
                                    year: "numeric",
                                  }).format(new Date(question.createdAt)) +
                                  " " +
                                  new Intl.DateTimeFormat("en", {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(question.createdAt))
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} md={4} className="mt_10">
                              <Typography variant="body1" component="h2">
                                Last Update At
                              </Typography>
                              <TextField
                                variant="outlined"
                                defaultValue={
                                  new Intl.DateTimeFormat("en", {
                                    month: "long",
                                  }).format(new Date(question.updatedAt)) +
                                  "-" +
                                  new Intl.DateTimeFormat("en", {
                                    day: "2-digit",
                                  }).format(new Date(question.updatedAt)) +
                                  "-" +
                                  new Intl.DateTimeFormat("en", {
                                    year: "numeric",
                                  }).format(new Date(question.updatedAt)) +
                                  " " +
                                  new Intl.DateTimeFormat("en", {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(question.updatedAt))
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                              />
                            </Grid>
                          </>
                        )}
                        <Divider style={{ width: "100%" }} />
                        <Grid item xs={12} md={12}>
                          <h2> Answers </h2>

                          <div className="edit-question-answer-list">
                            {questionAnswer.length &&
                              questionAnswer.map((item, index) => (
                                <div key={index}>
                                  <div className="edit-question-answer-wrapper edit_question_order">
                                    <FormControl fullWidth variant="outlined">
                                      <ReactQuill
                                        theme="snow"
                                        modules={answerModules}
                                        formats={formats}
                                        value={item.answerText || ""}
                                        onChange={(event) => answerHandler(event, index)}
                                      />
                                    </FormControl>

                                    <div className="choice_order_section_width_edit">
                                      <Typography
                                        variant="body1"
                                        component="h2"
                                      >
                                        Order
                                      </Typography>
                                      <FormControl
                                        fullWidth
                                        variant="outlined"
                                      >
                                        <OutlinedInput
                                          className="admin_textbox"
                                          placeholder="Enter Order"
                                          value={item.order || ""}
                                          onChange={(event) =>
                                            handleUpdateOrders(event, index)
                                          }
                                        />
                                      </FormControl>
                                    </div>
                                    <span className="fs-semi-bold fs-16 f-color ml-25 mr_14">
                                      <FormControl>
                                        <Checkbox
                                          checked={item.isCorrect}
                                          color="primary"
                                          inputProps={{
                                            'aria-label': 'controlled'
                                          }}
                                          onChange={(event) =>
                                            handleChangeCheckbox(event, item, index)
                                          }
                                        />
                                      </FormControl>
                                    </span>
                                    <span
                                      className="cp"
                                      onClick={() => removeAnswer(item, index)}
                                    >
                                      <CloseIcon />
                                    </span>
                                  </div>
                                  <br />
                                </div>
                              ))}
                          </div>
                        </Grid>
                      </Grid>
                      {/* {disabled ? */}
                      <div className="edit-question-choice-handle">
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            style={btnGreen}
                            color="primary"
                            onClick={addchoiceHandler}
                          >
                            Add choice
                          </Button>
                        </Grid>
                      </div>

                      <div className="add_question_button">
                        <Button
                          variant="outlined"
                          style={btnCancel}
                          color="primary"
                          onClick={() => history.push("/questions")}
                        >
                          Cancel
                        </Button>

                        <button
                          variant="outlined"
                          className="btn3 btn_primary cp"
                          style={btnSubmit}
                          color="primary"
                          onClick={() => updateQuestion()}
                        >
                          <div className="btn_loader">
                            Save
                            &nbsp;
                            {atvBtn &&
                              <div>
                                <CircularProgress size={13} />
                              </div>
                            }
                          </div>
                        </button>
                      </div>
                      {/* </Container> */}
                    </div>
                  </>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>) : (
                  <>
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        You Are Not Authorized To Visit This Page
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default EditQuestion;
