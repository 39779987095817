import React, { useEffect, useState, useCallback, useRef } from "react";
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { DateTime } from "luxon";
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { FilePicker } from "react-file-picker";

import { withStyles } from "@material-ui/core/styles";
import cross from "../../components/profile/profileCompletion/images/cross.svg";

import "../RecruitmentPartnersAndClients/indexModal.css";

import { BlobServiceClient } from "@azure/storage-blob";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import getCustomerTypes from "../../apis/getCustomerType";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";

import organizationLogoUpdateResponse from "../../apis/organizationLogoUpload";
import ImageCropper from "./ImageCropper/ImageCropper";
import { Loader } from '@progress/kendo-react-indicators';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonAlign: {
    display: "flex",
    alignItems: "center",
    padding: "24px 42px 24px 42px !important",
  },
  bodyForEditProfile: {
    marginTop: "70px",
  },
  changeProfiletitle: {
    padding: "16px 42px!important",
  },
  changeprofilebody: {},
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: "45px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonForProfilePic: {
    position: "absolute",
    right: "8px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

/* ------------------------  */
const DialogTitleForProfilePic = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <div className="crossFormodal cp" onClick={onClose}>
          <img className="closeicon_set" src={cross} alt=""></img>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent);

const DialogContentForprofilepic = withStyles((theme) => ({
  root: {
    marginTop: "0px!important",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

// for tooltip
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#1A8FFF",
  },
  tooltip: {
    backgroundColor: "#1A8FFF",
    padding: "4px 13px",
    fontFamily: "Segoe-Semibold",
    fontSize: "13px",
  },
}));

const RecruitmentPartners = (isDataLoaded) => {
  const jsonConfig = require("../../Config.json");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [modalIsOpene, setmodalIsOpene] = useState(false);
  const [allData, setAllData] = useState([]);
  const [cusData, setCusData] = useState([]);
  // const [recName, setRecName] = useState();

  // const [status, setStatus] = useState();
  // const [recId, setRecId] = useState();

  const isActiveItems = ["True", "False"];
  const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
  const userCameraStreamRef = useRef(null);

  const [display, setdisplay] = useState(false);
  const [modalProfilePictureUrl, setModalProfilePictureUrl] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [organizationPicture, setOrganizationPicture] = useState();
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState();
  const [logoUrl, setlogoUrl] = useState();
  const [logoFilename, setlogoFilename] = useState(null);
  const [recruiterPartnerLogo, setrecruiterPartnerLogo] = useState();

  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage2, setCroppedImage2] = useState(undefined);
  const getImgFreeCrop = (croppedImage2) => {
    setCroppedImage2(croppedImage2);
  };
  const [customers, setCustomers] = useState([]);
  const [atvBtn, setAtvBtn] = useState(false);
  const [recruitmentPartnerInfo, setRecruitmentPartnerInfo] = useState({
    recName: "",
    status: "",
    recId:""
  })
  // //---------------------------------------
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (recruiterID, recruiterName, newCroppedImg, logoFilename) => {
      try {
        const croppedImage = newCroppedImg;
        var block = croppedImage.split(";");

        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        await b64toBlob(
          realData,
          contentType,
          recruiterID,
          recruiterName,
          logoFilename
        );
        setdisplay(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  const filePickerOnChange = async (file) => {
    const selectedFileName = file.name;
    setlogoFilename(selectedFileName);
    const downloadedUrl = URL.createObjectURL(file);
    setImageToCrop(downloadedUrl);
    setProfilePictureEditModal(true);
  };

  //------------ >  ON CROP MODAL SAVE BUTTON <------

  const logoSave = () => {
    setProfilePictureEditModal(false);
    setSelectedFileName(logoFilename);
    setModalProfilePictureUrl("");
    setImageToCrop("");
  };

  const logoRemove = () => {
    setSelectedFileName(null);
    setlogoFilename(null);
  };

  const closeProfilePicModal = async () => {
    setdisplay(false);
    setlogoFilename(null);

    if (userCameraStreamRef.current) {
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setProfilePictureEditModal(false);
  };

  const b64toBlob = async (
    b64Data,
    contentType,
    recruiterID,
    recruiterName,
    logoFilename,
    sliceSize
  ) => {

    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(
      byteArrays,
      { type: contentType },
      { name: "profile.png" }
    );
    await uploadToBlobStorage(blob, recruiterID, recruiterName, logoFilename);
  };

  //------------ Generating UUID -----------

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  //----------------------------------
  const clearData = () => {
    setRecruitmentPartnerInfo({
      recName: "",
      status: ""
    })
  };

  const handleisActive = (e, value) => {
    if(value === "True"){
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, status: true})
    }else if(value === null){
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, status: undefined})
    }else{
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, status: false})
    }
  };

  const handelEditClick = (e, value) => {
    if(value?.isActive === true){
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, status:"true" , recName: value.name.trim(), recId:value?.id})
    }else{
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, status:"false" , recName: value.name.trim()})
    }
    setmodalIsOpene((prev) => (prev = true));
    setrecruiterPartnerLogo(value?.recruitmentpartnerLogoUrl);
  };

  const getUserID = localStorage.getItem("userID");

  // Create Recruitment Organization
  const createRecruiter = async () => {
    setAtvBtn(true)
    const jsonConfig = require("../../Config.json");
    var recData = {
      name: recruitmentPartnerInfo.recName?.trim(),
      isActive: recruitmentPartnerInfo.status,
      createdBy: getUserID,
      updatedBy: getUserID,
      recruitmentpartnerLogoUrl: logoFilename === null ? null : logoUrl,
    };
    if (
      recData.name === undefined ||
      recData.name === "" ||
      recData.isActive === undefined
    ) {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please fill all the mandatory fields.",
        "Error",
        2500
      );
    } else {
      try {
        const recResponse = await axios.post(
          jsonConfig.apiUrl + "recruitmentpartner",
          recData,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        getData();
        if (
          recResponse.data.message ===
          "This recruitment partner name already exists Please use a different recruitment partner name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Recruitment organization name already exists. Please use a different Recruitment organization name.",
            "Error",
            2500
          );
        } else {

          NotificationManager.success(
            "Recruitment organization added successfully.",
            "Success",
            2500
          );
          setAtvBtn(false)
          var recruiterID = recResponse.data.data.id;
          showCroppedImage(recruiterID, recruitmentPartnerInfo.recName, croppedImage2, logoFilename);
          setmodalIsOpen((prev) => (prev = false));
          clearData();
        }
      } catch (error) {
        console.log(error);
        setAtvBtn(false)
        getData();
      }
    }
  };

  const uploadToBlobStorage = async (
    blob,
    recruiterID,
    recruiterName,
    logoFilename
  ) => {
    const sas = jsonConfig.sasToken;
    const uuid = create_UUID();
    let decodedToken = decodeURI(sas);
    const bsClient = new BlobServiceClient(
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClient = bsClient.getContainerClient("organizationlogos");
    let timeStamp = new Date().getTime();
    // const blobName = userId + "/" + "orgLogo_" + timeStamp + ".png";
    const recruitmentPartnerName = recruiterName
      .replace(/ /g, "")
      .toLowerCase();
    const blobName =
      recruiterID + "/" + uuid + "_" + recruitmentPartnerName + ".png";

    const url = `${jsonConfig.ctResourcesCdn}/organizationlogos/${blobName}`;
    setlogoUrl(url);

    const blobClient = containerClient.getBlockBlobClient(blobName);
    const fileTest = window.localStorage.getItem("File Selected");
    if (logoFilename === null) {
      console.log("Logo Not Uploaded");
    } else {
      const response = await blobClient.uploadBrowserData(blob);
    }
    var dt = new Date();
    if (logoFilename === null) {
      console.log("Data not INserted....");
    } else {
      var orgImagedata = {
        id: recruiterID,
        recruitmentpartnerLogoUrl: url,
        lastUpdated: dt,
      };
    }

    try {
      const payload = {
        userId: recruiterID,
        orgImagedata: orgImagedata,
      };
      const response = await organizationLogoUpdateResponse(payload);
      setOrganizationPicture(response?.data?.data?.organizationLogoUrl);
      setProfilePictureEditModal(false);
      setSelectedFileName(null);
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  // Edit Recruitment Organization
  const editRecruiter = async () => {
    setAtvBtn(true)
    let recrData = {
      name: recruitmentPartnerInfo.recName?.trim(),
      updatedBy: getUserID,
      isActive: recruitmentPartnerInfo.status,
      recruitmentpartnerLogoUrl: logoFilename === null ? null : logoUrl,
    };

    let Id = recruitmentPartnerInfo.recId;

    if (recrData.name === undefined || recrData.name === "") {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please fill all the mandatory fields.",
        "Error",
        2500
      );
    } else {
      try {
        const custResponse = await axios.put(
          jsonConfig.apiUrl + "recruitmentpartner/" + Id,
          recrData,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        getData();

        if (
          custResponse.data.message ===
          "This Recruitment organization name already exists Please use a different Recruitment organization name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Recruitment organization name already exists. Please use a different Recruitment organization name.",
            "Error",
            2500
          );
        } else {
          NotificationManager.success(
            "Recruitment organization updated successfully.",
            "Success",
            2500
          );
          showCroppedImage(Id, recruitmentPartnerInfo.recName, croppedImage2, logoFilename);
          handleClosed();
          clearData();
          setAtvBtn(false)
        }
      } catch (error) {
        console.log(error);
        setAtvBtn(false)
      }
    }
  };

  const handleName = (e) => {
    if(e.target.value){
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, recName: e.target.value.trim()})
    }else{
      setRecruitmentPartnerInfo({...recruitmentPartnerInfo, recName: ""})
    }
  };

  const getData = async () => {

    let data = await getAllRecruitmentPartnersAndClients();
    const cuData = await getCustomerTypes();
    const cData = cuData?.data?.data;
    let allDataSet = data?.data?.data;

    for (let i = 0; i < allDataSet.length; i++) {
      let a = allDataSet[i]?.recruitmentpartnercustomers;
      let b = a.map((item) => item?.customer?.name).join();
      allDataSet[i].customersName = b;

      let agencies = allDataSet[i]?.agencies;
      let agencyNam = agencies.map((item) => item?.agencyName).join(", ");
      allDataSet[i].agenciesName = agencyNam;

      let date = allDataSet[i]?.createdAt;
      let convertedDate = DateTime.fromISO(date)
        .toLocal()
        .startOf("day")
        .toJSDate();
      allDataSet[i].createdTime = convertedDate;
    }

    setAllData(allDataSet);
    setCusData(cData.filter((item) => item?.isActive === true));

  };

  const handleClose = () => {
    setmodalIsOpen((prev) => (prev = false));
    //Removing local storage data after cosing main modal
    setlogoFilename(null);
    setSelectedFileName(null);
  };
  const handleClosed = () => {
    setmodalIsOpene((prev) => (prev = false));
    setlogoFilename(null);
    setSelectedFileName(null);
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    position: "absolute",
    top: "-50px",
    right: "0",
  };

  const btnSavegreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "1px solid #2AD156",
  };

  const openModal = (e, client) => {
    setmodalIsOpen(true);
    setlogoFilename(null);
    //------------- Filename displaying on modal
    setSelectedFileName("");
  };

  const closeModal = () => {
    setmodalIsOpen(false);
  };

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  useEffect(() => {

    setlogoFilename(null);
    getData();

  }, []);

  return (
    <>
      <div className="containerMain">
        <GridDiv
          pageable={{
            buttonCount: 10,
            info: true,
            previousNext: true,
            pageSizes: true,
            serverPaging: true,
            serverFiltering: true,
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          style={{
            height: "70vh",
            width: "100%",
            overflow: "hidden",
          }}
          data={process(allData, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >
          {
            !isDataLoaded && (
              <GridNoRecords>
                <div className='col-4'>
                  <br />
                  <Loader size='large' themeColor="info" type="converging-spinner" />
                </div>
              </GridNoRecords>
            )}
          <GridColumn
            title="Action"
            width="100px"
            filterable={false}
            cell={(event) => {
              return (
                <td style={{ textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      handelEditClick(event.dataItem, event.dataItem)
                    }
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                </td>
              );
            }}
          />
          <GridColumn
            field="name"
            title="Name"
            filterable={true}
            sortable={true}
          />
          <GridColumn
            field="customersName"
            title="Customers"
            filterable={true}
            sortable={true}
          />
          <GridColumn
            field="agenciesName"
            title="Agencies"
            filterable={true}
            sortable={true}
          />
          <GridColumn
            field="createdTime"
            title="Created At"
            filterable={true}
            sortable={true}
            filter={"date"}
            format="{0:d},{0:t}"
            width="235px"
            cell={(e) => {
              return (
                <td>
                  {DateTime.fromISO(e.dataItem.createdAt)
                    .toFormat("MM/dd/yyyy")
                    .toString()}
                </td>
              );
            }}
          />
          <GridColumn
            title="Is Active"
            field="isActive"
            width="180px"
            filterable={true}
            filter={"boolean"}
            cell={(event) => {
              return <td>{event.dataItem?.isActive ? "True" : "False"}</td>;
            }}
          />
        </GridDiv>
        <Button
          variant="outlined"
          style={btnGreen}
          color="primary"
          onClick={openModal}
        >
          Add Recruitment Organization
        </Button>
      </div>

      {/* add */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalIsOpen}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle className="admindailog_title_modal">
            Add Recruitment Organization
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "grey",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography gutterBottom>Recruitment Organization Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Enter Recruitment Organization Name"
              className="admin_textbox"
              onChange={handleName}
            />
          </FormControl>
          &nbsp;
          <Typography>Is Active*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={isActiveItems}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Status"
                />
              )}
              onChange={handleisActive}
            />
          </FormControl>
          &nbsp;
          <Typography>Organization Logo (Optional)</Typography>
          <FormControl fullWidth variant="outlined">
            <div id="orgLogoUploadContainer">
              <label htmlFor="contained-button-file">
                <FilePicker
                  extensions={["jpg", "jpeg", "png"]}
                  onChange={filePickerOnChange}
                  onError={(errMsg) => {
                    NotificationManager.error(errMsg, "Error");
                  }}
                >
                  {/*--------- Upload Organization Button------------ */}
                  <button className="logoButton_rp">
                    <CloudUploadIcon />{" "}
                    <span className="logoButton_rp_text">Upload Logo</span>
                  </button>
                </FilePicker>
              </label>
              <Typography variant="subtitle2" component="h2">
                {selectedFileName ? (
                  <div className={classes.root}>
                    <Alert
                      id="orglogoFileName"
                      onClose={logoRemove}
                      closeText="Remove"
                    >
                      {selectedFileName}
                    </Alert>
                  </div>
                ) : (
                  ""
                )}
              </Typography>
            </div>
          </FormControl>
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={createRecruiter}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
        <Dialog
          id="mainContainer"
          open={profilePictureEditModal}
          onClose={closeProfilePicModal}
          aria-labelledby="customized-dialog-title"
          maxWidth="sm"
          fullWidth={true}
          className="record-video-intro-responsive"
        >
          <DialogTitleForProfilePic
            className={`${classes.changeProfiletitle} responsive-profilepic_title`}
            onClose={closeProfilePicModal}
          >
            Organization Logo
          </DialogTitleForProfilePic>

          <DialogContentForprofilepic
            className={`${classes.changeprofilebody} responsive-profilepic_body`}
          >
            {display ? null : (
              <div className="text-center">
                <div className="crop-container" id="rectCrop">
                  <div id="freeCropper">
                    <ImageCropper
                      id="freeCropper"
                      imageToCrop={imageToCrop}
                      onImageCropped={(croppedImage2) =>
                        getImgFreeCrop(croppedImage2)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </DialogContentForprofilepic>
          {display ? null : (
            <>
              <div className="divider_adjust">
                <hr className="new3 mt_20 mb_0" />
              </div>
              <DialogActions
                className={`${classes.buttonAlign} responsive-dialog`}
              >
                <button
                  type="button"
                  className="btn-big btn-green ml_5 cp "
                  // onClick={showCroppedImage} // <--------- LOGO UPLOAD TRIGGER
                  onClick={logoSave}
                >
                  Save Logo
                </button>
                <button
                  type="button"
                  className="btn-big btn-skill cp "
                  onClick={closeProfilePicModal}
                >
                  Cancel
                </button>
              </DialogActions>
            </>
          )}
        </Dialog>
        <NotificationContainer />
      </Dialog>
      {/* Edit */}
      <Dialog
        onClose={handleClosed}
        aria-labelledby="customized-dialog-title"
        open={modalIsOpene}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle className="admindailog_title_modal">
            Edit Recruitment Organization
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "grey",
              }}
              onClick={handleClosed}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>

        <DialogContent>
          <Typography gutterBottom>Recruitment Organization Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Enter Recruitment Organization Name"
              className="admin_textbox"
              defaultValue={recruitmentPartnerInfo.recName}
              onChange={handleName}
            />
          </FormControl>
          &nbsp;
          <Typography>Is Active*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={isActiveItems}
              disabled
              defaultValue={recruitmentPartnerInfo.status === "true" ? "True" : "False"}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Status"
                />
              )}
              onChange={handleisActive}
            />
          </FormControl>
          &nbsp;
          <Typography>Organization Logo (Optional)</Typography>
          <FormControl fullWidth variant="outlined">
            <div id="orgLogoUploadContainer">
              <label htmlFor="contained-button-file">
                <FilePicker
                  extensions={["jpg", "jpeg", "png"]}
                  onChange={filePickerOnChange}
                  onError={(errMsg) => {
                    NotificationManager.error(errMsg, "Error");
                  }}
                >
                  <button className="logoButton_rp">
                    <CloudUploadIcon />{" "}
                    <span className="logoButton_text"></span>
                    {selectedFileName === null && recruiterPartnerLogo ? (
                      <div className="logoButton_rp_text">Change Logo</div>
                    ) : (
                      <div className="logoButton_rp_text">Upload Logo</div>
                    )}
                  </button>
                </FilePicker>
              </label>
              {selectedFileName === null && recruiterPartnerLogo ? (
                <div>
                  <img
                    className="recruiterLogoContainer"
                    id="editRecruiterLogoContainer"
                    src={recruiterPartnerLogo}
                    alt=""
                  />
                </div>
              ) : (
                <Typography variant="subtitle2" component="h2">
                  {selectedFileName ? (
                    <div className={classes.root}>
                      <Alert
                        id="orglogoFileName"
                        onClose={logoRemove}
                        closeText="Remove"
                      >
                        {selectedFileName}
                      </Alert>
                    </div>
                  ) : (
                    ""
                  )}
                </Typography>
              )}
            </div>
          </FormControl>
        </DialogContent>

        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={handleClosed}>
              Cancel
            </Button>

            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={editRecruiter}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
        <Dialog
          id="mainContainer"
          open={profilePictureEditModal}
          onClose={closeProfilePicModal}
          aria-labelledby="customized-dialog-title"
          maxWidth="sm"
          fullWidth={true}
          className="record-video-intro-responsive"
        >
          <DialogTitleForProfilePic
            className={`${classes.changeProfiletitle} responsive-profilepic_title`}
            onClose={closeProfilePicModal}
          >
            Organization Logo
          </DialogTitleForProfilePic>

          <DialogContentForprofilepic
            className={`${classes.changeprofilebody} responsive-profilepic_body`}
          >
            {display ? null : (
              <div
                className="text-center"
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <div className="crop-container" id="rectCrop">
                  <div id="freeCropper">
                    <ImageCropper
                      id="freeCropper"
                      imageToCrop={imageToCrop}
                      onImageCropped={(croppedImage2) =>
                        getImgFreeCrop(croppedImage2)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </DialogContentForprofilepic>
          {display ? null : (
            <>
              <div className="divider_adjust">
                <hr className="new3 mt_20 mb_0" />
              </div>
              <DialogActions
                className={`${classes.buttonAlign} responsive-dialog`}
              >
                <button
                  type="button"
                  className="btn-big btn-green ml_5 cp "
                  onClick={logoSave}
                >
                  Save Logo
                </button>
                <button
                  type="button"
                  className="btn-big btn-skill cp "
                  onClick={closeProfilePicModal}
                >
                  Cancel
                </button>
              </DialogActions>
            </>
          )}
        </Dialog>
        <NotificationContainer />
      </Dialog>
    </>
  );
};

export default RecruitmentPartners;
