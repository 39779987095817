import React from 'react';
import "./TestLandingSkeleton.css";
import { Skeleton } from "@material-ui/lab";

const TestLandingSkeleton = () => {
  return (
    <div className='skeleton_container' >

      {/* first skeleton container */}
      <div className='skeleton_items_first'>
        <div>
          <Skeleton variant="text" className='skeleton_width' height={45} />
        </div>
        <div>
          <Skeleton variant="text" width={300} height={45} />
        </div>
      </div>

      {/* second skeleton container */}
      <div className='skeleton_items_second'>
        <div className='skeleton_items_1'>
          <Skeleton variant="rect" className="skeleton_width" height={350} />
          <Skeleton variant="text" className="skeleton_width" height={10} />
        </div>
        <div className='skeleton_items_2'>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="text" className="skeleton_width" height={40} />
          </div>
          <div>
            <Skeleton variant="rect" width={200} height={50} />
          </div>
        </div>

      </div>
    </div>

  )
}

export default TestLandingSkeleton;