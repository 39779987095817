import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import Group121 from "./images/Group 121.png";
import Group174img from "./images/Group 174-disabled.png";
import Group175img from "./images/Group 175.png";
import timerImage from "./images/time.png";
import incorrect from "./images/incorrect.png";
import correct from "./images/correct.png";
import axios from "axios";
import ReactPlayer from "react-player";
import "./TestResult.css";
import Logo from "../basicInfoForm/images/logo_recruiter_header.png";
import { useHistory } from "react-router";
import testQuestionDescription from "../../utilities/questionSyntaxHighlight";
import Prism from "prismjs";
import getUserTestByTestId from "../../apis/userTest/userTestByTestId";
import profileResponse from "../../apis/profile/profileById";
import getUserTestQuestionAnswer from "../../apis/userTest/userTestQuestionAnswerByTestId";
import Header from "../poweResumeHeader/header";
import recruiter_header_logo from "../basicInfoForm/images/logo_recruiter_header.png";

const TestResult = (props) => {
  const { match } = props;
  const history = useHistory();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  const [questionTime, setQuestionTime] = useState("");
  const [answerTime, setAnswerTime] = useState("");
  const [userAnswerList, setUserAnswerList] = useState([]);
  const [skillTestDate, setSkillTestDate] = useState("");
  const [testSkill, setTestSkill] = useState("");
  const [accenture, setAccenture] = useState("");
  const [profileData, setProfileData] = useState({})
  const jsonConfig = require("../../Config.json");
  const [totalnoofquestion, settotalnoofquestion] = useState("")
  const img_url = "http://localhost:3000/uploads/";
  useEffect(() => {
    const fetchAllVideos = async () => {
      if (userAnswerList) {

        for (const answer of userAnswerList) {
          if (answer && answer.videoURL) {
            await fetch(answer.videoURL);
          }
        }
      }
    };
    fetchAllVideos();
  }, [userAnswerList]);
  setTimeout(() => Prism.highlightAll(), 0);

  // user test accenture data
  // const userTestAccenture = async () => {
  //   const testId = match.params.id;
  //   try {
  //     if (testId) {
  //       const userTestAccentureDetails = await axios.get(
  //         jsonConfig.apiUrl + "userTestsAccentureBytestId/" + testId,
  //         {
  //           headers: {
  //             "x-functions-key": jsonConfig.key,
  //           },
  //         }
  //       );
  //       console.log("accenturedata", userTestAccentureDetails);
  //       // setTestSkill(userTestDetails.data.data)
  //       setAccenture(userTestAccentureDetails.data.data[0]);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // get Skill data

  const userTest = async () => {
    const testId = match.params.id;
    try {
      if (testId) {
        const userTestDetails = await getUserTestByTestId(testId);

        setTestSkill(userTestDetails.data.data);
        await profileDataResponse(userTestDetails.data.data.userTestUser.id)
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get profiledata

  const profileDataResponse = async (userid) => {

    try {
      const response = await profileResponse(userid);

      setProfileData(response.data.data[0])

    } catch (error) {
      // do nothing
      console.log("profileerror", error)
    }
  };


  // get test details

  const getTestDetails = async () => {
    const testId = match.params.id;
    try {
      if (testId) {
        const userTestDetails = await getUserTestQuestionAnswer(testId);
        const tempUsersTestDetails = userTestDetails.data.data;
        settotalnoofquestion(tempUsersTestDetails.length)
        let totalQuestionSecondsAlloted = 0;
        let totalQuestionMinutesAlloted = 0;
        tempUsersTestDetails.forEach((el) => {
          totalQuestionSecondsAlloted =
            totalQuestionSecondsAlloted +
            Number.parseInt(el.userTestQuestionQuestion.timeLimit);
        });
        if (totalQuestionSecondsAlloted > 60) {

          totalQuestionMinutesAlloted = (totalQuestionSecondsAlloted / 60).toFixed(1);
          setQuestionTime(totalQuestionMinutesAlloted + " Minutes");
        } else {
          setQuestionTime(totalQuestionSecondsAlloted + " Seconds");
        }

        let totalAnswerSecondsAlloted = 0;
        let totalAnswerMinutesAlloted = 0;
        tempUsersTestDetails.forEach((el) => {
          totalAnswerSecondsAlloted =
            totalAnswerSecondsAlloted + Number.parseInt(el.userTimeTaken);
        });

        if (totalAnswerSecondsAlloted > 60) {


          if (totalAnswerSecondsAlloted % 60 == 0) {
            totalAnswerMinutesAlloted = totalAnswerSecondsAlloted / 60;
            setAnswerTime(totalAnswerMinutesAlloted + " Minutes");
          }
          else {
            totalAnswerMinutesAlloted = Math.floor(totalAnswerSecondsAlloted / 60)
            setAnswerTime(totalAnswerMinutesAlloted + " Minutes " + totalAnswerSecondsAlloted % 60 + " Seconds");
          }



        } else {
          setAnswerTime(totalAnswerSecondsAlloted + " Seconds");
        }
        setTotalQuestions(tempUsersTestDetails.length);
        let userTestScore = 0;
        tempUsersTestDetails.forEach((el) => {
          if (el.isCorrect) {
            userTestScore = userTestScore + 1;
          }
        });
        setTotalCorrectAnswers(userTestScore);
        setSkillTestDate(new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(tempUsersTestDetails[0].createdAt))
          + "-" +
          new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(tempUsersTestDetails[0].createdAt))
          + "-" +
          new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(tempUsersTestDetails[0].createdAt))
          + " " +
          new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric' }).format(new Date(tempUsersTestDetails[0].createdAt)));

        setUserAnswerList(userTestDetails.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const minToSec = (timeLimit) => {
    let minutes = Math.floor(timeLimit / 60)
    let seconds = timeLimit - minutes * 60;
    return minutes + (minutes === 1 ? ' minute' : ' minutes') + ' ' + (seconds < 10 ? "0" : "") + seconds + ' seconds';
  }
  // Format Date
  const formatDate = (dateVal) => {
    let date = new Date(dateVal);

    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  };

  // Toggle Questions
  const questionsHandler = (index) => {
    let inputCheckboxes = document.querySelectorAll(".clearcheckbox");
    for (let i = 0; i < inputCheckboxes.length; i++) {
      inputCheckboxes[i].checked = false;
    }
    setQuestionIndex(index);
    setQuestionNumber(index + 1);
  };




  const IsJsonString = (str) => {
    // alert("ok")
    try {
      var locationjson = JSON.parse(str);
      if (Object.keys(locationjson).length > 0) {
        return true;
      }
      else {
        return false;
      }
    } catch (e) {
      return false;
    }
    // return true;
  }

  const responsive = {
    0: {
      items: 3,
    },
    600: {
      items: 3,
    },
    1024: {
      items: totalnoofquestion,
    },
  };
  // const customDividerStyle = {
  //   height: "274px",
  // };

  useEffect(() => {
    getTestDetails();
    userTest();
    // userTestAccenture();
    // console.log((window.location.href).split("/")[3]);
  }, []);

  return (
    <>
      <div className="nav_bg_home home-specific-header">
        <div className="container w1200 nav_bg_home_section">
          <div className="nav_bg_left">
            <li
              className="cp headerListStyleNone"
              onClick={() => history.push('/')}
            >
              <a>
                <img
                  src={recruiter_header_logo}
                  className="nav_logo_home"
                  alt="img"
                />
              </a>
            </li>
          </div>

        </div>
      </div>

      <div className="nav_bg_home_responsive">
        <div className="nav_bg_home_responsive-wrapper">
          <div className="home-top-nav">
            <li
              className="cp headerListStyleNone"
              onClick={() => history.push('/')}
            >
              <a>
                <img
                  src={recruiter_header_logo}
                  className="nav_logo_home"
                  alt="img"
                />
              </a>
            </li>

          </div>
        </div>
      </div>
      <div className="mainbg mainbgcenter">
        <div className="card test-result-card" id="test-result-card">
          <div className="test-result-container">
            <h6 className="test-result-heading">Skill Video Test Result</h6>
            <div className="playbuttonsection">
              {/* <div>
        <img src={Group121} alt="" className="playButton" />
      </div> */}
              <div>
                <p className="fs-semi-bold fs-25 f-color mb_3" id="testSkill_Name">
                  {testSkill?.test?.testName}
                </p>
                <p className="fs-regular fs-18 f-color mb_3">
                  Skill Test Taken On: {skillTestDate}
                </p>
                <p className="fs-regular fs-18 f-color mb_3">
                  Skill Test Duration: {questionTime}
                </p>
                <p className="fs-regular fs-18 f-color mb_3">
                  Time Taken By Candidate: {answerTime}
                </p>
              </div>

              <div className="accenture-data df">
                <div className="cadidateinfo_clm_one">
                  <p className="fs-regular fs-18 f-color mb_3">
                    First Name: {profileData.user?.firstName}
                  </p>
                  <p className="fs-regular fs-18 f-color mb_3">
                    Last Name: {profileData.user?.lastName}
                  </p>
                  <p className="fs-regular fs-18 f-color mb_3">
                    Email: {profileData.user?.email}
                  </p>
                </div>
                <div className="cadidateinfo_clm_two">
                  <p className="fs-regular fs-18 f-color mb_3">
                    Country: {profileData.locationCountry ? IsJsonString(profileData.locationCountry) ? JSON.parse(profileData.locationCountry).name : "" : ""}
                  </p>
                  <p className="fs-regular fs-18 f-color mb_3">
                    State: {profileData.locationState ? IsJsonString(profileData.locationState) ? JSON.parse(profileData.locationState).name : "" : ""}
                  </p>
                  <p className="fs-regular fs-18 f-color mb_3">
                    City: {profileData.locationCity ? IsJsonString(profileData.locationCity) ? JSON.parse(profileData.locationCity).name : "" : ""}
                  </p>
                </div>

              </div>
            </div>
            <hr className="new1" />
            <p className="fs-semi-bold fs-18 f-color">Score Card</p>
            <div className="scorecardsection">
              <div>
                <p className="fs-regular fs-18 f-color">Total Questions</p>
                <p className="fs-semi-bold fs-18">{totalQuestions}</p>
              </div>
              <div className="ml_41">
                <p className="fs-regular fs-18 f-color">
                  Total Correct Answers
                </p>
                <span className="fs-semi-bold fs-18 ml_5 green_text">
                  {totalCorrectAnswers}
                </span>
                <span className="fs-regular fs-18 ml_5">out of</span>
                <span className="fs-semi-bold fs-18 ml_5">
                  {totalQuestions}
                </span>
              </div>
              <div className="ml_41">
                <p className="fs-regular fs-18 f-color">Skill Test Score</p>
                <span className="fs-semi-bold fs-18 ml_5 green_text">
                  {totalCorrectAnswers}
                </span>
                <span className="fs-regular fs-18 ml_5">out of</span>
                <span className="fs-semi-bold fs-18 ml_5">
                  {totalQuestions}
                </span>
              </div>

              <div className="ml_41">
                <p className="fs-regular fs-18 f-color">Number of Violations</p>
                <span className="fs-semi-bold fs-18 ml_5 green_text">
                  {testSkill.NumberofViolations ? testSkill.NumberofViolations : 0}
                </span>
              </div>
            </div>
            <hr className="new1 " />
            <p className="fs-semi-bold fs-20 f-color">Questions</p>

            <div className="row mt_17 ml_5">
              <AliceCarousel responsive={responsive}>
                {userAnswerList.map((question, index) =>
                  userAnswerList[Number(index)]
                    ?.userTestQuestionUserTestQuestionAnswer?.length === 0 ? (
                    <button
                      type="button"
                      className={
                        questionIndex === index
                          ? "btnquestion btnquestion-questionselected  cp"
                          : "btnquestion btnquestion-questiondisable  cp"
                      }
                      key={index}
                      onClick={() => questionsHandler(index)}
                    >
                      <span>{index + 1}</span>
                      <span className="btn-icon">
                        <img
                          src={
                            questionIndex === index ? Group175img : Group174img
                          }
                          alt=""
                        />
                      </span>
                    </button>
                  ) : question.isCorrect === true ? (
                    <button
                      type="button"
                      className={
                        questionIndex === index
                          ? "btnquestion btnquestion-questionselected  cp"
                          : "btnquestion btnquestion-questionright  cp"
                      }
                      key={index}
                      onClick={() => questionsHandler(index)}
                    >
                      <span>{index + 1}</span>
                      <span className="btn-icon">
                        <img src={Group175img} alt="" />
                      </span>
                    </button>
                  ) : question.isCorrect === false ? (
                    <button
                      type="button"
                      className={
                        questionIndex === index
                          ? "btnquestion btnquestion-questionselected  cp"
                          : "btnquestion btnquestion-questionwrong  cp"
                      }
                      key={index}
                      onClick={() => questionsHandler(index)}
                    >
                      <span>{index + 1}</span>
                      <span className="btn-icon">
                        <img src={Group175img} alt="" />
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={
                        questionIndex === index
                          ? "btnquestion btnquestion-questionselected  cp"
                          : "btnquestion btnquestion-questiondisable  cp"
                      }
                      key={index}
                      onClick={() => questionsHandler(index)}
                    >
                      <span>{index + 1}</span>
                      <span className="btn-icon">
                        <img src={Group175img} alt="" />
                      </span>
                    </button>
                  )
                )}
              </AliceCarousel>
            </div>
            <hr className="new1 mt_17" />
            <div className="row df">
              <div className="mw" id="customWidthTest">
                <div className="df">
                  <div>
                    <p className="fs-regular fs-18 f-color mr_10">
                      Video response time limit:
                    </p>
                    <p className="fs-semi-bold fs-18 f-color">Response time:</p>
                  </div>

                  <div>
                    <p className="fs-regular fs-18 f-color">
                      {/* <span className="time_icon">
                        <img src={timerImage} />
                      </span>
                      {userAnswerList[questionIndex]?.userTestQuestionQuestion
                        ?.timeLimit + " seconds"} */}
                      {minToSec(userAnswerList[questionIndex]?.userTestQuestionQuestion
                        ?.timeLimit)}
                    </p>
                    <p className="fs-semi-bold fs-18 f-color">
                      {userAnswerList[questionIndex]?.userTimeTaken +
                        " seconds"}
                    </p>
                  </div>
                </div>
                <video autoplay
                  src={
                    userAnswerList
                      ? userAnswerList.length > 0
                        ? userAnswerList[questionIndex]
                          ? userAnswerList[questionIndex].videoURL
                            ? userAnswerList[questionIndex].videoURL
                            : ""
                          : ""
                        : ""
                      : ""
                  }
                  className="video-interview-test mt_17"
                  controls={true}
                />
              </div>
              <div className="vl"></div>
              <div className="mw ml_22 scrollHeight2 viewtestresults-questionside">
                <div className="fs-semi-ui-class fs-18 f-color videobuttomquestion">
                  <span className="fs-20">Q.{questionNumber}</span>
                  <span className="pt_2  pl_6">
                    {/* {userAnswerList[Number(questionIndex)]
                      ? userAnswerList[Number(questionIndex)]
                          .userTestQuestionQuestion
                        ? userAnswerList[Number(questionIndex)]
                            .userTestQuestionQuestion.questionDesc.split("\\n").join(' ')
                          ? userAnswerList[Number(questionIndex)]
                              .userTestQuestionQuestion.questionDesc.split("\\n").map(str =>(
                                <p className='string-whitespace-pre'>{str}</p>
                              ))
                          : null
                        : null
                      : null} */}
                    <div id="testQuestionDescp">
                      {testQuestionDescription(userAnswerList[Number(questionIndex)] ? userAnswerList[Number(questionIndex)].userTestQuestionQuestion.questionDesc : "")}
                    </div>
                  </span>
                </div>
                {userAnswerList[questionIndex]
                  ? userAnswerList[questionIndex].questionimg === ""
                    ? ""
                    : //To display image do not remove
                    // <div className="mt_16">
                    //   <img
                    //     src={
                    //       img_url + userAnswerList[questionIndex].questionimg
                    //     }
                    //     alt=""
                    //   />
                    // </div>
                    ""
                  : null}
                {userAnswerList[questionIndex]
                  ? userAnswerList[questionIndex].userTestQuestionQuestion
                    ? userAnswerList[questionIndex].userTestQuestionQuestion
                      .questionAnswer
                      ? userAnswerList[questionIndex].userTestQuestionQuestion
                        .questionAnswer.length > 0
                        ? userAnswerList[
                          questionIndex
                        ].userTestQuestionQuestion.questionAnswer.map(
                          (options, index) => (
                            <p
                              className={
                                userAnswerList[
                                  Number(questionIndex)
                                ]?.userTestQuestionUserTestQuestionAnswer.find(
                                  (el) =>
                                    el.userTestQuestionAnswerAnswer
                                      .isCorrect === true
                                )
                                  ? "questionoption questionoption-alignment right-question-option"
                                  : "questionoption questionoption-alignment wrong-question-option"
                              }
                              key={"v-ops-" + index}
                            >
                              {userAnswerList[Number(questionIndex)]
                                .userTestQuestionQuestion.questionAnswertype.code ==
                                'SingleChoice' ? (
                                <>
                                  <input
                                    type="radio"
                                    name="radio-group"
                                    id={options.id + "-radio-" + index}
                                    checked={
                                      userAnswerList[Number(questionIndex)]
                                        ?.userTestQuestionUserTestQuestionAnswer[0]
                                        ?.answerId == options.id
                                    }
                                    //  == userAnswerList[Number(questionIndex)].userTestQuestionUserTestQuestionAnswer[0].id
                                    value={options.id}
                                    disabled={true}
                                  />
                                  <label
                                    htmlhtmlFor={options.id}
                                    className="fs-semi-ui-class fs-18 f-color"
                                  >
                                    <div>
                                      {options.answerText &&
                                        options.answerText.includes("\n")
                                        ?
                                        options.answerText.split("\n")
                                          .map((item, idx) => (

                                            <p key={idx} className='without-linebreak' dangerouslySetInnerHTML={{
                                              __html: item
                                            }}>
                                            </p>

                                          ))
                                        :
                                        options.answerText.split("\\n")
                                          .map((item, idx) => (

                                            <span key={idx} className='without-linebreak' dangerouslySetInnerHTML={{
                                              __html: item
                                            }}>
                                            </span>

                                          ))
                                      }
                                    </div>
                                  </label>
                                </>
                              ) : (
                                <>
                                  <form>
                                    <div className="interview-chkbox-testresult">
                                      <input
                                        type="checkbox"
                                        className={
                                          userAnswerList[
                                            Number(questionIndex)
                                          ].isCorrect
                                            ? "right-checkbox clearcheckbox"
                                            : "wrong-checkbox clearcheckbox"
                                        }
                                        id={options.id + "-checkbox-" + index}
                                        checked={userAnswerList[
                                          Number(questionIndex)
                                        ].userTestQuestionUserTestQuestionAnswer.find(
                                          (el) => el.answerId == options.id
                                        )}
                                        value={options.id}
                                        disabled={true}
                                      />
                                      <label htmlFor={options.id}>
                                        <div>
                                          {options.answerText &&
                                            options.answerText.includes("\n")
                                            ?
                                            options.answerText.split("\n")
                                              .map((item, idx) => (
                                                <p key={idx} className='without-linebreak' dangerouslySetInnerHTML={{
                                                  __html: item
                                                }}></p>

                                              ))
                                            :
                                            options.answerText.split("\\n")
                                              .map((item, idx) => (
                                                <span key={idx} className='without-linebreak' dangerouslySetInnerHTML={{
                                                  __html: item
                                                }}></span>

                                              ))
                                          }
                                        </div>
                                      </label>
                                    </div>
                                  </form>
                                </>
                              )}
                            </p>
                          )
                        )
                        : null
                      : null
                    : null
                  : null}
                {userAnswerList[Number(questionIndex)] ? (
                  userAnswerList[Number(questionIndex)].isCorrect ? (
                    <div className="mt_17">
                      {userAnswerList[Number(questionIndex)]?.isCorrect ===
                        true ? (
                        <img src={correct} />
                      ) : null}
                    </div>
                  ) : (
                    <div className="mt_17">
                      {userAnswerList[Number(questionIndex)]?.isCorrect ===
                        false ? (
                        <img src={incorrect} />
                      ) : null}
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TestResult;
