import React, { useContext, useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress
} from "@material-ui/core";
import AddSpoc from './icons/addSPOC.png';
import ManageSpocTable from "../../components/manageSpocTable/manageSpocTable";
import Header from "../../components/headerAdmin/Header";
import './manageSpoc.css';
import { addSpoc } from "../../apis/organisationAdminAPI/manageSpoc";
import { updateSpoc } from "../../apis/organisationAdminAPI/manageSpoc";
import { getSPOCByOrg } from "../../apis/organisationAdminAPI/manageSpoc";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { Autocomplete } from "@material-ui/lab";
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import BasicHeader from '../../components/basicHeader/BasicHeader.js';
import { UserTypeContext } from "../../AppRoute";

const ManageSpoc = (props) => {
    const { userType, userRecords } = useContext(UserTypeContext);
    const userRecruitmentpartnerId = userType === "Admin" ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId
    const idContextTest = userType === "Admin" ? props?.location?.state?.id : userRecords?.id
    const userRole = userType === "Admin" ? props?.location?.state?.userRole : userRecords?.role?.roleName
    const userRecordData = userType === "Admin" ? props?.location?.state?.userRecords : userRecords
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [editedRow, setEditedRow] = useState({});
    const [spocs, setSpocs] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const [spocInfo, setSpocInfo] = useState({
        name: "",
        client: "",
        email: "",
        address: "",
        active: { name: "True", value: true }
    })
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)

    const handleOpenDialog = () => {
        setOpenDialog(true);
        setEditedRow({});
    };
    const handelEditOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow({});
        setSpocInfo({
            name: "",
            client: "",
            email: "",
            address: "",
            active: { name: "True", value: true }
        })
    };

    const handleSubmit = async () => {
        if (spocInfo.name === "" ||
            spocInfo.client === "" ||
            spocInfo.email === "" ||
            spocInfo.address === "" ||
            spocInfo.active === ""
        ) {
            NotificationManager.error(
                "Please fill in all the required fields",
                "Error",
                3000
            );
        }
        else {
            try {
                let payload = {
                    "spocName": spocInfo?.name,
                    "clientId": spocInfo?.client?._id,
                    "spocEmail": spocInfo?.email,
                    "address": spocInfo?.address,
                    "isActive": spocInfo?.active?.value,
                    "recruitmentpartnerId": userRecruitmentpartnerId
                }
                setIsUserDataUpdating(true)
                if (Object.keys(editedRow).length > 0) {
                    const response = await updateSpoc(editedRow?._id, payload);
                    if (response.status === 200) {
                        setIsloading(true)
                        NotificationManager.success("POC details updated successfully", "Success", 3500);
                        setIsUserDataUpdating(false)
                        handleCloseDialog();
                        let spocsData = [...spocs];
                        setSpocs([]); // Clear the table data
                        let index = spocsData.findIndex((item) => item?._id === editedRow?._id);
                        spocsData[index] = response?.data?.data;
                        setSpocs(spocsData);
                        setIsloading(false)
                    }
                    else{
                        setIsUserDataUpdating(false)
                        NotificationManager.success(response?.data?.message || "Failed to update POC !!", "error", 3500);
                    }
                }
                else {
                    const response = await addSpoc(payload);
                    if (response.status === 200) {
                        setIsloading(true)
                        NotificationManager.success("POC has been added successfully", "Success", 3500);
                        setIsUserDataUpdating(false)
                        handleCloseDialog();
                        let spocsData = [...spocs];
                        let arr = [];
                        let newSpoc = response?.data?.data;
                        arr.push(newSpoc);
                        spocsData = arr.concat(spocsData);
                        setSpocs(spocsData);
                        setIsloading(false)
                    }
                    else{
                        setIsUserDataUpdating(false)
                        NotificationManager.success(response?.data?.message || "Failed to add POC !!", "error", 3500);
                    }
                }
            } catch (error) {
                if (error.response.status === 400 && error.response.data.errorCode === 400) {
                    const errorMessage = error?.response?.data?.errMessage;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
                else{
                    const errorMessage = error?.response?.data?.message;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
                setIsUserDataUpdating(false)
            }
        }
    };

    const getSpocs = async () => {
        setIsloading(true)
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            const response = await getSPOCByOrg(payload);
            if (response.status === 200) {
                setSpocs(response.data.data)
            }
            setIsloading(false)
        } catch (error) {
            setIsloading(false)
            console.log(error);
        }
    }
    const allClientsData = async () => {
        let payload = {
            recruitmentPartnerId: userRecruitmentpartnerId
        }
        try {
            let response = await getClientByOrg(payload);
            if (response.status === 200) {
                setClientData(response.data.data);
            }

        } catch (error) {
            setClientData([]);
            console.log(error);
        }
    };
    const handleEdit = (rowData) => {
        setSpocInfo({
            ...spocInfo,
            name: rowData.spocName,
            client: rowData.client[0],
            email: rowData.spocEmail,
            address: rowData.address,
            active: {
                name: rowData.isActive ? "True" : "False",
                value: rowData.isActive
            }
        })
        setEditedRow(rowData); // Set the row data when edit button is clicked
        handelEditOpenDialog(); // Open the dialog
    };
    useEffect(() => {
        if (userRecruitmentpartnerId) {
            getSpocs()
            allClientsData()
        }
    }, [])

    const handleNameChange = (e) => {
        if (e.target.value) {
            setSpocInfo({ ...spocInfo, name: e.target.value })
        } else {
            setSpocInfo({ ...spocInfo, name: "" })
        }
    }
    const handleClientChange = (e, value) => {
        if (value) {
            setSpocInfo({ ...spocInfo, client: value })
        } else {
            setSpocInfo({ ...spocInfo, client: "" })
        }
    }
    const handleEmailChange = (e) => {
        if (e.target.value) {
            setSpocInfo({ ...spocInfo, email: e.target.value })
        } else {
            setSpocInfo({ ...spocInfo, email: "" })
        }
    }
    const handleAddressChange = (e) => {
        if (e.target.value) {
            setSpocInfo({ ...spocInfo, address: e.target.value })
        } else {
            setSpocInfo({ ...spocInfo, address: "" })
        }
    }
    const handleActiveChange = (e, value) => {
        if (value) {
            setSpocInfo({ ...spocInfo, active: value })
        } else {
            setSpocInfo({ ...spocInfo, active: "" })
        }
    }
    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecordData,
                roleName: userRole
            }
        });
    }
    return (
        <>
            {(userType === "Organization Admin" || userType === "MIS Operator" || (userType === 'Admin' && userRole === 'Organization Admin') || (userType === 'Admin' && userRole === 'MIS Operator')) ?
                <>
                    <NotificationContainer />
                    <Header />
                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-spoc-main">
                                <div className="header-spoc">
                                    <div className="header-image">
                                        <Typography className="manage-spoc-main-head">Manage POC</Typography>
                                    </div>
                                    {userType === "Admin" ? "" :
                                        <Button className="add-button"
                                            onClick={handleOpenDialog}
                                            startIcon={<img src={AddSpoc} alt="Add SPOC" style={{ width: "16px", height: "16px" }} />}
                                        >
                                            Add POC
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                <ManageSpocTable spocs={spocs} isloading={isloading} onEdit={handleEdit} userType={userType} />

                            </div>
                        </div>
                    </div>

                </>
                : <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="sm"
                fullWidth={true}
                className="add-spoc"
            >
                <div className="spoc_dialog_modal" >
                    <div className='spoc-dialog-TitleContainer'>
                        <img src={AddSpoc} alt="Add SPOC" className="dialog-image" />
                        <span className="dialog-title-text">{Object.keys(editedRow).length > 0 ? 'Edit POC' : 'Add POC'}</span>
                    </div>
                </div>
                <DialogContent className="spoc-dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className='field-title-spoc-dialog'>POC Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter POC Name"
                                className='input-field-spoc-dialog'
                                value={spocInfo.name}
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-spoc-dialog'>Client*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={spocInfo.client}
                                options={clientData}
                                getOptionLabel={(option) => option?.clientName}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Client"
                                    />
                                )}
                                onChange={handleClientChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-spoc-dialog'>Email*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                className='input-field-spoc-dialog'
                                placeholder="Enter Email"
                                value={spocInfo.email}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-spoc-dialog'>Address*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Address"
                                value={spocInfo.address}
                                multiline
                                minRows={4}
                                onChange={handleAddressChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='field-title-spoc-dialog'>Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={spocInfo.active}
                                options={activeDropDown}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}
                                onChange={handleActiveChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className='dialog-actionbutton-spoc'>
                    <button onClick={handleCloseDialog} className="cancel-button-spoc">
                        Cancel
                    </button>
                    <button
                        className={isUserDataUpdating ? 'updateButton_spoc_disabled' : 'updateButton-spoc'}
                        onClick={handleSubmit}
                        disabled={isUserDataUpdating}
                    >

                        <span>Save</span>
                        {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton_spoc" />) : null}


                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ManageSpoc;