import axios from "axios";
const jsonConfig = require("../Config.json");

const getCandidateProfileBasedOnOrganization = async (candidateId,recruitmentpartnerId) => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "getCandidateProfileBasedOnOrg/"+candidateId+"/"+recruitmentpartnerId, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default getCandidateProfileBasedOnOrganization;
