import axios from "axios";
const jsonConfig = require("../../Config.json");

const getPublishedUserTests = async (id) => {
    let allResponse = {}
    try {
        const getallresponse = await axios.get(
            jsonConfig.apiUrl + "getCompletedPublishedTestsByUserId/"+id,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          )
        allResponse = getallresponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default getPublishedUserTests;
