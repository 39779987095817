import {
 
  FormControl,
  OutlinedInput,
  Tooltip,
  
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import validateEmail from "../../utilities/validateEmail";
import { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router";

import "./style.css";


import reSendOtpResponse from "../../apis/otp/reSendOtp";
import otpVerificationResponse from "../../apis/otpVerification/otpVerfication";
import emailVerificationResponse from "../../apis/otp/emailVerification";
import subSkillBySkillIdResponse from "../../apis/skills/SubSkillBySkillId";
import phoneNumberExistResponse from "../../apis/users/getUserDataByPhoneNumber";
import csc from "../../utilities/country-state-city/dist";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import "./registerTaketest.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import getAllPublicTest from "../../apis/Tests/getAllPublicTest";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";

import HomeContactUsModal from "../../pages/home/homeHeader/homeContactUsModal/homeContactUs";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const filterSkillOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.testName,
});

export default function FormOne(props) {
  const today = new Date();
  const history = useHistory();

  const [firstName, setFirstName] = useState(
    props.location.state ? props.location.state.firstName : ""
  );
  const [lastName, setLastName] = useState(
    props.location.state ? props.location.state.lastName : ""
  );
  const [MiddleInitial, setMiddleInitial] = useState(
    props.location.state ? props.location.state.MiddleInitial : ""
  );
  const [email, setEmail] = useState(
    props.location.state ? props.location.state.email : ""
  );
  const [phoneNumber, setphoneNumber] = useState("");
  const [Phonecode, setPhonecode] = useState("");
  const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
  const [phoneError, setPhoneError] = useState(null);
 
  const [publictest, setpublictest] = useState([]);
  const [modalCountryObject, setModalCountryObject] = useState(
    props.location.state ? JSON.parse(props.location.state.locationCountry) : {}
  );
  const [stateList, setStateList] = useState(Array);
  const [modalStateObject, setModalStateObject] = useState(
    props.location.state ? JSON.parse(props.location.state.locationState) : {}
  );
  const [cityList, setCityList] = useState(Array);
  const [modalCityObject, setModalCityObject] = useState(
    props.location.state?.locationCity
      ? JSON.parse(props.location.state.locationCity)
      : {}
  );
  const [userCountry, setUserCountry] = useState(
    props.location.state ? JSON.parse(props.location.state.locationCountry) : {}
  );
  const [userState, setUserState] = useState(
    props.location.state ? JSON.parse(props.location.state.locationState) : {}
  );
  const [userCity, setUserCity] = useState(
    props.location.state?.locationCity
      ? JSON.parse(props.location.state.locationCity)
      : {}
  );
  
  const [subSill, setSubSkill] = useState([]);
  const [skillid, setskillId] = useState(
    props.location.state ? props.location.state.skillId : ""
  );
  const [testDefault, settestDefault] = useState(
    props.location.state ? props.location.state.test : {}
  );

  const [IsbtnDisabled, setIsbtnDisabled] = useState(
    props.location.state ? false : true
  );
  const [verifyEmailBtn, setVerifyEmailBtn] = useState(
    props.location.state ? true : false
  );
  const [formError, setFormError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(
    props.location.state ? true : false
  );
  const [isEmailDisabled, setisEmailDisabled] = useState(
    props.location.state ? true : false
  );
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(300000);
  const [emailidId, setemailidId] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(
    props.location.state ? true : false
  );
 
  const hamburger = useRef(0);
  const [open, setOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false);
  const [countryList, setCountryList] = useState([
    {
      currency: "USD",
      flag: "",
      isoCode: "US",
      latitude: "38.00000000",
      longitude: "-97.00000000",
      name: "United States",
      phonecode: "+1",
    },
    {
      currency: "CAD",
      flag: "",
      isoCode: "CA",
      latitude: "60.00000000",
      longitude: "-95.00000000",
      name: "Canada",
      phonecode: "+1",
    },
    {
      currency: "INR",
      flag: "",
      isoCode: "IN",
      latitude: "20.00000000",
      longitude: "77.00000000",
      name: "India",
      phonecode: "+91",
    },
    {
      currency: "MXN",
      flag: "",
      isoCode: "MX",
      latitude: "23.00000000",
      longitude: "-102.00000000",
      name: "Mexico",
      phonecode: "+52",
    }
  ]);
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const [otpValue, setOtpValue] = useState("");

  const emailChangeHandler = (event) => {
    setFormError(true);
    const emailValue = event.target.value;
    setEmail(emailValue);
    const emailValidate = validateEmail(emailValue);
    if (emailValidate && emailValue) {
      setVerifyEmailBtn(true);
    } else {
      setVerifyEmailBtn(false);
    }
  };
  const changeEmailId = () => {
    setisEmailDisabled(false);
    setOtpSent(false);
    setOtpValue("");
    setIsEmailVerified(false);
    setIsActive(!isActive);
    setCounter(300000);
  };
  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      (seconds === 0 ? "00" : seconds)
    );
  };

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          setCounter((counter) => counter - 1000);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId); ///componentWillUnmount here mounting phase is distroyed
  }, [isActive, counter]);

  const reSendOtp = async () => {
    const payloadData = {
      id: emailidId,
      email: email,
      isVerified: false,
    };
    try {
      await reSendOtpResponse(payloadData);
      setIsActive(true);
      setCounter(300000);
    } catch (error) {
      console.log(error);
    }
  };

  const sendOtp = async (e) => {
    const payLoad = {
      email: email,
      isVerified: false,
    };

    try {
      const sendOtpData = await emailVerificationResponse(payLoad);
      setemailidId(sendOtpData.data.id);
      if (sendOtpData.status===200) {
        return NotificationManager.error(
          "User already exists in ClearedTalent",
          "Error",
          2500
        );
      }
      setOtpSent(true);
      setisEmailDisabled(true);
      setIsActive(!isActive);
      setDisableButton(false);
      NotificationManager.success("OTP sent", "Success", 2500);
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const otpVerification = async () => {
    const payLoad = {
      id: emailidId,
      email: email,
      otpHash: otpValue,
    };
    if (otpValue !== "" && otpValue.length === 6) {
      try {
        const verifyOtpData = await otpVerificationResponse(payLoad);
        if (verifyOtpData.data.message.includes("Invalid OTP")) {
          setDisableButton(false);
          return NotificationManager.error(
            "Please enter valid OTP",
            "Error",
            2500
          );
        } else {
          setIsEmailVerified(true);
          setDisableButton(true);
          return NotificationManager.success("OTP verified", "Success", 2500);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      NotificationManager.error("Please enter valid OTP", "Error", 2500);
    }
  };
  // Ending of new flow

  // get all active skills

  const getAllpublictests = async () => {
    try {
      let allSkillResponse = await getAllPublicTest();
      setpublictest(allSkillResponse.filter((i) => !i.isPrivate));

    } catch (error) {
      console.log(error.response.data);
    }
  };

  // skill change
  const skillChange = async (event, value) => {
    if (value) {
      setskillId(value.id);
      getSubSkill(value.id);
      settestDefault(value);
    }
  };

  //  get subskill

  const getSubSkill = async (skillid) => {
    try {
      const response = await subSkillBySkillIdResponse(skillid);
      setSubSkill(response.data.data);
    } catch (error) {
      console.error("subskillerror", error);
      setSubSkill([]);
    }
  };

 
  useEffect(() => {
    getAllpublictests();
  }, []);

  const checkPhoneNumber = async (phoneNumber) => {
    var phoneNumberInput = {
      phoneNumber: phoneNumber,
    };
    try {
      const phoneNochkResponse = await phoneNumberExistResponse(
        phoneNumberInput
      );
      if (phoneNochkResponse.data.data[0].isUnique === true) {
        return phoneNochkResponse.data.data[0].isUnique;
      }
    } catch (error) {
      console.log("phonenoresponse", error);
    }
  };
  const continueRegisterHandler = async (e) => {
    e.preventDefault();
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      skillid === "" ||
      Object.keys(userCountry).length === 0 ||
      Object.keys(userState).length === 0
    ) {
      return NotificationManager.error(
        "Please fill all required fields",
        "Error",
        2500
      );
    }
    if (IsbtnDisabled) {
      return NotificationManager.error(
        "Please agree to terms of use & privacy policy to continue",
        "Error",
        2500
      );
    }
    if (phoneError) {
      return NotificationManager.error(
        "Please enter a valid phone number",
        "Error",
        2500
      );
    }
    if (phoneNumber !== "") {
      const isunique = await checkPhoneNumber(phoneNumber);
      if (!isunique) {
     
        return NotificationManager.error(
          "Phone number already exists in our system",
          "Error",
          2500
        );
      }
    }
    history.push({
      pathname: "/test-landing",
      state: {
        firstName,
        lastName,
        MiddleInitial,
        phoneNumber: phoneNumber ? phoneNumber : "",
        countryCode: Phonecode,
        email,
        locationCountry: JSON.stringify(userCountry),
        locationState: JSON.stringify(userState),
        locationCity: JSON.stringify(userCity),
        skillId: testDefault.skillId,
        subSkillsJson: subSill,
        test: testDefault,
        pageName:""
      },
    });
  };

 

  const countryHandler = (event, value) => {
    setModalStateObject();
    setModalCityObject("");
    if (value === null) {
      setUserCountry("");
      setDefaultCountryCode("")
    } else if (value) {
      setUserCountry(value);
      setDefaultCountryCode(value.isoCode)
      setStateList(csc.getStatesOfCountry(value.isoCode));
    }
  };

  const stateHandler = (event, value) => {
    setModalCityObject("");
    if (value === null) {
      setUserState("");
    } else if (value) {
      setUserState(value);
      setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode));
      setModalStateObject(value);
    }
  };

  const cityHandler = async (event, value) => {
    if (value) {
      setUserCity(value);
      setModalCityObject(value);
    }
  };

  const phoneNumberChangeHandler = (val) => {
    setPhoneNoIntnl(val);
    if (val) {
      if (val && isPossiblePhoneNumber(val)) {
        setPhoneError(false)
        setPhonecode(`+${parsePhoneNumber(val).countryCallingCode}`);
        let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
        setphoneNumber(val.replace(countryCode_PhoneNumber, ""));
      }
      else {
        setPhoneError(true)
      }
    }
    else {
      setPhoneError(false)
      setPhonecode("")
      setphoneNumber("")
    }
  };

  const keypressHandler = (e) => {
    var invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const TermsCheckboxHandler = (e) => {
    setIsTermsAccepted(e.target.checked);
    if (e.target.checked === true) {
      setIsbtnDisabled(false);
    } else {
      setIsbtnDisabled(true);
    }
  };
  const goToTerms = (e) => {
    window.open("/terms-of-use");
  };

  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy");
  };


  
 
  const goToHomePage = () => {
    window.open("/", "_self");
  };
  const hamburgerClicked = () => {
    setHamburgerIsClicked(!hamburgerIsClicked);
  };
  const openSupportModal = () => {
    setOpen(true);
  };

  const _modalClose = () => {
    setOpen(false);
  };
  const gotoSignin = () => {
    history.push("/signIn");
  };

  return (
    <>
      <div className="mainContainer_reg">
        <div className="topContainer">
          <div className="nav_bg_home home-specific-header">
            <div className="container w1200 nav_bg_home_section">
              <div className="nav_bg_left">
                <li
                  className="cp headerListStyleNone"
                  onClick={() => goToHomePage()}
                >
                    <img
                      src={recruiter_header_logo}
                      className="nav_logo_home"
                      alt="img"
                    />
              
                </li>
              </div>
              <div className="nav_bg_right">
                <ul className="navbar_home">
                  <li className="special_hover cp" onClick={openSupportModal}>
                    <span className="contactUsFont">Contact Us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="nav_bg_home_responsive">
            <div className="nav_bg_home_responsive-wrapper">
              <div className="home-top-nav">
                <li
                  className="cp headerListStyleNone"
                  onClick={() => goToHomePage()}
                >
                 
                    <img
                      src={recruiter_header_logo}
                      className="nav_logo_home"
                      alt="img"
                    />
                 
                </li>
                <div
                  className={
                    hamburgerIsClicked
                      ? "hamburgerIsClicked"
                      : "hamburgerIsNotClicked"
                  }
                  ref={hamburger}
                >
                  <ul className="navbar_home-responsive">
                    <li className="special_hover cp" onClick={openSupportModal}>
                      <span>Contact Us</span>
                    </li>
                  </ul>
                </div>
                <span className="icon" onClick={hamburgerClicked}>
                  <i className="fa fa-bars"></i>
                </span>
              </div>
            </div>
          </div>
          <Grid container className="mainDiv_reg">
            <Grid item xs={12}>
              <div className="rightHeading">Register & Take Test</div>
            </Grid>
            <Grid item sm={6}>
            <div className="leftContainer">
                <div className="leftHeading">
                  Please read the instructions below before you start test
                </div>
                <p className="left_points">
                  <span className="leftBullets">1.</span> For multiple choice questions, you may be allowed to pick just one answer choice or multiple. The clock starts as soon as you click <span className="leftBullets">“Start Test”</span> so start <span className="leftBullets">ONLY</span> when you are ready.
                </p>
                <p className="left_points">
                  <span className="leftBullets">2.</span> Some questions may have two parts. For example, Part 1 may be an open ended question where you are expected to speak out your response on video for 1 minute and Part 2 may be a multiple choice question where you are expected to pick the correct answer(s) from the options provided. 
                </p>
                <p className="left_points">
                  <span className="leftBullets">3.</span> You will be video recorded during the test so please ensure your camera is active and focusing on you.
                </p>
                <p className="left_points">
                  <span className="leftBullets">4.</span> Please note that at no point during the test you can go back to the previous questions.
                </p>
                <p className="left_points">
                  <span className="leftBullets">5.</span> Please try not to navigate out of the test window (or open other applications) for the test duration. We detect such movements and report them to the reviewer.
                 
                </p>
              </div>
            </Grid>
         
            <Grid item sm={6}>
              <div className="rightContainer">

                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <div className="inputLabel_right">First Name*</div>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          placeholder="Enter your first name"
                          className="admin_textbox"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="inputLabel_right">Last Name*</div>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          placeholder="Enter your last name"
                          className="admin_textbox"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="inputLabel_right_email">
                        Email (OTP Verification Required)*
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          placeholder="Enter email"
                          className="admin_textbox"
                          onChange={(event) => emailChangeHandler(event)}
                          disabled={isEmailDisabled}
                        />
                      </FormControl>
                      {formError ? (
                        <>
                          {" "}
                          {verifyEmailBtn ? (
                            ""
                          ) : (
                            <p className="form-error fs-regular">
                              Please enter a valid email address
                            </p>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        {!otpSent ? (
                          <>
                            {verifyEmailBtn ? (
                              <Grid item xs={12}>
                                <button
                                  className="sendOtp"
                                  onClick={(e) => sendOtp(e)}
                                >
                                  Send OTP
                                </button>
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <button className="sendOtp_disabled">
                                  Send OTP
                                </button>
                              </Grid>
                            )}
                          </>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <div className="resendOtpDiv">
                                <button
                                  className="changeEmail"
                                  onClick={(e) => changeEmailId(e)}
                                >
                                  Change Email
                                </button>
                                <button
                                  className="resendOtp"
                                  onClick={(e) => reSendOtp(e)}
                                  disabled={counter}
                                >
                                  Resend OTP
                                </button>
                                {!!counter && (
                                  <p className="resendotp-timer fs-semi-bold">
                                    {millisToMinutesAndSeconds(counter)}{" "}
                                  </p>
                                )}
                              </div>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <div className="inputLabel_right_email">
                        Enter your OTP*
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                          placeholder="Enter the OTP"
                          className="admin_textbox"
                          value={otpValue}
                          onChange={(e) =>
                            setOtpValue(e.target.value.trim())
                          }
                          disabled={!otpSent ? true : isEmailVerified}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {otpSent && verifyEmailBtn ? (
                        <>
                          <button
                            className="sendOtp"
                            onClick={(e) => otpVerification(e)}
                            disabled={disableButton}
                          >
                            {disableButton ? "Verified" : "Verify"}
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="sendOtp_disabled">Verify</button>
                        </>
                      )}
                    </Grid>

                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <div className="inputLabel_right">Country*</div>
                      <Autocomplete
                        id="combo-box-demo"
                        className="mt_14"
                        options={countryList}
                        autoHighlight
                        filterOptions={filterOptions}
                        defaultValue={modalCountryObject}
                        getOptionLabel={(option) =>option.name||""}
                                              onChange={countryHandler}
                        renderInput={(params) => (
                          <TextField
                          
                            {...params}
                            variant="outlined"
                            required={true}
                            placeholder="Select country"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="inputLabel_right">Phone Number</div>
                      <div className="select customized-select dropdown_width_recruiter_invite w100 numericContainer">
                        <PhoneInput
                          international
                          defaultCountry={defaultCountryCode}
                          className="textbox_phone_input"
                          placeholder="Enter phone number"
                          value={phoneNoIntnl}
                          onChange={(e) => phoneNumberChangeHandler(e)}
                          onKeyPress={(e) => keypressHandler(e)}
                        />
                        {(phoneError) ? (
                          <p className="form-error fs-regular">
                            Please enter a valid phone number
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="inputLabel_right">State*</div>
                      <Autocomplete
                        id="combo-box-demo"
                        className="mt_14"
                        options={stateList}
                        autoHighlight
                        filterOptions={filterOptions}
                        value={modalStateObject}
                        getOptionLabel={(option) =>option.name||""}
                      
                        onChange={stateHandler}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required={true}
                            placeholder="Select state"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="inputLabel_right">City</div>
                      <Autocomplete
                        id="combo-box-demo"
                        className="mt_14"
                        options={cityList}
                        autoHighlight
                        filterOptions={filterOptions}
                        value={modalCityObject}
                        getOptionLabel={(option) => option.name||""}
                       
                        onChange={cityHandler}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select city"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="inputLabel_right">Select Skill*</div>
                      <Autocomplete
                        id="combo-box-demo"
                        className="mt_14"
                        options={publictest}
                        autoHighlight
                        filterOptions={filterSkillOptions}
                        value={testDefault}
                        getOptionLabel={(option) => option.testName||""}
                        
                        onChange={skillChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required
                            placeholder="Select a skill"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="termsContainer">
                        <div className="video-interview-chkbox basicinfo_chkbox">
                          <input
                            type="checkbox"
                            id="terms"
                            checked={isTermsAccepted}
                            onChange={(e)=>{}}
                            onClick={(e) => TermsCheckboxHandler(e)}
                          />
                          <label htmlFor="terms"></label>
                        </div>
                        <p className="f-color fs-semi-bold fs-16">
                          {" "}
                          I agree to ClearedTalent’s{" "}
                          <span className="termsLink" onClick={goToTerms}>
                            {" "}
                            Terms of Use{" "}
                          </span>
                          <span className="text-cyan"> & </span>
                          <span
                            className="termsLink"
                            onClick={goToPrivacyPolicy}
                          >
                            Privacy Policy{" "}
                          </span>
                        </p>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="continue_container">
                        {disableButton ? <button
                          disabled={!isEmailVerified}
                          onClick={(e) => continueRegisterHandler(e)}
                          className="continue_button"
                        >
                          Continue
                        </button> :
                          <button className="continue_button_disabled">

                            Continue
                          </button>}

                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="note">
                      Note : A field marked with * implies mandatory
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="alreadyAccount">
                      Already have an account?{" "}
                      <div className="loginLink" onClick={gotoSignin}>
                        Log-in
                      </div>
                    </div>
                  </Grid>
                </>

              </div>
            </Grid>
          </Grid>
          <NotificationContainer />
        </div>
        <div className="footer_bg_login">
          <div className="container w1200">
            <div className="container_rec">
              <div className="footer_left_logo homepage-footer-left-logo">
                <img src={recruiter_header_logo} className="w-100" alt="img" />
              </div>
              <div className="footer-right">
                <div className="social_links">
                  <a href="https://www.linkedin.com/company/clearedtalent/">
                    <Tooltip title="LinkedIn">
                      <LinkedIn className="iconColor" />
                    </Tooltip>
                  </a>
                  <a href="https://www.facebook.com/Cleared-Talent">
                    <Tooltip title="Facebook">
                      <Facebook className="iconColor" />
                    </Tooltip>
                  </a>
                  <a href="https://twitter.com/Cleared_Talent">
                    <Tooltip title="Twitter">
                      <Twitter className="iconColor" />
                    </Tooltip>
                  </a>
                  <a href="https://www.instagram.com/clearedtalent/">
                    <Tooltip title="Instagram">
                      <Instagram className="iconColor" />
                    </Tooltip>
                  </a>
                </div>
                <div className="footer-links">
                  <div>
                    ClearedTalent © {today.getFullYear()}.&nbsp; All Rights
                    Reserved.
                  </div>
                  <div>
                    <span className="footerTerms" onClick={goToTerms}>
                      Terms of Use
                    </span>
                    &nbsp;&nbsp;
                    <span className="footerTerms" onClick={goToPrivacyPolicy}>
                      Privacy Policy{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeContactUsModal modalOpen={open} modalClose={_modalClose} />
    </>
  );
}
