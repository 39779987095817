import axios from "axios";
const jsonConfig = require("../Config.json");

const getAllRecruitmentPartnersAndClients = async () => {
  try{
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "recruitmentpartner", {
           headers: {
             "x-functions-key": jsonConfig.key,
          },
        });

        return Promise.resolve(getAllResponse)

    }catch (error) {
       return Promise.reject(error)
    }
  }

export default getAllRecruitmentPartnersAndClients;
