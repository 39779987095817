import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { browserName, browserVersion } from 'react-device-detect'
import IncompatibleBrowser from './components/incompatible Browser/IncompatibleBrowser';
const jsonConfig = require('./Config.json')

ReactDOM.render(
  <React.StrictMode>
    {(browserName === "Chrome" && browserVersion < jsonConfig.browserSupport.Chrome.minVersion) || (browserName === "Edge" && browserVersion < jsonConfig.browserSupport.Edge.minVersion) ? <IncompatibleBrowser /> : <App />}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
