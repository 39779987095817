import React from 'react';
import { useState, useMemo } from "react";
import { codeTofield } from "../GlobalAssistingFunctions/GlobalAssistingFunctions";
import "./activityTabs.css"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteActivity from "./DeleteActivity.js";
import deleteCandidateActivityEventById from '../../apis/deleteCandidateActivityEventById/deleteCandidateActivityEventById.js';
import {
    NotificationContainer, NotificationManager
} from "react-notifications";

const AcitvityTabs = ({ setUpdate, data, setIndex, selectedCandReq, closeEdit, moveCandidate, userType, userIdType }) => {

    const [deleteIndex, setDeleteIndex] = useState(null); // State to track the item being deleted
    // switch modal and record the index of the clicked event
    const handleTabOnclick = (i) => {
        setIndex(i);
        setUpdate(false)
    }

    let tabs = useMemo(() => data?.map((elem, i) => ({ ...elem, index: i }))?.filter(elem => elem?.isCurrentlyUsed), [data]);

    // Handle the delete click event to open the DeleteActivity dialog
    const handleDeleteComponent = (i) => {
        setDeleteIndex(i); // Set the index of the item to be deleted
    }

    // Handle the actual delete confirmation logic
    const handleConfirmDelete = async () => {

        let resp = await deleteCandidateActivityEventById({ requisitionId: selectedCandReq.requisitionId, email: selectedCandReq.email, senderId: userIdType });

        if (resp?.status === 200) {
            NotificationManager.success("Candidate status updated successfully", "Success", 2000);
            let payload = resp?.data?.candidateRequisition
            moveCandidate(payload, payload?.email, tabs.at(-1)?.candidateStatusCode, payload?.status?.candidateStatusCode, !payload.isActive)
        }
        closeEdit()
    }


    return (
        <>
            {
                // if no data 
                tabs?.length > 0 ?
                    <div className='recentActivities_Container activityActionContainer' >
                        {tabs?.map((elem, i) => {
                            return <div key={"ActivityTabs " + i} className=' activityCapsule' >
                                <div className="activityTabsTitleAction">{codeTofield(elem.candidateStatusCode)}</div>
                                <div className="activityActionsTabs">
                                    <span onClick={() => { handleTabOnclick(elem?.index) }}>
                                        Edit<EditSharpIcon className="editIconeActivityTabs" />
                                    </span>
                                    {
                                        // show delete only for the last event
                                        i === tabs.length - 1 && userType === "MIS Operator" && <span onClick={() => handleDeleteComponent(i)} >Delete<DeleteOutlineIcon className="deleteIconeActivityTabs"
                                        /></span>
                                    }

                                </div>
                            </div>
                        })}
                    </div>
                    :
                    <div className='loaderForEdit'><h1>No events found</h1></div>

            }


            {/* Conditionally render the DeleteActivity dialog when deleteIndex is not null */}
            {deleteIndex !== null && (
                <DeleteActivity
                    data={data[deleteIndex]} // Pass the selected data to the DeleteActivity component
                    onConfirm={handleConfirmDelete} // Handle delete confirmation
                    onCancel={() => setDeleteIndex(null)} // Handle cancel action
                />
            )}
            <NotificationContainer />
        </>
    )
}

export default AcitvityTabs
