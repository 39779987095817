import React, { useState, useEffect, useContext, useCallback } from 'react';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import Container from '@material-ui/core/Container';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// import FormControl from '@material-ui/core/FormControl';
// import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import axios from "axios";
import csc from "../../utilities/country-state-city/dist";
// import { ICountry, IState, ICity } from "../../utilities/country-state-city/dist";
import "./search.css";
import { useMsal, useAccount } from "@azure/msal-react";
// import { loginRequest } from "../../authConfig";
// import Footer from "../../components/footer/footer";
import Header from "../../components/adminHeader/adminHeader";
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

// import FileViewer from 'react-file-viewer';
import { makeStyles } from '@material-ui/core/styles';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
// import TablePagination from '@material-ui/core/TablePagination';
// import { formatPhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';
import { UserTypeContext } from "../../AppRoute";
// import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Skeleton } from '@material-ui/lab';
import searchCandidateAdminAPI from "../../apis/searchCandidateAdminAPI";
import debounce from 'lodash/debounce';
// import {fflate} from 'fflate';
// const fflate = require('fflate');


const useStyles = makeStyles((theme) => ({

    buttonAlignForRemove: {
        padding: "32px!important"
    },
    bodyForRemove: {
        padding: "26px 24px 0px 24px!important",
        marginTop: "0px!important"
    },
    leftGrid : {
        // border: "1px solid blue",
        padding: "0px !important",
        boxShadow: "0px 5px 10px 0px rgba(26, 143, 255, 0.24)",
        borderRadius: "0px 0px 0px 20px",
        height: "fit-content",
    },
    searchTopContainer : {
        backgroundColor : "#D1E8FF",
        padding : "10px 10px 30px 10px"
    },
    searchBtn : {
        backgroundColor : "#1885e6",
        color : "white",
        borderRadius : "5px",
        padding : "5px 20px"
    },
    mb10: {
        marginBottom : "10px !important",
    },
    tableGridContainer : {
        paddingTop : "0px !important",
        width: "100%",
    },
    tableContainerStyle : {
        // paddingTop : "0px !important",
        // marginLeft : "5px",
        boxShadow : "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;",
        padding : "10px",
    },
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
    profileButton: {
        color: '#fff',
        backgroundColor: '#1885e6',
        width: '70px',
        '&:hover': {
            backgroundColor: '#1885e6',
            opacity: '0.9'
        }
    },
    
}));





const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(0.5)
    }
});

// const CloseAction = withStyles(styles)((props) => {
//     const { classes, onClose } = props;
//     return (
//         <MuiDialogActions className={classes.root}>
//             {onClose ? (
//                 <IconButton
//                     aria-label="close"
//                     // className={classes.closeButton}
//                     onClick={onClose}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogActions>
//     );
// });
const DialogContent = withStyles((theme) => ({
    root: {
        // padding: "0px 30px 0px 30px!important",
    }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(MuiDialogActions);

const Search = ({ idContextTest }) => {
    const jsonConfig = require("../../Config.json");
    // const { instance, accounts } = useMsal();
    const classes = useStyles();
    // const account = useAccount(accounts[0] || {});
    const { userType } = useContext(UserTypeContext);
    // const [countryList, setCountryList] = useState([{
    //     "currency": "USD",
    //     "flag": "",
    //     "isoCode": "US",
    //     "latitude": "38.00000000",
    //     "longitude": "-97.00000000",
    //     "name": "United States",
    //     "phonecode": "+1"
    // },
    // {
    //     "currency": "CAD",
    //     "flag": "",
    //     "isoCode": "CA",
    //     "latitude": "60.00000000",
    //     "longitude": "-95.00000000",
    //     "name": "Canada",
    //     "phonecode": "+1"
    // },
    // {
    //     "currency": "INR",
    //     "flag": "",
    //     "isoCode": "IN",
    //     "latitude": "20.00000000",
    //     "longitude": "77.00000000",
    //     "name": "India",
    //     "phonecode": "+91"
    // }]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [activeSkills, setActiveSkills] = useState([]);
    // const [activeIndustry, setActiveIndustry] = useState([]);
    // const [allRole, setAllRole] = useState([]);
    // const [allExp, setAllExp] = useState([]);
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setemail] = useState('');
    const [phnNo, setphnNo] = useState('');
    const [testScore, settestScore] = useState('');
    // const [totalExpId, settotalExpId] = useState('');
    const [skillId, setskillId] = useState('');
    // const [roleId, setroleId] = useState('');
    // const [industryId, setindustryId] = useState('');
    const [countryObject, setcountryObject] = useState({});
    const [stateObject, setstateObject] = useState({});
    const [cityObject, setcityObject] = useState({});
    // const [zipCode, setzipCode] = useState('');
    // const [searchData, setsearchData] = useState([]);
    // const [tableDisplay, setTableDisplay] = useState(false);
    // const [isRecruiter, setIsRecruiter] = useState(false);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [resetTestModal, setresetTestModal] = useState(false);
    const [testIdToDelete, settestIdToDelete] = useState("");
    const [TotalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
    // const [page, setPage] = useState(0);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [order, setorder] = useState(1);
    const [lastTestTakenon, setlastTestTakenon] = useState({name: "Anytime"});
    // const [isSearchDataLoaded, setisSearchDataLoaded] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [dataState1, setDataState1] = useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [],
        },
    });
    const [dataResult1, setDataResult1] = useState([]);
    
    // const [payload, setPayload] = useState([]);
    // const [intervalData, setIntervalData] = useState("");

    const [searchTerms, setSearchTerms] = useState([]);
    const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
    const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    // at the time of datastate change
    const dataState1Change = async (event) => {
        const filters = event?.dataState?.filter?.filters || [];
        // const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
        const terms = filters && filters.length > 0
        ? filters
            .filter(({ value }) => value && value !== '') // Filter out objects where value is empty, null, or white spaces
            .map(({ field, value, operator }) => ({ field, value, operator }))
        : [];
        terms?.forEach((item, i) => {
            if(item?.field === "percentage")
            {
                item.value = parseInt(item?.value);
            }
        });
        setSearchTerms(terms);
        const take = event?.dataState?.take;
        const skip = event?.dataState?.skip;
        const page = Math.floor(skip / take) + 1;
        let searchTerms = terms;
        setTakeNumberOfResult(take);
        setSkipNumberOfResult(skip);
        setPageNumber(page);
        setDataState1(event.dataState)
  
    };

    // callback func for API call
    const debouncedSearch = useCallback(
        debounce(async (time, searchTerms, page, size) => {
        setIsSearching(true);
        setDataResult1([]);
          const search = await searchCandidateAdminAPI(time, searchTerms, page, size);
          if (search.data.length <= 0) {
            setIsSearching(false);
            setDataResult1(search.data);
            setTotalNumberOfRecords(search.count);         
          }
          if (search.data.length > 0) {

            search?.data?.forEach((item, i) => {
                item["locationCity"] = JSON.parse(item.locationCity)?.name || "N/A";
                item["locationState"] = JSON.parse(item.locationState)?.name || "N/A";
                item["locationCountry"] = JSON.parse(item.locationCountry)?.name || "N/A";
            });

            setIsSearching(false);
            setDataResult1(search.data);
            setTotalNumberOfRecords(search.count);
          }
    
        }, 2500),
        []
      );

      const fieldsToField = [
        "firstName",
        "lastName",
        "email",
        "phoneNumber",
        "roleName",
        "isActive"
        
    ];

    useEffect(() => {
        // it will run when there is no filter in kendo
        try
        {
            if (searchTerms.length <= 0 ) {
                let newsearchTerms = [];
                debouncedSearch(lastTestTakenon?.name, newsearchTerms, pageNumber, takeNumberOfResult);
            }
        
            // it will run when there is filter in kendo
            if (searchTerms.length > 0) {
        
                let searchTerm;
                // remove null undefined values of fields
                searchTerm = searchTerms.filter(obj => {
                    if (
                        fieldsToField.includes(obj.field) &&
                        (obj.value === null || obj.value === undefined || obj.value === "")
                    ) {
                        return false; // remove the object
                    }
                    return true; // keep the object
                });
                setDataState1({
                    skip: skipNumberOfResult,
                    take: takeNumberOfResult,
                    filter: {
                        logic: "and",
                        filters: searchTerm
                    },
                    sort: [
                        {
                            field: "",
                            dir: "desc",
                        },
                    ],
                })
                debouncedSearch(lastTestTakenon?.name, searchTerm, pageNumber, takeNumberOfResult);
            }
        }
        catch(err)
        {
            setisDataLoaded(true);
        }
        finally
        {
            setisDataLoaded(true);
        }
    
    }, [searchTerms, pageNumber, takeNumberOfResult]);

    // Dropdown Change
    const TestTakenTimeHandler = async(e, value) => {
        if (value) {
            setlastTestTakenon(value);
            debouncedSearch(value?.name, searchTerms, Math.floor((dataState1?.skip / dataState1?.take) + 1), dataState1?.take);
        }
        else {
            setlastTestTakenon({name : "Anytime"});
            debouncedSearch("Anytime", searchTerms, Math.floor((dataState1?.skip / dataState1?.take) + 1), dataState1?.take);
        }
    }

    const getSkeleton_candidateSearch = () => {
        let arr = []
        for (let i = 0; i < 10; i++) {
            arr.push(<Skeleton key={i} className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="45px" />)
        }
        return arr;
    }

    const handleGotoProfile = async (props) => {
        window.open(`/candidate/profile/${props?.dataItem?.id}`, "_blank")
    }

    const allTestTakenOnArr = [
        {
            name: "Last 24 Hours"
        },
        {
            name: "Last 7 Days"
        },
        {
            name: "Last 30 Days"
        },
        {
            name: "Anytime"
        }
    ]


    // const getActiveSkills = async () => {

    //     try {
    //         const activeSkillResponse = await axios.get(
    //             jsonConfig.apiUrl + "getActiveSkills",
    //             {
    //                 headers: {
    //                     'x-functions-key': jsonConfig.key,
    //                 }
    //             }
    //         )

    //         setActiveSkills(activeSkillResponse.data.data)

    //     }
    //     catch (error) {
    //         // do nothing
    //         console.log(error);
    //     }

    // }

    // const countryChangeHandler = (event, value) => {
    //     if (value) {
    //         setStateList(csc.getStatesOfCountry(value.isoCode));
    //         setcountryObject(value)
    //     }
    //     else {
    //         setcountryObject({})
    //     }
    // }
    // const stateChangeHandler = (event, value) => {
    //     if (value) {
    //         setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode))
    //         setstateObject(value)
    //     }
    //     else {
    //         setstateObject({})
    //     }
    // }

    // const cityChangeHandler = (event, value) => {
    //     if (value) {
    //         setcityObject(value)
    //     }
    //     else {
    //         setcityObject({})
    //     }
    // }
    // const firstnameHandler = (e) => {

    //     setfirstName(e.target.value)
    // }
    // const lastnameHandler = (e) => {
    //     setlastName(e.target.value)
    // }
    // const emailHandler = (e) => {
    //     setemail(e.target.value);
    // }
    // const phoneNoHandler = (e) => {
    //     setphnNo(e.target.value)
    // }
    // const testScoreHandler = (e) => {
    //     settestScore(e.target.value);

    // }
    

    // const primarySkillChangeHandler = (e, value) => {
    //     if (value) {
    //         setskillId(value.id);
    //     }
    //     else {
    //         setskillId("")
    //     }
    // }
    // const btnGreen = {
    //     color: '#fff',
    //     backgroundColor: '#2AD156',
    //     border: 'none',
    //     padding: '7px 23px 7px 23px',
    //     marginBottom: "12px"
    // };
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.name,
    });

    const resetUserTest = async () => {
        try {
           
            await axios.put(
                jsonConfig.apiUrl + "resetCandidateTests/" + testIdToDelete, {},
                {
                    headers: {
                        "x-functions-key": jsonConfig.key,
                    },
                }

            )
            NotificationManager.success("The Candidate tests has been reset", "Success", 2500);
            setresetTestModal(false)
        } catch (error) {
            console.log(error);
            NotificationManager.error("Reset tests failed for the candidate due to an internal error", "Error", 2500);
        }
    }

    // const resetUserTestHandler = (e, id) => {

    //     settestIdToDelete(id)
    //     // resetUserTest(id)
    //     setresetTestModal(true)

    // }

    // const IsJsonString = (str) => {
    //     // alert("ok")
    //     try {
    //         var locationjson = JSON.parse(str);
    //         if (Object.keys(locationjson).length > 0) {
    //             return true;
    //         }
    //         else {
    //             return false;
    //         }
    //     } catch (e) {
    //         return false;
    //     }
    //     // return true;
    // }

    return (
        <>
            <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
            <div className='section-wrapper'>
                <div className="container w1200 flex-to-footer">
                    <div className="height_adjust mt-110">
                        {!isDataLoaded ?
                            <div className="row padding_80 text-center">
                                <CircularProgress />
                            </div>
                            :
                            <>
                                {
                                    userType === "Admin" ? (
                                        <Container component={Paper} className="allignmentFix">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    {/* <DialogTitle className="admindailog_title" id="customized-dialog-title">Search Candidates</DialogTitle> */}
                                                    <h2 className='fs-semi-bold fs-30 f-color pageHead'>Search Candidates</h2>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div>
                                                        <Typography variant="body1" component="h2">
                                                            Last Test Taken On
                                                        </Typography>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            filterOptions={filterOptions}
                                                            options={allTestTakenOnArr}
                                                            getOptionLabel={(option) => option.name || ""}
                                                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Time" />}
                                                            value={lastTestTakenon}
                                                            onChange={TestTakenTimeHandler}
                                                            getOptionSelected={(option, value) => option.name === value.name || true}
                                                        />
                                                    </div>
                                                </Grid>

                                                {/* Table */}
                                                <Grid item xs={12} className={classes.tableGridContainer}>
                                                    {true ? <div className={classes.tableContainerStyle}>
                                                        <GridDiv
                                                            filterable={true}
                                                            filter={dataState1.filter}
                                                            resizable={true}
                                                            reorderable={true}
                                                            pageable={{
                                                                buttonCount: 10,
                                                                info: true,
                                                                previousNext: true,
                                                                pageSizes: [10, 20, 50, 100],
                                                            }}
                                                            skip={dataState1.skip}
                                                            take={dataState1.take}
                                                            onDataStateChange={dataState1Change}
                                                            data={dataResult1 || []}
                                                            total={TotalNumberOfRecords || 0}
                                                            className='kendo_candidateSearch_admin'
                                                        >
                                                            <GridNoRecords>
                                                                {isSearching ? getSkeleton_candidateSearch() : "No results found !"}
                                                            </GridNoRecords>
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                width="100px"
                                                                filterable={false}
                                                                title="Profile"
                                                                cell={(props) => {
                                                                    return (
                                                                        <td align="center">
                                                                            <Button
                                                                                size="small"
                                                                                variant="contained"
                                                                                className={classes.profileButton}
                                                                                onClick={(e) => handleGotoProfile(props)}
                                                                            >
                                                                                Profile
                                                                            </Button>
                                                                        </td>
                                                                    );
                                                                }}
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="firstName"
                                                                title="First Name"
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="lastName"
                                                                title="Last Name"
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="email"
                                                                title="Email"
                                                                width="250px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="phoneNumber"
                                                                title="Phone Number"
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="name"
                                                                title="Skill"
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="locationCity"
                                                                title="City"
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="locationState"
                                                                title="State"
                                                                filterable={true}
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="locationCountry"
                                                                title="Country"
                                                                width="200px"
                                                            />
                                                            <GridColumn
                                                                className={classes.fontRoboto}
                                                                field="percentage"
                                                                title="Test Score %"
                                                                width="150px"
                                                                filter={"numeric"}
                                                            />
                                                        </GridDiv>
                                                    </div> : null}
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    ) : userType === "" ? (
                                        <div className="row padding_80 text-center">
                                            <p className="fs-semi-bold fs-40 f-color">
                                                Loading...
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="row padding_80 text-center">
                                            <p className="fs-semi-bold fs-40 f-color">You Are Not Authorized To Visit This Page</p>
                                        </div>
                                    )
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
            <Dialog
                open={resetTestModal}
                onClose={() => setresetTestModal(false)}
                aria-labelledby="customized-dialog-title"
            >
                <DialogContent className={`${classes.bodyForRemove} removemodal_responsive`}>
                    <div className="text-center fs-semi-bold f-color fs-18">Do you want to reset the tests for the candidate?</div>
                </DialogContent>
                <DialogActions className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}>
                    <div className="remove_modal">
                        <button type="button" className="btn_remove btn-skill cp" onClick={() => setresetTestModal(false)}>No</button>
                        <button type="button" className="btn2 btn-green ml_5 cp" onClick={() => resetUserTest()}>Yes</button>
                    </div>
                </DialogActions>
            </Dialog>
            <NotificationContainer />

        </>

    )
}

export default Search;