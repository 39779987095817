import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllNotDeletedUserTestResponse = async (profileId) => {
    let allTestList=[]
    try {
       const getallskillresponse = await axios.get(
          jsonConfig.apiUrl + "getActiveCompletedUserTest/"+profileId ,
          {
             headers: {
                "x-functions-key": jsonConfig.key,
             },
          }
       );
        allTestList = getallskillresponse.data.data;

    return  Promise.resolve(allTestList) 
    }
    catch (error) {
    //    console.error(error);
    return   Promise.reject(error)
    }
 }

 export default getAllNotDeletedUserTestResponse;