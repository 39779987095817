import axios from "axios";
const jsonConfig = require("../../Config.json");

const recruiterSignUpResponse = async (payloadData) => {
    let allResponse = {};
    try {
        const Response = await axios.post(
            jsonConfig.apiUrl + "recruiterSignup", payloadData,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = Response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr", error);
        return Promise.reject(error);
    }
}

export default recruiterSignUpResponse;