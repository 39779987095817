import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllQuestionDetailsDynamic = async (testIds) => {
  let allResponse = {};
  const payload={questionId:testIds}
  try {
    const url=`${jsonConfig.apiUrl}getallquestiondynamic`
    const getallresponse = await axios.post(
      url,payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
        
        
      },
    );

    allResponse = getallresponse;

    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default getAllQuestionDetailsDynamic;