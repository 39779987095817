import axios from "axios";
const jsonConfig = require("../../Config.json");


const getAllReferralSkiilsByRecruiterPartners = async (id) => {
  try {
    // 
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "referralbyrecruitmentpartner/" +id, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default getAllReferralSkiilsByRecruiterPartners;
