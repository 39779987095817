import axios from "axios";
const jsonConfig = require("../../Config.json");

const createB2cMapping = async (b2cMappingPayload) => {
    // alert("ok")
    let allResponse = {}
    try {
        const getallresponse =await axios.post(
            jsonConfig.apiUrl + "b2cmapping",
            b2cMappingPayload,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
        allResponse = getallresponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error)
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default createB2cMapping;