import React, { useState } from 'react';
import "./privacyPolicy.css";
import CheckMark from './images/check.svg';
import Header from '../../pages/home/homeHeader/Header/Header';
import AdminHeader from "../../components/adminHeader/adminHeader";
const PrivacyPolicy = () => {
  const [loggedUser, setLoggedUser] = useState(localStorage.getItem("userID"))
  return (
    <div>
      {loggedUser === null ? <Header /> : <AdminHeader uid={loggedUser} isDataLoaded={true} />}
      <div className="container terms-of-use-container w1200">
        <div className="card text-center question-card-container">
          <div className="fs-bold fs-35 f-color termsofuse">Privacy Policy</div>
          <div className="f-color fs-20 fs-regular  mt_22 text-left">Last updated: 2021-06-17</div>

          <div className="f-color fs-20 fs-regular mt_22 text-left">
            <div className="pr_5 mb_10 fs-22 fs-semi-bold">1. Overview</div>
            <div>
              <div className="mb_20 line-height-22">
                <i className='italic-blue fs-18 fs-bold'>
                  ClearedTalent (www.clearedtalent.com) is an online talent community platform. We enable candidates to build credibility by taking a test relevant to their skill area. ClearedTalent customers (recruiters and employers) can view the candidates’ test and their ClearedTalent profile (PoweResume). Candidates can also share the test and PoweResume directly with future employers.
                </i>
              </div>

              <div className="mb_20 line-height-22">
                <i className='italic-blue fs-18 fs-bold'>
                  ClearedTalent strengthens candidate engagement with our customers. ClearedTalent’s workflow will improve candidate experience and will make hiring process a smooth and transparent experience for candidates and employers.
                </i>
              </div>
              <div className="mb_20 line-height-22">
                <i className='italic-blue fs-18 fs-bold'>
                  Candidates and our customers are the primary users of ClearedTalent.
                </i>
              </div>
            </div>
          </div>

          <div className="f-color fs-22 fs-semi-bold mt_22 text-left">1. Data We Collect</div>

          <div className="f-color fs-20 fs-regular mt_22 text-left">
            <div className="pr_5 mb_20 fs-20 fs-bold italic-blue">Candidate Data (During Registration)</div>
            <div>
              <div className="mb_20">
                To register a candidate will voluntarily provide data including name, email address, location (country, state and optionally city) and mobile number (optional). A candidate will typically take a test designed by ClearedTalent as a part of the registration. If the test is video recorded, the candidate will be informed up front as a part of information shared prior to taking the test. The data collected during registration will be part of the candidate’s profile in ClearedTalent. ClearedTalent’s candidate profile is named PoweResume.
              </div>
            </div>

            <div className="pr_5 mb_20 fs-20 fs-bold italic-blue">Candidate Data (During Updating Candidate Profile – PoweResume)</div>
            <div>
              <div className="mb_20">
                Candidates don’t have to provide additional information on their profile; however, profile information helps candidates to get more from ClearedTalent, including helping recruiters and employers find and select them. It’s the candidates’ choice whether to add more information on their profiles and to make sensitive information, if any, available to ClearedTalent. We request candidates not to add personal data to their profile that they would not want recruiters and employers to see.
              </div>
            </div>

            <div className="pr_5 mb_20 fs-20 fs-bold italic-blue">Candidate Data (During engagement in recruitment process or in a job)</div>
            <div>
              <div className="mb_20">
                ClearedTalent may seek reviews from its customers on a candidates’ performance during the interview process or in a job. We may collect data on whether candidates got selected for jobs they applied for using ClearedTalent.
              </div>
            </div>

            <div className="pr_5 mb_20 fs-20 fs-bold italic-blue">Customer Data</div>
            <div>
              <div className="mb_20">
                ClearedTalent may request clients to provide their names, email addresses and phone numbers so communication can be made to customers when candidates take tests in ClearedTalent or when candidates’ profiles match a customer’s requirements.
              </div>

              <div className="mb_20">
                Customers may share their job descriptions with ClearedTalent or its partners so the vetting process and the matching algorithms can be configured to work better for customers.
              </div>

              <div className="mb_5">
                ClearedTalent may request candidates to review the hiring experience with a customer.
              </div>
            </div>
          </div>

          <div className="f-color fs-22 fs-semi-bold mt_22 text-left">2. How We Use Data</div>

          <div className="f-color fs-20 fs-regular mt_22 text-left">
            <div className="pr_5 mb_20 fs-20 fs-bold italic-blue">Candidate Data </div>
            <div className='mb_20'>
              <div className="mb_20">
                ClearedTalent allows candidates to build credibility by getting vetted on its platform and build their profile on ClearedTalent (PoweResume). We use the data we have about candidates (e.g., data you provide, data we collect from candidates’ engagement with ClearedTalent services and inferences we make from the data we have about you) to recommend jobs to candidates and candidates to our customers. We may also use the information collected to recommend training or upskilling to the candidates. Candidates can share the test and PoweResume directly with future employers.
              </div>

              <div className="mb_20">
                Candidates can signal they are interested in changing jobs. ClearedTalent may provide the candidates options to hide certain information. Candidates can request their profiles be deactivated in ClearedTalent by writing to us at <a className="fs-semi-bold cp italic-blue" href="mailto:support@clearedtalent.com" target="blank">support@clearedtalent.com.</a>
              </div>
            </div>

            <div className="pr_5 mb_20 fs-20 fs-bold italic-blue">Customer Data</div>
            <div>
              <div className="mb_20">
                ClearedTalent will use the customers’ contacts to communicate with them. At the customers’ request, customers’ job descriptions may be used to make ClearedTalent’s vetting more appropriate to the customers’ job requirements.
              </div>

              <div className="mb_20">
                If feedback on customer is collected from candidates, such information may be shared with the customer. No customer information (except open job requirements which may be shared with candidates) will be shared with anyone else but the customer’s designated contacts.
              </div>
            </div>
          </div>

          <div className="f-color fs-22 fs-semi-bold mt_22 text-left">3. Your Access to & Control over Information</div>

          <div className="f-color fs-20 fs-regular mt_22 text-left">
            <div className='privacy-policy-last-section'>
              <img src={CheckMark} alt='checkmark' className='mr_18' />
              <div>Change/correct any data we have about you</div>
            </div>
            <div className='privacy-policy-last-section'>
              <img src={CheckMark} alt='checkmark' className='mr_18' />
              <div>Have us deactivate your profile</div>
            </div>
            <div className='privacy-policy-last-section'>
              <img src={CheckMark} alt='checkmark' className='mr_18' />
              <div>Express any concern you have about our use of your data</div>
            </div>
            <div className='privacy-policy-last-section'>
              <img src={CheckMark} alt='checkmark' className='mr_18' />
              <div>Hide certain information as allowed by ClearedTalent</div>
            </div>
            <div className='privacy-policy-last-section'>
              <img src={CheckMark} alt='checkmark' className='mr_18' />
              <div>You can communicate with us by writing to us at <a className="fs-semi-bold cp italic-blue" href="mailto:support@clearedtalent.com" target="blank">support@clearedtalent.com.</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
export default PrivacyPolicy