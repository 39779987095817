import axios from "axios";
const jsonConfig = require("../../Config.json");

const userTestFeedback = async (testId, reviewSubmitPayLoad) => {
  let allResponse = {}
  try {
    const getallresponse = await axios.put(
      jsonConfig.apiUrl + "updateTestFeedback/" + testId,
      reviewSubmitPayLoad,
      {
        headers: {
          "x-functions-key": jsonConfig.key
        }
      }
    );

    allResponse = getallresponse;

    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default userTestFeedback;