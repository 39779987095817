import React, { useState, useEffect } from "react";
import "./searchRequisitions.css";
import Header from "../../components/headerAdmin/Header";

import { Button, Grid, TextField, Paper, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { Autocomplete } from "@material-ui/lab";
import Footer from "../../components/adminFooter/Footer";
import AddRequi from "../../components/basicInfoForm/images/add requisition.png"
import "react-datepicker/dist/react-datepicker.css";
import getAllRequisitionStatus from "../../apis/getallRequisitionStatus/getAllRequisitionStatus";
import RequisitionListCard from "../../components/RequisitionsList/RequisitionListCard";
import getAllRecruiterLeader from "../../apis/getAllRecruiterLeader/getAllRecruiterLeader";
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";


const SearchRequisitions = (props) => {
    const history = useHistory();
    const userType = props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType;
    const userRecruitmentpartnerId = props?.location?.state?.userRecruitmentpartnerId ? props?.location?.state?.userRecruitmentpartnerId : props?.userRecruitmentpartnerId;
    const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : props?.id;
    const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType;
    const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords;

    const [requisitionStatus, setRequisitionStatus] = useState([]);
    const [recruitmentLeaders, setRecruitmentLeaders] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [allClient, setAllClient] = useState([]);
    const [subStatus, setSubStatus] = useState("");
    const [currentStatusCode, setCurrentStatusCode] = useState("");
    const [keywordOptions, setKeywordOptions] = useState([])
    const [keywordCurrentValue, setKeywordCurrentValue] = useState("")
    const [searchComplete, setSearchComplete] = useState(true)
    const jsonConfig = require("../../Config.json");

    const [searchpayload, setSearchPayload] = useState({
        keywords: [],
        assignedTo: "",
        priority: "",
        createdOn_from: "",
        createdOn_to: "",
        clientId: "",
        updatedOn_from: "",
        updatedOn_to: "",
        status: "",
        subStatus: ""
    })


    const keywordValuechangeHandler = (e, value) => {
        setSearchPayload(prev => ({
            ...prev,
            keywords: value
        }))
        setKeywordOptions([])
    }

    const keywordTextFieldChange = (e) => {
        setKeywordCurrentValue(e.target.value)
        let arr = [];
        arr.push(e.target.value)
        setKeywordOptions(arr)
    }

    const filterChange = (newValue, val) => {
        if (val) {
            if (newValue === "status") {
                setCurrentStatusCode(val?.statusCode);
                setSearchPayload(prev => ({
                    ...prev,
                    [newValue]: val._id // Toggle the value of the clicked filter
                }));
            }
            else if (newValue === "subStatus") {
                let substatusCode = val === "COVERED" ? jsonConfig.requisitionStatusCodes.OPENSUBSTATUS.COVERED : val === "UNCOVERED" ? jsonConfig.requisitionStatusCodes.OPENSUBSTATUS.UNCOVERED : jsonConfig.requisitionStatusCodes.OPENSUBSTATUS.UNASSIGNED;
                setSubStatus(val);
                setSearchPayload(prev => ({
                    ...prev,
                    [newValue]: substatusCode // Toggle the value of the clicked filter
                }));
            }
            else {
                setSearchPayload(prev => ({
                    ...prev,
                    [newValue]: val // Toggle the value of the clicked filter
                }));
            }
        }
        else {
            if (newValue === "status") {
                setCurrentStatusCode("");
            }
            else if (newValue === "subStatus") {
                setSubStatus("");
            }
            setSearchPayload(prev => ({
                ...prev,
                [newValue]: "" // Toggle the value of the clicked filter
            }))
        }
    };

    const getReqStatus = async () => {
        try {
            const res = await getAllRequisitionStatus();
            setRequisitionStatus(res.data.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        let isMounted = true;
        const getAllClientData = async () => {
            let payload = {
                recruitmentPartnerId: userRecruitmentpartnerId
            }
            let res = await getClientByOrg(payload);
            if (isMounted) {
                setAllClient(res.data.data);
            }
        }

        const getAllrecruitmentLeadersData = async () => {
            const res = await getAllRecruiterLeader({ recruitmentPartnerId: userRecruitmentpartnerId });
            if (isMounted) {
                setRecruitmentLeaders(res.data.data);
            }
        }

        getReqStatus();
        getAllrecruitmentLeadersData()
        getAllClientData()

        return () => {
            isMounted = false;
        };

    }, [userRecruitmentpartnerId]);

    const goToDahboard = () => {
        if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
            history.push({
                pathname: "/requisition-dashboard",
                state: { id: idContextTest, recruitementPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
            });
        } else {
            history.push({
                pathname: "/dashboard",
                state: {
                    id: idContextTest, roleName: userRole, recruitmentPartnerId: userRecruitmentpartnerId, userRecords: userRecords
                }
            });
        }
    }

    const goToCreateReq = () => {
        history.push("/create-requisition");
    }

    return (
        <>
            <div className='talentCommunityMainContainer sea_top_contain' >
                <div className='talentCommunityTopContainer'>
                    <Grid container>
                        <Grid item xs={12}>
                            <Header />
                        </Grid>
                    </Grid>
                    <div className="mainDiv_advanceSearch" >
                        <div >
                            <div className="sea_top_contain">
                                <div className="titlesofCommunity">
                                    Requisitions
                                </div>
                                {userType === "MIS Operator" ?
                                    <div className="addReq_button" >
                                        <div>
                                            <button className="seareq_button" onClick={(e) => goToCreateReq()}>
                                                <img src={AddRequi} height={18} width={18} className="seareq_image" alt="AddRequisition_Icon" />
                                                &nbsp;
                                                Add Requisitions</button>
                                        </div>
                                    </div>
                                    : null}
                            </div>



                        </div>

                        <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDahboard()}>
                            <ArrowBackIcon />  &nbsp;{ userType === "MIS Operator"?"Go Back to Requisition Dashboard" :"Go Back to Dashboard"}
                        </div>
                        <div className="searec_main_con">

                            <div className="searec_leftcont_requisitionSearch">
                                <div className="searchrec_subheader ">
                                    <span className='showingRF'>Search Requisitions</span>
                                    <div >
                                        {
                                            searchComplete ? (<Button
                                                variant="contained"
                                                color="primary"
                                                className="searchButton"
                                                onClick={(e) => {
                                                    setIsSearching(!isSearching)
                                                    setSearchComplete(false)
                                                }}
                                                disabled={!searchComplete}
                                            >
                                                Search
                                            </Button>) : (
                                                <Button
                                                    style={{
                                                        border: '1px solid #1a8fff6b',
                                                        backgroundColor: '#1a8fff6b',
                                                        opacity: 0.8,
                                                        color: '#fff',
                                                    }}
                                                    // onClick={revokeRecruiterHandler}
                                                    disabled={!searchComplete}
                                                >
                                                    <div className="btn_loader">
                                                        Searching
                                                        &nbsp;
                                                        {!searchComplete && (
                                                            <div>
                                                                <CircularProgress size={14} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </Button>
                                            )
                                        }
                                    </div>


                                </div>

                                <div className="searchreq_leftmaincon keywordSearchContainer inputItemsContainer " >
                                    <span className='filterItemTitle'>Search Keywords</span>
                                    <div >

                                        <Autocomplete
                                            id="tags-filled"
                                            freeSolo
                                            multiple
                                            filterSelectedOptions
                                            className='searchreq_autocom mt_10'
                                            options={keywordOptions}
                                            value={searchpayload.keywords}
                                            onChange={keywordValuechangeHandler}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    variant='outlined'
                                                    value={keywordCurrentValue}
                                                    onChange={(e) => keywordTextFieldChange(e)}
                                                    placeholder="Enter keywords" />
                                            )}
                                        />
                                        <div className='divider_input_searchreq searchreq_autocom'></div>

                                    </div>
                                    {userType === "MIS Operator" || userType === "Organization Admin" ?
                                        <div className="skillContainer_advanceSearch">
                                            <span className='filterItemTitle'>Assigned To</span>
                                            <Autocomplete
                                                id="free-solo-2-demo"
                                                options={recruitmentLeaders}
                                                className='searchreq_autocom mt_10'
                                                filterSelectedOptions
                                                getOptionLabel={(option) => option.email || ""}
                                                onChange={(e, val) => filterChange("assignedTo", val?.id || "")}
                                                PaperComponent={({ children }) => (
                                                    <Paper
                                                        style={{ fontSize: "14px", fontFamily: 'Segoe UI' }}
                                                    >
                                                        {children}
                                                    </Paper>
                                                )}
                                                value={recruitmentLeaders.filter((option) => option.id === searchpayload.assignedTo)[0] || ""}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        placeholder='Select Recruitment Leader'
                                                        variant="outlined"
                                                    />}
                                            />
                                            <div className='divider_input_searchreq searchreq_autocom'></div>

                                        </div>
                                        :
                                        null}
                                    <div className="skillContainer_advanceSearch">
                                        <span className='filterItemTitle'>Priority</span>
                                        <Autocomplete
                                            id="free-solo-2-demo"
                                            options={["High", "Low"]}
                                            className='searchreq_autocom mt_10'
                                            filterSelectedOptions
                                            value={searchpayload.priority}
                                            getOptionLabel={(option) => option || ""}
                                            onChange={(e, val) => filterChange("priority", val || "")}
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{ fontSize: "14px", fontFamily: 'Segoe UI' }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    placeholder='Select Priority'
                                                    variant="outlined"
                                                />}
                                        />
                                        <div className='divider_input_searchreq searchreq_autocom'></div>
                                    </div>

                                    {currentStatusCode !== jsonConfig.requisitionStatusCodes.OPEN ?
                                        <div className="skillContainer_advanceSearch">
                                            <span className='filterItemTitle'>Status</span>
                                            <Autocomplete
                                                id="free-solo-2-demo"
                                                options={requisitionStatus}
                                                className='searchreq_autocom mt_10'
                                                filterSelectedOptions
                                                value={requisitionStatus.filter((option) => option._id === searchpayload.status)[0] || ""}
                                                getOptionLabel={(option) => option.name || ""}
                                                onChange={(e, val) => filterChange("status", val || "")}
                                                PaperComponent={({ children }) => (
                                                    <Paper
                                                        style={{ fontSize: "14px", fontFamily: 'Segoe UI' }}
                                                    >
                                                        {children}
                                                    </Paper>
                                                )}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        placeholder='Requisition Status'
                                                        variant="outlined"
                                                    />}
                                            />
                                            <div className='divider_input_searchreq searchreq_autocom'></div>
                                        </div>
                                        :
                                        <div className='searec_employementContainer'>
                                            <div className='searec_employmentItems'>
                                                <span className='filterItemTitle'>Status</span>
                                                <Autocomplete
                                                    id="free-solo-2-demo"
                                                    options={requisitionStatus}
                                                    className='employementAutocomplete mt_10'
                                                    value={requisitionStatus.filter((option) => option._id === searchpayload.status)[0] || ""}
                                                    onChange={(e, val) => filterChange("status", val || "")}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    PaperComponent={({ children }) => (
                                                        <Paper
                                                            style={{ fontSize: "13px", fontFamily: 'Segoe UI', textWrap: "wrap" }}
                                                        >
                                                            {children}
                                                        </Paper>
                                                    )}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            placeholder='Requisition Status'
                                                            variant="outlined"
                                                        />}
                                                />
                                            </div>
                                            <div className='searec_employmentItems'>
                                                <span className='filterItemTitle'>Sub Status</span>
                                                <Autocomplete
                                                    id="free-solo-2-demo"
                                                    options={(userType === 'Recruiter' || (userType === 'Admin' && userRole === "Recruiter")) ? ['COVERED', 'UNCOVERED'] : ['COVERED', 'UNCOVERED', 'UNASSIGNED']}
                                                    className='employementAutocomplete mt_10'
                                                    value={subStatus}
                                                    onChange={(e, val) => filterChange("subStatus", val || "")}
                                                    getOptionLabel={(option) => option || ""}
                                                    PaperComponent={({ children }) => (
                                                        <Paper
                                                            style={{ fontSize: "13px", fontFamily: 'Segoe UI', textWrap: "wrap" }}
                                                        >
                                                            {children}
                                                        </Paper>
                                                    )}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            placeholder='Select Substatus'
                                                            variant="outlined"
                                                        />}
                                                />
                                            </div>
                                        </div>}

                                    <div className="skillContainer_advanceSearch">
                                        <span className='filterItemTitle'>Client Name</span>
                                        <Autocomplete
                                            id="free-solo-2-demo"
                                            options={allClient}
                                            className='searchreq_autocom mt_10'
                                            filterSelectedOptions
                                            value={allClient.filter((option) => option._id === searchpayload.clientId)[0] || ""}
                                            getOptionLabel={(option) => option.clientName || ""}
                                            onChange={(e, val) => filterChange("clientId", val?._id || "")}
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{ fontSize: "14px", fontFamily: 'Segoe UI' }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    placeholder='Select Client'
                                                    variant="outlined"
                                                />}
                                        />
                                        <div className='divider_input_searchreq searchreq_autocom'></div>
                                    </div>

                                    <div className="skillContainer_advanceSearch datePicker_SearchReequisition">


                                        <span className='filterItemTitle'>Created On</span>
                                        <div className="mt_10"></div>
                                        <div className="seareq-date-picker">
                                            <div>
                                                <DatePicker
                                                    className="seareq_date_border"
                                                    showIcon
                                                    selected={searchpayload.createdOn_from ? new Date(searchpayload.createdOn_from) : ""}
                                                    onChange={(date) => filterChange("createdOn_from", date)}
                                                    placeholderText="Select Date"
                                                />
                                            </div>
                                            <div className='filterItemTitle seareq_datePicker'>To</div>
                                            <div>
                                                <DatePicker
                                                    className="seareq_date_border"
                                                    showIcon
                                                    selected={searchpayload.createdOn_to ? new Date(searchpayload.createdOn_to) : ""}
                                                    onChange={(date) => filterChange("createdOn_to", date)}
                                                    placeholderText="Select Date"
                                                />
                                            </div>
                                        </div>
                                        <div className='divider_input_searchreq searchreq_autocom'></div>
                                    </div>



                                    <div className="skillContainer_advanceSearch">

                                        <span className='filterItemTitle'>Updated On</span>
                                        <div className="mt_10"></div>
                                        <div className="seareq-date-picker ">
                                            <div>
                                                <DatePicker
                                                    className="seareq_date_border"
                                                    showIcon
                                                    selected={searchpayload.updatedOn_from ? new Date(searchpayload.updatedOn_from) : ""}
                                                    onChange={(date) => filterChange("updatedOn_from", date)}
                                                    placeholderText="Select Date"
                                                />
                                            </div>
                                            <div className='filterItemTitle seareq_datePicker' >To</div>
                                            <div>
                                                <DatePicker
                                                    className="seareq_date_border"
                                                    showIcon
                                                    selected={searchpayload.updatedOn_to ? new Date(searchpayload.updatedOn_to) : ""}
                                                    onChange={(date) => filterChange("updatedOn_to", date)}
                                                    placeholderText="Select Date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RequisitionsList /> */}
                            <RequisitionListCard
                                searchpayload={searchpayload}
                                isSearching={isSearching}
                                idContextTest={idContextTest}
                                userType={userType}
                                userRecruitmentpartnerId={userRecruitmentpartnerId}
                                requisitionStatus={requisitionStatus}
                                isSearchRequisition={true}
                                userRole={userRole}
                                setSearchComplete={setSearchComplete}
                            />

                            {/* </card> */}
                        </div>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default SearchRequisitions;