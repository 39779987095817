import React, { useState, useCallback, useEffect } from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid as GridDiv,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import debounce from "lodash/debounce";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import jobReports from "../../apis/requisitionReports/jobReport";
// import { UserTypeContext } from "../../AppRoute";
import { DateTime } from "luxon";
import "./requisitionReports.css";
import * as XLSX from "xlsx";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  odd: {
    background: "#F6F6F6 !important",
  },
  even: {
    background: "#fffff !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  waitingText: {
    marginLeft: "5px",
  },
}));
const JobReport = (props) => {
  const {
    userType,
    userIdType,
    userRecruitmentpartnerId,
    userRecords,
    dateRange,
    triggerAction,
    resetTrigger,
    startSelectedDate,
    endSelectedDate,
    additionalFilterData,
    setIsfilterSearch,
    isfilterSearch
  } = props;

  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles();
  const [isExporting, setIsExporting] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const columns = [
    { field: "placementType", title: "Placement Type", width: "180px" },
    { field: "primarySkill", title: "Primary Skill", width: "180px" },
    { field: "clientDetails.clientName", title: "Client Name", width: "180px" },
    { field: "reqreferenceId", title: "Reference ID", width: "180px" },
    { field: "statusDetails.name", title: "Requisition Status", width: "180px" },
    { field: "country", title: "Country", width: "180px" },
    { field: "state", title: "State", width: "180px" },
    { field: "city", title: "City", width: "180px" },
    { field: "priority", title: "Priority", width: "180px" },
    { field: "recruitmentLeaderData.displayName", title: "Recruitment Leader", width: "180px" },
    { field: "teamLeaderNames", title: "Team Leader", width: "180px" },
    { field: "recruiterNames", title: "Primary Recruiter", width: "180px" },
    {
      field: "created_at",
      title: "Created On",
      width: "180px",
      format: "{0:d},{0:t}",
      cell: (e) => {
        return (
          <td>
            {DateTime.fromISO(e.dataItem.created_at)
              .toFormat("MM/dd/yyyy")
              .toString()}
          </td>
        );
      },
    },
  ];

  const [dataResult, setDataResult] = useState([]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    setIsSearching(true);
    setDataResult([]);
    const terms =
      filters && filters.length > 0
        ? filters
          .filter(({ value }) => value && value !== "") // Filter out objects where value is empty, null, or white spaces
          .map(({ field, value, operator }) => ({ field, value, operator }))
        : [];

    setSearchTerms(terms);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setPageNumber(page);
    setDataState(event.dataState);
    setIsSearching(true);
  };




  // useEffect(() => {
  //   if (filterSearched) {
  //    setIsSearching(true)
  //   if(filteredResponse?.data?.data){
  //     setTimeout(()=>{
  //       setDataResult(filteredResponse.data.data);
  //      },3000)
  //       setTotalResult(filteredResponse.data.totalCount[0].total);
  //       setFilteredSearched(false)
  //       setIsSearching(false)
  //   }
  //   }
  // }, [filterSearched, filteredResponse])

  // console.log({filterSearched})



  useEffect(() => {
    if (triggerAction) {
      callApiToGenerateExcel(); // Perform the action
      resetTrigger(); // Reset the trigger to avoid repeated execution
    }
  }, [triggerAction, resetTrigger]);

  useEffect(() => {
    let setDefaultValue = {
      skip: 0,
      take: 10,
      filter: {
        logic: "and",
        filters: [],
      },
    };
    setDataState(setDefaultValue);
  }, []);
 
  function formatDateOnly(dateString) {
    const date = new Date(dateString);
    
  
    // Extract the year, month, and day in the local timezone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    const test = `${year}-${month}-${day}`;
    
  
    return test;
  }

  const debouncedSearch = useCallback(
    debounce(
      async (
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userId,
        additionalFilterData
      ) => {
        let payload = {
          userRecruitmentpartnerId: userRecruitmentpartnerId,
          page: pageNumber,
          size: takeNumberOfResult,
          formattedDatestart: formatDateOnly(startSelectedDate),
          formattedDateend: formatDateOnly(endSelectedDate),
          isExport: false,
          userId: userId,
          role: userType,
          additionalFilter: {
            placementType: additionalFilterData.placementType,
            clientName: additionalFilterData.clientName,
            divisionId: additionalFilterData.divisionId,
            requisitionStatus: additionalFilterData.requisitionStatus
          }

        };

        const search = await jobReports(payload);

        if (search?.data?.length <= 0) {
          setDataResult(search.data[0]);
          setTotalResult(0);
          setIsSearching(false);
        }
        if (search?.data?.length > 0) {
          setDataResult(search.data);
          setIsfilterSearch(false)
          if (search.totalCount[0].total)
            setTotalResult(search.totalCount[0].total);
          setIsSearching(false);

        }

      },
      1000
    ),
    []
  );





  useEffect(() => {
    if (!startSelectedDate || !endSelectedDate) {
      NotificationManager.error("Please enter a valid date", "Error", 2500);
    } else {
      setIsSearching(true);
      setDataResult([]);



      debouncedSearch(
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userIdType,
        additionalFilterData
      );
      setIsfilterSearch(false)
    }
  }, [
    searchTerms,
    pageNumber,
    takeNumberOfResult,
    startSelectedDate,
    endSelectedDate,
    isfilterSearch
  ]);

  const callApiToGenerateExcel = async () => {
    setIsExporting(true);


    let maxSize = 100;
    let end = Math.ceil(totalResult / maxSize);
    let pageStart = 1;
    let exportArr = [];
    for (let start = 0; start < end; start++) {
      let payload = {
        userRecruitmentpartnerId: userRecruitmentpartnerId,
        isExport: true,
        page: pageStart,
        size: maxSize,
        formattedDatestart: formatDateOnly(startSelectedDate),
        formattedDateend: formatDateOnly(endSelectedDate),
        userId: userIdType,
        role: userType,
        additionalFilter: {
          placementType: additionalFilterData.placementType,
          clientName: additionalFilterData.clientName,
          divisionId: additionalFilterData.divisionId,
          requisitionStatus: additionalFilterData.requisitionStatus
        }
      };


      const allDetails = await jobReports(payload);
      allDetails.data.forEach((element) => {
        let data = {
          "Placement Type": element.placementType,
          "Primary Skill": element.primarySkill,
          "Client Name": element.clientDetails.clientName,
          "Reference ID": element.reqreferenceId,
          "Requisition Status": element.statusDetails.name,
          "Country": element.countryStr,
          "State": element.stateStr,
          "City": element.cityStr,
          Priority: element.priority,
          "Recruitment Leader": element.recruitmentLeaderData.displayName,
          "Primary Recruiter": element.recruiterNames,
          "Team Leader": element.teamLeaderNames,
          "Created On": DateTime.fromISO(element.created_at)
            .toFormat("MM/dd/yyyy")
            .toString(),
        };
        exportArr.push(data);
      });
      pageStart = pageStart + maxSize;
    }
    if (exportArr.length === 0) {
      NotificationManager.error("No data present", "Error", 2500);
      setIsExporting(false);
    } else {
      const MAX_ROWS_PER_SHEET = 500;

      const wb = XLSX.utils.book_new();

      // Calculate the number of sheets needed
      const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

      // Loop to create sheets
      for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
        // Get a subset of data for each sheet
        const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
        const endRow = Math.min(
          (sheetIndex + 1) * MAX_ROWS_PER_SHEET,
          exportArr.length
        );
        const subsetData = exportArr.slice(startRow, endRow);

        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(subsetData);

        // Append the sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
      }

      // Save the workbook to an Excel file
      XLSX.writeFile(wb, `JobReport.xlsx`, { bookType: "xlsx", type: "file" });
    }
    setIsExporting(false);

    // Call the API to generate the Excel file
  };

  const jobReportSkeleton = () => {
    let arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push(
        <Skeleton
          key={i}
          className={i % 2 === 0 ? classes.odd : classes.even}
          variant="rect"
          width="100%"
          height="50px"
        />
      );
    }
    return arr;
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={isExporting}>
        <CircularProgress color="inherit" />
        <Typography className={classes.waitingText}>
          {" "}
          Please wait ...
        </Typography>
      </Backdrop>
      <GridDiv
        filterable={false}
        filter={dataState.filter}
        ortable={true}
        resizable={true}
        reorderable={true}
        pageable={{
          pageSizes: [10, 20, 50, 100],
          info: true,
          previousNext: true,
          buttonCount: 10,
        }}
        skip={dataState.skip}
        take={dataState.take}
        // filterOperators={filterOperators}
        onDataStateChange={dataStateChange}
        data={dataResult}
        total={totalResult}
        className="kendo_reports"
      >
        <GridNoRecords>
          {isSearching ? jobReportSkeleton() : "No results found !"}
        </GridNoRecords>

        {columns.map((column) => (
          <GridColumn
            key={column.field}
            field={column.field}
            title={column.title}
            width={column.width}
            filterable={false}
            {...(column.cell && { cell: column.cell })}
            {...(column.format && { format: column.format })}
          />
        ))}
      </GridDiv>
    </>
  );
};

export default JobReport;
