import axios from "axios";
const jsonConfig = require("../../Config.json");

const addReferralProgram = async (data) => {
    try {
      const getAllResponse = await axios.put(
        jsonConfig.apiUrl + "referralpartnerskillsbyreferralpartnerid/" + data.referralpartnerId,
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
      return Promise.resolve(getAllResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  export default addReferralProgram;