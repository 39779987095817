import axios from "axios";
const jsonConfig = require("../../Config.json");

const EditTest = async (id,payload) => {
  
  try {
    const getAllResponse = await axios.put(
      jsonConfig.apiUrl + "Tests/" + id,
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default EditTest;
