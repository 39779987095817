import React, { useState } from 'react'
// import Header from '../../components/header/header';
import "./termsAndCondition.css";
import { useHistory } from "react-router";
import Header from '../../pages/home/homeHeader/Header/Header';
import AdminHeader from "../../components/adminHeader/adminHeader";
export const TermsAndCondition = () => {
    const history = useHistory();
    const [loggedUser, setLoggedUser] = useState(localStorage.getItem("userID"))
    // const goToHome=()=>{
    //     history.push('https://www.clearedtalent.com')
    // }
    const goToPrivacyPolicy = () => {
        window.open('/privacy-policy', '_self');
    }
    return (
        <div>
            {loggedUser === null ? <Header /> : <AdminHeader uid={loggedUser} isDataLoaded={true} />}
            <div className="container terms-of-use-container w1200">
                <div className="card text-center question-card-container">
                    <div className="fs-bold fs-35 f-color termsofuse">TERMS OF USE</div>
                    <div className="f-color fs-20 fs-regular  mt_22 text-left">Last updated: 2021-06-01</div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">1. Introduction</div>
                        <div>
                            <div className="mb_5"> Welcome to <span className="fs-semi-bold">ClearedTalent</span>  (“Company”, “we”, “our”, “us”)!</div>
                            <div className="mb_5">These Terms of Service (“Terms”, “Terms of Service”)
                                govern your use of our website located at <a className="fs-semi-bold cp goToclrTalent" href="https://www.clearedtalent.com" target="blank">www.clearedtalent.com </a>  (“Service”, “Services”) operated by <span className="fs-semi-bold">ClearedTalent.</span></div>
                            <div className="mb_5"> Your agreement with us includes these Terms. You acknowledge that you have read and understood Terms, and agree to be bound of them.</div>
                            <div className="mb_5"> If you do not agree with (or cannot comply with) Terms, then you may not use the Service,but please let us know by emailing at <span className="fs-semi-bold">support@clearedtalent.com </span> so we can try to find a
                                solution. These Terms apply to all visitors, users and others who wish to access or use
                                Service.</div>

                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">2. Communications</div>
                        <div>
                            By using our Service, you agree to receive notifications, subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by emailing us at <a className="fs-semi-bold cp goToclrTalent" href="mailto:support@clearedtalent.com" target="blank">support@clearedtalent.com.</a>
                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5  mb_10 fs-22 fs-semi-bold">3. Intellectual Property</div>
                        <div>
                            <div className="mb_5">
                                Service and its content (excluding personal information provided by users), features and functionality are and will remain the exclusive property of ClearedTalent and its licensors. You own any personal information you provide to us, but you also grant us a non-exclusive license to it.
                            </div>

                            <div className="mb_5">
                                ClearedTalent reserves all of its intellectual property rights in the Services. ClearedTalent logos and other ClearedTalent trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks of ClearedTalent.
                            </div>
                        </div>
                    </div>

                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5  mb_10 fs-22 fs-semi-bold">4. Content</div>
                        <div>
                            <div className='mb_5'>
                                Content found on or through this Service are used with permission. Sharing of content (including without limitation, skill test or candidate related information) must be done as per established ClearedTalent processes. Content includes but is not limited to question repository and candidate related information. You may not distribute, modify, transmit, reuse, download, store, repost, copy, or use said Content, whether in whole or in part, for commercial or other purposes, without express advance written permission from us.
                            </div>
                            <div className='mb_5'>
                                You and ClearedTalent agree that we may access, store, process and use any information and personal data that you provide in accordance with the terms of the <a className="fs-semi-bold cp goToclrTalent" onClick={goToPrivacyPolicy}>Privacy Policy.</a>
                            </div>
                        </div>
                    </div>

                    {/* <div className="f-color fs-20 fs-regular mt_22 ">
                        <div className="pr_5  mb_10 fs-22 fs-semi-bold">4. Additional Service Terms for Customers</div>
                        <div>
                        <div className="mb_5">
                        Customer will use ClearedTalent (and related services) and information about candidates and
                        content (including without limitation, skill test or PoweResume) only to recruit individuals to
                        become employees and consultants of Customer or its Affiliates, or, if Customer is acting as a
                        Staffing Agency or BPO, only to recruit individuals to become employees and consultants of
                        its clients. "Staffing Agency" means a Customer that uses the Services to recruit on behalf of
                        a third-party client and may use the client's name and/or logo. "BPO" means a business
                        process outsourcer Customer that recruits on behalf of a client and may use the client's name
                        and/or logo.
                        </div>
                        <div className="mb_5">
                        Customer will share ClearedTalent content (including without limitation, skill test, candidate
                        information or PoweResume) only within the Customer’s organization. Without express
                        advance written permission from us, Customer can only share ClearedTalent content with its
                        client if the client has signed a subscription agreement with ClearedTalent.
                        </div>
                        <div className="mb_5">
                        Customer is solely responsible for hiring decision of a candidate. ClearedTalent disclaims
                        any and all liability in connection with the recruitment or hiring of candidates.
                        </div>
                        </div>
                    </div> */}

                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">5. Accounts</div>
                        <div>
                            <div className="mb_5">When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate at all times. Inaccurate information may result in the immediate termination of your account on Service.</div>
                            <div className="mb_5">You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</div>
                            <div className="mb_5">You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.</div>
                            <div className="mb_5">If you are a candidate, you understand that the information made available to ClearedTalent may be shared with prospective employers and/or recruiters for contract or full-time job opportunities. Your profile may contain video interviews or video introduction which may also be shared with prospective employers and/or recruiters for contract or full-time job opportunities. You can hide specific information if ClearedTalent allows such functionality.
                            </div>
                            <div className="mb_5"> We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</div>
                            <div className='mb_5'>
                                If you wish to terminate your account, you may simply discontinue using Service. You can also deactivate your account by emailing us at support@clearedtalent.com.
                            </div>
                            <div className='mb_5'>
                                All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                            </div>

                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">6. Prohibited Uses</div>
                        <div className="mb_5">You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</div>
                        <ul className="Prohibited_ul" >

                            <li> 0.1. In any way that violates any applicable national or international law or regulation.</li>
                            <li>0.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                            <li>0.3. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</li>
                            <li>0.4. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</li>
                            <li>0.5. Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</li>
                            <li>0.6. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</li>
                            <li>0.7. Use any device, software, or routine that interferes with the proper working of Service.</li>
                            <li>0.8. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</li>
                            <li>0.9. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</li>
                            <li>0.10. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</li>
                            <li>0.11. Take any action that may damage or falsify Company rating.</li>
                            <li>0.12. Otherwise attempt to interfere with the proper working of Service.</li>
                        </ul >
                    </div>
                    {/* <div className="f-color fs-20 fs-regular mt_22 ">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">7. Intellectual Property</div>
                        <div>
                            <div className="mb_5">Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of ClearedTalent and its licensors. </div>
                            
                        </div>
                    </div> */}
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">7. Feedback</div>
                        <div>
                            <div className="mb_5">
                                You may provide us either directly at support@clearedtalent.com or via our account managers feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
                            </div>

                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">8. Disclaimer of Warranty</div>
                        <div>
                            <div className="mb_5">THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</div>
                            <div className="mb_5">NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</div>
                            <div className="mb_5">
                                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                            </div>
                            <div className="mb_5">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</div>

                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">9. Limitation of Liability</div>
                        <div>
                            <div className="mb_5">EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                            </div>
                        </div>
                    </div>
                    {/* <div className="f-color fs-20 fs-regular mt_22 ">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">11. Termination </div>
                        <div>
                            <div className="mb_5">We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, 
                            under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</div>
                            <div className="mb_5">If you wish to terminate your account, you may simply discontinue using Service.</div>
                            <div className="mb_5">All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</div>
                            
                        </div>
                    </div> */}
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">10. Governing Law </div>
                        <div>
                            <div className="mb_5">These Terms shall be construed in accordance with and governed by the laws of State of Georgia(USA).</div>
                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">11. Acknowledgement </div>
                        <div>
                            <div className="mb_5">BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</div>
                        </div>
                    </div>
                    <div className="f-color fs-20 fs-regular mt_22 text-left">
                        <div className="pr_5 mb_10 fs-22 fs-semi-bold">12. Contact Us</div>
                        <div>
                            <div className="mb_5">Please send your feedback, comments, requests for technical support by email: <span className="fs-semi-bold">support@clearedtalent.com</span>.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
