/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import userSessionLogs from './apis/userSessionLogs';
const ProtectedRoute = ({ component: Component, ...rest }) => {

  const [isUserActive, setIsUserActive] = React.useState(false);

  const sendActivityPing = async () => {
    await userSessionLogs(localStorage.getItem('userID'))
    }
  

  React.useEffect(() => {
    const handleUserActivity = () => {
      setIsUserActive(true);
    };

    const events = ['click', 'mousemove', 'keydown', 'mousedown', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    const intervalId = setInterval(() => {
      if (isUserActive) {
        sendActivityPing();
        setIsUserActive(false);
      }
    }, 60000); // 1 minute interval

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
      clearInterval(intervalId);
    };
  }, [isUserActive]);



  const getToken = localStorage.getItem('token');
  
    return (
      <Route {...rest} render={(props) => (
        !!getToken ? 
          <Component {...props} /> : <Redirect to={{ pathname: '/signIn',  state: { from: props.location }}} />   
    )} />
    )
  }

export default ProtectedRoute
