import React, { useState, useEffect } from "react";
import "./interviewDatetime.css";
import Group from "./images/Group 181.png";
import Modal from "react-modal";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { useMsal, useAccount } from "@azure/msal-react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import cross from "./images/cross.svg";
import { makeStyles } from "@material-ui/core/styles";
import profileResponse from "../../../apis/profile/profileById";
import interviewDataUpdateResponse from "../../../apis/profile/updateInterviewData";
import Checkbox from "@material-ui/core/Checkbox";
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
const useStyles = makeStyles((theme) => ({
  buttonAlign: {
    display: "flex",
    alignItems: "center",
    padding: "24px 42px 24px 42px !important",
  },
  bodyForEditDateTime: {
    padding: "0px 24px 26px 42px!important",
    // marginTop: "70px",
  },
  interviewTimeNavTab: {
    minWidth: "100px",
    fontFamily: "Segoe-Semibold",
    borderBottom: "2px solid #a3d0fa"
  },
  interviewDateTimeTab: {
    color: "#1A8FFF",
    fontSize: "15px",
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: "22px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const InterviewDateTime = ({ setLastUpdate, userId,profileData }) => {
  const classes = useStyles();
  const jsonConfig = require("../../../Config.json");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [modalAvailabilityDate, setModalAvailabilityDate] = useState("");
  const [cardAvailabilityDate, setCardAvailabilityDate] =
    useState("0000-00-00");
  const [loadingController, setLoadingController] = useState(false);
  const [isactivelySeekingDisabled, setisactivelySeekingDisabled] =
    useState(false);
  const [activelySeeking, setactivelySeeking] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const times = [
    {
      label: "Early Morning",
      time: "7 AM - 9 AM local",
      name: "Early Morning",
    },
    {
      label: "Morning",
      time: "9 AM - 12 PM local",
      name: "morning",
    },
    {
      label: "Afternoon",
      time: "12 PM - 5 PM local",
      name: "afternoon",
    },
    {
      label: "Evening",
      time: "5 PM - 8 PM local",
      name: "evening",
    },
  ];

  const initTimesData = {
    "Early Morning": false,
    morning: false,
    afternoon: false,
    evening: false,
  };
  const [weekdaysTimes, setWeekdaysTimes] = useState({ ...initTimesData });
  const [weekendsTimes, setWeekendsTimes] = useState({ ...initTimesData });
  const [interviewPreferenceRes, setInterviewPreferenceRes] = useState([]);

  // Get Interview Data
  const getInterviewdatedate = () => {
    try {
      let interviewPreferenceLocal =
      profileData.interviewPreference;
      setLastUpdate(profileData.lastUpdated);
      setactivelySeeking(profileData.activelySeekingOpportunity);
      setModalAvailabilityDate(profileData.availabilityDate);
      setCardAvailabilityDate(profileData.availabilityDate);
      if (interviewPreferenceLocal !== null) {
        setInterviewPreferenceRes(JSON.parse(interviewPreferenceLocal));
      }
      setLoadingController(true);
    } catch {
      
    }
  };
  const getInterviewdatedata = async () => {
    try {
      const interviewData = await profileResponse(userId);
      let interviewPreferenceLocal =
        interviewData.data.data[0].interviewPreference;
      setLastUpdate(interviewData.data.data[0].lastUpdated);
      setactivelySeeking(interviewData.data.data[0].activelySeekingOpportunity);
      setModalAvailabilityDate(interviewData.data.data[0].availabilityDate);
      setCardAvailabilityDate(interviewData.data.data[0].availabilityDate);
      if (interviewPreferenceLocal !== null) {
        setInterviewPreferenceRes(JSON.parse(interviewPreferenceLocal));
      }
      setLoadingController(true);
    } catch {
      // Code for catch
      setLoadingController(true);
    }
  };

  // Open modify day modal
  const openModifydayModal = () => {
    setmodalIsOpen(true);
    if (interviewPreferenceRes.length > 0) {
      if (interviewPreferenceRes[0]) {
        setWeekdaysTimes({ ...interviewPreferenceRes[0] });
      } else {
        setWeekdaysTimes({ ...initTimesData });
      }
      if (interviewPreferenceRes[1]) {
        setWeekendsTimes({ ...interviewPreferenceRes[1] });
      } else {
        setWeekendsTimes({ ...initTimesData });
      }
    }
  };

  // Close Modify day modal
  const closeModifydayModal = () => {
    setmodalIsOpen(false);
  };

  // Save details
  const savedetails = async () => {
    let sheduletime = [
      {
        ...weekdaysTimes,
        weekdays: Object.keys(weekdaysTimes).some(
          (key) => key !== "weekdays" && weekdaysTimes[key]
        ),
      },
      {
        ...weekendsTimes,
        weekends: Object.keys(weekendsTimes).some(
          (key) => key !== "weekends" && weekendsTimes[key]
        ),
      },
    ];

    let date = new Date();

    let reqInterviewPreferenceData = {
      lastUpdated: date,
      interviewPreference: JSON.stringify(sheduletime),
      updatedBy: userId,
      activelySeekingOpportunity: true,
      availabilityDate: modalAvailabilityDate,
    };

    try {
      const payload = {
        userId: userId,
        data: reqInterviewPreferenceData,
      };
      let interviewData = await interviewDataUpdateResponse(payload);
      let interviewPreferenceLocal =
        interviewData.data.data.interviewPreference;

      setCardAvailabilityDate(interviewData.data.data.availabilityDate);

      if (interviewPreferenceLocal !== null) {
        setInterviewPreferenceRes(JSON.parse(interviewPreferenceLocal));
      }
      NotificationManager.success("Profile updated", "Success");
      getInterviewdatedata();

      setmodalIsOpen(false);
    } catch {
      // code for catch block
    }
  };
  useEffect(() => {
    getInterviewdatedate();
  }, [profileData]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const mainLabelStyle = {
    position: "relative",
    cursor: "pointer",
    fontWeight: "600",
    fontFamily: "Segoe-Semibold",
    fontSize: "22px",
    color: "#01313C",
    marginTop: "38px",
  };
  const getTimeStateObj = (stateName) => {
    return stateName === "weekdaysTimes"
      ? { ...weekdaysTimes }
      : { ...weekendsTimes };
  };
  const handleTimeChnage = (checked, name, stateName) => {
    const timeStateObj = getTimeStateObj(stateName);
    timeStateObj[name] = checked;
    stateName === "weekdaysTimes"
      ? setWeekdaysTimes(timeStateObj)
      : setWeekendsTimes(timeStateObj);
  };
  const generateTimesRow = (stateName) => {
    return times.map((item, index) => {
      let isChecked = getTimeStateObj(stateName)[item.name];
      const id = `${stateName}_${item.name}`;
      return (
        <div className="addday_chkboxs" key={index}>
          <label htmlFor={id}>
            <div className="adddaychkheight" id="InterviewTimeCheckbox">
              <Checkbox
                id={id}
                color="primary"
                checked={isChecked}
                onChange={(e) =>
                  handleTimeChnage(e.target.checked, item.name, stateName)
                }
              />
              <div className="adddaychk">{item.label} </div>
              <div>({item.time})</div>
            </div>
          </label>
        </div>
      );
    });
  };
  const generateTimesBtn = (timeObj) => {
    if (timeObj) {
      return times.map((item, index) => {
        const isChecked = timeObj[item.name];
        // ckeachedarr.push(isChecked)
        if (isChecked)
          return (
            <button type="button" className="btn preference-btn mt_9" key={index}>
              {`${item.label} (${item.time})`}
            </button>
          );
      });
    }
  };
  const activelySeekingOpportunityHandler = async (value) => {
    setisactivelySeekingDisabled(true);
    let date = new Date();
    let reqInterviewPreferenceData = {
      lastUpdated: date,
      updatedBy: userId,
      activelySeekingOpportunity: value,
    };

    try {
      const payload = {
        userId: userId,
        data: reqInterviewPreferenceData,
      };
      let interviewData = await interviewDataUpdateResponse(payload);
      let interviewPreferenceLocal =
        interviewData.data.data.interviewPreference;
      NotificationManager.success("Profile updated", "Success");
      setisactivelySeekingDisabled(false);
    } catch {
      // code for catch block
      setisactivelySeekingDisabled(false);
    }
  };

  return (
    <>
      {loadingController ? (
        <>
          <div >
            <div>
              <div className="row">
                <div  className="powres_card mt_10 powres_inter" >
                  <div>
                    <p className="mn_medium f-color fs-18">
                      Actively Seeking Opportunity
                    </p>
                    <div className="mb_12">
                      <span className="resume">
                        <input
                          type="radio"
                          id="test3"
                          name="radio-group-testimonial"
                          disabled={isactivelySeekingDisabled}
                          defaultChecked={activelySeeking}
                          onClick={(e) => activelySeekingOpportunityHandler(true)}
                        />
                        <label
                          htmlFor="test3"
                          className="fs-semi-bold seeking-oportunity-radio-label f-color"
                        >
                          Yes
                        </label>
                      </span>
                      <span className="resume ml_12">
                        <input
                          type="radio"
                          id="test4"
                          name="radio-group-testimonial"
                          disabled={isactivelySeekingDisabled}
                          defaultChecked={activelySeeking ? false : true}
                          onClick={(e) => activelySeekingOpportunityHandler(false)}
                        />
                        <label
                          htmlFor="test4"
                          className="fs-semi-bold  seeking-oportunity-radio-label f-color"
                        >
                          No
                        </label>
                      </span>
                    </div>                 
                    <p className="mn_medium f-color fs-18">
                      Interview Information
                    </p>
                    {cardAvailabilityDate != "0000-00-00" &&
                      cardAvailabilityDate != null ? (
                      <p className="fs-regular fs-14 f-color df mt_15">
                        Availability For Next Assignment
                      </p>
                    ) : null}
                    <p className="fs-semi-bold fs-14 f-color">
                      {cardAvailabilityDate
                        ? cardAvailabilityDate === "0000-00-00"
                          ? "Information not available"
                          : new Intl.DateTimeFormat("en", { month: "long" }).format(
                            new Date(cardAvailabilityDate)
                          ) +
                          "-" +
                          new Intl.DateTimeFormat("en", {
                            day: "2-digit",
                          }).format(new Date(cardAvailabilityDate)) +
                          "-" +
                          new Intl.DateTimeFormat("en", {
                            year: "numeric",
                          }).format(new Date(cardAvailabilityDate))
                        : ""}
                    </p>

                    <Box sx={{ borderColor: 'divider', borderBottom: 1 }}>

                    </Box>
                    <Box sx={{}}>
                      {tabIndex === 0 && (

                        <Box>
                          <p className="mn_bold f-color fs-16 mt_12">Weekdays</p>
                          {generateTimesBtn(interviewPreferenceRes[0]) ? (
                            generateTimesBtn(interviewPreferenceRes[0]).filter(
                              (i) => i === undefined
                            ).length === 4 ? (
                              <p className="fs-regular fs-14 f-color df mt_12">
                                Information not available
                              </p>
                            ) : (
                              generateTimesBtn(interviewPreferenceRes[0])
                            )
                          ) : (
                            <p className="fs-regular fs-14 f-color df mt_12">
                              Information not available
                            </p>
                          )}

                        </Box>
                      )}
                      {/* {tabIndex === 1 && ( */}
                      {/* <Box> */}
                      <div>
                        <p className="mn_bold f-color fs-16 mt_12">Weekends</p>
                        {generateTimesBtn(interviewPreferenceRes[1]) ? (
                          generateTimesBtn(interviewPreferenceRes[1]).filter(
                            (i) => i === undefined
                          ).length === 4 ? (
                            <p className="fs-regular fs-14 f-color df mt_12">
                              Information not available
                            </p>
                          ) : (
                            generateTimesBtn(interviewPreferenceRes[1])
                          )
                        ) : (
                          <p className="fs-regular fs-14 f-color df mt_12">
                            Information not available
                          </p>
                        )}
                      </div>

                      {/* </Box> */}
                      {/* )} */}
                    </Box>
                  </div>
                  <div>
                  <button
                      type="button"
                      className="btn btn-date mt_15 cp"
                      onClick={openModifydayModal}
                    >
                      <div>
                        <img className="date_icon" src={Group} alt="" />
                      </div>
                      <div className="pl_10 fs-14" >Manage Interview Date/Time</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              open={modalIsOpen}
              onClose={closeModifydayModal}
              aria-labelledby="customized-dialog-title"
              maxWidth="sm"
              fullWidth={true}
              className="record-video-intro-responsive"
            >
              <div className="ManageInterviewModalHeader">
                <legend> Interview Date/Time Preference </legend>

                <div className="crossFormodal cp" onClick={closeModifydayModal}>
                </div>
              </div>
              <DialogContent
                className={`${classes.bodyForEditDateTime} responsive-body-for-profile-edit`}
              >
                <div style={mainLabelStyle} className="InterviewTimeContaine">
                  <legend className="mb_0">Weekdays</legend>
                </div>
                <hr className="new2 " />

                {generateTimesRow("weekdaysTimes")}
                <div style={mainLabelStyle} className="mt_32">
                  <legend className="mb_0">Weekends</legend>
                </div>
                <hr className="new2 " />

                {generateTimesRow("weekendsTimes")}
              </DialogContent>

              <DialogActions
                id="btmModalContainer"
                className={`${classes.buttonAlign} responsive-dialog`}
              >
                <button
                  type="button"
                  className="btn-big btn-skill cp"
                  onClick={closeModifydayModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-big btn_powres ml_5 cp"
                  onClick={savedetails}
                >
                  Save
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      ) : (
        <>
          <div className="card mt_10 powres_inter">
            <div className="row">
              <p className="fs-semi-bold fs-18">
                <Skeleton variant="text" animation="wave" />
              </p>
              <p className="fs-regular fs-14 f-color mt_15">
                <Skeleton variant="text" animation="wave" />
              </p>
              <p className="fs-semi-bold fs-14 f-color">
                <Skeleton variant="text" animation="wave" />
              </p>

              <p className="fs-semi-bold fs-16 mt_12">
                <Skeleton variant="text" animation="wave" />
              </p>

              <p className="fs-semi-bold fs-16 mt_12">
                <Skeleton variant="text" animation="wave" />
              </p>

              <p className="fs-semi-bold fs-16 mt_12">
                <Skeleton variant="text" animation="wave" />
              </p>

              <p className="fs-semi-bold fs-16 mt_12">
                <Skeleton variant="text" animation="wave" />
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InterviewDateTime;
