import axios from "axios";
const jsonConfig = require("../../Config.json");

const getTrackCandidateInfo = async (payload) => {
    let allResponse = {}
    try {
        const getallresponse =await axios.post(
            jsonConfig.apiUrl + "getTrackCandidateInfo",
            payload,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
        allResponse = getallresponse;
        return Promise.resolve(allResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default getTrackCandidateInfo;