import axios from "axios";
const jsonConfig = require("../../Config.json");


const getLocations = async (keyword) => {
    try {
        const resp = await axios.get(
            jsonConfig.apiUrl + `getLocations/${keyword}`,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        return Promise.resolve(resp)
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export default getLocations;