import axios from "axios";
const jsonConfig = require("../../Config.json");

const UpdateRecruiter = async (payload) => {
  let allResponse = {};
  try {
    const response = await axios.put(
      jsonConfig.apiUrl + "UpdateUsers/" + payload.id,
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = response;

    return Promise.resolve(allResponse);
  } catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
};

export default UpdateRecruiter;