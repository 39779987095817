import axios from "axios";
const jsonConfig = require("../../Config.json");

const recruiterLeadernameByDivision = async (id,recruitmentpartnerId) => {
 
    try {
        const getAllResponse = await axios.get(
          jsonConfig.apiUrl + "recruiterLeadernamebydivision/"+ id+"/"+recruitmentpartnerId,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        return Promise.resolve(getAllResponse);
      } catch (error) {
        return Promise.reject(error);
      }
}

export default recruiterLeadernameByDivision;