import axios from "axios";
const jsonConfig = require("../../Config.json");

const reSendOtpResponse = async (payloadData) => {
    let allResponse = {}
    try {
        const emailResponse = await axios.put(
            jsonConfig.apiUrl + "ResendOtp", payloadData,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = emailResponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr", error);        
        return Promise.reject(error);
    }
}

export default reSendOtpResponse;