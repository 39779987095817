import axios from "axios";
const jsonConfig = require("../../Config.json");

const UpdateStatusReferralById = async (data) => {
    let allResponse = {}
    try {
        const response = await axios.put(
            jsonConfig.apiUrl + "updatestatusreferralbyid/" + data.referralId, data.data,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }
          );
        
        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
      console.log("errrrrr", error);
        return Promise.reject(error)
    }
}

export default UpdateStatusReferralById;