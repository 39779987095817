import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import Modal from "../../components/modal/";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PlusIcon from "./../../resources/images/plus_icon.png";
import Header from "../../components/adminHeader/adminHeader";
import getAllInvitedCandidateResponse from "../../apis/inviteCandidate/getAllInvitedCandidate";
import revokeInviteCandidateResponse from "../../apis/inviteCandidate/revokeInviteCandidate";
import { process } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-grid";
import { Grid as KendoGrid, GridNoRecords } from "@progress/kendo-react-grid";
import "./candidate.css";
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Candidate = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const classes = useStyles();
  const [clientData, setClientData] = React.useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [isDataLoaded, setisDataLoaded] = useState(false);

  const [editClientData, setEditClientData] = useState(null);
  const [allCandidateArr, setAllCandidateArr] = useState([]);
  const [openRevokeDialogue, setOpenRevokeDialogue] = useState(false);
  const [revokeCandidateDetails, setRevokeCandidateDetails] = useState({});

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getAllCandidate();
      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }
    fetchAllData();
  }, []);
  const getAllCandidate = async () => {

    let allCandidates = await getAllInvitedCandidateResponse();
    if (allCandidates.data.data) {

      setAllCandidateArr(allCandidates.data.data);
    } else {
      setAllCandidateArr([]);
    }

  };
  const openRevokeModal = (value) => {
    setRevokeCandidateDetails(value);
    setOpenRevokeDialogue(true);
  };
  const closeRevokeModal = () => {
    setOpenRevokeDialogue(false);
  };
  const revokeCandidate = async () => {
    var payload = {
      isActive: false,
    };

    try {
      let revokeInviteResponse = await revokeInviteCandidateResponse(
        revokeCandidateDetails.id,
        payload
      );
      NotificationManager.success("Revoke done", "Success", 2500);
      getAllCandidate();
      setOpenRevokeDialogue(false);
    } catch (error) {
      NotificationManager.error("Revoke failed", "Error", 2500);
      setOpenRevokeDialogue(false);
    }
  };



  const headerStyle = {
    whiteSpace: "nowrap",
  };

  const formatDate = (datetime) => {
    const date = DateTime.fromISO(datetime)
      .toLocal()
      .startOf("day")
      .toJSDate();
    return date;
  };

  // creating filter for kendoGrid

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  // fetching data(allSubSkillForCard) from database

  const [result, setResult] = useState(allCandidateArr);
  const dataStateChange = (event) => {
    setResult(process(allCandidateArr, event.dataState));
    setDataState(event.dataState);
  };
  useEffect(() => {


    setResult(process(allCandidateArr, dataState));
    const inDate = "invitedDate";
    const inBy = "invitedByPerson";
    const exDate = "expiryDate";
    for (let i = 0; i < allCandidateArr.length; i++) {
      // invited date
      allCandidateArr[i][inDate] = allCandidateArr[i].createdAt
        ? handleRequestDateLuxon(allCandidateArr[i])
        : "N/A";

      // invited by
      allCandidateArr[i][inBy] = allCandidateArr[i].user
        ? allCandidateArr[i].user.firstName +
        " " +
        allCandidateArr[i].user.lastName
        : "N/A";

      // expiry date
      allCandidateArr[i][exDate] = allCandidateArr[i].expiresOn
        ? formatDate(allCandidateArr[i].expiresOn)
        : "N/A";
    }

  }, [allCandidateArr]);

  // Luxon DateTime
  const handleRequestDateLuxon = (data) => {
    const date = data.createdAt;
    const formatted = DateTime.fromISO(date)
      .toLocal()
      .startOf("day")
      .toJSDate();

    return formatted ? formatted : "N/A";
  };

  const handleRevoke = (props) => {
    return (
      <td className="actionContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openRevokeModal(props.dataItem)}
        >
          Revoke
        </Button>
      </td>
    );
  };

  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <Container component={Paper} className="allignmentFix">
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <h2 className="fs-semi-bold fs-30 f-color">
                          Pending Candidate Invites
                        </h2>
                      </Grid>
                      {/*KENDO GRID */}
                      <KendoGrid
                        className="kw"
                        filter={dataState.filter}
                        resizable={true}
                        filterable={true}
                        sort={dataState.sort}
                        pageable={{
                          buttonCount: 10,
                          info: true,
                          previousNext: true,
                          pageSizes: true,
                        }}
                        style={{
                          maxHeight: "500px",
                          overflow: "hidden",
                        }}
                        skip={dataState.skip}
                        take={dataState.take}
                        data={result}
                        onDataStateChange={(e) => dataStateChange(e)}
                      >
                        {/* {
                          !isDataLoaded && (
                            <GridNoRecords>
                              <div className='col-4'>
                                <br />
                                <Loader size='large' themeColor="info" type="converging-spinner" />
                              </div>
                            </GridNoRecords>
                          )} */}
                        <GridColumn
                          field="firstName"
                          title="First Name"
                          width="200px"
                          resizable={true}
                          filterable={true}
                        />
                        <GridColumn
                          field="lastName"
                          title="Last Name"
                          width="200px"
                          resizable={true}
                          filterable={true}
                        />
                        <GridColumn
                          field="email"
                          title="Email"
                          width="200px"
                          filterable={true}
                          sortable={true}
                        />
                        <GridColumn
                          field="invitedDate"
                          title="Invited Date"
                          width="200px"
                          filterable={true}
                          sortable={true}
                          filter={"date"}
                          format="{0:d},{0:t}"
                          cell={(e) => {
                            return (
                              <td>
                                {DateTime.fromISO(e.dataItem.createdAt)
                                  .toFormat("MM/dd/yyyy")
                                  .toString()}
                              </td>
                            );
                          }}
                        />
                        <GridColumn
                          field="invitedByPerson"
                          title="Invited By"
                          width="200px"
                          filterable={true}
                          sortable={true}
                        />
                        <GridColumn
                          field="expiryDate"
                          type="date"
                          title="Invite Expiry Date"
                          width="200px"
                          filterable={true}
                          sortable={true}
                          filter={"date"}
                          format="{0:d},{0:t}"
                          cell={(e) => {
                            return (
                              <td>
                                {DateTime.fromISO(e.dataItem.expiresOn)
                                  .toFormat("MM/dd/yyyy")
                                  .toString()}
                              </td>
                            );
                          }}
                        />
                        <GridColumn
                          title="Revoke"
                          width="200px"
                          resizable={true}
                          filterable={false}
                          cell={handleRevoke}
                        />
                      </KendoGrid>
                    </Grid>
                  </Container>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>
                ) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={openRevokeDialogue}
        onClose={closeRevokeModal}
        aria-labelledby="customized-dialog-title"
      >
        <DialogContent
          className={`${classes.bodyForRemove} removemodal_responsive`}
        >
          <div className="text-center fs-semi-bold f-color fs-18">
            Do you want to revoke this invite?
          </div>
        </DialogContent>
        <DialogActions
          className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button `}
        >
          <div className="remove_modal revoke_adjust">
            <button
              type="button"
              className="btn_remove btn-skill cp"
              onClick={closeRevokeModal}
            >
              No
            </button>
            <button
              type="button"
              className="btn2 btn_primary ml_5 cp"
              onClick={revokeCandidate}
            >
              Yes
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <NotificationContainer />
    </>
  );
};

export default Candidate;
