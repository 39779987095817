import axios from "axios";
const jsonConfig = require("../../Config.json");

const deleteTestimonial = async (id) => {
  let allResponse = {};
  try {
    const deleteTestimonialResponse = await axios.delete(
      jsonConfig.apiUrl + "testimonials/" + id,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = deleteTestimonialResponse;
    return Promise.resolve(allResponse);
  } catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
};

export default deleteTestimonial;
