import React, { useEffect, useState } from "react";
import getTestTypes from "../../apis/Tests/getTestTypes";
import getSkills from "../../apis/Tests/getSkills";
import getSubskills from "../../apis/Tests/getSubskills";
import addTest from "../../apis/Tests/addTest";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import EditTest from "../../apis/Tests/editTest";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import questionBySubSkill from "../../apis/Tests/questionBySubskill";
const useStyles = makeStyles((theme) => ({
  iconButton: {
    paddingLeft: "4px"
  },
  errorMsg: {
    "marginLeft": "5px",
    color: " #F32013"
  },
  addIconButton: { marginTop: "5px" }
}
));
const EditTestModal = ({
  isEdit,
  setIsEdit,
  getTests,
  testData,
  setTestData,
  testTypes,
  skills,
  subskills,
  customers,
  recruitmentPartner,
  setQuestionBySkill,
  questionBySkill,
  totalQuestion,
  setTotalQuestion
}) => {
  const classes = useStyles();
  const [type, setType] = useState("");
  const [customer, setCustomer] = useState("");
  const [recruitmentpartners, setRecruitmentpartners] = useState("");
  const [skill, setSkill] = useState("");
  const [selectedSubskill, setSelectedSubskill] = useState([]);
  const [isActiveCode, setIsActiveCode] = useState("");
  const [testStatus, setTestStatus] = useState("");
  const activeOptions = ["True", "False"];
  const [selectedCustomer, setSelectedCustomer] = useState([])
  const [noOfQuestion, setNoOfQuestion] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [apvBtn, setApvBtn] = useState(false);
  useEffect(() => {
    if (Object.keys(testData).length > 0) {
      setTestStatus(testData.isActive ? "True" : "False");
      if (testData.customerId != "")
        getCustomerData(testData.customerId)
      setCustomer(testData.customerId)
    }
  }, [Object.keys(testData).length]);

  const closeModal = () => {
    setIsEdit(false);
    setTestData({});
    setSelectedSubskill([]);
    setQuestionBySkill([]);
    setNoOfQuestion([]);
    setTotalQuestion(0);
    setErrorMsg([]);
  };

  const testTypeHandler = (e, value) => {
    if (value) {
      setType(value.code);
      if (value.code === "Public") {
        setTestData((previousState) => ({
          ...previousState,
          testtype: value,
          organization: {},
        }));
        setCustomer("");
        setRecruitmentpartners("");
        setSelectedCustomer([])
      } else {
        setTestData((previousState) => ({ ...previousState, testtype: value }));
      }
    } else {
      setTestData((previousState) => ({ ...previousState, testtype: {} }));
      setTestData((previousState) => ({ ...previousState, organization: {} }));
      setType("");
      setCustomer("");
      setRecruitmentpartners("");
    }
  };

  const getValues = (selected) => {
    const values = [];
    for (let i = 0; i < subskills.length; i++) {
      for (let j = 0; j < selected.length; j++) {
        if (subskills[i].name === selected[j].name) {
          values.push(subskills[i])
        }
      }
    }
    return (values)
  }

  const getCustomers = (value) => {
    let custo = recruitmentPartner.filter((item) => item.id === value);
    custo = custo.map((item) => item.recruitmentpartnercustomers)
    custo = custo[0];
    return (custo);
  }

  const getCustomerData = (value) => {
    let cust = getCustomers(testData.recruitmentpartnerId);
    if (cust) {
      cust = cust.filter((item) => item.customerId === value);
      setSelectedCustomer(cust[0])
    }
  }

  const customerHandler = (e, value) => {
    if (value) {
      setTestData({ ...testData, customerId: value.customerId });
      setCustomer(value.customerId);
      getCustomerData(value.customerId);
    } else {
      setTestData({ ...testData, customerId: "" });
      setCustomer("");
      setSelectedCustomer([])
    }
  };

  const RecruitmentpartnerHandler = (e, value) => {
    setSelectedCustomer([]);
    setCustomer("")
    if (value) {
      setTestData({ ...testData, recruitmentpartnerId: value.id });
    } else {
      setTestData({ ...testData, recruitmentpartnerId: "" });
      setRecruitmentpartners("");
    }
  };

  const isActiveHandler = (e, value) => {
    if (value === "True") {
      setTestData({ ...testData, isActive: true });
    }
    else if (value === "False") {
      setTestData({ ...testData, isActive: false });
    }
    else {
      setTestData({ ...testData, isActive: "" });
    }
  };

  const skillHandler = (e, value) => {
    if (value) {
      setTestData({ ...testData, skillId: value.id });
      setSkill(value.id);
    } else {
      setTestData({ ...testData, skillId: "" });
      setSkill("");
    }
  };

  const subskillsHandler = async (e, value, i) => {
    let tempArr = testData.testsubskillsmappings
    tempArr[i].subSkill = { id: value.id, name: value.name };
    tempArr[i].subskillId = value.id
    setTestData({ ...testData, testsubskillsmappings: tempArr });
    let msgArr = [...errorMsg];
    msgArr[i] = "";
    setErrorMsg(msgArr);
    if (value.id) {
      const payload = {
        subSkillsId: value.id ? value.id : "",
      };
      const res = await questionBySubSkill(payload);
      if (res.status === 200) {
        let questionData = [...questionBySkill];
        questionData[i] = res.data.data.length
        setQuestionBySkill(questionData || [])
      }
    }
  };

  const addSubskill = async () => {
    const row = { subSkill: { id: "", name: "", }, subskillId: "", noOfQuestion: null };
    let tempArr = testData.testsubskillsmappings
    tempArr.push(row);
    setTestData({ ...testData, testsubskillsmappings: tempArr });
    let questionData = [...questionBySkill];
    questionData[questionData.length] = -1
    setQuestionBySkill(questionData || []);
    let msgArr = [...errorMsg];
    msgArr.push("");
    setErrorMsg(msgArr);
  }
  const deleteSubskill = (e, i) => {
    let tempArr = [...testData.testsubskillsmappings];
    tempArr.splice(i, 1);
    setTestData({ ...testData, testsubskillsmappings: tempArr });
    let questionDatatemp = [...questionBySkill];
    questionDatatemp.splice(i, 1);
    setQuestionBySkill(questionDatatemp || []);
    let total = 0
    tempArr.forEach(element => { if (element.noOfQuestion) total += element.noOfQuestion * 1 });
    setTotalQuestion(total);
    let msgArr = [...errorMsg];
    msgArr.splice(i, 1);
    setErrorMsg(msgArr);
  }

  const subSkillQuestionHandler = (value, i) => {
    let tempArr = testData.testsubskillsmappings;
    tempArr[i].noOfQuestion = value * 1;
    setTestData({ ...testData, testsubskillsmappings: tempArr });
    let total = 0;
    tempArr.forEach(element => { if (element.noOfQuestion) total += element.noOfQuestion * 1 });
    setTotalQuestion(total);
    let msgArr = [...errorMsg];
    msgArr[i] = "";
    setErrorMsg(msgArr);
  }
  const handleSave = async () => {
    setApvBtn(true);
    let noOfQuestionArr = [];
    let selectedSubSkillArr = [];
    let flag = true;
    let questionError = false;
    let msg = "";
    let msgArr = [...errorMsg];
    for (let i = 0; i < testData.testsubskillsmappings.length; i++) {
      let obj = {}
      if (testData.testsubskillsmappings[i].noOfQuestion) {
        if ((testData.testsubskillsmappings[i].noOfQuestion * 1) > questionBySkill[i] && questionBySkill[i] > 0) {
          questionError = true
          msgArr[i] = "Max Question for subskill " + testData.testsubskillsmappings[i].subSkill.name
            + " is: " + questionBySkill[i];
        } else if ((testData.testsubskillsmappings[i].noOfQuestion * 1) < 0) {
          questionError = true
          msgArr[i] = "Please fill with positive value for all subkill's ";

        } else if (questionBySkill[i] === 0) {
          questionError = true;
          msgArr[i] = "No Question present in  subskill " + testData.testsubskillsmappings[i].subSkill.name
        } else {
          // noOfQuestionArr.push(testData.testsubskillsmappings[i].noOfQuestion);
          obj.noOfQuestion = testData.testsubskillsmappings[i].noOfQuestion
        }
      } else {
        flag = false;
      }
      if (testData.testsubskillsmappings[i].subskillId) {
        // selectedSubSkillArr.push(testData.testsubskillsmappings[i].subskillId)
        obj.subskillId = testData.testsubskillsmappings[i].subskillId
      } else {
        flag = false;
      }
      selectedSubSkillArr.push(obj);
    }
    setErrorMsg(msgArr);
    const payload = {
      testName: testData.testName ? testData.testName : "",
      testCode: testData.testCode ? testData.testCode : "",
      skillId: testData.skillId ? testData.skillId : "",
      testtypeId: testData.testtype ? testData.testtype.id : "",
      customerId: customer,
      recruitmentpartnerId: testData.recruitmentpartnerId ? testData.recruitmentpartnerId : "",
      subskill: selectedSubSkillArr ? selectedSubSkillArr : [],
      createdBy: localStorage.getItem("userID"),
      updatedBy: localStorage.getItem("userID"),
      isActive: testData.isActive,
      // noOfQuestion: noOfQuestionArr
    };
    if (payload.testName === "" || payload.testCode === "" || payload.skillId === "" || payload.testtypeId === undefined || payload.subskill === "" || payload.isActive === "" || payload.subskill.length === 0 || !flag) {
      setApvBtn(false);
      NotificationManager.error("Please fill in all the fields", Error, 2500);
    }
    else if (testData.testtype.code === "Client-Specific" && payload.recruitmentpartnerId === "") {
      setApvBtn(false);
      NotificationManager.error("Please fill in all the fields", Error, 2500);
    } else if (!questionError) {
      if (testData.testtype.code === "Public") {
        payload.customerId = "";
        payload.recruitmentpartnerId = "";
      }
      try {
        const res = await EditTest(testData.id, payload);
        let msg = res.data.message;
        if (res.status === 201||res.status === 200) {
          NotificationManager.success(
            "Test Updated successfully",
            "Success",
            2500
          );
          getTests();
          setCustomer("");
          setSelectedCustomer([]);
          closeModal();
          setApvBtn(false);
        }
        else {
          setApvBtn(false);
          NotificationManager.error(
            "This Test already exists Please use a different Test Code",
            "Failure",
            2500
          );
        }
      }
      catch (error) {
        console.log(error);
        setApvBtn(false);
      }
    }
    setApvBtn(false);

  };


  if (!isEdit) {
    return null;
  } else {
    return (
      <>
        <Dialog
          onClose={closeModal}
          aria-labelledby="customized-dialog-title"
          open={isEdit}
          PaperProps={{
            style: {
              width: "600px",
            },
          }}
        >
          <Grid item xs={12}>
            <div className="creatTest_title">
              <DialogTitle
                id="customized-dialog-title"
                className="admindailog_title_modal"
                onClose={closeModal}
              >
                Edit Test
                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: "7px",
                    top: "12px",
                    color: "grey",
                  }}
                  onClick={closeModal}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            </div>
          </Grid>
          <DialogContent className="userlist_dailog_content">
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Test Name*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Enter Test Name"
                  className="admin_textbox"
                  value={testData.testName}
                  onChange={(e) =>
                    setTestData({ ...testData, testName: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Test Code*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Enter Test Code"
                  className="admin_textbox"
                  defaultValue={testData.testCode}
                  onChange={(e) =>
                    setTestData({ ...testData, testCode: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Test Type*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  options={testTypes}
                  value={testData.testtype}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Test Type"
                    />
                  )}
                  onChange={testTypeHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            {testData.testtype?.code === "Client-Specific" ? <>
              <Grid item xs={12} md={12}>
                <Typography variant="body1" component="h2">
                  Select Recruitment Organization*
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    id="combo-box-demo"
                    options={recruitmentPartner.filter((item) => item.isActive === true)}
                    value={testData.recruitmentpartner}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Recruitment Organization"
                      />
                    )}
                    onChange={RecruitmentpartnerHandler}
                  />
                </FormControl>
              </Grid>
              &nbsp;
              {testData.recruitmentpartnerId !== "" ? (
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" component="h2">
                    Select Customer
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      id="combo-box-demo"
                      value={selectedCustomer}
                      options={getCustomers(testData.recruitmentpartnerId)}
                      getOptionLabel={(option) => option?.customer?.name || ""}
                      getOptionSelected={(option, value) => option?.customer?.id === value?.customer?.id}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select Customer"
                        />
                      )}
                      onChange={customerHandler}
                    />
                  </FormControl>
                </Grid>
              ) : null}
            </> : null}
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Is Active*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  options={activeOptions}
                  defaultValue={testStatus}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Status"
                    />
                  )}
                  onChange={isActiveHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Skill*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  autoSelect
                  id="combo-box-demo"
                  options={skills}
                  defaultValue={testData.skill}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Skill"
                    />
                  )}
                  onChange={skillHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body1" component="h2">
                  Subskills*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" component="h2">
                  No.of Questions*
                </Typography>
              </Grid>
            </Grid>
            {testData.testsubskillsmappings.map((item, i) => {
              return (
                <div key={i}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          disableClearable
                          options={subskills}
                          defaultValue={item.subSkill || ""}
                          getOptionLabel={(option) => option.name || ""}
                          getOptionSelected={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Subskills"
                            />
                          )}
                          getOptionDisabled={option => !!testData.testsubskillsmappings.find(element => element.subskillId === option.id)}
                          onChange={(event, newValue) => subskillsHandler(event, newValue, i)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <OutlinedInput
                        type="number"
                        placeholder="Enter Total No.of Questions"
                        className="admin_textbox"
                        value={item.noOfQuestion || ""}
                        onChange={(e) => subSkillQuestionHandler(e.target.value, i)}
                      />
                      {i !== 0 && (
                        <span className={`${classes.iconButton} `}>
                          <CancelRoundedIcon
                            onClick={(e) => { deleteSubskill(e, i) }}
                          />
                        </span>
                      )}
                    </Grid>
                    <p className={`${classes.errorMsg} `}>{errorMsg[i]}</p>
                  </Grid>
                </div>
              )
            })}
            <div className={"row optionsStyle addButton " + `${classes.addIconButton} `}>
              <AddCircleIcon className="addBtn"
                onClick={(e) => addSubskill(e)}
              />
            </div>
            &nbsp;
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Total Number of Questions: {totalQuestion}
              </Typography>
            </Grid>
          </DialogContent>

          <Grid
            item
            xs={12}
            md={12}
            className="user_pop_button content-scrollable"
          >
            <DialogActions>
              <Button variant="outlined" color="primary" className="btn3" onClick={closeModal}>
                Cancel
              </Button>

              <button
                type="button"
                className="btn3 btn_primary ml_5 cp"
                onClick={handleSave}
                disabled={apvBtn}
              >
                <div className="btn_loader">
                  Save
                  &nbsp;
                  {apvBtn &&
                    <div>
                      <CircularProgress size={13} />
                    </div>
                  }
                </div>
              </button>
            </DialogActions>
          </Grid>
        </Dialog>
        <NotificationContainer />
      </>
    );
  }
};

export default EditTestModal;