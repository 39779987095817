import axios from "axios";
const jsonConfig = require("../../Config.json");


const getAllTeamByOrgId = async (payload) => {
    try {
        const resp = await axios.post(
            jsonConfig.apiUrl + "teamByOrg",
             payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        return Promise.resolve(resp);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export default getAllTeamByOrgId;