import React from 'react'
import { Skeleton } from '@material-ui/lab';
import { List } from 'react-virtualized';

const VirtualizedBox = ({ children, ...props }) => {
    const itemCount = children?.length;

    const rowRenderer = ({ index, key, style, isScrolling }) => {
        return (
            <div key={key} style={style}>
                {!isScrolling ? children[index] : <Skeleton variant="text" animation="wave" height="fit-content" width="100%" />}
            </div>
        );
    };



    return (
        <List
            height={200} // Specify the height of the virtualized list
            rowCount={itemCount}
            rowHeight={36} // Specify the height of each row
            rowRenderer={rowRenderer}
            width={800} // Specify the width of the virtualized list
            {...props}
        />
    );
};

export default VirtualizedBox
