import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, OutlinedInput, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from '@material-ui/lab';
import getAllActiveRecruitmentpartner from "../../apis/organization/getAllActiveRecruitmentpartner";
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import getAgencyByAgencytypeResponse from '../../apis/recruiters/getAgencyByAgencytype';
import validateCompanyEmail from '../../utilities/validateCompanyEmail';
import createUserByAdminRequest from '../../apis/users/createUserByAdmin';
import phoneNumberExistResponse from '../../apis/users/getUserDataByPhoneNumber';
import { NotificationManager } from "react-notifications";
import approveRecruitersResponse from '../../apis/recruiters/approveRecruiters';

const AddUserModel = ({
    openAddModel,
    setOpenAddModel,
    setIsRefreshNeeded,
    userRoleswithOutCandidate,
    agencies,
    userIdType,
    isActiveOption
}) => {

    const closeButton = {
        position: "absolute",
        right: "7px",
        top: "12px",
        color: "grey",
    };

    const btnSavegreen = {
        color: "#fff",
        backgroundColor: "#2AD156",
        border: "1px solid #2AD156",
    };

    const [userData, setUserData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        country: "",
        phoneNumber: "",
        countryCallingCode: "",
        role: "",
        recruitmentPartner: "",
        agencyType: "",
        agency: ""
    })

    const [defaultCountryCode, setDefaultCountryCode] = useState("")
    const [phoneNumberInt, setPhoneNumberInt] = useState("")
    const [phoneError, setPhoneError] = useState(false)
    const [allActiveRecruitmentPartner, setAllActiveRecruitmentPartner] = useState([])
    const [allAgencyData, setAllAgencyData] = useState([])
    const [atvBtn, setAtvBtn] = useState(false);
    const [countryList, setCountryList] = useState([
        {
            currency: "USD",
            flag: "",
            isoCode: "US",
            latitude: "38.00000000",
            longitude: "-97.00000000",
            name: "United States",
            phonecode: "+1",
        },
        {
            currency: "CAD",
            flag: "",
            isoCode: "CA",
            latitude: "60.00000000",
            longitude: "-95.00000000",
            name: "Canada",
            phonecode: "+1",
        },
        {
            currency: "INR",
            flag: "",
            isoCode: "IN",
            latitude: "20.00000000",
            longitude: "77.00000000",
            name: "India",
            phonecode: "+91",
        },
        {
            currency: "MXN",
            flag: "",
            isoCode: "MX",
            latitude: "23.00000000",
            longitude: "-102.00000000",
            name: "Mexico",
            phonecode: "+52",
        }
    ]);

    const clearAllFields = () => {
        setPhoneNumberInt("")
        setUserData({
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            country: "",
            phoneNumber: "",
            countryCallingCode: "",
            role: "",
            recruitmentPartner: "",
            agencyType: "",
            agency: ""
        })
        setAllAgencyData([])
        setDefaultCountryCode("")
    }

    const closeModel = () => {
        setOpenAddModel(false);
        clearAllFields();
    }

    const getAllRecruitmentPartner = async () => {
        try {
            let response = await getAllActiveRecruitmentpartner();
            setAllActiveRecruitmentPartner(response.data.data)
        } catch (error) {
            console.log("error", error);
        }

    }

    const getAllAgencyTypesData = async (agency, userRecruitmentPartner) => {
        try {
            const allData = await getAgencyByAgencytypeResponse(userRecruitmentPartner.id, {
                type: agency,
            });
            setAllAgencyData(allData?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    const userFirstNameHandler = (event) => {
        setUserData({ ...userData, firstName: event.target.value });
    }
    const userMiddleInitialHandler = (event) => {
        setUserData({ ...userData, middleName: event.target.value });
    }
    const userLastNameHandler = (event) => {
        setUserData({ ...userData, lastName: event.target.value });
    }
    const userEmailHandler = (event) => {
        setUserData({ ...userData, email: event.target.value });
    };
    const userCountryChangeHandler = (e, value) => {
        if (value) {
            setDefaultCountryCode(value?.isoCode)
            setUserData({ ...userData, country: value })
        } else {
            setUserData({ ...userData, country: "" })
        }
    }
    const keypressHandler = (e) => {
        var invalidChars = ["-", "+", "e"];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handlePhoneNumberChange = (val) => {
        setPhoneNumberInt(val);
        if (val) {
            if (val && isPossiblePhoneNumber(val)) {
                let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
                setUserData({ ...userData, phoneNumber: val.replace(countryCode_PhoneNumber, ""), countryCallingCode: countryCode_PhoneNumber })
                setPhoneError(false);
            } else {
                setPhoneError(true);
            }
        }
        else {
            setPhoneError(false);
            setUserData({ ...userData, phoneNumber: "", countryCallingCode: "" })
        }
    };

    const userRoleHandler = async (e, value) => {
        if (value) {
            setUserData({ ...userData, role: value, recruitmentPartner: "", agencyType: "", agency: "" })

            await getAllRecruitmentPartner();


        }
        else {
            setUserData({ ...userData, role: "", recruitmentPartner: "", agencyType: "", agency: "" })
        }
        setAllAgencyData([])
    };

    const recruitmentPartnerChangehandler = (e, value) => {
        if (!value) {
            setUserData({ ...userData, recruitmentPartner: "", agencyType: "", agency: "" })
        } else {
            setUserData({ ...userData, recruitmentPartner: value, agencyType: "", agency: "" })
        }
        setAllAgencyData([])
    };

    const userAgencyTypeHandler = (e, value) => {
        if (value) {
            setUserData({ ...userData, agencyType: value, agency: "" })
            getAllAgencyTypesData(value.code, userData.recruitmentPartner)
        }
        else {
            setUserData({ ...userData, agencyType: "", agency: "" })
            setAllAgencyData([])
        }
    }

    const userAgencyHandeler = (e, value) => {
        if (value) {
            setUserData({ ...userData, agency: value })
        } else {
            setUserData({ ...userData, agency: "" })
        }

    }

    const checkForDuplicatePhoneNumber = async (userphoneEditNumber) => {
        var phnnoinput = {
            "phoneNumber": userphoneEditNumber
        }
        try {
            const phoneNochkResponse = await phoneNumberExistResponse(phnnoinput);
            return (
                phoneNochkResponse.data.data[0].isUnique
            )
        } catch (error) {
            console.log(error);
        }
    }

    const addUserHandler = async () => {
        setAtvBtn(true);
        const mailValidationHandle = validateCompanyEmail(userData.email);
        if (
            userData.firstName === "" ||
            userData.lastName === "" ||
            userData.email === "" ||
            userData.country === "" ||
            userData.phoneNumber === "" ||
            userData.countryCallingCode === "" ||
            userData.country === "" ||
            userData.role === "" ||
            (userData.role.roleName === "Recruiter" && (
                userData.recruitmentPartner === "" ||
                userData.agencyType === "" ||
                userData.agency === ""
            ))
        ) {
            setAtvBtn(false);
            return NotificationManager.error(
                "Please fill all the required fields",
                "Error",
                2500
            );
        }
        else if (phoneError) {
            setAtvBtn(false);
            return NotificationManager.error(
                `Please enter a valid phone number`,
                "Error",
                2500
            );
        }
        let isuniquePhoneNo = await checkForDuplicatePhoneNumber(userData.phoneNumber);
        if (!isuniquePhoneNo) {
            setAtvBtn(false);
            return NotificationManager.error(
                `Phone no already exists in the system. Please enter a unique phone number.`,
                "Error",
                2500
            );
        }
        else if (!mailValidationHandle) {
            setAtvBtn(false);
            return NotificationManager.error(
                `Please enter a company email ID`,
                "Error",
                2500
            );
        }
        else {
            let payload = {
                firstName: userData.firstName,
                MiddleInitial: userData.middleName,
                lastName: userData.lastName,
                locationCountry: JSON.stringify(userData.country),
                phoneCode: userData.countryCallingCode,
                phoneNumber: userData.phoneNumber,
                email: userData.email,
                roleId: userData.role.id,
                roleCode: userData.role.code,
                recruitmentpartnerId: userData.recruitmentPartner.id,
                agencyId: userData.agency.id,
                createdBy: userIdType,
                updatedBy: userIdType,
                isActive: true,
            };


            try {
                const createUser = await createUserByAdminRequest(payload);
                if (createUser.status === 208 || createUser.status === 200 || createUser.status === 500) {
                    setAtvBtn(false);
                    NotificationManager.error(
                        `${createUser.data.message}`,
                        "Error",
                        2500
                    );
                } else {

                    let credentialPayload = {
                        userid: createUser.data.data.id,
                        approval: true,
                        recruitmentpartnerId: userData?.recruitmentPartner?.id ? userData.recruitmentPartner.id : "",
                        roleId: userData.role.id,
                        agencyId: userData?.agency?.id ? userData.agency.id : ""
                    }
                    const sendUserEmailResponse = await approveRecruitersResponse(credentialPayload);
                    closeModel();
                    setIsRefreshNeeded(true);
                    NotificationManager.success("User added", "Success", 2500);
                    setAtvBtn(false);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }


    return (
        <>
            <Dialog
                onClose={(e) => closeModel()}
                aria-labelledby="customized-dialog-title"
                open={openAddModel}
                PaperProps={{
                    style: {
                        width: "600px",
                    },
                }}
            >
                <Grid item xs={12}>
                    <DialogTitle
                        id="customized-dialog-title"
                        className="admindailog_title_modal"
                        onClose={(e) => closeModel()}
                    >
                        Create User
                        <IconButton
                            aria-label="close"
                            style={closeButton}
                            onClick={(e) => closeModel()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                </Grid>
                <DialogContent className="userlist_dailog_content">
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="h2">
                            First Name*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                                placeholder="Enter First Name"
                                className="admin_textbox"
                                onChange={userFirstNameHandler}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="h2">
                            Middle Name
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                                placeholder="Enter Middle Name"
                                className="admin_textbox"
                                onChange={userMiddleInitialHandler}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="h2">
                            Last Name*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                                placeholder="Enter Last Name"
                                className="admin_textbox"
                                onChange={userLastNameHandler}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="h2">
                            Email*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                                className="admin_textbox"
                                placeholder="Enter Email ID"
                                value={userData?.email || ""}
                                onChange={userEmailHandler}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="h2">
                            Country*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <Autocomplete
                                id="combo-box-demo"
                                options={countryList}
                                autoHighlight
                                getOptionLabel={(option) =>
                                    option.name
                                        ? option.name
                                        : ""
                                }
                                value={userData?.country || {}}
                                onChange={userCountryChangeHandler}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required={true}
                                        placeholder="Please Select Country"
                                    />}
                            />
                        </FormControl>
                    </Grid>
                    &nbsp;
                    <Grid item xs={12} md={12} className="phone_input_userlist">
                        <Typography variant="body1" component="h2">
                            Phone Number*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <PhoneInput
                                international
                                className="textbox_phone_input_userlist"
                                defaultCountry={defaultCountryCode}
                                placeholder="Enter Phone Number"
                                value={phoneNumberInt}
                                onChange={(val) => handlePhoneNumberChange(val)}
                                onKeyPress={(e) => keypressHandler(e)}
                            />
                            {(phoneError) && (
                                <FormHelperText error>Please enter a valid phone number</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>


                    &nbsp;
                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" component="h2">
                            User Type*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                            <Autocomplete
                                autoSelect
                                id="combo-box-demo"
                                options={userRoleswithOutCandidate}
                                getOptionLabel={(option) => option.roleName || ""}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select User Type"
                                    />
                                )}
                                value={userData.role || {}}
                                onChange={userRoleHandler}
                            />
                        </FormControl>

                        &nbsp;
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" component="h2">
                                Recruitment Organization*
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    autoSelect
                                    id="combo-box-demo"
                                    options={allActiveRecruitmentPartner}
                                    getOptionLabel={(options) => options.name || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Recruitment Organization"
                                        />
                                    )}
                                    value={userData.recruitmentPartner || ""}
                                    onChange={recruitmentPartnerChangehandler}
                                />
                            </FormControl>
                        </Grid>
                        &nbsp;
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" component="h2">
                                Agency Type*
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    autoSelect
                                    id="combo-box-demo"
                                    options={agencies || []}
                                    getOptionLabel={(options) => options.name || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Agency"
                                        />
                                    )}
                                    value={userData?.agencyType || ""}
                                    onChange={userAgencyTypeHandler}
                                />
                            </FormControl>
                        </Grid>

                        &nbsp;
                        <Grid item xs={12} md={12}>
                            <Typography variant="body1" component="h2">
                                Agency Name*
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                    autoSelect
                                    id="combo-box-demo"
                                    options={allAgencyData || []}
                                    getOptionLabel={(options) => options.agencyName || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select Agency"
                                        />
                                    )}
                                    value={userData?.agency || ""}
                                    onChange={userAgencyHandeler}
                                />
                            </FormControl>
                        </Grid>


                        &nbsp;
                        <Typography gutterBottom>Is Active*</Typography>
                        <FormControl fullWidth variant="outlined">
                            <Autocomplete
                                autoSelect
                                id="combo-box-demo"
                                options={isActiveOption}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" />
                                )}
                                disabled
                                value={"True"}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>

                <Grid
                    item
                    xs={12}
                    md={12}
                    className="user_pop_button content-scrollable"
                >
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(e) => closeModel()}
                            className="btn3"
                        >
                            Cancel
                        </Button>

                        <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={addUserHandler}>
                            <div className="btn_loader">
                                Save
                                &nbsp;
                                {atvBtn &&
                                    <div >
                                        <CircularProgress size={13} />
                                    </div>
                                }
                            </div>
                        </button>

                    </DialogActions>
                </Grid>
            </Dialog>
        </>
    )
}

export default AddUserModel