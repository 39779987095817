import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid as KendoGrid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from '@material-ui/lab/Skeleton'
import { NotificationContainer } from "react-notifications";
import debounce from 'lodash/debounce';

import Header from "../../components/adminHeader/adminHeader";
import AddUserModel from "./AddUserModel";
import EditUserModel from "./EditUserModel";

import { getAgencyType } from "../../apis/agency/agencyApi";
import getActiveRoles from "../../apis/users/getActiveRoles";
import seachUser from "../../apis/searchUser";

import { UserTypeContext } from "../../AppRoute";
import "./userList.css";

const useStyles = makeStyles((theme) => ({
  odd: {
    background: '#F6F6F6 !important'
  },
  even: {
    background: '#fffff !important'
  },
}))

const UserList = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const [allRoles, setallRoles] = useState([]);
  const [userRoleswithOutCandidate, setUserRoleWithoutCandidate] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);;
  const [isSearching, setIsSearching] = useState(true);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles()
  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [agencies, setAgencies] = useState([]);

  const [openAddModel, setOpenAddModel] = useState(false)
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false)
  const [openEditModel, setOpenEditModel] = useState(false)
  const [editUserData, setEditUserData] = useState({})


  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const [dataResult, setDataResult] = useState([]);
  const isActiveOption = ["True", "False"];

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    marginTop: "10px",
    marginBottom: "20px",
  };

  const btnEdit = {
    cursor: "pointer",
    color: "#fff",
    backgroundColor: "#1885e6",
  };

  const fieldsToField = [
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "roleName",
    "isActive"

  ];

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        if (userType === "Admin") {
          await getAllRoles();
          await geAllAgencyType();
        }
      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }

    }
    fetchAllData()
  }, []);

  useEffect(() => {
    // it will run when there is no filter in kendo
    if (searchTerms.length <= 0) {
      let newsearchTerms = [];
      debouncedSearch(newsearchTerms, pageNumber, takeNumberOfResult);
    }

    // it will run when there is filter in kendo
    if (searchTerms.length > 0) {

      let searchTerm;
      // remove null undefined values of fields
      searchTerm = searchTerms.filter(obj => {
        if (
          fieldsToField.includes(obj.field) &&
          (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
          return false; // remove the object
        }
        return true; // keep the object
      });
      setDataState({
        skip: skipNumberOfResult,
        take: takeNumberOfResult,
        filter: {
          logic: "and",
          filters: searchTerm
        },
        sort: [
          {
            field: "",
            dir: "desc",
          },
        ],
      })
      debouncedSearch(searchTerm, pageNumber, takeNumberOfResult);
    }

  }, [searchTerms, pageNumber, takeNumberOfResult, isRefreshNeeded]);

  const dataStateChange = async (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    setIsSearching(true)
    setDataResult([])
    // const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const terms = filters && filters.length > 0
      ? filters
        .filter(({ field, value }) => (field === 'isActive' ? true : value && value !== '')) // Filter out objects where value is empty, null, or white spaces
        .map(({ field, value, operator }) => ({ field, value, operator }))
      : [];
    setSearchTerms(terms);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState)

  };

  const debouncedSearch = useCallback(
    debounce(async (searchTerms, page, size) => {
      setIsSearching(true);
      setDataResult([])
      const search = await seachUser(searchTerms, page, size);
      if (search.data.length <= 0) {
        setIsSearching(false);
        setDataResult(search.data);
        setTotalResult(search.count);
      }
      if (search.data.length > 0) {
        setDataResult(search.data);
        setTotalResult(search.count);
      }

    }, 2500),
    []
  );


  const getAllRoles = async () => {
    try {
      const allRoleResponse = await getActiveRoles();
      let roleArray = allRoleResponse.data.data;
      roleArray = roleArray.filter((item) => item.roleName !== "Candidate")
      setallRoles(allRoleResponse.data.data);
      setUserRoleWithoutCandidate(roleArray)

    } catch (error) {
      console.log(error);
    }

  };

  const openEditmodal = (event, userId, dataItem) => {
    let selectedUserData = {
      id: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      country: "",
      phoneNumber: "",
      countryCallingCode: "",
      role: "",
      recruitmentPartner: "",
      agencyType: "",
      agency: ""
    }
    selectedUserData.id = dataItem?.id
    selectedUserData.firstName = dataItem?.firstName
    selectedUserData.middleName = dataItem?.MiddleInitial
    selectedUserData.lastName = dataItem?.lastName
    selectedUserData.email = dataItem?.email
    selectedUserData.phoneNumber = dataItem?.phoneNumber
    if (dataItem?.profile?.locationCountry) {
      selectedUserData.country = JSON.parse(dataItem?.profile?.locationCountry)
    }
    if (dataItem?.countryCode) {
      selectedUserData.countryCallingCode = dataItem?.countryCode
    }
    selectedUserData.role = dataItem?.role
    if (dataItem?.role.roleName !== "Candidate") {
      selectedUserData.recruitmentPartner = dataItem?.recruitmentpartner
      if ((dataItem?.agency)) {
        let agencyType_edit = agencies.filter((item) => item.id === dataItem.agency.agencyTypeId)
        selectedUserData.agencyType = agencyType_edit[0]
        selectedUserData.agency = dataItem.agency
      }
    }
    selectedUserData.status = dataItem?.isActive ? "True" : "False"
    setEditUserData(selectedUserData)
    setOpenEditModel(true)
  };


  const geAllAgencyType = async () => {
    try {
      const allAgency = await getAgencyType();
      setAgencies(allAgency.data.data);

    } catch (error) {
      console.log(error);
    }

  };

  const getSkeleton = () => {
    let arr = []
    for (let i = 0; i < 11; i++) {
      arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="50px" />)
    }
    return arr;
  }

  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded && userType !== "Admin" ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <div className="userlist_mainContainer">
                    <div className="users_top_section_wrapper">
                      <div className="userlist_heading">Users</div>
                      <div>
                        <Button
                          variant="outlined"
                          style={btnGreen}
                          color="primary"
                          onClick={(e) => setOpenAddModel(true)}
                        >
                          Create User
                        </Button>
                      </div>
                    </div>
                    <div className="userlist_content_container">
                      <div className="userlist_kendogridContainer">
                        <KendoGrid
                          filter={dataState.filter}
                          filterable={true}
                          reorderable={true}
                          resizable={true}
                          pageable={{
                            buttonCount: 10,
                            info: true,
                            previousNext: true,
                            pageSizes: [10, 20, 50, 100],
                          }}
                          className="userlist_kendostyle"
                          total={totalResult}
                          skip={dataState.skip}
                          take={dataState.take}
                          data={dataResult}
                          onDataStateChange={dataStateChange}
                        >
                          <GridNoRecords>
                            {isSearching ? getSkeleton() : "No results found !"}
                          </GridNoRecords>
                          <Column
                            title="Edit"
                            filterable={false}
                            width="200px"
                            cell={(event) => {
                              return (

                                <td style={{ textAlign: "center" }}>
                                  <Button
                                    onClick={(e) => openEditmodal(e, event.dataItem.id, event.dataItem)}
                                    variant="contained"
                                    color="primary"
                                    style={btnEdit}
                                  >
                                    Edit
                                  </Button>
                                </td>
                              );
                            }}
                          />
                          <Column
                            field="firstName"
                            title="First Name"
                            width="250px"
                          />
                          <Column
                            field="lastName"
                            title="Last Name"
                            width="250px"
                          />
                          <Column
                            field="email"
                            title="Email"
                            width="300px"
                          />
                          <Column
                            field="phoneNumber"
                            title="Phone Number"
                            width="250px"
                          />
                          <Column
                            field="role.code"
                            title="User Type"
                            width="180px"
                          />

                          <Column
                            field="isActive"
                            title="Is Active"
                            width="180px"
                            filterable={true}
                            filter={"boolean"}
                            cell={(event) => {
                              return <td>{event.dataItem?.isActive ? "True" : "False"}</td>;
                            }}
                          />


                        </KendoGrid>
                      </div>


                    </div>
                  </div>
                ) : userType === "" ? (<div className="row padding_80 text-center">
                  <p className="fs-semi-bold fs-40 f-color">
                    Loading...
                  </p>
                </div>) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}

            <AddUserModel
              openAddModel={openAddModel}
              setOpenAddModel={setOpenAddModel}
              isRefreshNeeded={isRefreshNeeded}
              setIsRefreshNeeded={setIsRefreshNeeded}
              userRoleswithOutCandidate={userRoleswithOutCandidate}
              agencies={agencies}
              userIdType={userIdType}
              isActiveOption={isActiveOption}
            />
            <EditUserModel
              openEditModel={openEditModel}
              isRefreshNeeded={isRefreshNeeded}
              setOpenEditModel={setOpenEditModel}
              userRoles={editUserData?.role?.roleName !== "Candidate" ? userRoleswithOutCandidate : allRoles}
              agencies={agencies}
              userIdType={userIdType}
              editUserData={editUserData}
              isActiveOption={isActiveOption}
              setIsRefreshNeeded={setIsRefreshNeeded}
            />
            <NotificationContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;