import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllRecruitersResponse = async () => {
    let allResponse = {};
    try {
        const Response = await axios.get(
            jsonConfig.apiUrl + "Getallrecruiter",
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = Response;

        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr", error);
        return Promise.reject(error);
    }
}

export default getAllRecruitersResponse;