import React, { useState, useEffect } from "react";
import "./resume.css";
import pdfimage from "./images/pdf.png";
import docimage from "./images/doc.png";
import Groupcrossimg from "./images/Group 300.png";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import { FilePicker } from "react-file-picker";
import Modal from "react-modal";
import { Document, Page, pdfjs } from "react-pdf";
import LinkedIn from "./images/linkedIn.svg";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import FileViewer from "react-file-viewer";
import { makeStyles } from "@material-ui/core/styles";
import pencil from "../profileCompletion/images/pencil.png";
import profileResponse from "../../../apis/profile/profileById";
import updateProfile from "../../../apis/profile/updateProfile";

const useStyles = makeStyles((theme) => ({
  buttonAlignForRemove: {
    padding: "32px!important",
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0.5),
  },
});

const CloseAction = withStyles(styles)((props) => {
  const { classes, onClose } = props;
  return (
    <MuiDialogActions className={classes.root}>
      {onClose ? (
        <IconButton
          aria-label="close"
          // className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogActions>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    // padding: "0px 30px 0px 30px!important",
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const Resume = ({ setLastUpdate, userId}) => {
  const jsonConfig = require("../../../Config.json");
  const classes = useStyles();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [resumeData, setResumeData] = useState("");
  const [isResumeDataAvailable, setIsResumeDataAvailable] = useState(false);
  const [resumeUploadButton, setResumeUploadButton] = useState(false);
  const [resumeViewerModel, setResumeViewerModel] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [resumeUrl, setResumeUrl] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileType, setFileType] = useState("");
  const [loadingController, setLoadingController] = useState(false);
  const [openLinkedIn, setOpenLinkedIn] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [name, setName] = useState("");

  const getResumeData = async () => {
    // const authResponse = await instance.acquireTokenSilent({
    //   ...loginRequest,
    //   account: account,
    // });

    try {
      const getResumeResponse = await profileResponse(userId);

      setLastUpdate(getResumeResponse.data.data[0].lastUpdated)
      setName(getResumeResponse.data.data[0].user.firstName);
     
      if (
        getResumeResponse.data.data[0].resumeUrl ||
        getResumeResponse.data.data[0].referralResumeUrl
      ) {
        if( getResumeResponse.data.data[0].resumeUrl){
          let index = getResumeResponse.data.data[0].resumeUrl.lastIndexOf(".");
          let type = getResumeResponse.data.data[0].resumeUrl.substr(index + 1);
          setFileType(type);
        }else{
          let index = getResumeResponse.data.data[0].referralResumeUrl.lastIndexOf(".");
          let type = getResumeResponse.data.data[0].referralResumeUrl.substr(index + 1);
          setFileType(type);
        }
       
        setResumeData(getResumeResponse.data.data[0]);
        setIsResumeDataAvailable(true);
        setLoadingController(true);
      } else {
        setIsResumeDataAvailable(false);
        setLoadingController(true);
      }
      if (
        !getResumeResponse.data.data[0].videoIntroUrl == null ||
        !getResumeResponse.data.data[0].videoIntroUrl == ""
      ) {

      }
    } catch (error) {
      setIsResumeDataAvailable(false);
      setResumeData("");
      setLoadingController(true);
    }
  };
  const downloadResume = async (event, url) => {
    window.open(url, "_blank");
    setResumeUrl(url);
  };
  const viewResume = async () => {
    const sas = jsonConfig.sasToken;
    const bsClient = new BlobServiceClient(

      `${jsonConfig.ctResourcesCdn + sas}`

    );
    const containerClient = bsClient.getContainerClient("resume");
    const blobName = userId + "/" + "_Resume." + fileType;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    const downloadBlockBlobResponse = await blobClient.download();
    const downloadedUrl = URL.createObjectURL(
      await (
        await blobClient.download()
      ).blobBody
    );
    setResumeUrl(downloadedUrl);
  };
  const viewResumeinPop = async () => {
    await viewResume();
    setResumeViewerModel(true);
  };
  const removeResume = async () => {
    // const authResponse = await instance.acquireTokenSilent({
    //   ...loginRequest,
    //   account: account,
    // });
    var dt = new Date();
    var resumeDataRequest = {
      resumeUrl: "",
      resumeName: "",
      lastUpdated: dt,
    };
    try {
      const payload = {
        id: userId,
        data: resumeDataRequest,
      }
      const updateResumeResponse = await updateProfile(payload);
      NotificationManager.success("Resume deleted", "Success");
      await getResumeData();
      setIsResumeDataAvailable(false);
      setOpenDeleteDialogue(false);
    } catch (error) { }
  };

  const uploadToBlobStorage = async (blob) => {
    let firstName = ''
    try {
      const getResumeResponse = await profileResponse(userId);
      firstName = getResumeResponse.data.data[0].user.firstName;
    } catch (error) {
      setIsResumeDataAvailable(false);
      setResumeData("");
      setLoadingController(true);
    }
    const blobStorageAccount = jsonConfig.blobStorageAccountName;
    const sas = jsonConfig.sasToken;
    let decodedToken = decodeURI(sas);
    const bsClient = new BlobServiceClient(
      // `https://${blobStorageAccount}.blob.core.windows.net${sas}`
      `${jsonConfig.ctResourcesCdn + sas}`

    );
    let blobValue = blob.name.split(".")
    var v = blobValue[blobValue.length - 1];
    var c = firstName + "_Resume." + v;
    const containerClient = bsClient.getContainerClient("resume");
    const blobName = userId + "/" + c;
    const url = `${jsonConfig.ctResourcesCdn}/resume/${blobName}`;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    const response = await blobClient.uploadBrowserData(blob);
    var dt = new Date();
    const resumeDataRequest = {
      resumeUrl: url,
      resumeName: firstName + "_Resume",
      lastUpdated: dt,
    };
    try {
      const payload = {
        id: userId,
        data: resumeDataRequest,
      }
      const updateResumeResponse = await updateProfile(payload);
    } catch (error) {
      NotificationManager.error("Resume updation failed", "Error");
      setResumeUploadButton(false);
    }

    setResumeUploadButton(false);
    await getResumeData();
    NotificationManager.success("Resume uploaded", "Success");
  };
  const filePickerOnChange = async (file) => {
    await uploadToBlobStorage(file);
  };
  const openLinkedInModal = () => {
    setOpenLinkedIn(true);
  };
  const closeLinkedModal = () => {
    setOpenLinkedIn(false);
    setUrlValue("")
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "100%",
      width: "100%",
      overlfow: "scroll",
    },
  };
  const customDeleteModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const closeDeleteDialogue = () => {
    setOpenDeleteDialogue(false);
  };
  const openLinkInWinDow = (e, linkedIn) => {
    if (linkedIn) {
      window.open(linkedIn);
    }
  };
  useEffect(() => {
    // instance
    //   .acquireTokenSilent({
    //     ...loginRequest,
    //     account: account,
    //   })
    //   .then((response) => {
    getResumeData();
    // });
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [userId]);
  return (
    <>
      {loadingController ? (
        <>
          <div className="powres_card mt_10 powres_box" >
            <div className="row">
             
              <p className="mn_semibold fs-14 text-center f-color">Resume</p>
             

              {isResumeDataAvailable ? (

                <>
                  <div className="df mt_10 text-center"style={{justifyContent:"center"}}>
                    {fileType === "pdf" ? (
                      <div>
                        <img src={pdfimage} alt="" />
                      </div>
                    ) : (
                      <div>
                        <img src={docimage} alt="" />
                      </div>
                    )}
                    <div
                      className="fs-semi-bold fs-14 upload_text br_word"
                      onClick={(e) => { resumeData.resumeUrl ? downloadResume(e, resumeData.resumeUrl) : downloadResume(e, resumeData.referralResumeUrl) }}
                    >
                      {`${name}_Resume.${fileType}`}
                    </div>
                  </div>
                </>
              ) : 
               <p className="fs-regular fs-12 f-color mt_30 text-center">
                (Allowed file types: .pdf, .docx)
              </p> 
              }

              {isResumeDataAvailable && resumeData.resumeUrl ? (
                ""
              ) : (
                <>
                  <FilePicker
                    extensions={["pdf", "docx"]}
                    onChange={filePickerOnChange}
                    onError={(errMsg) => {
                      NotificationManager.error(errMsg, "Error");
                    }}
                  >
                    <button className="btn-resume-testimonial cp fs_manrope_12_btn mt_70">
                      Upload Resume
                    </button>
                  </FilePicker>
                </>
              )}
              {isResumeDataAvailable && resumeData.resumeUrl ? (
                <>
                  <FilePicker
                    extensions={["pdf", "docx"]}
                    onChange={filePickerOnChange}
                    onError={(errMsg) => {
                      NotificationManager.error(errMsg, "Error");
                    }}
                  >
                    <button className="btn-resume-testimonial mt_20 cp fs_manrope_12_btn">
                      Replace Resume
                    </button>
                  </FilePicker>
                </>
              ) : (
                ""
              )}
              {isResumeDataAvailable && resumeData.resumeUrl ? (
                <>
                  <button
                    className=" btn-resume-testimonial mt_10 cp fs_manrope_12_btn"
                    onClick={() => setOpenDeleteDialogue(true)}
                  >
                    Remove Resume
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <Dialog
              open={openDeleteDialogue}
              onClose={closeDeleteDialogue}
              aria-labelledby="customized-dialog-title"
            >
              <DialogContent
                className={`${classes.bodyForRemove} removemodal_responsive`}
              >
                <div className="text-center fs-semi-bold f-color fs-18">
                  Are you sure want to delete ?
                </div>
              </DialogContent>
              <DialogActions
                className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}
              >
                <div className="remove_modal">
                  <button
                    type="button"
                    className="btn_remove btn-skill cp"
                    onClick={closeDeleteDialogue}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn2 btn_powres ml_5 cp"
                    onClick={removeResume}
                  >
                    Yes
                  </button>
                </div>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openLinkedIn}
              onClose={closeLinkedModal}
              aria-labelledby="customized-dialog-title"
              className="linkedin-modal-dialog"
              maxWidth="sm"
            >
              <DialogContent className={`${classes.bodyForRemove} `}>
                <div className="ml_5 fs-semi-bold f-color fs-18">
                  LinkedIn URL
                </div>
              </DialogContent>
              <DialogActions
                className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}
              >
                <div className="linkedin-modal-url-container">
                  <div className="linkedin-modal-url-container">
                    {/* <label className="field_name email-label-form-one" htmlFor="email">Linked In URL</label> */}
                    <input
                      type="text"
                      className="text_box_cleartalent_form1 mb_10"
                      id="email"
                      name="linkedIn"
                      placeholder="Enter LinkedIn URL"
                      value={urlValue}
                      onChange={(e) => setUrlValue(e.target.value)}
                    />
                  </div>
                  <div className="remove_modal">
                    <button
                      type="button"
                      className="btn-big btn-skill cp"
                      onClick={closeLinkedModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn-big btn-green ml_10 cp"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </DialogActions>
            </Dialog>
            <NotificationContainer />
          </div>
        </>
      ) : (
        <>
          <div className="powres_card mt_10 powres_box">
            <div className="row">
              <Skeleton variant="text" animation="wave" />
              <div className="df mt_6">
                <div className="w-100 ml_10">
                  <Skeleton variant="text" animation="wave" />
                </div>
              </div>
              <Skeleton variant="text" animation="wave" />

              <div className="text-right remove-btn-design type_a mt_10 float_none">
                <Skeleton variant="text" animation="wave" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Resume;
