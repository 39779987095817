import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import "./header.css";
import Logo from "../../../../components/basicInfoForm/images/logo_recruiter_header.png"
import axios from "axios";
import { useHistory } from "react-router";
import support from "./images/support.png";
import { loginRequest } from "../../../../authConfig";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import GeneralSupportModal from "../../../../components/generalSupportModal/generalSupportModal";
import Logout from "../../../../resources/images/logout.png";
import ShareIcon from '@material-ui/icons/Share';
import cancelemailicon from "../../../../components/header/images/cancelemail.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Search from "../../../../components/header/images/search-resized.png";
import getUsersById from "../../../../apis/users/getUserById";
import powerResumeSharingResponse from "../../../../apis/sharePoweResume/powerResumeSharing";
import validateEmail from "../../../../utilities/validateEmail";
import Ellipse from "./images/Ellipse.png";
import { Box, Card, CardActions, ClickAwayListener, Grid, Menu, MenuItem, MenuList, TextField } from "@material-ui/core";
import { Button } from 'react-bootstrap'
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import MenuIcon from '@material-ui/icons/Menu';
import { PersonAddTwoTone } from "@material-ui/icons";
import track from "../../../../components/basicInfoForm/images/track.png"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
  btnCancel: {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFFFFF",
    color: "#1885E6",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    fontSize: '15px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#D1E8FF"
    }
  },
  btnSave: {
    width: "120px",
    height: "32px",
    backgroundColor: "#1885E6",
    fontSize: '15px',
    color: "#FFFFFF",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8"
    }
  },
  mailText: {
    marginLeft: "-12px",
    fontSize: "25px",
    height: "30px"
  }
}));
const Header = (props) => {
  const classes = useStyles();

  const { instance, accounts } = useMsal();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const jsonConfig = require("../../../../Config.json");
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const [mailValidate, setMailValidate] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [modalForShare, setmodalForShare] = useState(false);

  const [mailData, setMailData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [navOpen, setnavOpen] = useState(false);
  const [dismail, setdismail] = useState(true);
  const [allmailforshare, setallmailforshare] = useState([]);
  const inputemail = useRef(null);
  const shareMessageRef = useRef(null);
  const [sharedMessage, setSharedMessage] = useState("");
  const [errorEmailMessage, setErrorEmailMessage] = useState(false);
  const [mailInput, setMailInput] = useState("");
  const [isSharedHeader, setisSharedHeader] = useState(false);
  const userId = localStorage.getItem("userID");
  const [value, setValue] = useState("");

  const hamburger = useRef(0);
  const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false)
  const [count, setcount] = useState(0)
  const [arr1, setarr1] = useState([])
  const [arr2, setarr2] = useState([])
  const [showmail, setshowmail] = useState(true);
  const [maillength, setmaillength] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [isNavigationOpen, setisNavigationOpen] = useState(false);
  const [isInvitable, setIsInvitable] = useState(props.isInvitable)

  const handleReset = () => {
    setValue("");
  };

  const goToSearch = () => {
    history.push("/search");
  };
  const emailHandler = (e) => {
    inputemail.current = e;
  };
  const openShare = async () => {
    setmodalForShare(true);
    allmailforshare.current = [];
    setSharedMessage("");
  };
  const mailclose = () => {
    setshowmail(false);
  }
  const mailopen = () => {
    setshowmail(true);

  }
  var tempCount = 0;
  var mailct = allmailforshare.length;
  const deleteEmailHandler = (i) => {
    if (i > -1) {
      allmailforshare.splice(i, 1);
    }
    setallmailforshare((prev) => [...prev]);
    {
      allmailforshare.map((value, i) => { tempCount = tempCount + value.length }
      )
      tempCount = tempCount + ((mailct - 1) * 10)
      if (tempCount > 80) {
        setcount(tempCount)
      }
      else {
        setmaillength(mailct - 2)
      }
      setcount(tempCount)

    }
  };
  const addEmailHandler = async (value) => {
    const mailAddress = mailInput;
    const local_email = validateEmail(mailInput);
    setValue(value)
    setdismail(true);
    if (
      local_email &&
      (mailAddress.includes("gmail") ||
        mailAddress.includes("outlook") ||
        mailAddress.includes("yahoo") ||
        mailAddress.includes("hotmail"))
    ) {
      NotificationManager.error(`you can only share PoweResume to a corporate email address`,
        "Error");
    }
    else if (local_email) {

      var temp = 0;
      for (var i = 0; i <= allmailforshare.length; i++) {
        if (mailInput == allmailforshare[i]) {
          temp++;
        }
      }
      if (temp == 0) {
        emailCheak();
        await setallmailforshare((prev) => [...prev, mailInput]);
      }
      else {
        NotificationManager.error("Email Already Exist ", "Error");
      }
      setMailInput("");
      handleReset();
    } else {
      NotificationManager.error("Please Enter Correct Email", "Error");
    }
  };
  const setshareMessage = (e) => {
    shareMessageRef.current = e;
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMailValidate(false);
  };
  var sum = 0;

  const trimEmail = (value) => {
    sum = sum + value.length;
    if (sum < 20)
      return value;
    else {
      mailopen();
    }
  }
  const emailCheak = () => {
    var tempo = allmailforshare.length;
    let countEmail = count + mailInput.length + 10;
    setcount(countEmail)
    if (countEmail > 80) {
      setshowmail(false)
    }
    else {
      setmaillength(tempo)
    }


  }

  const messageHandler = (e, value) => {
    mailclose();
  }


  const sharePoweResumeEmail = async () => {
    const pathNameSplit = window.location.pathname.split("/");
    const id = pathNameSplit[3];
    const mailBody = sharedMessage;
    const recruiterId = pathNameSplit[2] === "shared" ? "" : userId;
    if (allmailforshare.length > 0) {
      try {
        let payload = {
          recruiterId: recruiterId,
          ProfileId: props.candidateId,
          EmailIDs: allmailforshare,
          Iscandidate: false,
          comments: mailBody,
          createdBy: recruiterId,
          updatedBy: recruiterId,
          candidateEmailId: props.emailId
        };
        const shareResumeResponse = await powerResumeSharingResponse(payload);
        setmodalForShare(false);
        setallmailforshare([]);

        NotificationManager.success(
          "This PoweResume has been shared",
          "Success"
        );
      } catch (err) {
        console.log(err);
        NotificationManager.error(
          "Error sending email. Please try again after some time.",
          "Error"
        );
      }
    } else {
      NotificationManager.error(
        "Enter atleast one email address and a message",
        "Error"
      );
    }
  };
  const handelcloseshare = () => {
    setMailInput("");
    setmodalForShare(false);
    setallmailforshare([]);
    setErrorEmailMessage(false);
  };

  const openSupport = () => {
    setOpen(true);
  };

  const _modalClose = () => {
    setOpen(false);
  };



  const hamburgerClicked = () => {
    setHamburgerIsClicked(!hamburgerIsClicked)
  }



  const handleClickmenulist = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInvite = () => {
    if (props.emailId) {
      history.push({
        pathname: `/invite-candidates/${props.emailId}`,
      });
    }
  }

  const divClose = () => {
    setisNavigationOpen(false);
  }



  return (
    <>
      <div className="nav_bg header_fixed profile_headshadow">
        <div className="nav_bg_home home-specific-header">
          <div className="container w1200 nav_bg_home_section">
            <div className="nav_bg_left">
              <li className='cp headerListStyleNone'>
                <a><img src={Logo} className="nav_logo_home" alt='img' /></a>
              </li>
            </div>

            {props.poweResumeAvialable ? (

              <div className="account_nav_header" onClick={(e) => setisNavigationOpen(!isNavigationOpen)}>
                <IconButton onClick={handleClickmenulist}>
                  <MenuIcon />
                </IconButton>

                {isNavigationOpen ? (
                  <ClickAwayListener onClickAway={divClose}>
                    <div className="profile_drop_menu_header">
                      <Card>

                        <CardActions >
                          <MenuList>
                            <MenuItem onClick={openShare}>
                              <div className="sharecontainer_header">
                                <div className="shareIcon_header"><ShareIcon /></div>
                                <div className="shareText_header">Share</div>
                              </div>
                            </MenuItem>
                            {isInvitable ?
                              <MenuItem onClick={handleInvite}>
                                <div className="sharecontainer_header">
                                  <div className="shareIcon_header"><PersonAddTwoTone /></div>
                                  <div className="shareText_header">Invite Candidate</div>
                                </div>
                              </MenuItem>
                              : ""}
                             
                          </MenuList>
                        </CardActions>
                      </Card>

                    </div>
                  </ClickAwayListener>
                ) : null}
              </div>
            ) : null}

          </div>
        </div>

        <div className='nav_bg_home_responsive'>
          <div className="nav_bg_home_responsive-wrapper">
            <div className="home-top-nav">
              <li className='cp headerListStyleNone'>
                <a><img src={Logo} className="nav_logo_home" alt='img' /></a>
              </li>
              {props.poweResumeAvialable ? (
                <>
                  <div className={hamburgerIsClicked ? "hamburgerIsClicked" : "hamburgerIsNotClicked"} ref={hamburger}>
                    <ul className="navbar_home-responsive">
                      <li onClick={openShare}>
                        <div className="sharecontainer_header">
                          <div className="shareIcon_header"><ShareIcon /></div>
                          <div className="shareText_header">Share</div>
                        </div>
                      </li>
                      {isInvitable ?
                        <li onClick={handleInvite}>
                          <div className="sharecontainer_header">
                            <div className="shareIcon_header"><PersonAddTwoTone /></div>
                            <div className="shareText_header">Invite Candidate</div>
                          </div>
                        </li>
                        : ""}

                    </ul>
                  </div>
                  <a className="icon" onClick={hamburgerClicked}>
                    <i className="fa fa-bars"></i>
                  </a>
                </>) : null}
            </div>
          </div>
        </div>

        <Dialog
          open={modalForShare}
          onClose={handelcloseshare}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle className="support_dialog_title">
            <p id="para">Share this </p>
            <div className="support_lin">PoweResume</div>
            <img src={Ellipse} alt="img" className="blue" />
          </DialogTitle>

          <DialogContent className="support_con">
            <div className="support_header">
              <Grid container className="support_grid">
                <Grid item xs={12}>
                  <div className="support_form_leb mt_18 mb_3 profile_support">
                    Add Email Id*
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {allmailforshare.map((value, i) =>
                    showmail ? (
                      <span className="mail_bg ">
                        <span className="mail_txt">{value.toLowerCase()}</span>
                        <span className="mail_close_icon cp">
                          <img
                            src={cancelemailicon}
                            onClick={(e) => deleteEmailHandler(i)}
                            height="13px"
                            width="13px"
                          />
                        </span>
                      </span>
                    ) :
                      (
                        (maillength + 1) > i ?
                          (

                            <span className="mail_bg">
                              <span className="mail_txt">{value.toLowerCase()}</span>
                              <span className="mail_close_icon cp">
                                <img
                                  src={cancelemailicon}
                                  onClick={(e) => deleteEmailHandler(i)}
                                  height="13px"
                                  width="13px"
                                />
                              </span>
                            </span>
                          ) : (
                            <div onClick={mailopen} className="mail_more">
                              {i === (maillength + 1) ?
                                (
                                  <span className="mail_b" >
                                    <span className="cp">{(allmailforshare.length - 1) - maillength}{" more"}</span>
                                  </span>
                                )
                                : ("")
                              }
                            </div>
                          )
                      )
                  )}

                  <Autocomplete
                    id="tags-filled"
                    options={[]}
                    disableClearable
                    freeSolo
                    value={value}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                    }}
                    filterSelectedOptions
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          title: `${params.inputValue}`,
                        });
                      }
                      return filtered;
                    }}
                    onChange={() => addEmailHandler()}
                    renderOption={(option) => (
                      <li className="cp">{option.inputValue}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="classes.mailText"
                        value={mailInput}
                        onChange={(e) => setMailInput(e.target.value.trim())}
                        placeholder="Add Mail"
                      />
                    )}
                  />

                </Grid>
                <Grid item xs={12} className="support_grid_message">
                  <div className="support_form_leb mb_10">Add a message</div>
                </Grid>
                <Grid item xs={12}>
                  <textarea
                    type="text"
                    className="support_message"
                    rows={4}
                    onChange={(e) => setSharedMessage(e.target.value)}
                    onClick={messageHandler}
                  />
                  {errorEmailMessage ? (
                    <p className="form-error fs-regular">
                      Please enter a message
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </div>
          </DialogContent>

          <DialogActions className={`${classes.buttonForSupport} `}>
            <Button className={classes.btnCancel} onClick={handelcloseshare}>Cancel</Button>
            <Button className={classes.btnSave} onClick={sharePoweResumeEmail}>Share</Button>
          </DialogActions>

        </Dialog>
        <Snackbar
          open={mailValidate}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            className="remove-snackbar-alert"
          >
            Please enter your question.
          </Alert>
        </Snackbar>
      </div >
      <NotificationContainer />
    </>
  );
};
export default Header;
