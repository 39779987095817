import React, { useState, useEffect } from 'react';
import Header from '../home/homeHeader/Header/Header';
import Logo from '../home/images/logo.png';
import './candidateSignIn.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import axios from "axios";
import { useHistory } from "react-router";
import { withRouter } from 'react-router-dom';
import { set } from 'date-fns';
import { Tooltip } from 'react-bootstrap';
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import Footer from '../../components/adminFooter/Footer';
import resetPasswordOtpResponse from '../../apis/password/resetPasswordOtp';
import resetPasswordVerifyResponse from '../../apis/password/resetPasswordVerify';
import resetPasswordResponse from '../../apis/password/resestPassword';
import customLoginResponse from "../../apis/login/customLogin";
import changePasswordResponse from '../../apis/password/changePassword';
import validateEmail from '../../utilities/validateEmail';

const passwordValidationMsg = "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
const passwordMismatchMsg = "Please make sure your passwords match";
const CandidateSignIn = (props) => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const jsonConfig = require("../../Config.json");
  const history = useHistory();
  const [forgotPwd, setForgotPwd] = useState(false);
  const [enableVerifyOtp, setEnableVerifyOtp] = useState(false);
  const [verifyEmailBtn, setVerifyEmailBtn] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrorPwd, setFormErrorPwd] = useState(false);
  const [formErrorCmPwd, setFormErrorCmPwd] = useState(false);
  const intialForgotPwdScreen = {
    email: '',
    newPwd: '',
    verifyOtp: '',
    confirmPwd: ''
  }
  const [forgotpwdScreen, setForgotpwdScreen] = useState(intialForgotPwdScreen);
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(300000);
  const [newPasswordValidateErr, setNewPasswordValidateError] = useState(false);
  const [newCmPasswordValidateErr, setNewCmPasswordValidateError] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isResetRequired, setIsResetRequired] = useState(false);
  const [resetRequiredNewPwd, setResetRequiredNewPwd] = useState('');
  const [resetRequiredCmPwd, setResetRequiredCmPwd] = useState('');
  const [resetRequiredNewPwdValidateErr, setResetRequiredNewPwdValidateErr] = useState(false);
  const [resetRequiredCmPwdValidateErr, setResetRequiredCmPwdValidateErr] = useState(false);
  const [userId, setUserId] = useState('');
  const [disableOtp, setDisableOtp] = useState(true);
  const [onRetypePwdValidate, setOnRetypePwdValidate] = useState(false)

  const fpHandler = () => {
    setForgotPwd(true);
  }
  const bacBtn = () => {
    setForgotPwd(false);
    setFormError(false);
    setFormErrorPwd(false);
    setFormErrorCmPwd(false);
    setEnableVerifyOtp(false);
    setVerifyEmailBtn(false)
    setCounter(300000);
    setIsActive(false);
    setForgotpwdScreen(intialForgotPwdScreen);
    setIsPasswordReset(false);
    setOtpVerified(false);
    setEmail('');
    setPwd('');
    setIsResetRequired(false);
  }

  const goToTerms = (e) => {
    window.open("/terms-of-use", "_self");
  }
  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy", "_self");
  }

  const sendOtp = async () => {

    const payloadData = {
      email: forgotpwdScreen.intialForgotPwdScreen.email,
    }
    try {
      const resetpassworotpData = await resetPasswordOtpResponse(payloadData);
      if (resetpassworotpData.data.message.includes('We do not have an account in ClearedTalent')) {
        return (
          NotificationManager.error("We do not have an account in ClearedTalent with this email address", "Error", 2500)
        )
      } else {
        NotificationManager.success("OTP Sent", "Success", 2500)
        setEnableVerifyOtp(true);
        setIsActive(!isActive);
      }

    } catch (error) {
      console.log(error);

    }
    setDisableOtp(false);

  }
  const verifyOtpReq = async () => {
    const payloadData = {
      email: forgotpwdScreen.intialForgotPwdScreen.email,
      otp: forgotpwdScreen.intialForgotPwdScreen.verifyOtp,
    }
    try {
      const resetpasswordverifyData = await resetPasswordVerifyResponse(payloadData);

      if (resetpasswordverifyData.data.message.includes('Invalid OTP')) {
        return (
          NotificationManager.error("Please enter valid OTP", "Error", 2500)
        )
      } else {
        setCounter(300000);
        setIsActive(false);
        setEnableVerifyOtp(false);
        setOtpVerified(true)
        return (
          NotificationManager.success("OTP verified", "Success", 2500)
        )
      }

    } catch (error) {
      console.log(error);

    }
  }

  // Password Validation Regex

  const validatePassword = (password) => {
    const regX =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (regX.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const fpEmailHandle = (e) => {
    setFormError(true);
    const email = e.target.value;
    setForgotpwdScreen((prev) => ({
      intialForgotPwdScreen: {
        ...prev.intialForgotPwdScreen,
        email
      }
    }))

    const emailValidate = validateEmail(email);
    if (emailValidate && email) {
      setVerifyEmailBtn(true);
    } else {
      setVerifyEmailBtn(false);
    }
  }

  const fpNewPwdHandle = (e) => {
    setFormErrorPwd(true);
    const newPwd = e.target.value;
    setForgotpwdScreen((prev) => ({
      intialForgotPwdScreen: {
        ...prev.intialForgotPwdScreen,
        newPwd
      }
    }))
    const passwordValidate = validatePassword(newPwd);
    if (passwordValidate && newPwd) {
      setNewPasswordValidateError(true)
    } else {
      setNewPasswordValidateError(false);
    }
  }

  const fpNewConfirmPwdHandle = (e) => {
    setFormErrorCmPwd(true);
    const confirmPwd = e.target.value;
    setForgotpwdScreen((prev) => ({
      intialForgotPwdScreen: {
        ...prev.intialForgotPwdScreen,
        confirmPwd
      }
    }))
    if (confirmPwd && forgotpwdScreen?.intialForgotPwdScreen?.newPwd === confirmPwd) {
      setNewCmPasswordValidateError(true);
    } else {
      setNewCmPasswordValidateError(false);
    }
  }

  const fpOtpHandle = (e) => {
    const verifyOtp = e.target.value;
    setForgotpwdScreen((prev) => ({
      intialForgotPwdScreen: {
        ...prev.intialForgotPwdScreen,
        verifyOtp
      }
    }))
  }

  const resetPasswordReq = async () => {
    const payloadData = {
      email: forgotpwdScreen.intialForgotPwdScreen.email,
      newpassword: forgotpwdScreen.intialForgotPwdScreen.newPwd,
    }
    try {
      const resetPasswordReqData = await resetPasswordResponse(payloadData);
      if (resetPasswordReqData.data.message.includes('Password Updated succerssfully')) {
        setIsPasswordReset(true);
        return (
          NotificationManager.success("Password Updated", "Success", 2500)
        )
      }

    } catch (error) {
      console.log(error);

    }
  }
  const signIn = async () => {
    if (!email || !pwd) {
      NotificationManager.error("Please enter your email and password", "Error", 2500)
      return;
    }

    const payloadData = {
      email: email,
      password: pwd
    }
    try {
      const route = jsonConfig.ip_address
      let ipAddress = ""; // Default to empty string
      try {
        const ip_address = await axios.get(route);
        ipAddress = ip_address.data.ip; // If successful, assign the IP
      } catch (innerError) { // The inner try-catch handles the specific IP fetching error
        ipAddress = "incognito"; // Ensure it remains an empty string if it fails
      }

      // Add the IP address to the payload
      const updatedPayload = {
        ...payloadData,
        ipAddress: ipAddress, // Add IP to payload
      };
      const customLoginData = await customLoginResponse(updatedPayload);

      if (customLoginData.data.message.includes('Invalid Password')) {
        return (
          NotificationManager.error("Incorrect username or password", "Error", 2500)
        )
      }
      if (customLoginData.data.message.includes("We Couldn’t find a ClearedTalent account associated with this email. Please try again")) {
        return (
          NotificationManager.error("We Couldn't find a ClearedTalent account associated with this email. Please try again", "Error", 2500)
        )
      }
      if (customLoginData.data.message.includes('Your account is not active')) {
        return (
          NotificationManager.error("Your account is not active. Please contact the Administrator", "Error", 2500)
        )

      }
      if (customLoginData.data.message.includes('Your Recruitment Organisation has been deactivated. Contact support@clearedtalent.com for further assistance.')) {
        return (
          NotificationManager.error("Your Recruitment Organisation has been deactivated. Contact support@clearedtalent.com for further assistance.", "Error", 5000)
        )

      }
      else {

        var token = customLoginData.data.token

        validateResetRequired(customLoginData.data.data, token);
        //   history.push({
        //      state: customLoginData.data.data.id
        // })

      }

    } catch (error) {
      console.log(error);
      NotificationManager.error("Unable to login, Please click on 'Forgot Password' or contact ClearedTalent support", "Error", 2500)
    }
  }

  // const handleEnter = (e) => {
  //   if (e.keyCode === 13) {
  //     console.log('e.keyCode----->', e.keyCode)
  //     signIn()
  //   }
  // }




  const rrNewPwdHandle = (e) => {
    let newPwd = e.target.value;
    setResetRequiredNewPwd(newPwd);
    if (newPwd && validatePassword(newPwd)) {
      setResetRequiredNewPwdValidateErr(true)
    } else {
      setResetRequiredNewPwdValidateErr(false);
    }
  }

  const rrConfirmPwdHandle = (e) => {
    let cmPwd = e.target.value;
    setResetRequiredCmPwd(e.target.value);
    if (cmPwd && resetRequiredNewPwd === cmPwd) {
      setResetRequiredCmPwdValidateErr(true);
      setOnRetypePwdValidate(true)
    } else {
      setResetRequiredCmPwdValidateErr(false);
      setOnRetypePwdValidate(false)
    }
  }

  const validateResetRequired = async (user, token) => {
    if (user?.IsResetRequired) {

      setIsResetRequired(true);
      setUserId(user.id);
    }
    // else {
    //   localStorage.setItem('userID', user.id);
    //   props.pareentCallback(token)
    //   // window.location.pathname = "/landing";
    //   history.push("/landing")
    // }
    else {

      if (token != undefined) {
        localStorage.setItem("token", token)
      }
      localStorage.setItem('userID', user.id);
      props.pareentCallback(token);
      if (props?.location?.state?.isRedrictedFromIvite) {
        // history.push("/invitedtest-landing")
        history.push({
          pathname: "/invitedtest-landing",
          state: {
            ...props?.location?.state
          },

        })

      }
      else {
        // window.location.pathname = "/landing";
        history.push("/landing")

      }

    }

  }

  const changePasswordReq = async () => {
    const payloadData = {
      userid: userId,
      password: pwd,
      newpassword: resetRequiredNewPwd
    }
    try {
      const changePasswordReqData = await changePasswordResponse(payloadData);
      if (changePasswordReqData.data.message.includes('Congrats! you have sucessfully Updated password.')) {
        setIsPasswordReset(true);
      }

    } catch (error) {
      console.log(error);

    }
  }

  const submitHandler = (e) => {
    e.preventDefault();
  }

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? "0" : "") + (seconds === 0 ? "00" : seconds);
  };

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {

        if (counter > 0) {
          setCounter((counter) => counter - 1000);
        }

      }, 1000);
    }

    return () => clearInterval(intervalId);  ///componentWillUnmount here mounting phase is distroyed
  }, [isActive, counter]);

  useEffect(() => {
    if (localStorage.getItem("role")) {
      history.push("/")
    }
  }, [])

  return (
    <>
      <div className="footerflex candidate-signin-wrapper">
        <div className="singin_box">
          <Header />
        </div>
        <div>

          {
            forgotPwd ?
              <>

                {!otpVerified ?
                  (
                    <div className="login_wrapper_main">

                      <div className='reset-password-container  login-box-border'>
                        <div className='candidate-signin-title'>
                          <p className='fs-semi-bold fs-22'>Reset your password</p>
                        </div>
                        <div>
                          <label className="field_name email-label-form-one" htmlFor="email">Email:
                            {/* <span className='email-label-notice'>(A One-Time Password (OTP) will be sent to your email address)</span> */}
                          </label>
                          <div className='fp-email-wrapper'>
                            <div className='fp-email-with-err'>
                              <input type="text" id="email" name="fpEmail"
                                className="mb_20 ht_47 emailStyle"
                                disabled={enableVerifyOtp}
                                value={forgotpwdScreen?.intialForgotPwdScreen?.email ?? ''}
                                onChange={fpEmailHandle}
                                required
                              />
                              {
                                formError ?
                                  <>
                                    {verifyEmailBtn ?
                                      ''
                                      :
                                      <>
                                        <p className='form-error fs-regular'>Please enter a valid email address</p>
                                      </>
                                    }
                                  </>
                                  :
                                  ''
                              }
                            </div>
                            <div className='formone-otpbtnswitch'>
                              {!enableVerifyOtp &&
                                <button onClick={sendOtp} disabled={verifyEmailBtn ? false : true} >
                                  Send OTP
                                </button>
                              }

                              {
                                enableVerifyOtp &&
                                <>
                                  {counter ?

                                    <button>{millisToMinutesAndSeconds(counter)} </button>
                                    :
                                    <button onClick={sendOtp}>
                                      Resend OTP
                                    </button>
                                  }


                                </>
                              }
                            </div>
                          </div>

                          {
                            // enableVerifyOtp &&
                            <>
                              <div className="mt_34">
                                <div className='resetPwdOTPText'> <label htmlFor="otp-in"><b>OTP:</b></label></div>
                                <div className="otp_sec">
                                  <div className="w-70">
                                    <div id="otp-holder2">
                                      <div id="otp-content2">
                                        <input id="otp-in" type="tel" maxLength="6"
                                          value={forgotpwdScreen?.intialForgotPwdScreen?.verifyOtp ?? ''}
                                          onChange={fpOtpHandle}
                                          required
                                          disabled={disableOtp ? true : false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="send_otp">
                                    <button className="ml_10" disabled={!enableVerifyOtp} onClick={verifyOtpReq}>
                                      Verify OTP
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )
                  :
                  <>
                    {
                      !isPasswordReset ?
                        (
                          <form onSubmit={submitHandler}>
                            <div className="login_wrapper_main">
                              <div className='reset-password-container  login-box-border'>
                                <div className='candidate-signin-title'>
                                  <p className='fs-semi-bold fs-22'>Change your password</p>
                                </div>
                                <div>
                                  <label className="field_name email-label-form-one" htmlFor="email">New Password ({passwordValidationMsg}):</label>
                                  <input type="password" id="email" name="fpNewPwd"
                                    value={forgotpwdScreen?.intialForgotPwdScreen?.newPwd ?? ''}
                                    disabled={newCmPasswordValidateErr}
                                    onChange={fpNewPwdHandle}
                                    required
                                  />

                                  {
                                    formErrorPwd ?
                                      <>
                                        {newPasswordValidateErr ?
                                          ''
                                          :
                                          <>
                                            <p className='form-error fs-regular'>{passwordValidationMsg}</p>
                                          </>
                                        }
                                      </>
                                      :
                                      ''
                                  }
                                </div>
                                <div className="mt_20">
                                  <label className="field_name email-label-form-one" htmlFor="email">Retype Password:</label>
                                  <input type="password" id="email" name="fpNewConfirmPwd"
                                    value={forgotpwdScreen?.intialForgotPwdScreen?.confirmPwd ?? ''}
                                    onChange={fpNewConfirmPwdHandle}
                                    required
                                  />
                                  {
                                    formErrorCmPwd ?
                                      <>
                                        {newCmPasswordValidateErr ?
                                          ''
                                          :
                                          <>
                                            <p className='form-error fs-regular'>{passwordMismatchMsg}</p>
                                          </>
                                        }
                                      </>
                                      :
                                      ''
                                  }
                                  <div className='candidate-signin-btn-fp-screen'>
                                    {/* <button
                          onClick={bacBtn}
                        >Cancel</button> */}
                                    <button
                                      onClick={resetPasswordReq}
                                      disabled={!newPasswordValidateErr || !newCmPasswordValidateErr}
                                    >Reset Password</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        )
                        :
                        (
                          <div className='message-container  login-box-border'>
                            <div className='candidate-signin-title'>
                              <p className='fs-semi-bold fs-22'>Your password has been changed!</p>
                            </div>
                            <div className='mt_34'>
                              <button
                                className="full-width signInBtnAfterReset"
                                onClick={bacBtn}
                              >Sign-In</button>
                            </div>
                          </div>
                        )
                    }
                  </>
                }
              </>
              :
              <>
                {!isResetRequired ?
                  (
                    <form onSubmit={submitHandler}>
                      {
                        props?.location?.state?.isRedrictedFromIvite ?
                          <div className='login_wrapper_main error_message' >
                            <p className='cleartalent_sign_top_errormsg_text'>You already have a clearedtalent account. To take the test you need to login to your account.</p>
                          </div>
                          :
                          <div className='mt_29'></div>
                      }

                      <div className="login_wrapper_main">

                        <div className="login_container login-box-border login_control">
                          <div className='container'>
                            <div className='candidate-signin-title'>
                              <p className='recruiter_signin_header'>Sign-In</p>
                            </div>
                            <div>
                              <label htmlFor="email" className='field_name_reccruiter'>Email:</label>
                              <input type="text" id="email" className='text_box_cleartalent_form_recruiter' placeholder="Enter Your Email" name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // onKeyPress={handleEnter}
                                required
                              />
                            </div>
                            <div className="mt_34">
                              <label htmlFor="psw" className='field_name_reccruiter'>Password:</label>
                              <input type="password" className='text_box_cleartalent_form_recruiter' placeholder="Enter Your Password" id="email" name="email"
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                                // onKeyPress={handleEnter}
                                required
                              />
                              <div className="clear_login_remember_sec mt-10">
                                {/* <div className="form-group1 ">
                        <input type="checkbox" id="html" />
                        <label for="html" className="pr">Remember Me</label>
                      </div> */}

                                <label className="fgtext cp" onClick={fpHandler}>
                                  Forgot password?
                                </label>
                              </div>

                              <div className='mt_34 recruiter_signin'>
                                <button className="full-width btn_sign_up_recruiter btn-blue_sign_up_recruiter" type="submit"
                                  onClick={signIn}
                                // disabled={!email || !pwd}
                                >Sign-In</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )
                  :
                  <>
                    {/* Reset Required Password */}
                    {!isPasswordReset ?
                      (
                        <form onSubmit={submitHandler}>
                          <div className="login_wrapper_main">
                            <div className='reset-password-container login-box-border'>
                              <div className='candidate-signin-title'>
                                <p className='fs-semi-bold fs-22'>Change your password</p>
                              </div>
                              <div>
                                <label className="field_name email-label-form-one" htmlFor="rrNewPwd">New Password ({passwordValidationMsg}):</label>
                                <input type="password" id="rrNewPwd" name="rrNewPwd"
                                  onChange={rrNewPwdHandle}
                                  required
                                  disabled={onRetypePwdValidate}
                                />
                                <>
                                  {!resetRequiredNewPwd || resetRequiredNewPwdValidateErr ?
                                    ''
                                    :
                                    <>
                                      <p className='form-error fs-regular'>{passwordValidationMsg}</p>
                                    </>
                                  }
                                </>
                              </div>
                              <div className="mt_20">
                                <label className="field_name email-label-form-one" htmlFor="rrConfirmPwd">Confirm Password:</label>
                                <input type="password" id="rrConfirmPwd" name="rrConfirmPwd"
                                  onChange={rrConfirmPwdHandle}
                                  required
                                />

                                <>
                                  {!resetRequiredCmPwd || resetRequiredCmPwdValidateErr ?
                                    ''
                                    :
                                    <>
                                      <p className='form-error fs-regular'>{passwordMismatchMsg}</p>
                                    </>
                                  }
                                </>

                                <div className='candidate-signin-btn-fp-screen'>
                                  {/* <button
                          onClick={bacBtn}
                        >Cancel</button> */}
                                  <button
                                    type="submit"
                                    onClick={changePasswordReq}
                                    disabled={!resetRequiredNewPwdValidateErr || !resetRequiredCmPwdValidateErr}
                                  >Change Password</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )
                      :
                      (
                        <div className="login_wrapper_main">
                          <div className='message-container  login-box-border'>
                            <div className='candidate-signin-title'>
                              <p className='fs-semi-bold fs-22'>Your password has been changed!</p>
                            </div>
                            <div className='mt_34'>
                              <button
                                className="full-width signInBtnAfterReset"
                                onClick={bacBtn}
                              >Sign-In</button>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </>
                }
              </>
          }


        </div>
        <NotificationContainer />
        <div className="profile_footer">
          <Footer />
        </div>

        {/* <div class="footer_bg_candidates" data-aos="fade-up">
        <div class="container w1200">
          <div class="footer_adjust footer-adjust-home">
            <div className='footer_left_logo homepage-footer-left-logo'>
              <img src={Logo} class="mt_40 w-100" />
            </div>
            <div class="f_height">
              <ul class="footer_links">
                <li><a>ClearedTalent © 2021.&nbsp; All Rights Reserved.</a></li>
                {/* <li className='cp' onClick={goToTerms}><a>Terms of Use</a></li> *
                <li><span className="home-footer-terms cp" onClick={goToTerms}> Terms of Use </span>&nbsp;&nbsp;<span className="home-footer-terms cp" onClick={goToPrivacyPolicy}>Privacy Policy </span></li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      </div>
    </>
  )
}

export default withRouter(CandidateSignIn)
