import React, { useRef, useEffect } from 'react';
import Logo from './images/logo.png';
import Arrow from './images/Arrow.png';
import Video from './images/register.mp4';
import { Carousel, Tooltip } from 'react-bootstrap';
import Header from './homeHeader/Header/Header';
import { loginRequest, signUpRequest } from "../../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useHistory } from "react-router";
import './home.css';
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';

const HomePage = () => {
  const history = useHistory();
  const today = new Date();
  const { instance, accounts } = useMsal();
  const topButton = useRef(0);
  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const goToTest = () => {
    window.open("/basic-info-form", "_self");
  }
  const goToTerms = (e) => {
    window.open("/terms-of-use", "_self");
  }
  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy", "_self");
  }
  const scrollFunction = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      topButton.current.classList.add('show-button')
    } else {
      topButton.current.classList.remove('show-button')
    }
  }
  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", scrollFunction);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  })
  // useEffect(() => {
  //   const scrollOptions = {
  //     left: 0,
  //     top: 0,
  //     behavior: 'smooth'
  //   }
  //   window.scrollTo(scrollOptions);
  // })
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/landing")
    }
  }, [])


  return (
    <>
      <Header />
      <div className="banner_section">
        {/* <video autoplay muted loop id="myVideo" className="w-100"
        src={Video} 
        type="video/mp4"
        /> */}
        <video id="myVideo" className="responsive-home-myVideo" autoPlay={true} loop={true} muted >
          <source src={Video} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>

        <div className="banner_text_section">
          <div className="container w1200 container-padding-left">
            {/* <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators">
                      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  </div>
                  <div className="carousel-inner">
                      <div className="carousel-item active">
                        <p className="banner_text_top">A new paradigm of <span className="banner_text_top_other_color">career possibilities
                            </span>
                        </p>
                        <p className="banner_text_bottom">Join a talent community accessed by leading global employers </p>
                      </div>
                      <div className="carousel-item">
                        <p className="banner_text_top">Quick, effective,  <span className="banner_text_top_other_color">intuitive</span></p>
                        <p className="banner_text_bottom">Waste no time! Get pre-screened and avoid multiple rounds of interviews</p>
                      </div>
                  </div>
                </div> */}
            <Carousel>
              <Carousel.Item>
                <Carousel.Caption>
                  <p className="banner_text_top">A new paradigm of <span className="banner_text_top_other_color">career possibilities</span>
                  </p>
                  <p className="banner_text_bottom">Join a talent community accessed by leading global employers </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Carousel.Caption>
                  <p className="banner_text_top">Quick, effective,  <span className="banner_text_top_other_color">intuitive</span></p>
                  <p className="banner_text_bottom">Waste no time! Get pre-screened and avoid multiple rounds of interviews</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>

          </div>
        </div>
      </div>

      <div className="banner_bottom_section">
        <div className="container w1200">
          <div className="banner_bottom_section_wrapper">
            <div className="banner_left_text_section">
              <p className="banner_bottom_section_text_left"><span className="banner_bottom_section_text_other_color">ClearedTalent</span> is helping global companies assemble a next-gen workforce
              </p>
            </div>
            <div className="banner_bottom_section_text_right">Join the <span className="banner_bottom_section_text_right_other_color">ClearedTalent</span> community</div>
          </div>
        </div>
      </div>
      <div className="parallax">
        <div className="home_step_section">
          <div className="container w1200 ">
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-delay="500" data-aos-once="true"></div>
                <div className='bullet-line hr-first-l' data-aos="reveal-top" data-aos-once="true" ></div>
              </div>
              <div className='bullet-text' id="trigger-bullets" data-aos="zoom-in" data-aos-delay="500" data-aos-once="true">Stand out from other candidates by taking a brief skill test</div>
            </div>
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-anchor="#trigger-bullets" data-aos-delay="2200" data-aos-once="true"></div>
                <div className='bullet-line hr-second-l' data-aos="reveal-top" data-aos-anchor="#trigger-bullets" data-aos-delay="2500" data-aos-once="true"></div>
              </div>
              <div className='bullet-text' data-aos="zoom-in" data-aos-anchor="#trigger-bullets" data-aos-delay="2200" data-aos-once="true">Build credibility with prospective employers by spending just a few minutes</div>
            </div>
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-anchor="#trigger-bullets" data-aos-delay="3200" data-aos-once="true"></div>
                <div className='bullet-line hr-third-l' data-aos="reveal-top" data-aos-anchor="#trigger-bullets" data-aos-delay="3500" data-aos-once="true"></div>
              </div>
              <div className='bullet-text' data-aos="zoom-in" data-aos-anchor="#trigger-bullets" data-aos-delay="3200" data-aos-once="true">Take the skill test once and use it for multiple opportunities</div>
            </div>
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-anchor="#trigger-bullets" data-aos-delay="4200" data-aos-once="true"></div>
                <div className='bullet-line'></div>
              </div>
              <div className='bullet-text' data-aos="zoom-in" data-aos-anchor="#trigger-bullets" data-aos-delay="4200" data-aos-once="true">Don’t like your skill test scores? Prepare and take the test again</div>
            </div>
          </div>
        </div>
        <div className="banner_bottom_section-1">
          <div className="container w1200">
            <p className="banner_bottom_section_register">As per HR industry analysts, hiring of pre-vetted candidates occur 60% faster than candidates who are just matched using a resume
            </p>
          </div>
        </div>
        <div className="start_button_section " data-aos="fade-up">
          <div className="container w1200 ">

            <button type="button" className="btn1-home btn-start_big request-demo mt_15" onClick={() => goToTest()}>
              START NOW
            </button>


          </div>
        </div>

        <div className="footer_bg_candidates" data-aos="fade-up">
          <div className="container w1200">
            <div className="footer_adjust footer-adjust-home">
              <div className='footer_left_logo homepage-footer-left-logo'>
                <img src={Logo} className="mt_40 w-100" />
              </div>
              <div className="f_height">
                <div className='social_links'>
                  <a href='https://www.linkedin.com/company/clearedtalent/'><Tooltip title="LinkedIn"><LinkedIn className='iconColor' /></Tooltip></a>
                  <a href='https://www.facebook.com/Cleared-Talent'><Tooltip title="Facebook"><Facebook className='iconColor' /></Tooltip></a>
                  <a href='https://twitter.com/Cleared_Talent'><Tooltip title="Twitter"><Twitter className='iconColor' /></Tooltip></a>
                  <a href='https://www.instagram.com/clearedtalent/'><Tooltip title="Instagram"><Instagram className='iconColor' /></Tooltip></a>
                </div>
                <ul className="footer_links">
                  <li><a>ClearedTalent © {today.getFullYear()}.&nbsp; All Rights Reserved.</a></li>
                  {/* <li className='cp' onClick={goToTerms}><a>Terms of Use</a></li> */}
                  <li><span className="home-footer-terms cp" onClick={goToTerms}> Terms of Use </span>&nbsp;&nbsp;<span className="home-footer-terms cp" onClick={goToPrivacyPolicy}>Privacy Policy </span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button id="myBtn" title="Go to top" onClick={goToTop} ref={topButton}>
        <img src={Arrow} alt='arrow' />
      </button>
    </>
  )
}
export default HomePage