import axios from "axios";
const jsonConfig = require("../../Config.json");

const subSkillBySkillIdResponse = async (skillid) => {
  let allSkillsList = {};
  try {
    const getAllSubSkills = await axios.get(
      jsonConfig.apiUrl + "SubskillBySkillId/" + skillid,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allSkillsList = getAllSubSkills;

    return Promise.resolve(allSkillsList);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default subSkillBySkillIdResponse;
