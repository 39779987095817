import axios from "axios";
const jsonConfig = require("../../Config.json");

const createUserTestRequest = async (payload) => {
  let allResponse = {};
  try {
    const getallresponse = await axios.post(
      jsonConfig.apiUrl + 'usertest',
      payload,
      {
        headers: {
          'x-functions-key': jsonConfig.key
        }
      }
    );

    allResponse = getallresponse;

    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default createUserTestRequest;