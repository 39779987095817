import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import React from "react";
import { Tooltip } from "react-bootstrap";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import "./Footer.css";
const Footer = () => {
  const today = new Date();

  const goToTerms = (e) => {
    window.open("/terms-of-use", "_self");
  };
  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy", "_self");
  };
  return (
    <>
      <div className="footer_recruiter_signup">
        <div className="container w1200">
          <div className="container_rec">
            <div className="footer_left_logo homepage-footer-left-logo">
              <img src={recruiter_header_logo} className="w-100" alt="" />
            </div>
            <div className="footer-right">
              <div className='social_links'>
                <a href='https://www.linkedin.com/company/clearedtalent/'><Tooltip title="LinkedIn"><LinkedIn className='iconColor' /></Tooltip></a>
                <a href='https://www.facebook.com/Cleared-Talent'><Tooltip title="Facebook"><Facebook className='iconColor' /></Tooltip></a>
                <a href='https://twitter.com/Cleared_Talent'><Tooltip title="Twitter"><Twitter className='iconColor' /></Tooltip></a>
                <a href='https://www.instagram.com/clearedtalent/'><Tooltip title="Instagram"><Instagram className='iconColor' /></Tooltip></a>
              </div>
              <div className="footer-links">
                <div>ClearedTalent © {today.getFullYear()}.&nbsp; All Rights Reserved.</div>
                <div><span className="footerTerms" onClick={goToTerms}>Terms of Use</span>&nbsp;&nbsp;<span className="footerTerms" onClick={goToPrivacyPolicy}>Privacy Policy </span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="white_section_recruiter"></div>
    </>
  );
};

export default Footer;
