import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, makeStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Header from "../../components/adminHeader/adminHeader";
import CircularProgress from '@material-ui/core/CircularProgress';
//initialising kendo grid
import {
    Grid as GridDiv,
    GridColumn,
    GridNoRecords
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query"
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';
const useStyles = makeStyles({
    fontRoboto: {
        fontFamily: "Roboto,sans-serif",
    },
    button: {
        marginBottom: "10px",
    },
});
export default function Domain({ idContextTest }) {
    const { userType, userIdType } = useContext(UserTypeContext);
    const jsonConfig = require("../../Config.json")
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [modalIsEditopen, setmodalIsEditopen] = useState(false);
    const [isDataLoaded, setisDataLoaded] = useState(false);
    const [allDomain, setallDomain] = useState([]);
    const [modalDataForEdit, setmodalDataForEdit] = useState({});
    const [modalDataForAdd, setmodalDataForAdd] = useState({})
    const [apvBtn, setApvBtn] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                if (userType === "Admin") {
                    await getAllDomain();
                } else {
                    setisDataLoaded(true)
                }
            } catch (error) {
                setisDataLoaded(true);
            } finally {
                setisDataLoaded(true);
            }
        }
        fetchAllData();

    }, [])
    const getAllDomain = async () => {

        try {
            const getAllDomainResponse = await axios.get(
                jsonConfig.apiUrl + "domains", {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
            );
            if (getAllDomainResponse.status === 200) {
                setisDataLoaded(true);
                setallDomain(getAllDomainResponse.data.data)

            }
        } catch (error) {
            console.log(error);
        }

    }
    const openModal = () => {
        setmodalIsOpen(true)
    }
    const closeModal = () => {
        setmodalIsOpen(false);
        setmodalDataForAdd({})
    }
    const closeEditmodal = () => {
        setmodalIsEditopen(false)
    }
    const domainChangeHandlerForEdit = (e) => {
        var v = e.target.value
        setmodalDataForEdit(prevState => ({
            ...prevState,
            name: v,
        })
        )
    }
    const activeHandlerEdit = (e, value) => {
        if (value) {
            var v = value.isActive
            setmodalDataForEdit(prevState => ({
                ...prevState,
                isActive: v,
            })
            )
        }
        else {
            modalDataForEdit.isActive = ""
        }
    }
    const openEditmodal = async (e, id) => {
        try {
            const getDomainById = await axios.get(
                jsonConfig.apiUrl + "domains/" + id, {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
            );
            //    setallDomain(getAllDomainResponse.data.data)
            setmodalDataForEdit(getDomainById.data.data)
            setmodalIsEditopen(true)
        } catch (error) {
            console.log(error);
        }
    }
    const updateDomain = async () => {
        setApvBtn(true);
        var domainEditData = {
            "name": modalDataForEdit.name?.trim(),
            "isActive": modalDataForEdit.isActive,
            "updatedBy": userIdType
        }
        if (!domainEditData.name || modalDataForEdit.isActive === "") {
            setApvBtn(false);
            return (
                NotificationManager.error('Please fill all the mandatory fields', 'Error', 2500)
            )
        }
        try {
            const updateDomainResponse = await axios.put(
                jsonConfig.apiUrl + "domains/" + modalDataForEdit.id, domainEditData, {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
            );
            getAllDomain();
            NotificationManager.success('Domain updated', 'Success', 2500)
            setApvBtn(false);
            setmodalIsEditopen(false)
        } catch (error) {
            console.log(error);
            setApvBtn(false);
        }
    }
    const domainNamehandlerForadd = (e) => {
        var v = e.target.value
        setmodalDataForAdd(prevState => ({
            ...prevState,
            name: v,
        })
        )
    }
    const activeHandlerForAdd = (e, value) => {
        if (value) {
            setmodalDataForAdd(prevState => ({
                ...prevState,
                isActive: value.isActive,
            })
            )
        }
    }
    const activeHandlerAdd = (e, value) => {
        if (value === "") {
            setmodalDataForAdd(prevState => ({
                ...prevState,
                isActive: undefined,
            }))
        }
    }
    const addDomain = async () => {
        setApvBtn(true);
        var domainAddData = {
            "name": modalDataForAdd.name?.trim(),
            "isActive": modalDataForAdd.isActive,
            "createdBy": userIdType,
            "updatedBy": userIdType
        }
        if (!domainAddData.name || modalDataForAdd.isActive === undefined) {
            setApvBtn(false);
            return (
                NotificationManager.error('Please fill all the mandatory fields', 'Error', 2500)
            )
        }
        try {
            const adddomainResponse = await axios.post(
                jsonConfig.apiUrl + "domains", domainAddData, {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
            );
            if (adddomainResponse.status === 200) {
                setApvBtn(false);
                return (
                    NotificationManager.error('The domain already exists in the system. Please use a different domain name', 'Error', 2500)
                )
            }
            NotificationManager.success('Domain added', 'Success', 2500)
            await getAllDomain();
            setApvBtn(false);
            closeModal()
        } catch (error) {
            console.log(error);
            setApvBtn(false);
        }
    }


    const v = [{ isActive: true },
    { isActive: false },
    ];
    const closeButton = {
        position: 'absolute',
        right: "7px",
        top: "12px",
        color: 'grey',
    };
    const btnGreen = {
        color: '#fff',
        backgroundColor: '#1885e6',
        border: 'none',
        padding: '7px 23px 7px 23px',
        float: 'right',
        marginTop: '20px',
        marginRight: '2em'
    };
    const btnSavegreen = {
        color: '#fff',
        backgroundColor: '#2AD156',
        border: '1px solid #2AD156',
    }
    const [dataState, setDataState] = React.useState({
        skip: 0,
        take: 10,
        filter: {
            logic: "and",
            filters: [],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });
    //initialising result for kendo filter
    const [result, setResult] = React.useState(allDomain, dataState)
    const onDataStateChange = (event) => {
        setDataState(event.dataState);
        setResult(process(allDomain, event.dataState));
    }
    //updating the change
    useEffect(() => {
        setResult(process(allDomain, dataState));
    }, [allDomain]);
    return (
        <><Header uid={idContextTest} isDataLoaded={isDataLoaded} />
            <div className='section-wrapper'>
                <div className="container w1200 flex-to-footer">
                    <div className="height_adjust mt_110">
                        {!isDataLoaded ?
                            <div className="row padding_80 text-center">
                                <CircularProgress />
                            </div>
                            :
                            <>
                                {
                                    userType === "Admin" ?
                                        <div>
                                            <Paper>
                                                <Grid container spacing={3}  >
                                                    <Grid item sm >
                                                        <h2 className='fs-semi-normal fs-30 f-color' style={{ marginLeft: '1em' }}>Domains</h2>
                                                    </Grid>
                                                    <Grid item sm>
                                                        <Button variant="outlined" style={btnGreen} color="primary" onClick={openModal}>
                                                            Add Domain
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>

                                            <GridDiv
                                                data={result}
                                                sortable={true}
                                                reorderable={true}
                                                filterable={true}
                                                total={allDomain.length}
                                                pageable={{
                                                    buttonCount: 10,
                                                    info: true,
                                                    previousNext: true,
                                                    pageSizes: true,
                                                    serverPaging: true,
                                                    serverFiltering: true,
                                                }}
                                                style={{
                                                    maxHeight: "500px",
                                                    overflow: "hidden",
                                                }}
                                                onDataStateChange={onDataStateChange}
                                                {...dataState}
                                                resizable={true}
                                            >
                                                {!isDataLoaded && (
                                                    <GridNoRecords>
                                                        <div className='col-4'>
                                                            <br />
                                                            <Loader size='large' themeColor="info" type="converging-spinner" />
                                                        </div>
                                                    </GridNoRecords>
                                                )}

                                                < GridColumn
                                                    className={classes.fontRoboto}
                                                    field="name"
                                                    title="Domain"
                                                    width="200px"
                                                    filterable={true}
                                                    sortable={true}
                                                />
                                                <GridColumn
                                                    className={classes.fontRoboto}
                                                    title="Is Active"
                                                    field="isActive"
                                                    width="40px"
                                                    filterable={true}
                                                    filter={"boolean"}
                                                    cell={(event) => {
                                                        return (
                                                            <td>
                                                                {event.dataItem?.isActive ? "True" : "False"}
                                                            </td>
                                                        );
                                                    }}
                                                />
                                                <GridColumn
                                                    className={classes.fontRoboto}
                                                    title="Edit"
                                                    width="40px"
                                                    filterable={false}
                                                    cell={(event) => {
                                                        return (
                                                            <td className='editCenter'>
                                                                <Button onClick={() => openEditmodal(event.dataItem, event.dataItem.id)}
                                                                    variant="contained"
                                                                    color="primary"
                                                                >Edit</Button>
                                                            </td>
                                                        );
                                                    }} />
                                            </GridDiv>
                                            {/* end of kendoGrid */}
                                        </div>
                                        : userType === "" ? (
                                            <div className="row padding_80 text-center">
                                                <p className="fs-semi-bold fs-40 f-color">
                                                    Loading...
                                                </p>
                                            </div>
                                        ) :
                                            (<div className="row padding_80 text-center">
                                                <p className="fs-semi-bold fs-40 f-color">You Are Not Authorized To Visit This Page</p>
                                            </div>)
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <Dialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                open={modalIsOpen}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}
            >
                <DialogTitle id="customized-dialog-title" className="admindailog_title_modal" onClose={closeModal}>
                    Add Domain
                    <IconButton
                        aria-label="close"
                        style={closeButton}
                        onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent >
                    <Typography gutterBottom>
                        Domain*
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            placeholder="Enter Domain"
                            className="admin_textbox"
                            // value={skillName}
                            onChange={domainNamehandlerForadd}
                        />
                    </FormControl>
                    &nbsp;
                    <Typography gutterBottom>
                        Is Active*
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <Autocomplete
                            autoSelect
                            id="combo-box-demo"
                            options={v}
                            getOptionLabel={(option) => option.isActive.toString().charAt(0).toUpperCase() + option.isActive.toString().slice(1)}
                            getOptionSelected={(option, value) => option.isActive === value.isActive}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder='Select Status' />}
                            onChange={activeHandlerForAdd}
                            onInputChange={activeHandlerAdd}
                        />
                    </FormControl>
                </DialogContent>
                <Grid item xs={12} md={12} className="user_pop_button" >
                    <DialogActions>
                        <Button variant="outlined" className="btn3" color="primary" onClick={closeModal}>
                            Cancel
                        </Button>
                        <button
                            type="button"
                            className="btn3 btn_primary ml_5 cp"
                            onClick={addDomain}
                            disabled={apvBtn}
                        >
                            <div className="btn_loader">
                                Save
                                &nbsp;
                                {apvBtn &&
                                    <div>
                                        <CircularProgress size={13} />
                                    </div>
                                }
                            </div>
                        </button>
                    </DialogActions>
                </Grid>
            </Dialog>
            {/* ----------- Edit ---------- */}
            <Dialog
                onClose={closeEditmodal}
                aria-labelledby="customized-dialog-title"
                open={modalIsEditopen}
                PaperProps={{
                    style: {
                        width: '600px',
                    },
                }}
            >
                <DialogTitle id="customized-dialog-title" className="admindailog_title_modal" onClose={closeEditmodal}>
                    Edit Domain
                    <IconButton
                        aria-label="close"
                        style={closeButton}
                        onClick={closeEditmodal}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        Domain*
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            // placeholder="Name"
                            className="admin_textbox"
                            defaultValue={modalDataForEdit.name}
                            onChange={domainChangeHandlerForEdit}
                        />
                    </FormControl>
                    &nbsp;
                    <Typography gutterBottom>
                        Is Active*
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                        <Autocomplete
                            autoSelect
                            id="combo-box-demo"
                            options={v}
                            getOptionLabel={(option) => option.isActive.toString().charAt(0).toUpperCase() + option.isActive.toString().slice(1)}
                            value={modalDataForEdit || ""}
                            getOptionSelected={(option, value) => {
                                if (value.isActive === "") {
                                    return option.isActive === true
                                } else {
                                    return option.isActive === value.isActive
                                }
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            onChange={activeHandlerEdit}
                        />
                    </FormControl>
                </DialogContent>
                <Grid item xs={12} md={12} className="user_pop_button" >
                    <DialogActions>
                        <Button variant="outlined" className="btn3" color="primary" onClick={closeEditmodal}>
                            Cancel
                        </Button>

                        <button
                            type="button"
                            className="btn3 btn_primary ml_5 cp"
                            onClick={updateDomain}
                            disabled={apvBtn}
                        >
                            <div className="btn_loader">
                                Save
                                &nbsp;
                                {apvBtn &&
                                    <div>
                                        <CircularProgress size={13} />
                                    </div>
                                }
                            </div>
                        </button>
                    </DialogActions>
                </Grid>
            </Dialog>
            <NotificationContainer />
        </>
    )
}