import axios from "axios";
const jsonConfig = require("../../Config.json");

const generateAdditionalSkillResponse = async (data) => {
    let allSkillsList = {}
    try {
        const response = await axios.post(jsonConfig.apiUrl + "GenerateAdditionalSkill", data, 
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        allSkillsList = response;
        return Promise.resolve(allSkillsList)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default generateAdditionalSkillResponse;