import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import Header from "../../components/headerAdmin/Header.js";
import Footer from "../../components/adminFooter/Footer";
import "./pendingInvites.css";
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn } from "@progress/kendo-react-grid";
import getAllPendingCandidateByInvitedIdResponse from "../../apis/pendingInvites/pendingInvites";
import getAllPendingCandidatesByOrganizationId from "../../apis/pendingInvites/pendingInvitesByOrganizationId.js"
import { useHistory } from "react-router";
import { DateTime } from "luxon";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SearchReferralByRecruitmentPartnerId from '../../apis/myTallentCommunityByOrgId/searchReferralByRecruitmentPartnerId.js';
import UpdateStatusReferralById from '../../apis/myTallentCommunityByOrgId/updateStatusReferralById.js';
import revokeInviteCandidateResponse from '../../apis/inviteCandidate/revokeInviteCandidate.js';
import BasicHeader from "../../components/basicHeader/BasicHeader.js"
import { UserTypeContext } from '../../AppRoute.js';
const useStyles = makeStyles({
  fontRoboto: {
    fontFamily: "Roboto,sans-serif",
  },
})

const PendingInvites = (props) => {
  const classes = useStyles();
  const [allPendingCandidateByInvitedIdArr, setAllPendingCandidateByInvitedIdArr] = useState([]);
  const [allPendingCandidateByOrgId, setAllPendingCandidateByOrgId] = useState([])
  const { userType, userRecords } = useContext(UserTypeContext);
  const userRecruitmentpartnerId = userType === "Admin" ? props?.location?.state?.userRecruitmentpartnerId  : userRecords?.recruitmentpartnerId
  const idContextTest = userType === "Admin" ? props?.location?.state?.id : userRecords?.id
  const userRole = userType === "Admin" ? props?.location?.state?.userRole : userRecords?.role?.roleName
  const userRecordData = userType === "Admin" ? props?.location?.state?.userRecords : userRecords
  const history = useHistory();
  const [revokeCandidateDetails, setRevokeCandidateDetails] = useState({});
  const [pendingData, setPendingData] = useState([]);
  const [pendingDataByOrgId, setPendingDataByOrgId] = useState([])
  const [openRevokeDialogue, setOpenRevokeDialogue] = useState(false);
  const [revokeReferral, setRevokeReferral] = useState([]);

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "firstName",
          operator: "startswith",
          value: "",
        },
        {
          field: "lastName",
          operator: "startswith",
          value: "",
        },
        {
          field: "test.testName",
          operator: "startswith",
          value: "",
        },
        {
          field: "test.skill.name",
          operator: "startswith",
          value: "",
        },

        {
          field: "email",
          operator: "startswith",
          value: "",
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [dataResult, setDataResult] = useState(process(allPendingCandidateByInvitedIdArr, dataState));
  const [dataResult1, setDataResult1] = useState(process(allPendingCandidateByOrgId, dataState));

  const getAllPendingCandidateByInvitedId = async () => {

    if (userRole === 'Recruiter' || userRole === "Recruitment Leader" || userRole === "Team Leader") {
      var rId = idContextTest;
      console.log({rId});
      
      let payload = {
        userType: userRole
      }
      let allPendingCandidateByInvitedId = await getAllPendingCandidateByInvitedIdResponse(rId, payload);

      if (allPendingCandidateByInvitedId?.data?.data) {
        let arr = [];
        let data = allPendingCandidateByInvitedId?.data?.data;

        if (data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            //Aging
            let date = getDate(data[index].createdAt);
            let dateOfInvite = data[index].createdAt;
            let currentDate = DateTime.now().toISO();
            const date1 = DateTime.fromISO(dateOfInvite);
            const date2 = DateTime.fromISO(currentDate);
            const diff = date2.diff(date1, ["days"]).toObject();
            const difference = diff.days.toString().split(".")[0].includes("-")
              ? 0
              : diff.days.toString().split(".")[0];
            let expDate = new Date(data[index].expiresOn);

            // Test Expiry Date
            const formattedDate = DateTime.fromISO(data[index].expiresOn)
              .toLocal()
              .startOf("day")
              .toJSDate();

            arr.push(
              Object.assign(data[index], {
                dateTime: date,
                aging: difference,
                testExpiry: formattedDate,
              })
            );
          }

          setPendingData(arr);
          setDataResult(process(arr, dataState));

        }
      } else {
        const emptArr = [];

        setPendingData([]);
        setDataResult(process(emptArr, dataState));

      }
    } else {
      var oId = userRecruitmentpartnerId;
      let allPendingCandidateByOrgId = await getAllPendingCandidatesByOrganizationId(oId)

      if (allPendingCandidateByOrgId.data.data) {
        let arr = [];
        let data = allPendingCandidateByOrgId.data.data;

        if (data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            //Aging
            let date = getDate(data[index].createdAt);
            let dateOfInvite = data[index].createdAt;
            let currentDate = DateTime.now().toISO();
            const date1 = DateTime.fromISO(dateOfInvite);
            const date2 = DateTime.fromISO(currentDate);
            const diff = date2.diff(date1, ["days"]).toObject();
            const difference = diff.days.toString().split(".")[0].includes("-")
              ? 0
              : diff.days.toString().split(".")[0];
            let expDate = new Date(data[index].expiresOn);

            // Test Expiry Date
            const formattedDate = DateTime.fromISO(data[index].expiresOn)
              .toLocal()
              .startOf("day")
              .toJSDate();

            arr.push(
              Object.assign(data[index], {
                dateTime: date,
                aging: difference,
                testExpiry: formattedDate,
              })
            );
          }
          setPendingDataByOrgId(arr);
          setDataResult1(process(arr, dataState));
        }
      } else {
        const emptArr = [];
        setPendingDataByOrgId([]);
        setDataResult1(process(emptArr, dataState));
      }
    }

  };

  const dataStateChange = (event) => {
    setDataState(event.dataState);
    setDataResult(process(pendingData, event.dataState));
  };

  const dataStateChange1 = (event) => {
    setDataState(event.dataState);
    setDataResult1(process(pendingDataByOrgId, event.dataState));
  };

  const getDate = (date) => {
    let fullDate = "";
    fullDate =
      new Intl.DateTimeFormat("en", { day: "2-digit" }).format(new Date(date)) +
      "-" +
      new Intl.DateTimeFormat("en", { month: "long" }).format(new Date(date)) +
      "-" +
      new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        new Date(date)
      ) +
      ", " +
      new Date(date).toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
      });

    return fullDate;
  };


  useEffect(() => {
    if (userRecruitmentpartnerId) {
      getAllPendingCandidateByInvitedId();
    }
  }, [userRecruitmentpartnerId]);

  const goToDashboard = () => {
    history.push({
      pathname: "/dashboard",
      state: {
        userType: userType,
        recruitmentPartnerId: userRecruitmentpartnerId,
        id: idContextTest,
        userRecords: userRecordData,
        roleName: userRole
      }
    });
  }


  const openRevokeModal = (value) => {
    setRevokeCandidateDetails(value);
    setOpenRevokeDialogue(true);
    getReferralData(value);
  };

  const closeRevokeModal = () => {
    setOpenRevokeDialogue(false);
  };

  const revokeCandidate = async () => {
    if (revokeReferral.length !== 0) {
      const payload = {
        referralId: revokeReferral[0]?.id,
        data: {
          status: "Referral Cancelled",
        },
      };

      try {
        await UpdateStatusReferralById(payload);
      } catch (err) {
        NotificationManager.error("Something Went Wrong!", "error", 2500)
      }
    }

    var payload = {
      isActive: false,
    };

    try {
      await revokeInviteCandidateResponse(
        revokeCandidateDetails.id,
        payload
      );
      NotificationManager.success("Revoke done", "Success", 2500);
      getAllPendingCandidateByInvitedId();
      setOpenRevokeDialogue(false);
    } catch (error) {
      NotificationManager.error("Revoke failed", "Error", 2500);
      setOpenRevokeDialogue(false);
    }
  };

  const getReferralData = async (param) => {

    const payloadDataForReferral = {
      recruitmentPartnerId: userRecruitmentpartnerId,
      data: {
        skillId: [],
        country: "",
        status: [],
      },
    };
    try {
      let res = await SearchReferralByRecruitmentPartnerId(
        payloadDataForReferral
      );
      const allData = res?.data?.data;
      res = allData;
      res = res.filter((item, i) => item.email === (param.email ? param.email : ""));
      setRevokeReferral(res || []);

    } catch (err) {
      NotificationManager.error("Something Went Wrong!", "error", 2500)
    }

  };


  return (
    <>
      {(userType === "Candidate" ) ? (
        <>
          <BasicHeader />
          <div className="row padding_80 text-center">
            <p className="fs-semi-bold fs-40 f-color authorize">
              You Are Not Authorized To Visit This Page
            </p>
          </div>
        </>
      ) :
        <div className='pendingInvitemainContainer'>
          <div className='pendingInviteTopContainer'>
            <Grid container>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid item xs={12} container className="pendingInviteMainGrid">
                <div className='titlesofCommunity'>Pending Invites</div>
                <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashboard(e)}>
                  <ArrowBackIcon /> Go Back to Dashboard
                </div>
                <div className='kendoGridDiv'>
                  <GridDiv
                    className="kendoStyle"
                    filter={dataState.filter}
                    filterable={true}
                    sort={dataState.sort}
                    sortable={true}
                    resizable={true}
                    pageable={{
                      buttonCount: 10,
                      info: true,
                      previousNext: true,
                      pageSizes: true,
                    }}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={userRole === 'Recruiter' || userRole === "Recruitment Leader" || userRole === "Team Leader" ? dataResult : dataResult1}
                    onDataStateChange={userRole === 'Recruiter' || userRole === "Recruitment Leader" || userRole === "Team Leader" ? dataStateChange : dataStateChange1}
                  >
                    {userType === "Organization Admin" || userType === "Admin"  ? "" :
                      <GridColumn
                        className={classes.fontRoboto}
                        field="id"
                        title="Action"
                        width="150px"
                        filterable={false}
                        sortable={false}
                        cell={(event) => {
                          return (
                            <td>
                              <div className="revokeButtonAllignment">
                                <Button
                                  className={classes.fontRoboto}
                                  type="button"
                                  size="small"
                                  variant="contained"
                                  color="secondary"
                                  onClick={(e) => {
                                    openRevokeModal(event.dataItem);
                                  }
                                  }
                                >
                                  Revoke
                                </Button>
                              </div>
                            </td>
                          );
                        }}
                      />

                    }

                    <GridColumn
                      className={classes.fontRoboto}
                      field="firstName"
                      title="First Name"
                      width="150px"
                    />
                    <GridColumn
                      className={classes.fontRoboto}
                      field="lastName"
                      title="Last Name"
                      width="150px"
                    />

                    <GridColumn
                      className={classes.fontRoboto}
                      field="email"
                      title="Email"
                      width="250px"
                    />
                    <GridColumn
                      className={classes.fontRoboto}
                      field="test.testName"
                      title="Test"
                      width="200px"
                    />
                    <GridColumn
                      className={classes.fontRoboto}
                      field="test.skill.name"
                      title="Skill"
                      width="200px"
                    />
                    <GridColumn
                      className={classes.fontRoboto}
                      field="aging"
                      title="Aging (Days)"
                      width="120px"
                    />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="user.displayName"
                        title="Invited By"
                        width="250px"
                      /> 

                    <GridColumn
                      className={classes.fontRoboto}
                      field="testExpiry"
                      title="Test Expiry Date"
                      filterable={true}
                      sortable={true}
                      filter={"date"}
                      format="{0:d},{0:t}"
                      width={245}
                      cell={(e) => {
                        return (
                          <td>
                            {DateTime.fromISO(e.dataItem.expiresOn)
                              .toFormat("MM/dd/yyyy")
                              .toString()}
                          </td>
                        );
                      }}
                    />
                  </GridDiv>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='pendingInviteBottomContainer'>
            <Footer />
          </div>
        </div>
      }

      <Dialog
        open={openRevokeDialogue}
        onClose={closeRevokeModal}
        aria-labelledby="customized-dialog-title"
      >
        <DialogContent
          className={`${classes.bodyForRemove} removemodal_responsive`}
        >
          <div className="text-center fs-semi-bold f-color fs-18">
            Do you want to revoke invite?
          </div>
        </DialogContent>
        <DialogActions
          className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button `}
        >
          <div className="remove_modal revoke_adjust">
            <button
              type="button"
              className="btn_remove btn-skill cp"
              onClick={closeRevokeModal}
            >
              No
            </button>
            <button
              type="button"
              className="btn2 btn-green ml_5 cp"
              onClick={revokeCandidate}
            >
              Yes
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <NotificationContainer />
    </>
  )
}

export default PendingInvites