import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState, useContext, useRef } from "react";
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import Header from "../../components/adminHeader/adminHeader";
import "./AdminReferral.css";
import AdminReferralKendo from "./AdminReferralKendo";
import { UserTypeContext } from "../../AppRoute";
import getSkills from "../../apis/Tests/getSkills";

import getReferralPartnerSkills from "../../apis/adminReferralAPI/getReferralPartnerSkills";
import { DateTime } from "luxon";
const AdminReferral = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  // const [clientData, setClientData] = React.useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [editClientData, setEditClientData] = useState(null);

  const [allPublicSkill, setallPublicSkill] = useState([]);
  const [allRecruitmentOrgs, setAllRecruitmentOrgs] = useState([]);
  const [allData, setAllData] = useState([]);
  
  const getAllSkills = async () => {

    let allSkillResponse = await getSkills();

    setallPublicSkill(
      allSkillResponse.data.data.filter((i) => i.isActive === true)
    );

  };
  const getRecruitmentOrgs = async () => {

    let allData = await getAllRecruitmentPartnersAndClients();
    let data = allData.data.data;
    let newData = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].isActive === true) {
        newData.push(data[i]);
      }
    }

    setAllRecruitmentOrgs(newData);

  };

  const getData = async () => {

    let data = await getReferralPartnerSkills();
    let allDataSet = data.data.data;
    for (let i = 0; i < allDataSet.length; i++) {
      // skill filter for duplicate values
      let skills = allDataSet[i].referralskills.map(
        (item) => item?.skill?.name
      );

      skills = skills.filter((item, index) => skills.indexOf(item) === index);
      skills = skills.filter(Boolean).join(", ");
      allDataSet[i].skills = skills;

      // location filter for duplicate values
      let locations = allDataSet[i].referralskills.map(
        (item) => JSON.parse(item.location).name
      );

      locations = locations.filter(
        (item, index) => locations.indexOf(item) === index
      );
      locations = locations.filter(Boolean).join(", ");
      allDataSet[i].locations = locations;

      //dates
      const date = allDataSet[i].createdAt;
      const formatted = DateTime.fromISO(date)
        .toLocal()
        .startOf("day")
        .toJSDate();
      allDataSet[i].formattedDate = formatted;
    }

    setAllData(allDataSet);

  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getData();
        await getRecruitmentOrgs();
        await getAllSkills();
      } catch (error){
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }
    fetchAllData();

  }, []);

  return (
    <>
      <Header uid={idContextTest}  isDataLoaded={isDataLoaded}/>
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <Container component={Paper} className="allignmentFix">
                    <Grid container>
                      <Grid item xs={6}>
                        <h2 className="fs-semi-bold fs-30 f-color">
                          {" "}
                          Referral
                        </h2>
                      </Grid>
                      <Grid item xs={6}></Grid>
                      <AdminReferralKendo userType={userType} userIdType={userIdType} isDataLoaded={isDataLoaded} setisDataLoaded={setisDataLoaded}  
                      allPublicSkill={allPublicSkill} allRecruitmentOrgs={allRecruitmentOrgs} allData={allData} getData={getData}
                      />
                    </Grid>
                  </Container>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>
                ) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminReferral;
