import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Group174img from "../../../pages/adminApplication/components/skillVideoTest/images/Group 174-disabled.png";
import Group175img from "../../../pages/adminApplication/components/skillVideoTest/images/Group 175.png";
import cross from "../../../pages/adminApplication/components/skillVideoTest/images/cross.svg";
// import Modal from "react-modal";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import incorrect from "../../../pages/adminApplication/components/skillVideoTest/images/incorrect.png";
import correct from "../../../pages/adminApplication/components/skillVideoTest/images/correct.png";
import axios from "axios";
import ReactPlayer from "react-player";
import backArrow from "../../../pages/adminApplication/components/skillVideoTest/images/backarrow.png";
import nextArrow from "../../../pages/adminApplication/components/skillVideoTest/images/nextarrow.png";
// import incorrect from "./images/incorrect.png";
// import correct from "./images/correct.png";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";

import Button from "@material-ui/core/Button";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@material-ui/core/MobileStepper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import getUserTestByTestId from "../../../apis/userTest/userTestByTestId";
import getUserTestQuestionAnswer from "../../../apis/userTest/userTestQuestionAnswerByTestId";
import noVideo from "../../../pages/adminApplication/components/skillVideoTest/images/video_unavailable.png";
import getPublishedUserTests from "../../../apis/userTest/getPublishedUserTests";
import getAllUserTestsAdmin from "../../../apis/userTest/getAllUserTestsAdmin";
import getUsersById from "../../../apis/users/getUserById";
import { useLocation } from "react-router-dom";
import testQuestionDescription from "../../../utilities/questionSyntaxHighlight";
import Prism from "prismjs";
import { DateTime } from "luxon";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  btnpostion: {
    display: "flex",
    padding: "0px!important",
    marginTop: "20px",
    background: "none!important",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end!important",
  },
  btnstyle: {
    padding: "0px!important",
    background: "none!important",
    maxWidth: "unset !important",
  },
  swipeablecontainer: {
    width: "99.9%",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const CandidateTest = (props) => {
  let userId = localStorage.getItem("userID");
  const { testAccessData } = props;

  // const img_url = "http://localhost:3000/uploads/";
  // const jsonConfig = require("../../../../Config.json");
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [modalStatus, setModalStatus] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
  const [questionTime, setQuestionTime] = useState("");
  const [answerTime, setAnswerTime] = useState("");
  const [userAnswersResponse, setUserAnswerResponse] = useState([]);
  const [userAnswerList, setUserAnswerList] = useState([]);
  const [skillTestDate, setSkillTestDate] = useState("");
  const [loadingController, setLoadingController] = useState(false);
  const [isSharebtnClicked, setisSharebtnClicked] = useState(false);
  const userAnswerResponseRef = useRef(0);
  const textAreaRef = useRef(null);
  const [userTestId, setuserTestId] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [skillName, setSkillName] = useState("");
  const [userRole, setuserRole] = useState(localStorage.getItem("role"));
  const [user, setUser] = useState([]);
  const maxSteps = userAnswersResponse.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    // alert(step)
  };

  const getAllUsersById = async () => {
    try {
      const response = await getUsersById(userId);
      setUser(response.data.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  // skillName

  const getSkillName = async (testId) => {
    try {
      if (testId) {
        const userTestSkill = await getUserTestByTestId(testId);
        setuserTestId(userTestSkill.data.data.id);
        setSkillName(userTestSkill.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const sharebtnClickedHandler = (e) => {
    setisSharebtnClicked(true);
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    // setLinkToShare('Copied!');

    setTimeout(sharebtnTxtChange, 5000);
  };
  const sharebtnTxtChange = () => {
    setisSharebtnClicked(false);
  };

  // Open Skill Test Modal
  const openSkilltestModal = async () => {
    await getTestDetails(
      userAnswersResponse[userAnswerResponseRef.current]
        ? userAnswersResponse[userAnswerResponseRef.current].id
          ? userAnswersResponse[userAnswerResponseRef.current].id
          : ""
        : ""
    );
    setModalStatus(true);
  };

  // Close Skill Test Modal
  const closeSkillTestModal = (event) => {
    setModalStatus(false);
  };

  // Toggle Questions
  const questionsHandler = (index) => {
    let inputCheckboxes = document.querySelectorAll(".clearcheckbox");
    for (let i = 0; i < inputCheckboxes.length; i++) {
      inputCheckboxes[i].checked = false;
    }
    setQuestionIndex(index);
    setQuestionNumber(index + 1);
  };

  // Get all user test
  const getUserTests = async () => {
    try {
      let role = localStorage.getItem("role");
      if (role === "Admin") {
        let userTestApiResponse = await getAllUserTestsAdmin(props.profileId);
        setUserAnswerResponse(userTestApiResponse.data.data);
      } else {
        let userTestApiResponse = await getPublishedUserTests(props.profileId);
        setUserAnswerResponse(userTestApiResponse.data.data);
      }

      setLoadingController(true);
    } catch (error) {
      console.log("%cError: " + error, "color:red;");
      setLoadingController(true);
    }
  };

  const questionCardSliderHandler = async (action) => {
    if (action == "increment") {
      handleNext();
      if (
        userAnswerResponseRef.current + 1 <= userAnswersResponse.length &&
        userAnswerResponseRef.current + 1 >= 0
      ) {
        userAnswerResponseRef.current = userAnswerResponseRef.current + 1;
        await getTestDetails(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
        await getSkillName(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
      }
    }
    if (action == "decrement") {
      handleBack();
      if (
        userAnswerResponseRef.current - 1 <= userAnswersResponse.length &&
        userAnswerResponseRef.current - 1 >= 0
      ) {
        userAnswerResponseRef.current = userAnswerResponseRef.current - 1;
        await getTestDetails(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );

        await getSkillName(
          userAnswersResponse[userAnswerResponseRef.current]
            ? userAnswersResponse[userAnswerResponseRef.current].id
              ? userAnswersResponse[userAnswerResponseRef.current].id
              : ""
            : ""
        );
      }
    }
  };

  // Get Test Details
  const getTestDetails = async (testId) => {
    try {
      if (testId) {
        const userTestDetails = await getUserTestQuestionAnswer(testId);
        const tempUsersTestDetails = userTestDetails.data.data;

        let totalQuestionSecondsAlloted = 0;
        let totalQuestionMinutesAlloted = 0;
        tempUsersTestDetails.forEach((el) => {
          totalQuestionSecondsAlloted =
            totalQuestionSecondsAlloted +
            Number.parseInt(el.userTestQuestionQuestion.timeLimit);
        });
        if (totalQuestionSecondsAlloted > 60) {
          totalQuestionMinutesAlloted = totalQuestionSecondsAlloted / 60;
          const time =
            totalQuestionMinutesAlloted % 1 === 0
              ? totalQuestionMinutesAlloted
              : parseFloat(totalQuestionMinutesAlloted).toFixed(1);

          setQuestionTime(time + " Minutes");
        } else {
          setQuestionTime(totalQuestionSecondsAlloted + " Seconds");
        }

        let totalAnswerSecondsAlloted = 0;
        let totalAnswerMinutesAlloted = 0;
        tempUsersTestDetails.forEach((el) => {
          totalAnswerSecondsAlloted =
            totalAnswerSecondsAlloted + Number.parseInt(el.userTimeTaken);
        });

        if (totalAnswerSecondsAlloted > 60) {
          let minute = ~~((totalAnswerSecondsAlloted % 3600) / 60);
          let second = totalAnswerSecondsAlloted % 60;
          setAnswerTime(
            minute +
            (minute === 1 ? " minute" : " minutes") +
            " " +
            second +
            " seconds"
          );
        } else {
          setAnswerTime(totalAnswerSecondsAlloted + " Seconds");
        }
        setTotalQuestions(tempUsersTestDetails.length);
        let userTestScore = 0;
        tempUsersTestDetails.forEach((el) => {
          if (el.isCorrect) {
            userTestScore = userTestScore + 1;
          }
        });
        setTotalCorrectAnswers(userTestScore);
        // setSkillTestDate(
        //   new Intl.DateTimeFormat("en", { month: "long" }).format(
        //     new Date(tempUsersTestDetails[0].createdAt)
        //   ) +
        //     "-" +
        //     new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
        //       new Date(tempUsersTestDetails[0].createdAt)
        //     ) +
        //     "-" +
        //     new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        //       new Date(tempUsersTestDetails[0].createdAt)
        //     ) +
        //     " " +
        //     new Intl.DateTimeFormat("en", {
        //       hour: "numeric",
        //       minute: "numeric",
        //     }).format(new Date(tempUsersTestDetails[0].createdAt))
        // );
        setSkillTestDate(
          DateTime.fromISO(tempUsersTestDetails[0].createdAt).toFormat(
            "LLL-dd-yyyy t"
          )
        );
        setUserAnswerList(userTestDetails.data.data);
        // setLoadingController(true);
      }
    } catch (error) {
      console.log("%cError: " + error, "color:red;");
    }
  };
  const minToSec = (timeLimit) => {
    // const time = timeLimit

    let minutes = Math.floor(timeLimit / 60);
    let seconds = timeLimit - minutes * 60;
    return (
      minutes +
      (minutes === 1 ? " minute" : " minutes") +
      " " +
      (seconds < 10 ? "0" : "") +
      seconds +
      " seconds"
    );
  };
  // Format Date
  const formatDate = (dateVal) => {
    let date = new Date(dateVal);

    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "-" + day + "-" + year;
  };

  // Take Skill Test
  const takeSkillTest = (event) => {
    history.push("/video-skill-test");
  };

  useEffect(() => {
    getAllUsersById();
  }, []);

  // Use Effect Get Test
  useEffect(() => {
    if (props.profileId) {
      getUserTests();
    }
  }, [props.profileId]);

  // Use Effect Get Test Details
  useEffect(() => {
    getTestDetails(
      userAnswersResponse[0]
        ? userAnswersResponse[0].id
          ? userAnswersResponse[0].id
          : ""
        : ""
    );
    getSkillName(
      userAnswersResponse[0]
        ? userAnswersResponse[0].id
          ? userAnswersResponse[0].id
          : ""
        : ""
    );
  }, [userAnswersResponse]);

  const IsJsonString = (str) => {
    // alert("ok")
    try {
      var locationjson = JSON.parse(str);

      if (Object.keys(locationjson).length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
    // return true;
  };
  const responsive = {
    0: {
      items: 3,
    },
    600: {
      items: 3,
    },
    1024: {
      items: 12,
    },
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const timeLimitStyle = {
    fontSize: "12px",
  };

  const customDividerStyle = {
    height: "274px",
  };
  setTimeout(() => Prism.highlightAll(), 0);

  const isTestAccessContainsSkillId = (skillId) => {
    return testAccessData?.includes(skillId);

  };

  return (
    <>
      {loadingController ? (
        <>
          {userAnswersResponse && userAnswersResponse.length ? (
            <div>
              {userAnswerList.length > 0 ? (
                <div className="skillTestmain">
                  <div className="skillTestInfoContainer_poweResumeRight">
                    <AutoPlaySwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={activeStep}
                      className={classes.swipeablecontainer}


                    >
                      {userAnswersResponse.map((step, index) => (
                        <div key={index} className="skillVideoTestContentContainer_poweResumeRight">
                          <div className="SkillVideoTestTitleStatusContainer_poweResumeRight">
                            <span className="primarySkillTitle_poweResumeRight">Skill Video Test</span>
                            <div className={step.userTestTestStatus.code === "completed" ? "videoSKillTestStatus_poweResumeRight_completed" : "videoSKillTestStatus_poweResumeRight_missing"}>
                              {step.userTestTestStatus.code === "completed"
                                ? "Completed"
                                : "Exited before completion"}
                            </div>
                          </div>
                          {Math.abs(activeStep - index) <= 2 ? (<>
                            <span className="skillTestName_poweResumeRight">{step?.test?.testName}
                              {step?.test?.testCode &&
                                `(${step?.test?.testCode})`}
                            </span>
                            <div className="skillTestTimeInfoContainer_poweResumeRight">
                              <span>Skill test taken on: {DateTime.fromISO(
                                step.createdAt
                              ).toFormat("dd-LLL-yyyy")}
                              </span>
                              <span>Skill test duration: {questionTime}</span>
                              <span>User time taken: {answerTime}</span>
                            </div>
                            <div className="testScoreContainer_poweResumeRight">
                              <span>Total Questions: {totalQuestions}</span>
                              <span>Skill test score {totalCorrectAnswers} out of {totalQuestions}</span>
                            </div>
                            {userRole === "Candidate" ||
                              userRole === null ? (
                              <></>
                            ) : (
                              (userRole === 'Admin' || step?.test?.recruitmentpartnerId ===
                                "" ||
                                (user[0]?.recruitmentpartnerId ===
                                  step?.test?.recruitmentpartnerId &&
                                  typeof step?.test
                                    ?.recruitmentpartnerId !==
                                  "undefined")) && (
                                <div className="skillVideoTestestbtnContainer_poweResumeRight">
                                  <div className="add_email_box_skillvideo_test mt_33">
                                    <input
                                      type="text"
                                      className="search-input"
                                      value={
                                        "https://" +
                                        window.location.host +
                                        "/test/" +
                                        userTestId
                                      }
                                      ref={textAreaRef}
                                    />
                                    <Button
                                      className="viewSkillTestBtn_poweResumeRight_copyLink"
                                      onClick={(e) =>
                                        sharebtnClickedHandler(e)
                                      }
                                    >
                                      {isSharebtnClicked
                                        ? "Copied"
                                        : "Copy Link"}
                                    </Button>
                                  </div>
                                  <div className="skill_video_test_btnsection">
                                    <Button
                                      type="button"
                                      className="viewSkillTestBtn_poweResumeRight"
                                      onClick={(e) =>
                                        openSkilltestModal(e)
                                      }
                                    >
                                      View Skill Test
                                    </Button>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                          ) : null}
                        </div>
                      ))}
                    </AutoPlaySwipeableViews>
                    <div className="additionalSKillScroll_poweResumeRight">
                      <IconButton className="arraowIconContainer_powerResumeRight" onClick={() => questionCardSliderHandler("decrement")} disabled={activeStep === 0}><ArrowBackIos className="arraowIcon_powerResumeRight" /></IconButton>
                      <div className="skillItemCountContainer_poweResumeRight">{activeStep + 1}<span className="skillItemCountContainer_poweResumeRight_opacity"> out of {userAnswersResponse.length}</span></div>
                      <IconButton className="arraowIconContainer_powerResumeRight" onClick={() => questionCardSliderHandler("increment")} disabled={activeStep === userAnswersResponse.length - 1}><ArrowForwardIos className="arraowIcon_powerResumeRight" /></IconButton>
                    </div>
                  </div>
                </div>


              ) :
                <div className="skillVideoTestContentContainer_poweResumeRight">
                  <div className="SkillVideoTestTitleStatusContainer_poweResumeRight">
                    <span className="primarySkillTitle_poweResumeRight">Skill Video Test</span>
                    <Skeleton variant="rect" animation="wave" height={30} width={150} />
                  </div>
                  <Skeleton variant="rect" animation="wave" height={15} width={100} />
                  <div className="skillTestTimeInfoContainer_poweResumeRight">
                    <Skeleton variant="rect" animation="wave" height={12} width={150} />
                    <Skeleton variant="rect" animation="wave" height={12} width={150} />
                    <Skeleton variant="rect" animation="wave" height={12} width={150} />
                  </div>
                  <div className="testScoreContainer_poweResumeRight">
                    <Skeleton variant="rect" animation="wave" height={12} width={150} />
                    <Skeleton variant="rect" animation="wave" height={12} width={150} />
                  </div>
                  <div className="skillVideoTestestbtnContainer_poweResumeRight">
                    <Skeleton variant="rect" animation="wave" height={30} width="75%" />
                    <Skeleton variant="rect" animation="wave" height={30} width={100} />
                  </div>
                  <div className="additionalSKillScroll_poweResumeRight">
                    <Skeleton variant="circle" animation="wave" height={24} width={24} />
                    <Skeleton variant="react" animation="wave" height={24} width={120} />
                    <Skeleton variant="circle" animation="wave" height={24} width={24} />
                  </div>

                </div>
              }

            </div>
          ) : (
            <div className="card mt_10">
              <div className="row">
                <p className="fs-light fs-35 f-color">Skill Test</p>
                <p className="fs-regular fs-16 mt_12">
                  No skill tests available
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="skillVideoTestContentContainer_poweResumeRight">
          <div className="SkillVideoTestTitleStatusContainer_poweResumeRight">
            <span className="primarySkillTitle_poweResumeRight">Skill Video Test</span>
            <Skeleton variant="rect" animation="wave" height={30} width={150} />
          </div>
          <Skeleton variant="rect" animation="wave" height={15} width={100} />
          <div className="skillTestTimeInfoContainer_poweResumeRight">
            <Skeleton variant="rect" animation="wave" height={12} width={150} />
            <Skeleton variant="rect" animation="wave" height={12} width={150} />
            <Skeleton variant="rect" animation="wave" height={12} width={150} />
          </div>
          <div className="testScoreContainer_poweResumeRight">
            <Skeleton variant="rect" animation="wave" height={12} width={150} />
            <Skeleton variant="rect" animation="wave" height={12} width={150} />
          </div>
          <div className="skillVideoTestestbtnContainer_poweResumeRight">
            <Skeleton variant="rect" animation="wave" height={30} width={500} />
            <Skeleton variant="rect" animation="wave" height={30} width={100} />
          </div>
          <div className="additionalSKillScroll_poweResumeRight">
            <Skeleton variant="circle" animation="wave" height={24} width={24} />
            <Skeleton variant="react" animation="wave" height={24} width={120} />
            <Skeleton variant="circle" animation="wave" height={24} width={24} />
          </div>

        </div>
      )}
      <Dialog
        fullScreen
        open={modalStatus}
        onClose={closeSkillTestModal}
        aria-labelledby="customized-dialog-title"
        TransitionComponent={Transition}
        // maxWidth="sm"
        //  fullWidth={true}
        className="fullscreen-width full_screen_from_top"
      >
        {/* <DialogTitle onClose={closeSkillTestModal}>Skill Video Test</DialogTitle> */}
        <div
          className="skill-video-crossFormodal"
          onClick={closeSkillTestModal}
        >
          <img className="closeicon_set" src={cross} alt=""></img>
        </div>
        <DialogContent className="mt_20">
          <div className="playbuttonsection">
            {/* <div>
                      <img src={Group121} alt="" className="playButton" />
                    </div> */}
            <div>
              <p className="fs-semi-bold fs-16 f-color mb_3">
                {/* React Native - Advance coding test */}
                {skillName?.test?.testName}
              </p>
              <p className="fs-regular fs-14 f-color mb_3">
                Skill Test Taken On: {skillTestDate}
              </p>
              <p className="fs-regular fs-14 f-color mb_3">
                Skill Test Duration: {questionTime}
              </p>
              <p className="fs-regular fs-14 f-color mb_3">
                Time Taken By Candidate: {answerTime}
              </p>
            </div>

            <div className="accenture-data df view_test"  >
              <div className="cadidateinfo_clm_one">
                <p className="fs-regular fs-14 f-color mb_3">
                  First Name: {props.profileData.user?.firstName}
                </p>
                <p className="fs-regular fs-14 f-color mb_3">
                  Last Name: {props.profileData.user?.lastName}
                </p>
                <p className="fs-regular fs-14 f-color mb_3">
                  Email: {props.profileData.user?.email}
                </p>
              </div>
              <div className="cadidateinfo_clm_two">
                <p className="fs-regular fs-14 f-color mb_3">
                  Country:{" "}
                  {props.profileData.locationCountry
                    ? IsJsonString(props.profileData.locationCountry)
                      ? JSON.parse(props.profileData.locationCountry)
                        .name
                      : ""
                    : ""}
                </p>
                <p className="fs-regular fs-14 f-color mb_3">
                  State:{" "}
                  {props.profileData.locationState
                    ? IsJsonString(props.profileData.locationState)
                      ? JSON.parse(props.profileData.locationState).name
                      : ""
                    : ""}
                </p>
                {props.profileData.locationCity && (
                  <p className="fs-regular fs-14 f-color mb_3">
                    City:{" "}
                    {props.profileData.locationCity
                      ? IsJsonString(props.profileData.locationCity)
                        ? JSON.parse(props.profileData.locationCity)
                          .name
                        : ""
                      : ""}
                  </p>
                )}
              </div>
            </div>
          </div>
          <hr className="new1" />
          <p className="fs-semi-bold fs-16 f-color">Score Card</p>
          <div className="scorecardsection">
            <div>
              <p className="fs-regular fs-14 f-color">
                Total Questions
              </p>
              <p className="fs-semi-bold fs-18">{totalQuestions}</p>
            </div>
            <div className="ml_41">
              <p className="fs-regular fs-14 f-color">
                Total Correct Answers
              </p>
              <span className="fs-semi-bold fs-18 ml_5 green_text">
                {totalCorrectAnswers}
              </span>
              <span className="fs-regular fs-18 ml_5">out of</span>
              <span className="fs-semi-bold fs-18 ml_5">
                {totalQuestions}
              </span>
            </div>
            <div className="ml_41">
              <p className="fs-regular fs-14 f-color">
                Skill Test Score
              </p>
              <span className="fs-semi-bold fs-18 ml_5 green_text">
                {totalCorrectAnswers}
              </span>
              <span className="fs-regular fs-18 ml_5">out of</span>
              <span className="fs-semi-bold fs-18 ml_5">
                {totalQuestions}
              </span>
            </div>

            <div className="ml_41">
              <p className="fs-regular fs-14 f-color">
                Number of Violations
              </p>
              <span className="fs-semi-bold fs-18 ml_5 green_text">
                {skillName.NumberofViolations
                  ? skillName.NumberofViolations
                  : 0}
              </span>
            </div>
          </div>
          <hr className="new1 " />
          <p className="fs-semi-bold fs-16 f-color">Questions</p>

          <div className="row mt_17">
            <AliceCarousel responsive={responsive}>
              {userAnswerList.map((question, index) =>
                userAnswerList[Number(index)]
                  ?.userTestQuestionUserTestQuestionAnswer?.length ===
                  0 ? (
                  <button
                    type="button"
                    className={
                      questionIndex === index
                        ? "btnquestion btnquestion-questionselected  cp"
                        : "btnquestion btnquestion-questiondisable  cp"
                    }
                    key={index}
                    onClick={() => questionsHandler(index)}
                  >
                    <span>{index + 1}</span>
                    <span className="btn-icon">
                      <img
                        src={
                          questionIndex === index
                            ? Group175img
                            : Group174img
                        }
                        alt=""
                      />
                    </span>
                  </button>
                ) : question.isCorrect === true ? (
                  <button
                    type="button"
                    className={
                      questionIndex === index
                        ? "btnquestion btnquestion-questionselected  cp"
                        : "btnquestion btnquestion-questionright  cp"
                    }
                    key={index}
                    onClick={() => questionsHandler(index)}
                  >
                    <span>{index + 1}</span>
                    <span className="btn-icon">
                      <img src={Group175img} alt="" />
                    </span>
                  </button>
                ) : question.isCorrect === false ? (
                  <button
                    type="button"
                    className={
                      questionIndex === index
                        ? "btnquestion btnquestion-questionselected  cp"
                        : "btnquestion btnquestion-questionwrong  cp"
                    }
                    key={index}
                    onClick={() => questionsHandler(index)}
                  >
                    <span>{index + 1}</span>
                    <span className="btn-icon">
                      <img src={Group175img} alt="" />
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className={
                      questionIndex === index
                        ? "btnquestion btnquestion-questionselected  cp"
                        : "btnquestion btnquestion-questiondisable  cp"
                    }
                    key={index}
                    onClick={() => questionsHandler(index)}
                  >
                    <span>{index + 1}</span>
                    <span className="btn-icon">
                      <img src={Group175img} alt="" />
                    </span>
                  </button>
                )
              )}
            </AliceCarousel>
          </div>
          <hr className="new1 mt_17" />
          <div className="row df skillvideo_btm_adjust">
            <div className="mw">
              <div className="df">
                <div>
                  <p className="fs-regular fs-14 f-color mr_10">
                    Video response time limit:
                  </p>
                  <p className="fs-semi-bold fs-16 f-color">
                    Response time:
                  </p>
                </div>

                <div>
                  <p className="fs-regular fs-14 f-color">
                    {/* <span className="time_icon">
                              <img src={timerImage} />
                            </span> */}
                    {/* {userAnswerList[questionIndex]?.userTestQuestionQuestion
                              ?.timeLimit + " seconds"} */}
                    {minToSec(
                      userAnswerList[questionIndex]
                        ?.userTestQuestionQuestion?.timeLimit
                    )}
                  </p>
                  <p className="fs-semi-bold fs-14 f-color">
                    {userAnswerList[questionIndex]?.userTimeTaken +
                      " seconds"}
                  </p>
                </div>
              </div>
              {userAnswersResponse.filter(
                (item) =>
                  item.id === userAnswerList[questionIndex]?.userTestId
              )[0]?.isDeleted ? (
                <video
                  autoplay
                  // src={
                  //   noVideo
                  // }
                  className="video-interview-test mt_17"
                  controls={true}
                  poster={noVideo}
                />
              ) : (
                <video
                  autoplay
                  src={
                    userAnswerList
                      ? userAnswerList.length > 0
                        ? userAnswerList[questionIndex]
                          ? userAnswerList[questionIndex].videoURL
                            ? userAnswerList[questionIndex].videoURL
                            : ""
                          : ""
                        : ""
                      : ""
                  }
                  className="video-interview-test mt_17"
                  controls={true}
                />
              )}
            </div>
            <div className="vl"></div>
            <div className="mw ml_22 scrollHeight1 viewtestresults-questionsides interview-question-scroll1">
              <div className="fs-semi-ui-class fs-16 f-color videobuttomquestion">
                <span className="fs-20">Q.{questionNumber}</span>
                <span className="pt_4  pl_6" id="testQuestionDescp">
                 
                  {testQuestionDescription(
                    userAnswerList[Number(questionIndex)]
                      ? userAnswerList[Number(questionIndex)]
                        .userTestQuestionQuestion.questionDesc
                      : ""
                  )}
                </span>
              </div>
              {userAnswerList[questionIndex]
                ? userAnswerList[questionIndex].questionimg === ""
                  ? ""
                  : 
                  null
                : null}
              {userAnswerList[questionIndex]
                ? userAnswerList[questionIndex].userTestQuestionQuestion
                  ? userAnswerList[questionIndex]
                    .userTestQuestionQuestion.questionAnswer
                    ? userAnswerList[questionIndex]
                      .userTestQuestionQuestion.questionAnswer
                      .length > 0
                      ? userAnswerList[
                        questionIndex
                      ].userTestQuestionQuestion.questionAnswer.map(
                        (options, index) => (
                          <p
                            className={
                              userAnswerList[
                                Number(questionIndex)
                              ]?.userTestQuestionUserTestQuestionAnswer.find(
                                (el) =>
                                  el.userTestQuestionAnswerAnswer
                                    .isCorrect === true
                              )
                                ? "questionoption questionoption-alignment right-question-option"
                                : "questionoption questionoption-alignment wrong-question-option"
                            }
                            key={"v-ops-" + index}
                          >
                            {userAnswerList[Number(questionIndex)]
                              .userTestQuestionQuestion
                              .questionAnswertype.code ==
                              "SingleChoice" ? (
                              <>
                                <input
                                  type="radio"
                                  name="radio-group"
                                  id={options.id + "-radio-" + index}
                                  checked={
                                    userAnswerList[
                                      Number(questionIndex)
                                    ]
                                      ?.userTestQuestionUserTestQuestionAnswer[0]
                                      ?.answerId == options.id
                                  }
                                  //  == userAnswerList[Number(questionIndex)].userTestQuestionUserTestQuestionAnswer[0].id
                                  value={options.id}
                                  disabled={true}
                                />
                                <label
                                  htmlhtmlFor={options.id}
                                  className="fs-semi-ui-class fs-18 f-color"
                                >
                                  <div>
                                    {options.answerText &&
                                      options.answerText.includes("\n")
                                      ? options.answerText
                                        .split("\n")
                                        .map((item, idx) => (
                                          <p
                                            key={idx}
                                            className="without-linebreak"
                                            dangerouslySetInnerHTML={{
                                              __html: item
                                            }}
                                          >
                                          </p>
                                        ))
                                      : options.answerText
                                        .split("\\n")
                                        .map((item, idx) => (
                                          <span
                                            key={idx}
                                            className="without-linebreak"
                                            dangerouslySetInnerHTML={{
                                              __html: item
                                            }}
                                          >
                                          </span>
                                        ))}
                                  </div>
                                </label>
                              </>
                            ) : (
                              <div className="checkAnswer">
                                <form>
                                  <div className="interview-chkbox">
                                    <input
                                      type="checkbox"
                                      className={
                                        userAnswerList[
                                          Number(questionIndex)
                                        ].isCorrect
                                          ? "right-checkbox clearcheckbox"
                                          : "wrong-checkbox clearcheckbox"
                                      }
                                      id={
                                        options.id +
                                        "-checkbox-" +
                                        index
                                      }
                                      checked={userAnswerList[
                                        Number(questionIndex)
                                      ].userTestQuestionUserTestQuestionAnswer.find(
                                        (el) =>
                                          el.answerId == options.id
                                      )}
                                      value={options.id}
                                      disabled={true}
                                    />
                                    <label htmlFor={options.id} className="optionAlignment">
                                      <div>
                                        {options.answerText &&
                                          options.answerText.includes(
                                            "\n"
                                          )
                                          ? options.answerText
                                            .split("\n")
                                            .map((item, idx) => (
                                              <p
                                                key={idx}
                                                className="without-linebreak"
                                                dangerouslySetInnerHTML={{
                                                  __html: item
                                                }}
                                              ></p>
                                            ))
                                          : options.answerText
                                            .split("\\n")
                                            .map((item, idx) => (
                                              <span
                                                key={idx}
                                                className="without-linebreak"
                                                dangerouslySetInnerHTML={{
                                                  __html: item
                                                }}
                                              >
                                              </span>
                                            ))}
                                      </div>
                                    </label>
                                  </div>
                                </form>
                              </div>
                            )}
                          </p>
                        )
                      )
                      : null
                    : null
                  : null
                : null}
              {userAnswerList[Number(questionIndex)] ? (
                userAnswerList[Number(questionIndex)].isCorrect ? (
                  <div className="mt_17">
                    {userAnswerList[Number(questionIndex)]
                      ?.isCorrect === true ? (
                      <img src={correct} />
                    ) : null}
                  </div>
                ) : (
                  <div className="mt_17">
                    {userAnswerList[Number(questionIndex)]
                      ?.isCorrect === false ? (
                      <img src={incorrect} />
                    ) : null}
                  </div>
                )
              ) : null}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CandidateTest;
