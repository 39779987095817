import axios from "axios";
const jsonConfig = require("../../Config.json");

const uploadResumeLogs = async (payload) => {
    let allResponse = {};
    try {
        const customLogoutData = await axios.post(
            jsonConfig.apiUrl + "uploadResume",
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = customLogoutData;

        return Promise.resolve(allResponse);
    } catch (error) {
        console.log("errrrrr", error);
        return Promise.reject(error);
    }
};

export default uploadResumeLogs;
