import axios from "axios";
const jsonConfig = require("../../Config.json");

const pimaryMySkillByProfileIdResponse = async (candidateId) => {
  let allResponse = {};
  try {
    const primarySkillsData = await axios.get(
      jsonConfig.apiUrl + "primaryMySkillByProfileId/" + candidateId,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = primarySkillsData;

    return Promise.resolve(allResponse);
  } catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
};

export default pimaryMySkillByProfileIdResponse;
