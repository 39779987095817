import axios from "axios";
const jsonConfig = require("../../Config.json");

export const getAllSPoc = async () => {
  try {
    const getResponse = await axios.get(
      jsonConfig.apiUrl + "spoc",
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      });

    return Promise.resolve(getResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

  export const addSpoc = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "spoc",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getSpocById = async (id) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "spoc/" + id,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateSpoc = async (id, data) => {
    try {
      const getResponse = await axios.put(
        jsonConfig.apiUrl + "SPOCById/" + id,
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getSPOCByOrg = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "SPOCByOrg",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };