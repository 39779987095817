import axios from "axios";
const jsonConfig = require("../Config.json");

const putEditNewRecruitmentPartnersAndClients = async (clientData) => {
  try {
    const getAllResponse = await axios.put(jsonConfig.apiUrl + "organizations/" + clientData.id, clientData, {
          headers: {
           "x-functions-key": jsonConfig.key,
           },
       });
     return Promise.resolve(getAllResponse)
    
  } catch (error) {
        return Promise.reject(error)
     }
}
export default putEditNewRecruitmentPartnersAndClients;