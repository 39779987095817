import {
  
  useState,
  useEffect,
  useRef,

  useContext
} from "react";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import "../header/header.css";
import Logo from "../header/images/logoNew.png";
import { useHistory } from "react-router";

import {
  NotificationContainer,

} from "react-notifications";

import "./adminHeader.css";
import { UserTypeContext } from "../../AppRoute";
const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const Header = (props) => {
const { isDataLoaded}=props
  const userType = useContext(UserTypeContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const history = useHistory();
  const classes = useStyles();
 
  const [navDisplay, setnavDisplay] = useState(false);
 
  const hamburger = useRef(0);
  const [isNavigationOpen, setisNavigationOpen] = useState(false);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }




  const goToSearch = () => {
    history.push("/search");
  };
  const goToUserList = () => {
    history.push("/users");
  };
  
  const goToAddPrimarySkill = () => {
    history.push("/skills");
  };
  const goToQuestionreport = () => {
    history.push("/reported-question");
  };
  const goToDomain = () => {
    history.push("/domain");
  };
  const goToQuestionList = () => {
    history.push("/questions");
  };
  const goToSubskills = () => {
    history.push("/subskill");
  };
  const goToEmailConfigaration = () => {
    history.push("/emailconfiguration");
  };
  const goToRecruiterPrivateSkills = () => {
    history.push("/client-specific-test-access");
  };
  const goToRecruitmentPartnersAndClients = () => {
    history.push("/recruitment-organizations-customers-and-agencies");
  };
  const goToReferral = () => {
    history.push("/admin-referral");
  };
  const goToGlobalConfiguration = () => {
    history.push("/globalconfiguration");
  };
  const goToCandidate = () => {
    history.push("/pending-candidate-invites");
  };

  const goToRecruiters = () => {
    history.push("/sign-up-requests");
  };

  const goToAllTests = () => {
    history.push("/tests");
  };

  const signOut = () => {
    localStorage.removeItem("userID");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    window.open("/", "_self");
  };


  return (
    <>
      {/* New navbar */}
      <div className="nav_bg header_fixed">
        <div className="nav_bg_home home-specific-header">
          <div className="container w1200 nav_bg_home_section">
            <div className="nav_bg_left">
              <li  className={isDataLoaded?"cp listStyle":"listStyle"} >
                <span>
                  <img src={Logo} className="nav_logo_home" alt="img" onClick={(e) =>isDataLoaded? history.push("/"):()=>false} />
                </span>
              </li>
            </div>
            <div className="nav_bg_right">
              <ul className="navbar admin-header-navbar">
                {userType === "Recruiter" ? (
                  <></>
                ) : userType === "Admin" ? (
                  <>
                    <li>
                      <div className={classes.root}>
                        <div>
                          <Button
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                          >
                            Admin
                          </Button>
                          <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <MenuItem onClick={() => goToUserList()}>
                                        Manage Users
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => goToEmailConfigaration()}
                                      >
                                        Email Configuration
                                      </MenuItem>
                                      <MenuItem onClick={() => goToSearch()}>
                                        Search Candidates
                                      </MenuItem>
                                      <MenuItem onClick={() => goToDomain()}>Domains</MenuItem>
                                      <MenuItem
                                        onClick={() => goToAddPrimarySkill()}
                                      >
                                        Skills
                                      </MenuItem>
                                      <MenuItem onClick={() => goToSubskills()}>
                                        Sub-Skills
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => goToQuestionList()}
                                      >
                                        Questions
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          goToRecruitmentPartnersAndClients()
                                        }
                                      >
                                        Recruitment Organizations, Customers &
                                        Agencies
                                      </MenuItem>
                                      <MenuItem onClick={() => goToReferral()}>
                                        Referral
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          goToRecruiterPrivateSkills()
                                        }
                                      >
                                        Client-Specific Test Access
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          goToGlobalConfiguration()
                                        }
                                      >
                                        Global Configuration
                                      </MenuItem>
                                      <MenuItem onClick={() => goToQuestionreport()}>Reported Questions</MenuItem>
                                      <MenuItem onClick={() => goToCandidate()}>Pending Candidate Invites</MenuItem>
                                      <MenuItem
                                        onClick={() => goToRecruiters()}
                                      >
                                        Sign-Up Requests
                                      </MenuItem>
                                      <MenuItem onClick={() => goToAllTests()}>
                                        Tests
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </div>
                      </div>
                    </li>
                  </>
                ) : null}

                <li onClick={signOut} className="LinkForHeader_admin cp df">
                  <div></div>
                  <div className="pt_3 pl_3">Sign Out</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="nav_bg_home_responsive">
          <div className="nav_bg_home_responsive-wrapper">
            <div className="home-top-nav">
              <li className="cp listStyle" onClick={(e) => history.push("/")}>
                <span>
                  <img src={Logo} className="nav_logo_home" alt="img" />
                </span>
              </li>

              {navDisplay ? (
                <>
                  <div ref={hamburger} className="itemlist">
                    <ul className="navbar_mobile">
                      <li
                        onClick={(e) => setisNavigationOpen(!isNavigationOpen)}
                        className="LinkForHeader_admin cp df responsive_li_adminpages menuBorder"
                      >
                        <div className="pt_1 pl_3">Admin</div>
                      </li>
                      <li
                        onClick={signOut}
                        className="LinkForHeader_admin cp df"
                      >
                        <div className="mobilemenuleft_adjust"></div>
                        <div className="pt_1 pl_3">Sign Out</div>
                      </li>
                    </ul>
                  </div>
                </>
              ) : null}
              <a
              
                className="icon"
                onClick={(e) => setnavDisplay(!navDisplay)}
              >
                <i className="fa fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
      {isNavigationOpen ? (
        <div className="dropdown-content cp">
          <li onClick={() => goToUserList()}>Manage Users</li>
          <li onClick={() => goToEmailConfigaration()}>Email Configuration</li>
          <li onClick={() => goToSearch()}>Search Candidates</li>
          <li onClick={() => goToDomain()}>Domains</li>
          <li onClick={() => goToAddPrimarySkill()}>Skills</li>
          <li onClick={() => goToSubskills()}>Sub-Skills</li>
          <li onClick={() => goToQuestionList()}>Questions</li>
          <li onClick={() => goToRecruitmentPartnersAndClients()}>
            {" "}
            Recruitment Organizations, Customers & Agencies
          </li>
          <li onClick={() => goToReferral()}>Referral</li>
          <li onClick={() => goToRecruiterPrivateSkills()}>
            Client-Specific Test Access
          </li>
          <li onClick={() => goToGlobalConfiguration()}>
            Global Configuration
          </li>
          <li onClick={() => goToQuestionreport()}>Reported Questions</li>
          <li onClick={() => goToCandidate()}>Pending Candidate Invites</li>
          <li onClick={() => goToRecruiters()}>Sign-Up Requests</li>
          <li onClick={() => goToAllTests()}>Tests</li>
        </div>
      ) : null}
    </>
  );
};
export default Header;
