import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { Container } from "react-bootstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import { Autocomplete } from "@material-ui/lab";
import jsonConfig from "../../Config.json";
import { process } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-grid";
import { Grid as KendoGrid } from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import updateConfigurationResponse from "../../apis/globalConfiguration/updateGlobalConfiguration";
import OrganizationConfig from "../../apis/globalConfiguration/organizationConfig";
import OrganizationConfigEdit from "../../apis/globalConfiguration/organizationConfigEdit";
import OrganizationConfigDelete from "../../apis/globalConfiguration/organizationConfigDelete";
import "./TestTakingStyle.css";
import { async } from "q";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  deleteButtonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
    textAlign: "center"
  },
  title: {
    padding: "16px 30px!important",
  },
  label: {
    display: "inline-block",
    fontSize: "17px",
    color: "#04093F",
    fontFamily: "Segoe-Regular",
    fontStyle: "normal",
    marginBottom: "10px !important"
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
});

const initialDataState = {
  sort: [
    {
      field: "code",
      dir: "asc",
    },
  ],
  take: 10,
  skip: 0,
};

const TestTakingInterval = ({ data, org, userType, setisDataUpdaed }) => {
  // "org" is the API data for getting all R.O
  const classes = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [dataForEdit, setDataForEdit] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedDays, setSelectedDays] = useState(null);
  const [isDataUpdated, setIsDataUpdated] = useState(true);
  const [dataState, setDataState] = React.useState(initialDataState);
  const [result, setResult] = useState([]);
  const [atvBtn, setAtvBtn] = useState(false);

  // editing button on click
  const handleEdit = (props) => {
    return (
      <td className="actionContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal(props.dataItem)}
        >
          Edit
        </Button>
      </td>
    );
  };

  const closeModal = () => {
    if (openEditModal) {
      setOpenEditModal(false);
    } else {
      setOpenAddModal(false);
    }
    setSelectedOrg(null);
    setSelectedDays(null);
    setDataForEdit(null);
    setDeleteData(null);
  };
  const openModal = (list) => {
    setOpenEditModal(true);
    // data for edit modal
    setDataForEdit(list);
    setSelectedOrg(list.recruitmentpartner);
    setSelectedDays(list.valuInDays);
    setDeleteData(list);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  }

  const openDeleteModal = (list) => {
    setDeleteModal(true);
  }

  const openAddForm = () => {
    setOpenAddModal(true);
  };

  const addHandler = async () => {
    setAtvBtn(true);
    if (selectedOrg === null || selectedDays === null) {
      setAtvBtn(false);
      NotificationManager.error("Please fill in all the fields", "Error", 3000);
      return;
    }
    else if (selectedDays <= 0) {
      setAtvBtn(false);
      NotificationManager.error("Please enter a valid number of days", "Error", 3000);
      return;
    } else {
      const payload = {
        code: "TestReminderInterval",
        name: "Test Reminder Interval",
        value: (selectedDays * 24 * 60).toString(), // in minutes
        organizationId: selectedOrg?.id,
        createdBy: userType?.id,
        updatedBy: userType?.id,
      };

      try {
        const resp = await OrganizationConfig(payload);
        if (resp.data.message === "This organization already exist in our system") {
          setAtvBtn(false);
          NotificationManager.error("This organization is already existed in our system", "Error", 3000);
        }
        else {
          NotificationManager.success("Data added successfully", "Success", 3000);
          closeModal();
          setAtvBtn(false);
          setIsDataUpdated(true);
        }
      } catch (err) {
        console.log("Error", err);
        setAtvBtn(false);
        return;
      }
    }
  };

  const updateHandler = async () => {
    setAtvBtn(true);
    if (selectedOrg === null || selectedDays === null) {
      setAtvBtn(false);
      NotificationManager.error("Please fill in all the fields", "Error", 3000);
      return;
    }
    else if (selectedDays <= 0) {
      setAtvBtn(false);
      NotificationManager.error("Please enter a valid number of days", "Error", 3000);
      return;
    } else {
      const payload = {
        code: "TestReminderInterval",
        name: "Test Reminder Interval",
        value: (selectedDays * 24 * 60).toString(), // in minutes
        organizationId: selectedOrg?.id,
        createdBy: userType?.id,
        updatedBy: userType?.id,
      };

      try {
        const resp = await OrganizationConfigEdit(payload, dataForEdit?.id);
        NotificationManager.success("Data updated successfully", "Success", 3000);
        setAtvBtn(false);
        closeModal();
        setIsDataUpdated(true);
      } catch (err) {
        console.log("Error", err);
        setAtvBtn(false);
        return;
      }
    }
  };

  const deleteHandler = async () => {
    if (deleteData?.id) {
      try {
        const res = await OrganizationConfigDelete(deleteData?.id);
        NotificationManager.success("Data is deleted successfully", "Success", 3000);
        setIsDataUpdated(true);
        setDeleteModal(false);
        setDeleteData("");
        closeModal();
      }
      catch (err) {
        console.log("Error", err);
      }
    }
  }

  const orgChange = async (event, value) => {
    setSelectedOrg(value);
  };

  const changeDays = async (e) => {
    setSelectedDays(e.target.value);
  };

  const getAllDataOfOrg = async () => {

    try {
      const resp = await OrganizationConfig();
      resp?.data?.data.map(
        (item, i) => (item.valuInDays = parseInt(item.value) / 24 / 60)
      );

      setResult(resp?.data?.data || []);
      return;
    } catch (err) {
      console.log("Error", err);
      return err;
    }

  };

  useEffect(() => {

    if (isDataUpdated) {
      getAllDataOfOrg();

      setIsDataUpdated(false);
    }

  }, [isDataUpdated]);

  return (
    <>
      <h3 className="fs-semi-bold fs-30 f-color">Test Interval</h3>
      {/*KENDO GRID */}
      <div className="addIntervalButton">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openAddForm()}
        >
          Add New Test Reminder
        </Button>
      </div>

      <Grid
        pageable={true}
        sortable={true}
        filterable={true}
        data={process(result, dataState)}
        {...dataState}
        onDataStateChange={(e) => {
          setDataState(e.dataState);
        }}
      >
        <Column
          field="recruitmentpartner.name"
          title="Recruitment Organization"
          width="200px"
          resizable={true}
          filterable={true}
        />
        <Column
          field="valuInDays"
          title="Days"
          width="200px"
          filter="numeric"
          resizable={true}
          filterable={true}
        />
        <Column
          title="Action"
          width="200px"
          resizable={true}
          filterable={false}
          cell={handleEdit}
        />
      </Grid>

      {/* Edit Modal */}
      <Dialog
        open={openEditModal}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          Edit Test Reminder
        </DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="select customized-select">
            <div>
              <label className={`${classes.label}`} htmlFor="country">
                Recruitment Organization*
              </label>
              <Autocomplete
                id="combo-box-demo"
                className="mt_11"
                options={org.filter((item) => item.isActive === true)}
                autoHighlight
                disabled
                value={selectedOrg}
                getOptionLabel={(option) => option?.name || ""}
                getOptionSelected={(option, value) =>
                  value ? option.id === value.id : true
                }
                onChange={orgChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    placeholder="Select Recruitment Organization"
                  />
                )}
              />
            </div>
            <div className="mt_15">
              <label className="field_name" htmlFor="country">
                Days*
              </label>
              <input
                type="number"
                className="text_box_cleartalent custom-text-width-demo-email configaration_input mt_8"
                defaultValue={selectedDays || ""}
                min="0"
                onChange={(e) => changeDays(e)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn-big btn-skill cp"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-big btn-skill cp"
            onClick={openDeleteModal}
          >
            Delete
          </button>
          <button variant="outlined" className="btn5 btn_primary cp" disabled={atvBtn} onClick={updateHandler}>
            <div className="btn_loader">
              Submit
              &nbsp;
              {atvBtn &&
                <div >
                  <CircularProgress size={15} />
                </div>
              }
            </div>
          </button>
        </DialogActions>
      </Dialog>

      {/* Add modal */}
      <Dialog
        open={openAddModal}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          Add New Test Reminder
        </DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="select customized-select">
            <div>
              <label className={`${classes.label}`} htmlFor="country">
                Recruitment Organization*
              </label>
              <Autocomplete
                id="combo-box-demo"
                className="mt_11"
                options={org.filter((item) => item.isActive === true)}
                autoHighlight
                value={selectedOrg || null}
                getOptionLabel={(option) => option?.name || ""}
                getOptionSelected={(option, value) =>
                  value ? option.id === value.id : true
                }
                onChange={orgChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    placeholder="Select Recruitment Organization"
                  />
                )}
              />
            </div>
            <div className="mt_15">
              <label className="field_name" htmlFor="country">
                Days*
              </label>
              <input
                type="number"
                className="text_box_cleartalent custom-text-width-demo-email configaration_input mt_8"
                value={selectedDays || ""}
                min="0"
                onChange={(e) => changeDays(e)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn5 btn-skill cp"
            onClick={closeModal}
          >
            Cancel
          </button>

          <button variant="outlined" className="btn5 btn_primary cp" disabled={atvBtn} onClick={addHandler}>
            <div className="btn_loader">
              Submit
              &nbsp;
              {atvBtn &&
                <div >
                  <CircularProgress size={15} />
                </div>
              }
            </div>
          </button>
        </DialogActions>
      </Dialog>

      {/* Delete modal */}
      <Dialog
        open={deleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          Delete Test Reminder
        </DialogTitle>
        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="fs-semi-bold f-color fs-18">
            Do you want to delete the test reminder for {deleteData?.recruitmentpartner?.name} ?
          </div>
        </DialogContent>
        <DialogActions className={`${classes.deleteButtonForSupport}`}>
          <button
            type="button"
            className="btn-big btn-skill cp"
            onClick={closeDeleteModal}
          >
            No
          </button>
          <button
            type="button"
            className="btn-big btn_primary cp"
            onClick={deleteHandler}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TestTakingInterval;
