import axios from "axios";
const jsonConfig = require("../../Config.json");

const getSkillTestEligibility = async (profileId) => {
    let isSkillTestAvailable=false
    try {
        let payload={
            profileid:profileId
        }
       const getSkillTestEligibilityresponse = await axios.post(
          jsonConfig.apiUrl + "verifySkillTestEligibility",payload,
          {
             headers: {
                "x-functions-key": jsonConfig.key,
             },
          }
       );
        isSkillTestAvailable = getSkillTestEligibilityresponse.data;

    return  Promise.resolve(isSkillTestAvailable) 
    }
    catch (error) {
    //    console.error(error);
    return   Promise.reject(error)
    }
 }

 export default getSkillTestEligibility;