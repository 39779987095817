import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import Footer from "../../components/adminFooter/Footer";
import "./accountSetting.css";
import { Button, Card, CardActions, CardContent, CircularProgress, ClickAwayListener, FormControl, Grid, MenuItem, MenuList, OutlinedInput, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { BlobServiceClient } from "@azure/storage-blob";
import user_register from "../../components/basicInfoForm/images/avatar.png";
import profilePictureUpdateResponse from "../../apis/profile/profilePictureUpdate";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../components/profile/profileCompletion/cropImage";
import Slider from "@material-ui/core/Slider";
import { FilePicker } from "react-file-picker";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import cross from "../../components/profile/profileCompletion/images/cross.svg";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Header from "../../components/headerAdmin/Header"
import updateUserResponse from "../../apis/users/updateRecruiter.js";
import { Autocomplete } from "@material-ui/lab";
import updateProfile from "../../apis/profile/updateProfile.js";
import HomeContactUsModal from "../home/homeHeader/homeContactUsModal/homeContactUs.js";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { UserTypeContext } from "../../AppRoute";

const useStyles = makeStyles((theme) => ({
    buttonAlign: {
        display: "flex",
        alignItems: "center",
        padding: "24px 42px 24px 42px !important",
    },
    bodyForEditProfile: {
        marginTop: "70px",
    },
    changeProfiletitle: {
        padding: "16px 42px!important",
    },
    changeprofilebody: {},
    dialog: {
        position: "absolute",
        right: "2.5%",
        top: "6%",
        borderRadius: "10px",
    },
    data: {
        opacity: "50%",
        fontFamily: "Segoe UI",
        fontSize: 20,
    },
    dataCountry: {
        opacity: "50%",
        fontFamily: "Segoe UI",
        fontSize: 20,
        width: 230
    },
    dataPhoneNumber: {
        opacity: "50%",
        fontFamily: "Segoe UI",
        fontSize: 20,
        width: 200
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: "45px",
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonForProfilePic: {
        position: "absolute",
        right: "8px",
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitleForProfilePic = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <div className="crossFormodal cp" onClick={onClose}>
                    <img className="closeicon_set" src={cross} alt=""></img>
                </div>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {},
}))(MuiDialogContent);
const DialogContentForprofilepic = withStyles((theme) => ({
    root: {
        marginTop: "0px!important",
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
}))(MuiDialogActions);
// for tooltip
const AccountSetting = () => {
    const { userType, userIdType, userRecords } = useContext(UserTypeContext);
    const goToDashboard = () => {
        history.push("/dashboard")

    };

    const jsonConfig = require("../../Config.json");
    const classes = useStyles();

    const [userData, setUserData] = useState({
        firstName: userRecords?.firstName,
        lastName: userRecords?.lastName,
        email: userRecords?.email,
        phoneNumber: userRecords?.phoneNumber,
        countryCode: userRecords?.countryCode,
        country: userRecords?.profile?.locationCountry ? JSON.parse(userRecords?.profile?.locationCountry) : {},
        recruitmentOrganization: userRecords?.recruitmentpartner?.name,
        displayName: userRecords?.displayName,
        profilePicture: userRecords?.profile?.profilePicUrl,
        organizationLogo: userRecords?.recruitmentpartner?.recruitmentpartnerLogoUrl,
        orgcode:userRecords?.recruitmentpartner?.code,   
    })
    const [open, setOpen] = useState(false);
    const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
    const userCameraStreamRef = useRef(null);
    const [profilePicBlob, setprofilePicBlob] = useState("");
    const [display, setdisplay] = useState(false);
    const [modalProfilePictureUrl, setModalProfilePictureUrl] = useState(user_register);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [previousImageURL, setPreviousImageURL] = useState(userRecords?.profile?.profilePicUrl ? userRecords?.profile?.profilePicUrl : "")
    const [phoneError, setPhoneError] = useState(false);
    const [phoneNumberChanged, setPhoneNumberChanged] = useState(false)
    const [isDefaultProfile, setisDefaultProfile] = useState(false)
    const [ischange, setischange] = useState(true);
    const [isUserDataUpdating, setisUserDataUpdating] = useState(false);
    const [isProfilePictureUploading, setisProfilePictureUploading] = useState(false);
    const [isProfilePictureChanged, setisProfilePictureChanged] = useState(false);
    const [isProfilePictureRemoving, setisProfilePictureRemoving] = useState(false);
    const history = useHistory();

    const _modalClose = () => {
        setOpen(false);
    }


    const firstNameChange = (e) => {
        setischange(false);
        setUserData({ ...userData, firstName: e.target.value })
    }

    const lastNameChange = (e) => {
        setischange(false);
        setUserData({ ...userData, lastName: e.target.value })
    }

    const keypressHandler = (e) => {
        var invalidChars = [
            "-",
            "+",
            "e",
        ];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    }

    const phoneNumberChangeHandler = (val) => {
        setPhoneNumberChanged(true);
        if (val) {
            if (val && isPossiblePhoneNumber(val)) {
                setischange(false)
                setPhoneError(false)
                let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
                setUserData({ ...userData, phoneNumber: val.replace(countryCode_PhoneNumber, ""), countryCode: countryCode_PhoneNumber })
            }
            else {
                setPhoneError(true)
            }
        }
        else {
            setPhoneError(false)
            setUserData({ ...userData, countryCode: "" })
            setUserData({ ...userData, phoneNumber: "" })
        }
    };

    const [countryList, setCountryList] = useState([
        {
            currency: "USD",
            flag: "",
            isoCode: "US",
            latitude: "38.00000000",
            longitude: "-97.00000000",
            name: "United States",
            phonecode: "+1",
        },
        {
            currency: "CAD",
            flag: "",
            isoCode: "CA",
            latitude: "60.00000000",
            longitude: "-95.00000000",
            name: "Canada",
            phonecode: "+1",
        },
        {
            currency: "INR",
            flag: "",
            isoCode: "IN",
            latitude: "20.00000000",
            longitude: "77.00000000",
            name: "India",
            phonecode: "+91",
        },
        {
            currency: "MXN",
            flag: "",
            isoCode: "MX",
            latitude: "23.00000000",
            longitude: "-102.00000000",
            name: "Mexico",
            phonecode: "+52",
        }
    ]);

    const countryHandler = (event, value) => {
        if (value) {
            setischange(false)
            setUserData({ ...userData, country: value })
        } else {
            setUserData({ ...userData, country: "" })
        }

    };

    /*  --------------------------------------------------------------------- */

    const showCroppedImage = useCallback(async () => {
        try {
            setisProfilePictureUploading(true);
            const croppedImage = await getCroppedImg(
                modalProfilePictureUrl,
                croppedAreaPixels,
                rotation
            );
            var block = croppedImage.split(";");

            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            await b64toBlob(realData, contentType, true);
            setisProfilePictureUploading(false);
            setdisplay(false);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation]);


    const showCroppedImage1 = useCallback(async (defaultProfilePicture) => {
        let cropArea = {
            height: 250,
            width: 250,
            x: 0,
            y: 0
        }
        try {
            const croppedImage = await getCroppedImg(
                user_register,
                cropArea,
                0

            );
            var block = croppedImage.split(";");

            var contentType = block[0].split(":")[1];
            var realData = block[1].split(",")[1];
            await b64toBlob(realData, contentType, false, defaultProfilePicture);
            setisProfilePictureRemoving(false);
            setdisplay(false);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, rotation]);



    const deleteCroppedImg = () => {
        const defaultProfilePicture = true;
        setisProfilePictureRemoving(true);
        showCroppedImage1(defaultProfilePicture);
    }



    const openProfilePictureModal = async () => {
        setModalProfilePictureUrl(userData?.profilePicture)
        !userData?.profilePicture ? <>{setisDefaultProfile(true)}</> : userData?.profilePicture.includes("default") ? setisDefaultProfile(true) : setisDefaultProfile(false)

        setProfilePictureEditModal(true);
    };
    const closeProfilePicModal = async () => {
        if (userCameraStreamRef.current) {
            userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
        }
        setisProfilePictureChanged(false);
        setProfilePictureEditModal(false);
        setUserData({ ...userData, profilePicture: userRecords?.profile?.profilePicUrl ? userRecords?.profile?.profilePicUrl : "" })
    };
    const b64toBlob = async (b64Data, contentType, status, defaultProfilePicture, sliceSize) => {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(
            byteArrays,
            { type: contentType },
            { name: "profile.png" }
        );
        await uploadToBlobStorage(blob, status, defaultProfilePicture);
        setprofilePicBlob(blob);
        const url = URL.createObjectURL(blob);
        setUserData({ ...userData, profilePicture: url })
    };
    const b64toBlobForCamera = async (b64Data, contentType, sliceSize) => {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(
            byteArrays,
            { type: contentType },
            { name: "profile.png" }
        );
        const url = URL.createObjectURL(blob);
        setModalProfilePictureUrl(url);
    };
    const filePickerOnChange = async (file) => {
        setisProfilePictureChanged(true);
        const downloadedUrl = URL.createObjectURL(file);
        setModalProfilePictureUrl(downloadedUrl);
    };


    const uploadToBlobStorage = async (blob, status, defaultProfilePicture) => {
        const sas = jsonConfig.sasToken;
        let decodedToken = decodeURI(sas);
        const bsClient = new BlobServiceClient(
            `${jsonConfig.ctResourcesCdn + sas}`
        );
        const containerClient = bsClient.getContainerClient("profilepicture");
        let timeStamp = new Date().getTime();

        const profileFileName = defaultProfilePicture ? "defaultProfile_" : "profile_";
        const blobName = userIdType + "/" + profileFileName + timeStamp + ".png";

        // const blobName = usersdata.id + "/" + "profile_" + timeStamp + ".png";

        const url = `${jsonConfig.ctResourcesCdn}/profilepicture/${blobName}`;
        const blobClient = containerClient.getBlockBlobClient(blobName);
        const response = await blobClient.uploadBrowserData(blob);
        var dt = new Date();
        var profileimagedata = {
            profilePicUrl: url,
            lastUpdated: dt,
            previousProfielPicUrl: previousImageURL
        };
        try {
            const payload = {
                userId: userIdType, profileimagedata: profileimagedata

            };

            const response = await profilePictureUpdateResponse(payload);
            if (status === true) {
                NotificationManager.success("Profile picture uploaded", "Success", 2500);
            }
            else {
                NotificationManager.success("Profile picture removed", "Success", 2500);
            }
            setUserData({ ...userData, profilePicture: response.data.data.profilePicUrl })

            setProfilePictureEditModal(false);

        } catch (error) {
            console.error(error);
        }
    };
    const constraints = {
        audio: false,
        video: {
            width: 640,
            height: 420,
        },
    };
    const opencameraModal = async () => {
        setdisplay(true);
        const video = document.getElementById("video");
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            window.stream = stream;
            video.srcObject = stream;
            userCameraStreamRef.current = stream;
        } catch (e) { }
    };
    const captureImage = async () => {
        setisProfilePictureChanged(true);
        const video = document.getElementById("video");
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        var a = context.drawImage(video, 0, 0, 640, 480);
        var b = context.getImageData(0, 0, 640, 480);
        const imageUrl = canvas.toDataURL("image/jpeg");
        var block = imageUrl.split(";");

        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        await b64toBlobForCamera(realData, contentType);
        setdisplay(false);
        userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    };



    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const updateUsers = async () => {
        if (phoneError === true) {
            NotificationManager.error(
                "Please enter a valid phone number",
                "Error",
                2500
            );
        }
        else if (userData?.firstName === "" || userData?.lastName === "" || userData?.country === "" || userData?.phoneNumber === "" || userData?.countryCode === "") {
            NotificationManager.error(
                "Please fill in all the details",
                "Error",
                2500
            );
        }
        else {
            setisUserDataUpdating(true);
            const payload = {
                id: userIdType,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                MiddleInitial: userRecords?.MiddleInitial,
                displayName: userData?.firstName + " " + userData?.lastName,
                countryCode: userData?.countryCode,
                phoneNumber: userData?.phoneNumber,
                email: userData?.email,
                roleId: userRecords?.roleId,
                b2cId: null,
                recruitmentpartnerId: userRecords?.recruitmentpartnerId,
                updatedBy: localStorage.getItem("userID"),
                isActive: userRecords?.isActive,
                locationCountry: JSON.stringify(userData?.country),
            }

            try {
                let res = await updateUserResponse(payload);
                NotificationManager.success(
                    "Profile updated successfully",
                    "Success",
                    2500
                );
                setischange(true)
                setisUserDataUpdating(false);
                window.location.reload();
            } catch (error) {
                console.log(error);
                if (error.response.status === 400) {
                    setisUserDataUpdating(false)
                    setischange(false)
                    const errorMessage = error.response.data.errMessage;
                    NotificationManager.error(errorMessage, "Error", 2500);
                }
            }
        }
    }

    return (
        <>
            {(userType !== "Admin" || userType !== "Candidate") ?
                (<><div className="mainContainer_acc">
                    <div className="topcontainer">
                        <Header />
                        <div className="bodyContainer">
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className="titlesofCommunity">Account Settings</div>
                                    <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashboard(e)}>
                                        <ArrowBackIcon /> Go Back to Dashboard
                                    </div>

                                    <div className="underline_Heading"></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Grid item xs={12} sm={4} md={3} lg={2}>
                                            <div className="leftsideDiv">
                                                <div className="upperLeftSection">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <div className="upper_left_img">
                                                                {userData?.profilePicture ? <>
                                                                    <img
                                                                        src={userData?.profilePicture}
                                                                        alt=""
                                                                        className="edit_profiles"
                                                                    />
                                                                </>
                                                                    :
                                                                    <>
                                                                        <img
                                                                            src={user_register}
                                                                            alt=""
                                                                            className="edit_profiles"
                                                                        />

                                                                    </>}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <div className="editContainer_left" onClick={openProfilePictureModal}>
                                                                <div className="Edit_name">Edit</div>
                                                                <EditIcon className="icon1" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className="display_rec_name">{userData?.displayName}</div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <div className="logo_main_div">
                                                                <div className="edit_logo_div">
                                                                    {userData?.organizationLogo && (
                                                                        <img
                                                                            src={userData?.organizationLogo}
                                                                            alt="logo"
                                                                            className="edit_logos"
                                                                        />
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={8} md={9} lg={10}>
                                            <div className="rightsideDiv">
                                                <Card className="rightBody">
                                                    <div className="rightTopSection">

                                                        <div className='userDetailFieldContainer'>
                                                            <div className='userDetails_headings'>First Name*</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="Please enter firstname"
                                                                    className="userDetails_textbox_outline"
                                                                    value={userData?.firstName}
                                                                    onChange={(e) => firstNameChange(e)}
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div className='userDetailFieldContainer'>
                                                            <div className='userDetails_headings'>Last Name*</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="Please enter lastname"
                                                                    className="userDetails_textbox_outline"
                                                                    value={userData?.lastName}
                                                                    onChange={(e) => lastNameChange(e)}
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div className='userDetailFieldContainer_email'>
                                                            <div className='userDetails_headings'>Email</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="Please enter email"
                                                                    className="userDetails_textbox_outline"
                                                                    value={userData?.email}
                                                                    disabled={true}
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div className='userDetailFieldContainer'>
                                                            <div className='userDetails_headings'>Country *</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <Autocomplete
                                                                    id="combo-box-demo"
                                                                    className="userDetails_textbox_outline"
                                                                    options={countryList}
                                                                    value={userData?.country}
                                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                                    getOptionLabel={(option) => option.name ? option.name : ""}
                                                                    onChange={countryHandler}
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            {...params} variant="outlined" />}
                                                                />
                                                            </FormControl>
                                                        </div>

                                                        <div className='userDetailFieldContainer_phoneNumber'>
                                                            <div className='userDetails_headings'>Phone Number *</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <PhoneInput
                                                                    international
                                                                    className="textbox_phone_input_accountSettings"
                                                                    value={userData?.countryCode + " " + userData?.phoneNumber}
                                                                    onChange={(e) => phoneNumberChangeHandler(e)}
                                                                    onKeyPress={(e) => keypressHandler(e)}
                                                                />
                                                                {(phoneError) ? (
                                                                    <p className="form-error fs-regular phoneError_ml">
                                                                        Please enter a valid phone number
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </FormControl>
                                                        </div>

                                                        <div className='userDetailFieldContainer_email'>
                                                            <div className='userDetails_headings'>Organization</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="Please enter email"
                                                                    className="userDetails_textbox_outline"
                                                                    value={userData?.recruitmentOrganization}
                                                                    disabled={true}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className='userDetailFieldContainer'>
                                                            <div className='userDetails_headings'>Organization Code</div>
                                                            {console.log("code_code",userData)}
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="Org code"
                                                                    className="userDetails_textbox_outline"
                                                                    value={userData?.orgcode}
                                                                    // onChange={(e) => firstNameChange(e)}
                                                                    disabled={true}
                                                                />
                                                            </FormControl>
                                                        </div>

                                                    </div>
                                                    <div className="rightBottomSection">
                                                        <button
                                                            className={ischange === true ? 'updateButton_AccountSettings_disabled' : isUserDataUpdating ? 'updateButton_AccountSettings_disabled' : 'updateButton_AccountSettings'}
                                                            onClick={updateUsers}
                                                            disabled={ischange || isUserDataUpdating}
                                                        >
                                                            <span>Update</span>
                                                            {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton" />) : null}
                                                        </button>
                                                    </div>
                                                </Card>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="footerContainer">
                        <Footer />
                    </div>
                </div>
                    <Dialog
                        open={profilePictureEditModal}
                        onClose={closeProfilePicModal}
                        aria-labelledby="customized-dialog-title"
                        maxWidth="sm"
                        fullWidth={true}
                        className="record-video-intro-responsive profilePictureDialog"
                    >
                        <DialogTitleForProfilePic
                            className={`${classes.changeProfiletitle} responsive-profilepic_title`}
                            onClose={closeProfilePicModal}
                        >
                            Change Profile Picture
                        </DialogTitleForProfilePic>

                        <DialogContentForprofilepic
                            className={`${classes.changeprofilebody} responsive-profilepic_body`}
                            changeprofilebody
                        >
                            {display ? null : (
                                <div className="text-center">
                                    <div className="crop-container" id="circle_ProfilePic">
                                        <Cropper
                                            image={modalProfilePictureUrl == null ? user_register : modalProfilePictureUrl}
                                            crop={crop}
                                            rotation={rotation}
                                            zoom={zoom}
                                            aspect={1 / 1}
                                            onCropChange={setCrop}
                                            onRotationChange={setRotation}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />
                                    </div>
                                    <div className="controls">
                                        <div className="w-100">
                                            <p className="fs-semi-bold fs-15 mt_19 f-color">Zoom</p>
                                            <Slider
                                                value={zoom}
                                                min={1}
                                                max={3}
                                                step={0.1}
                                                aria-labelledby="Zoom"
                                                onChange={(e, zoom) => setZoom(zoom)}
                                                classes={{ container: "slider" }}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <p className="fs-semi-bold fs-15 mt_19 f-color">
                                                Rotation
                                            </p>
                                            <Slider
                                                value={rotation}
                                                min={0}
                                                max={360}
                                                step={1}
                                                aria-labelledby="Rotation"
                                                onChange={(e, rotation) => setRotation(rotation)}
                                                classes={{ container: "slider" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="profilecamera_upload">
                                        <FilePicker
                                            extensions={["jpg", "jpeg", "png"]}
                                            onChange={filePickerOnChange}
                                            onError={(errMsg) => {
                                                NotificationManager.error(errMsg, 'Error');
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btn change_profile_btn cp "
                                            >
                                                Upload Picture
                                            </button>
                                        </FilePicker>
                                        <span>
                                            <button
                                                type="button"
                                                className="btn change_profile_btn ml_5 cp "
                                                onClick={opencameraModal}
                                            >
                                                Use My Camera
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="camera_section">
                                <video
                                    id="video"
                                    playsInline
                                    autoPlay
                                    style={
                                        display
                                            ? { display: "block", width: "100%" }
                                            : { display: "none" }
                                    }
                                ></video>
                                <button
                                    id="snap"
                                    className="btn change_profile_btn cp "
                                    onClick={captureImage}
                                    style={
                                        display
                                            ? { marginTop: "26px", marginBottom: "26px" }
                                            : { display: "none" }
                                    }
                                >
                                    Capture
                                </button>
                                <canvas
                                    id="canvas"
                                    width="640"
                                    height="480"
                                    style={{ display: "none" }}
                                ></canvas>
                            </div>
                        </DialogContentForprofilepic>
                        {display ? null : (
                            <>
                                <div className="divider_adjust">
                                    <hr className="new3 mt_20 mb_0" />
                                </div>
                                <DialogActions
                                    className={`${classes.buttonAlign} responsive-dialog`}
                                >
                                    <button
                                        type="button"
                                        className="cancelButton_AccountSettings"
                                        onClick={closeProfilePicModal}
                                    >
                                        Cancel
                                    </button>
                                    {isDefaultProfile ?
                                        <>
                                        </> :
                                        <>
                                            <button
                                                type="button"
                                                className={isProfilePictureRemoving ? "cancelButton_AccountSettings removeButton_accountSettings_disabled" : "cancelButton_AccountSettings removeButton_accountSettings"}
                                                onClick={deleteCroppedImg}
                                            >
                                                <span>Remove</span>
                                                {isProfilePictureRemoving ? (
                                                    <CircularProgress className="circularProgress_removeButton" />
                                                ) : null}
                                            </button>
                                        </>}
                                    <button
                                        type="button"
                                        className={isProfilePictureChanged ? isProfilePictureUploading ? "updateButton_AccountSettings_disabled profilePictureSave_accountSettings" : "updateButton_AccountSettings profilePictureSave_accountSettings" : "updateButton_AccountSettings_disabled profilePictureSave_accountSettings"}
                                        onClick={showCroppedImage}
                                        disabled={!isProfilePictureChanged}
                                    >
                                        <span>Save</span>
                                        {isProfilePictureUploading ? (
                                            <CircularProgress className="circularProgress_saveButton" />
                                        ) : null}
                                    </button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                    <HomeContactUsModal
                        modalOpen={open}
                        modalClose={_modalClose}
                    />
                    <NotificationContainer />
                </>
                ) : (<>
                    <div className="row padding_80 text-center">
                        <p className="fs-semi-bold fs-40 f-color">
                            You Are Not Authorized To Visit This Page
                        </p>
                    </div>
                </>)
            }
        </>
    );
}

export default AccountSetting;