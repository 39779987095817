import React, { useState, useEffect, useRef, useNavigate } from 'react';
import Header from '../home/homeHeader/Header/Header';
import './error404.css'
import Logo from '../home/images/logo.png';
import { useHistory } from "react-router";
import { withRouter } from 'react-router-dom';
import { set } from 'date-fns';
import { Tooltip } from 'react-bootstrap';
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import ErrorLogo from '../../components/basicInfoForm/images/error.svg'
import ExpireLogo from '../../components/basicInfoForm/images/expiration-icon.png'
import Footer from '../../components/adminFooter/Footer';
import Button from '@material-ui/core/Button';
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import HomeContactUsModal from '../home/homeHeader/homeContactUsModal/homeContactUs';




function Error404(props) {
  const [open, setOpen] = useState(false);
  const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false)
  const history = useHistory();
  const hamburger = useRef(0);

  const goToPreviousPage = (e) => {
    if (localStorage.getItem('role') == "Recruiter") {
      gotoHomePage()

    } else {
      history.goBack()
    }

  }
  const gotoHomePage = (e) => {
    history.push("/")
  }
  const gotoLogin = (e) => {
    localStorage.clear();
    history.push("/signIn")

  }
  const openSupportModal = () => {
    setOpen(true);
  }
  const _modalClose = () => {
    setOpen(false);
  }

  const hamburgerClicked = () => {
    setHamburgerIsClicked(!hamburgerIsClicked)
  }


  return (

    <>

      {/*-------------> Nav Header Main <---------------- */}
      <div className="nav_bg_home home-specific-header">
        <div className="container w1200 nav_bg_home_section">
          <div className="nav_bg_left">

            <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
              <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
            </li>

          </div>
          <div className="nav_bg_right">
            <ul className="navbar_home">
              <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
            </ul>
          </div>
        </div>
      </div>

      {/*-------> Nav Header Mobile <------*/}
      <div className='nav_bg_home_responsive'>
        <div className="nav_bg_home_responsive-wrapper">
          <div className="home-top-nav">
            <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
              <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
            </li>
            <div className={hamburgerIsClicked ? "hamburgerIsClicked" : "hamburgerIsNotClicked"} ref={hamburger}>
              <ul className="navbar_home-responsive">
                <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
              </ul>
            </div>
            <a className="icon" onClick={hamburgerClicked}>
              <i className="fa fa-bars"></i>
            </a>
          </div>
        </div>
      </div>


      {/* -------- Error Component --------- */}
      {/* {props.sessionExpired ? */}
      {(localStorage.getItem("sessionExpired")) ?

        <>
          <div className='error404-container'>
            <div className="error404_wrapper_main">
              <div className='errorLogoWrapper'><img src={ExpireLogo} className="error404Logo"></img></div>
            </div>
            <div className="error404_wrapper_main">
              <div className='errorTextHeading' id="errorTextHeadingID">Your Session Has Expired</div>
            </div>
            <div className="error404_wrapper_main">
              <div className='errorTextParagrapgh'>Please Login Again To Continue.</div>
            </div>
            <div className="error404_wrapper_main">

              <Button variant="contained" className='error404ActionBtn' onClick={gotoLogin}>
                Login     </Button>
            </div>
          </div>
        </>
        :
        <>
          <div className='error404-container'>
            <div className="error404_wrapper_main">
              <div className='errorLogoWrapper'><span className='error4text'>4</span><img src={ErrorLogo} className="error404Logo"></img><span className='error4text'>4</span></div>
            </div>
            <div className="error404_wrapper_main">
              <div className='errorTextHeading'>Page Not Found</div>
            </div>
            <div className="error404_wrapper_main">
              <div className='errorTextParagrapgh'>The page you are looking for  does not exist or was removed.</div>
            </div>
            <div className="error404_wrapper_main">

              <Button variant="contained" className='error404ActionBtn' onClick={goToPreviousPage}>
                Go Back </Button>
            </div>
          </div>
        </>}

      <HomeContactUsModal
        modalOpen={open}
        modalClose={_modalClose}
      />
      <Footer />
    </>
  )
}

export default Error404