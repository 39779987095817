import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { Badge, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Loader } from '@progress/kendo-react-indicators';
const AllRevokedRecruitersKendoTable = ({ allRevokedRecruiters, isDataLoaded }) => {
  const history = useHistory();

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [result, setResult] = React.useState(allRevokedRecruiters.result);

  useEffect(() => {

    for (let i = 0; i < allRevokedRecruiters.length; i++) {
      let agencyName = "agencyName"
      if (allRevokedRecruiters[i].agency) {
        allRevokedRecruiters[i][agencyName] = allRevokedRecruiters[i]?.agency.agencyName
      }
      else allRevokedRecruiters[i][agencyName] = "N/A"
    }

  }, [allRevokedRecruiters])


  const dataStateChange = (event) => {
    setResult(process(allRevokedRecruiters, event.dataState));
    setDataState(event.dataState);
  };


  const handlePhoneNumber = (props) => {
    return (
      <td>{props.dataItem.phoneNumber ? props.dataItem.phoneNumber : " "}</td>
    );
  };

  const stylesforBadge = {
    textAlign: "center",
  };

  const handleAction = (props) => {
    return (
      <td>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => handleRowClick(e, props.dataItem)}
        >
          Go To Profile
        </Button>
      </td>
    );
  };

  // const handleActiveInactive = (props) => {
  //   return (
  //     <td style={stylesforBadge}>
  //       {props.dataItem.isActive ? (
  //         <Badge
  //           color="primary"
  //           badgeContent="Active"
  //           className="recruiter_badge"
  //         ></Badge>
  //       ) : (
  //         <Badge
  //           color="secondary"
  //           badgeContent="Inactive"
  //           className="recruiter_badge"
  //         ></Badge>
  //       )}
  //     </td>
  //   );
  // };

  const handleOrganization = (props) => {
    return (
      <td>
        {props.dataItem.organizationnameduringsignup
          ? props.dataItem.organizationnameduringsignup
          : " "}
      </td>
    );
  };

  const handleCountry = (props) => {
    return (
      <td>
        {props.dataItem?.profile?.locationCountry
          ? JSON.parse(props.dataItem?.profile?.locationCountry).name
          : " "}
      </td>
    );
  };

  let columns = [
    { id: 0, name: "expanded", title: " ", filterable: true, width: "200px" },
    {
      id: 1,
      name: "action",
      title: "Action",
      cell: handleAction,
      filterable: false,
      width: "150px",
    },
    {
      id: 2,
      name: "firstName",
      title: "First Name",
      filterable: true,
      width: "200px",
    },
    {
      id: 3,
      name: "lastName",
      title: "Last Name",
      filterable: true,
      width: "200px",
    },
    { id: 4, name: "email", title: "Email", filterable: true, width: "350px" },
    {
      id: 5,
      name: "organization",
      title: "Recruitment Organization",
      cell: handleOrganization,
      filterable: true,
      width: "200px",
    },
    {
      id: 6,
      name: "agencyName",
      title: "Agency",
      filterable: true,
      width: "200px",
    },
    {
      id: 7,
      name: "phoneNumber",
      title: "Phone Number",
      filterable: true,
      cell: handlePhoneNumber,
      filter: "numeric",
      format: "n0",
      width: "200px",
    },
    {
      id: 8,
      name: "locationCountry",
      filterable: true,
      title: "Country",
      cell: handleCountry,
      width: "200px",
    },

  ];

  const handleRowClick = (rowData = {}, data) => {
    history.push({
      pathname: "/recruiter-talent-community",
      state: { id: data.id },
    });
  };

  // const CellRender = (props) => {
  //   // const dataItem = props.originalProps.dataItem;
  //   // const cellField = props.originalProps.field;
  //   const additionalProps = {};
  //   const clonedProps = { ...props.td.props, ...additionalProps };
  //   return React.cloneElement(props.td, clonedProps, props.td.props.children);
  // };

  // const customCellRender = (td, props) => (
  //   <CellRender originalProps={props} td={td} />
  // );
  useEffect(() => {


    setResult(process(allRevokedRecruiters, dataState));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRevokedRecruiters]);

  return (
    <div className="containerMain">
      <Grid
        sort={dataState.sort}
        sortable={true}
        style={{
          maxHeight: "75vh",
          width: "100%",
          overflow: "hidden"
        }}
        filter={dataState.filter}
        filterable={true}
        pageable={{
          buttonCount: 10,
          info: true,
          previousNext: true,
          pageSizes: true,
        }}
        resizable={false}
        skip={dataState.skip}
        take={dataState.take}
        data={result}
        reorderable={true}
        onDataStateChange={dataStateChange}
      // cellRender={customCellRender}
      >
        {
          !isDataLoaded && (
            <GridNoRecords>
              <div className='col-4'>
                <br />
                <Loader size='large' themeColor="info" type="converging-spinner" />
              </div>
            </GridNoRecords>
          )}
        {columns.slice(2).map((column) => {
          return (
            <Column
              key={column.id}
              field={column.name}
              title={column.title}
              cell={column.cell}
              filter={column.filter}
              filterable={column.filterable}
              width={column.width}
            />
          );
        })}
      </Grid>
    </div>
  );
};

export default AllRevokedRecruitersKendoTable;
