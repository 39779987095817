import axios from "axios";
const jsonConfig = require("../../Config.json");


const countallstatusforrequitionforcandidates = async (payload_data) => {
  try {

    // if (userType == "Team Leader") {
    //   var payload_data = {
    //     "teamLeaderId": id,
    //     "requisitionId": requisitionId,
    //     recruitmentpartnerId:userRecruitmentpartnerId
    //   };
    // } 
    // if (userType == "MIS Operator") {
    //   var payload_data = {
    //     "MISId": id,
    //     "requisitionId": requisitionId,
    //     recruitmentpartnerId:userRecruitmentpartnerId
    //   };
    // }

    // if (userType == "Recruiter") {
    //   var payload_data = {
    //     "recruiterId": id,
    //     "requisitionId": requisitionId,
    //     recruitmentpartnerId:userRecruitmentpartnerId
    //   };
    // }
    // if (userType == "Recruitment Leader") {
    //   var payload_data;
    //   if(checkRecruiterCandidate){
    //     payload_data = {
    //       "recruiterId": id,
    //       "requisitionId": requisitionId,
    //       "recruitmentpartnerId":userRecruitmentpartnerId
    //     };
    //   }else{
    //     payload_data = {
    //       "RecruitmentLeaderId": id,
    //       "requisitionId": requisitionId,
    //       "recruitmentpartnerId":userRecruitmentpartnerId
    //     };
    //   }

    // }
    // if (userType == "Organization Admin") {
    //   var payload_data = {
    //     "orgId": id,
    //     "requisitionId": requisitionId,
    //     "recruitmentpartnerId":userRecruitmentpartnerId
    //   };
    // }
    const getAllResponse = await axios.post(
      jsonConfig.apiUrl + `countallstatusforrequitionforcandidates`, payload_data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default countallstatusforrequitionforcandidates;