import React, { useState, useEffect, useContext } from "react";
import "./candidateprofile.css";
import noProfilePictureImage from "../profileCompletion/images/no-user.png";
import camera from "../profileCompletion/images/camera.png";
import view from "../profileCompletion/images/view.png";
import download from "../profileCompletion/images/download.png";
import candidateResumeById from "../../../apis/candidateResumeById/candidateResumeById";
import Header from "../../../pages/adminApplication/components/header/header";
import Footer from "../../../components/adminFooter/Footer";
import invitecandidateIcon from '../new/images/invitecandidate.svg'
import { DateTime } from 'luxon'
import { Button, Tooltip } from "react-bootstrap";
import getUsersById from "../../../apis/users/getUserById";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import csc from "../../../../src/utilities/country-state-city/dist";
import { UserTypeContext } from "../../../AppRoute.js";
import pdfIcon from "../../../pages/myTalentCommunity/icons/pdf.svg"
import { Skeleton } from "@material-ui/lab";
import { PersonAdd, PersonAddOutlined, PersonAddTwoTone } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProfileCompletion from "../profileCompletion/profileCompletion";
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: "#1A8FFF",
    },
    tooltip: {
        backgroundColor: "#1A8FFF",
        padding: "4px 13px",
        fontFamily: "Segoe-Semibold",
        fontSize: "13px",
    },
    buttonAlign: {
        display: "flex",
        alignItems: "center",
        padding: "24px 42px 24px 42px !important",
    },
}));


const useStyles = makeStyles((theme) => ({
    changeProfiletitle: {
        padding: "16px 42px!important",
    },
    changeprofilebody: {},
}))

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
    closeButton: {
        position: "absolute",
        right: "45px",
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonForProfilePic: {
        position: "absolute",
        right: "8px",
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const OnlyCandidateProfile = (props) => {
    const jsonConfig = require("../../../Config.json");
    const history = useHistory();
    const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
    const [candidateProfileData, setCandidateProfileData] = useState({})
    const [isEmailCopied, setisEmailCopied] = useState(false);
    const [additionalSkills, setAdditionalSkills] = useState([])
    const [wrappedAdditionalSkills, setWrappedAdditionalSkills] = useState(false);
    const [uploadedBy, setuploadedBy] = useState("")
    const [resumeURLarr, setResumeURLarr] = useState([])
    const { match } = props;
    const [currency, setCurrency] = useState("")
    const { userType, userIdType } = useContext(UserTypeContext);
    const [profileCompletion, setProfileCompletion] = useState("");
    const [recruitmentpartnerId,setuserRecruitmentpartnerId] = useState(props.userRecruitmentpartnerId)

    useEffect(() => {
        getResumeDataBycandidateId()
    }, [])


    const getResumeDataBycandidateId = async () => {
        try {
            let response = await candidateResumeById(match.params.id);
            setCandidateProfileData(response.data.data)
            setResumeURLarr(response.data.data.resumeUrlArr)
            setAdditionalSkills(response.data.data.additionalSkills)
            let userData = await getUsersById(response.data.data.uploadedBy)
            setuploadedBy(userData.data.data[0].displayName)
            if (response.data.data.countryCallingCode) {
                let candidatesIndex = response.data.data.countryCallingCode.indexOf('+')

                if (candidatesIndex !== -1) {
                    let cCurrency = response.data.data.countryCallingCode.split("+")
                    setCurrency(csc.getCurrencyByPhonecode(cCurrency[1]).currency)
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const openResume = (resumeUrl) => {
        window.open(resumeUrl, '_blank');
    }

    const getWrapItem = (item) => {
        let wordCount = item.split(" ");
        let word = ""
        if (wordCount.length > 5) {
            word = wordCount[0] + " " + wordCount[1] + " " + wordCount[2] + " " + wordCount[4] + " " + wordCount[5] + '...'
        }
        else word = item
        return word
    }

    const changeCopyText = () => {
        setisEmailCopied(false);
    };
    const copyEmail = (e, useremail) => {
        setisEmailCopied(true);
        navigator.clipboard.writeText(useremail);
        setTimeout(changeCopyText, 3000);
    };

    const handleInvite = () => {
        if (candidateProfileData.emailID) {
            history.push({
                pathname: `/invite-candidates/${candidateProfileData.emailID}`,
            });
        }
    }

    const fileExtension = (resumeUrl) => {
        let extension = resumeUrl.split(".").pop().toLowerCase();
        if (extension === "pdf") {
            return <img src={pdfIcon} alt="PDF Icon" className="fileImg_resume_skeleton" />;
        } else if (extension === "doc" || extension === "docx") {
            return <img src={view} alt="DOC Icon" className="fileImg_resume_skeleton" />;
        }
    };

    const fileExtensionArr = (oldProfiles) => {
        let extension = oldProfiles.split(".").pop().toLowerCase();
        if (extension === "pdf") {
            return (
                <img src={pdfIcon} alt="PDF Icon" className="fileImg" />
            );
        } else if (extension === "doc" || extension === "docx") {
            return (
                <img src={view} alt="DOC Icon" className="fileImg" />
            );
        }
    };

    const getWrappedSecondarySkills = (skills) => {
        let arrData = [];
        skills?.map((item, i) => {
            if (i <= 15) {
                arrData.push(
                    <Tooltip title={item}>
                        <div className="chipStyle ">
                            <span className="chipstyle_skill_font">{getWrapItem(item)}</span>
                        </div>
                    </Tooltip>
                )
            }
        })
        return arrData;
    }


    const getAllSecondarySkills = (skills) => {
        let arrData = [];
        skills?.map((item, i) => {
            arrData.push(
                <Tooltip title={item}>
                    <div className="chipStyle ">
                        <span className="chipstyle_skill_font">{getWrapItem(item)}</span>
                    </div>
                </Tooltip>
            )
        })
        return arrData;
    }

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
    }

    const getWrappedEmail = (email) => {
        let wrappedemail = email.length > 28 ? email.substring(0, 28) + "..." : email;
        return (
            <BootstrapTooltip title={isEmailCopied ? "Copied" : "Click to copy Email"}>
                <span className="candidateDetails" onClick={(e) => copyEmail(e, email)}>{wrappedemail}</span>
            </BootstrapTooltip>
        );
    }

    return (
        <>
            {userType === "Admin" || userType === "Recruiter" || userType === 'Recruitment Leader' || userType === 'Organization Admin' || userType === 'MIS Operator' || userType === 'Team Leader' ? (<>
                <div className="mainContainer_candidateProfilePoweresume onlyCandidateProfileMainContainer">
                    <div className="topContainer_candidateProfilePoweresume">
                        <Header poweResumeAvialable={false} />
                        <div className="mainDiv_candidateProfilePoweresume">
                            <div className="leftDiv_candidateProfilePoweresume">
                                {Object.keys(candidateProfileData).length > 0 ?
                                    <ProfileCompletion
                                        userId={userIdType}
                                        loadingController={true}
                                        candidateData={candidateProfileData}
                                        profileCompletion={profileCompletion}
                                        userRecruitmentPartnerId={recruitmentpartnerId}
                                        userType={userType}
                                    />
                                    :
                                    <div className="leftDiv_candidateProfilePoweresume">
                                        <div className="profileHeading">
                                            <div className="poweResumeHeading_poweResumeRight">Candidate Profile</div>
                                            <div className="poweResumeLastUpdated_poweResumeRight">
                                                <Skeleton variant="rect" width={280} height={17} animation="wave" />
                                            </div>

                                        </div>
                                        <div className="firstBlueContainer">

                                            <div className="imgAndCVContainer">
                                                {/* image */}
                                                <div className="imageContainer">
                                                    <Skeleton variant="circle" width={70} height={70} animation="wave" />
                                                </div>
                                                {/* resume  */}
                                                <div className="resumeContainer_skeleton">
                                                    <Skeleton variant="rect" width={20} height={20} animation="wave" />
                                                    <Skeleton variant="rect" width={60} height={20} animation="wave" />
                                                </div>
                                            </div>




                                            {/* candidate details */}

                                            <div className="detailsContainer detailContainer_skeleton">
                                                <div className="partitionContainer_skeleton">
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                </div>
                                                <div className="partitionContainer_skeleton">
                                                    <Skeleton variant="rect" height={12} width={100} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                </div>
                                                <div className="partitionContainer_skeleton">
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                    <Skeleton variant="rect" height={12} width={240} animation="wave" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* bottom cards */}

                                        <div className="cardsContainer">
                                            <div className="cardStyle">
                                                <div className="primarySkillTitle_poweResumeRight">Primary Skill</div>
                                                <div className="primarySkillSkeletonContainer">
                                                    <Skeleton variant="rect" width={70} height={18} animation="wave" />
                                                    <Skeleton variant="rect" width={200} height={18} animation="wave" />
                                                </div>
                                            </div>
                                            <div className="cardStyle">
                                                <div className="primarySkillTitle_poweResumeRight">Secondary Skill</div>
                                                <Skeleton variant="rect" width="94%" height={230} animation="wave" className="secondarySkill_skeleton" />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="rightDiv_candidateProfilePoweresume">
                                <div className="mainContainer_poweResumeRight">
                                    <div className="headeings_poweResumeRight">
                                        <div className="profileHeaderDiv_skeleton">
                                            <div>
                                                <Skeleton variant="circle" width={54} height={54} animation={false} />
                                            </div>
                                            <div className="nameEmailDiv_poweResumeRight">
                                                <span><Skeleton variant="text" width={50} animation={false} /></span>
                                                <span><Skeleton variant="text" width={130} animation={false} /></span>
                                            </div>
                                        </div>
                                        <div className="rightCorner_poweResumeRight">
                                            <span className="poweResumeHeading_poweResumeRight_header">PoweResume&#8482;</span>
                                            <span className="poweResumeLastUpdated_poweResumeRight"><Skeleton variant="text" width={160} animation={false} /></span>
                                        </div>

                                    </div>
                                    <div className="poweResumeInfoCard_poweResumeRight_skeleton">
                                        <div className="leftSide_poweResumeInfoCard">
                                            <div className="topContainerone_leftSide_poweResumeInfoCard">
                                                <div className="resumeCard_leftSide_poweResumeInfoCard">
                                                    <div className="resumeHeading_resumeBox"><Skeleton variant="text" width={70} animation={false} /></div>
                                                    <span><Skeleton variant="rect" width={50} height={50} animation={false} /></span>
                                                    <span className=""><Skeleton variant="rect" width={170} height={20} animation={false} /></span>
                                                </div>
                                                <div className="personalInfoCard_leftSide_poweResumeInfoCard">
                                                    <div className="personalInfoCard_title_poweResumeInfoCard"><Skeleton variant="text" width={160} animation={false} /></div>
                                                    <div className="personalInfocardItems_poweResumeCard"><Skeleton variant="text" width={160} animation={false} /></div>
                                                    <div className="personalInfocardItems_poweResumeCard"><Skeleton variant="text" width={160} animation={false} /></div>
                                                    <div className="personalInfocardItems_poweResumeCard"><Skeleton variant="text" width={160} animation={false} /></div>
                                                </div>
                                                <div className="videoIndtroCard_leftSide_poweResumeInfoCard">
                                                    <span className="resumeHeading_resumeBox"><Skeleton variant="text" width={140} animation={false} /></span>
                                                    <span>
                                                        <Skeleton variant="rect" width={150} height={100} animation={false} />
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="primarySkillContainer_poweResumeRight">
                                                <span className="primarySkillTitle_poweResumeRight"><Skeleton variant="text" width={160} animation={false} /></span>
                                                <div className="primarySKillMainContainer_poweResumeRight">
                                                    <div className="primarySKillItemOne_poweResumeRight">
                                                        <span className="PrimarySkillName_poweResumeRight"><Skeleton variant="text" width={70} animation={false} /></span>
                                                        <span className="primarySkillExp_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span>
                                                    </div>
                                                    <div className="primarySKillItemTwo_poweResumeRight">
                                                        <div className="star_primaryskill">
                                                            <div className="starRatingContainer_poweResumeRight"><Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                        </div>
                                                        <span className="SelfAssesmentTitle_poweResumeRight"><Skeleton variant="text" width={80} animation={false} /></span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="primarySkillContainer_poweResumeRight">
                                                <span className="primarySkillTitle_poweResumeRight"><Skeleton variant="text" width={160} animation={false} /></span>
                                                <div className="primarySKillMainContainer_poweResumeRight">
                                                    <div className="primarySKillItemOne_poweResumeRight">

                                                        <span className="PrimarySkillName_poweResumeRight"><Skeleton variant="text" width={70} animation={false} /></span>
                                                        <span className="primarySkillExp_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span>
                                                    </div>
                                                    <div className="primarySKillItemTwo_poweResumeRight">
                                                        <div className="star_primaryskill">
                                                            <div className="starRatingContainer_poweResumeRight"><Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                            <div className="starRatingContainer_poweResumeRight"> <Skeleton variant="circle" width={20} height={20} animation={false} /></div>
                                                        </div>
                                                        <span className="SelfAssesmentTitle_poweResumeRight"><Skeleton variant="text" width={80} animation={false} /></span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="skillTestContainer_poweResumeRight">
                                                <div className="skillTestInfoContainer_poweResumeRight">
                                                    <span className="primarySkillTitle_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span>
                                                    <span className="skillTestName_poweResumeRight"><Skeleton variant="text" width={130} animation={false} /></span>
                                                    <div className="skillTestTimeInfoContainer_poweResumeRight_skeleton">
                                                        <span><Skeleton variant="text" width={220} animation={false} /></span>
                                                        <span><Skeleton variant="text" width={210} animation={false} /></span>
                                                        <span><Skeleton variant="text" width={190} animation={false} /></span>
                                                    </div>
                                                    <div className="testScoreContainer_poweResumeRight">
                                                        <span><Skeleton variant="text" width={100} animation={false} /></span>
                                                        <span><Skeleton variant="text" width={100} animation={false} /></span>
                                                    </div>
                                                    <div className="additionalSKillScroll_poweResumeRight">
                                                        <div className="arraowIconContainer_powerResumeRight"><span className="arraowIcon_powerResumeRight"><Skeleton variant="circle" width={20} height={20} animation={false} /></span></div>
                                                        <div className="skillItemCountContainer_poweResumeRight"><span className="skillItemCountContainer_poweResumeRight_opacity"><Skeleton variant="circle" width={20} height={20} animation={false} /></span></div>
                                                        <div className="arraowIconContainer_powerResumeRight"><span className="arraowIcon_powerResumeRight"><Skeleton variant="circle" width={20} height={20} animation={false} /></span></div>
                                                    </div>
                                                </div>
                                                <div><Skeleton variant="rect" width={125} animation={false} /></div>
                                            </div>
                                        </div>
                                        <div className="rightSide_poweResumeInfoCard">
                                            <div className="UpperSection_rightSide_poweResumeInfoCard">
                                                <span className="manrope-18_poweResumeRight"><Skeleton variant="text" width={180} animation={false} /></span>
                                                <span className="availabilty_poweResumeRight"><Skeleton variant="text" width={130} animation={false} /></span>
                                                <div className="availabilty_poweResumeRight availabiltyDiv_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /><span className="availabiltyTime_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span></div>
                                                <span className="weektitle_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span>
                                                <span className="weektitle_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span>
                                                <span className="weektitle_poweResumeRight"><Skeleton variant="text" width={100} animation={false} /></span>

                                            </div>
                                            <div className="dateTimeButtonContainer_poweResumeRight">
                                                <div className="">
                                                    <Skeleton variant="rect" width={180} height={50} animation={false} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="invitetotaketestConrainer">
                                    <PersonAddTwoTone className="inviteCandidateIconStyle" />
                                    <span className="invitecandidate_text1">Start Sending Test Invitations</span>
                                    <Button variant="contained" className="invitecandidate_invitebutton" onClick={(e) => handleInvite()}>Invite</Button>
                                    <span className="invitecandidate_text2">Invite Candidate</span>
                                    <span className="invitecandidate_text3">to take Test</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="bottomContainer_candidateProfilePoweresume">
                        <Footer />
                    </div>
                </div>
            </>)
                : (<>
                    <div className="row padding_80 text-center">
                        <p className="fs-semi-bold fs-40 f-color">
                            You Are Not Authorized To Visit This Page
                        </p>
                    </div>
                </>)}
        </>
    )

}

export default OnlyCandidateProfile