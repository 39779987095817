export const msalConfig = {
  auth: {
    clientId: "4298489e-2425-4338-b930-f6b368ace0ac",
    authority:
      "https://clearedtalentqa.b2clogin.com/clearedtalentqa.onmicrosoft.com/B2C_1_SignUpSignInCandidate",
    redirectUri: `https://${window.location.host}`,
    knownAuthorities: ["clearedtalentqa.b2clogin.com"],
    postLogoutRedirectUri: `https://${window.location.host}`
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
};

export const signInRequest = {
  authority: "https://clearedtalentqa.b2clogin.com/clearedtalentqa.onmicrosoft.com/B2C_1_SignIn"
}

export const signUpRequest = {
  authority: "https://clearedtalentqa.b2clogin.com/clearedtalentqa.onmicrosoft.com/B2C_1_SignUp"
}

export const signInOrSignUpRequest = {
  authority: "https://clearedtalentqa.b2clogin.com/clearedtalentqa.onmicrosoft.com/B2C_1_SignUpSignInCandidate"
}

export const forgotPasswordRequest = {
  authority: "https://clearedtalentqa.b2clogin.com/clearedtalentqa.onmicrosoft.com/b2c_1_reset"
}

// export const apiRequest = {
//   scopes: ["https://clearedtalent.onmicrosoft.com/clearedtalent-backend-api/interview.user.create_interview"],
//   forceRefresh: false,
// };

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};