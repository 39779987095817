import React, { useState, useEffect, useCallback } from "react";
import Profile from "./profile/Profile";
import { useHistory } from "react-router";
import axios from "axios";
import Header from "./components/header/header";
import { CircularProgress } from "@material-ui/core";
import sharedPowerResume from "../../apis/sharePoweResume/getSharedPowerResume";

const SharedAdminApplication = (props) => {
  const jsonConfig = require("../../Config.json");
  const history = useHistory();
  let id = props?.match?.params?.id;
  const [profileId, setprofileId] = useState("");
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [viewTestAccess, setViewTestAccess] = useState([]);
  console.log("viewTestAccess", viewTestAccess);

  const getSharedPoweResume = async (payload) => {
    try {
      const sharedPoweResumeResonse = await sharedPowerResume(payload);
      setisDataLoaded(true);
      setprofileId(sharedPoweResumeResonse.data.data[0]?.ProfileId);
      setViewTestAccess(sharedPoweResumeResonse.data.data[0]?.testAccess);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    await getSharedPoweResume({ Id: id.toString() });
  }, [id]);
  return (
    <>
      <div>
        {profileId !== "" ? (
          <Profile candidateId={profileId} testAccess={viewTestAccess} isShare={true}  recruitmentpartnerId=""/>
        ) : (
          <>
            <Header />
            <div className="section-wrapper">
              <div className="profile-wrapper flex-to-footer">
                <div className="container w1004 powerresumetop_adjust">
                  <div className="container register-test-container">
                    <div className="card cardheight cardheight_endtest success-card mt_10 text-center">
                      <div className="end-test-container">
                        <div className="start_test_text_top">
                          {isDataLoaded ? (
                            <p className="fs-semi-bold fs-16 f-color">
                              The PoweResume link is invalid or expired
                            </p>
                          ) : (
                            <div className="row padding_80 text-center">
                              <CircularProgress />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SharedAdminApplication;
