import axios from "axios";
const jsonConfig = require("../../Config.json");

const GetTestByRecruitmentPartner = async (recruiterId) => {
  let allResponse = {}
  try {
      const getallresponse =await axios.get(
          jsonConfig.apiUrl + "GetTestByRecruitmentPartner/"+recruiterId,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
      
      allResponse = getallresponse;
      return Promise.resolve(allResponse)
  }
  catch (error) {
      return Promise.reject(error)
  }
}

export default GetTestByRecruitmentPartner;