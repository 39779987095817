import React, { useState, useContext, useEffect } from "react";
import "./adminLanding.css";
import AdminHeader from "../../components/adminHeader/adminHeader";
import { CircularProgress } from "@material-ui/core";
import ManageUser from "./images/manage_user.svg";
import Question from "./images/questions.svg";
import EmailConfiguration from "./images/email_config.svg";
import Domain from "./images/domain.svg";
import Skill from "./images/skills.svg";
import SubSkill from "./images/subskill.svg";
import ReportedQuestion from "./images/reorted_questions.svg";
import SearchCandidate from "./images/search_candidates.svg";
import { useHistory } from "react-router";
import { UserTypeContext } from "../../AppRoute";
const AdminLanding = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const history = useHistory();
  const [loadingController, setLoadingController] = useState(false);
  useEffect(() => {
    (async () => {
      if (userType) {
        setLoadingController(true)
      }
    })()
    return () => {
      setLoadingController(false)
    }
  }, [])

  return (
    <>
      <AdminHeader uid={idContextTest} isDataLoaded={loadingController} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt_100">
            {!loadingController ? (
              <div className="row circular-progress-center-question text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <>
                    <div className="admin-container">
                      <p className="fs-light fs-35 f-color fs-30 ml_17 mb_20">
                        Admin Navigation
                      </p>

                      <p className="fs-regular fs-28 f-color ml_17 mb_20">
                        Tests/Questions
                      </p>
                      <section className="admin-section-home-first mb_32">
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/questions")}
                        >
                          <img src={Question} alt="questions" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Questions
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/subskill")}
                        >
                          <img src={SubSkill} alt="subskill" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Sub-Skills
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/skills")}
                        >
                          <img src={Skill} alt="skills" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Skills
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          id="recruiter_private_skill_access"
                          onClick={() =>
                            history.push("/client-specific-test-access")
                          }
                        >
                          <img
                            src={SubSkill}
                            alt="recruiter_private_skill_access"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10 wrap-white">
                            Client-Specific Test Access
                          </p>
                        </div>
                      </section>
                      <section className="admin-section-home-first mb_32">
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/tests")}
                        >
                          <img
                            src={SubSkill}
                            alt="recruiter_private_skill_access"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Tests
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/domain")}
                        >
                          <img src={Domain} alt="domain" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Domains
                          </p>
                        </div>
                        <div className="page-box-element_last"></div>
                        <div className="page-box-element_last"></div>
                      </section>
                      <hr className="admindivider"></hr>
                      <p className="fs-regular fs-28 f-color ml_17 mt_12 mb_20">
                        Users/Clients
                      </p>
                      <section className="admin-section-home-first mb_32">
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/users")}
                        >
                          <img src={ManageUser} alt="manage_user" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Manage Users
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/search")}
                        >
                          <img src={SearchCandidate} alt="search_candidates" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Search Candidates
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/sign-up-requests")}
                        >
                          <img
                            src={EmailConfiguration}
                            alt="email_configuration"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10 nowrap-white">
                            Sign-Up Requests
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() =>
                            history.push(
                              "/recruitment-organizations-customers-and-agencies"
                            )
                          }
                        >
                          <img
                            src={SubSkill}
                            alt="recruiter_private_skill_access"
                          />
                          <p className="fs-semi-bold f-color fs-20 mt-10">
                            Recruitment Organizations, Customers & Agencies
                          </p>
                        </div>
                      </section>
                      <section className="admin-section-home-first mb_32">
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/admin-referral")}
                        >
                          <img src={SubSkill} alt="manage_user" />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Referral
                          </p>
                        </div>
                        <div className="page-box-element_last"></div>
                        <div className="page-box-element_last"></div>
                        <div className="page-box-element_last"></div>
                      </section>
                      <hr className="admindivider"></hr>
                      <p className="fs-regular fs-28 f-color ml_17 mt_12 mb_20">
                        Others
                      </p>
                      <section className="admin-section-home-first mb_32">
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/globalconfiguration")}
                        >
                          <img
                            src={EmailConfiguration}
                            alt="email_configuration"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10 nowrap-white">
                            Global Configuration
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() => history.push("/emailconfiguration")}
                        >
                          <img
                            src={EmailConfiguration}
                            alt="email_configuration"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Email Configuration
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          onClick={() =>
                            history.push("/pending-candidate-invites")
                          }
                        >
                          <img
                            src={SubSkill}
                            alt="recruiter_private_skill_access"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10">
                            Pending Candidate Invites
                          </p>
                        </div>
                        <div
                          className="page-box-element"
                          id="reported_questions"
                          onClick={() => history.push("/reported-question")}
                        >
                          <img
                            src={ReportedQuestion}
                            alt="reported_questions"
                          />
                          <p className="fs-semi-bold fs-20 f-color mt-10 wrap-white">
                            Reported Questions
                          </p>
                        </div>
                      </section>
                    </div>
                  </>
                ) : userType === "" ? (<>
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...</p>
                  </div>
                </>) : (
                  <>
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        You Are Not Authorized To Visit This Page
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminLanding;
