import axios from "axios";
const jsonConfig = require("../../Config.json");

const companyBySkillId = async (id) => {    
    let allResponse = {}
    try {
        const response = await axios.get(
            jsonConfig.apiUrl + "CompanyByMySkillId/" + id,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }
          );
        
        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error);    
        return Promise.reject(error);
    }
}

export default companyBySkillId;