import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllTeamLeaderRequisitionStatusAndCounts = async(id,payload) => {
    try
    {
        const response = await axios.post(
            `${jsonConfig.apiUrl}getAllTeamLeaderRequisitionStatusAndCounts/${id}`,payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                }
            }
            
        );
        return Promise.resolve(response);
    }
    catch(error)
    {
        return Promise.reject(error);
    }
}

export default getAllTeamLeaderRequisitionStatusAndCounts