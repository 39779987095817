import axios from "axios";
const jsonConfig = require("../../Config.json");

const putUserTestQuestionAnswerResponse = async (userTestIdRef,userAnswersResponseRef) => {
    let allResponse = {}
    try {
        const getallresponse = await axios.put(
            jsonConfig.apiUrl + "usertestquestionanswer/" + userTestIdRef,
            userAnswersResponseRef,
            {
              headers: {
                "x-functions-key": jsonConfig.key
              }
            }
          );
        allResponse = getallresponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default putUserTestQuestionAnswerResponse;