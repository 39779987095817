import axios from "axios";
const jsonConfig = require("../../Config.json");

const changePasswordResponse = async (payload) => {
    let allResponse = {}
    try {
        const changePasswordReqData = await axios.post(
            jsonConfig.apiUrl + "changePassword",
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
        allResponse = changePasswordReqData;

        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr",error);        
        return Promise.reject(error);
    }
}

export default changePasswordResponse;