import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { Container } from "react-bootstrap";
import { CircularProgress, Grid, Paper, TableContainer } from "@material-ui/core";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
import { Autocomplete } from "@material-ui/lab";
import jsonConfig from "../../Config.json";
import { process } from "@progress/kendo-data-query";
import { GridColumn } from "@progress/kendo-react-grid";
import { Grid as KendoGrid, GridNoRecords } from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
import updateConfigurationResponse from "../../apis/globalConfiguration/updateGlobalConfiguration";
import { Loader } from '@progress/kendo-react-indicators';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  label: {
    display: "inline-block",
    fontSize: "17px",
    color: "#04093F",
    fontFamily: "Segoe-Regular",
    fontStyle: "normal",
    marginBottom: "10px !important"
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
});

const DefaultOrganization = ({ data, org, setisDataUpdaed, isDataLoaded }) => {
  const classes = useStyles();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [dataForEdit, setDataForEdit] = useState({});
  const [selectedOrg, setSelectedOrg] = useState({});
  const [atvBtn, setAtvBtn] = useState(false);

  //   useEffect(() => {
  //      get
  //   }, []);

  // creating filter for kendoGrid

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  // fetching data(allSubSkillForCard) from database

  const [result, setResult] = useState(data);
  const dataStateChange = (event) => {
    setResult(process(data, event.dataState));
    setDataState(event.dataState);
  };
  useEffect(() => {
    setResult(process(data, dataState));
  }, [data]);

  // editing button on click
  const handleEdit = (props) => {
    return (
      <td className="actionContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal(props.dataItem)}
        >
          Edit
        </Button>
      </td>
    );
  };

  const closeModal = () => {
    setOpenEditModal(false);
  };
  const openModal = (list) => {
    setOpenEditModal(true);
    setDataForEdit(list);
    setSelectedOrg(list.recruitmentpartner);
  };
  const updateHandler = async () => {
    setAtvBtn(true);
    const data = {
      id: dataForEdit.id ? dataForEdit.id : "",
      code: dataForEdit.code ? dataForEdit.code : "",
      name: dataForEdit.name ? dataForEdit.name : "",
      value: selectedOrg?.id ? selectedOrg.id : "",
      updatedBy: null,
    };
    const payload = {
      code: dataForEdit.code,
      data: data,
    };
    try {
      const updateConfigResponse = await updateConfigurationResponse(payload);
      NotificationManager.success(
        `${dataForEdit.name} updated`,
        "Success",
        2500
      );
      setisDataUpdaed(true);
      setOpenEditModal(false);
      setAtvBtn(false);
    } catch (error) {
      console.log(error);
      setAtvBtn(false);
    }
  };

  const orgChange = async (event, value) => {
    setSelectedOrg(value);
  };

  return (
    <>
      <h3 className="fs-semi-bold fs-30 f-color mt-15">Default Recruitment Organizations</h3>
      {/*KENDO GRID */}

      <KendoGrid
        className="kw"
        filter={dataState.filter}
        resizable={true}
        filterable={true}
        sort={dataState.sort}
        pageable={{
          buttonCount: 10,
          info: true,
          previousNext: true,
          pageSizes: true,
        }}
        skip={dataState.skip}
        take={dataState.take}
        data={result}
        onDataStateChange={(e) => dataStateChange(e)}
      >
        {!isDataLoaded && (
          <GridNoRecords>
            <div className='col-4'>
              <br />
              <Loader size='large' themeColor="info" type="converging-spinner" />
            </div>
          </GridNoRecords>
        )}
        <GridColumn
          field="name"
          title="Name"
          width="200px"
          resizable={true}
          filterable={true}
        />
        <GridColumn
          field={`recruitmentpartner.name`}
          title="Recruitment Organization"
          width="200px"
          resizable={true}
          filterable={true}
        />
        <GridColumn
          title="Edit"
          width="200px"
          resizable={true}
          filterable={false}
          cell={handleEdit}
        />
      </KendoGrid>

      <Dialog
        open={openEditModal}
        onClose={closeModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          {dataForEdit.name}
        </DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="dropdown_width_outer mt_12">
            <div className="select customized-select default-org">
              <label className={`${classes.label}`} htmlFor="country">
                Recruitment Organization*
              </label>
              <Autocomplete
                id="combo-box-demo"
                className="mt_11"
                options={org}
                autoHighlight
                value={selectedOrg}
                getOptionLabel={(option) =>
                  selectedOrg?.name
                    ? option?.name
                    : option?.name
                }
                onChange={orgChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    placeholder="Select Recruitment Organization"
                  />
                )}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn5 btn-skill cp"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button variant="outlined" className="btn5 btn_primary cp" disabled={atvBtn} onClick={updateHandler}>
            <div className="btn_loader">
              Submit
              &nbsp;
              {atvBtn &&
                <div >
                  <CircularProgress size={15} />
                </div>
              }
            </div>
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DefaultOrganization;
