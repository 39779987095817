import axios from "axios";
const jsonConfig = require("../../Config.json");

const getSkillResponse = async () => {
    let allSkillsList = {}
    try {
        const getallskillresponse = await axios.get(
            jsonConfig.apiUrl + "skills",
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        allSkillsList = getallskillresponse;

        return Promise.resolve(allSkillsList)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default getSkillResponse;