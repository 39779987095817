import axios from "axios";
const jsonConfig = require("../../Config.json");

const getRecruiterLeaderRequisitionsDetails = async(offset, limit, statusCode, selectedFilter, id,searchText,userRecruitmentpartnerId) => {
    try
    {
        const payload = {
            statusCode : statusCode === "ALL" ? "" : statusCode,
            filters : selectedFilter,
            userId: id,
            searchText: searchText,
            recruitmentpartnerId:userRecruitmentpartnerId,
            userRecruitmentpartnerId:userRecruitmentpartnerId,
        }
        const response = await axios.post(
            `${jsonConfig.apiUrl}getAllRecruiterLeaderRequisitions?offset=${offset}&limit=${limit}`, payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                }
            }
        );
        return Promise.resolve(response);
    }
    catch(error)
    {
        return Promise.reject(error);
    }
}

export default getRecruiterLeaderRequisitionsDetails