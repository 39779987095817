import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateMySkillResponse = async (payload) => {
  let allSkillsList = {};
  try {
    const getAllSubSkills = await axios.put(
      jsonConfig.apiUrl + "mySkills/" + payload.id,payload.data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allSkillsList = getAllSubSkills;
    return Promise.resolve(allSkillsList);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default updateMySkillResponse;
