import React, { useEffect, useState } from "react";
// import { DialogTitle, IconButton, DialogActions, DialogContent, Button, Grid } from "@mmaterial-ui/core/";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
/*** components */

import DropdownBox from "../../components/formElements/dropdownBox";
import InputBox from "../../components/formElements/inputBox";

/*** components */

/*** apis */
import getSkillById from "../../apis/getSkillById";
import postNewSkill from "../../apis/postNewSkill";
import putEditSkill from "../../apis/putEditSkill";
import putUpdateSkillPopularity from "../../apis/putUpdateSkillPopularity";
import { CircularProgress } from "@material-ui/core";
/*** api */

const AddSkillForm = (props) => {
  const [apvBtn, setApvBtn] = useState(false);
  const {
    domainOptions,
    onCancel,
    onSave,
    userIdType,
    clientData,
    editSkill,
  } = props;

  let defaultFields = {
    domain: "",
    skill: "",
    isActive: "",
    optionType: "",
    client: {},
  };

  if (editSkill) {
    const {
      client,
      clientId,
      domain,
      domainId,
      id,
      isActive,
      isPrimary,
      isPrivate,
      name,
      skillCode,
    } = editSkill;

    defaultFields = {
      ...defaultFields,
      domain,
      domainId,
      skill: name,
      isActive: isActive.toString(),
      optionType: isPrivate ? "Client-Specific" : "Public",
      client,
      clientId,
      skillId: id,
    };
  }

  const [formFields, setFormFields] = useState(defaultFields);
  const fieldChangeHandler = (field) => (e, val) => {
    const fieldValue = val || e.target.value;
    setFormFields({ ...formFields, [field]: fieldValue });
    if (field === "optionType") {
      setFormFields((prev) => ({ ...prev, client: "" }));
    }
  };

  const cancel = (e) => {
    onCancel && props.onCancel();
  };

  const save = async (e) => {
    setApvBtn(true);
    let response = {};
    const skillData = {
      id: formFields.skillId,
      name: formFields.skill.trim(),
      isPrimary: true,
      updatedBy: userIdType,
      isActive: formFields.isActive === "" ? "" : (formFields.isActive === "true" ? true : false),
      domainId: formFields.domain ? formFields.domain.id : "",
      optionType: formFields.optionType === "Public" ? "Public" : "Private",
    };
    skillData.isPrivate = skillData.optionType === "Private";
    if (skillData.isPrivate) {
      skillData.clientId = formFields.client.id;
    }

    if (
      skillData.name === "" ||
      skillData.domainId === "" ||
      skillData.isActive === ""
    ) {
      setApvBtn(false);
      return NotificationManager.error(
        "Please fill all the mandatory fields",
        "Error",
        2500
      );
    }

    if (skillData.id) {
      try {
        response = await putEditSkill(skillData);

        if (response.data.message?.includes("This Skill name already")) {
          setApvBtn(false);
          return NotificationManager.error(
            "The skill already exists in the system. Please use a different skill name",
            "Error",
            2500
          );
        }
        NotificationManager.success("Skill updated", "Success", 2500);
        setApvBtn(false);
      } catch (e) {
        console.log(e);
        NotificationManager.error("Skill update failed", "Error", 2500);
        setApvBtn(false);
      }
    } else {
      try {
        skillData.createdBy = userIdType;
        response = await postNewSkill(skillData);

        if (response.data.message?.includes("This Skill name already")) {
          setApvBtn(false);
          return NotificationManager.error(
            "The skill already exists in the system. Please use a different skill name",
            "Error",
            2500
          );
        }
        NotificationManager.success("Skill added", "Success", 2500);
        setApvBtn(false);
      } catch (e) {
        console.log(e);
        NotificationManager.error("Skill addition failed", "Error", 2500);
        setApvBtn(false);
      }
    }

    onSave && props.onSave();
  };

  let domainProps = {
    defaultValue: formFields.domain,
    label: "Domain*",
    options: domainOptions,
    getOptionLabel: (option) => (option ? option.name : null),
    placeholder: "Select Domain",
    onChange: fieldChangeHandler("domain"),
  };

  let clientProps = {
    defaultValue: formFields.client,
    label: "Client*",
    options: clientData,
    getOptionLabel: (option) => (option ? option.name : null),
    placeholder: "Select Client",
    onChange: fieldChangeHandler("client"),
  };
  const skillProps = {
    value: formFields.skill,
    label: "Skill*",
    placeholder: "Enter Skill Name",
    onChange: fieldChangeHandler("skill"),
  };

  let isActiveProps = {
    defaultValue: formFields.isActive,
    label: "Is Active*",
    options: ["true", "false"],
    getOptionLabel: (option) =>
      option ? option[0].toUpperCase() + option.substring(1) : null,
    placeholder: "Select Status",
    onChange: fieldChangeHandler("isActive"),
  };
  let selectDropDown = {
    defaultValue: formFields.optionType,
    label: "Skill Type(Public/Client-Specific)*",
    options: ["Public", "Client-Specific"],
    getOptionLabel: (option) => {
      return option ? option[0].toUpperCase() + option.substring(1) : null;
    },
    placeholder: "Select skill type",
    onChange: fieldChangeHandler("optionType"),
  };
  const btnGreen = {
    color: '#fff',
    backgroundColor: '#2AD156',
    border: '1px solid #2AD156',
  };
  const btnCancel = {
    color: "#3f51b5",
    backgroundColor: "#fff",
    padding: "7px 20px 7px 20px",
    marginRight: "7px",
    border: "1px solid #3f51b5",
  };

  //TODO add delete skill button on edit skill page
  //TDOD send put request to update skill popularity tables.
  return (
    <>
      <div className='Recruiter_Private_Skill_Modal'>
        <DialogTitle className="admindailog_title_modal">
          {formFields.skillId ? "Edit Skill" : "Add Skill"}
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              color: "grey",
            }}
            onClick={cancel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </div>
      <DialogContent>
        <DropdownBox {...domainProps} />
        &nbsp;
        <InputBox {...skillProps} />
        &nbsp;
        <DropdownBox {...isActiveProps} />

      </DialogContent>
      <Grid>
        <Grid item xs={12} md={12} className="user_pop_button" >
          <DialogActions>
            <Button onClick={cancel} variant="outlined" className="btn3 " color="primary">Cancel</Button>
            <button
              type="button"
              className="btn3 btn_primary ml_5 cp"
              onClick={save}
              disabled={apvBtn}
            >
              <div className="btn_loader">
                Save
                &nbsp;
                {apvBtn &&
                  <div>
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
      </Grid>
      <NotificationContainer />
    </>
  );
};

export default AddSkillForm;
