import axios from "axios";
const jsonConfig = require("../../Config.json");


const updateTeamDetails = async (payload) => {
    try {
        const resp = await axios.put(
            jsonConfig.apiUrl + "updateTeamByID",
             payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        return Promise.resolve(resp);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export default updateTeamDetails;