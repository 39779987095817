import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllTestSubskills = async (value) => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "TestSubskillsMapping/" + value.id ,{
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default getAllTestSubskills;
