import axios from "axios";
const jsonConfig = require("../../Config.json");

const putUserTestDetails = async (userTestIdRef, userAnswersResponseRef, resultData, isInvited, candidateResumeIds) => {
    let allResponse = {};
    let payload = {
        "userAnswersResponseRef": userAnswersResponseRef,
        "resultData": resultData,
        "isInvited": isInvited,
        "candidateResumeIds": candidateResumeIds
    }


    try {
        const getallresponse = await axios.post(
            jsonConfig.apiUrl + "usertestDetail/" + userTestIdRef,
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key
                }
            }
        );
        allResponse = getallresponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default putUserTestDetails;