import React, { useState } from "react";
const validateCompanyEmail = (email) => {
    const regX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regX.test(email)) {
      if (
        email.includes("gmail") ||
        email.includes("outlook") ||
        email.includes("yahoo") ||
        email.includes("hotmail")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  export default validateCompanyEmail;