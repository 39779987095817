import axios from "axios";
const jsonConfig = require("../../Config.json");

const createCandidateProfileAtSelfTest = async (payload) => {
    // console.log(payload,"payloadpayload");
    let response = {}

    try {

        const createCandidateResumeMappingPowerResumeResponse = await axios.post(
            jsonConfig.apiUrl + "createCandidateProfileAtSelfTest", payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        response = createCandidateResumeMappingPowerResumeResponse.data;

        return Promise.resolve(response)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default createCandidateProfileAtSelfTest;