import React, {

  useEffect,
  useRef,
  useState,
} from "react";
import "./header.css";
import validateEmail from '../../utilities/validateEmail';
import noProfilePictureImage from "./images/no-user.png";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Logo from "./images/logoNew.png";
import { Button } from 'react-bootstrap'
import ShareIcon from '@material-ui/icons/Share';
import { useHistory } from "react-router";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Typography, Card, CardActions, CardContent, MenuItem, MenuList, ClickAwayListener, TextField } from "@material-ui/core";
import ProfileById from '../../apis/profile/profileById'
import { Grid} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import cancelemailicon from "./images/cancelemail.svg";
import Ellipse from "./images/Ellipse.png";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SupportIconNew from "../../components/basicInfoForm/images/supportIconNew.svg";
import LoggedInSupportModal from "../../components/loggedInSupportModal/loggedInSupportModal";
import sharePoweResumeResponse from "../../apis/sharePoweResume/sharePoweResume";
import profileResponse from "../../apis/profile/profileById";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const filter = createFilterOptions();
const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: "0px 30px",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "5px 42px 20px !important",
  },
  title: {
    padding: "16px 30px",
  },
  bodyForShare: {
    padding: "0px 30px",
  },
  dialog: {
    position: "absolute",
    right: "0",
    top: "6%",
    borderRadius: "10px",
  },
  heading: {
    fontFamily: "Source Sans Pro",
    fontSize: "45px",
    fontWeight: 600
  },
  write: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    color: "#519AF8",
    cursor: "pointer",
  },
  btnCancel: {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFFFFF",
    color: "#1885E6",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    fontSize: '15px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#D1E8FF"
    }
  },
  btnSave: {
    width: "120px",
    height: "32px",
    backgroundColor: "#1885E6",
    fontSize: '15px',
    color: "#FFFFFF",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8"
    }
  },
  mailText:{
    marginLeft: "-12px",
    fontSize:"25px",
    height:"30px"
  }
}));
const Header = ({ userId,mailData,setMailData,lastUpdate,profileData}) => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const jsonConfig = require("../../Config.json");
  const [navDisplay, setnavDisplay] = useState(false);
  const [loadingController, setLoadingController] = useState(false);
  const [mailValidate, setMailValidate] = useState(false);
  const [modalForShare, setmodalForShare] = useState(false);
  const [isNavigationOpen, setisNavigationOpen] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState(false);
  const [allmailforshare, setallmailforshare] = useState([]);
  const [displayname, setdisplayname] = useState([]);
  const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
  const inputemail = useRef(null);
  const shareMessageRef = useRef(null);
  const [count, setcount] = useState(0);
  const [maillength, setmaillength] = useState(0);
  const [showmail, setshowmail] = useState(true);
  const getProfileData = () => {
    try {
      const user = profileData.user;
      setdisplayname(user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) + " "+ user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1))
      setLoadingController(true);
      setMailData((prevState) => ({
        ...prevState,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      }));
    }
    catch (error) {
      console.log(error);
    }
  };
  const openSupport = () => {
    setOpen(true);
    setisNavigationOpen(false);
    getProfileData();
  };
  const setshareMessage = async (e, value) => {
    shareMessageRef.current = e;
    const regex = /^\s*$/g
    const textValue = shareMessageRef.current
    if (regex.test(textValue)) {
      setErrorEmailMessage(true)
    } else if (textValue === '') {
      setErrorEmailMessage(false)
    }
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMailValidate(false);
  };
  const sharePoweResumeEmail = async () => {
    const candidateComments = shareMessageRef.current;
    if (inputemail.current && allmailforshare.length === 0) {
      NotificationManager.error(`Please enter valid corporate email address and click  "${"Enter"}"`, "Error", 2500)
      return;
    }
    if (candidateComments === "" || candidateComments === null) {
      NotificationManager.error("Enter your message to recruiters/hiring managers", "Error")
  
      return;
    }
    if (shareMessageRef.current && allmailforshare.length > 0) {
      if (jsonConfig.sendEmail) {
        try {
          let payload = {
            "ProfileId": userId,
            "EmailIDs": allmailforshare,
            "comments": candidateComments,
            "createdBy": userId,
            "updatedBy": userId,
            "candidateEmailId":mailData.email
          }
          const shareResumeResponse = await sharePoweResumeResponse(payload)
          setmodalForShare(false);
          setallmailforshare([]);
          let mailShare;

          if(shareResumeResponse?.data?.alreadyAddedRecruiter)
          mailShare=allmailforshare.filter((x)=>!(shareResumeResponse.data.alreadyAddedRecruiter.includes(x)))
        else
        mailShare=allmailforshare;
          if (mailShare.length>0) {
              NotificationManager.success(  
                ` Your Poweresume has been shared with :-  ` + mailShare.map((x)=>x+'\n'), 
                "Success",
              )
          }
          else {
            NotificationManager.error(
              ` You have already shared your Poweresume with :-  ` + shareResumeResponse.data.alreadyAddedRecruiter.map((x)=>x+'\n'),
              "Error"
            );
          }
        } catch (err) {
          console.log(err);
          NotificationManager.error(
            "Error sending email. Please try again after some time.",
            "Error"
          );
        }
      } else {
        NotificationManager.error(
          "Enter atleast one email address and a message",
          "Error"
        );
      }
    }
  };
  const showhambergermenu = (e) => {
    if (navDisplay === true) {
      setnavDisplay(false);
    } else {
      setnavDisplay(true);
    }
  };
  const handelcloseshare = () => {
    setmodalForShare(false);
    setallmailforshare([]);
    setErrorEmailMessage(false)
  };
  const openShare = async () => {
    setmodalForShare(true);
    setisNavigationOpen(false);
    allmailforshare.current = [];
    shareMessageRef.current = null;
    getProfileData();
  };
  const divClose =()=>{
    setisNavigationOpen(false);
  }
  const emailHandler = (e) => {
    inputemail.current = e;
  };
  useEffect(() => {
    emailHandler()
  }, [modalForShare])
  const addEmailHandler = async () => {
    const mailAddress = inputemail.current;
    const local_email = validateEmail(inputemail.current);
    if (
      local_email &&
      (mailAddress.includes("gmail") ||
        mailAddress.includes("outlook") ||
        mailAddress.includes("yahoo") ||
        mailAddress.includes("hotmail"))
    ) {
      NotificationManager.error("Please Use A Corporate Email", "Error");
    }
    else if (local_email) {
      var temp = 0;
      for (var i = 0; i <= allmailforshare.length; i++) {
        if (inputemail.current == allmailforshare[i]) {
          temp++;
        }
      }
      if (temp == 0) {
        emailCheak();
        await setallmailforshare((prev) => [...prev, inputemail.current]);
      }
      else {
        NotificationManager.error("Email Already Exist ", "Error");
      }
    } else {
      NotificationManager.error("Please Enter Correct Email", "Error");
    }
  };
  var tempCount=0;
  var mailct = allmailforshare.length;
  const deleteEmailHandler = (i) => {
    if (i > -1) {
      allmailforshare.splice(i, 1);
    }
    setallmailforshare((prev) => [...prev]);
    {allmailforshare.map((value, i) =>
    {tempCount= tempCount + value.length }
        )
        tempCount=tempCount+((mailct-1)*10)
        if(tempCount>80)
        { 
          setcount(tempCount)
        }
        else{
          setmaillength(mailct-2)
        }
     setcount(tempCount)
    
    }
  };
  const DialogContent = withStyles((theme) => ({
    root: {
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      justifyContent: "center",
    },
  }))(MuiDialogActions);

  const signOut = () => {
    localStorage.removeItem("userID");
    localStorage.removeItem("role");
    localStorage.removeItem("token")
    localStorage.removeItem("videoIntroUrl");
    window.open("/", "_self");
  };

  const _modalClose = () => {
    setOpen(false);
  };
  const getProfile = () => {
    try {
      if (
        profileData.profilePicUrl != "" &&
        profileData.profilePicUrl != null
      ) {
        setProfilePicture(profileData.profilePicUrl);
      }
    } catch (error) {
    }
  };

  const emailCheak =()=>{
    var tempo = allmailforshare.length; 
    let countEmail = count+inputemail.current.length +10;
        setcount(countEmail)
        if(countEmail >80)
        {
          setshowmail(false)
        }
        else{
          setmaillength(tempo)
        }
}
const mailclose =()=>{
  setshowmail(false);
}
const mailopen =()=>{
  setshowmail(true);
  
}
const messageHandler =(e, value) =>{
  mailclose();
}
const getCustomizedFirstName = (fullName,length) => {
  const names = fullName.split(" ");
  let firstName = names[0];

  if (firstName.length > length) {
    firstName = `${firstName.slice(0, length)}...`;
  }
  return firstName;
};
  
useEffect(() => {
    getProfileData();
    getProfile();
  },[profileData]);
  return (
    <>
    <div className="Poweresume">
      <div className="nav_bg header_fixed profile_headshadow">
        <div className="container w1200 nav_bg_home_sectio home-specific-header resume_head"  >
          <div className="nav_bg_left">
            <a href="/profile"><img
              src={Logo}
              className="nav_logo_home cp"
              alt="Cleared Talent Logo"
            />
            </a>
          </div>
          {
          loadingController ?         
          <div className="profile_dropdown">
              <div className="fl">
                <img
                  src={profilePicture}
                  alt="profile picture"
                  className="profile_Image cp"
                />
                <div className="fle cp" onClick={(e) => setisNavigationOpen(!isNavigationOpen)}>
                  <Typography className="user_menu  profile_name profileName_DesktopElement" > Hi, {getCustomizedFirstName(mailData.firstName,15)} </Typography>
                  <Typography className="user_menu  profile_name profileName_PhoneElement" > Hi, {getCustomizedFirstName(mailData.firstName,10)} </Typography>
                    <ArrowDropDown className="profile_drop" />                    
                </div>
                {isNavigationOpen ? (
                 <ClickAwayListener onClickAway={divClose}>
                  <div className="profile_drop_men">
                    <Card>
                      <CardContent className="all_profil">
                        <img
                          src={profilePicture}
                          alt=""
                          className="profileIma"
                        />
                        <Typography className="sdudent_name">{displayname}</Typography>
                      </CardContent>
                      <CardActions className="cardAction">
                        <MenuList className="menulist">
                          <MenuItem className="card_text" onClick={openShare}><ShareIcon />&nbsp; Share</MenuItem>
                          <MenuItem className="card_text" onClick={openSupport}> <img src={SupportIconNew} className="pr_support"/>&nbsp; Support</MenuItem>
                          <MenuItem className="card_text" onClick={signOut}><ExitToAppIcon />&nbsp; Sign Out</MenuItem>
                        </MenuList>
                      </CardActions>
                    </Card>
                  </div>
                  </ClickAwayListener>
                ) : null}
              </div>  
          </div>
          :""}
        </div>
        <LoggedInSupportModal
          modalOpen={open}
          modalClose={_modalClose}
          mailData={mailData}
        />

        {/* dailog for share */}
        <Dialog
          open={modalForShare}
          onClose={handelcloseshare}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle className='support_dialog_title'>
            <p id="para">Share your</p>
            <div className='support_lin'>PoweResume</div>
            <img
              src={Ellipse}
              alt='img'
              className='blue'
            />
          </DialogTitle>
          <DialogContent className='support_con'>
            <div className='support_header'>
              <Grid container className='support_grid'>
                <Grid item xs={12}><div className='support_form_leb mt_18 mb_3 profile_support'>Add Recipients*</div></Grid>
                <Grid item xs={12}>
                {allmailforshare.map((value, i) =>
                  showmail ? (
               
               <span className="mail_bg ">
               <span className="mail_txt">{value.toLowerCase()}</span>
                      <span className="mail_close_icon cp">
                        <img
                          src={cancelemailicon}
                          onClick={(e) => deleteEmailHandler(i)}
                          height="13px"
                          width="13px"
                        />
                      </span>
                      </span>
                    ):
                    (
                      (maillength+1) > i  ?
                         (
                    <span className="mail_bg">
                      <span className="mail_txt">{value.toLowerCase()}</span>
                      <span className="mail_close_icon cp">
                        <img
                          src={cancelemailicon}
                          onClick={(e) => deleteEmailHandler(i)}
                          height="13px"
                          width="13px"
                        />
                      </span>
                    </span>
                  ) : (
                    <div onClick={mailopen} className="mail_more">    
                    { i=== (maillength+1) ?
                      (
                    <span className="mail_b " > 
                    <span className="cp">{(allmailforshare.length-1)-maillength}{" more"}</span>
                    </span>                        
                      )
                      :("")
                    }                      
                      </div>  
                    )   
                    )  
                    )}
                  <Autocomplete
                    id="tags-filled"
                    disableClearable
                    options={[]}
                    freeSolo
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                    }}
                    filterSelectedOptions
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          title: `${params.inputValue}`,
                        });
                      }
                      return filtered;
                    }
                    }
                    onChange={() => 
                        addEmailHandler()
                      }
                    renderOption={(option) => <li className="cp">{option.inputValue}</li>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="classes.mailText"
                        
                        onChange={(e) => emailHandler(e.target.value.trim())}
                        placeholder="Add Mail"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className="support_grid_message">
                  <div className='support_form_leb mb_10'>Add a message*</div>
                </Grid>
                <Grid item xs={12}>
                  <textarea
                    type="text"
                    className="support_message"
                    rows={4}
                    onChange={(e) => setshareMessage(e.target.value)}
                    onClick={messageHandler}
                  />
                  {errorEmailMessage ? <p className='form-error fs-regular'>Please enter a message</p> : ''}
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions className={`${classes.buttonForSupport} `}>
            <Button className={classes.btnCancel} onClick={handelcloseshare}>Cancel</Button>
            <Button className={classes.btnSave} onClick={sharePoweResumeEmail}>Share</Button>
          </DialogActions>
        </Dialog>
        <NotificationContainer />
        <Snackbar
          open={mailValidate}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="error"
            className="remove-snackbar-alert"
          >
            Please enter your question.
          </Alert>
        </Snackbar>
      </div>
      </div>
    </>
  );
};
export default Header;
