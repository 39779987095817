import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory } from "react-router";

import Grid from "@material-ui/core/Grid";
import Footer from "../../components/adminFooter/Footer";
import Header from "../../components/headerAdmin/Header.js";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import getInvitedUserTestInfo from "../../apis/userTest/getUserTestInfoByUserId";
import CircularProgress from "@material-ui/core/CircularProgress";
import Micoff from "../../resources/images/micoff.png";
import Micon from "../../resources/images/micon.svg";
import Videon from "../../resources/images/videoon.png";
import Videooff from "../../resources/images/videooff.svg";
import bubble from "../../resources/images/bubble.svg";
import DefaultThumbnail from "../../resources/images/defaultvideo.svg";
import getQuestionalCountByTestId from "../../apis/question/getQuestionCountByTestId";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import HomeContactUsModal from "../../pages/home/homeHeader/homeContactUsModal/homeContactUs";
import { UserTypeContext } from "../../AppRoute";
import getUserTestDynamicQuestion from "../../apis/userTest/getUserTestDynamicQuestion";
import TestLandingPage from "../../components/basicInfoForm/TestLandingPage";
import TestLandingSkeleton from "../../components/Skelton/TestLandingSkeleton";
import { toHoursAndMinutes } from "../../utilities/toHourMinuteSecond";

const InviteTestLanding = (props) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const history = useHistory();
  const userCameraStreamRef = useRef(null);
  const [volume, setvolume] = useState(null);
  const [mediaStream, setmediaStream] = useState(null);
  const [isCameraOpen, setisCameraOpen] = useState(true);
  const [isMicOpen, setisMicOpen] = useState(true);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [userTestInfo, setuserTestInfo] = useState([]);
  const [totalQuestion, setTotalQuetions] = useState(0)
  const [testId, setTestId] = useState('');
  const videoref = useRef(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [allTestId, setAllTestId] = useState([]);
  const [totalTestTime, setTotalTestTime] = useState(0);
  const [userId, setUserId] = useState(props.idContextTest)
  const [skillId, setSkillId] = useState("")

  const gotoProfile = async () => {
    await userCameraStreamRef.current.getTracks().forEach((track) => {
      track.stop();
    });
    history.push("/profile");
  };
  const constraints = {
    audio: true,
    video: { width: { ideal: 1280 }, height: { ideal: 720 } },
  };

  const opencamera = async () => {
    // const video = document.getElementById('video');
    document.getElementById("video").style.display = "none";

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      window.stream = stream;
      // video.srcObject = stream;
      document.getElementById("video").style.display = "block";
      videoref.current.srcObject = stream;
      setmediaStream(stream);
      userCameraStreamRef.current = stream;
      document.getElementById("thumbnail").style.display = "none";

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);

      const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 1024;

      microphone.connect(analyser);
      analyser.connect(scriptProcessor);
      scriptProcessor.connect(audioContext.destination);
      scriptProcessor.onaudioprocess = function () {
        const array = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        const average = arraySum / array.length;
        setvolume(average);
      };
    } catch (e) {
      console.log(e);
      document.getElementById("video").style.display = "none";
      userCameraStreamRef.current = "";
    }
  };

  // const cameraOpenHandler = (e) => {

  //     setisCameraOpen(!isCameraOpen)
  //     if (e) {
  //         videoref.current.srcObject = mediaStream
  //     }
  //     else {
  //         videoref.current.srcObject = null
  //     }
  // }
  // const micOpenHandler = (e) => {
  //     setisMicOpen(!isMicOpen)

  // }
  const getInvitedTestInfo = async () => {
    if (props.idContextTest) {
      let payload = {
        id: props.idContextTest,
      };
      const info = await getInvitedUserTestInfo(payload);
      // console.log(info.data.data[0].test.skillId,"bbbbb")
      setSkillId(info.data.data[0].test.skillId)
      setTestId(info.data.data[0].testId)
      setuserTestInfo(info.data.data);
      setisDataLoaded(true);
    }
  };
  const startTest = async () => {
    if (userCameraStreamRef.current) {
      await userCameraStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      })
      if (allTestId.length > 0) {
        history.push({
          pathname: "/video-skill-test-registered",
          state: {
            ...props?.location?.state,
            userId: props.idContextTest,
            test: userTestInfo[0].test,
            allTestId,
            isCandidateExist: true,
            pageName: "/invite/" + userTestInfo[0].id,
            recruitmentpartnerId: userTestInfo[0]?.user?.recruitmentpartner?.id,
            inviteId: userTestInfo[0]?.id,
            recruiterId: userTestInfo[0]?.invitedBy,
            skillId: skillId
          },
        });
      } else {
        return NotificationManager.error(
          "Question Bank For This Skill Is Being Updated – Please Try Later.",
          "Error",
          3000
        );
      }

    } else {
      return NotificationManager.error(
        "This test cannot be taken without activating camera and microphone. Please allow camera and microphone access.",
        "Error",
        3000
      );
    }
  };
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        gotoProfile()
      }
    };
  }, [history])
  useEffect(() => {
    getInvitedTestInfo();

    document.body.style = "background: #ffffff;";
  }, []);
  useEffect(() => {
    if (isDataLoaded) {
      opencamera();
    }
  }, [isDataLoaded]);


  useEffect(() => {

    if (userId && testId) {
      (async () => {
        setisDataLoaded(false)
        const res = await getUserTestDynamicQuestion(userId, testId);
        if (res.data.data) {
          const testIds = res.data.data.map(item => item.id);
          const totaltime = res.data.data.reduce((accumulator, object) => {
            return accumulator + object.timeLimit * 1;
          }, 0);
          setisDataLoaded(true)
          let timeVal = toHoursAndMinutes(totaltime);
          setTotalTestTime(timeVal)
          setAllTestId(testIds)
        } else {
          setisDataLoaded(true)
          let timeVal = toHoursAndMinutes(0);
          setTotalTestTime(timeVal)
          setAllTestId([])
        }
      })()
    }

  }, [userId, testId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (testId) {
          const response = await getQuestionalCountByTestId(testId);

          setTotalQuetions(response.data.data[0].totalquestionscount)
        }

      } catch (error) {
        console.log('error')
      }
    }
    fetchData();
  }, [testId])
  const openSupportModal = () => {
    setIsModelOpen(true);
  };
  const modalClose = () => {
    setIsModelOpen(false);
  }

  return (
    <>
      {userType === "Admin" || userType === "Recruiter" ? (
        <div className="row padding_80 text-center">
          <p className="fs-semi-bold fs-40 f-color">
            You Are Not Authorized To Visit This Page
          </p>
        </div>
      ) :
        (<>
          <div className="nav_bg_home home-specific-header">
            <div className="container w1200 nav_bg_home_section">
              <div className="nav_bg_left">
                <li className="cp headerListStyleNone">
                  <a>
                    <img
                      src={recruiter_header_logo}
                      className="nav_logo_home"
                      alt="img"
                      onClick={(e) => gotoProfile(e)}
                    />
                  </a>
                </li>
              </div>
              <div className="nav_bg_right">
                <ul className="navbar_home">
                  <li className="special_hover cp" onClick={openSupportModal}><span className="contactUsFont">Contact Us</span></li>
                </ul>
              </div>
            </div>
          </div>
          {!isDataLoaded ? (<TestLandingSkeleton />) : (
            <>

              <div className="nav_bg_home_responsive">
                <div className="nav_bg_home_responsive-wrapper">
                  <div className="home-top-nav">
                    <li className="cp headerListStyleNone">
                      <a>
                        <img
                          src={recruiter_header_logo}
                          className="nav_logo_home"
                          alt="img"
                          onClick={(e) => gotoProfile(e)}
                        />
                      </a>
                    </li>
                  </div>
                </div>
              </div>

              {isDataLoaded ? (
                userTestInfo.length > 0 ? (
                  <div>
                    <div className="test_landing_header pr">
                      <p className="test_landing_header_text_1">
                        Welcome back to ClearedTalent
                      </p>
                      <p className="test_landing_header_text_2">
                        Test for {userTestInfo[0]?.test?.testName}
                      </p>
                      <p className="test_landing_header_text_3">
                        You were invited to take the test by{" "}
                        <span className="test_landing_header_text_3_3">
                          {userTestInfo[0]?.user?.firstName +
                            " " +
                            userTestInfo[0]?.user?.lastName}
                        </span>{" "}
                        ({userTestInfo[0]?.user?.recruitmentpartner?.name})
                        {userTestInfo[0]?.test?.customer?.name !== undefined ? "(" + userTestInfo[0]?.test?.customer?.name + ")" : ""}
                      </p>
                    </div>
                    <Grid container spacing={0} className="test_landing_main">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className="vl_recruiter test_landing_left_bg"
                      >
                        <div className="form-one-left_recruiter test_left_adjust">
                          <div
                            className="form-one-left-upper"
                            style={{ display: "flex", justifyContent: "space-between" }}
                          >
                            <div className="form-one-left-section df">

                              <video
                                className="MediaStreamView-video test_landing"
                                id="video"
                                playsInline
                                ref={videoref}
                                autoPlay={true}
                              />
                              <img
                                src={DefaultThumbnail}
                                id="thumbnail"
                                className="default_video"
                              />


                              <div className="test_landing_video_bottom_section">
                                {userCameraStreamRef.current ? (
                                  <>
                                    <div className="test_landing_video_bottom_icon">
                                      <img
                                        src={Videon}
                                        className="test_landing_video_bottom_icon_adjust"
                                      />
                                    </div>
                                    <div className="test_landing_video_bottom_icon">
                                      <img
                                        src={Micon}
                                        className="test_landing_video_bottom_icon_adjust"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="test_landing_video_bottom_icon">
                                      <img
                                        src={Videooff}
                                        className="test_landing_video_bottom_icon_adjust"
                                      />
                                    </div>
                                    <div className="test_landing_video_bottom_icon">
                                      <img
                                        src={Micoff}
                                        className="test_landing_video_bottom_icon_adjust"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="bar">
                              {isMicOpen ? (
                                <div
                                  className="pid"
                                  style={{ height: `${volume}%` }}
                                ></div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="test_recorder_bottom_text_section">
                          <p className="test_recorder_bottom_text_section_text">
                            *Please allow access to the microphone and camera
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="mainbg_form_recruiter df">
                          <div className="other_formpage_recruiter">
                            <div className="form_text_container_other">
                              <div className="test_dres_right_header">
                                <p className="test_dres_right_header_text">
                                  Start when you are ready
                                </p>
                              </div>
                              <div className="form-one-left-text test_screen">
                                <p className="test_dres_right_header_text_1">
                                  1. This test has{" "} <span className="blueTexts"> {allTestId.length ? allTestId.length : 0} </span> multiple choice questions & the total test time is
                                  <span className="blueTexts">{totalTestTime.h >= 1 ? ` ${totalTestTime.h}` : ""}</span>
                                  {totalTestTime.h > 1 ? " hours," : totalTestTime.h === 1 ? " hour," : ""}
                                  <span className="blueTexts">{totalTestTime.m >= 1 ? ` ${totalTestTime.m}` : ""}</span>
                                  {totalTestTime.m > 1 ? " minutes" : totalTestTime.m === 1 ? " minute" : ""}
                                  {totalTestTime.s >= 1 ? " and" : ""}
                                  <span className="blueTexts">{totalTestTime.s >= 1 ? ` ${totalTestTime.s}` : ""}</span>{totalTestTime.s > 1 ? " seconds" : totalTestTime.s === 1 ? " second" : ""}
                                  <span className="blueTexts">{allTestId.length === 0 ? ` ${"0"} ` : ""}</span>
                                  {allTestId.length === 0 ? "minute" : ""}.
                                </p>
                                <p className="test_dres_right_header_text_1">
                                  2. For multiple choice questions, you may be allowed to pick just one answer choice or multiple. The clock starts as soon as you click <span className="leftBullets">“Start Test”</span> so start <span className="leftBullets">ONLY</span> when you are ready.
                                </p>
                                <p className="test_dres_right_header_text_1">
                                  3. Some questions may have two parts. For example, Part 1 may be an open ended question where you are expected to speak out your response on video for 1 minute and Part 2 may be a multiple choice question where you are expected to pick the correct answer(s) from the options provided.
                                </p>
                                <p className="test_dres_right_header_text_1">
                                  4. You will be video recorded during the test so please ensure your camera is active and focusing on you.
                                </p>
                                <p className="test_dres_right_header_text_1">
                                  5. Please note that at no point during the test you can go back to the previous question.
                                </p>
                                <p className="test_dres_right_header_text_1">
                                  6. Please try not to navigate out of the test window (or open other applications) for the test duration. We detect such movements and report them to the reviewer.
                                </p>
                              </div>

                              <div className="test_dres_right_bottom">
                                <p className="test_dres_right_bottom_text">
                                  If you're all set click the button below.
                                </p>
                              </div>
                              <div className="test_landing_button_wrapper">
                                <button
                                  type="button"
                                  className="btn-testlanding btn-test-landing"
                                  onClick={() => startTest()}
                                >
                                  Start Test
                                </button>
                              </div>
                              <div className="test_dres_right_bottom">
                                <p
                                  className="test_dres_right_bottom_text_1"
                                  onClick={gotoProfile}
                                >
                                  Or Take The Test Later
                                </p>
                              </div>

                              <div className="test_dres_right_bottom1">
                                <p className="test_dres_right_bottom_text_2">
                                  Test invitation expires on{" "}
                                  {userTestInfo[0].expiresOn
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div className="section-wrapper">
                    <div className="container w1004 flex-to-footer">
                      <div className="row circular-progress-center text-center">
                        <div className="height_adjust">
                          <p className="f-color fs-regular fs-22">
                            You don't have any active invite for test
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="section-wrapper">
                  <div className="container w1004 flex-to-footer">
                    <div className="row circular-progress-center text-center">
                      <CircularProgress />
                    </div>
                  </div>
                </div>
              )}
            </>)}
          <Footer />
          <NotificationContainer />
          <HomeContactUsModal modalOpen={isModelOpen} modalClose={modalClose} />
        </>)}
    </>
  );
};

export default InviteTestLanding;
