import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EditIcon from "@material-ui/icons/Edit";

/** components */

import Header from "../../components/tableElements/header";

/*** components */

const AddClientAdminTable = (props) => {
  const { clientData, openModal } = props;

  const colNames = ["Name","Organization Type", "Edit", "Is Active"];
  const colAlignment = ["inherit","inherit", "center", "center"];

  const columns = colNames.map((name, ix) => {
    return { name: name, align: colAlignment[ix] };
  });

  const btnEdit = {
    cursor: "pointer",
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <Header columnNames={columns} />
        <TableBody className="user_table">
          {clientData.map((value, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {value.name}
              </TableCell>
              <TableCell component="th" scope="row">
              {value.organizationtypeId ? value.organizationtype?.name : "" }
              </TableCell>
              <TableCell
                align="center"
                style={btnEdit}
                onClick={(e) => openModal(e, value)}
              >
                <EditIcon />
              </TableCell>
              <TableCell align="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={value.isActive}
                      value={value.id}
                    />
                  }
                  labelPlacement="start"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddClientAdminTable;
