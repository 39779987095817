import axios from "axios";
const jsonConfig = require("../../Config.json");

const verifyTokenResponse = async (payload) => {
    let captchaResponse = {}
    try {
        const response = await axios.post(
            jsonConfig.apiUrl + "capture",payload,
          
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
            captchaResponse  = response;
            console.log("response api",captchaResponse)

        return Promise.resolve(captchaResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default verifyTokenResponse;