import axios from "axios";
const jsonConfig = require("../../Config.json");

export const getAllRecruiterEmails = async () => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "getallrecruiteremails", {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const recruiterRequisition = async (payload) => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "RequisitionforRecruiter",
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const reassignRecruiter = async (payload) => {
  try {
    const getAllResponse = await axios.post(jsonConfig.apiUrl + "recruiterReassignment",
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
}
