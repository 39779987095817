import axios from "axios";
const jsonConfig = require("../../Config.json");

const resetPasswordOtpResponse = async (payload) => {
    let allResponse = {};
    try {
        const resetpassworotpData = await axios.post(
            jsonConfig.apiUrl + "resetpassworotp", payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
        allResponse = resetpassworotpData;

        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr",error);    
        return Promise.reject(error);
    }
}

export default resetPasswordOtpResponse;