import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateSubSkillsResponse = async (subSkillId,payload) => {
    let allSkillsList = {}
    try {
        const getallskillresponse = await axios.put(
            jsonConfig.apiUrl + "subSkills/" + subSkillId,
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        allSkillsList = getallskillresponse;

        return Promise.resolve(allSkillsList)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default updateSubSkillsResponse;