import React, { useState, useEffect, useContext, useRef } from "react";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import Header from "../../components/adminHeader/adminHeader";
import CircularProgress from "@material-ui/core/CircularProgress";

import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import "./questionReport.css";
import incorrect from "../../components/profile/skillVideotest/images/incorrect.png";
import correct from "../../components/profile/skillVideotest/images/correct.png";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import getQuestionReportResponse from "../../apis/questionReport/getQuestionReports";
import resolveQuestionReportResponse from "../../apis/questionReport/resolveQuestionReport";
import updateQuestionReportResponse from "../../apis/questionReport/updateQuestionReport";

import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import InputBox from "../../components/formElements/inputBox";
import { TextareaAutosize } from "@material-ui/core";
import { DateTime } from "luxon";
import { UserTypeContext } from "../../AppRoute";
const useStyles = makeStyles((theme) => ({
  buttonAlignForRemove: {
    padding: "32px!important",
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0.5),
  },
});
const QuestionReport = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);

  const jsonConfig = require("../../Config.json");
  const classes = useStyles();
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const history = useHistory();
  const [reportedData, setreportedData] = useState([]);
  const [reportedDataType, setreportedDataType] = useState("Unresolved");
  const [isModalOpen, setisModalOpen] = useState(false);
  const [dataForModal, setdataForModal] = useState({});
  const [userAnsArray, setuserAnsArray] = useState([]);
  const [showAction, setShowAction] = useState(true);
  const [reportOnChange, setReportOnChange] = useState(false);
  const [valueReport, setValueReport] = useState({ name: "Unresolved" });
  const [IsOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
  const [confirmDailogState, setconfirmDailogState] = useState({});
  const [confirmDailogType, setconfirmDailogType] = useState("");
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState({});
  useEffect(() => {

    getReportedData();

  }, [reportedDataType]);
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getReportedData();
      } catch (error){
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
   }
   fetchAllData()
  }, []);
  const reportTypeHandler = (e, value) => {
    setReportOnChange(true);
    if (value) {
      setValueReport(value);
      setreportedDataType(value.name);

      if (value.name === "Accepted") {
        setShowAction(false);
      }
      if (value.name === "Rejected") {
        setShowAction(false);
      } else {
        setShowAction(true);
      }
    } else {
      setreportedDataType("");
      setShowAction(true);
      setValueReport({});
    }
  };

  const getReportedData = async () => {

    var reportdata = {
      reportStatus: reportedDataType,
    };
    try {
      // const reporteddataResponse = await axios.post(
      //     jsonConfig.apiUrl + "GetquestionReports", reporteData,
      //     {
      //         headers: {
      //             "x-functions-key": jsonConfig.key,
      //         },
      //     }
      // );
      const reporteddataResponse = await getQuestionReportResponse(reportdata);

      if (reporteddataResponse.data.data.length > 0) {


        setreportedData(reporteddataResponse.data.data);
        setShowAction(
          reporteddataResponse.data.data.some(
            (e) => e.reportStatus === "Unresolved"
          )
        );

      } else {


        setreportedData([]);

      }
    } catch (error) {
      console.log(error);
    }

  };


  const openViewModal = (value) => {
    setdataForModal(value);
    setisModalOpen(true);
  };
  const closeModal = () => {
    setisModalOpen(false);
  };

  const handleOpen = (obj = {}) => {
    setComment({ ...obj, admincomments: "" });
    setOpen(true);
  };

  const handleClose = () => {
    setComment({});
    setOpen(false);
  };


  const resolveQuestion = async (value, type) => {
    var resolveData = {
      questionreportid: value.id,
      reportStatus: type,
    };

    try {
      const resolveQuestionResponse = await updateQuestionReportResponse(
        resolveData
      );
      await getReportedData();
      setreportedDataType("");
      setValueReport({});
      await NotificationManager.success(
        `This report is marked as ${type}ed`,
        "Success",
        2500
      );
      setIsOpenConfirmDailog(false);
    } catch (error) {
      console.log(error);
    }
  };
  const gotoQuestionReport = (questionId) => {
    history.push("/questions/" + questionId);
  };
  const openConfirmDailog = (value, type) => {
    setconfirmDailogState(value);
    setconfirmDailogType(type);
    setIsOpenConfirmDailog(true);
  };


  const headername = {
    padding: "16px 3px",
  };
  const reportType = [
    { name: "Unresolved" },
    { name: "Accepted" },
    { name: "Rejected" },
  ];
  const closeButton = {
    position: "absolute",
    right: "7px",
    top: "12px",
    color: "grey",
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "none",
    padding: "7px 11px 7px 11px",
    float: "right",
    marginRight: "17px",
    marginBottom: "15px",
  };
  const closeModalBtn = {
    color: "#01313C",
    backgroundColor: "#fff",
    border: " 1px solid #A3BCD5",
    marginBottom: "15px",
  };

  const textStyle = {
    marginLeft: "-13px",
  };

  const handleSaveComment = async (e) => {
    if (comment.admincomments === "" || !comment.admincomments) {
      return NotificationManager.error(
        "Please fill all the mandatory fields",
        "Error",
        2500
      );
    }
    try {
      const response = await updateQuestionReportResponse(comment);
      if (comment.reportStatus === "Accepted") {
        NotificationManager.success(
          "Question Report Accepted",
          "Success",
          2500
        );
      }
      if (comment.reportStatus === "Rejected") {
        NotificationManager.success(
          "Question Report Rejected",
          "Success",
          2500
        );
      }

      const newData = [...reportedData].filter(
        (d) => d.id !== comment.questionreportid
      );
      setreportedData(newData);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <Container
                    component={Paper}
                    className="allignmentFix"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {/* //<DialogTitle id="customized-dialog-title" className="admindailog_title" style={headername}>Reported Questions</DialogTitle> */}
                        <h2 className="fs-semi-bold fs-30 f-color">
                          Reported Questions
                        </h2>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography gutterBottom>Resolution Status</Typography>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            autoSelect
                            id="combo-box-demo"
                            options={reportType}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Resolution Status"
                              />
                            )}
                            value={valueReport}
                            onChange={(e, value) => reportTypeHandler(e, value)}
                          />
                        </FormControl>
                      </Grid>
                      <TableContainer>
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              {/* <TableCell align="left">Name</TableCell> */}
                              <TableCell align="left" className="pl-0">
                                Question
                              </TableCell>
                              <TableCell align="left" className="nowrap-white">
                                Reported Date
                              </TableCell>
                              <TableCell
                                align="center"
                                className="nowrap-white"
                              >
                                Resolution Status
                              </TableCell>
                              <TableCell
                                align="center"
                                className="nowrap-white"
                              >
                                Reported By
                              </TableCell>
                              <TableCell
                                align="center"
                                className="nowrap-white"
                              >
                                Question Id
                              </TableCell>
                              <TableCell align="center">View</TableCell>
                              {/* {!showAction && <TableCell align="center">Action</TableCell>} */}
                              {/* {reportOnChange ?
                                                                    <> */}
                              {showAction ? (
                                <>
                                  <TableCell align="right">Action</TableCell>
                                </>
                              ) : (
                                ""
                              )}
                              {/* </>
                                                                    :
                                                                    <>
                                                                    </>
                                                                } */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportedData.length !== 0 ? (
                              reportedData.map((value, index) => {
                                if (index === 0) {
                                }
                                return (
                                  <TableRow key={index}>
                                    {/* <TableCell >
                                                                            {
                                                                                (value.user.firstName ? value.user.firstName : "")
                                                                                + " " + (value.user.MiddleInitial ? value.user.MiddleInitial : "")
                                                                                + " " + (value.user.lastName ? value.user.lastName : "")
                                                                            }
                                                                        </TableCell> */}
                                    <TableCell
                                      size="small"
                                      className="cp pl-0 link-colour"
                                      onClick={() =>
                                        history.push(
                                          "/questions/" + value.questionId
                                        )
                                      }
                                    >
                                      {value.question.questionDesc}
                                      {/* <Link href={"https://" + window.location.host + "/questions/" + value.questionId} variant="body1" target="_blank">
                                                                            {(value.question.questionDesc)}
                                                                            </Link> */}
                                      {/* <NavLink to={`questions/${value.questionId}`}>{(value.question.questionDesc)}</NavLink> */}
                                    </TableCell>
                                    <TableCell>
                                      {/* {value.createdAt
                                        ? moment(value.createdAt).format("LLL")
                                        : ""} */}
                                      {value.createdAt
                                        ? DateTime.fromISO(
                                          value.createdAt
                                        ).toFormat("LLLL d, yyyy t")
                                        : ""}
                                    </TableCell>
                                    <TableCell>
                                      {value.reportStatus
                                        ? value.reportStatus
                                        : ""}
                                    </TableCell>
                                    <TableCell>
                                      <Link
                                        href={
                                          "https://" +
                                          window.location.host +
                                          "/candidate/profile/" +
                                          value.user.id
                                        }
                                        variant="body1"
                                        target="_blank"
                                      >
                                        {(value.user.firstName
                                          ? value.user.firstName
                                          : "") +
                                          " " +
                                          (value.user.MiddleInitial
                                            ? value.user.MiddleInitial
                                            : "") +
                                          " " +
                                          (value.user.lastName
                                            ? value.user.lastName
                                            : "")}
                                      </Link>
                                    </TableCell>
                                    <TableCell>{value.question.id}</TableCell>
                                    <TableCell>
                                      <Button
                                        onClick={(e) => openViewModal(value)}
                                      >
                                        <VisibilityIcon />
                                      </Button>
                                      {/* <Button onClick={(e) => resolveQuestion(value)}>Resolved</Button> */}
                                    </TableCell>
                                    {/* <TableCell>
                                                                            {value.reportStatus ==='Resolved' 
                                                                            ?
                                                                            null
                                                                             : 
                                                                             <Button onClick={(e) => resolveQuestion(value)}>Resolve</Button>
                                                                             }
                                                                        </TableCell> */}
                                    {value.reportStatus === "Accepted" ||
                                      value.reportStatus === "Rejected" ? null : (
                                      <>
                                        <TableCell>
                                          {/* <Button onClick={(e) => openConfirmDailog(value,"Accepted")}>Accept</Button> */}
                                          <Button
                                            onClick={() =>
                                              handleOpen({
                                                questionreportid: value.id,
                                                reportStatus: "Accepted",
                                              })
                                            }
                                          >
                                            Accept
                                          </Button>
                                        </TableCell>
                                        <TableCell>
                                          {/* <Button onClick={(e) => openConfirmDailog(value,"Rejected")}>Reject</Button> */}
                                          <Button
                                            onClick={() =>
                                              handleOpen({
                                                questionreportid: value.id,
                                                reportStatus: "Rejected",
                                              })
                                            }
                                          >
                                            Reject
                                          </Button>
                                        </TableCell>
                                      </>
                                    )}
                                  </TableRow>
                                );
                              })
                            ) : (
                              <Typography gutterBottom>
                                NO DATA FOUND
                              </Typography>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Container>
                ) : userType === "" ? (<div className="row padding_80 text-center">
                  <p className="fs-semi-bold fs-40 f-color">
                    Loading...
                  </p>
                </div>) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog
        onClose={closeModal}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <Grid item xs={12}>
          <div className="Recruiter_Private_Skill_Modal">
            <DialogTitle
              id="customized-dialog-title"
              className="admindailog_title_modal"
              onClose={closeModal}
            >
              Reported Question
              <IconButton
                aria-label="close"
                style={closeButton}
                onClick={closeModal}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </div>
        </Grid>
        <DialogContent className="userlist_dailog_content">
          <p
            className="fs-semi-bold fs-18 f-color cp"
            onClick={(e) => gotoQuestionReport(dataForModal.questionId)}
          >
            {dataForModal.question
              ? "Q: " + dataForModal.question.questionDesc
              : ""}
          </p>
          <div className="answer-set-container-question-report">
            {dataForModal.answer
              ? dataForModal.answer.map((item, index) => (
                // console.log(item.AnswerId,dataForModal.usertestquestionanswer.answerId)

                <div className="answer-set-list-questionreport mb_12 radiobtn-questionreport ">
                  <div>
                    <FormControlLabel
                      value="disabled"
                      checked={dataForModal.usertestquestionanswer.find(
                        (el) => el.answerId == item.AnswerId
                      )}
                      control={
                        dataForModal.answertype.code === "SingleChoice" ? (
                          <Radio />
                        ) : (
                          <Checkbox />
                        )
                      }
                    />
                    <span className="fs-regular fs-18 f-color">
                      {item.answerText}
                    </span>
                  </div>
                  <div>
                    <span>
                      {item.isCorrect ? (
                        <>
                          <p className="fs-semi-bold fs-16 f-color">
                            Correct Answer
                          </p>
                        </>
                      ) : null}
                    </span>
                  </div>
                </div>
              ))
              : null}
            {/* <Typography gutterBottom></Typography> */}
            <p className="fs-semi-bold fs-18 f-color mb_10">
              Question Report Description
            </p>
            <Typography>
              {dataForModal.description ? dataForModal.description : null}
            </Typography>
          </div>
        </DialogContent>

        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={closeModal}>
              Close
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <Dialog
        open={IsOpenConfirmDailog}
        onClose={() => setIsOpenConfirmDailog(false)}
        aria-labelledby="customized-dialog-title"
      >
        <DialogContent
          className={`${classes.bodyForRemove} removemodal_responsive`}
        >
          <div className="text-center fs-semi-bold f-color fs-18">
            {confirmDailogType === "Accepted"
              ? "Do you want to resolve this report by marking the candidate's answer as correct?"
              : "Do you want to reject this report?"}
          </div>
        </DialogContent>
        <DialogActions
          className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}
        >
          <div className="remove_modal">
            <button
              type="button"
              className="btn_remove btn-skill cp"
              onClick={() => setIsOpenConfirmDailog(false)}
            >
              No
            </button>
            <button
              type="button"
              className="btn2 btn-green ml_5 cp"
              onClick={(e) => {
                resolveQuestion(confirmDailogState, confirmDailogType)
                handleOpen()
              }
              }

            >
              Yes
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "500px",
          },
        }}
      >
        <DialogContent className="dialogContent">
          <Typography gutterBottom>Comment *</Typography>
          <TextareaAutosize
            className="textareaComment"
            type="text"
            placeholder="Enter Comment"
            value={comment.admincomments}
            onChange={(e) =>
              setComment({ ...comment, admincomments: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions className="btnAction">
          <Button onClick={handleClose} style={closeModalBtn}>
            cancel
          </Button>
          <Button onClick={handleSaveComment} style={btnGreen}>
            {comment.reportStatus === "Accepted" ? "accept" : "reject"}
          </Button>
        </DialogActions>
      </Dialog>

      <NotificationContainer />
    </>
  );
};

export default QuestionReport;
