import React from "react";
import { Typography, TextField } from "@material-ui/core";

const InputBox = ({ value, label, placeholder, className, onChange }) => {
  return (
    <>
      <Typography>{label}</Typography>
      <input
        type="text"
        className="text_box_cleartalent_form1  w-94"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  );
};

export default InputBox;
