import React, { useRef, useEffect, useState } from "react";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ReferralForm from "../../components/referralForm/referralForm.js";
import "./referral.css";
import Grid from "@material-ui/core/Grid";
import tick_mark from "../../components/basicInfoForm/images/successBlueTick.png";
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
// import axios from "axios";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import data from "./data.json";

import Footer from "../../components/adminFooter/Footer.js";
import getAllReferralSkiilsByRecruiterPartners from "../../apis/referral/referralbyrecruitmentpartner";
import getAllReferralPartnerSkill from "../../apis/referral/getAllReferralPartnerSkill";
import referralVerifyUser from "../../apis/otp/referralVerifyUser";
import verifyOtpResponse from "../../apis/otp/verifyOtp";
import reSendOtpResponse from "../../apis/otp/reSendOtp";
import sendReferrals from "../../apis/referral/sendReferrals";
// import getUserByEmailId from "../../apis/referral/getUserByEmailId";
import jsonConfig from "../../Config.json";
import { BlobServiceClient } from "@azure/storage-blob";
import HomeContactUsModal from "../home/homeHeader/homeContactUsModal/homeContactUs";
import SendmailModal from "./sendmailModal"
import referredEmails from "../../apis/referral/referredEmails";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& > *": {
//       margin: theme.spacing(1),
//     },
//   },
//   input: {
//     display: "none",
//   },
//   otpStyle: {
//     caretColor: "transparent",
//   },
// }));

const Referral = (props) => {
  const { location } = props;

  const recruiterPartnerId = location?.state?.recruiterPartnerId;
  const [isInviteSend, setisInviteSend] = useState(false);

  const [putEmailPage, setPutEmailPage] = useState(false);
  const [putOtp, setPutOtp] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(true);
  const [failModal, setFailModal] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [counter, setCounter] = useState(jsonConfig.OTPTimeLimit);
  const [emailidId, setemailidId] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [allRecruitmentOrgLogo, setAllRecruitmentOrgLogo] = useState([]);
  const [startProgress, setStartProgress] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const btnRef = useRef();
  // const [logoes, setLogoes] = useState([]);
  const [finalPayloadArr, setFinalPayloadArr] = useState();
  const [failedReferralArr, setFailedReferralArr] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [candidates, setCandidates] = useState(data);
  const [recruiterPartnerdata, setRecruiterParterdata] = useState([]);
  const [recruiterPartnerSkills, setRecruiterParterSkills] = useState([]);
  const [openContactUS, setOpenContactUS] = useState(false);
  const hamburger = useRef(0);
  const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const goToHomePage = () => {
    window.open("/", "_self");
  };

  const buttonFocus = (val) => {
    if (val.length === 6) {
      btnRef.current.focus();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };



  const sendOtp = async () => {
    const payLoad = {
      email: userEmail,
      isVerified: false,
    };


    try {
      const sendOtpData = await referralVerifyUser(payLoad);
      setemailidId(sendOtpData.data.id);
      setOtpSent(true);
      setIsActive(!isActive);
      NotificationManager.success("OTP sent", "Success", 2500);
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const reSendOtp = async () => {
    const payloadData = {
      id: emailidId,
      email: userEmail,
      isVerified: false,
    };
    try {
      await reSendOtpResponse(payloadData);
      setIsActive(true);
      setCounter(jsonConfig.OTPTimeLimit);
      NotificationManager.success("OTP sent", "Success", 2500);
    } catch (error) {
      console.log(error);
    }
  };

  // UUID creation
  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  const updateReferralResume = async (blob, i, referredResumeUrl) => {
    const uuid = create_UUID();
    const sas = jsonConfig.sasToken;
    decodeURI(sas);
    const bsClient = new BlobServiceClient(
      // `https://${blobStorageAccount}.blob.core.windows.net${sas}`
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClientReferral = bsClient.getContainerClient("resume");

    let blobValue = blob.name.split(".")
    let v = blobValue[blobValue.length - 1];

    let urlSplit = referredResumeUrl.split("/");

    ////delete Code
    const options = {
      deleteSnapshots: 'include' // or 'only'
    }
    let deleteBlobName = urlSplit[4] + "/" + urlSplit[5] + "/" + urlSplit[6];
    const blobClientReferralDelete = containerClientReferral.getBlockBlobClient(deleteBlobName);

    // const del =
    await blobClientReferralDelete.deleteIfExists(options);

    ///UploadCode

    let blobName = urlSplit[4] + "/" + urlSplit[5] + "/" + uuid + "." + v;

    const blobClientReferral = containerClientReferral.getBlockBlobClient(blobName);
 
    await blobClientReferral.uploadBrowserData(blob);
    let url = `${jsonConfig.ctResourcesCdn}/resume/${blobName}`;
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["resumeurl"] = url;
    originalData[i] = changingData;
    setCandidates(originalData || []);
  }
  const updatePowerResume = async (blob, i, powerResumeUrl,firstName) => {
    const sas = jsonConfig.sasToken;
    decodeURI(sas);
    const bsClient = new BlobServiceClient(
      // `https://${blobStorageAccount}.blob.core.windows.net${sas}`
      `${jsonConfig.ctResourcesCdn + sas}`
    );

    let blobValue = blob.name.split(".")
    let v = blobValue[blobValue.length - 1];
    const containerClientPowerResume = bsClient.getContainerClient("resume");
    let blobName = powerResumeUrl.split("resume/")[1];
    blobName = blobName.split(".")[0];
    blobName = blobName + "." + v;
    const blobClientPowerResume = containerClientPowerResume.getBlockBlobClient(blobName);
    // const responsePowerResume = 
    await blobClientPowerResume.uploadBrowserData(blob);
    let url = `${jsonConfig.ctResourcesCdn}/resume/${blobName}`;

    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["powerResumeUrl"] = url;
    changingData["powerResumeName"]=firstName + "_Resume";
    originalData[i] = changingData;
    setCandidates(originalData || []);

  }

  const createReferralResume = async (blob, i) => {
    const uuid = create_UUID();
    const sas = jsonConfig.sasToken;
    decodeURI(sas);
    const bsClient = new BlobServiceClient(
      // `https://${blobStorageAccount}.blob.core.windows.net${sas}`
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    var v = blob.name.split(".")[1];
    var c = uuid + "." + v;
    const containerClient = bsClient.getContainerClient("resume/referral");
    const blobName = candidates[i]?.company?.id + "/" + c;
    const url = `${jsonConfig.ctResourcesCdn}/resume/referral/${blobName}`;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    // uploaded
    // const response =
     await blobClient.uploadBrowserData(blob);
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["resumeurl"] = url;
    changingData["powerResumeUrl"] = "";
    originalData[i] = changingData;
    setCandidates(originalData || []);

  }



  const createPowerResume = async (blob, i, profileId, firstName) => {

    const sas = jsonConfig.sasToken;
    decodeURI(sas);
    const bsClient = new BlobServiceClient(
      // `https://${blobStorageAccount}.blob.core.windows.net${sas}`
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    var v = blob.name.split(".")[1];
    var c = firstName + "_Resume" + "." + v;

    const containerClient = bsClient.getContainerClient("resume");
    const blobName = profileId + "/" + c;

    const url = `${jsonConfig.ctResourcesCdn}/resume/${blobName}`;
    const blobClient = containerClient.getBlockBlobClient(blobName);
    // uploaded
    // const response = 
    await blobClient.uploadBrowserData(blob);
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["powerResumeUrl"] = url;
    changingData["powerResumeName"]=firstName + "_Resume";
    originalData[i] = changingData;
    setCandidates(originalData || []);
  }

  const uploadToBlobStorage = async (blob, i, update, referredResumeUrl, powerResumeUrl, profileId, bucket, firstName) => {
    if (update === "no") {
      await createReferralResume(blob, i);
    } else if (update === "yes") {
      if (bucket.includes("referral") && bucket.includes("profile")) {
        await updateReferralResume(blob, i, referredResumeUrl);
        if (powerResumeUrl)
          await updatePowerResume(blob, i, powerResumeUrl,firstName)
        else
          await createPowerResume(blob, i, profileId, firstName);
      } else if (bucket.includes("referral")) {
        await updateReferralResume(blob, i, referredResumeUrl);
      } else if (bucket.includes("profile")) {
        if (powerResumeUrl)
          await updatePowerResume(blob, i, powerResumeUrl,firstName);
        else
          await createPowerResume(blob, i, profileId, firstName);
        // await createReferralResume(blob, i);
      }
    }
  };

  const handleCloseDialog = () => {
    setisInviteSend(false);
    setOpen(false);
    setCounter(jsonConfig.OTPTimeLimit);
    setPutEmailPage(false);
    setPutOtp(false);
    setUserEmail("");
    setVerifyOtp("");
    setOtpSent(false);
    setIsActive(false);
    setIsEmailVerified(false);
    // handleRecaptchaOnClose();
  };

  const otpVerification = async () => {
    setisInviteSend(true);
    const payLoad = {
      id: emailidId,
      email: userEmail,
      otpHash: verifyOtp,
    };
    try {
      const verifyOtpData = await verifyOtpResponse(payLoad);
      if (verifyOtpData.data.message.includes("Invalid OTP")) {
        setisInviteSend(false);
        return NotificationManager.error(
          "Please enter valid OTP",
          "Error",
          2500
        );
      } else {
        setIsEmailVerified(true);
        setisInviteSend(true);
        setStartProgress(true);
        let finalPayload = [];
        let emails = [];
        //Blob
        let count = 0
        for (let i = 0; i < candidates.length; i++) {
          if (candidates[i].email !== "") {
            emails.push(candidates[i]?.email);
            count++;
          }
        }
        setTotalCandidate(count);
        const resp = await referredEmails({ emails: emails, otpEmail: userEmail });
        let refferedArr = []
        if (resp.status === 200) {
          refferedArr = resp.data.data;
          for (let i = 0; i < candidates.length; i++) {
            if (candidates[i].file !== "") {
              for (let j = 0; j < refferedArr.length; j++) {
                if (refferedArr[j].update === "no" || refferedArr[j].update === "yes") {
                  if (refferedArr[j].email === candidates[i].email) {
                    await uploadToBlobStorage(
                      candidates[i].file,
                      i,
                      refferedArr[j].update,
                      refferedArr[j].referredResumeUrl,
                      refferedArr[j].powerResumeUrl,
                      refferedArr[j].profileId,
                      refferedArr[j].bucket,
                      refferedArr[j].firstName);
                    if (refferedArr[j].update === "no" || refferedArr[j].update === "yes") {
                      finalPayload.push({
                        email: candidates[i]?.email,
                        skillId: candidates[i]?.skill?.id,
                        referredBy: userEmail,
                        agencyId:
                          recruiterPartnerId === undefined ||
                            recruiterPartnerId === null ||
                            recruiterPartnerId === ""
                            ? candidates[i]?.company?.agencyId
                            : recruiterPartnerdata[0]?.agencyId,
                        resumeurl: refferedArr[j].update === "no" || (refferedArr[j].update === "yes" && refferedArr[j].bucket.includes("referral")) ? candidates[i]?.resumeurl : "",
                        countryCode: candidates[i]?.countryCode,
                        phoneNumber: candidates[i]?.phoneNumber,
                        country: JSON.stringify(candidates[i]?.location),
                        orgReferralId:
                          recruiterPartnerId === undefined ||
                            recruiterPartnerId === null ||
                            recruiterPartnerId === ""
                            ? candidates[i]?.company?.id
                            : recruiterPartnerdata[0]?.id,
                        createdBy: "new",
                        updatedBy: "new",
                        update: refferedArr[j].update === "yes" ? true : false,
                        powerResumeUrl: refferedArr[j].bucket.includes("profile") ? candidates[i]?.powerResumeUrl : "",
                        powerResumeName: refferedArr[j].bucket.includes("profile") ? candidates[i]?.powerResumeName : "",
                        referralId: refferedArr[j].referralId,
                        profileId: refferedArr[j].profileId
                      });
                    }
                    break;
                  }
                }
              }

            }
          }
        }
        setFinalPayloadArr(finalPayload);
        // const response =
        await sendReferrals(finalPayload);
        const failed = refferedArr.filter(
          (item, i) =>
            item.update === "yes" ||
            item.update === "not required"
        );
        setFailedReferralArr(failed);
        handleCloseDialog();
        setShowAlert(true);
        setUserEmail("");
        setVerifyOtp("");
        setisInviteSend(false);
        setIsActive(false);
        setIsEmailVerified(false);
        setOtpSent(false);
        setCounter(jsonConfig.OTPTimeLimit);
        setStartProgress(false);
        for (let i = 0; i < data.length; i++) {
          data[i].fileType = "";
          data[i].file = "";
          data[i].resumeurl = "";
          data[i].skill = "";
          data[i].company = "";
          data[i].location = "";
          data[i].email = "";
          data[i].intlPhoneNumber = "";
          data[i].phoneNumber = "";
          data[i].countryCode = "";
        }
        setCandidates(data);
        return NotificationManager.success("OTP verified", "Success", 2500);
      }
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      (seconds === 0 ? "00" : seconds)
    );
  };

  const stepOneCreateAccount = async () => {
    // This is used to send the OTP to mail
    // sendOtp();
  };

  const getAllReferralPartnersSkills = async () => {
    let arr = [];
    const response = await getAllReferralPartnerSkill();

    for (let i = 0; i < response?.data?.data.length; i++) {
      if (response?.data?.data[i]?.isActive === true) {
        arr.push({
          referralProgramId: response?.data?.data[i]?.id,
          recruitmentOrgId: response?.data?.data[i]?.recruitmentpartner?.id,
          url:
            response?.data?.data[i]?.recruitmentpartner
              ?.recruitmentpartnerLogoUrl,
        });
      }
    }
    setAllRecruitmentOrgLogo(arr);
  };

  const showHighlightings = async (start, counter) => {
    const originalData = [...candidates];
    for (let j = start === null ? 0 : start; j <= counter; j++) {
      const changingData = originalData[j];
      candidates[j].file === ""
        ? (changingData["fileValidation"] = "select a file")
        : (changingData["fileValidation"] = "");

      candidates[j].skill === ""
        ? (changingData["skillValidation"] = "select a skill")
        : (changingData["skillValidation"] = "");

      if (
        recruiterPartnerId === null ||
        recruiterPartnerId === undefined ||
        recruiterPartnerId.length === 0
      ) {
        candidates[j].company === "" || candidates[j].company === null
          ? (changingData["companyValidation"] = "select a company")
          : (changingData["companyValidation"] = "");
      }
      candidates[j].location === "" || candidates[j].location === null
        ? (changingData["locationValidation"] = "select a location")
        : (changingData["locationValidation"] = "");
      if (candidates[j].email.length === 0) {
        changingData["validEmail"] = false;
      }
      if (
        candidates[j].intlPhoneNumber === "" ||
        candidates[j].intlPhoneNumber === undefined
      ) {
        changingData["validNumber"] = "Enter a valid phone number";
      }
      originalData[j] = changingData;
    }
    setCandidates(originalData || []);
  };

  // const handleOnClick = (e) => {
  //   e.preventDefault();
  //   // setLoading(true);
  // };

  const handleSendInviteCandidate = async (e) => {
    e.preventDefault();
    let arr = candidates;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].company === null) {
        arr[i]["company"] = "";
        arr[i]["location"] = "";
      }
    }
    setCandidates(arr);
    setFinalPayloadArr();
    setFailedReferralArr();
    setShowAlert(false);
    setAlertOpen(true);
    let flag = 0;
    let isResumeEmpty = false;
    let start = null;
    let counter = 0;
    let item = 0;
    let validMail = 0;
    let validPhoneNumber = 0;
    if (
      recruiterPartnerId === null ||
      recruiterPartnerId === undefined ||
      recruiterPartnerId.length === 0
    ) {
      for (let i = 0; i < candidates.length; i++) {
        if (
          candidates[i].file !== "" ||
          candidates[i].skill !== "" ||
          candidates[i].company !== "" ||
          candidates[i].company === null ||
          candidates[i].location !== "" ||
          candidates[i].location === null ||
          candidates[i].email !== "" ||
          candidates[i].phoneNumber !== "" ||
          candidates[i].countryCode !== "" ||
          candidates[i].intlPhoneNumber !== ""
        ) {
          if (start === null) {
            start = i;
          }
          if (i >= counter) {
            counter = i;
          }
          if (
            candidates[i].skill === "" ||
            candidates[i].company === "" ||
            candidates[i].location === "" ||
            candidates[i].email === "" ||
            candidates[i].phoneNumber === "" ||
            candidates[i].countryCode === ""
          ) {
            flag = 1;
            item += 1;
          } else if (candidates[i].file === "") {
            isResumeEmpty = true;
            item += 1;
          } else if (candidates[i].validEmail === "already selected" || !candidates[i].validEmail) {
            validMail = 1;
          } else if (candidates[i].validNumber) {
            validPhoneNumber = 1
          } else {
            flag = 0;
          }
        } else {
          const originalData = [...candidates];
          const changingData = originalData[i];
          changingData["fileValidation"] = "";
          changingData["skillValidation"] = "";
          changingData["companyValidation"] = "";
          changingData["locationValidation"] = "";
          changingData["validEmail"] = "ok";
          changingData["validNumber"] = "";
          originalData[i] = changingData;
          setCandidates(originalData || []);
        }
      }
      if (
        candidates[0].file === "" &&
        candidates[0].skill === "" &&
        candidates[0].company === "" &&
        candidates[0].location === "" &&
        candidates[0].email === "" &&
        candidates[0].phoneNumber === "" &&
        candidates[0].countryCode === "" &&
        candidates[1].file === "" &&
        candidates[1].skill === "" &&
        candidates[1].company === "" &&
        candidates[1].location === "" &&
        candidates[1].email === "" &&
        candidates[1].phoneNumber === "" &&
        candidates[1].countryCode === "" &&
        candidates[2].file === "" &&
        candidates[2].skill === "" &&
        candidates[2].company === "" &&
        candidates[2].location === "" &&
        candidates[2].email === "" &&
        candidates[2].phoneNumber === "" &&
        candidates[2].countryCode === ""
      ) {
        await showHighlightings(start, counter);
        NotificationManager.error(
          "To proceed you need to fill atleast one row",
          "Error",
          4000
        );
      } else {
        await showHighlightings(start, counter);
        if (flag === 1) {
          NotificationManager.error(
            "Please fill all the necessary fields",
            "Error",
            4000
          );
        } else if (validMail === 1) {
          NotificationManager.error(
            "Please enter valid email addresses",
            "Error",
            4000
          );
        } else if (validPhoneNumber === 1) {
          NotificationManager.error(
            "Please enter valid Phone Number's",
            "Error",
            4000
          );
        } else if (isResumeEmpty) {
          NotificationManager.error(
            "Resume is mandatory for referrals",
            "Error",
            4000
          );
        } else if (item !== 0) {
          NotificationManager.error(
            "Please fill all the necessary fields",
            "Error",
            4000
          );
        } else {
          handleClickOpen();
          if (putEmailPage === false && putOtp === false) {
            setPutEmailPage(true);
            setPutOtp(false);
          } else if (putEmailPage === true && putOtp === false) {
            if (userEmail === "") {
              NotificationManager.error(
                "Please enter a valid email",
                "Error",
                2500
              );
            } else {
              setPutEmailPage(true);
              setPutOtp(true);
              stepOneCreateAccount();
            }
          }
        }
      }
    } else {
      for (let i = 0; i < candidates.length; i++) {
        if (
          candidates[i].file !== "" ||
          candidates[i].skill !== "" ||
          candidates[i].location !== "" ||
          candidates[i].location === null ||
          candidates[i].email !== "" ||
          candidates[i].phoneNumber !== "" ||
          candidates[i].countryCode !== ""||
          candidates[i].intlPhoneNumber !== ""
        ) {
          if (start === null) {
            start = i;
          }
          if (i >= counter) {
            counter = i;
          }
          if (
            candidates[i].skill === "" ||
            candidates[i].location === "" ||
            candidates[i].email === "" ||
            candidates[i].phoneNumber === "" ||
            candidates[i].countryCode === ""
          ) {
            flag = 1;
            item += 1;
          } else if (candidates[i].file === "") {
            isResumeEmpty = true;
            item += 1;
          } else if (candidates[i].validEmail === "already selected" || !candidates[i].validEmail) {
            validMail = 1;
          } else if (candidates[i].validNumber) {
            validPhoneNumber = 1
          } else {
            flag = 0;
          }
        } else {
          const originalData = [...candidates];
          const changingData = originalData[i];
          changingData["fileValidation"] = "";
          changingData["skillValidation"] = "";
          changingData["locationValidation"] = "";
          changingData["validEmail"] = "ok";
          changingData["validNumber"] = "";
          originalData[i] = changingData;
          setCandidates(originalData || []);
        }
      }
      if (
        candidates[0].file === "" &&
        candidates[0].skill === "" &&
        candidates[0].location === "" &&
        candidates[0].email === "" &&
        candidates[0].phoneNumber === "" &&
        candidates[0].countryCode === "" &&
        candidates[1].file === "" &&
        candidates[1].skill === "" &&
        candidates[1].location === "" &&
        candidates[1].email === "" &&
        candidates[1].phoneNumber === "" &&
        candidates[1].countryCode === "" &&
        candidates[2].file === "" &&
        candidates[2].skill === "" &&
        candidates[2].location === "" &&
        candidates[2].email === "" &&
        candidates[2].phoneNumber === "" &&
        candidates[2].countryCode === ""
      ) {
        await showHighlightings(start, counter);
        NotificationManager.error(
          "To proceed you need to fill atleast one row",
          "Error",
          4000
        );
      } else {
        await showHighlightings(start, counter);
        if (flag === 1) {
          NotificationManager.error(
            "Please fill all the necessary fields",
            "Error",
            4000
          );
        } else if (validMail === 1) {
          NotificationManager.error(
            "Please enter valid email addresses",
            "Error",
            4000
          );
        } else if (validPhoneNumber === 1) {
          NotificationManager.error(
            "Please enter valid Phone Number's",
            "Error",
            4000
          );
        } else if (isResumeEmpty) {
          NotificationManager.error(
            "Resume is mandatory for referrals",
            "Error",
            4000
          );
        } else if (item !== 0) {
          NotificationManager.error(
            "Please fill all the necessary fields",
            "Error",
            4000
          );
        } else {
          handleClickOpen();
          setPutEmailPage(true);
          setPutOtp(false);

        }
      }
    }
  };

  const getAllRecruitmentPartnerSkills = async () => {
    try {
      const response = await getAllReferralSkiilsByRecruiterPartners(
        recruiterPartnerId
      );
      setRecruiterParterdata(response?.data?.data);
      const data = response?.data?.data[0]?.referralskills.filter(
        (item) => item.skill.isActive === true
      );
      setRecruiterParterSkills(data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if(recruiterPartnerId)
    {
    getAllRecruitmentPartnerSkills();
    }
    else
    {
    getAllReferralPartnersSkills();
    }
  }, []);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          setCounter((counter) => counter - 1000);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId); ///componentWillUnmount here mounting phase is distroyed
  }, [isActive, counter]);

  const RecruiterPartnerLogo = () => {
    return (
      <>
        {recruiterPartnerdata?.map((item, index) => {
          return (
            <div key={index} className="logos">
              <img
                src={item?.recruitmentpartner?.recruitmentpartnerLogoUrl}
                width="105vw"
                alt="logo"
              />
            </div>
          );
        })}
      </>
    );
  };
  const openSupportModal = () => {
    setOpenContactUS(true);
  };

  const _modalClose = () => {
    setOpenContactUS(false);
  };
  const hamburgerClicked = () => {
    setHamburgerIsClicked(!hamburgerIsClicked);
  };




  return (
    <>
      {/* Headers */}
      <div className="mainDiv">
        <div>
          <div className="nav_bg_home home-specific-header">
            <div className="container w1200 nav_bg_home_section">
              <div className="nav_bg_left">
                <li
                  className="cp"
                  onClick={() => goToHomePage()}
                  style={{ listStyle: "none" }}
                >
                  <a>
                    <img
                      src={recruiter_header_logo}
                      className="nav_logo_home"
                      alt="img"
                    />
                  </a>
                </li>
              </div>
              <div className="nav_bg_right">
                <ul className="navbar_home">
                  <li className="special_hover cp" onClick={openSupportModal}>
                    <a>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="nav_bg_home_responsive">
            <div className="nav_bg_home_responsive-wrapper">
              <div className="home-top-nav">
                <li
                  className="cp headerListStyleNone"
                  onClick={() => goToHomePage()}
                >
                  <a>
                    <img
                      src={recruiter_header_logo}
                      className="nav_logo_home"
                      alt="img"
                    />
                  </a>
                </li>
                <div
                  className={
                    hamburgerIsClicked
                      ? "hamburgerIsClicked"
                      : "hamburgerIsNotClicked"
                  }
                  ref={hamburger}
                >
                  <ul className="navbar_home-responsive">
                    <li className="special_hover cp" onClick={openSupportModal}>
                      <a>Contact Us</a>
                    </li>
                  </ul>
                </div>
                <a className="icon" onClick={hamburgerClicked}>
                  <i className="fa fa-bars"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* body */}
        <div className="banner_section_referral">
          <div className="container w1200 mb_10 relativeBody">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={2} className="rightSide_border">
                <div className="referralSidebarFixed">
                  <div className="style">
                    <div className="why_refer_text">
                      <div className="smallTickStyle">
                        {/* <img src={tick_mark} alt="tickmark" className="tickStyle"/> */}
                        &#10004;
                      </div>
                      <div className="blueReferralText">
                        Please check out the skills in which we are actively
                        hiring & refer people you know if you believe they have
                        the required skills
                      </div>
                    </div>
                    <div className="why_refer_text">
                      <div className="smallTickStyle">
                        {/* <img src={tick_mark} alt="tickmark" className="tickStyle"/> */}
                        &#10004;
                      </div>
                      <div className="blueReferralText">
                        If your referred candidate is hired, you may be eligible
                        for a reward
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Collapse in={alertOpen}>
                  {finalPayloadArr === undefined &&
                    failedReferralArr === undefined ? (
                    <></>
                  ) : finalPayloadArr?.length !== 0 &&
                    failedReferralArr?.length === 0 ? (
                    <Alert
                      variant="filled"
                      severity="success"
                      className="alertStyle"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlertOpen(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <div>All referrals are submitted successfully</div>
                    </Alert>
                  ) : (
                    showAlert && (
                      <Alert
                        variant="filled"
                        severity="info"
                        className="alertStyle"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setAlertOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <div>
                          Some of the referred candidates are already in our
                          community, Please click{" "}
                          <i
                            onClick={() => setFailModal(true)}
                            className="existedUser"
                          >
                            here
                          </i>{" "}
                          to see more
                        </div>
                      </Alert>
                    )
                  )}
                </Collapse>
                <div className="container">
                  {/* logos */}
                  <div className="recruitmentPartners mt_30 mb_20">
                    {recruiterPartnerId ? (
                      <RecruiterPartnerLogo />
                    ) : (
                      allRecruitmentOrgLogo.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={item.url !== null ? "logos" : ""}
                          >
                            {item.url !== null ? (
                              <img src={item.url} width="105vw" alt="logo" />
                            ) : null}
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="underline1">
                    <div className="underline2"></div>
                  </div>
                  <div className="body_text mb_8">
                    Refer people in your network
                  </div>
                  <div className="mandatoryText">
                    {`${"(All fields are mandatory)"}`}
                  </div>
                  {/* form */}
                  <div className="container-fluid m_24">
                    <ReferralForm
                      candidates={candidates}
                      setCandidates={setCandidates}
                      recruiterPartnerId={recruiterPartnerId}
                      recruiterPartnerSkills={recruiterPartnerSkills}
                      recruiterPartnerdata={recruiterPartnerdata}
                    />
                    <div className="underline_box df mr_10">
                      <div className="underline3"></div>
                      <div className="underline2"></div>
                    </div>
                    {putEmailPage === false && putOtp === false ? (
                      <>
                        <div>
                          <div className="underline_box df">
                            <div className="button_box">
                              <button
                                type="button"
                                className="btn-invite_next btn-invite_next_blue cp btn_style"
                                onClick={handleSendInviteCandidate}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="emptyDiv"></div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>

            <GoogleReCaptchaProvider reCaptchaKey={jsonConfig.Recaptcha_Site_Key}>
              <SendmailModal startProgress={startProgress} putEmailPage={putEmailPage} open={open} putOtp={putOtp} userEmail={userEmail} setUserEmail={setUserEmail} setVerifyOtp={setVerifyOtp} buttonFocus={buttonFocus} reSendOtp={reSendOtp} millisToMinutesAndSeconds={millisToMinutesAndSeconds} otpVerification={otpVerification} verifyOtp={verifyOtp} counter={counter} btnRef={btnRef} isInviteSend={isInviteSend} handleCloseDialog={handleCloseDialog} otpSent={otpSent} isEmailVerified={isEmailVerified} sendOtp={sendOtp} setPutEmailPage={setPutEmailPage} setPutOtp={setPutOtp} />
            </GoogleReCaptchaProvider>
            <Dialog
              open={failModal}
              onClose={() => setFailModal(false)}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <div className="failedDataDiv">
                  <div>
                    <img src={tick_mark} className="tickImage" alt="img" />
                  </div>
                  <div className="successData">
                    {totalCandidate - failedReferralArr?.length}/
                    {totalCandidate} Referrals Sent!
                  </div>
                  <div className="failedList">
                    {failedReferralArr !== undefined ? (
                      failedReferralArr.map((item, i) => {
                        return (
                          <p key={i} className="failedListItem">
                            {item.email} - {item.status}
                          </p>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn-invite_next btn-invite_next_blue cp btn_style"
                      // onClick={handleSendInviteCandidate}
                      onClick={() => setFailModal(false)}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <HomeContactUsModal
              modalOpen={openContactUS}
              modalClose={_modalClose}
            />

            <NotificationContainer />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Referral;
