import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllMailforRecruiterResponse = async (data) => {
  let allResponse = {};
  try {
    const Response = await axios.post(
      jsonConfig.apiUrl + "getByCodeandCountry",
      data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = Response;

    return Promise.resolve(allResponse);
  } catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
};

export default getAllMailforRecruiterResponse;
