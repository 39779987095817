import React, { useContext,useEffect,useState } from 'react'
import './TrackCandidate.css';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { UserTypeContext } from '../../AppRoute';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import 'beautiful-react-diagrams/styles.css';
import { Cancel, Search } from '@material-ui/icons';
import SubmissionReport from '../../components/RequisitionReports/SubmissionReport';
import getTrackCandidateInfo from '../../apis/getTrackCandidateInfo/getTrackCandidateInfo';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
const useStyles = makeStyles((theme) => ({


  customDropdown: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: ' 5px 10px',
    width: '20vw',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:focus-visible fieldset': {
        border: 'none',
      },

    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
    '&.MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent', // Set the background color to transparent when focused
    },
  },

  customTextField: {
    border: '1px solid lightgrey',
    borderRadius: '5px',
    padding: '11px 5px',
    width: '20vw',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&:focus-visible fieldset': {
        border: 'none',
      },

    },
    //remove borde bottom
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
    '&.MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '0px',
      paddingLeft: '0px',
      fontSize: '1rem',
      color: 'black',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    


  },
  button: {
    margin: theme.spacing(1),
  },
  AssignRecruiterBtn: {
    color: 'white',
    marginBottom: '10px',
    padding: '10px',
    marginRight: '10px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    display: 'flex', // Added display flex
    flexDirection: 'row',
    height:'36px',
    '& .MuiButton-label': {
      display: 'flex', // Added display flex
      flexDirection: 'row',
      justifyContent: 'center'
    },
    '&:hover': {
      //make background color a little darker
      // backgroundColor: '#32c173',
      // color: 'white',
    },

  }


}
));







function TrackCandidateBody() {
  const  location  = useLocation();
  const { userType, userIdType, userRecruitmentpartnerId, userRecords } = useContext(UserTypeContext);
  const [showHistory, setShowHistory] = React.useState(false)
  const [candidateInfo, setCandidateInfo] = useState([]);
  const [candidateInfoLoading, setCandidateInfoLoading] = useState(false)
  const [startSelectedDate, setStartSelectedDate] = useState();
  const [endSelectedDate, setEndSelectedDate] = useState();
  const [email, setemail] = useState('')
  const [triggerSubmissionReportAction, setTriggerSubmissionReportAction] = useState(false)
  const history = useHistory();
  const classes = useStyles();
  const [candidateProfile, setCandidateProfile] = useState('')

  const searchCandidate = async () => {
    if(email === '') {
      NotificationManager.error('Please enter email to search candidate')
      return
    }else{
      setShowHistory(true)
      const response = await getTrackCandidateInfo({
        candidateEmail: email,
        recruitmentPartnerId: userRecruitmentpartnerId,
      });
      const userData = response?.data?.data?.userDetails[0]
      setCandidateInfo(userData)
      setCandidateInfoLoading(response?.data?.data?.userDetails?.length > 0 ? true : false)
      let url = '';
      if (userData?.powerResumeId) {
        url = 'candidate/profile/' + userData?.powerResumeId + '/' + userData.recruitmentOrgId
      }
      else {
        url = 'only-candidate-profile/' + userData?.candidateId
      }
      setCandidateProfile(url)
    }
    

  }

  const handleEmailChange = (e) => {
    setemail(e.target.value.trim());
    setShowHistory(false)
    setCandidateInfo([])
    setCandidateInfoLoading(false)
    setCandidateProfile('')
    setShowHistory(false)
  }



  const clearEmailField = () => {
    setemail('')
    setShowHistory(false)
    setCandidateInfo([])
    setCandidateInfoLoading(false)
    setCandidateProfile('')
    setShowHistory(false)
  }

  const goToMyTallent = () => {
    history.push('/')
  };
  useEffect(() => {
    setStartSelectedDate(new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000))
    setEndSelectedDate(new Date())
  }, [])
  
  return (
    <div className='trackCandidateContainer'>

      <div className='titlesofCommunity'>
        Candidate History</div>

      <div className="goBackToRecruiterDashboard" onClick={(e) => goToMyTallent(e)}>
        <ArrowBackIcon />&nbsp; Go Back to Dashboard
      </div>

{
  location?.state?.checkCandidateHistory?<></>:  <div className='trackCand_UpperCard'>
  <div className='trackCand_searchFieldsContainer'>
    <div>
      <div className='trackCandidate_searchHeading'>Candidate Email:*</div>
      <TextField
        placeholder="Candidate Email"
        className={`${classes.customTextField} `}
        variant='standard'
        fullWidth
        value={email}
        onChange={(e) => handleEmailChange(e)}
        //add cross 
        InputProps={{
          endAdornment: (
           <>
            {
              email.length > 0 ?
                <Cancel
                  style={{ cursor: 'pointer' }}
                  onClick={clearEmailField}
                />
                : ''
            }
           </>
          ),
        }}
      />
    </div>
    {candidateInfoLoading ? 
    <div
    style={{display:'flex',alignItems:'end'}}
    >
    <div className='trackCandidate_searchHeading'>Candidate Name: </div>
    <div className='trackCandidate_searchHeading' style={{marginLeft:'10px'}}>  {candidateInfo?.name}</div>

    </div>
     :<></>}
    

  </div>

  <div className='trackCand_SearchBtn'>

    <Button
      variant="contained"
      startIcon={<Search />}
      className={classes.AssignRecruiterBtn}
      style={{ backgroundColor: '#11BB55' }}
      onClick={searchCandidate}
    >
      Search Candidate
    </Button>



   {
    console.log('candidateInfo',candidateInfo)
   }
    <Button
      variant="contained"
      startIcon={<VisibilityIcon />}
      className={classes.AssignRecruiterBtn}
      onClick={() =>  window.open(candidateProfile, '_blank', 'noreferrer noopener')}
      color='primary'
      disabled={candidateInfo?.length === 0 || candidateInfo===undefined}
    >
      View Candidate Profile
    </Button>

  </div>

</div>
}
     

{/* ---------- Make sure to remove the hardcoded startdate and do the necessary changes from the backend api --------*/}
     {
      showHistory ?
      <SubmissionReport  userType= {userType} userIdType ={userIdType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords ={userRecords}
      triggerAction={triggerSubmissionReportAction} initiator={"candidateHistory"}  resetTrigger={() => setTriggerSubmissionReportAction(false)} startSelectedDate={'2024-01-01T12:21:53.000Z'} endSelectedDate={endSelectedDate} trackCandidateSearch={true} trackCandidateSearchEmail={email} />
      :''
     }

     {
      location?.state?.checkCandidateHistory?
      <SubmissionReport  userType= {userType} userIdType ={userIdType} userRecruitmentpartnerId={userRecruitmentpartnerId} userRecords ={userRecords}
      triggerAction={triggerSubmissionReportAction} initiator={"candidateHistory"} resetTrigger={() => setTriggerSubmissionReportAction(false)} startSelectedDate={'2024-01-01T12:21:53.000Z'} endSelectedDate={endSelectedDate} trackCandidateSearch={true} trackCandidateSearchEmail={location?.state?.candidateEmail} />
      :''
     }
    {
      showHistory || location?.state?.checkCandidateHistory ? <></> :
      <div style={{height:'43vh'}}>
      </div>
    }
    </div>
  )
}

export default TrackCandidateBody


