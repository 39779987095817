import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import './deleteActivity.css'; // Import the CSS file

const DeleteActivity = ({  onConfirm, onCancel }) => {
   
    return (
        <Dialog
            open={true}
            onClose={onCancel}
            aria-labelledby="delete-dialog-title"
            className='deleteActivityDialog'
        >
            <div  className="deleteActivitydialogTitle">Confirm Deletion</div>
            <DialogContent className="deleteActivitydialogContent">
                <p>Are you sure you want to delete this activity?</p>
            </DialogContent>
            <DialogActions className="deleteActivitydialogActions">
                <Button onClick={onCancel} className="deleteActivityButtonCancel">
                    Cancel
                </Button>
                <Button onClick={onConfirm} className="deleteActivityButtonConfirm" variant="contained">
                    Confirm Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteActivity;
