import axios from "axios";
const jsonConfig = require("../../Config.json");

export const getAllAgency = async () => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "agency", {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAgencyType = async () => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "agencyType", {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAgencyById = async (id) => {
  try {
    const getAllResponse = await axios.get(jsonConfig.apiUrl + "agency/" + id, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAgencyById = async (id, data) => {
  try {
    const getAllResponse = await axios.put(
      jsonConfig.apiUrl + "agency/" + id,
      data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createNewAgency = async (data) => {
  try {
    const getAllResponse = await axios.post(
      jsonConfig.apiUrl + "agency/",
      data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
