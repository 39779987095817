import axios from "axios";
const jsonConfig = require("../Config.json");

const postNewRecruitmentPartnersAndClients = async (clientData) => {
  try {
    const getAllResponse = await axios.post(jsonConfig.apiUrl + "organizations", clientData, {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
     });
     return Promise.resolve(getAllResponse)
    
  } catch (error) {
        return Promise.reject(error)
     }

}
export default postNewRecruitmentPartnersAndClients;