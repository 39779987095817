import axios from "axios";
const jsonConfig = require("../../Config.json");

const getCandidateStatusByStatusCode = async (page,size,payload) => {
    try {
        const resp = await axios.post(jsonConfig.apiUrl + `getCandidateActivity?page=${page}&size=${size}`, payload, {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
        });
        return Promise.resolve(resp);
    }
    catch(err) {
        return Promise.reject(err);
    }
}

export default getCandidateStatusByStatusCode;