import React, { useEffect, useState, useContext } from "react";
import "./requisitionDashboard.css";
import Header from "../../components/headerAdmin/Header";
import Footer from "../../components/adminFooter/Footer";
import { useHistory } from "react-router";

import RequisitionListCard from "../../components/RequisitionsList/RequisitionListCard";
import RequisitionDashboardCount from "../../components/RequisitionDashboardCount/RequisitionDashboardCount";
import getAllRequisitionStatus from "../../apis/getallRequisitionStatus/getAllRequisitionStatus";
import ActionCard_RequisitionDashboard from "../../components/ActionCard_RequisitionDashboard/ActionCard_RequisitionDashboard";
import { ArrowBack } from "@material-ui/icons";

const RequisitionDashboard = (props) => {

  const userType = props?.location?.state?.userType ? props?.location?.state?.userType : props?.userType
  const userRecruitmentpartnerId = props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props?.userRecruitmentpartnerId;
  const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : props?.id
  const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props?.userRecords;
  const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props?.userType

  const [requisitionStatus, setRequisitionStatus] = useState([]);
  const [status, setStatus] = useState("");
  const [selectedFilter, setSelectedFilter] = useState({
    covered: "",
    priority: "",
    latestDate: true
  });
  const history = useHistory();
  const statusChange = (newValue) => {
    setStatus(newValue);
  };

  const filterChange = (newValue, val) => {
    setSelectedFilter(prev => ({
      ...prev,
      [newValue]: val // Toggle the value of the clicked filter
    }));
  };

  const goToDahboard = () => {
    history.push({
      pathname: "/dashboard",
      state: {
        userType: userType,
        recruitmentPartnerId: userRecruitmentpartnerId,
        id: idContextTest,
        userRecords: userRecords,
        roleName: userRole
      }
    });
  }


  useEffect(() => {
    let isMounted = true;

    const getReqStatus = async () => {
      try {
        const res = await getAllRequisitionStatus();
        if (isMounted) {
          setRequisitionStatus(res.data.data);
        }
      } catch (error) {
        console.log("Error", error);
      }
    };

    getReqStatus();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="maincontainer_mis_operator_dashboard">
      <div className="topcontainer_mis_operator_dashboard">
        <Header />
        {
          userType !== 'Candidate' ?
            <div className="maindiv_mis_operator_dashboard">
              
                <div className="goBackToRecruiterDashboard" onClick={(e) => goToDahboard()}>
                  <ArrowBack />&nbsp; Go Back to Dashboard
                </div>
              
              <div className="card_divisions">
                {/* first card */}
                <ActionCard_RequisitionDashboard
                  userType={userType}
                  idContextTest={idContextTest}
                  userRecruitmentpartnerId={userRecruitmentpartnerId}
                  userRole={userRole}
                  userRecords={userRecords}
                />
                {/* second card */}
                <RequisitionListCard
                  status={status}
                  statusChange={statusChange}
                  selectedFilter={selectedFilter}
                  filterChange={filterChange}
                  requisitionStatus={requisitionStatus}
                  userType={userType}
                  idContextTest={idContextTest}
                  userRecruitmentpartnerId={userRecruitmentpartnerId}
                  userRole={userRole}
                />
                {/* third card */}
                <RequisitionDashboardCount
                  status={status}
                  statusChange={statusChange}
                  selectedFilter={selectedFilter}
                  filterChange={filterChange}
                  requisitionStatus={requisitionStatus}
                  userType={userType}
                  idContextTest={idContextTest}
                  userRecruitmentpartnerId={userRecruitmentpartnerId}
                  userRole={userRole}
                />
              </div>
            </div>

            : (
              <>
                <div className="row padding_80 text-center" style={{
                  marginTop: "100px"
                }}>
                  <p className="fs-semi-bold fs-40 f-color">
                    You Are Not Authorized To Visit This Page
                  </p>
                </div>
              </>
            )
        }
      </div>
      <div className="bottom_container_mis_operator_dashboard">
        <Footer />
      </div>
    </div>
  )
}

export default RequisitionDashboard