import React, { useRef, useCallback, useEffect, useState } from "react";
import pdfimage from "../../components/basicInfoForm/images/pdfNew.svg";
import docimage from "../../components/basicInfoForm/images/doc.png";
import { makeStyles } from "@material-ui/core/styles";
import uploadBtn from "../../components/basicInfoForm/images/upload.svg";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { FilePicker } from "react-file-picker";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import getAllReferralpartnersbyskillid from "../../apis/referral/getAllReferralpartnersbyskillid";
import getallreferralskillResponse from "../../apis/referral/getallreferralskill";
import referrallocationbyreferralpartnersid from "../../apis/referral/referrallocationbyreferralpartnersid";
import getAllReferralPartnerSkill from "../../apis/referral/getAllReferralPartnerSkill";

import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { OutlinedInput } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  inputTagPadding : {
    paddingLeft: "0px",
  },
}));

function ReferralForm({
  candidates,
  setCandidates,
  recruiterPartnerId,
  recruiterPartnerSkills,
  recruiterPartnerdata,
}) {
  const jsonConfig = require("../../Config.json");
  const classes = useStyles();
  const [isResumeDataAvailable, setIsResumeDataAvailable] = useState(false);

  const [render, setRender] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [skills, setSkills] = useState([]);
  const [skillId, setSkillId] = useState("");
  const [selectSkill, setSelectSkill] = useState({});
  const [organization, setOrganization] = useState([]);
  const [organizationId, setOrganizationId] = useState("");

  const [selectOrganization, setSelectOrganization] = useState({});
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fileName, setFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const keypressHandler = (e) => {
    var invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const closeAll = () => {
    setIsResumeDataAvailable(false);
    handleClose();
  };

  const allReferralByPartnerId = () => {
    try {
    } catch (error) {
      console.log("error", error);
    }
  };

  const referralSkills = useCallback(async () => {
    try {
      if (recruiterPartnerId) {
        const result = recruiterPartnerSkills?.map((m) => m.skill);

        setSkills(result);
      } else {
        const response = await getAllReferralPartnerSkill();

        const arr = response?.data?.data.filter(
          (item) => item.isActive === true
        );
        let filteredSkills = [];
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr[i].referralskills.length; j++) {
            if (arr[i].referralskills[j].skill?.isActive === true) {
              filteredSkills.push(arr[i].referralskills[j].skill);
            }
          }
        }

        const ids = filteredSkills.map((o) => o.id);
        const filtered = filteredSkills.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );

        setSkills(filtered);
      }
    } catch (error) {
      console.log(error);
    }
  }, [recruiterPartnerId, recruiterPartnerSkills]);
  const referralpartnersBySkillId = async () => {
    try {
      const response = await getAllReferralpartnersbyskillid(skillId);
      if (!recruiterPartnerId) {
        const data = response?.data?.data.filter(
          (item) => item.isActive === true
        );
        setOrganization(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllLocationByReferralPartnerId = async () => {
    try {
      const payload = {
        referralpartnerId: organizationId,
        skillId: skillId,
      };
      const response = await referrallocationbyreferralpartnersid(payload);

      let _locations = [];
      if (response.data.data) {
        _locations = response.data.data.map((loc) => JSON.parse(loc.location));
      }
      setLocations(_locations);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLocationsByReferralPartnerId = async ({
    referralpartnerId: organizationId,
    skillId: skillId,
  }) => {
    const payload = {
      referralpartnerId: organizationId,
      skillId: skillId,
    };
    try {
      const response = await referrallocationbyreferralpartnersid(payload);

      let _locations = [];
      if (response.data.data) {
        _locations = response.data.data.map((loc) => JSON.parse(loc.location));
      }
      return _locations || [];
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const commonData = {
    skillId: skillId,
    referredBy: "",
    country: selectedLocation,
    agencyId: selectOrganization?.referralpartner?.agencyId,
    resumeurl: fileName,
    orgReferralId: "1bbc093c-ccd4-488b-a008-17a91249dbc7",
    createdBy: "new",

    updatedBy: "new",
  };

  const payload = [...candidates].map((c) => ({ ...c, ...commonData }));

  useEffect(() => {
    referralSkills();
  }, [referralSkills, candidates.length]);

  useEffect(() => {
    setSelectOrganization(null);
    if (!skillId || skillId === "") {
      return setOrganization([]);
    }
    if (recruiterPartnerId) {
      const companyName = recruiterPartnerdata[0].recruitmentpartner;
      return setOrganization([companyName]);
    }
    referralpartnersBySkillId();
  }, [skillId]);

  useEffect(() => {
    setSelectedLocation(null);
    if (selectOrganization) {
      if (recruiterPartnerId) {
        const temp = [];
        recruiterPartnerSkills?.forEach((m) => {
          if (m.skillId === skillId) temp.push(...m.location);
        });

        return setLocations(temp);
      }
      getAllLocationByReferralPartnerId();
    } else {
      setLocations([]);
    }
  }, [selectOrganization]);

  const fileUpload = (file, i) => {
    var fType = "";
    if (
      file.name.substring(file.name.length - 5, file.name.length) === ".docx"
    ) {
      fType = ".docx";
    } else if (
      file.name.substring(file.name.length - 4, file.name.length) === ".pdf"
    ) {
      fType = ".pdf";
    }

    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["file"] = file;
    changingData["fileType"] = fType;
    originalData[i] = changingData;
    setCandidates(originalData || []);
  };

  const removeFile = (i) => {
    closeAll();
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["fileType"] = "";
    changingData["file"] = "";
    originalData[i] = changingData;
    setCandidates(originalData || []);
  };

  const skillHandler = (val, i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["skill"] = val;
    originalData[i] = changingData;
    setCandidates(originalData || []);
  };

  const clearBothLocationAndCompany = (i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["location"] = null;
    originalData[i] = changingData;
    setCandidates(originalData || []);
  };

  const clearOrganization = (i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["company"] = null;
    originalData[i] = changingData;
    setCandidates(originalData || []);
    clearBothLocationAndCompany(i);
  };

  const clearlocation = (i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["location"] = null;
    originalData[i] = changingData;
    setCandidates(originalData || []);
    clearOrganization(i);
  };

  const clearlocationsArr = (i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["locations"] = null;
    originalData[i] = changingData;
    setCandidates(originalData || []);
    clearOrganization(i);
  };

  const handleSelectSkill = async (value, i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["skill"] = value === null ? "" : value;
    changingData["skillValidation"] = value === null ? "select a skill" : "";
    changingData["company"] = "";
    changingData["location"] = "";
    if (recruiterPartnerId) {
      const _skills = recruiterPartnerSkills.find(
        (s) => s.skillId === changingData.skill?.id
      );
      changingData.locations = _skills?.location || [];
    }
    if(!recruiterPartnerId){
      try {
        const response = await getAllReferralpartnersbyskillid(value?.id);
        if (!recruiterPartnerId) {  
          const data = response?.data?.data.filter((item) => item.isActive === true);  
          changingData.companyArray = data  
        }  
      } catch (error) {  
        console.log(error);  
      }
    }
    setSkillId(value?.id);
    setSelectSkill(value);

    if (!value) {
      clearOrganization(i);
      clearlocationsArr(i);
    }
    originalData[i] = changingData;
    setCandidates(originalData || []);
  };

  const organizationHandler = async (value, i) => {
    if (value) {
      const originalData = [...candidates];
      const changingData = originalData[i];
      changingData["company"] = value === null ? "" : value;
      changingData["location"] = "";
      changingData["companyValidation"] =
        value === null ? "select a company" : "";
      if (!recruiterPartnerId) {
        const _locations = await getLocationsByReferralPartnerId({
          referralpartnerId: changingData?.company?.id,
          skillId: changingData.skill?.id,
        });
        changingData.locations = _locations || [];
      } else {
        const _skills = recruiterPartnerSkills.find(
          (s) => s.skillId === changingData.skill?.id
        );
        changingData.locations = _skills?.location || [];
      }
      originalData[i] = changingData;
      setCandidates(originalData || []);
    }
    if (!value) {
      clearlocation(i);
      const originalData = [...candidates];
      const changingData = originalData[i];
      changingData["company"] = value === null ? "" : value;
      changingData["location"] = "";
      originalData[i] = changingData;
      setCandidates(originalData || []);
      clearlocationsArr(i);
    }
  };

  const handleLocation = (value, i) => {
    const originalData = [...candidates];
    const changingData = originalData[i];
    changingData["location"] =
      value === null ? "" : value.location ? JSON.parse(value.location) : value;
    changingData["locationValidation"] =
      value === null ? "select a location" : "";
    originalData[i] = changingData;
    setCandidates(originalData || []);

    setSelectedLocation(value ? value : null);
  };

  const emailChangeHandler = async (e, index) => {
    let { value, name } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    const originalData = [...candidates];
    const changingData = originalData[index];
    changingData[name] = value;
    if (value !== "") {
      let validation = value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      changingData["validEmail"] = validation;
      if (validation !== null) {
        for (let i = 0; i < candidates.length; i++) {
          if (i !== index) {
            if (candidates[i].email === value) {
              changingData["validEmail"] = "already selected";
            }
          }
        }
      }
    } else {
      changingData["validEmail"] = [];
    }
    originalData[index] = changingData;
    setCandidates(originalData || []);
  };

  const phoneChangeHandler = (val, index) => {
    const originalData = [...candidates];
    const changingData = originalData[index];
    changingData["intlPhoneNumber"] = val;
    if (val !== undefined) {
      if (val && isPossiblePhoneNumber(val)) {
        changingData["validNumber"] = "";
        changingData["countryCode"] = `+${
          parsePhoneNumber(val).countryCallingCode
        }`;
        var ret = changingData["intlPhoneNumber"].replace(
          changingData["countryCode"],
          ""
        );
        changingData["phoneNumber"] = ret;
      } else {
        changingData["validNumber"] = "Please enter a valid phone number";
      }
    } else {
      changingData["phoneNumber"] = "";
      changingData["validNumber"] = "";
      changingData["countryCode"] = "";
    }
    originalData[index] = changingData;
    setCandidates(originalData || []);
    setPhoneNumber(candidates[index].phoneNumber);
  };

  const closeExtraForms = (index) => {
    const filteredData = candidates.filter((o, i) => i !== index);
    setCandidates(filteredData);
  };

  const addMoreForm = () => {
    const candidateObj = {
      fileType: "",
      file: "",
      resumeurl: "",
      skill: "",
      company: "",
      location: "",
      email: "",
      intlPhoneNumber: "",
      phoneNumber: "",
      countryCode: "",
    };
    if (candidates.length < 10) {
      setCandidates([...candidates, candidateObj]);
    } else {
      NotificationManager.error(
        "You can refer upto 10 candidates at a time",
        "Error"
      );
    }
  };

  const formatLocationOptions = (data) => {
    return `${data?.name}`;
  };
  return (
    <div className="scrollbox" id="style-2">
      <div className="invite_candidate_form_wrapper mt_10">
        {candidates.map((candidate, i) => (
          <div key={i} className="input_rows">
            <div className="input_row_each">
              <Grid container spacing={0} className="dfGrid">
                <Grid item lg={1}>
                  <div className="invite_referral_label_wrapper upload_btn">
                    <div className="labels w100" htmlFor="fname"></div>
                    <div className="select customized-select dropdown_width_recruiter_invite w100">
                      <div>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="icon-button-file"
                          type="file"
                        />
                        <label htmlFor="icon-button-file">
                          {candidate.file ? (
                            <>
                              <div className="df mt_0 fileType_icons">
                                <Button
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                  className="docbutton"
                                >
                                  {candidate.fileType === ".pdf" ? (
                                    <img src={pdfimage} height="39px" />
                                  ) : (
                                    <img src={docimage} />
                                  )}
                                  <div className="fs-semi-bold upload_textFile">
                                    {candidate.fileType === ".pdf"
                                      ? "Resume.pdf"
                                      : "Resume.docx"}
                                  </div>
                                </Button>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  className="menus"
                                >
                                  <FilePicker
                                    extensions={["pdf", "docx"]}
                                    onChange={(file) => {
                                      handleClose();
                                      fileUpload(file, i);
                                    }}
                                    onError={(errMsg) => {
                                      NotificationManager.error(
                                        errMsg,
                                        "Error"
                                      );
                                    }}
                                  >
                                    <MenuItem
                                      className="f_menu"
                                      onClick={handleClose}
                                    >
                                      <div className="replace_remove">
                                        Replace
                                      </div>
                                    </MenuItem>
                                  </FilePicker>

                                  <MenuItem
                                    onClick={() => removeFile(i)}
                                    className="s_menu"
                                  >
                                    <div className="replace_remove">Remove</div>
                                  </MenuItem>
                                </Menu>
                              </div>
                            </>
                          ) : (
                            <>
                              <FilePicker
                                extensions={["pdf", "docx"]}
                                onChange={(file) => {
                                  handleClose();
                                  fileUpload(file, i);
                                }}
                                onError={(errMsg) => {
                                  NotificationManager.error(
                                    errMsg,
                                    "Error"
                                  );
                                }}
                              >
                                <button className="btn upload-btn-resume-testimonial cp pdFix">
                                  <img src={uploadBtn} className={candidate["fileValidation"] === "select a file" ? "dotted_border" : ""}/>
                                  <p className={candidate["fileValidation"] === "select a file" ? "upload_resume_button_text_highlighted" : "upload_resume_button_text"}>
                                    Upload Resume
                                  </p>
                                </button>
                              </FilePicker>
                            </>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={11} className="flexInputs">
                  <div className="invite_referral_label_wrapper">
                    <div className="labels w100" htmlFor="fname">
                      Skill
                    </div>
                    <div className="select customized-select dropdown_width_recruiter_invite w100">
                      <Autocomplete
                        key={render}
                        id="combo-box-demo"
                        className={
                          candidate["skillValidation"] === "select a skill"
                            ? "mt_8 selectfocus autocomplete_border_highlighted"
                            : "mt_8 selectfocus autocomplete_border"
                        }
                        options={skills || []}
                        value={candidate.skill || null}
                        autoHighlight
                        getOptionSelected={(option, value) => option.name  === value.name}
                        getOptionLabel={(option) => option?.name || ""}
                        onChange={(e, val) => {
                          handleSelectSkill(val, i);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select skill"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="invite_referral_label_wrapper">
                    <div className="labels w100" htmlFor="fname">
                      Company
                    </div>
                    <div className="select customized-select dropdown_width_recruiter_invite w100">
                      <Autocomplete
                        key={render}
                        id="combo-box-demo"
                        className={
                          candidate["companyValidation"] === "select a company"
                            ? "mt_8 selectfocus autocomplete_border_highlighted"
                            : "mt_8 selectfocus autocomplete_border"
                        }
                        disabled={recruiterPartnerId ? true : false}
                        options={
                          recruiterPartnerId
                            ? organization
                            : candidate?.skill === ""
                            ? []
                            : candidate?.companyArray
                        }
                        value={
                          recruiterPartnerId
                            ? {
                                name: recruiterPartnerdata[0]
                                  ?.recruitmentpartner?.name,
                              }
                            : candidate.company || null
                        }
                        //  value={selectOrganization}
                        autoHighlight
                        getOptionSelected={(option, value) => recruiterPartnerId ? true : (value.name === option.name)}
                        getOptionLabel={(option) =>
                          option.recruitmentpartner?.name || option.name || ""
                        }
                        onChange={(e, val) => {
                          organizationHandler(val, i);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select company"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="invite_referral_label_wrapper">
                    <div className="labels w100" htmlFor="fname">
                      Location
                    </div>
                    <div className="select customized-select dropdown_width_recruiter_invite w100">
                      <Autocomplete
                        key={render}
                        id="combo-box-demo"
                        className={
                          candidate["locationValidation"] ===
                          "select a location"
                            ? "mt_8 selectfocus autocomplete_border_highlighted"
                            : "mt_8 selectfocus autocomplete_border"
                        }
                        options={candidate?.locations || []}
                        value={
                          candidate?.location === ""
                            ? null
                            : candidate?.location || null
                        }
                        autoHighlight
                        getOptionSelected={(option, value) => (value.name === formatLocationOptions(
                          option.name
                            ? option
                            : option.location
                            ? JSON.parse(option?.location)
                            : {}
                        ))}
                        getOptionLabel={(option) => {
                          return formatLocationOptions(
                            option.name
                              ? option
                              : option.location
                              ? JSON.parse(option?.location)
                              : {}
                          );
                        }}
                        onChange={(e, val) => {
                          handleLocation(val, i);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            required={true}
                            placeholder="Select location"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="invite_referral_label_wrapper">
                    <div className="labels w100" htmlFor="fname">
                      Email
                    </div>
                    <div className="select customized-select dropdown_width_recruiter_invite w100">
                      <OutlinedInput
                        className={
                          (candidate.validEmail !== false
                            ? "text_box_cleartalent_form_referral mt_8 emailInputTextPadding"
                            : "highlightStyle mt_8 emailInputTextPadding")
                        }
                        id="email"
                        name="email"
                        value={candidate.email}
                        placeholder="Email address"
                        onChange={(e) => emailChangeHandler(e, i)}
                      />
                      {candidate.validEmail === undefined ? (
                        <></>
                      ) : candidate.validEmail === false ? (
                        <></>
                      ) : !candidate.validEmail ? (
                        <>
                          <p className="invite__error">
                            This email is not valid email
                          </p>
                        </>
                      ) : candidate.validEmail === "already selected" ? (
                        <>
                          <p className="invite__error">
                            Email is already entered, please enter a different
                            email address
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="invite_referral_label_wrapper">
                    <div className="labels w100 phoneLabel" htmlFor="fname">
                      <p>Phone number</p>
                    </div>
                    <div className="select customized-select dropdown_width_recruiter_invite w100 numericContainer">
                      <PhoneInput
                        international
                        defaultCountry={candidate?.location?.isoCode}
                        className={
                          candidate["validNumber"] !==
                          "Enter a valid phone number"
                            ? "text_box_cleartalent_form_referral mt_8"
                            : "highlightStyle mt_8"
                        }
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Phone number"
                        value={candidate.intlPhoneNumber}
                        onChange={(val) => phoneChangeHandler(val, i)}
                        onKeyPress={(e) => keypressHandler(e)}
                      />
                      {candidate["validNumber"] ===
                      "Enter a valid phone number" ? (
                        <></>
                      ) : (
                        <span className="validationText">
                          {candidate.validNumber}
                        </span>
                      )}
                      <div className="closeIcon cp">
                        {i !== 0 && i !== 1 && i !== 2 && (
                          <CancelRoundedIcon
                            onClick={() => {
                              closeExtraForms(i);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        ))}

        <div className="invite_candidate_add_refer_icon_wrap cp">
          <>
            <div className="df add_btn_style">
              <div className="add_btn_text">Refer more people</div>
              <div className="add_btn">
                <AddCircleIcon
                  color="primary"
                  style={{ fontSize: 50 }}
                  onClick={addMoreForm}
                />
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default ReferralForm;
