import axios from "axios";
const jsonConfig = require("../../Config.json");

const getDynamicFieldsByStatus = async (candidateStatusCode, placementType) => {
    let allResponse = {}
    try {
        const getallresponse = await axios.post(
            jsonConfig.apiUrl + "getCandidateRequisitionsStatus", { candidateStatusCode, placementType },
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = getallresponse;
        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("Error", error);
        return Promise.reject(error)
    }
}


export default getDynamicFieldsByStatus;