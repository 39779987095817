import React, { useState } from "react";
const validateEmail = (email) => {
    const regX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regX.test(email)) {
      return true;
    } else {
      return false;
    }
  };
export default validateEmail;