import axios from "axios";
const jsonConfig = require("../../Config.json");

const searchRequisition = async (offset, limit, payload) => {
    try {
        const response = await axios.post(
            `${jsonConfig.apiUrl}searchRequisition?offset=${offset}&limit=${limit}`, payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                }
            }
        );
        return Promise.resolve(response);
    }
    catch (error) {
        return Promise.reject(error);
    }
}

export default searchRequisition