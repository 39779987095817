import axios from "axios";
const jsonConfig = require("../../Config.json");

const getSkillsByPopularityResponse = async () => {
    let allResponse = {}
    try {
        const ActiveSkillsData = await axios.get(
            jsonConfig.apiUrl + "skillByPopularity", {
            headers: {
                "x-functions-key": jsonConfig.key,
            },
        }
        );
        allResponse = ActiveSkillsData.data.data;
        return Promise.resolve(allResponse)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default getSkillsByPopularityResponse;