import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
} from "@material-ui/core";
import Modal from "../../components/modal";
import React, { useEffect, useState, useContext } from "react";
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import Header from "../../components/adminHeader/adminHeader";
import AddClientForm from "../../forms/addClientForm";
import AddClientAdminTable from "../../tables/AddClientAdminTable";
import "./RecruitmentPartnersAndClients.css";
import RecruitmentPartners from "./RecruitmentPartnersKendo";
import Customer from "./CustomerKendo";
import getCustomerTypes from "../../apis/getCustomerType";
import Agency from "./AgencyKendo";
import { UserTypeContext } from "../../AppRoute";
const RecruitmentPartnersAndClients = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const [clientData, setClientData] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [editClientData, setEditClientData] = useState(null);
  const [tabName, setTabName] = useState("Recruitment Partner");


  const GetClients = async () => {

    try {
      const res = await getAllRecruitmentPartnersAndClients();

      setClientData(res.data.data);
    } catch (e) {
      console.log(e);
    }

  };
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await GetClients();
        await GetCusType();
      } catch (error){
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }
    fetchAllData();
    }, []);

  const GetCusType = async () => {

    try {
      const res = await getCustomerTypes();

      setCustomerData(res);
    } catch (e) {
      console.log(e);
    }

  };




  const tabClicked = (tabValue) => {
    setTabName(tabValue);
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "none",
    padding: "7px 23px 7px 23px",
    float: "right",
    left: "6px",
    marginTop: "20px",
    marginBottom: "20px",
  };

  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <Container component={Paper} className="allignmentFix">
                    <Grid container>
                      <Grid item xs={6}>
                        <h2 className="fs-semi-bold fs-30 f-color">
                          {" "}
                          Recruitment Organizations, Customers & Agencies
                        </h2>
                      </Grid>
                      <Grid item xs={6}></Grid>
                      <div className="tab_header">
                        <div className="df">
                          <div
                            className={`tab_button ${tabName === "Recruitment Partner"
                              ? "tab_button_active"
                              : ""
                              }`}
                            onClick={() => tabClicked("Recruitment Partner")}
                          >
                            Recruitment Organizations
                          </div>
                          <div
                            className={`tab_button ${tabName === "Customer" ? "tab_button_active" : ""
                              }`}
                            onClick={() => tabClicked("Customer")}
                          >
                            Customers
                          </div>
                          <div
                            className={`tab_button ${tabName === "Agencies" ? "tab_button_active" : ""
                              }`}
                            onClick={() => tabClicked("Agencies")}
                          >
                            Agencies
                          </div>
                        </div>
                      </div>
                      {tabName === "Recruitment Partner" ? (
                        <RecruitmentPartners  isDataLoaded={isDataLoaded}/>
                      ) : tabName === "Customer" ? (
                        <Customer  isDataLoaded={isDataLoaded}/>
                      ) : (
                        <Agency  isDataLoaded={isDataLoaded}/>
                      )}
                    </Grid>
                  </Container>
                ) : userType === "" ? (
                  <>
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        Loading...
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruitmentPartnersAndClients;
