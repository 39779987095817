import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  Grid as GridDiv,
  GridColumn,
  GridToolbar,
  GridNoRecords
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import getCustomerTypes from "../../apis/getCustomerType";
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import { Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import DropdownBox from "../../components/formElements/dropdownBox";
import axios from "axios";
import { DateTime } from "luxon";
import { Loader } from '@progress/kendo-react-indicators';
//////////////////////////////////////////////////

// import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
// import DialogTitle from "@material-ui/core/DialogTitle";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { FilePicker } from "react-file-picker";
import { withStyles } from "@material-ui/core/styles";
import cross from "../../components/profile/profileCompletion/images/cross.svg";
import { BlobServiceClient } from "@azure/storage-blob";
/////////////////////////////////////////////////

import organizationLogoUpdateResponse from "../../apis/organizationLogoUpload";
import ImageCropper from "./ImageCropper/ImageCropper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  buttonAlign: {
    display: "flex",
    alignItems: "center",
    padding: "24px 42px 24px 42px !important",
  },
  bodyForEditProfile: {
    marginTop: "70px",
  },
  changeProfiletitle: {
    padding: "16px 42px!important",
  },
  changeprofilebody: {},
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: "45px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonForProfilePic: {
    position: "absolute",
    right: "8px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleForProfilePic = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <div className="crossFormodal cp" onClick={onClose}>
          <img className="closeicon_set" src={cross} alt=""></img>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContentForprofilepic = withStyles((theme) => ({
  root: {
    marginTop: "0px!important",
  },
}))(MuiDialogContent);



// for tooltip
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#1A8FFF",
  },
  tooltip: {
    backgroundColor: "#1A8FFF",
    padding: "4px 13px",
    fontFamily: "Segoe-Semibold",
    fontSize: "13px",
  },
}));

const Customer = (isDataLoaded) => {
  const jsonConfig = require("../../Config.json");
  const [allData, setAllData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpend, setIsOpend] = useState(false);
  // const [custName, setCustName] = useState();
  // const [status, setStatus] = useState();
  // const [custType, setCustType] = useState();
  // const [customerId, setCustomerId] = useState();
  const [customerInfo, setCustomerInfo] = useState({
    custName: "",
    status: "",
    custType: "",
    customerId: "",
  })

  const [recruiterData, setRecruiterData] = useState([]);
  const [recruitmentPartnerArr, setRecruitmentPartnerArr] = useState([]);
  const [recruitOrg, setRecruitOrg] = useState([]);
  const [recPartnersArr, setRecPartnersArr] = useState([]);
  const customerTypes = ["Internal", "External"];
  const isActiveItems = ["True", "False"];

  const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
  const userCameraStreamRef = useRef(null);

  const [display, setdisplay] = useState(false);
  const [modalProfilePictureUrl, setModalProfilePictureUrl] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [profilePicture, setProfilePicture] = useState();
  const [organizationPicture, setOrganizationPicture] = useState();
  const [profilePicBlob, setprofilePicBlob] = useState("");
  const [editprofileModal, setEditprofileModal] = useState(false);
  const classes = useStyles();
  const [selectedFileName, setSelectedFileName] = useState();
  const [logoUrl, setlogoUrl] = useState();
  const [newRecID, setnewRecID] = useState("");
  const [recruiterPartnerLogo, setrecruiterPartnerLogo] = useState();
  const [editLOGOname, seteditLOGOname] = useState();
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage2, setCroppedImage2] = useState(undefined);
  const [atvBtn, setAtvBtn] = useState(false);
  const getImgFreeCrop = (croppedImage2) => {
    setCroppedImage2(croppedImage2);
  };

  //---------------------------------------
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(
    async (recruiterID, recruiterName, newCroppedImg) => {
      const logoLocalStorage = window.localStorage.getItem("File Selected");

      try {
        const croppedImage = newCroppedImg;
        var block = croppedImage.split(";");

        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        await b64toBlob(
          realData,
          contentType,
          recruiterID,
          recruiterName,
          logoLocalStorage
        );
        setdisplay(false);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  const filePickerOnChange = async (file) => {
    const selectedFileName = file.name;
    window.localStorage.setItem("File Selected", selectedFileName);
    const downloadedUrl = URL.createObjectURL(file);
    setImageToCrop(downloadedUrl);
    setProfilePictureEditModal(true);
  };

  //------------ >  ON CROP MODAL SAVE BUTTON <------

  const logoSave = () => {
    const fileName = window.localStorage.getItem("File Selected");

    setProfilePictureEditModal(false);
    setSelectedFileName(fileName);
    setModalProfilePictureUrl("");
    setImageToCrop("");
  };

  const logoRemove = () => {
    setSelectedFileName(null);
    localStorage.removeItem("File Selected");
  };

  const openProfilePictureModal = async () => {
    setProfilePictureEditModal(true);
  };

  const closeProfilePicModal = async () => {
    setdisplay(false);
    window.localStorage.removeItem("File Selected");

    if (userCameraStreamRef.current) {
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setProfilePictureEditModal(false);
  };

  const openEditProfileModal = async () => {
    setEditprofileModal(true);
  };

  const b64toBlob = async (
    b64Data,
    contentType,
    recruiterID,
    recruiterName,
    logoLocalStorage,
    sliceSize
  ) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(
      byteArrays,
      { type: contentType },
      { name: "profile.png" }
    );
    await uploadToBlobStorage(
      blob,
      recruiterID,
      recruiterName,
      logoLocalStorage
    );
  };

  //------------ Generating UUID -----------

  function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  // --------- Delete Old Blob While Uploading New Image -------------
  const deleteCurrentProfileBlob = async () => {
    const sas = jsonConfig.sasToken;
    let decodedToken = decodeURI(sas);
    const bsClient = new BlobServiceClient(
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClient = bsClient.getContainerClient("organizationlogos");

    const currentBlobURL = recruiterPartnerLogo;
    const currentBlobName = currentBlobURL
      .substring(currentBlobURL.lastIndexOf("organizationlogos/") + 18)
      .trim();
    deleteBlobIfItExists(containerClient, currentBlobName);
  };

  //---If previous blob exists then deleting it from blob -----
  const deleteBlobIfItExists = async (containerClient, selectedBlobName) => {
    const options = {
      deleteSnapshots: "include",
    };
    // Create blob client from container client
    const blockBlobClient = await containerClient.getBlockBlobClient(
      selectedBlobName
    );
    await blockBlobClient.deleteIfExists(options);
  };

  //-----------------------------------------------------------------------
  const uploadToBlobStorage = async (
    blob,
    recruiterID,
    recruiterName,
    logoLocalStorage
  ) => {
    const sas = jsonConfig.sasToken;
    const uuid = create_UUID();
    let decodedToken = decodeURI(sas);
    const bsClient = new BlobServiceClient(
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClient = bsClient.getContainerClient("organizationlogos");
    let timeStamp = new Date().getTime();
    // const blobName = userId + "/" + "orgLogo_" + timeStamp + ".png";
    const recruitmentPartnerName = recruiterName
      .replace(/ /g, "")
      .toLowerCase();
    const blobName =
      uuid +
      "_" +
      recruitmentPartnerName +
      "/" +
      "orgLogo_" +
      timeStamp +
      ".png";

    const url = `${jsonConfig.ctResourcesCdn}/organizationlogos/${blobName}`;
    setlogoUrl(url);

    const blobClient = containerClient.getBlockBlobClient(blobName);
    const fileTest = window.localStorage.getItem("File Selected");
    if (logoLocalStorage === null) {
      console.log("Logo Not Uploaded");
    } else {
      const response = await blobClient.uploadBrowserData(blob);
    }
    var dt = new Date();
    if (logoLocalStorage === null) {
      console.log("Data not INserted....");
    } else {
      var orgImagedata = {
        id: recruiterID,
        recruitmentpartnerLogoUrl: url,
        lastUpdated: dt,
      };
    }

    try {
      const payload = {
        userId: recruiterID,
        orgImagedata: orgImagedata,
      };
      const response = await organizationLogoUpdateResponse(payload);
      setOrganizationPicture(response.data.data.organizationLogoUrl);
      setProfilePictureEditModal(false);
      setSelectedFileName(null);
      getData();
    } catch (error) {
      console.error(error);
    }
  };
  //-------------------------------

  const getData = async () => {

    let data = await getCustomerTypes();
    let allDataSet = data.data.data;

    for (let i = 0; i < allDataSet.length; i++) {
      let recOrgs = allDataSet[i].recruitmentpartnercustomers;
      let recOrgsName = recOrgs
        .map((item) => item.recruitmentpartner?.name)
        .join(", ");
      allDataSet[i].recOrgsNameStr = recOrgsName;

      let date = allDataSet[i]?.createdAt;
      let convertedDate = DateTime.fromISO(date)
        .toLocal()
        .startOf("day")
        .toJSDate();
      allDataSet[i].createdTime = convertedDate;
    }

    setAllData(allDataSet);

    // Recruitment Partner api call for getting all recruitment partner name
    let recOrgRes = await getAllRecruitmentPartnersAndClients();
    let recOrgData = recOrgRes.data.data;

    setRecruiterData(recOrgData.filter((item) => item.isActive === true));

  };

  const clearData = () => {
    setCustomerInfo({
      custName: "",
      status: "",
      custType: "",
      customerId: "",
    })
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    position: "absolute",
    top: "-50px",
    right: "0",
  };

  const closeButton = {
    position: "absolute",
    right: "7px",
    top: "12px",
    color: "grey",
  };

  const btnSavegreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "1px solid #2AD156",
  };

  const getUserID = localStorage.getItem("userID");

  const handleOpen = () => {
    setIsOpen((prev) => (prev = true));
  };

  const handleClose = () => {
    setIsOpen((prev) => (prev = false));
  };

  const handleClosed = () => {
    setIsOpend((prev) => (prev = false));
  };

  // Handle Edit Action
  const handelEditClick = (e, value) => {
    setIsOpend(true);
    if(value.isActive === true){
      setCustomerInfo({...customerInfo, status: "true" , custName:value.name , custType : value.customerType , customerId: value.id })
    }else{
      setCustomerInfo({...customerInfo, status: "false" , custName:value.name , custType : value.customerType , customerId: value.id})
    }
    const recruit = value?.recruitmentpartnercustomers;
    setRecruitOrg(recruit);

    const recruitArr = [];

    for (let i = 0; i < recruit.length; i++) {
      recruitArr.push(recruit[i]?.recruitmentpartner?.id);
    }

    hanRecruitmentOrgsName(recruitArr);
  };

  const handleisActive = (e, value) => {
    if(value === "True"){
      setCustomerInfo({...customerInfo, status: "true" })
    }else if (value === null){
      setCustomerInfo({...customerInfo, status: "undefined" })
    }else{
      setCustomerInfo({...customerInfo, status: "false" })
    }
  };

  const handleName = (e) => {
    setCustomerInfo({...customerInfo, custName:e.target.value})
  };

  const handleCustomerType = (e, value) => {
    setCustomerInfo({...customerInfo, custType: value})
  };

  const handleRecruiterName = (e, value) => {
    let recruitmentPartnerId = value.map((item) => item.id);
    setRecruitmentPartnerArr(recruitmentPartnerId);
  };

  const getValue = (selected) => {
    const values = [];
    selected = selected.map((item, i) => item?.recruitmentpartner);
    for (let i = 0; i < recruiterData.length; i++) {
      for (let j = 0; j < selected.length; j++) {
        if (recruiterData[i].name === selected[j]?.name) {
          values.push(recruiterData[i]);
        }
      }
    }

    return values;
  };

  // This is for Edit Recruitment Organization
  const handleRecruitmentPartnersClick = (e, value) => {
    let cud = value.map((item) => item.id);
    setRecPartnersArr(cud);
  };

  // This is for Edit Recruitment Organization
  const hanRecruitmentOrgsName = (value) => {
    setRecPartnersArr(value);
  };

  useEffect(() => {

    getData();

  }, []);
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });



  const editCustomer = async () => {
    setAtvBtn(true)
    var custData = {
      name: customerInfo.custName,
      code: customerInfo.custName,
      customerType: customerInfo.custType,
      recruitmentpartnerId: recPartnersArr,
      isActive: customerInfo.status,
      updatedBy: getUserID,
    };
    let Id = customerInfo.customerId;

    if (
      custData.name === undefined ||
      custData.name === "" ||
      custData.customerType === undefined ||
      custData.customerType === "" ||
      custData.isActive === undefined ||
      custData.recruitmentpartnerId.length < 1
    ) {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please fill all the mandatory fields.",
        "Error",
        2500
      );
    } else {
      try {
        const custResponse = await axios.put(
          jsonConfig.apiUrl + "customers/" + Id,
          custData,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        getData();


        if (
          custResponse.data.message ===
          "This Customer name already exists Please use a different Customer name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Customer name already exists. Please use a different Customer name.",
            "Error",
            2500
          );
        } else {
          NotificationManager.success(
            "Customer updated successfully.",
            "Success",
            2500
          );
          handleClosed();
          clearData();
          setAtvBtn(false)
        }
      } catch (error) {
        console.log(error);
        setAtvBtn(false)
      }
    }
  };

  const createCustomer = async () => {
    setAtvBtn(true)
    var custData = {
      name: customerInfo.custName,
      code: customerInfo.custName,
      recruitmentpartnerId: recruitmentPartnerArr,
      customerType: customerInfo.custType,
      isActive: customerInfo.status,
      createdBy: getUserID,
    };

    if (
      custData.name === undefined ||
      custData.name === "" ||
      custData.customerType === undefined ||
      custData.isActive === undefined
    ) {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please fill all the mandatory fields.",
        "Error",
        2500
      );
    } else {
      try {
        const custResponse = await axios.post(
          jsonConfig.apiUrl + "customers",
          custData,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        getData();
        if (
          custResponse.data.message ===
          "This Customer name already exists Please use a different Customer name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Customer name already exists. Please use a different Customer name.",
            "Error",
            2500
          );
        } else {

          NotificationManager.success(
            "Customer added successfully.",
            "Success",
            2500
          );
          setIsOpen(false);
          clearData();
          setAtvBtn(false)
        }
      } catch (error) {
        console.log(error);
        setAtvBtn(false)
      }
    }
  };

  return (
    <>
      <div className="containerMain">
        <GridDiv
          pageable={{
            buttonCount: 10,
            info: true,
            previousNext: true,
            pageSizes: true,
            serverPaging: true,
            serverFiltering: true,
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          style={{
            height: "70vh",
            width: "100%",
            overflow: "hidden",
          }}
          data={process(allData, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >{
            !isDataLoaded && (
              <GridNoRecords>
                <div className='col-4'>
                  <br />
                  <Loader size='large' themeColor="info" type="converging-spinner" />
                </div>
              </GridNoRecords>
            )}
          <GridColumn
            title="Action"
            width="100px"
            filterable={false}
            cell={(event) => {
              return (
                <td style={{ textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      handelEditClick(event.dataItem, event.dataItem)
                    }
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                </td>
              );
            }}
          />
          <GridColumn field="id" title="ID" filterable={true} sortable={true} width="200px" />
          <GridColumn
            field="name"
            title="Name"
            filterable={true}
            sortable={true}
            width="200px"
          />
          <GridColumn
            field="recOrgsNameStr"
            title="Recruitment Organizations"
            filterable={true}
            sortable={true}
            width="230px"
          />
          <GridColumn
            field="customerType"
            title="Customer Type"
            filterable={true}
            sortable={true}
            width="200px"
          />
          <GridColumn
            field="createdTime"
            title="Created At"
            filterable={true}
            sortable={true}
            filter={"date"}
            format="{0:d},{0:t}"
            width="235px"
            cell={(e) => {
              return (
                <td>
                  {DateTime.fromISO(e.dataItem.createdAt)
                    .toFormat("MM/dd/yyyy")
                    .toString()}
                </td>
              );
            }}
          />
          <GridColumn
            title="Is Active"
            field="isActive"
            width="200px"
            filterable={true}
            filter={"boolean"}
            cell={(event) => {
              return <td>{event.dataItem?.isActive ? "True" : "False"}</td>;
            }}
          />
        </GridDiv>
        <Button
          variant="outlined"
          style={btnGreen}
          color="primary"
          onClick={handleOpen}
        >
          Add Customer
        </Button>
      </div>
      {/* ----------------- Add Cutomer -------------------- */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="creatTest_title">
          <DialogTitle
            id="customized-dialog-title"
            className="admindailog_title_modal"
            onClose={handleClose}
          >
            Add Customer
            <IconButton
              aria-label="close"
              style={closeButton}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography gutterBottom>Recruitment Organizations*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              multiple
              options={recruiterData}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Recruitment Organizations"
                />
              )}
              onChange={handleRecruiterName}
            />
          </FormControl>
          &nbsp;
          <Typography>Customer Type*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={customerTypes}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Customer Type"
                />
              )}
              onChange={handleCustomerType}
            />
          </FormControl>
          &nbsp;
          <Typography gutterBottom>Customer Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Enter Customer Name"
              className="admin_textbox"
              onChange={handleName}
            />
          </FormControl>
          &nbsp;
          <Typography>Is Active*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={isActiveItems}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Status"
                />
              )}
              onChange={handleisActive}
            />
          </FormControl>
          &nbsp;
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={handleClose}>
              Cancel
            </Button>

            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={createCustomer}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
      </Dialog>

      {/* ----------------- Edit Cutomer -------------------- */}
      <Dialog
        onClose={handleClosed}
        aria-labelledby="customized-dialog-title"
        open={isOpend}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="creatTest_title">
          <DialogTitle
            id="customized-dialog-title"
            className="admindailog_title_modal"
            onClose={handleClose}
          >
            Edit Customer
            <IconButton
              aria-label="close"
              style={closeButton}
              onClick={handleClosed}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography gutterBottom>Recruitment Organizations*</Typography>
          {recruitOrg.length !== 0 ? (
            <>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  multiple
                  options={recruiterData}
                  defaultValue={getValue(recruitOrg)}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Recruitment Organizations"
                    />
                  )}
                  onChange={handleRecruitmentPartnersClick}
                />
              </FormControl>
            </>
          ) : (
            <>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  multiple
                  options={recruiterData}
                  getOptionLabel={(option) => option.name || ""}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Recruitment Organizations"
                    />
                  )}
                  onChange={handleRecruitmentPartnersClick}
                />
              </FormControl>
            </>
          )}
          &nbsp;
          <Typography>Customer Type*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={customerTypes}
              value={customerInfo.custType || ""}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Customer Type"
                />
              )}
              onChange={handleCustomerType}
            />
          </FormControl>
          &nbsp;
          <Typography gutterBottom>Customer Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Enter Customer Name"
              className="admin_textbox"
              defaultValue={customerInfo.custName}
              onChange={handleName}
            />
          </FormControl>
          &nbsp;
          <Typography>Is Active*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={isActiveItems}
              // defaultValue={status === "true" ? "True" : "False"}
              value={customerInfo.status === null ? null : (customerInfo.status === "true" || customerInfo.status === true ? "True" : "False")}

              getOptionLabel={(option) => option || ""}
              getOptionSelected={(option, value) => option === (value === true ? "True" : "False")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Status"
                />
              )}
              onChange={handleisActive}
            />
          </FormControl>
          &nbsp;
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={handleClosed}>
              Cancel
            </Button>

            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={editCustomer}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
      </Dialog>
      <NotificationContainer />
    </>
  );
};

export default Customer;
