import React, { useState, useEffect, useContext } from "react";
import { DateTime } from "luxon";
import Header from "../components/header/header.js";
import { UserTypeContext } from "../../../AppRoute.js";
import Footer from "../../../components/adminFooter/Footer.js";
// import Footer from "../../components/adminFooter/Footer";
import ProfileCompletion from "../../../components/candidateProfile/profileCompletion/profileCompletion.js";
import NewCandidateProfile from "../../../components/candidateProfile/new/newCandidateProfile.js";
// import Skills from "../../../components/candidateProfile/skillCompletion/skills.js";
import Powerresume from "../../../components/candidateProfile/powerresumeCompletion/powerresume.js";
import "./profile.css";
// import OnlyCandidateProfile from "../../../components/candidateProfile/onlycandidateProfile/candidateprofile.js";

import profileResponse from "../../../apis/profile/profileById.js";
import otherMySkillsByProfileIdResponse from "../../../apis/profile/otherMySkillsByProfileId.js";
import pimaryMySkillByProfileIdResponse from "../../../apis/profile/primaryMySkillByProfileId.js";
import getCandidateProfileBasedOnOrganization from "../../../apis/getCandidateProfileBasedOnOrganization.js";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AdminHeader from "../../../components/adminHeader/adminHeader.js";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: '#fff',
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontFamily: '"Segoe UI Semibold", sans-serif !important',
    fontWeight: 'bold',
    fontSize: "10px",
    color: "#ffffff !important",
    textShadow: "0px 1px 2px black !important"
  },
}));

const Profile = (props) => {
  const { match } = props;
  const candidateId = props.candidateId
  const testAccess = props.testAccess
  const isShare = props.isShare
  const [recruitmentpartnerId, setrecruitmentpartnerId] = useState(props.recruitmentpartnerId)
  const { userType, userIdType } = useContext(UserTypeContext);
  const [primarySkills, setPrimarySkills] = useState("");
  const [displayname, setdisplayname] = useState("");
  const [mailData, setMailData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });
  const [profileCompletion, setProfileCompletion] = useState("");
  const [testimonialsData, setTesimonialsData] = useState("");
  const [otherSkills, setOtherSkills] = useState([]);
  const [loadingController, setLoadingController] = useState(true);
  const [activelySeeking, setactivelySeeking] = useState(false);
  const [lastUpdate, setLastUpdate] = useState("");
  const [isCandidateProfileAvailable, setIsCandidateProfileAvailable] = useState(true);
  const [candidateProfileData, setCandidateProfileData] = useState([]);
  const [candidateProfileDataLoading, setCandidateProfileDataLoading] = useState(true);
  const [emailId, setEmailId] = useState("");
  const classes = useStyles();
  const [backDropOpen, setBackDropOpen] = useState(false);

  // API call to fetch candidate data
  const getProfileData = async () => {
    try {
      const userId = candidateId;
      const response = await profileResponse(userId);
      setEmailId(response.data.data[0].user.email)
      setLoadingController(true);
      setProfileCompletion(response.data.data[0]);
      setactivelySeeking(response.data.data[0].activelySeekingOpportunity);
      setLastUpdate(response.data.data[0].updatedAt);
    } catch (err) {
      console.log(err);
    }
  };



  const getPrimarySkills = async () => {
    try {
      const id = candidateId;
      const primarySkillsData = await pimaryMySkillByProfileIdResponse(
        id
      );
      setLoadingController(true);
      setPrimarySkills(primarySkillsData.data.data[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const getOtherSkills = async () => {
    try {
      const id = candidateId;
      const otherSkillsData = await otherMySkillsByProfileIdResponse(
        id
      );
      if (otherSkillsData.data.data.length > 0) {
        setOtherSkills(otherSkillsData.data.data);
        setLoadingController(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCandidateProfileData = async () => {
    try {
      if (recruitmentpartnerId) {
        let response = await getCandidateProfileBasedOnOrganization(candidateId, recruitmentpartnerId)
        if (response.data.data) {
          setCandidateProfileData(response.data.data)
          setIsCandidateProfileAvailable(true)
        }
        else {
          setIsCandidateProfileAvailable(false)
        }
        setCandidateProfileDataLoading(false)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (candidateId) {
      getProfileData();
      getPrimarySkills();
      getOtherSkills();
      if (!isShare) {
        getCandidateProfileData();
      }

    }
  }, [candidateId]);

  const backdropChangeHandler = (val) => {
    setBackDropOpen(val);
  }


  return (
    <>
      <div className="mainContainer_candidateProfilePoweresume">
        <div className="topContainer_candidateProfilePoweresume">
        {userType=== "Admin" ? 
        <AdminHeader /> 
        : <Header
            candidateId={candidateId}
            emailId={emailId}
            poweResumeAvialable={true}
            isInvitable={recruitmentpartnerId ? true : false}
          /> }
          
          <div className={!isShare ? (!recruitmentpartnerId ? "mainDiv_candidateProfileOnlyPoweResume" : "mainDiv_candidateProfilePoweresume") : "mainDiv_candidateProfilePoweresume_shared"}>
            {!isShare && (
              <div className={!recruitmentpartnerId ? "leftDiv_candidateProfilePoweresumeDisplayNone" : "leftDiv_candidateProfilePoweresume"}>
                {candidateProfileDataLoading ? "" :
                  isCandidateProfileAvailable ? <>
                    <ProfileCompletion
                      userId={userIdType}
                      userRecruitmentPartnerId={recruitmentpartnerId}
                      userType={userType}
                      displayname={displayname}
                      setdisplayname={setdisplayname}
                      mailData={mailData}
                      setMailData={setMailData}
                      loadingController={loadingController}
                      candidateData={candidateProfileData}
                      profileCompletion={profileCompletion}
                      backdropChangeHandler={backdropChangeHandler}
                      className="camera_icon_recruiter cp"
                    />
                  </> :
                    null
                }
              </div>
            )}

            <div className={!isShare ? "rightDiv_candidateProfilePoweresume" : "rightDiv_candidateProfilePoweresume_shared"}>
              <Powerresume
                props={props}
                profileCompletion={profileCompletion}
                primarySkillsData={primarySkills}
                otherSkills={otherSkills}
                lastUpdate={lastUpdate}
                isShare={isShare}
              />
            </div>
          </div>
        </div>
        <div className="bottomContainer_candidateProfilePoweresume">
          <Footer />
        </div>

      </div>
      <Backdrop className={classes.backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" className="circularprogress_profile" />
        <Typography className={classes.text} variant="h6"> Uploading Resume</Typography>
      </Backdrop>

    </>
  )
}

export default Profile