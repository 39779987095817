import React, { useState, useEffect } from "react";
// import Modal from "react-modal";
// import VideoRecorder from "react-video-recorder";

import "./VideoIntro.css";
import Skeleton from '@material-ui/lab/Skeleton';
import cross from "./images/cross.svg"
import axios from "axios";
import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import Thumbnail from "../../../../components/profile/recordVideointro/images/videoIntro.jpg";

const useStyles = makeStyles((theme) => ({
  buttonAlign: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 55px 24px 42px !important'
  },
  bodyForEditProfile:{
    padding: "0px 24px 0px 42px!important",
    marginTop: "70px",
    position: 'relative',
  }

}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        variant="h6"
      >
        {children}
      </Typography>
      {onClose ? 
      <div className="crossFormodal cp" onClick={onClose}><img className="closeicon_set" src={cross} alt=""></img></div>
      : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    // padding:"0px 42px 0px 42px!important",
    marginTop:"0px!important"
  },
}))(MuiDialogContent);

const VideoIntro = ({profileData}) => {
  const classes = useStyles();
  const [loadingController, setLoadingController] = useState(false);
  const jsonConfig = require("../../../../Config.json");
  const [recordedVideoOpen, setRecordedVideoOpen] = useState(false);

  // custom styles
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  useEffect(() => {

    setTimeout(() => {
      setLoadingController(true);
    }, 1000)
  }, [])

    // for opening recorded video inside Modal

    const recordedVideoModal = (e) => {
      
      setRecordedVideoOpen(true);
    }
  
    // for closing recorded video inside Modal
  
    const recordedVideoClose = (e) => {
      setRecordedVideoOpen(false);
    }

  return (
    <>
      {
        loadingController ?
        <> 
        {

          profileData.videoIntroUrl ?
          <>
          <div className="card mt_10">
        <p className="fs-light fs-35 f-color">
          {/* {introVideoState.videoUrl ? "My Video Intro" : "Record My Video Intro"} */}
          My Video Introduction
        </p>
        <div className="row mt-10">
          <div className="row df skill_video_width">
  
              <div className="skill_video_sec_left skill-test-left">
                 <video
                className="preview-video-intro cp"
                src={profileData.videoIntroUrl}
                controls={false}
                onClick={recordedVideoModal}
                poster={Thumbnail}
                />
              </div>
  
            <div className="skill_video_sec_right">
            </div>
          </div>
        </div>
      </div>
          
          <Dialog 
            onClose={recordedVideoClose}
            open={recordedVideoOpen}
            className='recorded-video-container'
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
          <DialogTitle id="customized-dialog-title" onClose={recordedVideoClose}
          className='video-intro-heading'
          >
            Video Introduction
          </DialogTitle>
          <DialogContent>
          <video
            className="recorded-video-intro"
            src={profileData.videoIntroUrl}
            controls={true}
          />
          </DialogContent>
        </Dialog>
          </>
          :
          <>
            <div className="card mt_10">
              <p className="fs-light fs-35 f-color">
                My Video Introduction
              </p>
              <p className="fs-regular fs-16 f-color df mt_12">No video introduction available</p>
              <div className="row mt-10"></div>
            </div>
          </>
        }
        </>
      :
      <div className="card mt_10">
        <p className="fs-light fs-35 f-color">
          <Skeleton variant="text" animation="wave" />
        </p>
        <div className="row">
          <div>
            <Skeleton variant="rect" animation="wave" height={118}/>
          </div>
        </div>
       </div>
      }
    </>

  );
};

export default VideoIntro;