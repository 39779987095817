import axios from "axios";
const jsonConfig = require("../../Config.json");

const customLoginResponse = async (payload) => {
  let allResponse = {};
  try {
    const customLoginData = await axios.post(
      jsonConfig.apiUrl + "customlogin",
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = customLoginData;

    return Promise.resolve(allResponse);
  } catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
};

export default customLoginResponse;
