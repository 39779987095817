import axios from "axios";
const jsonConfig = require("../../Config.json");

const referredEmails = async (payload) => {
  try {
    const getAllResponse = await axios.post(jsonConfig.apiUrl + "referredEmails", payload, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default referredEmails;

