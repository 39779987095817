import React, { useState, useEffect, useContext, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {DialogContent,DialogActions} from "@material-ui/core";
import { UserTypeContext } from "../../AppRoute";
// import location from "../../components/profileCompletion/images/location.png";
import location from "../../components/profile/profileCompletion/images/location.png"
import mobile from "../../components/profile/profileCompletion/images/mobile.png"
import phoneNumberExistResponse from "../../apis/users/getUserDataByPhoneNumber";
import updateUserResponse from "../../apis/users/updateUsers";
import cross from "../../components/profile/profileCompletion/images/cross.svg"
import pencil from "../../components/profile/profileCompletion/images/pencil.png";
import { makeStyles } from '@material-ui/core/styles';
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { Skeleton } from "@material-ui/lab";
import csc from "../../utilities/country-state-city/dist"
import profileById from '../../apis/profile/profileById'
import profileResponse from "../../apis/profile/profileById";
import updateProfile from "../../apis/profile/updateProfile";
import getUsersById from "../../apis/users/getUserById";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import LinkedIn from "../../components/profile/resume/images/linkedIn.svg"
import {
    NotificationContainer,
    NotificationManager,
  } from "react-notifications";

const useStyles = makeStyles((theme) => ({
  buttonAlignForRemove: {
    padding: "32px!important",
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important",
  },
}));

const  PersonalInformation = ({ idContextTest, props,setLastUpdate,profileData }) => {

    // const [profileData, setProfileData] = useState({});
    const [locationCountry, setLocationCountry] = useState("");
    const [locationState, setLocationState] = useState("");
    const [locationCity, setLocationCity] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [openLinkedIn, setOpenLinkedIn] = useState(false);
    const [urlValue, setUrlValue] = useState("");
    const classes = useStyles();
    const [editprofileModal, setEditprofileModal] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    // const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
    const [modalProfilePictureUrl, setModalProfilePictureUrl] = useState(null);
    const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
    // const [editprofileModal, setEditprofileModal] = useState(false);
    const [loadingController, setLoadingController] = useState(false);
    
    const [userCountry, setUserCountry] = useState({});
    const [userState, setUserState] = useState({});
    const [userCity, setUserCity] = useState({});
    const [modalFirstName, setModalFirstName] = useState("");
  
    const [modalLastNames, setModalLastNames] = useState("");
    const [modalMiddleInitial, setmodalMiddleInitial] = useState("");
    const [totalExpYearSelected, setTotalExpYearSelected] = useState("");
    const [phoneNumber, setphoneNumber] = useState("");
    const [availablephnno, setavailablephnno] = useState("");
    const [email, setemail] = useState("")
    const [previousImageURL, setPreviousImageURL] = useState('')
    const [phoneNo, setPhoneNo] = useState("");
    const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    // const [phoneError, setPhoneError] = useState(false);
    const [phoneNumberEditModal, setPhoneNumberEditModal] = useState("");
    const [countryCodeEditModal, setCountryCodeEditModal] = useState("");
    const [phoneNumberChanged, setPhoneNumberChanged] = useState(false)
    const [defaultCountryCode, setDefaultCountryCode] = useState("")
    // const [countryCode, setcountryCode] = useState("")
    const [userEmail, setUserEmail] = useState("");
    const [modalCountryObject, setModalCountryObject] = useState({});
    // const [modalCountryObject, setModalCountryObject] = useState({});
    const [stateList, setStateList] = useState(Array);
    const [modalStateObject, setModalStateObject] = useState({});
    const [cityList, setCityList] = useState(Array);
    const [countryCode, setcountryCode] = useState("")
    const [modalCityObject, setModalCityObject] = useState({});
    const [display, setdisplay] = useState(false);
    const [isEmailCopied, setisEmailCopied] = useState(false)
    const userCameraStreamRef = useRef(null);
    const [isDefaultProfile, setisDefaultProfile] = useState(false)
    const jsonConfig = require("../../Config.json");
    const { userType, userIdType } = useContext(UserTypeContext);
    // const [lastUpdate, setLastUpdate] = useState("");
    const [primarySkills, setPrimarySkills] = useState("");

    useEffect(() => {
        // updateLastlogedinProfile({ userid: idContextTest })
        getProfileDatas();
        // getProfile();
        // getUserEmail();
      }, [profileData])

    const updateUrl = async () => {
        var expression =
          /^(https?:\/\/(.+?\.)?(www.)?linkedin\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/gi;
        var regex = new RegExp(expression);
        if (urlValue !== null || urlValue !== "") {
          if (!urlValue.match(regex)) {
            NotificationManager.error("Enter a correct linkedIn Url", "error");
            return;
          }
        }
        const data = {
          linkedInUrl: urlValue,
        };
        try {
          const payload = {
            id: idContextTest,
            data: data,
          }
          const updateLinkedInUrl = await updateProfile(payload);
          // await getProfile();
          setLinkedIn(urlValue);
          setUrlValue(urlValue);
          setOpenLinkedIn(false);
        } catch (error) {
          NotificationManager.error("URL updation failed", "Error");
        }
      };


      const closeLinkedModal = () => {
        setOpenLinkedIn(false);
        getProfileDatas();

      };
      const openEditProfileModal = async () => {
        await setModaldata();
    
        setEditprofileModal(true);
      };
    
      const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
          color: "#1A8FFF",
        },
        tooltip: {
          backgroundColor: "#1A8FFF",
          padding: "4px 13px",
          fontFamily: "Segoe-Semibold",
          fontSize: "13px"
    
        },
      }));
      function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();
    
        return <Tooltip arrow classes={classes} {...props} />;
      }

      
   
      const getProfileDatas = async () => {
        try {





          // const getProfile = async () => {
          //   try {
          //     const getProfileData = await profileResponse(idContextTest);
              setLinkedIn(profileData.linkedInUrl || "");
              setUrlValue(profileData.linkedInUrl || "");
              // setOpenLinkedIn(false);
          //   } catch (error) {
          //     console.log(error);
          //   }
          // };

          // const profileResponse = await profileById(idContextTest);
    
          
          // var profileDataParsed = profileResponse.data.data[0];
          // profileResponse.data?.data[0]?.profilePicUrl == null ? setPreviousImageURL('') : setPreviousImageURL(profileResponse.data?.data[0]?.profilePicUrl)
          // setModalProfilePictureUrl(profileResponse.data?.data[0]?.profilePicUrl);
          // setProfileData(profileDataParsed);
          setLastUpdate(profileData.lastUpdated);
          setphoneNumber(profileData.user.phoneNumber || "");
          setavailablephnno(profileData.user.phoneNumber || "");
          setemail(profileData.user.email);
          setUserEmail(profileData.user.email);
          setTotalExpYearSelected(profileData.totalExperienceId);
          setLocationCountry(JSON.parse(profileData.locationCountry).name);
          setLocationCity(JSON.parse(profileData.locationCity).name);
          setPhoneNoIntnl(profileData.user.countryCode + "" + profileData.user.phoneNumber)
          setcountryCode(profileData.user.countryCode)
          setDefaultCountryCode(profileData.user.countryCode ? "" : JSON.parse(profileData.locationCountry).isoCode)
          if (profileData.locationState != "") {
            setLocationState(JSON.parse(profileData.locationState).name || "");
          }
          if (profileData.locationCountry) {
            var a = JSON.parse(profileData.locationCountry);
            setStateList(csc.getStatesOfCountry(a.isoCode));
          }
          if (profileData.locationState) {
            var b = JSON.parse(profileData.locationState);
            setCityList(csc.getCitiesOfState(b.countryCode, b.isoCode));
          }
          setLoadingController(true);
      
        } catch (error) {
          // setLoadingController(true);
        }
      };







    const getProfileData = async () => {
        try {

          const profileResponse = await profileById(idContextTest);
          console.log("profileResponse", profileData)
    
          setLoadingController(true);
          var profileDataParsed = profileResponse.data.data[0];
          // profileResponse.data?.data[0]?.profilePicUrl == null ? setPreviousImageURL('') : setPreviousImageURL(profileResponse.data?.data[0]?.profilePicUrl)
          // setModalProfilePictureUrl(profileResponse.data?.data[0]?.profilePicUrl);
          // setProfileData(profileDataParsed);
          setLastUpdate(profileDataParsed.lastUpdated);
          setphoneNumber(profileDataParsed.user.phoneNumber || "");
          setavailablephnno(profileDataParsed.user.phoneNumber || "");
          setemail(profileDataParsed.user.email);
          setTotalExpYearSelected(profileDataParsed.totalExperienceId);
          setLocationCountry(JSON.parse(profileDataParsed.locationCountry).name);
          setLocationCity(JSON.parse(profileDataParsed.locationCity).name);
          setPhoneNoIntnl(profileDataParsed.user.countryCode + "" + profileDataParsed.user.phoneNumber)
          setcountryCode(profileDataParsed.user.countryCode)
          setDefaultCountryCode(profileDataParsed.user.countryCode ? "" : JSON.parse(profileDataParsed.locationCountry).isoCode)
          if (profileDataParsed.locationState != "") {
            setLocationState(JSON.parse(profileDataParsed.locationState).name || "");
          }
          if (profileDataParsed.locationCountry) {
            var a = JSON.parse(profileDataParsed.locationCountry);
            setStateList(csc.getStatesOfCountry(a.isoCode));
          }
          if (profileDataParsed.locationState) {
            var b = JSON.parse(profileDataParsed.locationState);
            setCityList(csc.getCitiesOfState(b.countryCode, b.isoCode));
          }
      
        } catch (error) {
          // setLoadingController(true);
        }
      };
      // const getUserEmail = async () => {
    
      //   try {
      //     const getUserEmailResponse = await getUsersById(idContextTest);
      //     setUserEmail(getUserEmailResponse.data.data[0].email);
      //   }
      //   catch (error) {
      //     console.log(error);
      //   }
      // }
  
      const openLinkInWinDow = (e, linkedIn) => {
        if (linkedIn) {
          window.open(linkedIn);
        }
      };
      const openLinkedInModal = () => {
        setOpenLinkedIn(true);
      };
  
  
    const phoneNumberChangeHandler = (val) => {
      setPhoneNoIntnl(val)
      setPhoneNumberChanged(true);
      if (val) {
        if (val && isPossiblePhoneNumber(val)) {
          setPhoneError(false)
          setCountryCodeEditModal(`+${parsePhoneNumber(val).countryCallingCode}`);
          let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
          setPhoneNumberEditModal(val.replace(countryCode_PhoneNumber, ""));
        }
        else {
          setPhoneError(true)
        }
      }
      else {
        setPhoneError(false)
        setCountryCodeEditModal("")
        setPhoneNumberEditModal("")
      }
    };
    const closeEditProfileModal = async () => {
      await getProfileData();
      setPhoneError(false)
      setEditprofileModal(false)
    }

    const keypressHandler = (e) => {
        var invalidChars = [
          "-",
          "+",
          "e",
        ];
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      }



      const setModaldata = async () => {
        let arrayDataYears = [];
        for (let i = 0; i <= 20; i++) {
          arrayDataYears.push(i);
        }
        let arrayDataMonths = [];
        for (let i = 0; i <= 11; i++) {
          arrayDataMonths.push(i);
        }
        //             *******dont remove it ****************
    
        setModalFirstName(profileData?.user?.firstName);
        setModalLastNames(profileData?.user?.lastName);
        setmodalMiddleInitial(profileData?.user?.MiddleInitial);
        setphoneNumber(profileData?.user?.phoneNumber || "")
    
        if (profileData.locationCountry) {
          setModalCountryObject(JSON.parse(profileData.locationCountry));
          setUserCountry(JSON.parse(profileData.locationCountry))
        }
        if (profileData.locationState) {
    
          setModalStateObject(JSON.parse(profileData.locationState));
          setUserState(JSON.parse(profileData.locationState));
        }
        if (profileData.locationCity) {
          setModalCityObject(JSON.parse(profileData.locationCity));
          setUserCity(JSON.parse(profileData.locationCity))
        }
      }
    
      const [countryList, setCountryList] = useState([{
        "currency": "USD",
        "flag": "",
        "isoCode": "US",
        "latitude": "38.00000000",
        "longitude": "-97.00000000",
        "name": "United States",
        "phonecode": "+1"
      },
      {
        "currency": "CAD",
        "flag": "",
        "isoCode": "CA",
        "latitude": "60.00000000",
        "longitude": "-95.00000000",
        "name": "Canada",
        "phonecode": "+1"
      },
      {
        "currency": "INR",
        "flag": "",
        "isoCode": "IN",
        "latitude": "20.00000000",
        "longitude": "77.00000000",
        "name": "India",
        "phonecode": "+91"
      }, {
        "currency": "MXN",
        "flag": "",
        "isoCode": "MX",
        "latitude": "23.00000000",
        "longitude": "-102.00000000",
        "name": "Mexico",
        "phonecode": "+52",
      }
      ]);
      const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.name,
      });
    
      const countryHandler = (event, value) => {
        if (value === null) {
          setUserCountry('')
        }
        setModalStateObject("");
        setModalCityObject("");
        if (value) {
          console.log("value", value)
          setUserCountry(value);
          setStateList(csc.getStatesOfCountry(value.isoCode));
          setUserState('')
          setUserCity('')
        }
      };
      const stateHandler = async (event, value) => {
        setModalCityObject("");
        if (value === null) {
          setUserState('')
        }
        else if (value) {
          setUserState(value);
          setUserCity('')
          setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode));
          setModalStateObject(value);
        }
        else {
          setUserState({})
          setUserCity({})
        }
      };
      const cityHandler = async (event, value) => {
        setUserCity({})
        if (value) {
          setUserCity(value);
          setModalCityObject(value);
        }
      };
    
      // phone no validation chk api call
      const checkForDuplicatePhoneNumber = async (phoneNumber) => {
        var phnnoinput = {
          "phoneNumber": phoneNumber
        }
        try {
          const phoneNochkResponse = await phoneNumberExistResponse(phnnoinput);
          if (phoneNochkResponse.data.data[0].isUnique === true) {
            return (
              phoneNochkResponse.data.data[0].isUnique
            )
          }
        } catch (error) {
          console.log(error);
        }
      }
    
    
    
      const updateProfileData = async () => {
        if (modalFirstName === "" || modalLastNames === "" || totalExpYearSelected === "" || userCountry === "" || userState === "") {
          NotificationManager.error("Please enter the required fields", "Error", 2500);
        }
        else if (phoneError) {
          NotificationManager.error("Please enter a valid phonr number", "Error", 2500);
        }
        else {
          let userData = {
            firstName: modalFirstName,
            lastName: modalLastNames,
            locationCountry: JSON.stringify(userCountry),
            MiddleInitial: modalMiddleInitial,
            updatedBy: idContextTest,
            isActive: true,
          };
          console.log("userData", userData)
          let isunique = true;
          if (phoneNumberChanged === true && phoneNumberEditModal != "") {
            if (phoneNumberEditModal != availablephnno) {
              isunique = await checkForDuplicatePhoneNumber(phoneNumberEditModal);
            }
            userData.phoneNumber = phoneNumberEditModal
            userData.countryCode = countryCodeEditModal
            if (isunique) {
              try {
                const payload = {
                  id: idContextTest,
                  data: userData
                }
                console.log("payload", idContextTest)
                const profileDataResponse = await updateUserResponse(payload);
              } catch (error) { console.error(error); }
    
              var v = userCountry
              delete v.timezones
              let profileDataRequest = {
                totalExperienceId: totalExpYearSelected,
                locationCountry: JSON.stringify(userCountry),
                locationState: JSON.stringify(userState),
                locationCity: JSON.stringify(userCity),
                updatedBy: idContextTest,
              };
              try {
                const payload = {
                  id: idContextTest,
                  data: profileDataRequest,
                }
                const profileDataResponse = await updateProfile(payload);
                NotificationManager.success("Profile updated", "Success");
                await getProfileData();
                setEditprofileModal(false);
              } catch (error) { console.error(error); }
            }
            else {
              NotificationManager.error("Phone Number Already Exists In Our System", "Error");
            }
          }
          else {
            let userData = {
              phoneNumber: phoneNumberChanged ? "" : phoneNumber,
              countryCode: phoneNumberChanged ? "" : countryCode,
              firstName: modalFirstName,
              lastName: modalLastNames,
              locationCountry: JSON.stringify(userCountry),
              MiddleInitial: modalMiddleInitial,
              updatedBy: idContextTest,
              isActive: true,
            };
            try {
              const payload = {
                id: idContextTest,
                data: userData,
              }
              const profileDataResponse = await updateUserResponse(payload);
            } catch (error) { console.error(error); }
            var v = userCountry
            delete v.timezones
            let profileDataRequest = {
              totalExperienceId: totalExpYearSelected,
              locationCountry: JSON.stringify(userCountry),
              locationState: JSON.stringify(userState),
              locationCity: JSON.stringify(userCity),
              updatedBy: idContextTest,
            };
            try {
              const payload = {
                id: idContextTest,
                data: profileDataRequest,
              }
              const profileDataResponse = await updateProfile(payload);
              NotificationManager.success("Profile updated", "Success");
              await getProfileData();
              setEditprofileModal(false);
            } catch (error) { console.error(error); }
          }
        }
        setPhoneNumberChanged(false)
      };


    return(
        <>








    {
        loadingController ?

          <div className="powres_card mt_10 powres_box" >

            <p className="mn_semibold fs-14 text-center" >
              Personal Information
              <span
                className="ml_12 cp edit_left pl_55"


                onClick={openEditProfileModal}
              >
                <img src={pencil} alt="" />
              </span></p>
            <div className="fs-semi-bold fs-14 f-color df_profile mt_20 ">

              <div className="icon_al">
                <BootstrapTooltip title="Phone">
                  <img className="profile_icon mr_31" src={mobile} alt="Phone" />
                </BootstrapTooltip>
              </div>
              <div>
                <p className="fs-semi-bold fs-14 f-color br-word br_word ">
                  {profileData.user ? profileData.user.phoneNumber ?
                    formatPhoneNumberIntl(profileData.user.countryCode + profileData.user.phoneNumber) ?
                      formatPhoneNumberIntl(profileData.user.countryCode + profileData.user.phoneNumber) :
                      profileData.user.phoneNumber

                    : "Phone number not available" : "Phone number not available"}

                </p>
              </div>
            </div>
            <div className="fs-semi-bold fs-14 f-color  df_profile  mt_15 ">
              <div className="icon_alig">
                <BootstrapTooltip title="Location">
                  <img className="profile_icon mr_26" src={location} alt="location" data-tip="Location" data-background-color="#1A8FFF" />
                </BootstrapTooltip>
              </div>
              <div>
                {
                  (locationCountry || locationState || locationCity) ?
                    <p className='br_word'>

                      <span>{locationCity ? locationCity + ", " : ""}</span>
                      <span>{locationState ? locationState + ", " : ""}</span>
                      <span>{locationCountry}</span>
                    </p>
                    :
                    ''
                }
              </div>
            </div>

            <div className="linkedin-wrapper mt_15 ">
              <img src={LinkedIn} alt="linkedIn" />
              {/* <p className=''> */}
              <p
                className={`f-color fs-semi-bold fs-14 email_hidden ${linkedIn ? "wordbreak ml_25 cp" : "ml_26"
                  }`}
                onClick={(e) => openLinkInWinDow(e, linkedIn)}
              >
                {linkedIn ? (linkedIn.substring(0, 30) + (linkedIn.length >=30 ? "...":"") ): "LinkedIn URL not available"}
              </p>
              <span
                className="ml_12 cp edit_left"
                onClick={openLinkedInModal}
              >
                <img src={pencil} alt="" />
              </span>
            </div>
          </div>
          :
          <>
            <div className="powres_card mt_10 powres_box">
              <div className="row">
                <Skeleton variant="text" animation="wave" />
                <div className="df mt_6">
                  <div className="w-100 ml_10">
                    <Skeleton variant="text" animation="wave" />
                  </div>
                </div>
                <Skeleton variant="text" animation="wave" />
                <div className="w-100 ml_10">
                  <Skeleton variant="text" animation="wave" />
                </div>


              </div>
            </div>
          </>

      }

<Dialog
            open={editprofileModal}
            onClose={closeEditProfileModal}
            aria-labelledby="customized-dialog-title"
            maxWidth="sm"
            fullWidth={true}
            className='record-video-intro-responsive'
          >
            <div className="crossFormodal cp" onClick={closeEditProfileModal}><img className="closeicon_set" src={cross} alt=""></img></div>
            <DialogContent className={`${classes.bodyForEditProfile} responsive-body-profilecompletion`}>
              <div className="dropdown_width_outer mt_12">
                <div className="select customized-select dropdown_width" >
                  <legend>First Name*</legend>
                  <input
                    type="text"
                    className="text_box_cleartalent w-94"
                    value={modalFirstName ? modalFirstName : ""}
                    onChange={(event) => { setModalFirstName(event.target.value) }}
                    placeholder="Enter your First Name"
                  />
                </div>
                <div className="select customized-select dropdown_width zipgap" >
                  <legend>Last Name*</legend>
                  <input
                    type="text"
                    className="text_box_cleartalent w-94"
                    value={modalLastNames ? modalLastNames : ""}
                    onChange={(event) => setModalLastNames(event.target.value)}
                    placeholder="Enter your Last Name"
                  />
                </div>
              </div>
              <div className="dropdown_width_outer mt_12">
                <div className="select customized-select dropdown_width" >
                  <legend>Middle Initial</legend>
                  <input
                    type="text"
                    className="text_box_cleartalent w-94"
                    value={modalMiddleInitial ? modalMiddleInitial : ""}
                    onChange={(event) => setmodalMiddleInitial(event.target.value)}
                    placeholder="Enter your Middle Initial"
                  />
                </div>
                <div className="select customized-select dropdown_width zipgap" >
                  <legend>Email</legend>
                  <input
                    type="text"
                    className="text_box_cleartalent w-94"
                    value={userEmail}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="dropdown_width_outer mt_12">
                <div className="select customized-select dropdown_width">
                  <legend>Country*</legend>
                  <Autocomplete
                    id="combo-box-demo"
                    options={countryList}
                    filterOptions={filterOptions}
                    defaultValue={userCountry}
                    getOptionLabel={(option) => option.name ? option.name : ""}
                    getOptionSelected={(option, value) => value === "" ? true : option.name === value.name}
                    onChange={countryHandler}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </div>
                <div className="select customized-select dropdown_width zipgap" >
                  <div className="select customized-select dropdown_width_recruiter_invite w100 numericContainer">
                    <legend>Phone Number</legend>
                    <PhoneInput
                      international
                      defaultCountry={defaultCountryCode}
                      className="textbox_phone_input"
                      placeholder="Enter phone number"
                      value={phoneNoIntnl}
                      onChange={(e) => phoneNumberChangeHandler(e)}
                      onKeyPress={(e) => keypressHandler(e)}
                    />

                  </div>
                </div>
              </div>
              <div className="dropdown_width_outer mt_12">
                <Grid container>
                  <Grid item md={6} xs={12}></Grid>
                  <Grid item md={6} xs={12}>
                    {(phoneError) ? (
                      <p className="form-error fs-regular phoneError_ml">
                        Please enter a valid phone number
                      </p>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>
              <div className="dropdown_width_outer mt_12">
                <div
                  className="select customized-select dropdown_width"
                >
                  <legend>State*</legend>
                  <Autocomplete
                    id="combo-box-State"
                    options={stateList}
                    // defaultValue={modalStateObject}
                    value={modalStateObject}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) => value === "" ? true : option.name === value.name}
                    onChange={stateHandler}
                    clearText="Clear"
                    renderInput={(params) => <TextField {...params} variant="outlined" />}

                  />
                </div>
                <div
                  className="select customized-select dropdown_width"
                >
                  <legend>City</legend>
                  <Autocomplete
                    id="combo-box-City"
                    options={cityList}
                    // defaultValue={modalCityObject}
                    filterOptions={filterOptions}
                    value={modalCityObject}
                    getOptionLabel={(option) => option.name || ""}
                    getOptionSelected={(option, value) => value === "" ? true : option.name === value.name}
                    onChange={cityHandler}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </div>
              </div>
            </DialogContent>
            <div className="divider_adjust">
              <hr className="new3 mt_20 mb_0" />
            </div>
            <DialogActions className={`${classes.buttonAlign} responsive-dialog`}>
              <button
                type="button"
                className="btn-big btn-skill cp"
                onClick={closeEditProfileModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-big btn_powres ml_10 cp"
                onClick={updateProfileData}
              >
                Save
              </button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openLinkedIn}
            onClose={closeLinkedModal}
            aria-labelledby="customized-dialog-title"
            className="linkedin-modal-dialog"
            maxWidth="sm"
          >
            <DialogContent className={`${classes.bodyForRemove} `}>
              <div className="ml_5 fs-semi-bold f-color fs-18">
                LinkedIn URL
              </div>
            </DialogContent>
            <DialogActions
              className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}
            >
              <div className="linkedin-modal-url-container">
                <div className="linkedin-modal-url-container">
                  {/* <label className="field_name email-label-form-one" htmlFor="email">Linked In URL</label> */}
                  <input
                    type="text"
                    className="text_box_cleartalent_form1 mb_10"
                    id="email"
                    name="linkedIn"
                    placeholder="Enter LinkedIn URL"
                    value={urlValue}
                    onChange={(e) => setUrlValue(e.target.value)}
                  />
                </div>
                <div className="remove_modal">
                  <button
                    type="button"
                    className="btn-big btn-skill cp"
                    onClick={closeLinkedModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-big btn_powres ml_10 cp"
                    onClick={updateUrl}
                  >
                    Save
                  </button>
                </div>
              </div>
            </DialogActions>
          </Dialog>
    
    </>
    )





}
export default PersonalInformation ;