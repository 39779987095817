import axios from "axios";
const jsonConfig = require("../../Config.json");

const candidateResumeById = async (Id, objectId) => {
  let allResponse = {};
  
  try {
    // Sending the request as query parameters
    const url = `${jsonConfig.apiUrl}candidateResume${Id || objectId ? '?' : ''}${Id ? `Id=${Id}` : ''}${Id && objectId ? '&' : ''}${objectId ? `objectId=${objectId}` : ''}`;

    const getAllResponse = await axios.get(url, {
      headers: {
        "x-functions-key": jsonConfig.key,
      },
    });

    allResponse = getAllResponse;
    return Promise.resolve(allResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

export default candidateResumeById;