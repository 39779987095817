import axios from "axios";
const jsonConfig = require("../../Config.json");

const OrganizationConfig = async(payload) => {
    var response = {};
    try
    {
        if(payload === undefined)
        {
            const allResponse = await axios.get(
                jsonConfig.apiUrl + "organizationconfig",
                {
                    headers:
                    {
                        "x-functions-key": jsonConfig.key,
                    }
                }
            );
            
            response = allResponse;
    
            return Promise.resolve(response);
        }
        else
        {
            const allResponse = await axios.post(
                jsonConfig.apiUrl + "organizationconfig", payload,
                {
                    headers:
                    {
                        "x-functions-key": jsonConfig.key,
                    }
                }
            );
            
            response = allResponse;
    
            return Promise.resolve(response);
        }
        
    }
    catch(err)
    {
        console.log("Error", err);
        return Promise.reject(err);
    }
}

export default OrganizationConfig;