import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

const DropdownBox = (props) => {
  const {
    label,
    options,
    getOptionLabel,
    placeholder,
    onChange,
    defaultValue,
  } = props;

  return (
    <>
      <Typography>{label}</Typography>
      <FormControl fullWidth>
        <Autocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
            />
          )}
          defaultValue={defaultValue || null}
          onChange={onChange}
        />
      </FormControl>
    </>
  );
};

export default DropdownBox;
