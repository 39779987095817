import axios from "axios";
const jsonConfig = require("../../Config.json");

const pendingInvitesResponse = async (rId,payload) => {
  let allResponse = {}
  try {
    const getallresponse = await axios.post(
      jsonConfig.apiUrl + "PendingInvites/" + rId,payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = getallresponse;
    return Promise.resolve(allResponse)
  }
  catch (error) {
    console.log("Error Invite Candidate", error);
    return Promise.reject(error)
  }
}

export default pendingInvitesResponse;