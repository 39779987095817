import axios from "axios";
import * as fflate from 'fflate';
const jsonConfig = require("../../Config.json");


const getAllCandidateProfileByOrgId = async (orgId, payload) => {
  try {
    const getallresponse = await axios.post(
      jsonConfig.apiUrl + "getAllCandidateProfileByOrgId/" + orgId, payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    let decompressedObject;
    if (getallresponse?.data && getallresponse?.data?.compressedbase64string) {
      const strData = atob(getallresponse.data.compressedbase64string);
      const charData = strData.split("").map((x) => {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);

      let apiDataUnCompressed = fflate.unzlibSync(binData);
      let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
      decompressedObject = JSON.parse(apiDataUnCompressedString);
    }
    return Promise.resolve(decompressedObject)

  }
  catch (error) {
    console.log("Error", error);
    return Promise.reject(error);
  }
}

export default getAllCandidateProfileByOrgId;