import axios from "axios";
const jsonConfig = require("../../Config.json");

const getRequisitionDetailsbyId = async (Id,payload) => {
 
    try {
        const getAllResponse = await axios.post(
          jsonConfig.apiUrl + "getRequisitionDetails/"+ Id,payload,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        return Promise.resolve(getAllResponse);
      } catch (error) {
        return Promise.reject(error);
      }
}

export default getRequisitionDetailsbyId;