import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateLastlogedin = async (payload) => {
    let responsedata = {}
    try {
        const UpdateLastlogedinresponse = await axios.put(
            jsonConfig.apiUrl + "UpdateLastloggedinByUserId",
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
            responsedata = UpdateLastlogedinresponse;

        return Promise.resolve(responsedata)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default updateLastlogedin;