import axios from "axios";
const jsonConfig = require("../../Config.json");

const deleteMySkills = async (id) => {
  let allResponse = {};
  try {
    const response = await axios.delete(jsonConfig.apiUrl + "mySkills/" + id,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = response;

    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default deleteMySkills;