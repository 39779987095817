import React, { useContext, useEffect, useState } from 'react'
import Header from "../../components/headerAdmin/Header"
import Footer from "../../components/adminFooter/Footer.js";
import './notification.css';
import { Button } from '@material-ui/core';
import user_register from "../../components/basicInfoForm/images/avatar.png";
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import { UserTypeContext } from "../../AppRoute";
import BasicHeader from "../../components/basicHeader/BasicHeader.js";
import getAllNotification from "../../apis/getAllNotification/getAllNotification";
import { DateTime } from "luxon";
import updateNotification from '../../apis/updateNotification/updateNotification';
import { useHistory } from "react-router";
import { ArrowBack } from '@material-ui/icons';

const Notification = () => {

    const { userType, userIdType } = useContext(UserTypeContext);
    const [allNotifications, setAllNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState([])
    const [isNotificationTab, setIsNotificationTab] = useState("newTab");
    const [newNotificationCount, setNewNotificationCount] = useState(0);
    const [allNotificationCount, setAllNotificationCount] = useState(0);
    const [allNotificationFetched, setAllNotificationFetched] = useState(false)
    const history = useHistory();

    const getAllNotificationData = async () => {
        let payload = {
            id: userIdType,
            role: userType,
            skip: 0,
            size: 5,
            isViewed: false
        }
        try {

            const response_unRead = await getAllNotification(payload)
            setNewNotification(response_unRead?.data?.data?.notifications)
            setNewNotificationCount(response_unRead?.data?.data?.totalCount)
            payload.isViewed = "all"
            const response_All = await getAllNotification(payload)
            setAllNotifications(response_All?.data?.data?.notifications)
            setAllNotificationCount(response_All?.data?.data?.totalCount)



        } catch (error) {
            console.log(error, "error");
        }
    }


    const newAPI = async (paramStr) => {
        try {
            let payload = {
                id: userIdType,
                role: userType,
                skip: newNotification.length,
                size: 5,
                isViewed: false
            }
            const response_unRead = await getAllNotification(payload)
            setNewNotificationCount(response_unRead?.data?.data?.totalCount)
            setNewNotification((prev) => [...prev, ...response_unRead.data.data.notifications]);
        } catch (error) {
            console.log(error, "error");
        }
    }
    const allAPI = async (paramStr) => {
        try {
            let payload = {
                id: userIdType,
                role: userType,
                skip: allNotifications.length,
                size: 5,
                isViewed: "all"
            }
            const response_Read = await getAllNotification(payload)
            setAllNotificationCount(response_Read?.data?.data?.totalCount)
            if(response_Read?.data?.data?.notifications?.length === 0){
                setAllNotificationFetched(true)
            }
            setAllNotifications((prev) => [...prev, ...response_Read.data.data.notifications]);
        } catch (error) {
            console.log(error, "error");
        }
    }
    useEffect(() => {
        getAllNotificationData()
    }, []);

    const getTimeDifference = (inputDate) => {
        const currentDate = new Date();
        const inputDateTime = new Date(inputDate);
        const timeDifference = (currentDate - inputDateTime) / 1000; // Difference in seconds
        const seconds = Math.floor(timeDifference);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (years > 0) {
            return `${years} year${years > 1 ? 's' : ''} ago`;
        } else if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes >= 10) {
            return `${minutes} minutes ago`;
        } else if (minutes > 0) {
            return `few minutes ago`;
        } else if (seconds > 0) {
            return `few seconds ago`;
        } else {
            return `just now`;
        }
    }



    const readedNotificationId = async (item) => {
        let payload = {
            _id: item._id,
            userId: item.receiverData.id,
            updateAll: false
        }
        try {
            if (item?.receiverData?.isViewed === false) {
                await updateNotification(payload)
            }
            if (userType === "Recruitment Leader") {
                if (item?.requisitionDetails?.assignedTo === userIdType) {
                    history.push(`/requisition-details/${item.requisitionId}`)
                }
                else {
                    const index = allNotifications.findIndex(dataitem => dataitem._id === item._id);
                    const updatedAllNotifications = allNotifications.map((dataitem, idx) =>
                        idx === index
                            ? { ...dataitem, receiverData: { ...dataitem.receiverData, isViewed: true } }
                            : dataitem
                    );
                    const updatedNewNotifications = newNotification.filter(dataitem => dataitem._id !== item._id);
                    setAllNotifications(updatedAllNotifications);
                    setNewNotification(updatedNewNotifications);
                }
            }
            else if (userType === 'Recruiter') {
                let data = item?.recruiterRequisition?.filter((dataitem) => dataitem.recruiterId === userIdType && dataitem?.isActive === true)
                if (data.length > 0) {
                    history.push(`/requisition-details/${item.requisitionId}`)
                }
                else {
                    const index = allNotifications.findIndex(dataitem => dataitem._id === item._id);
                    const updatedAllNotifications = allNotifications.map((dataitem, idx) =>
                        idx === index
                            ? { ...dataitem, receiverData: { ...dataitem.receiverData, isViewed: true } }
                            : dataitem
                    );
                    const updatedNewNotifications = newNotification.filter(dataitem => dataitem._id !== item._id);
                    setAllNotifications(updatedAllNotifications);
                    setNewNotification(updatedNewNotifications);
                }
            }
        } catch (error) {
            console.log(error, "error");
        }
    }

    const markAllDataReaded = async () => {
        let payload = {
            userId: userIdType,
            updateAll: true
        }
        try {
            await updateNotification(payload)
            window.location.reload()
        } catch (error) {
            console.log(error, "error");
        }
    }

    const goToDashBoard = () => {
        history.push("/")
    };


    return (
        <>
            {(userType !== "MIS Operator" || userType !== "Organization Admin") ? (
                <>
                    <div className='mainContainer_notification'>
                        <div className="topContainer_notification">
                            <Header isNotificationPage={true} />
                            <div className="mainDiv_notification">
                                <h2 className="titlesofCommunity_notification">Notification</h2>
                                <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashBoard(e)}>
                                    <ArrowBack />&nbsp; Go Back to Dashboard
                                </div>
                                <div className='newNotification_notification'>You've {newNotificationCount} new notifications</div>
                                <div className='notification_container'>
                                    <div className='newAll_notification'>
                                        <div className={isNotificationTab === "newTab" ? 'alltext_notification' : 'newtext_notification'} onClick={(e) => setIsNotificationTab("newTab")}>New</div>
                                        <div className={isNotificationTab === "allTab" ? 'alltext_notification' : 'newtext_notification'} onClick={(e) => setIsNotificationTab("allTab")}>All</div>
                                    </div>
                                    <div>
                                        <Button
                                            className={newNotificationCount === 0 ? "markAsReadButton_allReaded" : "markAsReadButton"}
                                            onClick={(e) => markAllDataReaded()}
                                            disabled={newNotificationCount === 0}
                                        >
                                            Mark all as read
                                        </Button>
                                    </div>
                                </div>
                                <div className='cardContainer_notification' onScroll={(e) => {
                                    const bottomThreshold = 50;
                                    const { scrollTop, clientHeight, scrollHeight } = e.target;

                                    if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
                                        if (isNotificationTab === "newTab") {
                                            if (newNotificationCount !== newNotification.length) newAPI("scroll");
                                        } else {
                                            if (!allNotificationFetched) allAPI("scroll");
                                        }

                                    }
                                }}>
                                    {(isNotificationTab === "newTab" ? newNotification : allNotifications)?.map((item, i) => {
                                        return (
                                            <div key={i} className={item?.receiverData?.isViewed === false ? 'cardContains_container_notification_unread' : 'cardContains_container_notification'} onClick={(e) => readedNotificationId(item)}>
                                                <div className='leftSideContainer_cardContainer_notification'>
                                                    <div>
                                                        <img src={user_register} alt="" className='profileImg_notification' />
                                                    </div>
                                                    <div className='candidateDetail_notification'>
                                                        <div className='activityTimeContainer_notification'>
                                                            <div className='activityTimingText_notification'>{getTimeDifference(item?.created_at)}</div>
                                                        </div>
                                                        <div className='candidateDetail_informationContainer_notification'>
                                                            <div className='interviewText_notification'>{item?.receiverData?.message}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>

                                                    <div className='interviewedDataContainer_notification'>
                                                        <div><CalendarTodayRoundedIcon className='calanderIcon_notification' /></div>
                                                        <div className='interviewedDate_notification'>{item?.created_at ? DateTime.fromISO(item?.created_at).toFormat("dd-LLL-yyyy, hh:mm a") : ''}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    {isNotificationTab === "newTab" ?
                                        (newNotification.length === 0 ? <div className="NoRecentText_notification">No recent notifications !!!</div> : "")
                                        : allNotifications.length === 0 ? <div className="NoRecentText_notification">No recent notifications !!!</div> : ""}

                                </div>
                            </div>

                        </div>
                        <div className="bottomContainer_notification">
                            <Footer />
                        </div>
                    </div>
                </>
            )
                :
                <>
                    <BasicHeader />
                    <div className="row padding_80 text-center">
                        <p className="fs-semi-bold fs-40 f-color authorize">
                            You Are Not Authorized To Visit This Page
                        </p>
                    </div>
                </>

            }

        </>
    )
}

export default Notification