import { useState } from "react";
import addTest from "../../apis/Tests/addTest";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import questionBySubSkill from "../../apis/Tests/questionBySubskill";
const useStyles = makeStyles((theme) => ({
  iconButton: {
    paddingLeft: "4px"
  },
  errorMsg: { "marginLeft": "5px", color: " #F32013" },
  addIconButton: { marginTop: "5px" }
}
));
const TestModal = ({
  isModalOpen,
  setIsModalOpen,
  getTests,
  testTypes,
  skills,
  subskills,
  customers,
  recruitmentPartner,
  setQuestionBySkill,
  questionBySkill,
  totalQuestion,
  setTotalQuestion
}) => {
  const classes = useStyles();

  const [recruitmentpartners, setRecruitmentpartners] = useState("");
  const [selectedSubskill, setSelectedSubskill] = useState([{ skill: "", question: "", name: "" }]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [apvBtn, setApvBtn] = useState(false);
  const activeOptions = [
    { code: true, name: "True" },
    { code: false, name: "False" },
  ];
  const [testInfo, setTestInfo] = useState({
    testName: "",
    testCode: "",
    testType: "",
    type: "",
    customer: "",
    skill: "",
    isActive: "",
    isActiveCode: ""
  })
  const [errorMsg, setErrorMsg] = useState([]);
  const closeModal = () => {
    setIsModalOpen(false);
    setTestInfo({
      testName: "",
      testCode: "",
      testType: "",
      type: "",
      customer: "",
      skill: "",
      isActive: "",
      isActiveCode: ""
    })
    setSelectedSubskill([{ skill: "", question: "", name: "" }]);
    setRecruitmentpartners("");
    setTotalQuestion(0);
    setQuestionBySkill([]);
    setErrorMsg([]);
  };

  const testNameChangeHandler = (e, value) => {
    if(e.target.value){
      setTestInfo({...testInfo, testName: e.target.value})
    }else{
      setTestInfo({...testInfo, testName: ""})
    }
  }

  const testCodeChangeHandler = (e, value) => {
    if(e.target.value){
      setTestInfo({...testInfo, testCode: e.target.value})
    }else{
      setTestInfo({...testInfo, testCode: ""})
    }
  }

  const testTypeHandler = (e, value) => {
    if (value) {
      setTestInfo({...testInfo, testType: value.id , type: value.code})
      if (value.code === "Public") {
        setTestInfo({...testInfo, customer: "", type: value.code , testType: value.id})
        setRecruitmentpartners("");
        setSelectedCustomer([]);
      }
    } else {
      setTestInfo({...testInfo, testType: value.id , type: value.code, customer: ""})
      setRecruitmentpartners("");
    }
  };

  const isActiveHandler = (e, value) => {
    if (value) {
      setTestInfo({...testInfo, isActive:value.name , isActiveCode: value.code})
    } else {
      setTestInfo({...testInfo, isActive: "" , isActiveCode: ""})
    }
  };

  const customerHandler = (e, value) => {
    if (value) {
      setTestInfo({...testInfo, customer: value.customer.id})
      getSelectedCustomerData(recruitmentpartners, value.customer.id);
    } else {
      setTestInfo({...testInfo, customer: ""})
      setSelectedCustomer([]);
    }
  };

  const RecruitmentpartnerHandler = (e, value) => {
    if (value) {
      setRecruitmentpartners(value.id);
    } else {
      setRecruitmentpartners("");
    }
    setSelectedCustomer([]);
    setTestInfo({...testInfo, customer: ""})
  };

  const getCustomers = (value) => {

    let custom;
    if (value === "") {
      custom = recruitmentPartner;
    } else {
      custom = recruitmentPartner.filter((item) => item.id === value);
    }
    custom = custom.map((item) => item.recruitmentpartnercustomers)
    custom = custom[0];
    return (custom);
  }

  const skillHandler = (e, value) => {
    if (value) {
      setTestInfo({...testInfo, skill: value.id})
    } else {
      setTestInfo({...testInfo, skill: ""})
    }
  };
  const subskillsHandler = async (event, newValue, i) => {
    let originalData = [...selectedSubskill];
    let dataToBeChange = originalData[i];
    dataToBeChange.skill = newValue.id;
    dataToBeChange.name = newValue.name;
    originalData[i] = dataToBeChange;
    setSelectedSubskill(originalData || []);
    let msgArr = [...errorMsg];
    msgArr[i] = "";
    setErrorMsg(msgArr);
    if (originalData[i].skill) {
      const payload = {
        subSkillsId: originalData[i].skill ? originalData[i].skill : "",
      };
      const res = await questionBySubSkill(payload);
      if (res.status === 200) {
        let questionData = [...questionBySkill];
        questionData[i] = res.data.data.length
        setQuestionBySkill(questionData || [])
      }
    }
  };
  const subSkillQuestionHandler = (value, i) => {
    let originalData = [...selectedSubskill];
    originalData[i].question = value * 1;
    let total = 0;
    originalData.forEach(element => total += element.question * 1);
    setTotalQuestion(total);
    setSelectedSubskill(originalData || []);
    let msgArr = [...errorMsg];
    msgArr[i] = "";
    setErrorMsg(msgArr);
  }
  const getSelectedCustomerData = (value, customerId) => {
    let custo = recruitmentPartner.filter((item) => item.id === value);
    custo = custo.map((item) => item.recruitmentpartnercustomers)
    let selctedData = custo[0].filter((item) => item.customerId === customerId)
    setSelectedCustomer(selctedData[0]);
  }

  const handleSave = async () => {
    setApvBtn(true)
    if (
      testInfo.testName === "" ||
      testInfo.isActive === "" ||
      testInfo.skill === "" ||
      testInfo.testType === "" || (testInfo.type === "Client-Specific" && recruitmentpartners === "")
    ) {
      setApvBtn(false)
      NotificationManager.error("Please fill in all the fields", Error, 2500);
    } else {
      let flag = true;
      let msg = "";
      let msgArr = [...errorMsg];
      for (let i = 0; i < selectedSubskill.length; i++) {
        if (selectedSubskill[i].skill === "" || selectedSubskill[i].question === "" || selectedSubskill[i].question === 0) {
          flag = false;
          msg = "Please fill in all the fields";
        }
        else if (questionBySkill[i] === 0) {
          flag = false;
          msgArr[i] = "No Question present in  subskill " + selectedSubskill[i].name
        }
        else if (selectedSubskill[i].question > questionBySkill[i] && questionBySkill[i] > 0) {
          flag = false;
          msgArr[i] = "Max Question for subskill " + selectedSubskill[i].name + " is: " + questionBySkill[i];
        } else if (selectedSubskill[i].question < 0) {
          flag = false;
          msgArr[i] = "Please fill with positive value for all subkill's ";
        }
      }
      setErrorMsg(msgArr);
      if (!flag) {
        if (msg !== "") {
          setApvBtn(false)
          NotificationManager.error(msg,
            Error, 2500);
        }
      }
      else {
        try {
          const payload = {
            testName: testInfo.testName ? testInfo.testName : "",
            testCode: testInfo.testCode ? testInfo.testCode : "",
            skillId: testInfo.skill ? testInfo.skill : "",
            testtypeId: testInfo.testType ? testInfo.testType : "",
            customerId: testInfo.customer ? testInfo.customer : "",
            recruitmentpartnerId: recruitmentpartners ? recruitmentpartners : "",
            selectedSubskill: selectedSubskill,
            createdBy: localStorage.getItem("userID"),
            updatedBy: localStorage.getItem("userID"),
            isActive: testInfo.isActiveCode,
          };

          const res = await addTest(payload);
          if (res.status=== 201) {
            getTests();
            closeModal();
            setApvBtn(false)
            NotificationManager.success(
              "Test added successfully",
              "Success",
              2500
            );
            setSelectedCustomer([]);
          } else {
            setApvBtn(false)
            NotificationManager.error("This Test Code Already Exists; Please Use A Different Test Code", "Error", 2500);
          }
        } catch (error) {
          setApvBtn(false)
          console.log(error);
        }
      }
    }
    setApvBtn(false)
  };

  const addSubskill = async () => {
    const row = {
      skill: "",
      question: "",
      name: ""
    };
    setSelectedSubskill([...selectedSubskill, row]);
    let questionData = [...questionBySkill];
    questionData[questionData.length] = -1
    setQuestionBySkill(questionData || []);
    let msgArr = [...errorMsg];
    msgArr.push("");
    setErrorMsg(msgArr);
  }
  const deleteSubskill = (e, i) => {
    let temp = [...selectedSubskill]
    temp.splice(i, 1);
    let total = 0
    temp.forEach(element => total += element.question * 1);
    setTotalQuestion(total);
    setSelectedSubskill(temp)
    let questionDatatemp = [...questionBySkill];
    questionDatatemp.splice(i, 1);
    setQuestionBySkill(questionDatatemp || []);
    let msgArr = [...errorMsg];
    msgArr.splice(i, 1);
    setErrorMsg(msgArr);
  }

  if (!isModalOpen) {
    return null;
  } else {
    return (
      <>
        <Dialog
          onClose={closeModal}
          aria-labelledby="customized-dialog-title"
          open={isModalOpen}
          PaperProps={{
            style: {
              width: "600px",
            },
          }}
        >
          <Grid item xs={12}>
            <div className="creatTest_title">
              <DialogTitle
                id="customized-dialog-title"
                className="admindailog_title_modal"
                onClose={closeModal}
              >
                Create Test
                <IconButton
                  aria-label="close"
                  style={{
                    position: "absolute",
                    right: "7px",
                    top: "12px",
                    color: "grey",
                  }}
                  onClick={closeModal}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
            </div>
          </Grid>
          <DialogContent className="userlist_dailog_content">
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Test Name*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Enter Test Name"
                  className="admin_textbox"
                  value={testInfo.testName}
                  onChange = {testNameChangeHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Test Code*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Enter Test Code"
                  className="admin_textbox"
                  value={testInfo.testCode}
                  onChange= {testCodeChangeHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Test Type*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  autoSelect
                  id="combo-box-demo"
                  options={testTypes}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Test Type"
                    />
                  )}
                  onChange={testTypeHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            {testInfo.type === "Client-Specific" ? <>
              <Grid item xs={12} md={12}>
                <Typography variant="body1" component="h2">
                  Select Recruitment Organization*
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Autocomplete
                    autoSelect
                    id="combo-box-demo"
                    filterSelectedOptions
                    options={recruitmentPartner.filter((item) => item.isActive === true)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Recruitment Organization"
                      />
                    )}
                    onChange={RecruitmentpartnerHandler}
                  />
                </FormControl>
              </Grid>
              &nbsp;
              {recruitmentpartners !== "" ? (
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" component="h2">
                    Select Customer
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <Autocomplete
                      autoSelect
                      id="combo-box-demo"
                      filterSelectedOptions
                      value={selectedCustomer}
                      options={getCustomers(recruitmentpartners)}
                      getOptionLabel={(option) => option?.customer?.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select customer"
                        />
                      )}
                      onChange={customerHandler}
                    />
                  </FormControl>
                </Grid>
              ) : null}

            </> : null}
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Is Active*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  id="combo-box-demo"
                  options={activeOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.name === value.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Status"
                    />
                  )}
                  onChange={isActiveHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Skill*
              </Typography>
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  autoSelect
                  id="combo-box-demo"
                  options={skills}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Skill"
                    />
                  )}
                  onChange={skillHandler}
                />
              </FormControl>
            </Grid>
            &nbsp;
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body1" component="h2">
                  Subskills*
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" component="h2">
                  No.of Questions*
                </Typography>
              </Grid>
            </Grid>
            {selectedSubskill.map((item, i) => {
              return (
                <div key={i}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <FormControl fullWidth variant="outlined">
                        <Autocomplete
                          disableClearable
                          options={subskills}
                          value={item}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select Subskills"
                            />
                          )}
                          getOptionDisabled={option => !!selectedSubskill.find(element => element.skill === option.id)}
                          onChange={(event, newValue) => subskillsHandler(event, newValue, i)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} >
                      <OutlinedInput
                        type="number"
                        placeholder="Enter Total No.of Questions"
                        className="admin_textbox"
                        value={item.question || ""}
                        onChange={(e) => subSkillQuestionHandler(e.target.value, i)}
                      />
                      {i !== 0 && (
                        <span className={`${classes.iconButton} `}>
                          <CancelRoundedIcon onClick={(e) => { deleteSubskill(e, i) }} />
                        </span>
                      )}
                    </Grid>
                    <p className={`${classes.errorMsg} `}>{errorMsg[i]}</p>
                  </Grid>
                </div>
              )
            })}
            <div className={`row optionsStyle addButton ${classes.addIconButton} `}>
              <AddCircleIcon className="addBtn" onClick={(e) => addSubskill(e)} />
            </div>
            &nbsp;
            <Grid item xs={12} md={12}>
              <Typography variant="body1" component="h2">
                Total Number of Questions: {totalQuestion}
              </Typography>
            </Grid>
          </DialogContent>

          <Grid
            item
            xs={12}
            md={12}
            className="user_pop_button content-scrollable"
          >
            <DialogActions>
              <Button variant="outlined" className="btn4" color="primary" onClick={closeModal}>
                Cancel
              </Button>

              <button
                type="button"
                className="btn3 btn_primary ml_5 cp"
                onClick={handleSave}
                disabled={apvBtn}
              >
                <div className="btn_loader">
                  Save
                  &nbsp;
                  {apvBtn &&
                    <div>
                      <CircularProgress size={13} />
                    </div>
                  }
                </div>
              </button>
            </DialogActions>
          </Grid>
        </Dialog>
        <NotificationContainer />
      </>
    );
  }
};

export default TestModal;
