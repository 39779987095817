import React, { useEffect, useState, useCallback } from "react";
// KendoReact
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// MUI
import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";

import { DateTime } from "luxon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Autocomplete } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

// APIS
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import { getAllAgency } from "../../apis/agency/agencyApi.js";
import { getAgencyType } from "../../apis/agency/agencyApi.js";
import { updateAgencyById } from "../../apis/agency/agencyApi.js";
import { createNewAgency } from "../../apis/agency/agencyApi.js";
import { Loader } from '@progress/kendo-react-indicators';

const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

// Functional Component
const AgencyKendo = (isDataLoaded) => {
  const [modalIsOpenAdd, setModalIsOpenAdd] = useState(false);
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);


  // My state for Agency
  const [allData, setAllData] = useState([]);
  const [recruiterData, setRecruiterData] = useState([]);

  // State for Adding
  const [agencyInfo, setAgencyInfo] = useState({
    recruitmentPartnerArr: "",
    isActive: "",
    agencyName: "",
    selectedAgencyType: {},
    recPartners: "",
    agencyId: "",

  })
  const [agencyTypeOptions, setAgencyTypeOptions] = useState([]);

  // Options for active and agency type
  const isActiveItems = ["True", "False"];
  const getUserID = localStorage.getItem("userID");
  const [atvBtn, setAtvBtn] = useState(false);

  //----------------------------------
  const clearData = () => {
    setAgencyInfo({
      recruitmentPartnerArr: "",
      isActive: "",
      agencyName: "",
      selectedAgencyType: {},
      recPartners: "",
    })
  };

  const handleisActive = (e, value) => {
    if(value === "True"){
      setAgencyInfo({...agencyInfo, isActive: true})
    }else if (value === "False"){
      setAgencyInfo({...agencyInfo, isActive: false})
    }else{
      setAgencyInfo({...agencyInfo, isActive: null})
    }
  };

  const handleAgencytype = (e, value) => {
    if (value) {
      setAgencyInfo({...agencyInfo, selectedAgencyType: value})
    } else {
      setAgencyInfo({...agencyInfo, selectedAgencyType: {}})
    }
  };



  const handleRecruiterName = (e, value) => {
    setAgencyInfo({...agencyInfo, recruitmentPartnerArr: value?.id})
  };

  const handleName = (e) => {
    setAgencyInfo({...agencyInfo, agencyName: e.target.value?.trim()})
  };


  // Edit Action Handler
  const handleEditClick = async (e) => {
    setModalIsOpenEdit((prev) => (prev = true));
    const selectedAgency = agencyTypeOptions.find(a => a.id === e.agencyTypeId);
    setAgencyInfo({...agencyInfo, agencyId: e?.id,agencyName: e.agencyName?.trim() , selectedAgencyType:selectedAgency ,recPartners : 
      e?.recruitmentpartner, isActive: e?.isActive , recruitmentPartnerArr: e?.recruitmentpartner })
  };

  const handleRecruitmentPartnersClick = (e, value) => {
    setAgencyInfo({...agencyInfo, recruitmentPartnerArr: value?.id})
  };

  const validateBoolean = (booleanValue) => {
    let active;
    if (booleanValue === "True") {
      active = true;
    } else if (booleanValue === true) {
      active = true;
    } else if (booleanValue === "False") {
      active = false;
    } else if (booleanValue === false) {
      active = false;
    } else {
      active = null;
    }
    return active;
  };


  // Adding agency handler
  const createAgency = async () => {
    setAtvBtn(true)
    let active = validateBoolean(agencyInfo.isActive);

    // Payload
    let agencyData = {
      agencyName: agencyInfo.agencyName?.trim(),
      agencyTypeId: agencyInfo.selectedAgencyType.id,
      recruitmentOrganizationId: agencyInfo.recruitmentPartnerArr,
      isActive: active,
      createdBy: getUserID,
      updatedBy: getUserID,
    };

    // Validating
    if (agencyData.agencyName?.trim().toLowerCase() === "null") {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please enter valid Agency Name.",
        "Error",
        2500
      );
    }

    if (
      !agencyData.recruitmentOrganizationId ||
      !agencyData.agencyName ||
      !agencyData.agencyTypeId ||
      agencyData.isActive === undefined ||
      agencyData.isActive === "" ||
      agencyData.isActive === null
    ) {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please fill all the mandatory fields.",
        "Error",
        2500
      );
    } else {
      try {
        const agencyResponse = await createNewAgency(agencyData);
        getData();

        // If Agency already exist
        if (
          agencyResponse?.data?.message ==
          "This Agency name already exists Please use a different Agency name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Agency name already exists. Please use a different Agency name.",
            "Error",
            2500
          );
        } else {
          NotificationManager.success(
            "Agency added successfully.",
            "Success",
            2500
          );
          clearData();
          handleClose();
          setAtvBtn(false)
        }
        setAtvBtn(false)

      } catch (error) {
        console.log(error);
        getData();
        setAtvBtn(false)
      }
    }
  };

  // Edit agency handler
  const editAgency = async () => {
    setAtvBtn(true)
    let active = validateBoolean(agencyInfo.isActive);

    // Payload
    let agencyData = {
      agencyName: agencyInfo.agencyName?.trim(),
      agencyTypeId: agencyInfo.selectedAgencyType.id,
      recruitmentOrganizationId: agencyInfo.recruitmentPartnerArr.id,
      isActive: active,
      createdBy: getUserID,
      updatedBy: getUserID,
    };

    // Validating
    if (agencyData.agencyName?.trim().toLowerCase() === "null") {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please enter valid Agency Name.",
        "Error",
        2500
      );
    }

    if (
      !agencyData.recruitmentOrganizationId ||
      !agencyData.agencyName ||
      !agencyData.agencyTypeId ||
      agencyData.isActive === undefined ||
      agencyData.isActive === "" ||
      agencyData.isActive === null
    ) {
      setAtvBtn(false)
      return NotificationManager.error(
        "Please fill all the mandatory fields.",
        "Error",
        2500
      );
    } else {
      try {
        const agencyResponse = await updateAgencyById(agencyInfo.agencyId, agencyData);
        getData();
        if (
          agencyResponse?.data?.message ===
          "This Recruitment organization name already exists Please use a different Recruitment organization name"
        ) {
          setAtvBtn(false)
          NotificationManager.error(
            "This Recruitment organization name already exists. Please use a different Recruitment organization name.",
            "Error",
            2500
          );
        } else {
          NotificationManager.success(
            "Agency updated successfully.",
            "Success",
            2500
          );

          handleClosed();
          clearData();
          setAtvBtn(false)
        }
      } catch (error) {
        console.log(error);
        setAtvBtn(false)
      }
    }
  };


  const getData = async () => {
    // Getting all agency data

    const agencyData = await getAllAgency();
    const allAgencyData = agencyData?.data?.data;

    // Getting agencyType data
    const agencyType = await getAgencyType();
    const agencyTypeData = agencyType?.data?.data;

    setAgencyTypeOptions(agencyTypeData);

    for (let i = 0; i < allAgencyData.length; i++) {
      // Recruitment partner name
      let recruitmentPartnerObj = allAgencyData[i]?.recruitmentpartner;
      allAgencyData[i].recruitmentsPartner = recruitmentPartnerObj?.name;

      // Agency type
      let agencyTypeValue = allAgencyData[i]?.agencyType?.code[0].toUpperCase() + allAgencyData[i]?.agencyType?.code.slice(1);
      allAgencyData[i].agencyType = agencyTypeValue;

      // Created Date
      let date = allAgencyData[i]?.createdAt;
      let convertedDate = DateTime.fromISO(date)
        .toLocal()
        .startOf("day")
        .toJSDate();
      allAgencyData[i].createdTime = convertedDate;
    }

    setAllData(allAgencyData);

    // Recruitment Partner api call for getting all recruitment partner name
    let data = await getAllRecruitmentPartnersAndClients();
    let allDataSet = data?.data?.data;

    setRecruiterData(allDataSet.filter((item) => item?.isActive === true));


  };

  const handleClose = () => {
    setModalIsOpenAdd((prev) => (prev = false));
  };

  const handleClosed = () => {
    setModalIsOpenEdit((prev) => (prev = false));
  };

  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    position: "absolute",
    top: "-50px",
    right: "0",
  };

  const btnSavegreen = {
    color: "#fff",
    backgroundColor: "#2AD156",
    border: "1px solid #2AD156",
  };

  const openModal = (e, client) => {
    setModalIsOpenAdd(true);
  };

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  useEffect(() => {

    getData();

  }, []);

  return (
    <>
      <div className="containerMain">
        <GridDiv
          pageable={{
            buttonCount: 10,
            info: true,
            previousNext: true,
            pageSizes: true,
            serverPaging: true,
            serverFiltering: true,
          }}
          sortable={true}
          filterable={true}
          resizable={true}
          style={{
            height: "70vh",
            width: "100%",
            overflow: "hidden",
          }}
          data={process(allData, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >{
            !isDataLoaded && (
              <GridNoRecords>
                <div className='col-4'>
                  <br />
                  <Loader size='large' themeColor="info" type="converging-spinner" />
                </div>
              </GridNoRecords>
            )
          }

          <GridColumn
            title="Action"
            width="100px"
            filterable={false}
            cell={(event) => {
              return (
                <td style={{ textAlign: "center" }}>
                  <Button
                    onClick={() =>
                      handleEditClick(event.dataItem, event.dataItem)
                    }
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                </td>
              );
            }}
          />
          <GridColumn
            field="id"
            title="ID"
            skillChangeHandler
            filterable={true}
            sortable={true}
            width="200px"
          />
          <GridColumn
            field="agencyName"
            title="Name"
            skillChangeHandler
            filterable={true}
            sortable={true}
            width="200px"
          />
          <GridColumn
            field="recruitmentsPartner"
            title="Recruitment Organization"
            filterable={true}
            sortable={true}
            width="230px"
          />
          <GridColumn
            field="agencyType"
            title="Agency Type"
            filterable={true}
            sortable={true}
            width="200px"
          />
          <GridColumn
            field="createdTime"
            title="Created At"
            filterable={true}
            sortable={true}
            filter={"date"}
            format="{0:d},{0:t}"
            width="235px"
            cell={(e) => {
              return (
                <td>
                  {DateTime.fromISO(e.dataItem.createdAt)
                    .toFormat("MM/dd/yyyy")
                    .toString()}
                </td>
              );
            }}
          />

          <GridColumn
            title="Is Active"
            field="isActive"
            width="200px"
            filterable={true}
            filter={"boolean"}
            cell={(event) => {
              return <td>{event.dataItem?.isActive ? "True" : "False"}</td>;
            }}
          />
        </GridDiv>
        <Button
          variant="outlined"
          style={btnGreen}
          color="primary"
          onClick={openModal}
        >
          Add Agency
        </Button>
      </div>

      {/* Add Agency */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalIsOpenAdd}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle className="admindailog_title_modal">
            Add Agency
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "grey",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent>
          <Typography gutterBottom>Recruitment Organization*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={recruiterData}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Recruitment Organization"
                />
              )}
              onChange={handleRecruiterName}
            />
          </FormControl>
          &nbsp;
          <Typography>Agency Type*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={agencyTypeOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Agency Type"
                />
              )}
              onChange={handleAgencytype}
            />
          </FormControl>
          &nbsp;
          <Typography gutterBottom>Agency Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Enter Agency Name"
              className="admin_textbox"
              onChange={handleName}
            />
          </FormControl>
          &nbsp;
          <Typography>Is Active*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={isActiveItems}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Status"
                />
              )}
              onChange={handleisActive}
            />
          </FormControl>
          &nbsp;
        </DialogContent>
        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={handleClose}>
              Cancel
            </Button>

            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={createAgency}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
        <NotificationContainer />
      </Dialog>

      {/* ------------------Edit Agency------------------ */}
      <Dialog
        onClose={handleClosed}
        aria-labelledby="customized-dialog-title"
        open={modalIsOpenEdit}
        PaperProps={{
          style: {
            width: "600px",
          },
        }}
      >
        <div className="Recruiter_Private_Skill_Modal">
          <DialogTitle className="admindailog_title_modal">
            Edit Agency
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                color: "grey",
              }}
              onClick={handleClosed}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>

        <DialogContent>
          <Typography gutterBottom>
            {/* Edit */}
            Recruitment Organization*
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={recruiterData || ""}
              defaultValue={agencyInfo.recPartners}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Recruitment Organization"
                />
              )}
              onChange={handleRecruitmentPartnersClick}
            />
          </FormControl>
          &nbsp;
          <Typography gutterBottom>Agency Name*</Typography>
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              placeholder="Enter Agency Name"
              className="admin_textbox"
              defaultValue={agencyInfo.agencyName}
              onChange={handleName}
            />
          </FormControl>
          &nbsp;
          <Typography>Agency Type*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={agencyTypeOptions}
              value={agencyInfo.selectedAgencyType || ""}
              getOptionLabel={(option) => option.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Agency Type"
                />
              )}
              onChange={handleAgencytype}
            />
          </FormControl>
          &nbsp;
          <Typography>Is Active*</Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              id="combo-box-demo"
              options={isActiveItems}
              value={agencyInfo.isActive === null ? null : (agencyInfo.isActive === "True" || agencyInfo.isActive === true ? "True" : "False")}

              getOptionLabel={(option) => option || ""}
              getOptionSelected={(option, value) => option === (value === true ? "True" : "False")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Status"
                />
              )}
              onChange={handleisActive}
            />
          </FormControl>
          &nbsp;
        </DialogContent>

        <Grid item xs={12} md={12} className="user_pop_button">
          <DialogActions>
            <Button variant="outlined" className="btn3" color="primary" onClick={handleClosed}>
              Cancel
            </Button>
            <button variant="outlined" className="btn3 btn_primary cp" disabled={atvBtn} onClick={editAgency}>
              <div className="btn_loader">
                Save
                &nbsp;
                {atvBtn &&
                  <div >
                    <CircularProgress size={13} />
                  </div>
                }
              </div>
            </button>
          </DialogActions>
        </Grid>
        <NotificationContainer />
      </Dialog>
    </>
  );
};

export default AgencyKendo;
