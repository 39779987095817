import axios from "axios";
const jsonConfig = require("../../Config.json");

const getAllCandidateRequisitionStatus = async () => {
    try {
        const res = await axios.get(
            jsonConfig.apiUrl + `getAllCandidateRequisitionStatus`, {
                headers: {
                  "x-functions-key": jsonConfig.key,
                },
              });

        return Promise.resolve(res);
    }
    catch(err) {
        return Promise.reject(err);
    }
}


export default getAllCandidateRequisitionStatus;