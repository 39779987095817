import axios from "axios";
const jsonConfig = require("../../Config.json");

const postUserTestQuestionResponse = async (payload) => {
    let allResponse = {}
    try {
        const response = await axios.post(
            jsonConfig.apiUrl + "usertestquestion",
            payload,
            {
              headers: {
                "x-functions-key": jsonConfig.key
              }
            }
          );
        allResponse = response;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default postUserTestQuestionResponse;