import axios from "axios";
const jsonConfig = require("../../Config.json");

const profileResponse = async (userId) => {
  let allResponse = {};
  try {
    const profileResponse = await axios.get(
      jsonConfig.apiUrl + "profile/" + userId,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allResponse = profileResponse;
    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default profileResponse;