import { Card, CircularProgress, Container, FormControl, makeStyles, OutlinedInput, Paper, Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import Grid from "@material-ui/core/Grid";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useHistory } from "react-router";
import csc from "../../utilities/country-state-city/dist";
import bubble_big from "../../components/basicInfoForm/images/bubble-big.png";
import bubble_small from "../../components/basicInfoForm/images/bubble-small.png";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import getSkillResponse from "../../apis/skills/getActiveSkills";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import InviteCandidateDetailsResponse from "../../apis/inviteCandidate/inviteCandidateDetails";
import getAllTests from "../../apis/Tests/getAllTests";
import jwt_decode from "jwt-decode";
import phoneNumberExistResponse from "../../apis/users/getUserDataByPhoneNumber";
import HomeContactUsModal from "../../pages/home/homeHeader/homeContactUsModal/homeContactUs";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import GetUserById from "../../apis/users/getUserById";
import getQuestionalCountByTestId from "../../apis/question/getQuestionCountByTestId";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});
const filterTestOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.testName,
});
const useStyles = makeStyles((theme) => ({
  testQuestAnsCard: {
    height: "66vh",
    maxWidth: "none",
    marginTop: '20px',
    paddingTop: "70px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
  },
}))

const RecruiterSignUp = (props) => {
  const { match } = props;
  const history = useHistory();
  const jsonConfig = require("../../Config.json");
  const [b1Style, setb1Style] = useState({ top: "25%" });
  const [b2Style, setb2Style] = useState({ top: "65%" });

  const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
  const [phoneError, setPhoneError] = useState(null);

  const [recruiterDataForSignUp, setrecruiterDataForSignUp] = useState({});
  const [emailError, setemailError] = useState(false);
  const [isEmailDisabled, setisEmailDisabled] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(300000);
  const [IsbtnDisabled, setIsbtnDisabled] = useState(true);
  const [countryList, setCountryList] = useState([
    {
      currency: "USD",
      flag: "",
      isoCode: "US",
      latitude: "38.00000000",
      longitude: "-97.00000000",
      name: "United States",
      phonecode: "+1",
    },
    {
      currency: "CAD",
      flag: "",
      isoCode: "CA",
      latitude: "60.00000000",
      longitude: "-95.00000000",
      name: "Canada",
      phonecode: "+1",
    },
    {
      currency: "INR",
      flag: "",
      isoCode: "IN",
      latitude: "20.00000000",
      longitude: "77.00000000",
      name: "India",
      phonecode: "+91",
    },
    {
      currency: "MXN",
      flag: "",
      isoCode: "MX",
      latitude: "23.00000000",
      longitude: "-102.00000000",
      name: "Mexico",
      phonecode: "+52",
    }
  ]);
  const today = new Date();
  const [stateList, setStateList] = useState(Array);
  const [cityList, setCityList] = useState(Array);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [MiddleInitial, setMiddleInitial] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [skillId, setskillId] = useState("");
  const [testId, setTestId] = useState("");
  const [userCountry, setUserCountry] = useState({});
  const [userState, setUserState] = useState({});
  const [userCity, setUserCity] = useState({});
  const [spinnerStatus, setSpinnerStatus] = useState(false);
  const [Phonecode, setPhonecode] = useState("");
  const [subSill, setSubSkill] = useState([]);
  const [primayskillDefault, setprimayskillDefault] = useState({});
  const [defaultTest, setDefaultTest] = useState({});
  const [activeSkill, setActiveSkill] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [isPageDisplay, setIsPageDisplay] = useState(false);
  const [recruitmentpartnerId, setRecruitmentPartnerId] = useState("");
  const hamburger = useRef(0);
  const [open, setOpen] = useState(false);
  const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false)
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  const [totalQuestions, setTotalQuetions] = useState(0);
  const classes = useStyles();
  const [recruiterId, setRecruiterId] = useState("");
  useEffect(() => {
    animateBubble();
  }, []);
  useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          setCounter((counter) => counter - 1000);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId); ///componentWillUnmount here mounting phase is distroyed
  }, [isActive, counter]);
  useEffect(() => {
    getTests();
  }, []);
  useEffect(() => {
    getInviteCandidateDetailsResponse(match.params.id);
  }, []);

  const getInviteCandidateDetailsResponse = async (id) => {
    try {
      let CandidateDetails = await InviteCandidateDetailsResponse({ id: id });

      if (!CandidateDetails.data.isexistingcandidate) {

        if (CandidateDetails.data.data && CandidateDetails.data.data.length > 0) {
          setRecruiterId(CandidateDetails.data.data[0].invitedBy)
          setFirstName(CandidateDetails.data.data[0].firstName);
          setLastName(CandidateDetails.data.data[0].lastName);
          setEmail(CandidateDetails.data.data[0].email);
          setskillId(CandidateDetails.data.data[0].test.skillId);
          setprimayskillDefault(CandidateDetails.data.data[0].skill);
          setDefaultTest(CandidateDetails.data.data[0].test);
          setTestId(CandidateDetails.data.data[0].testId)

          if (CandidateDetails.data.data[0]?.test?.recruitmentpartnerId === "") {
            const res = await GetUserById(CandidateDetails.data.data[0]?.invitedBy)
            setRecruitmentPartnerId(res?.data?.data[0]?.recruitmentpartner?.id)
          }
          else {
            setRecruitmentPartnerId(CandidateDetails.data.data[0]?.test?.recruitmentpartnerId)
          }


          setIsLinkValid(true);
        }
        // else {
        //   setIsLinkValid(false);
        //   var errMsg =
        //     CandidateDetails.data.message !== undefined
        //       ? CandidateDetails.data.message
        //       : "This invite link is invalid";
        //   NotificationManager.error(errMsg, "Error", 2500);
        //   setIsPageDisplay(true);
        // }
      }
      else {
        let token = localStorage.getItem("token")
        // let decoded = jwt_decode(token);
        if (token) {
          let decoded = jwt_decode(token);

          history.push({
            pathname: "/invitedtest-landing",
            state:{
              inviteId:match.params.id
            }
            
          });
        }
        else {
          // history.push("/signIn")
          history.push({
            pathname: "/signIn",
            state: {
              // userId:decoded.id ,
              test: CandidateDetails.data.data[0].test,
              isRedrictedFromIvite: true,
              pageName: "/invite/" + match.params.id,
              inviteId:match.params.id
            },
          });
          //  return(NotificationManager.error("You already have a clearedtalent account.To start the exam you need to login", "Error", 2500))


        }

      }
    } catch {
      setIsPageDisplay(true);
    }
  };

  const validateEmail = (email) => {
    const regX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regX.test(email)) {
      if (
        email.includes("gmail") ||
        email.includes("outlook") ||
        email.includes("yahoo") ||
        email.includes("hotmail")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const emailChangeHandler = (event) => {
    setemailError(true);
    const emailValue = event.target.value;
    const emailValidate = validateEmail(emailValue);
    setemailError(false);
  };
  const animateBubble = () => {
    setTimeout(() => {
      setb2Style({ top: "25%" });
      setb1Style({ top: "65%" });
    }, 100);
  };
  const gotoHomePage = (e) => {
    history.push("/");
  };
  const millisToMinutesAndSeconds = (millis) => {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return (
      minutes +
      ":" +
      (seconds < 10 ? "0" : "") +
      (seconds === 0 ? "00" : seconds)
    );
  };
  const goToTerms = (e) => {
    window.open("/terms-of-use");
  };
  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy");
  };
  const countryHandler = (event, value) => {
    if (value === null) {
      setUserCountry("");
    } else {
      setUserCountry(value);
      setDefaultCountryCode(value.isoCode)
      setStateList(csc.getStatesOfCountry(value.isoCode));
    }
  };
  const stateHandler = (event, value) => {
    if (value === null) {
      setUserState("");
    } else {
      setUserState(value);
      setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode));
    }
  };
  const cityHandler = async (event, value) => {
    if (value) {
      setUserCity(value);
    }
  };

  const phoneNumberChangeHandler = (val) => {
    setPhoneNoIntnl(val);
    if (val) {
      if (val && isPossiblePhoneNumber(val)) {
        setPhoneError(false)
        setPhonecode(`+${parsePhoneNumber(val).countryCallingCode}`);
        let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
        setphoneNumber(val.replace(countryCode_PhoneNumber, ""));
      }
      else {
        setPhoneError(true)
      }
    }
    else {
      setPhoneError(false)
      setPhonecode("")
      setphoneNumber("")
    }
  };

  const firstNameChangeHandler = (e) => {
    let firstName = e.target.value;
    setFirstName(firstName);
  };
  const lastNameChangeHandler = (e) => {
    let lastName = e.target.value;
    setLastName(lastName);
  };
  const keypressHandler = (e) => {
    var invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };
  const changeEmailId = () => {
    setisEmailDisabled(false);
    setIsActive(!isActive);
    setCounter(300000);
  };
  const TermsCheckboxHandler = (e) => {
    setIsTermsAccepted(e.target.checked);
    if (e.target.checked === true) {
      setIsbtnDisabled(false);
    } else {
      setIsbtnDisabled(true);
    }
  };
  const testChange = async (event, value) => {
    if (value) {
      // setskillId(value.id);
      setTestId(value.id);
      setDefaultTest(value);
      // getSubSkill(value.id);
      // setprimayskillDefault(value);
    } else {
      setTestId("");
      setDefaultTest({});
    }
  };
  const getSubSkill = async (skillid) => {
    try {
      const getAllSubSkills = await axios.get(
        jsonConfig.apiUrl + "SubskillBySkillId/" + skillid,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
      setSubSkill(getAllSubSkills.data.data);
    } catch (error) {
      console.error("subskillerror", error);
      setSubSkill([]);
    }
  };
  const checkPhoneNumber = async (phoneNumber) => {
    var phoneNumberInput = {
      phoneNumber: phoneNumber,
    };
    try {
      const phoneNochkResponse = await phoneNumberExistResponse(
        phoneNumberInput
      );
      if (phoneNochkResponse.data.data[0].isUnique === true) {
        return phoneNochkResponse.data.data[0].isUnique;
      }
    } catch (error) {
      console.log("phonenoresponse", error);
    }
  };
  const getTests = async () => {
    try {
      const res = await getAllTests();
      const data = res.data;
      setAllTests(data.data);
      setIsDataLoaded(true);
      animateBubble();
    } catch (error) {
      console.log(error.response.data);
    }
  };
  const updateProfileData = async (e) => {
    e.preventDefault();
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      Object.keys(defaultTest).length === 0 ||
      Object.keys(userCountry).length === 0 ||
      Object.keys(userState).length === 0
    ) {
      return NotificationManager.error(
        "Please fill all required fields",
        "Error",
        2500
      );
    }
    if (IsbtnDisabled) {
      return NotificationManager.error(
        "Please agree to terms of use & privacy policy to continue",
        "Error"
      );
    }
    if (phoneError) {
      return NotificationManager.error(
        "Please enter a valid phone number",
        "Error",
        2500
      );
    }
    if (phoneNumber != "") {
      const isunique = await checkPhoneNumber(phoneNumber);
      if (!isunique) {
        setSpinnerStatus(false);
        return NotificationManager.error(
          "Phone number already exists in our system",
          "Error",
          2500
        );
      }
    }
    history.push({
      pathname: "/test-landing",
      state: {
        firstName,
        lastName,
        MiddleInitial,
        phoneNumber: phoneNumber ? phoneNumber : "",
        countryCode: Phonecode,
        email,
        locationCountry: JSON.stringify(userCountry),
        locationState: JSON.stringify(userState),
        locationCity: JSON.stringify(userCity),
        skillId: skillId,
        subSkillsJson: subSill,
        test: defaultTest,
        recruitmentpartnerId: recruitmentpartnerId,
        pageName: "/invite/" + match.params.id,
        inviteId:match.params.id,
        recruiterId
      },
    });
  };
  const goToHome = async (e) => {
    history.push("/");
  };
  const hamburgerClicked = () => {
    setHamburgerIsClicked(!hamburgerIsClicked)
  }
  const openSupportModal = () => {
    setOpen(true);
  }

  const _modalClose = () => {
    setOpen(false);
  }
  useEffect(() => {
    const fetchData = async () => {
      if (testId) {
        const response = await getQuestionalCountByTestId(testId);
        setTotalQuetions(response.data.data[0].totalquestionscount)
      }

    }
    fetchData()
  }, [testId])

  return (
    <>
      {isLinkValid ? (
        <>
          <div className="mainContainer_reg">
            <div className="topContainer">
              <div className="nav_bg_home home-specific-header">
                <div className="container w1200 nav_bg_home_section">
                  <div className="nav_bg_left">

                    <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
                      <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                    </li>

                  </div>
                  <div className="nav_bg_right">
                    <ul className="navbar_home">
                      <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>


              <div className='nav_bg_home_responsive'>
                <div className="nav_bg_home_responsive-wrapper">
                  <div className="home-top-nav">
                    <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
                      <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                    </li>
                    <div className={hamburgerIsClicked ? "hamburgerIsClicked" : "hamburgerIsNotClicked"} ref={hamburger}>
                      <ul className="navbar_home-responsive">
                        <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                      </ul>
                    </div>
                    <a className="icon" onClick={hamburgerClicked}>
                      <i className="fa fa-bars"></i>
                    </a>
                  </div>
                </div>
              </div>
              <Grid container className="mainDiv_reg">
                <Grid item xs={12}>
                  <div className="rightHeading_inviteTest"> Complete Registration & Take Test</div>
                </Grid>
                <Grid item sm={6}>
                <div className="leftContainer">
                    <div className="leftHeading"> Please read the instructions below before you start test</div>                
                    <p className="left_points">
                      <span className="leftBullets">1.</span> For multiple choice questions, you may be allowed to pick just one answer choice or multiple. The clock starts as soon as you click <span className="leftBullets">“Start Test”</span> so start <span className="leftBullets">ONLY</span> when you are ready.
                    </p>
                    <p className="left_points">
                      <span className="leftBullets">2.</span> Some questions may have two parts. For example, Part 1 may be an open ended question where you are expected to speak out your response on video for 1 minute and Part 2 may be a multiple choice question where you are expected to pick the correct answer(s) from the options provided.
                    </p>

                    <p className="left_points">
                      <span className="leftBullets">3.</span> You will be video recorded during the test so please ensure your camera is active and focusing on you.
                    </p>
                    <p className="left_points">
                      <span className="leftBullets">4.</span> Please note that at no point during the test you can go back to the previous question.
                    </p>
                    <p className="left_points">
                      <span className="leftBullets">5.</span> Please try not to navigate out of the test window (or open other applications) for the test duration. We detect such movements and report them to the reviewer.
                    </p>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div className="rightContainer">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">First Name*</div>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            placeholder="Please Enter Your First Name"
                            className="admin_textbox"
                            value={firstName}
                            onChange={(e) => firstNameChangeHandler(e)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">Last Name*</div>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            placeholder="Please Enter Your Last Name"
                            className="admin_textbox"
                            value={lastName}
                            onChange={lastNameChangeHandler}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">Email*</div>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            className="admin_textbox"
                            placeholder="Enter Email"
                            value={email}
                            onChange={(event) => emailChangeHandler(event)}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">Country*</div>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_14"
                          options={countryList}
                          autoHighlight
                          filterOptions={filterOptions}
                          getOptionLabel={(option) =>
                            option.name
                              ? option.name
                              : ""
                          }
                          onChange={countryHandler}
                          renderInput={(params) => <TextField {...params} variant="outlined" required={true} placeholder="Please Select Country" />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">Phone Number</div>
                        <div className="select customized-select dropdown_width_recruiter_invite w100 numericContainer">
                          <PhoneInput
                            international
                            defaultCountry={defaultCountryCode}
                            className="textbox_phone_input"
                            placeholder="Enter phone number"
                            value={phoneNoIntnl}
                            onChange={(e) => phoneNumberChangeHandler(e)}
                            onKeyPress={(e) => keypressHandler(e)}
                          />
                          {(phoneError) ? (
                            <p className="form-error fs-regular">
                              Please enter a valid phone number
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">State*</div>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_14"
                          options={stateList}
                          autoHighlight
                          filterOptions={filterOptions}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          onChange={stateHandler}
                          renderInput={(params) => <TextField {...params} variant="outlined" required={true} placeholder="Please Select State" />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">City</div>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_14"
                          options={cityList}
                          autoHighlight
                          filterOptions={filterOptions}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          onChange={cityHandler}
                          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Please Select City" />}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className="inputLabel_right">Test*</div>
                        <Autocomplete
                          id="combo-box-demo"
                          className="mt_8"
                          options={allTests}
                          autoHighlight
                          filterOptions={filterTestOptions}
                          value={defaultTest}
                          disabled
                          getOptionLabel={(option) => option.testName}
                          onChange={testChange}
                          renderInput={(params) => <TextField {...params} variant="outlined" required placeholder="Select a skill" />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="termsContainer">
                          <div className="video-interview-chkbox basicinfo_chkbox">
                            <input type="checkbox" id="terms" checked={isTermsAccepted} onClick={(e) => TermsCheckboxHandler(e)} />
                            <label htmlFor="terms"></label>
                          </div>
                          <p className="f-color fs-semi-bold fs-16" > I agree to ClearedTalent’s <span className="termsLink" onClick={goToTerms}> Terms of Use </span><span className='text-cyan'> & </span><span className="termsLink" onClick={goToPrivacyPolicy}>Privacy Policy </span></p>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="continue_container">
                          <button onClick={(e) => updateProfileData(e)} className="continue_button">Start Test</button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="footer_bg_login">
              <div className="container w1200">
                <div className="container_rec">
                  <div className="footer_left_logo homepage-footer-left-logo">
                    <img src={recruiter_header_logo} className="w-100" />
                  </div>
                  <div className="footer-right">
                    <div className='social_links'>
                      <a href='https://www.linkedin.com/company/clearedtalent/'><Tooltip title="LinkedIn"><LinkedIn className='iconColor' /></Tooltip></a>
                      <a href='https://www.facebook.com/Cleared-Talent'><Tooltip title="Facebook"><Facebook className='iconColor' /></Tooltip></a>
                      <a href='https://twitter.com/Cleared_Talent'><Tooltip title="Twitter"><Twitter className='iconColor' /></Tooltip></a>
                      <a href='https://www.instagram.com/clearedtalent/'><Tooltip title="Instagram"><Instagram className='iconColor' /></Tooltip></a>
                    </div>
                    <div className="footer-links">
                      <div>ClearedTalent © {today.getFullYear()}.&nbsp; All Rights Reserved.</div>
                      <div><span className="footerTerms" onClick={goToTerms}>Terms of Use</span>&nbsp;&nbsp;<span className="footerTerms" onClick={goToPrivacyPolicy}>Privacy Policy </span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {isPageDisplay ? (
            <>
              <div className="mainContainer_reg">
                <div className="topContainer">
                  <div className="nav_bg_home home-specific-header">
                    <div className="container w1200 nav_bg_home_section">
                      <div className="nav_bg_left">
                        <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
                          <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                        </li>
                      </div>
                      <div className="nav_bg_right">
                        <ul className="navbar_home">
                          <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className='nav_bg_home_responsive'>
                    <div className="nav_bg_home_responsive-wrapper">
                      <div className="home-top-nav">
                        <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
                          <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                        </li>
                        <div className={hamburgerIsClicked ? "hamburgerIsClicked" : "hamburgerIsNotClicked"} ref={hamburger}>
                          <ul className="navbar_home-responsive">
                            <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                          </ul>
                        </div>
                        <a className="icon" onClick={hamburgerClicked}>
                          <i className="fa fa-bars"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="section-wrapper">
                    <div className="container w1200 flex-to-footer">
                      <div className="height_adjust mt-110">
                        <Container component={Paper} className={classes.testQuestAnsCard}>
                          <p className="fs-semi-bold fs-22 f-color mb_16 text-center">
                            The Invited link is invalid or expired
                          </p>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
      <HomeContactUsModal
        modalOpen={open}
        modalClose={_modalClose}
      />
    </>
  );
};

export default RecruiterSignUp;
