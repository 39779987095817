import axios from "axios";
const jsonConfig = require("../../Config.json");

export const getAllDivision = async () => {
  try {
    const getResponse = await axios.get(
      jsonConfig.apiUrl + "division",
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      });

    return Promise.resolve(getResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};

  export const addDivision = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "division",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getRecruiterDetails = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "getRecruiterDetails",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getAllRecruiterLeader = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "getAllRecruiterLeader",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const getMisOperator = async (id) => {
    try {
      const getResponse = await axios.get(
        jsonConfig.apiUrl + "getAllMisOperatorByOrgId/" + id,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const updateDivision = async (id, data) => {
    try {
      const getResponse = await axios.put(
        jsonConfig.apiUrl + "division/" + id,
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getDivisionByOrg = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "divisionbyorg",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getRecruitmentLeaderbyorg = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "getRecruitmentLeaderbyorg",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  export const getTeamLeadersByRecruitmentLeaderId = async (data) => {
    try {
      const getResponse = await axios.post(
        jsonConfig.apiUrl + "getTeamLeadersByRecruitmentLeaderId",
        data,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        });
  
      return Promise.resolve(getResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };