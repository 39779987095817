import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, OutlinedInput } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DatePicker from "react-datepicker";
import '../requisitionReports.css'
import getAllRequisitionStatus from '../../../apis/getallRequisitionStatus/getAllRequisitionStatus';

const JobReportSearch = ({ allDivisionNames, allClientNames, setIsfilterSearch, setAdditionalFilter, selectedTab, setAdditionalFilter_submission, setAdditionalFilter_workReport, setAdditionalFilter_recruiter_activity, setFilterValues_accessreport }) => {
    const [formValues, setFormValues] = useState({
        divisionId: [],
        clientName: [],
        requisitionStatus: [],
        placementType: [],
        start_date: "",
        offer_release_date: "",
        interview_date: "",
        email: [],
        ipAddress: []
    });
    const [statusNames, setStatusNames] = useState([]);
    const [placementTypeOption, setPlacementTypeOption] = useState([
        { PositionType: "Direct Placement" }, { PositionType: "Right To Hire" }, { PositionType: "Full Time" }, { PositionType: "Contract" }
    ]);


    useEffect(() => {
        setFormValues({
            divisionId: [],
            clientName: [],
            requisitionStatus: [],
            placementType: [],
            start_date: "",
            offer_release_date: "",
            interview_date: "",
            email: [],
            ipAddress: []
        })
    }, [selectedTab])



    useEffect(() => {
        const fetchStatusNames = async () => {
            let response = await getAllRequisitionStatus();
            setStatusNames(response.data.data);
        };
        fetchStatusNames();
    }, []);

    const handleAutocompleteChange = (event, newValue, fieldName) => {

        if (fieldName === "requisitionStatus") {
            const newValueArray = newValue.map(item => item.name);
            setFormValues({
                ...formValues,
                [fieldName]: newValueArray,
            });
        }

        else if (fieldName === "placementType") {
            const newValueArray = newValue.map(item => item.PositionType);
            setFormValues({
                ...formValues,
                [fieldName]: newValueArray,
            });
        } else {
            setFormValues({
                ...formValues,
                [fieldName]: newValue,
            });
        }
    };


    const handleDivisionChange = (event, newValue) => {
        // Extract the _id values from the selected options

        const divisionIds = newValue.map(option => option._id);
        handleAutocompleteChange(event, divisionIds, 'divisionId')


        // const divisionIds = newValue.map(option => option.divisionName);

        // handleAutocompleteChange(event, divisionIds, 'divisionId')

    };

    const handleIPChange = (event) => {
        const { value } = event.target;
        if (value.trim() === "") {

            setFormValues((prevState) => ({
                ...prevState, ipAddress: [],
            }));
            return;

        }

        setFormValues((prevState) => ({
            ...prevState, ipAddress: [value],
        }));

    };


    const handleEmailChange = (event) => {
        const { value } = event.target;
        if (value.trim() === "") {
            setFormValues((prevState) => ({
                ...prevState, email: [],
            }));
            return;

        }

        setFormValues((prevState) => ({
            ...prevState, email: [value],
        }));

    };

    const handleClientNameChange = (event, newValue) => {
        // Extract the _id values from the selected options
        const clientNames = newValue.map(option => option._id);
        handleAutocompleteChange(event, clientNames, 'clientName');
    };

    const handleDateChange = (date, field) => {
        if (date) {
            const formattedDate = date.toISOString().split('T')[0]; // Formats to yyyy-mm-dd
            setFormValues(prevValues => ({
                ...prevValues,
                [field]: formattedDate
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [field]: "" // Clear the date if null
            }));
        }
    };


    const handleSearch = () => {
        setIsfilterSearch(true);
        if (selectedTab === "Job Report") {
            setAdditionalFilter(prevFilter => ({
                ...prevFilter,
                placementType: formValues.placementType,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
                requisitionStatus: formValues.requisitionStatus,
                start_date: formValues.start_date,
                offer_release_date: formValues.offer_release_date,
                interview_date: formValues.interview_date
            }));
        }
        else if (selectedTab === "Submission Report") {
            setAdditionalFilter_submission(prevFilter => ({
                ...prevFilter,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
                start_date: formValues.start_date,
                offer_release_date: formValues.offer_release_date,
                interview_date: formValues.interview_date
            }));
        }
        else if (selectedTab === "Work Report") {
            setAdditionalFilter_workReport(prevFilter => ({
                ...prevFilter,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
                start_date: formValues.start_date,
                offer_release_date: formValues.offer_release_date,
                interview_date: formValues.interview_date
            }))
        }
        else if (selectedTab === "Recruiter's Activity") {
            setAdditionalFilter_recruiter_activity(prevFilter => ({
                ...prevFilter,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId,
            }))
        } else if (selectedTab === "Hiring Report") {
            setAdditionalFilter(prevFilter => ({
                ...prevFilter,
                placementType: formValues.placementType,
                clientName: formValues.clientName,
                divisionId: formValues.divisionId
            }));
        }
        else if (selectedTab === "Access log Report") {
            setFilterValues_accessreport(prevFilter => ({
                ...prevFilter,
                email: formValues.email,
                ipAddress: formValues.ipAddress

            }));
        }

    };


    return (
        <Box className='search_main_Container' >
            <Box className='search_btn_box'>
                <div className='text_top'>
                    <Typography className=''>Showing Results for</Typography>
                    <Typography style={{ color: 'gray', marginTop: "2%" }}>{selectedTab}</Typography>
                </div>

                <div className='search_btn_top'>
                    <button className='search_btn' onClick={handleSearch}>Search</button>
                </div>
            </Box>
            {/* <Box className='current_tab_search_name'>
                <Typography >{selectedTab} Search</Typography>
            </Box> */}

            <Box sx={{ marginTop: "14px", padding: "0px 4px" }} >
                {(selectedTab === "Job Report" || selectedTab === "Hiring Report") && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>
                    {selectedTab !== "Hiring Report" &&

                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Requisition Status
                            </Typography>
                            <Autocomplete
                                multiple
                                options={statusNames}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'requisitionStatus')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Status"
                                    />
                                )}
                            />
                        </div>
                    }
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Placement Type
                        </Typography>
                        <Autocomplete
                            multiple
                            options={placementTypeOption}
                            getOptionLabel={(option) => option.PositionType}
                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, 'placementType')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Placement Type"
                                />
                            )}
                        />
                    </div>
                </form>)}
                {selectedTab === "Submission Report" && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>
                    <div className='createRequisition_datapicker'>
                        <Typography variant="subtitle1" gutterBottom>
                            Start Date
                        </Typography>
                        <DatePicker
                            className="createRequisition_textbox_datapicker_reports_filter"
                            selected={formValues.start_date ? new Date(formValues.start_date) : null}
                            onChange={(date) => handleDateChange(date, 'start_date')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM-DD-YYYY"
                        />
                    </div>
                    <div style={{ marginBottom: "10px", marginTop: "15px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Offer Release Date
                        </Typography>
                        <DatePicker
                            className="createRequisition_textbox_datapicker_reports_filter"
                            selected={formValues.offer_release_date ? new Date(formValues.offer_release_date) : null}
                            onChange={(date) => handleDateChange(date, 'offer_release_date')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM-DD-YYYY"
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Interview Date
                        </Typography>
                        <DatePicker
                            className="createRequisition_textbox_datapicker_reports_filter"
                            selected={formValues.interview_date ? new Date(formValues.interview_date) : null}
                            onChange={(date) => handleDateChange(date, 'interview_date')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM-DD-YYYY"
                        />
                    </div>
                </form>)
                }
                {selectedTab === "Work Report" && (<form>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Division
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allDivisionNames}
                            getOptionLabel={(option) => option.divisionName}
                            onChange={handleDivisionChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Division"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Client Name
                        </Typography>
                        <Autocomplete
                            multiple
                            options={allClientNames}
                            getOptionLabel={(option) => option.clientName}
                            onChange={handleClientNameChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Client"
                                />
                            )}
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Start Date
                        </Typography>
                        <DatePicker
                            className="createRequisition_textbox_datapicker_reports_filter"
                            selected={formValues.start_date ? new Date(formValues.start_date) : null}
                            onChange={(date) => handleDateChange(date, 'start_date')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM-DD-YYYY"
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Offer Release Date
                        </Typography>
                        <DatePicker
                            className="createRequisition_textbox_datapicker_reports_filter"
                            selected={formValues.offer_release_date ? new Date(formValues.offer_release_date) : null}
                            onChange={(date) => handleDateChange(date, 'offer_release_date')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM-DD-YYYY"
                        />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Interview Date
                        </Typography>
                        <DatePicker
                            className="createRequisition_textbox_datapicker_reports_filter"
                            selected={formValues.interview_date ? new Date(formValues.interview_date) : null}
                            onChange={(date) => handleDateChange(date, 'interview_date')}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="MM-DD-YYYY"
                        />
                    </div>
                </form>)
                }
                {
                    selectedTab === "Recruiter's Activity" && (<form>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Division
                            </Typography>
                            <Autocomplete
                                multiple
                                options={allDivisionNames}
                                getOptionLabel={(option) => option.divisionName}
                                onChange={handleDivisionChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Division"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Client Name
                            </Typography>
                            <Autocomplete
                                multiple
                                options={allClientNames}
                                getOptionLabel={(option) => option.clientName}
                                onChange={handleClientNameChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Client"
                                    />
                                )}
                            />
                        </div>
                    </form>)
                }
                {
                    (selectedTab === "Access log Report") && (<form>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Email
                            </Typography>

                            <TextField variant="outlined" placeholder="Enter Email" className='access_search' fullWidth onChange={handleEmailChange} />
                        </div>
                        <div style={{ marginBottom: "10px" }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Ip Address
                            </Typography>
                            <TextField variant="outlined" placeholder="Enter IP Address" className='access_search' fullWidth onChange={handleIPChange} />
                        </div>
                    </form>)
                }
            </Box >
        </Box >
    );
};

export default JobReportSearch;
