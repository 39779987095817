import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function ImageCropper(props) {
  const { imageToCrop, onImageCropped } = props;

  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "%",
      width: 30,
      // aspect: 16 / 9
    }
  );

  const [imageRef, setImageRef] = useState();

  async function cropImage(crop) {
  try {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        "croppedImage.png" // destination filename
      );

      onImageCropped(croppedImage);
    }
    else {
      const getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          }
        });
      }
      getBase64FromUrl(imageToCrop).then((resolve)=>{
        onImageCropped(resolve)

      })
      
    }
  } catch (error) {
    console.log("Error In Async Crop Function-iMAGECropper.js", error)
  }
  }

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(sourceImage.width, sourceImage.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
    
    const safeAreaHeight = 2 * ((maxSize / 2) * Math.sqrt(2))
    const safeAreaWidth = 2 * ((maxSize / 2) * Math.sqrt(2))

    //--- Adding Background Color to Canvas Image -------
    ctx.fillStyle = '#ffffff'
    ctx.fillRect(0, 0,  safeAreaHeight,safeAreaWidth)


    ctx.drawImage(
      sourceImage,
    
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );
    return canvas.toDataURL('image/png');

  }

  return (
    <ReactCrop
      src={imageToCrop }
      crop={cropConfig}
      ruleOfThirds
      onImageLoaded={(imageRef) => setImageRef(imageRef)}
      onComplete={(cropConfig) => cropImage(cropConfig)}
      onChange={(cropConfig) => setCropConfig(cropConfig)}
      crossorigin="anonymous" // to avoid CORS-related problems
    />
  );
}

ImageCropper.defaultProps = {
  onImageCropped: () => {}
};

export default ImageCropper;
