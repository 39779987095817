import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateRecruiterPrivateTestAccessResponse = async (recruiterId, payload) => {
  let allResponse = {};
  try {
    const getallresponse = await axios.put(
      jsonConfig.apiUrl + "RecruiterPrivateTestAccesses/" + recruiterId,
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = getallresponse;
    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("Error", error);
    return Promise.reject(error);
  }
}

export default updateRecruiterPrivateTestAccessResponse;