import axios from "axios";
const jsonConfig = require("../Config.json");



const updateNewSkillResponse = async (skillData) => {
  try {
    const getAllResponse = await axios.put(jsonConfig.apiUrl + "skills/"+skillData.id,
    skillData, 
     {
             headers: {
             "x-functions-key":jsonConfig.key,
            },
          });
     return Promise.resolve(getAllResponse)
    
  } catch (error) {
        return Promise.reject(error)
     }
}
export default updateNewSkillResponse;