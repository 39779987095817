import React, { useState, useEffect } from "react";
import "./otherSkills.css";
import pencil from "./images/pencil.png";
import outlineSvg from './images/outline.svg';
import fillSvg from './images/fill.svg';
import cross from "./images/cross.svg"
import Skeleton from '@material-ui/lab/Skeleton';
import "react-dropdown/style.css";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import backArrow from "./images/backarrow.png";
import nextArrow from "./images/nextarrow.png";
import getTotalYearsExperience from "../../../apis/experience/getTotalYearsOfExp";
import otherMySkillsByProfileIdResponse from "../../../apis/profile/otherMySkillsByProfileId";
import getSkillResponse from "../../../apis/skills/getActiveSkills"
import getMySkillsByIdResponse from "../../../apis/skills/getMySkillsById";
import subSkillBySkillIdResponse from "../../../apis/skills/SubSkillBySkillId";
import generateAdditionalSkillResponse from "../../../apis/skills/generateAdditionalSkill";
import updateMySkillResponse from "../../../apis/skills/updateMySkills";
import deleteMySkills from "../../../apis/skills/deleteMySkills";
import createMySkill from "../../../apis/skills/createMyskill";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  buttonAlign: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 42px 24px 42px !important'
  },
  bodyForOtherSkill: {
    marginTop: "70px"
  },
  buttonAlignForRemove: {
    padding: "32px!important"
  },
  bodyForRemove: {
    padding: "26px 24px 0px 24px!important",
    marginTop: "0px!important"
  },
  swipeablecontainer: {
    width: "99.9%"
  },
  btnpostion: {
    display: "flex",
    padding: "0px!important",
    marginTop: "20px",
    background: "none!important",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end!important",
    width:"157px"

  },
  btnstyle: {
    padding: "0px!important",
    background: "none!important",
    maxWidth: "unset !important"
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(3.5),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const DialogContent = withStyles((theme) => ({
  root: {
  }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogActions);


const OtherSkills = ({ setLastUpdate, userId }) => {
  const classes = useStyles();
  const jsonConfig = require("../../../Config.json");
  const [isOtherSkillAvailable, setIsOtherSkillAvailable] = useState(false);
  const [AllSkillByProfileId, setAllSkillByProfileId] = useState([]);
  const [otherSkillModalEdit, setOtherSkillModalEdit] = useState(false);
  const [allSkillModal, setAllSkillModal] = useState([]);
  const [skillId, setskillId] = useState("");
  const [yearExpId, setyearExpId] = useState(0);
  const [monthExpId, setmonthExpId] = useState(0);
  const [otherSkillName, setotherSkillName] = useState("");
  const [starRating, setstarRating] = useState(0);
  const [otherSkillModalAdd, setOtherSkillModalAdd] = useState(false);
  const [addOtherSkillId, setAddOtherSkillId] = useState("");
  const [addOtherSkillText, setAddOtherSkillText] = useState("");
  const [addOtherSkillDescription, setAddOtherSkillDescription] = useState("");
  const [addOtherSkillStarRating, setAddOtherSkillStarRating] = useState(0);
  const [addOtherSkillYearExpId, setAddOtherSkillYearExpId] = useState(0);
  const [addOtherSkillMonthExpId, setaddOtherSkillMonthExpId] = useState(0);
  const [totalExpYearSelected, setTotalExpYearSelected] = useState("");
  const [editOtherSkillSelectedSkill, setEditOtherSkillSelectedSkill] = useState({});
  const [editModalSelectedDescription, setEditModalSelectedDescription] = useState("");
  const [editableSkillId, seteditableSkillId] = useState("");
  const [openDeleteDialogue, setopenDeleteDialogue] = useState(false);
  const [subSkill, setSubSkill] = useState([]);
  const [allTotalExpYear, setAllTotalExpYear] = useState([]);
  const [totalExpYearSelectedObject, setTotalExpYearSelectedObject] = useState({});
  const [totalExpYearSelectedForEdit, setTotalExpYearSelectedForEdit] = useState("");
  const [test, settest] = useState({})
  const [star, setStar] = useState('');
  const [starToggle, setStarToggle] = useState(true);
  const [loadingController, setLoadingController] = useState(false);
  const [handleSkillarr, setHandleSkillarr] = useState([]);
  const [btnClicked, setBtnClicked] = useState(false);
  const [skillChanging, setSkillChanging] = useState(false);
  const [autoComValue, setAutoComValue] = React.useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = AllSkillByProfileId?.length;
  const theme = useTheme();

  const additionalSkillCardSliderHandler = async (action) => {

    if (action == "increment") {
      handleNext();

    }
    if (action == "decrement") {
      handleBack();

    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getTotalExpYear = async () => {
    try {
      const TotalExpYearresponse = await getTotalYearsExperience();

      setAllTotalExpYear(TotalExpYearresponse);
    } catch (error) {
      console.error(error?.response?.data);
    }
  };

  // Get other skills

  const getOtherSkills = async () => {
    try {
      const getOtherSkillsResponse = await otherMySkillsByProfileIdResponse(userId);
      setAllSkillByProfileId(getOtherSkillsResponse?.data?.data);
      setLastUpdate(getOtherSkillsResponse.data.data[0].profile.lastUpdated)
      if (getOtherSkillsResponse.data != "") {
        setIsOtherSkillAvailable(true);
      }
      setLoadingController(true);
    } catch (error) {
      setIsOtherSkillAvailable(false);
      setLoadingController(true);
    }
  };

  // Create Level
  const levelCreation = (starval) => {
    var level = "";
    if (starval === 1) {
      level = "Novice";
    } else if (starval === 2) {
      level = "Beginner";
    } else if (starval === 3) {
      level = "Competent";
    } else if (starval === 4) {
      level = "Proficient";
    } else {
      level = "Expert";
    }
    return <p className="fs-semi-bold fs-18 f-color mb_3">{level}</p>;
  };

  const starRatingCaluculation = (starRating) => {
    const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
    if (starRating) {
      const arr = starArr.fill(fillSvg, 0, starRating);
      return arr.map((item, i) => (
        <img src={item} alt='item' style={{ marginRight: '5px' }} key={i} />
      ))
    }
    return '';
  }

  const getSkill = async () => {
    try {
      const getallskillresponse = await getSkillResponse();

      let allSkillList = getallskillresponse
      setAllSkillModal(allSkillList);
    }
    catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getSkill();
  }, [])
  // Edit Skill
  const editSkill = async (event, id, name, active, expid, expname, domainid) => {

    setSkillChanging(false);
    setTotalExpYearSelectedObject({ name: expname, id: expid })
    setTotalExpYearSelectedForEdit(expid);
    let arrayDataYears = [];
    for (let i = 0; i <= 20; i++) {
      arrayDataYears.push(i);
    }
    let arrayDataMonths = [];
    for (let i = 0; i <= 11; i++) {
      arrayDataMonths.push(i);
    }
    if (active === false) {

      try {
        const response = await getMySkillsByIdResponse(id);
        fnstarRatingCaluculation(parseInt(response.data.data[0].selfRating));
        setEditOtherSkillSelectedSkill(response.data.data[0].skill)
        seteditableSkillId(response.data.data[0].id);
        setyearExpId(response.data.data[0].experienceYears);
        setotherSkillName(response.data.data[0].skill.name);
        setskillId(response.data.data[0].skillId);
        setmonthExpId(response.data.data[0].experienceMonths);
        setEditModalSelectedDescription(response.data.data[0].description);
        setstarRating(parseInt(response.data.data[0].selfRating));
        setOtherSkillModalEdit(true);
      } catch (err) {
        console.log(err)
      }
    } else {
      try {
        const response = await getMySkillsByIdResponse(id);
        fnstarRatingCaluculation(parseInt(response.data.data[0].selfRating));
        setEditOtherSkillSelectedSkill(response.data.data[0].skill);
        seteditableSkillId(response.data.data[0].id);
        setskillId(response.data.data[0].skillId);
        setyearExpId(response.data.data[0].experienceYears);
        setmonthExpId(response.data.data[0].experienceMonths);
        setEditModalSelectedDescription(response.data.data[0].description);
        setstarRating(parseInt(response.data.data[0].selfRating));
        setOtherSkillModalEdit(true);

      } catch (err) {
        console.log(err);
      }
    }
  };

  // Skill change handler
  const skillChangeHandler = async (value) => {
    setSkillChanging(true);
    setSubSkill([])
    if (value == null) {
      setskillId('')
    }
    let original = {}
    if (value) {
      if (value?.title) {
        original = { ...original, name: value.name, id: 'other' }
      } else {
        original = { ...value }
      }
      setskillId(value.id);
      setskillId(original.id)
      setotherSkillName(original.name);
      try {
        const getAllSubSkills = await subSkillBySkillIdResponse(value.id);
        setSubSkill(getAllSubSkills.data.data);


      } catch (error) {
        console.error("subskillerror", error);
        setSubSkill([])
      }
    }
  };

  const selectTotalExpHandlerForEdit = (e, value) => {
    if (value == null) {
      setTotalExpYearSelectedForEdit('')
    }
    else if (value) {
      setTotalExpYearSelectedForEdit(value.id)
    }

  }

  const starClickedForEdit = (clickIndex) => {
    setStarToggle(prev => !prev);
    const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
    if (starToggle && clickIndex) {
      const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
      setStar(arr);
      setstarRating(clickIndex + 1);
    }
    else {
      const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
      setStar(arr);
      setstarRating(clickIndex + 1);
    }
  }
  const updateOtherSkill = async () => {
    if (skillId === "") {
      NotificationManager.error("Please select Skill", "Error");
      return;
    };
    if (totalExpYearSelectedForEdit === "") {
      NotificationManager.error(
        "Please select your years of experience", "Error"
      );
      return;
    }
    if (starRating === 0) {
      NotificationManager.error("Please provide a rating ", "Error");
      return;
    }
    else {
      if (skillId === "other") {
        var addSkill = {
          name: otherSkillName,
          isPrimary: false,
          isActive: false,
          createdBy: userId,
          updatedBy: userId,
        };
        const subJsonArr = subSkill.map(item => {
          const subJson = {
            id: item.subSkillId,
            name: item.name
          }
          return subJson
        });
        try {
          const response = await generateAdditionalSkillResponse(addSkill);
          var skillData = {
            experienceYears: yearExpId,
            experienceMonths: monthExpId,
            skillId: response.data?.data[0].id,
            profileId: userId,
            description: editModalSelectedDescription,
            totalExperienceId: totalExpYearSelectedForEdit,
            selfRating: starRating,
            subSkillsJson: skillChanging ? subJsonArr : (skillChanging && btnClicked) ? handleSkillarr : handleSkillarr,
            isPrimary: false,
            createdBy: userId,
            updatedBy: userId,
          };
          try {
            const payload = {
              id: editableSkillId,
              data: skillData,
            }
            const response = await updateMySkillResponse(payload);
            setOtherSkillModalEdit(false);
            setskillId("");
            setyearExpId(0);
            setmonthExpId(0);
            setstarRating(0)
            setEditModalSelectedDescription("");
            setTotalExpYearSelectedForEdit('')
            NotificationManager.success("Skill updated", "Success");
            getOtherSkills();
          } catch (err) {
            NotificationManager.error("Skill updation failed", "Error")
          }
        } catch (err) {
          console.log(err)
        }
      }

      else {
        const subJsonArr = subSkill.map(item => {
          const subJson = {
            id: item.subSkillId,
            name: item.name
          }
          return subJson
        });
        var skillData = {
          experienceYears: yearExpId,
          experienceMonths: monthExpId,
          skillId: skillId,
          profileId: userId,
          description: editModalSelectedDescription,
          totalExperienceId: totalExpYearSelectedForEdit,
          selfRating: starRating,
          subSkillsJson: skillChanging ? subJsonArr : (skillChanging && btnClicked) ? handleSkillarr : handleSkillarr,
          isPrimary: false,
          createdBy: userId,
          updatedBy: userId,
        };
        try {
          const payload = {
            id: editableSkillId,
            data: skillData,
          }
          const response = await updateMySkillResponse(payload);
          setOtherSkillModalEdit(false);
          setskillId("");
          setyearExpId(0);
          setmonthExpId(0);
          setstarRating(0)
          setEditModalSelectedDescription("");
          setTotalExpYearSelectedForEdit('')
          NotificationManager.success(
            "Skill updated",
            "Success"
          );
          getOtherSkills();
        } catch (err) {
          console.log(err);
          NotificationManager.error("Skill updation failed", "Error");
        }
      }
    }
  };
  const closeEditModal = () => {
    setOtherSkillModalEdit(false);
    setSubSkill([]);
  }
  // for delete
  const deleteOtherskill = async () => {
    try {
      const response = await deleteMySkills(editableSkillId);
      setOtherSkillModalEdit(false);
      NotificationManager.success('Skill deleted', 'Success');
      getOtherSkills();
      setopenDeleteDialogue(false);
    } catch (err) {
      NotificationManager.error('Skill deletetion failed', 'Error');
      console.log(err);
    }
  }

  const openAddanotherSkillModal = (event) => {
    setOtherSkillModalAdd(true);
    setSkillChanging(false);
    fnstarRatingCaluculation();
    setSubSkill([]);
  }
  const selectTotalExpHandler = (event, value) => {
    setTotalExpYearSelected("");
    if (value === null) {
      setTotalExpYearSelected('')
    }
    else if (value) {
      setTotalExpYearSelected(value.id)
    }

  }

  const addOthersSkill = async () => {
    if (addOtherSkillId === "") {
      NotificationManager.error("Please select your skill", "Error");
      return;
    }
    if (totalExpYearSelected === "") {
      NotificationManager.error(
        "Please select your years of experience", "Error"
      );
      return;
    }
    if (addOtherSkillStarRating === 0) {
      NotificationManager.error("Please provide a rating ", "Error");
      return;
    }
    else {
      if (addOtherSkillId === "other") {
        var addSkill = {
          name: addOtherSkillText,
          isPrimary: false,
          isActive: false,
          createdBy: userId,
          updatedBy: userId,
        };
        try {
          const response = await generateAdditionalSkillResponse(addSkill);
          var skillData = {
            experienceYears: addOtherSkillYearExpId,
            experienceMonths: addOtherSkillMonthExpId,
            skillId: response.data?.data[0].id,
            profileId: userId,
            description: addOtherSkillDescription,
            selfRating: addOtherSkillStarRating,
            totalExperienceId: totalExpYearSelected,
            isPrimary: false,
            createdBy: userId,
            updatedBy: userId,
          };
          try {
            const response = await createMySkill(skillData);
            setOtherSkillModalAdd(false);
            setAddOtherSkillId("");
            setAddOtherSkillYearExpId(0);
            setaddOtherSkillMonthExpId(0);
            setAddOtherSkillStarRating(0);
            setAddOtherSkillDescription("");
            NotificationManager.success(
              "Skill added",
              "Success"
            );
            getOtherSkills();
          } catch (err) {
            NotificationManager.error(
              "The selected skill is already added in your profile",
              "Error"
            );
          }
        } catch (err) {
          console.log(err);
        }
      } else {

        var skillData = {
          experienceYears: addOtherSkillYearExpId,
          experienceMonths: addOtherSkillMonthExpId,
          skillId: addOtherSkillId,
          profileId: userId,
          description: addOtherSkillDescription,
          selfRating: addOtherSkillStarRating,
          totalExperienceId: totalExpYearSelected,
          isPrimary: false,
          createdBy: userId,
          updatedBy: userId,
        };
        try {
          const response = await createMySkill(skillData);
          setOtherSkillModalAdd(false);
          setAddOtherSkillId("");
          setAddOtherSkillYearExpId(0);
          setaddOtherSkillMonthExpId(0);
          setAddOtherSkillDescription("");
          setAddOtherSkillStarRating(0);
          NotificationManager.success(
            "Skill added",
            "Success"
          );
          getOtherSkills();
        } catch (err) {
          NotificationManager.error(
            "The selected skill is already added in your profile",
            "Error"
          );
        }
      }
    }
  };
  const closeAddModal = () => {
    setOtherSkillModalAdd(false);
    setSubSkill([]);
  }

  const fnstarRatingCaluculation = (rating) => {
    const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
    if (rating) {
      const arr = starArr.fill(fillSvg, 0, rating);
      setStar(arr);
    } else {
      setStar(starArr);
    }
  }

  const starClicked = (clickIndex) => {
    setStarToggle(prev => !prev);
    const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
    if (starToggle && clickIndex) {
      const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
      setStar(arr);
      // setstarRating(clickIndex + 1);
      setAddOtherSkillStarRating(clickIndex + 1);
    }
    else {
      const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
      setStar(arr);
      // setstarRating(clickIndex + 1);
      setAddOtherSkillStarRating(clickIndex + 1);
    }
  }

  const viewhandler = (e, show) => {
    alert(show)
    var a = e.target.id
    settest({ [a]: show })
  }
  const onSkillChange = async (event, value) => {
    setSkillChanging(true);
    let original = {}
    if (value?.title) {
      original = { ...original, name: value.name, id: 'other' }
    } else {
      original = { ...value }
    }
    setAddOtherSkillId(original.id)
    setAddOtherSkillText(original.name);
    try {
      const getAllSubSkills = await subSkillBySkillIdResponse(original.id);
      setSubSkill(getAllSubSkills?.data?.data);
    } catch (error) {
      console.error("subskillerror", error)
      setSubSkill([])
    }
  }

  // Use Effect
  useEffect(() => {
    getOtherSkills();
    getTotalExpYear();
    // });
  }, [userId]);
  return (
    <>
      {
        loadingController ?
          <>
            <p className="mn_normal fs-25 f-color ">Additional Skills</p>
            {isOtherSkillAvailable ? (
              <>
                {AllSkillByProfileId
                  ? (
                    <>
                      <AutoPlaySwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        className={classes.swipeablecontainer}

                      // onChangeIndex={handleStepChange}
                      // enableMouseEvents
                      >

                        {AllSkillByProfileId?.map((value, index) => (
                          <div key={index}>
                            {Math.abs(activeStep - index) <= 2 ? (
                              // <img className={classes.img} src={step.imgPath} alt={step.label} />
                              <div className="individual-skills" key={index}>
                                <div className="df totalexp">
                                  <div>
                                    <p className="fs-semi-bold fs-18 f-color mb_3 text-upper">
                                      {value.skill.name}

                                      <span className="ml_12 cp edit_left">
                                        <img
                                          src={pencil}
                                          alt=""
                                          onClick={(e) =>
                                            editSkill(
                                              e,
                                              value.id,
                                              value.skill.name,
                                              value.skill.isActive,
                                              // value.subskills,
                                              value.totalexperienceid,
                                              value.totalExperience.name,
                                              // value.domain.id,
                                              value.selfRating
                                            )
                                          }
                                        />
                                      </span>
                                    </p>
                                    <p className="fs-semi-bold fs-12 f-color mb_3">
                                      {value?.totalExperience?.name}
                                    </p>

                                  </div>
                                </div>
                                <div className="df">
                                  <div className="myskill-right">
                                    <p className="fs-regular fs-18 mb_3">
                                      Self Assessment
                                    </p>
                                    <div className="rating">
                                      {starRatingCaluculation(parseInt(value.selfRating))}
                                    </div>
                                    {levelCreation(parseInt(value.selfRating))}
                                  </div>
                                </div>
                              </div>

                            ) : null}
                          </div>
                        ))}
                      </AutoPlaySwipeableViews>
                      <div className="fle">
                        <div>
                          <MobileStepper
                            steps={maxSteps}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            className={classes.btnpostion}
                            
                            nextButton={
                              <Button className={classes.btnstyle} size="small" onClick={() => additionalSkillCardSliderHandler("increment")} disabled={activeStep === maxSteps - 1}>

                                {theme.direction === 'rtl' ? <img className="cp" src={backArrow} /> : <img className="cp" src={nextArrow} />}
                              </Button>
                            }
                            backButton={
                              <Button className={classes.btnstyle} size="small" onClick={() => additionalSkillCardSliderHandler("decrement")} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <img className="cp" src={nextArrow} /> : <img className="cp" src={backArrow} />}
                              </Button>
                            }

                          />
                        </div>
                        <div className="row add_another_btn_adjust">
                          <button
                            type="button" 
                            className="btn btn-skill cp"
                            onClick={openAddanotherSkillModal}
                          >
                            Add Another Skill
                          </button>
                        </div>
                      </div>

                    </>
                  )
                  : null}
              </>
            ) : (
              <>
              <p className="fs-regular fs-16 mt_12">No additional skills available</p>
              <div className="row add_another_btn_adjust">
              <button
                type="button"
                className="btn btn-skill cp mt_50"
                onClick={openAddanotherSkillModal}
              >
                Add Another Skill
              </button>
            </div>
            </>
 )} 

            {/* Additional skill edit starts */}
            <Dialog
              open={otherSkillModalEdit}
              onClose={closeEditModal}
              aria-labelledby="customized-dialog-title"
              maxWidth="sm"
              fullWidth={true}
            >
              {/* <DialogTitle onClose={closeEditModal} /> */}
              <div className="crossFormodal cp" onClick={closeEditModal}><img className="closeicon_set" src={cross} alt=""></img></div>
              <DialogContent className={`${classes.bodyForOtherSkill} responsive-body-primaryskill`}>
                <div className="select customized-select">
                  <legend>Skill*</legend>
                </div>
                <Autocomplete
                  defaultValue={editOtherSkillSelectedSkill}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  // onInputChange ={onSkillChange}
                  onChange={(event, newValue) => skillChangeHandler(newValue)}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        name: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  id="free-solo-with-text-demo"
                  options={allSkillModal}
                  renderOption={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Skill" variant="outlined" />
                  )}
                />

                <div className="select customized-select mt_12">
                  <legend>Skill Experience*</legend>
                  <Autocomplete
                    id="combo-box-demo"
                    options={allTotalExpYear}
                    defaultValue={totalExpYearSelectedObject}
                    getOptionLabel={(option) => option.name}
                    onChange={selectTotalExpHandlerForEdit}
                    renderInput={(params) => <TextField {...params} placeholder="Select Skill Experience" variant="outlined" />}
                  />
                </div>

                <div className="mt_16">
                  <legend>Self Assessment*</legend>
                  <div className="rating_skill">
                    {star && star.map((image, index) => (
                      <img src={image} alt="starImage" key={index} onClick={() => starClickedForEdit(index)} />
                    ))
                    }

                  </div>
                </div>
              </DialogContent>
              <div className="divider_adjust">
                <hr className="new3 mt_20 mb_0" />
              </div>
              <DialogActions className={`${classes.buttonAlign} responsive-dialog`}>
                <button
                  type="button"
                  className="btn-big btn-skill cp"
                  onClick={() => setopenDeleteDialogue(true)}
                >
                  Delete
                </button>

                <button
                  type="button"
                  className="btn-big btn-skill cp ml_5"
                  onClick={closeEditModal}
                >
                  Cancel
                </button>
                <button
                  className="btn-big btn_powres ml_5 cp"

                  onClick={updateOtherSkill}
                >
                  Save
                </button>
              </DialogActions>
            </Dialog>

            {/* Additional skill edit ends */}


            {/*           for add otherskil                      */}


            <Dialog
              open={otherSkillModalAdd}
              onClose={closeAddModal}
              aria-labelledby="customized-dialog-title"
              maxWidth="sm"
              fullWidth={true}
            >
              {/* <DialogTitle onClose={closeAddModal} /> */}
              <div className="crossFormodal cp" onClick={closeAddModal}><img className="closeicon_set" src={cross} alt=""></img></div>

              <DialogContent className={`${classes.bodyForOtherSkill} responsive-body-primaryskill`}>

                <legend>Skill*</legend>
                <Autocomplete
                  defaultValue={autoComValue}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                  }}
                  // onInputChange ={onSkillChange}
                  onChange={onSkillChange}
                  filterSelectedOptions
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        name: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  id="free-solo-with-text-demo"
                  options={allSkillModal}
                  renderOption={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Skill" variant="outlined" />
                  )}
                />

                <div className="select customized-select mt_12">
                  <legend>Skill Experience*</legend>
                  <Autocomplete
                    id="combo-box-demo"
                    options={allTotalExpYear}
                    getOptionLabel={(option) => option.name}
                    onChange={selectTotalExpHandler}
                    renderInput={(params) => <TextField {...params} placeholder="Select Skill Experience" variant="outlined" />}
                  />
                </div>

                <div className="mt_16">
                  <legend>Self Assessment*</legend>
                  <div className="rating_skill">
                    {star && star.map((image, index) => (
                      <img src={image} alt="starImage" key={index} onClick={() => starClicked(index)} />
                    ))
                    }
                  </div>
                </div>
              </DialogContent>
              <div className="divider_adjust">
                <hr className="new3 mt_20 mb_0" />
              </div>
              <DialogActions className={`${classes.buttonAlign} responsive-dialog`}>
                <button
                  type="button"
                  className="btn-big btn-skill cp ml_5"
                  onClick={closeAddModal}
                >
                  Cancel
                </button>
                <button
                  className="btn-big btn_powres ml_5 cp"
                  onClick={addOthersSkill}
                >
                  Save
                </button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDeleteDialogue}
              onClose={() => setopenDeleteDialogue(false)}
              aria-labelledby="customized-dialog-title"
            >
              <DialogContent className={`${classes.bodyForRemove} removemodal_responsive`}>
                <div className="text-center fs-semi-bold f-color fs-18">Are you sure want to delete ?</div>
              </DialogContent>
              <DialogActions className={`${classes.buttonAlignForRemove} responsiveRemove_Modal_button`}>
                <div className="remove_modal" >
                  <button type="button" className="btn_remove btn-skill cp" onClick={() => setopenDeleteDialogue(false)}>No</button>
                  <button type="button" className="btn2 btn_powres ml_5 cp" onClick={deleteOtherskill}>Yes</button>
                </div>
              </DialogActions>
            </Dialog>

            <NotificationContainer />
          </>
          :
          <>
            <div>
              <Skeleton variant="text" animation="wave" />
              <Skeleton variant="rect" animation="wave" height={100} className='mt_16' />
            </div>
          </>
      }
    </>
  );
};
export default OtherSkills;
