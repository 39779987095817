import axios from "axios";
const jsonConfig = require("../../Config.json");

const emailConfigurationByCodeResponse = async (codeForMail) => {   
    let allResponse = {}
    try {
      const  emailResponse = await axios.post(
        jsonConfig.apiUrl + "emailconfigurationByCode",codeForMail,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              }
            }
          );
        
        allResponse = emailResponse;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error);     
        return Promise.reject(error);
    }
}

export default emailConfigurationByCodeResponse;