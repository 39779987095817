import React, { useState, useEffect, useCallback, useRef, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import upload from "./images/upload.png";
import { FilePicker } from "react-file-picker";
import axios from "axios";
import "./newCandidateProfile.css";
import { CenterFocusStrong, Close } from "@material-ui/icons";
import { Button, Menu } from "@material-ui/core";
import pdfimage from "../../../components/basicInfoForm/images/pdfNew.svg";
import docimage from "../../../components/basicInfoForm/images/doc.png";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { BlobServiceClient } from '@azure/storage-blob';
import { DateTime } from "luxon";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { UserTypeContext } from "../../../AppRoute";
import countNoOfFilesParsed from "../../../apis/parsing/countNoOfFilesParsed";
// import CircularProgress from '@mui/joy/CircularProgress';


const useStyles = makeStyles({

  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tyreWrapper: {
    position: 'relative',
    width: '80vw',
    height: '80vw',
    maxWidth: '80vh',
    maxHeight: '80vh',
    // backgroundColor: 'black',
    borderRadius: '50%',
    padding: '20px',
    boxSizing: 'border-box',
  },
  percentage: {
    position: 'absolute',
    top: '48%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1vw',
    color: 'white',
    zIndex: 10,
  },
  loader: {
    position: 'absolute',
    top: '34%',
    left: '30%',
    transform: 'translate(-50%, -50%)',
    // color: 'white',  
  },
});

const crypto = require('crypto'); //crypto module for hashing

const NewCandidateProfile = (loadingController, props) => {
  const classes = useStyles();
  const jsonConfig = require("../../../Config.json");
  const { userType, userIdType, userRecruitmentpartnerId } = useContext(UserTypeContext);;
  const [selectedFile, setSelectedFile] = useState({});

  /*blob storage */
  const [errorMessage, setErrorMessage] = useState({ text: '', visible: false });
  const [progress, setProgress] = useState(0)
  const { id } = props;
  const location = useLocation();
  const idContextTest = id ? id : location.state ? location.state.id : "";
  const [notificationType, setNotifiactionType] = useState("");
  const [notificationText, setNotifcationText] = useState("")
  const uploadedFileParseCount = useRef(0);
  const [progressOpen, setprogressOpen] = useState(false)
  const handleFileSelect = (file) => {
    setSelectedFile(file);
  }

  const fileExtension = () => {
    let extension = selectedFile?.name.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return <img src={pdfimage} alt="PDF Icon" className="new_candidate_icon" />
    } else if (extension === 'doc' || extension === 'docx') {
      return <img src={docimage} alt="DOC Icon" className="new_candidate_icon" />
    }
  }

  function create_UUID() {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt >>>= 4; // unsigned right shift to ensure a 32-bit integer
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }
  function hash_UUID(uuid) {
    const sha256 = crypto.createHash('sha256');
    sha256.update(uuid);
    return sha256.digest('hex');
  }


  const delayedFunction = async (filnameWithtimearr) => {

    const response = await countNoOfFilesParsed(filnameWithtimearr);

    if (response.status === 200) {

      uploadedFileParseCount.current = response.data.count;
    }
  }

  const uploadResumeToBlobStorage = async (blob) => {
    setprogressOpen(true)
    if (blob.length === 0) {
      setErrorMessage({ text: 'Select a file first.', visible: true });
      setTimeout(() => {
        setErrorMessage({ text: '', visible: false });
      }, 5000);
      return;
    }
    let filnameWithtimearr = []
    try {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);

      const sas = decodeURI(jsonConfig.sasResumeToken);
      const date = DateTime.now();
      const currentDate = date.toFormat("yyyy-MM-dd");
      const formattedDate = date.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ");

      const bsClient = new BlobServiceClient(
        `https://${jsonConfig.blobStorageResumeAccountName}.blob.core.windows.net${sas}`
      );

      const containerClient = bsClient.getContainerClient("staging");

      var v = blob.name.split(".");
      let uuid = create_UUID();
      let hashUuid = hash_UUID(uuid);
      let blobName = currentDate + "/" + userIdType + "/" + hashUuid + "." + v[v.length - 1];
      const blobClient = containerClient.getBlockBlobClient(blobName);

      const blobUploadOptions = {
        blobHTTPHeaders: {
          blobContentType: blob.type,
        },
        metadata: {
          "createdtime": formattedDate,
          "fullname": blob.name,
          "uploadedtype": "Recruiter Upload",
          "organizationid": userRecruitmentpartnerId,
          "blobfilename": blobName

        },
      };


      filnameWithtimearr.push({ "blobfilename": blobName, "createdtime": formattedDate, "organizationid": userRecruitmentpartnerId,"userRecruitmentpartnerId":userRecruitmentpartnerId,"userId":id})


      const response = await blobClient.uploadBrowserData(blob, blobUploadOptions);

      const intervalID = setInterval(() => {
        if (stopCondition(filnameWithtimearr)) {
          delayedFunction(filnameWithtimearr);
        } else {
          clearInterval(intervalID);
          setprogressOpen(false)

          window.location.reload();
        }
      }, filnameWithtimearr.length * 5000);

      setSelectedFile({})
    } catch (error) {
      setNotifiactionType('error')
      setNotifcationText('Resume upload failed')
      setSelectedFile({})
      console.error("An error occurred during upload:", error);
    }
  };

  const stopCondition = (filnameWithtimearr) => {
    if (uploadedFileParseCount.current === filnameWithtimearr.length) {
      return false
    } else {
      return true
    }
  }





  const handleclose = () => {
    setSelectedFile("")
  }
  return (
    <>
      {loadingController ? (
        <>
          {/* {progressOpen && (
            <div className={classes.backdrop}>
              <div className={classes.tyreWrapper}>
                <CircularProgress determinate size="lg" value={progress} sx={{
                  "--CircularProgress-size": "50%",
                  "--CircularProgress-trackThickness": "40px",
                  "--CircularProgress-progressThickness": "40px",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  height: '100%',
                  fontSize: '20px',  // Adjust this to control the size
                  color: "white"
                }} >
                  Uploading Resume...
                </CircularProgress>
              </div>
            </div>
          )} */}
          {!selectedFile.name && (userType === "Recruiter" || userType === "Admin") ?
            <div className="uploadFuctionContainer">
              <div className="mb_10">
                <img
                  src={upload}
                  alt=""
                  width="88.08"
                  className=""
                />
              </div>
              <div className="fileUploadText">
                Drag and drop a file here
              </div>
              <div className="fileUploadText">
                or
              </div>
              <div className="mb_10">
                <FilePicker
                  extensions={['pdf', 'doc', 'docx']}
                  onChange={handleFileSelect}
                >
                  <Button
                    className={userType === "Admin" ? "candidate_filepicker_button_disable" :"candidate_filepicker_button"}
                    disabled = {userType === "Admin"}
                  >Choose File</Button>
                </FilePicker>

              </div>
              <div className="cdProfileHeading_newProfile">
                Candidate Profile
              </div>
            </div>
            : <>
              <div className="uploadFuctionContainer_conditional">
                <div className="uploadFuctionCon">
                  <CancelTwoToneIcon onClick={(e) => handleclose()} className="close_icon_newCandidate" />
                  <div className="candidateFileExtention">{fileExtension()}</div>
                </div>
                <div className="mb_10">
                  <Button
                    className="candidate_filepicker_button"
                    onClick={(e) => uploadResumeToBlobStorage(selectedFile)}
                  >
                    upload Resume

                  </Button>

                </div>
                <div className="cdProfileHeading_newProfile">
                  Candidate Profile
                </div>
              </div>
            </>}
        </>
      ) : (
        <>gggggg</>
      )}
    </>
  );
};

export default NewCandidateProfile;