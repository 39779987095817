import axios from "axios";
const jsonConfig = require("../../Config.json");

const getCompletedUserTestByUserId = async (userId) => {

    let allResponse = {};
    try {
        const getallresponse = await axios.get(
            jsonConfig.apiUrl + "getCompletedUserTest/" + userId,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = getallresponse;
        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr", error);
        return Promise.reject(error);
    }
}

export default getCompletedUserTestByUserId;