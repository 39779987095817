import axios from "axios";
const jsonConfig = require("../../src/Config.json");

const organizationLogoUpdateResponse = async (payload) => {
  let allResponse = {}
  try {
    const response = await axios.put(
      jsonConfig.apiUrl + "uploadrecruitmentpartnerlogo/" + payload.userId,
      payload.orgImagedata,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allResponse = response;
    return Promise.resolve(allResponse)
  }
  catch (error) {   
    return Promise.reject(error)
  }
}

export default organizationLogoUpdateResponse;