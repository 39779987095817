import axios from "axios";
const jsonConfig = require("../../Config.json");

const getSubSkillByTestId = async (id) => {
    let allResponse = {};
    try {
      const getallresponse = await axios.get(
        jsonConfig.apiUrl + "TestSubskillsMapping/" + id,
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );
  
      allResponse = getallresponse;
      return Promise.resolve(allResponse);
    } catch (error) {
      console.log("Error in test subskill mapping", error);
      return Promise.reject(error);
    }
}

export default getSubSkillByTestId;