import { useState, useEffect } from 'react'
import './RecentActivities.css';
import countallstatus from "../../apis/countallstatusforrequitionforcandidates/countallstatusforrequitionforcandidates";
import { NotificationContainer } from "react-notifications";
import { useHistory } from 'react-router-dom';
import { candidateRequisitionStatusCode} from "../../Config.json"
import {  camelCase, codeTofield } from '../GlobalAssistingFunctions/GlobalAssistingFunctions';


function RecentActivities(props) {

    const { requisitionId, userType, idContextTest, userRecruitmentpartnerId, userRole, userRecords, setCurStatus, page,activCount, setactivCount } = props
    const [allCandidate, setallCandidate] = useState(true);

    const history = useHistory();

    useEffect(() => {
        getEmailOptions();
    }, []);

    // For getting list of email ids
    const getEmailOptions = async () => {

        let user = userType === 'Admin' ? userRole : userType, id = idContextTest

        if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
            user = userType === 'Admin' ? userRole : userType
            id = userRecruitmentpartnerId
        }

        let fields = {
            "MIS Operator": "MISId",
            "Recruitment Leader": "RecruitmentLeaderId",
            "Team Leader": "teamLeader",
            "Recruiter": "recruiterId",
            "Organization Admin": "orgId"
        }

        let payload_data = {
            "requisitionId": requisitionId,
            recruitmentpartnerId: userRecruitmentpartnerId,
            [fields[user]]: id,
        }

        let allCount = await countallstatus(payload_data)
        //   if(userType!=="Team Leader")

        let data = allCount?.data?.statusCounts || {}
        let count = {}
        Object.keys(candidateRequisitionStatusCode)?.forEach((elem, i) => {
            let codeKey = candidateRequisitionStatusCode[elem];
            let statusKey = codeTofield(codeKey)

            count[codeKey] = data[statusKey]?.count || 0
        })
        setactivCount(count || {})

    };

    const reqClick = (status) => {
        setallCandidate(true);
        
       
            history.push({
                pathname: `/activity/${requisitionId}`,
                state: { candidateState: status, requisitionId: requisitionId, status, userType: userType, userRole: userRole, userRecruitmentpartnerId: userRecruitmentpartnerId, userRecords: userRecords, id: idContextTest, activCount: activCount }
            });
            
            if (page !== "req")
             setCurStatus(status)
    }

    return (
        <div className='activities_cont' >
            <div className='recentActivities_Container' >
                <div className='recentActivities_heading'>
                    Requisition Activities
                </div>
                {
                    Object.keys(candidateRequisitionStatusCode)?.map((elem, i) => {
                        let statusCode=candidateRequisitionStatusCode[elem]
                        return <div key={"recentActivitiesCount" + i} className='recentActivities_status' onClick={() => { reqClick(statusCode) }}>
                            <div className='recentActivities_statusHead'>{camelCase(elem)}</div>
                            <div className='recentActivities_statusValue'>
                            {activCount[statusCode]}
                            </div>
                        </div>
                    })
                }



                <div className='recentActivities_status_bottom'>
                    {allCandidate &&
                        <div className='recentActivities_ViewAllCandidates' onClick={()=>reqClick("all")}>View All Candidates</div>
                    }
                </div>
            </div>
            <NotificationContainer />
        </div>
    )
}

export default RecentActivities