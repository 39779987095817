import axios from "axios";
const jsonConfig = require("../../Config.json");



const getMisOperatorByOrgId = async (orgId) => {
    try {
        const getAllResponse = await axios.get(
            jsonConfig.apiUrl + `getMisOperatorByOrgId/${orgId}`,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        return Promise.resolve(getAllResponse);
    } catch (error) {
        return Promise.reject(error);
    }
};
export default getMisOperatorByOrgId;