import { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions, Grid, TextField, Button, Typography, FormControl, CircularProgress, Chip } from "@material-ui/core";
import department from './icons/department.png';
import './manageClient.css';
import { Autocomplete } from "@material-ui/lab";
import ManageDivisionTable from "../../components/manageDivisionTable/manageDivisionTable.js";
import Header from "../../components/headerAdmin/Header";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import checkRequisitionExists from "../../apis/checkRequisitionExists/checkRequisitionExists";

import {
    addDivision,
    updateDivision,
    getDivisionByOrg,
    getRecruitmentLeaderbyorg,
    getTeamLeadersByRecruitmentLeaderId
}
    from "../../apis/organisationAdminAPI/manageDivision";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import BasicHeader from "../../components/basicHeader/BasicHeader";
import { AddCircle, Cancel } from "@material-ui/icons";
import { UserTypeContext } from "../../AppRoute";
import "./managedivision.css";

const ManageDivision = (props) => {
    const { userType, userRecords } = useContext(UserTypeContext);
    const idContextTest = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
    const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
    const userRole = userType === 'Admin' ? props?.location?.state?.userType : userRecords?.role?.roleName;
    const userRecrodData = userType === 'Admin' ? props?.location?.state?.userRecords : userRecords;
    const history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [divisionData, setDivisionData] = useState([]);
    const [recruitmentLeaderOptions, setRecruitmentLeaderOptions] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [editedRow, setEditedRow] = useState({});
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)
    const [teamLeaderOptions, setTeamLeaderOptions] = useState([]);
    const [activeDropDown, setActiveDropDown] = useState([
        { name: "True", value: true },
        { name: "False", value: false },
    ]);
    const [isActiveFieldDisabled, setIsActiveFieldDisabled] = useState(true)
    const [divisionInfo, setDivisionInfo] = useState({
        divisionName: "",
        active: { name: "True", value: true },
    })
    const [leadersArr, setLeadersArr] = useState([
        {
            selectedRecruitmentLeader: {},
            selectedTeamLeaders: [],
            leaderOptionFetching: false
        },
    ])
    const [exisitingRequisition, setExisitingRequisition] = useState([])

    const getRecruitmentLeadersData = async () => {
        try {
            let payload = {
                recruitmentpartnerId: userRecruitmentpartnerId,
                manageDivision: true,
            }
            let response = await getRecruitmentLeaderbyorg(payload);
            if (response.status === 200) {
                setRecruitmentLeaderOptions(response.data.data);
            }
            else {
                NotificationManager.error("Unable to retrieve recruitment leader data. Please try again later.", "Error", 2500);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleAddDivision = async () => {
        setEditedRow({});
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setEditedRow({});
        setDivisionInfo({
            divisionName: "",
            active: { name: "True", value: true }
        })
        setLeadersArr([
            {
                selectedRecruitmentLeader: {},
                selectedTeamLeaders: [],
                leaderOptionFetching: false
            },
        ])
        setIsActiveFieldDisabled(true);
        setExisitingRequisition([])
    };


    const handleSubmit = async () => {
        if (divisionInfo.divisionName === "" ||
            leadersArr.some(item => !item.selectedRecruitmentLeader.id) ||
            leadersArr.some(item => item.selectedTeamLeaders.length === 0) ||
            divisionInfo.active === "") {
            return NotificationManager.error(
                "Please fill in all the fields",
                "Error",
                2500
            );
        }
        else {
            setIsUserDataUpdating(true);
            let teams = [];
            for (let i = 0; i < leadersArr.length; i++) {
                let item = leadersArr[i];
                let teamLeaderIds = [];
                for (let j = 0; j < item.selectedTeamLeaders.length; j++) {
                    let teamLeader = item.selectedTeamLeaders[j];
                    teamLeaderIds.push(teamLeader.id);
                }
                teams.push({
                    recruiterLeaderId: item.selectedRecruitmentLeader.id,
                    teamLeaderId: teamLeaderIds
                });
            }

            let payload = {
                divisionName: divisionInfo.divisionName,
                recruitmentpartnerId: userRecruitmentpartnerId,
                teams: teams,
                isActive: divisionInfo.active.value,
                updatedBy: idContextTest
            }
            if (Object.keys(editedRow).length > 0) {
                try {
                    let response = await updateDivision(editedRow._id, payload);
                    if (response.status === 200) {
                        NotificationManager.success("Division details updated successfully", "Success", 2500);
                        handleCloseDialog();
                        getRecruitmentLeadersData()
                        allDivisonData()
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    if (error.response.status === 400 && error.response.data.errorCode === 400) {
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
            else {
                payload.createdBy = idContextTest;
                try {
                    let response = await addDivision(payload);
                    if (response.status === 200) {
                        NotificationManager.success("Division created successfully", "Success", 2500);
                        handleCloseDialog();
                        getRecruitmentLeadersData()
                        allDivisonData()
                    } else if (response.status === 202) {
                        const errorMessage = response.data.message;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                } catch (error) {
                    if (error.response.status === 400 && error.response.data.errorCode === 400) {
                        const errorMessage = error.response.data.errMessage;
                        NotificationManager.error(errorMessage, "Error", 2500);
                    }
                }
            }
            setIsUserDataUpdating(false);
        }
    };
    const allDivisonData = async () => {
        setIsDataLoading(true);
        setDivisionData([]);
        let payload = {
            recruitmentpartnerId: userRecruitmentpartnerId
        }
        try {
            let response = await getDivisionByOrg(payload);
            if (response.status === 200) {
                setDivisionData(response.data.data);
            }
            setIsDataLoading(false);
        } catch (error) {
            setDivisionData([]);
            setIsDataLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        if (userRecruitmentpartnerId) {
            getRecruitmentLeadersData()
            allDivisonData()
        }
    }, [])

    const handleDivisonName = (e) => {
        if (e.target.value) {
            setDivisionInfo({ ...divisionInfo, divisionName: e.target.value })
        } else {
            setDivisionInfo({ ...divisionInfo, divisionName: "" })
        }
    }
    const handleRecruitmentLeader = (e, value, index) => {
        let newArr = [...leadersArr];
        if (value) {
            newArr[index].selectedRecruitmentLeader = value;
            newArr[index].selectedTeamLeaders = [];
        }
        else {
            newArr[index].selectedRecruitmentLeader = {};
            newArr[index].selectedTeamLeaders = [];
        }
        setLeadersArr(newArr);
    }

    const handleActiveChange = async (e, value) => {
        if (value) {
            setDivisionInfo({ ...divisionInfo, active: value })
        }
        else {
            setDivisionInfo({ ...divisionInfo, active: "" })
        }
    }
    const handleEdit = async (rowData) => {
        setEditedRow(rowData);
        setDivisionInfo({
            ...divisionInfo,
            divisionName: rowData.divisionName,
            active: {
                name: rowData.isActive ? "True" : "False",
                value: rowData.isActive
            }
        })
        setOpenDialog(true);
        let teamDetails = rowData.teamDetails;
        let newArr = [];
        for (let index = 0; index < teamDetails.length; index++) {
            let item = teamDetails[index];
            let obj = {
                selectedRecruitmentLeader: item.recruiterLeaderDetails,
                selectedTeamLeaders: item.teamLeaderDetails,
                leaderOptionFetching: false
            };
            newArr.push(obj);
        }
        setLeadersArr(newArr);
        try {
            let payload = {
                recruitmentpartnerId: userRecruitmentpartnerId,
                divisionId: rowData._id,
                validation: "Division"
            }
            const response = await checkRequisitionExists(payload)
            setExisitingRequisition(response?.data?.data)
            const requisitionExists = response?.data?.data?.length;
            if (requisitionExists > 0 && rowData) {
                setIsActiveFieldDisabled(true)
            }
            else {
                setIsActiveFieldDisabled(false)
            }

        } catch (error) {
            NotificationManager.error("Something Went Wrong!", "Error", 2500)
        }
    };
    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: idContextTest,
                userRecords: userRecrodData,
                roleName: userRole
            }
        });
    }

    const increaseLeaderArr = () => {
        setLeadersArr([...leadersArr, {
            selectedRecruitmentLeader: {},
            selectedTeamLeaders: []
        }])
    }
    const leadersArrItemClose = (item, index) => {
        let data = exisitingRequisition.filter((value) => value.assignedTo === item.selectedRecruitmentLeader.id)
        if (data.length > 0) {
            NotificationManager.error("Unable to make changes as a requisition already exists for this client and division.", "Error", 2500)
        }
        else {
            let newArr = [...leadersArr];
            newArr.splice(index, 1);
            setLeadersArr(newArr);
        }
    }

    const checkDisbale_RecruitmentLeader = (item) => {
        let data = exisitingRequisition.filter((value) => value.assignedTo === item.selectedRecruitmentLeader.id)
        if (data?.length > 0) {
            return true
        }
        else {
            return false;
        }
    }

    const fetchTeamLeaders = async (e, index) => {
        setTeamLeaderOptions([]);
        let newArr = [...leadersArr];
        newArr[index].leaderOptionFetching = true;
        setLeadersArr(newArr);
        let payload = {
            recruiterLeaderId: leadersArr[index].selectedRecruitmentLeader.id,
            recruitmentpartnerId: userRecruitmentpartnerId
        }
        try {
            let response = await getTeamLeadersByRecruitmentLeaderId(payload);
            newArr[index].leaderOptionFetching = false;
            setLeadersArr(newArr);
            if (response.status === 200) {
                setTeamLeaderOptions(response.data.data);
            }
            else {
                NotificationManager.error("Unable to fetch team leaders", "Error", 2500);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteTeamLeaderHandler = async (option, i, index) => {
        try {
            let payload = {
                teamLeaderId: option.teamInfo.teamLeaderId,
                recruitmentpartnerId: userRecruitmentpartnerId,
                validation: "Division"
            }
            const dataRes = await checkRequisitionExists(payload);
            if (dataRes?.status === 200 && dataRes?.data?.data.length > 0) {
                NotificationManager.error(
                    `${dataRes?.data?.message}`,
                    "error",
                    2500);
            } else if (dataRes.status === 400 && dataRes.errorCode === 400) {
                const errorMessage = dataRes.data.message;
                NotificationManager.error(errorMessage, "Error", 2500);

            } else {
                let oldArr = [...leadersArr];
                let teamLeaders = [...oldArr[index]?.selectedTeamLeaders];
                let newTeamLeader = [];
                for (let i = 0; i < teamLeaders.length; i++) {
                    if (teamLeaders[i].id !== option.id) {
                        newTeamLeader.push(teamLeaders[i]);
                    }
                }
                oldArr[index] = {
                    ...oldArr[index],
                    selectedTeamLeaders: newTeamLeader
                };
                setLeadersArr(oldArr);
            }
        } catch (error) {
            NotificationManager.error("Something Went Wrong!", "error", 2500)
        }

    }

    const handleTeamLeaderChange = (e, value, index) => {
        if (value) {
            let newArr = [...leadersArr];
            newArr[index].selectedTeamLeaders = value;
            setLeadersArr(newArr);
        }
        else {
            let newArr = [...leadersArr];
            newArr[index].selectedTeamLeaders = [];
            setLeadersArr(newArr);
        }
    }

    const getFilteredRecruiterLeaderOptions = () => {
        let filteredOptions = recruitmentLeaderOptions.filter(item => {
            let isAlreadySelected = false;
            leadersArr.forEach(leader => {
                if (leader.selectedRecruitmentLeader.id === item.id) {
                    isAlreadySelected = true;
                }
            })
            return !isAlreadySelected;
        })
        return filteredOptions
    }

    const getFilteredTeamLeaderOptions = (item) => {
        if (teamLeaderOptions.length > 0) {
            let filteredOptions = teamLeaderOptions.filter(teamLeader => {
                let isAlreadySelected = false;
                item.selectedTeamLeaders.forEach(selectedTeamLeader => {
                    if (selectedTeamLeader.id === teamLeader.id) {
                        isAlreadySelected = true;
                    }
                })
                return !isAlreadySelected;
            })
            return filteredOptions
        }
        else {
            return [];
        }
    }

    return (
        <>
            {(userType === "Organization Admin" || userType === "MIS Operator") || (userType === 'Admin' && userRole === 'Organization Admin') || (userType === 'Admin' && userRole === 'MIS Operator') ?
                <>
                    <Header />

                    <div className="section-wrapper">
                        <div className="container w1200 flex-to-footer">
                            <div className="height_adjust mt-110 manage-client-main">
                                <div className="header-client-manager">
                                    <div className="header-image-manage-client">
                                        <Typography className="manage-client-main-head">Manage Division</Typography>
                                    </div>
                                    {userType === 'Admin' ? "" :
                                        <Button className="add-button-manageclient"
                                            onClick={handleAddDivision}
                                            startIcon={<img src={department} alt="Add Division" style={{ width: "16px", height: "16px" }} />}
                                        >
                                            Add Division
                                        </Button>
                                    }
                                </div>
                                <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>
                                    <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                                </div>
                                {/* Add here kendo table */}
                                <ManageDivisionTable divisionData={divisionData} isDataLoading={isDataLoading} onEdit={handleEdit} userType={userType} />

                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                maxWidth="sm"
                fullWidth
                className="add-division"
            >
                <div className="division_dialog_modal" >
                    <div className='division-dialog-TitleContainer'>
                        <img src={department} alt="Manage division" className="iconStyle" />
                        <span className="dialog-title-text">{Object.keys(editedRow).length > 0 ? 'Edit Division' : 'Add Division'}</span>
                    </div>
                </div>
                <DialogContent className="division-dialog-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className="field-title-division-dialog">Division Name*</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter Division Name"
                                className="input-field-division-dialog"
                                value={divisionInfo.divisionName}
                                onChange={handleDivisonName}
                            />
                        </Grid>

                        {leadersArr.map((item, index) => (
                            <Grid container spacing={2} item xs={12} key={`leadersArr_${index}`}>
                                <Grid item xs={leadersArr.length > 1 ? 5 : 6}>
                                    <Typography className="field-title-division-dialog">Recruitment Leader*</Typography>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        value={item.selectedRecruitmentLeader}
                                        options={getFilteredRecruiterLeaderOptions()}
                                        disabled={checkDisbale_RecruitmentLeader(item)}
                                        filterSelectedOptions
                                        getOptionLabel={(option) => option.email}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Recruitment Leader"
                                            />
                                        )}
                                        onChange={(e, value) => handleRecruitmentLeader(e, value, index)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className="field-title-division-dialog">Team Leaders*</Typography>

                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            multiple
                                            id="combo-box-demo"
                                            options={getFilteredTeamLeaderOptions(item)}
                                            value={item.selectedTeamLeaders}
                                            disableClearable
                                            filterSelectedOptions
                                            getOptionLabel={(option) => option.email}
                                            onFocus={(e) => fetchTeamLeaders(e, index)}
                                            loading={item.leaderOptionFetching}
                                            onChange={(e, value) => handleTeamLeaderChange(e, value, index)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select Team Leaders"
                                                    style={{ backgroundColor: '#fff', color: '#000', borderRadius: 4, borderColor: '#31b3f8' }}
                                                />
                                            )}
                                            renderTags={(value, getTagProps) => {
                                                return (
                                                    value.map((option, i) => (
                                                        <Chip
                                                            label={option.email}
                                                            {...getTagProps({ i })}
                                                            onDelete={() => deleteTeamLeaderHandler(option, i, index)}
                                                        />
                                                    ))
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    {leadersArr.length > 1 ?
                                        <Cancel className="cancelIcon_leadersArr" onClick={(e) => leadersArrItemClose(item, index)} />
                                        : ""}
                                </Grid>
                            </Grid>
                        ))}

                        <Grid item xs={12} className="addButtonGrid_addLeadersArr">
                            <AddCircle
                                color="primary"
                                onClick={increaseLeaderArr}
                                className="addIcon_leadersArr"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="field-title-division-dialog">Is Active*</Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                value={divisionInfo.active}
                                options={activeDropDown}
                                disabled={editedRow ? isActiveFieldDisabled : false}
                                getOptionLabel={(option) => option?.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Active"
                                    />
                                )}
                                onChange={handleActiveChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className="dialog-actionbutton-division">
                    <button onClick={handleCloseDialog} className="cancel-button-division">
                        Cancel
                    </button>
                    <button
                        className={isUserDataUpdating ? 'updateButton-division_disabled' : 'updateButton-division'}
                        onClick={handleSubmit}
                        disabled={isUserDataUpdating}
                    >
                        <span>Save</span>
                        {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton" />) : null}
                    </button>
                </DialogActions>
            </Dialog>
            <NotificationContainer />
        </>
    )
}

export default ManageDivision;