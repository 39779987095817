import React, { useEffect, useState, useContext, useRef } from 'react'
import "./AdvanceSearchTalentCommunity.css"
import Header from "../../../components/headerAdmin/Header.js";
import Footer from "../../../components/adminFooter/Footer";
import { Backdrop, Button, CircularProgress, FormControl, OutlinedInput, Paper, Slider, Switch, TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import csc from "../../../utilities/country-state-city/dist";
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import candidateCombinedSearch from '../../../apis/candidateCombinedSearch';
import candidateSearch from '../../../apis/candidateSearch';
import getAllIndustries from '../../../apis/getAllIndustries';
import getAllCompanies from '../../../apis/getAllCompanies';
import candidateKeySearch from '../../../apis/candidateKeySearch';
import ExcelImg from "../../../components/basicInfoForm/images/excelIcon.jpg";
import { NotificationManager} from "react-notifications";
import getAllDegrees from '../../../apis/getAllDegrees';
import BasicHeader from '../../../components/basicHeader/BasicHeader';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import getAllCandidateProfileByOrgId from '../../../apis/getAllCandidateProfileByOrgId/getAllCandidateProfileByOrgId';
import "react-datepicker/dist/react-datepicker.css";
import EditIcon from "../../../components/basicInfoForm/images/edit-icon.png";
import * as XLSX from 'xlsx';

import configJSON from "../../../Config.json";

import DataUsageIcon from '@material-ui/icons/DataUsage';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import getTalentCommunityStatics from '../../../apis/myTallentCommunityByOrgId/myTalentCommunityStaticsByOrgId';
import '../../ATS_Dashboard/RecLead.css'
import ActivityModal from '../../../components/AcitivityModal/AcitivityModal';
import EditActivity from '../../../components/EditActivity/EditActivity';
import { UserTypeContext } from '../../../AppRoute';
const useStyles = makeStyles((theme) => ({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
    profileButton: {
        color: '#fff',
        backgroundColor: '#1885e6',
        width: '70px',
        '&:hover': {
            backgroundColor: '#1885e6',
            opacity: '0.9'
        }
    },
    activityButton: {
        color: '#fff',
        backgroundColor: '#1885e6',
        width: '70px',
        marginLeft: '15px',
        '&:hover': {
            backgroundColor: '#1885e6',
            opacity: '0.9'
        }
    },
    globalSearchButton: {
        color: '#fff',
        backgroundColor: '#1885e6',
        marginRight: '-8px',
        borderRadius: '6px',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        '&:hover': {
            backgroundColor: '#1885e6',
            opacity: '0.9'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    waitingText: {
        marginLeft: '5px'
    },
    openTab: {
        backgroundColor: "#1885e6",
        color: "#FFFFFF",
    },
    search: {
        position: 'relative',
        backgroundColor: "#d1e8ff",
        marginLeft: 0,
        width: '70%',
        marginTop: '4px',
        borderRadius: "5px",
        height: "25px"
    },
    buttonForSupport: {
        justifyContent: "flex-end !important",
        padding: "7px 39px 18px !important",
    },
    autocomplete: {

    },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        //   color: 'inherit',

    },
    inputInput: {
        padding: theme.spacing(0, 4),
        // vertical padding + font size from searchIcon
        paddingLeft: "0px",
        transition: theme.transitions.create('width'),
        width: '100%',
        //   backgroundColor:"#d1e8ff",
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },

    title: {
        backgroundImage: "linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);!important"
    }

}))

const AdvanceSearchTalentCommunity = (props) => {

    // const userType = props?.location?.state?.userType ? props?.location?.state?.userType : props.userType;
    // const userRecruitmentpartnerId = props?.location?.state?.recruitmentPartnerId ? props?.location?.state?.recruitmentPartnerId : props.userRecruitmentpartnerId;
    // const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : props.userRole;
    // const userRecords = props?.location?.state?.userRecords ? props?.location?.state?.userRecords : props.userRecords;

    const { userType, userRecords } = useContext(UserTypeContext);
    const id = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
    const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
     const userRole = props?.location?.state?.userRole ? props?.location?.state?.userRole : userRecords?.role?.roleName;
    const userRecrodData = userType === 'Admin' ? props?.location?.state?.userRecords : userRecords;
    // const idContextTest = props?.location?.state?.id ? props?.location?.state?.id : props.id;
    // const { userType,userIdType:userId } = useContext(UserTypeContext);
    // const [recId,setRecId]=useState(userName==="Recruiter"?userId:"")
    const [reqId, setReqId] = useState(props?.requisitionIdVal)
    

    const [cityList_candidateSearch, setCityList_candidateSearch] = useState([])
    const [stateList_candidateSearch, setStateList_candidateSearch] = useState([])
    const [scorePercentage, setScorePercentage] = useState(0)
    const [allCandidateResponse, setAllCandidateResponse] = useState([])
    const [allCandidateSearchResponse, setAllCandidateSearchResponse] = useState([])
    const [allCandidateSearchResponse_recommendedCandidate, setAllCandidateSearchResponse_recommendedCandidate] = useState([])
    const [dataResult, setDataResult] = useState([])
    const [dataResult1, setDataResult1] = useState([])
    const [degrees, setDegrees] = useState([])
    const [allCompanies, setAllCompanies] = useState([])
    const [companyNotListed, setCompanyNotListed] = useState(false)
    const [currentSalaryValue, setCurrentSalaryValue] = useState("")
    const [expectedSalaryValue, setExpectedSalaryValue] = useState("")
    const [booleanFilterOn, setBooleanFilterOn] = useState(true)
    const [isSearching, setIsSearching] = useState(true)
    const [isSearching_recommended, setIsSearching_recommended] = useState(true)
    const [recruitmentpartnerId, setRecruimentPartnerId] = useState("")
    const [communityStats, setCommunityStats] = React.useState([]);
    const [communityStatsLoader, setCommunityStatsLoader] = React.useState(false);
    const [requisitionIdValue, setRequisitionIdValue] = useState([]);
    const [userCountry_candidateSearch, setUserCountry_candidateSearch] = useState("")
    const [userState_candidateSearch, setUserState_candidateSearch] = useState("")
    const [userCity_candidateSearch, setUserCity_candidateSearch] = useState("")
    const [selectedCurrency, setSelectedCurrency] = useState("")
    const [selectedIndustry, setSelectedIndustry] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")
    const [selectedEducation, setSelectedEducation] = useState([])
    const [noticeperiod, setNoticeperiod] = useState("")
    const [selectedUnlistedCompany, setSelectedUnlistedCompany] = useState("")
    const [minExperience, setMinExperience] = useState("")
    const [maxExperience, setMaxExperience] = useState("")
    const [keywordOptions, setKeywordOptions] = useState([])
    const [keywordCurrentValue, setKeywordCurrentValue] = useState("")
    const [selectedKeywords, setSelectedKeywords] = useState([])
    const [allIndustries_candidateSearch, setAllIndustries_candidateSearch] = useState([])

    const [serachingThroughTestScore, setSerachingThroughTestScore] = useState(false)

    const [primarySkillOptions, setPrimarySkillOptions] = useState([])
    const [primarySkillValues, setPrimarySkillValues] = useState([])
    const [currentPrimarySkillValue, setCurrentPrimarySkillValue] = useState("");

    const [additionalSkillOptions, setAdditionalSkillOptions] = useState([])
    const [additionalSkillValues, setAdditionalSkillValues] = useState([])
    const [currentAdditionalSkillValue, setCurrentAdditionalSkillValue] = useState("");
    const _export = useRef(null);

    const [totalCandidateCount, setTotalCandidateCount] = useState(0)
    const [totalCandidateCount_RecommendedCandidates, setTotalCandidateCount_RecommendedCandidates] = useState(0)

    const [candidateSearchPayload, setCandidateSearchPayload] = useState({})
    const [typeOfSearch, setTypeOfSearch] = useState("")
    const [isCandidateSearch, setIsCandidateSearch] = useState(false)
    const [kendoFilterArray, setKendoFilterArray] = useState([])
    const [handlingBothSearch, setHandlingBothSearch] = useState(false)
    const [oldSearchPayload, setOldSearchPayload] = useState({})
    const [oldCandidateSearchDecision, setOldCandidateSearchDecision] = useState("")

    const [takeNumberOfResult, setTakeNumberOfResult] = useState(20);
    const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [initialSearch, setInitialSearch] = useState(true);

    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("");
    const [isExporting, setIsExporting] = useState(false);
    const [email, setEmail] = useState("")
    const [isSearchingThroughKendoFilter, setIsSearchingThroughKendoFilter] = useState(false);
    const [tabSwitchCount, setTabSwitchCount] = useState(0);
    const [edit, setEdit] = useState(false)
    const [isActivity, setisActivity] = useState(false);
    const [allRequisitionData, setAllRequisitionData] = useState([]);

    const currencyCodes = ["USD", "EUR", "GBP", "JPY", "CAD", "AUD", "CHF", "CNY", "INR", "ZAR"];

    const [row_DataItem, set_Row_DataItem] = useState("")

    const [countryList, setCountryList] = useState([
        {
            currency: "USD",
            flag: "",
            isoCode: "US",
            latitude: "38.00000000",
            longitude: "-97.00000000",
            name: "United States",
            phonecode: "+1",
        },
        {
            currency: "CAD",
            flag: "",
            isoCode: "CA",
            latitude: "60.00000000",
            longitude: "-95.00000000",
            name: "Canada",
            phonecode: "+1",
        },
        {
            currency: "INR",
            flag: "",
            isoCode: "IN",
            latitude: "20.00000000",
            longitude: "77.00000000",
            name: "India",
            phonecode: "+91",
        },
        {
            currency: "MXN",
            flag: "",
            isoCode: "MX",
            latitude: "23.00000000",
            longitude: "-102.00000000",
            name: "Mexico",
            phonecode: "+52",
        }
    ]);

    const filterOperators = {
        text: [
            {
                text: "grid.filterContainsOperator",
                operator: "contains",
            },
        ],
        numeric: [
            {
                text: "grid.filterEqOperator",
                operator: "eq",
            },
        ],
        date: [
            {
                text: "grid.filterEqOperator",
                operator: "eq",
            },
        ],
        boolean: [
            {
                text: "grid.filterEqOperator",
                operator: "eq",
            },
        ],
    };

    const dataForNoticePeriod = [
        { value: 30, label: "30 days" },
        { value: 45, label: "45 days" },
        { value: 60, label: "60 days" },
        { value: 90, label: "90 days" }
    ];

    const [dataState, setDataState] = useState({
        skip: 0,
        take: 20,
        filter: {
            logic: "and",
            filters: [],
        },
    });

    const [dataState1, setDataState1] = useState({
        skip: 0,
        take: 20,
        filter: {
            logic: "and",
            filters: [],
        },
    });

    const [initialDataState, setInitialDataState] = useState({
        skip: 0,
        take: 20,
        filter: {
            logic: "and",
            filters: [],
        },
    });

    const [openedTab, setOpenedTab] = useState();

    const classes = useStyles()
    const history = useHistory();

    const handleCandidateSearchByOrgId = async (page, size, searchPayloadData) => {
        let _recruitmentpartnerId = userRecruitmentpartnerId
        setDataResult1([])
        setIsSearching(true)
        let payload = {
            page: page,
            size: size,
            sortingField: searchPayloadData.sortingField,
            sortingOrder: searchPayloadData.sortingOrder,
        }
        let allCandidateResponse = await getAllCandidateProfileResponse(_recruitmentpartnerId, payload, isExporting, openedTab)
        setAllCandidateSearchResponse(allCandidateResponse)
        setDataResult1(allCandidateResponse);
        setIsSearching(false)
    }












    // Get Community Stats Data
    const getCommunityStaticsData = async () => {
        try {
            let _recruitmentpartnerId = userRecruitmentpartnerId ? userRecruitmentpartnerId : "";

            if (_recruitmentpartnerId) {
                let res = await getTalentCommunityStatics(_recruitmentpartnerId);
                setCommunityStats(res?.data);
                setCommunityStatsLoader(true)
            }
        } catch (error) {
            console.log("error", error);
        } finally {
        }
    }

    useEffect(() => {
        getCommunityStaticsData();
    }, []);

    useEffect(() => {
        const getallCandidateData = async () => {
            try {
                let _recruitmentpartnerId = userRecruitmentpartnerId;
                setRecruimentPartnerId(_recruitmentpartnerId)
                let payload = {
                    page: pageNumber,
                    size: takeNumberOfResult,
                    sortingField: "created_at",
                    sortingOrder: 'desc',
                }
                let initialTab = props.requisitionIdVal ? 'Recommended Candidates' : 'Search Candidates';
                setOpenedTab(initialTab)
                let allCandidateResponse = await getAllCandidateProfileResponse(_recruitmentpartnerId, payload, isExporting, initialTab)
                if (initialTab === 'Search Candidates') {
                    setAllCandidateSearchResponse(allCandidateResponse)
                    setDataResult1(allCandidateResponse);
                    setIsSearching(false)
                }
                else {
                    setAllCandidateSearchResponse_recommendedCandidate(allCandidateResponse)
                    setDataResult(allCandidateResponse);
                    setIsSearching_recommended(false)
                }

                setInitialSearch(false);

                let response_allIndustries = await getAllIndustries()
                setAllIndustries_candidateSearch(response_allIndustries.data.data)

                let response_degrees = await getAllDegrees();
                setDegrees(response_degrees.data.data)
                let companiesData = [];
                let response_companies = await getAllCompanies()
                companiesData = response_companies.data.data
                let otherCompanyData = {
                    "_id": "",
                    "name": "others",
                    "created_at": "",
                    "updated_at": "",
                    "__v": ""
                }
                companiesData.push(otherCompanyData)
                setAllCompanies(companiesData)

            } catch (error) {
                console.log("error")
            }
        }
        getallCandidateData();
    }, []);
    useEffect(() => {

        const interval = setInterval(async () => {
            if (kendoFilterArray.length > 0) {
                if (!handlingBothSearch) {
                    setHandlingBothSearch(true)
                    setOldSearchPayload(candidateSearchPayload)
                    setOldCandidateSearchDecision(typeOfSearch)
                }
                setKendoFilterArray([])
                let additionalFilterArr = {}

                for (let element of kendoFilterArray) {
                    if (element.field === "emailID") {
                        additionalFilterArr.email = element.value.trim()
                    }
                    else if (element.field === "name") {
                        additionalFilterArr.candidateName = element.value.trim()
                    }
                    else if (element.field === "primarySkill") {
                        additionalFilterArr.primarySkill = [element.value.trim()]
                    }
                    else if (element.field === "degreeField") {
                        let degreeArr = [];
                        let degreeFieldArr = element.value.split(",")
                        degreeFieldArr.forEach(element => {
                            degreeArr.push(element.trim())
                        });
                        additionalFilterArr.education = degreeArr;
                    }
                    else if (element.field === "yr_of_exp") {

                        let yoeVal = element.value
                        additionalFilterArr.yearsofexp = yoeVal
                    }
                    else if (element.field === "country") {
                        additionalFilterArr.country = element.value.trim()
                    }
                    else if (element.field === "state") {
                        additionalFilterArr.state = element.value.trim()
                    }
                    else if (element.field === "city") {
                        additionalFilterArr.city = element.value.trim()
                    }
                    else if (element.field === "testnames") {
                        let testnamesArr = element.value.split(",")
                        let testnames = [];
                        testnamesArr.forEach(element => {
                            testnames.push(element.trim())
                        });
                        additionalFilterArr.testnames = testnames;
                    }
                    else if (element.field === "testskills") {
                        let testskillsArr = element.value.split(",")
                        let testskills = [];
                        testskillsArr.forEach(element => {
                            testskills.push(element.trim())
                        });
                        additionalFilterArr.testskills = testskills;
                    }
                }

                let searchDecisionArr = getSearchDecisionAndPayload(additionalFilterArr);

                let searchDecision = searchDecisionArr.searchDecision
                setTypeOfSearch(searchDecision)
                setCandidateSearchPayload(searchDecisionArr.payload)
                let searchPayloadData = searchDecisionArr.payload

                const take = initialDataState.take;
                const skip = initialDataState.skip;
                const page = Math.floor(skip / take) + 1;
                setTakeNumberOfResult(take);
                setSkipNumberOfResult(skip);
                setPageNumber(page);
                searchPayloadData.page = page
                searchPayloadData.size = take;
                searchPayloadData.sortingField = sortingField;
                searchPayloadData.sortingOrder = sortingOrder;
                searchPayloadData.userRecruitmentpartnerId =userRecruitmentpartnerId;
                searchPayloadData.userId =id;
                setIsSearching(true)
                let arr = await getSearchResult(searchDecision, searchPayloadData, false)
                setIsSearching(false)
                setDataResult1(arr);
                setAllCandidateSearchResponse(arr)
            }


        }, 2000); // Check every 2 seconds
        return () => clearInterval(interval); // Clear interval on component unmount
    }, [kendoFilterArray]);

    const getAllCandidateProfileResponse = async (orgId, payload, exportExcel, tab) => {
        let arr = [];
        payload.isRequistion = false;
        payload.requisitionIdVal = ""
        if (props.requisitionIdVal && tab === 'Recommended Candidates') {
            payload.isRequistion = true;
            payload.requisitionIdVal = props.requisitionIdVal;
            payload.totalData = 10;
        }
        payload.userRecruitmentpartnerId =userRecruitmentpartnerId;
        payload.userId =id;
        let responseData = await getAllCandidateProfileByOrgId(orgId, payload)
        let response = responseData[0].data
        if (!exportExcel) {
            if (responseData[0]?.totalCount[0]?.total) {
                if (props.requisitionIdVal && tab === 'Recommended Candidates') {
                    setTotalCandidateCount_RecommendedCandidates(responseData[0].totalCount[0].total)
                }
                else {
                    setTotalCandidateCount(responseData[0].totalCount[0].total)
                }
            }
            else {
                setTotalCandidateCount(0)
            }
        }
        for (let i = 0; i < response.length; i++) {
            let yoe = response[i].yr_of_exp ? parseInt(response[i].yr_of_exp) : 0
            let fulladdress = " " + (response[i].address ? response[i].address + ", " : '') + (response[i].city ? response[i].city + ", " : '') + (response[i].state ? response[i].state + ", " : '') + (response[i].country ? response[i].country + " " : '')
            let allSkillString = handleSkillMerge(response[i].skills)
            let allAdditionalSkillString = response[i].skills.join(", ")
            if (allSkillString.length > 100) {
                allSkillString = allSkillString.substring(0, 100)
            }
            let degreesValue = response[i].degrees
            let degreeStr = '';
            for (let j = 0; j < degreesValue.length; j++) {
                if (j === degreesValue.length - 1) {
                    degreeStr = degreeStr + degreesValue[j].trim()
                }
                else {
                    degreeStr = degreeStr + degreesValue[j].trim() + ', '
                }
            }
            let testnames = ""
            let testskills = ""
            let testScores = ""
            if (response[i]?.testDetails && response[i]?.testDetails.length > 0) {
                let testDetails = response[i].testDetails;
                for (let j = 0; j < testDetails.length; j++) {
                    if (j === testDetails.length - 1) {
                        testnames += testDetails[j].testName
                        testskills += testDetails[j].testSkillName
                        testScores += testDetails[j].testScore + "%"
                    }
                    else {
                        testnames += testDetails[j].testName + ", "
                        testskills += testDetails[j].testSkillName + ", "
                        testScores += testDetails[j].testScore + "% , "
                    }
                }
            }
            arr.push(
                Object.assign(
                    response[i],
                    { allSkill: allSkillString },
                    { allAdditionalSkill: allAdditionalSkillString },
                    { fulladdress: fulladdress },
                    { yearsofexp: yoe },
                    { degreeField: degreeStr },
                    { testnames: testnames },
                    { testskills: testskills },
                    { testScores: testScores }
                )
            );
        }
        return arr;
    }




    const percentageScoreHandler = (e, value) => {
        setScorePercentage(value)
    }
    const getSkeleton_candidateSearch = () => {
        let arr = []
        for (let i = 0; i < 20; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="50px" />)
        }
        return arr;
    }


    const submitModalOpen = async (props) => {
        setisActivity(true);
        const { dataItem } = props;
        setEmail(dataItem?.emailID)
        set_Row_DataItem(dataItem);

    };





    const handleCandidateLink_candidatesearch = (props) => {
        return (
            <td align="center">
                <Button
                    size="small"
                    variant="contained"
                    className={classes.profileButton}
                    onClick={(e) => handleGotoProfile(props)}
                >
                    Profile
                </Button>
                {
                    ( (userType === "Admin" && userRole==="Recruiter") || userType === "Recruiter" )
                    &&
                    <Button
                        size="small"
                        variant="contained"
                        className={classes.activityButton}
                        onClick={(e) => submitModalOpen(props)}
                    >
                        Action
                    </Button>
                }
            </td>
        );
    };

    const handleGotoProfile = async (props) => {
        if (props.dataItem.powerResumeId) {
            window.open(`/candidate/profile/${props.dataItem.powerResumeId}/${recruitmentpartnerId}`, "_blank")
        }
        else {
            window.open(`/only-candidate-profile/${props.dataItem.candidateId}`, "_blank")
        }
    }

    const companyChangeHandler = (e, value) => {
        if (value) {
            if (value.name === "others") {
                setCompanyNotListed(true)
                setSelectedCompany(value)
            }
            else {
                setSelectedCompany(value)
                setCompanyNotListed(false)
                setSelectedUnlistedCompany('')
            }
        }
        else {
            setSelectedCompany("")
            setCompanyNotListed(false)
            setSelectedUnlistedCompany('')
        }
    }

    const expectedSalaryHandler = (e) => {
        setExpectedSalaryValue(e.target.value)
    }

    const currentSalaryHandler = (e) => {
        setCurrentSalaryValue(e.target.value)
    }

    const countryHandler_candidateSearch = (event, value) => {
        if (value === null) {
            setUserCountry_candidateSearch("");
            setStateList_candidateSearch([])
            setCityList_candidateSearch([])
        } else if (value) {
            setUserCountry_candidateSearch(value);
            setStateList_candidateSearch(csc.getStatesOfCountry(value.isoCode));
        }
        setUserState_candidateSearch("")
        setUserCity_candidateSearch("")
    };

    const stateHandler_candidateSearch = (event, value) => {
        if (value === null) {
            setUserState_candidateSearch("");
            setCityList_candidateSearch([])
        } else if (value) {
            setUserState_candidateSearch(value);
            setCityList_candidateSearch(csc.getCitiesOfState(value.countryCode, value.isoCode));
        }
        setUserCity_candidateSearch("")
    };


    const cityHandler_candidateSearch = (event, value) => {
        if (value === null) {
            setUserCity_candidateSearch("");
        } else if (value) {
            setUserCity_candidateSearch(value);
        }
    };

    const currencyChangeHandler = (e, value) => {
        if (value)
            setSelectedCurrency(value)
        else setSelectedCurrency("")
    }
    const industryChangeHandler = (e, value) => {
        if (value)
            setSelectedIndustry(value)
        else setSelectedIndustry("")
    }

    const unlistedCompanyValuechangeHandler = (e) => {
        if (e.target.value) {
            setSelectedUnlistedCompany(e.target.value)
        }
        else setSelectedUnlistedCompany("")
    }

    const educationHandler = (e, value) => {
        if (value) {
            setSelectedEducation(value)
        }
        else {
            selectedEducation([])
        }
    }

    const noticeperiodHandler = (e, value) => {
        if (value) {
            setNoticeperiod(value)
        }
        else {
            setNoticeperiod("")
        }
    }

    const minExperienceHandler = (e) => {
        setMinExperience(e.target.value)
        if (!maxExperience || e.target.value > maxExperience)
            setMaxExperience(e.target.value)
    }
    const maxExperienceHandler = (e) => {
        setMaxExperience(e.target.value)
    }

    const getEducationData = (eduData) => {
        let arr = []
        for (let i = 0; i < eduData.length; i++) {
            arr.push(eduData[i].degreename)
            let alternativeValues = eduData[i].alternative
            if (alternativeValues.length > 0) {
                for (let j = 0; j < alternativeValues.length; j++) {
                    arr.push(alternativeValues[j])
                }
            }
        }
        return (arr)
    }

    const getSearchDecisionAndPayload = (additionalFilterArr) => {

        let searchDecision = ""
        let payload = {}
        let selectedSkillsValue = additionalSkillValues.map((item) => item.trim())
        let selectedPrimarySkillValue = primarySkillValues.map((item) => item.trim())
        let selectedEducationValue = getEducationData(selectedEducation)
        let additionalFilterArrKeysLength = Object.keys(additionalFilterArr).length

        if (selectedKeywords.length > 0 && (selectedSkillsValue.length > 0 || selectedPrimarySkillValue.length > 0 || (expectedSalaryValue != 10000001 && expectedSalaryValue != "") || (currentSalaryValue != 0 && currentSalaryValue !== '')
            || selectedCurrency || selectedIndustry || selectedEducationValue.length > 0 || selectedUnlistedCompany || selectedCompany || minExperience || maxExperience ||
            noticeperiod || userCountry_candidateSearch || userState_candidateSearch || userCity_candidateSearch || scorePercentage !== 0 || additionalFilterArrKeysLength > 0
        )) {
            payload = {
                operator: booleanFilterOn ? "OR" : "AND",
                keywords: selectedKeywords,
                skills: selectedSkillsValue.length > 0 ? selectedSkillsValue : '',
                primarySkill: selectedPrimarySkillValue.length > 0 ? selectedPrimarySkillValue : '',
                currentSalary: currentSalaryValue != 0 ? currentSalaryValue : '',
                expectedSalary: expectedSalaryValue != 10000001 ? expectedSalaryValue : '',
                Currency: selectedCurrency ? selectedCurrency : '',
                Industry: selectedIndustry ? selectedIndustry.name : '',
                education: selectedEducationValue.length > 0 ? selectedEducationValue : '',
                company: companyNotListed ? selectedUnlistedCompany : selectedCompany ? (selectedCompany.name).trim() : '',
                minYearsOfExperience: minExperience ? minExperience : (maxExperience ? 0 : ''),
                maxYearsOfExperience: maxExperience ? maxExperience : '',
                noticePeriod: noticeperiod ? noticeperiod.value : '',
                country: userCountry_candidateSearch ? userCountry_candidateSearch.name : '',
                state: userState_candidateSearch ? userState_candidateSearch.name : '',
                city: userCity_candidateSearch ? userCity_candidateSearch.name : '',
                OrgId: userRecruitmentpartnerId,
                testScore: scorePercentage ? scorePercentage : null,
                additionalFilterArr: JSON.stringify(additionalFilterArr)

            }
            searchDecision = "combinedSearch"
        }
        else if (selectedKeywords.length > 0) {
            payload = {
                operator: booleanFilterOn ? "OR" : "AND",
                keywords: selectedKeywords,
                OrgId: userRecruitmentpartnerId
            }
            searchDecision = "KeywordSearch"
        }
        else {
            payload = {
                skills: selectedSkillsValue.length > 0 ? selectedSkillsValue : '',
                primarySkill: selectedPrimarySkillValue.length > 0 ? selectedPrimarySkillValue : '',
                currentSalary: currentSalaryValue != 0 ? currentSalaryValue : '',
                expectedSalary: expectedSalaryValue != 10000001 ? expectedSalaryValue : '',
                Currency: selectedCurrency ? selectedCurrency : '',
                Industry: selectedIndustry ? selectedIndustry.name : '',
                education: selectedEducationValue.length > 0 ? selectedEducationValue : '',
                company: companyNotListed ? selectedUnlistedCompany : selectedCompany ? (selectedCompany.name).trim() : '',
                minYearsOfExperience: minExperience ? minExperience : (maxExperience ? 0 : ''),
                maxYearsOfExperience: maxExperience ? maxExperience : '',
                noticePeriod: noticeperiod ? noticeperiod.value : '',
                country: userCountry_candidateSearch ? userCountry_candidateSearch.name : '',
                state: userState_candidateSearch ? userState_candidateSearch.name : '',
                city: userCity_candidateSearch ? userCity_candidateSearch.name : '',
                OrgId: userRecruitmentpartnerId,
                testScore: scorePercentage ? scorePercentage : null,
                additionalFilterArr: JSON.stringify(additionalFilterArr)
            }
            searchDecision = "keyValueSearch"
        }
        let decisionArr = {
            searchDecision: searchDecision,
            payload: payload
        }
        return decisionArr;

    }

    const candidateSearchHandler = async () => {
        setDataResult1([])
        setIsCandidateSearch(true)
        setDataState1({
            skip: 0,
            take: 20,
            filter: {
                logic: "and",
                filters: [],
            },
        })

        let additionalFilterArr = {}

        let searchDecisionArr = getSearchDecisionAndPayload(additionalFilterArr);
        let searchDecision = searchDecisionArr.searchDecision;
        let searchPayloadData = searchDecisionArr.payload;
        searchPayloadData.sortingField = "created_at";
        searchPayloadData.sortingOrder = "desc";
        setSortingField("created_at");
        setSortingOrder("desc");
        setTypeOfSearch(searchDecision)
        setHandlingBothSearch(false)
        setCandidateSearchPayload(searchPayloadData)
        const take = initialDataState.take;
        const skip = initialDataState.skip;
        const page = Math.floor(skip / take) + 1;
        setTakeNumberOfResult(take);
        setSkipNumberOfResult(skip);
        setPageNumber(page);

        try {
            await handleCandidateSearchStateChange(searchDecision, searchPayloadData, page, take)
        } catch (error) {
            console.log("error", error)
        }
    }

    const getSearchResult = async (searchDecision, payload, exportExcel) => {
        let res;
        try {
            if (searchDecision === "combinedSearch") {
                res = await candidateCombinedSearch(payload)
            }
            else if (searchDecision === "KeywordSearch") {
                res = await candidateKeySearch(payload)
            }
            else {
                res = await candidateSearch(payload)
            }

            let arr = []
            if (res[0]?.data?.message === "Keyword search should not include numerical values.") {
                NotificationManager.error("Keyword search should not include numerical values", "Error", 4000);
                return arr;
            }
            else {
                if (res.length > 0) {
                    let response = res[0].data
                    if (!exportExcel) {
                        if (res[0]?.totalCount[0]?.total) {
                            setTotalCandidateCount(res[0].totalCount[0].total)
                        }
                        else {
                            setTotalCandidateCount(0)
                        }
                    }
                    for (let i = 0; i < response.length; i++) {
                        let yoe = response[i].yr_of_exp ? parseInt(response[i].yr_of_exp) : 0
                        let fulladdress = " " + (response[i].address ? response[i].address + ", " : '') + (response[i].city ? response[i].city + ", " : '') + (response[i].state ? response[i].state + ", " : '') + (response[i].country ? response[i].country + " " : '')
                        let allSkillString = handleSkillMerge(response[i].skills)
                        let allAdditionalSkillString = response[i].skills.join(", ")
                        if (allSkillString.length > 100) {
                            allSkillString = allSkillString.substring(0, 100)
                        }
                        let degreesValue = response[i].degrees
                        let degreeStr = '';
                        for (let j = 0; j < degreesValue.length; j++) {
                            if (j === degreesValue.length - 1) {
                                degreeStr = degreeStr + degreesValue[j].trim()
                            }
                            else {
                                degreeStr = degreeStr + degreesValue[j].trim() + ', '
                            }
                        }
                        let testnames = ""
                        let testskills = ""
                        let testScores = ""
                        if (response[i]?.testDetails && response[i]?.testDetails.length > 0) {
                            let testDetails = response[i].testDetails;
                            for (let j = 0; j < testDetails.length; j++) {
                                if (j === testDetails.length - 1) {
                                    testnames += testDetails[j].testName
                                    testskills += testDetails[j].testSkillName
                                    testScores += testDetails[j].testScore + "%"
                                }
                                else {
                                    testnames += testDetails[j].testName + ", "
                                    testskills += testDetails[j].testSkillName + ", "
                                    testScores += testDetails[j].testScore + "% , "
                                }
                            }
                        }
                        arr.push(
                            Object.assign(
                                response[i],
                                { allSkill: allSkillString },
                                { allAdditionalSkill: allAdditionalSkillString },
                                { fulladdress: fulladdress },
                                { yearsofexp: yoe },
                                { degreeField: degreeStr },
                                { testnames: testnames },
                                { testskills: testskills },
                                { testScores: testScores }
                            )
                        );
                    }
                    setIsSearching(false)
                    if (scorePercentage !== 0) {
                        setSerachingThroughTestScore(true)
                    } else {
                        setSerachingThroughTestScore(false)
                    }
                    return arr;
                }
                else {
                    setTotalCandidateCount(0)
                    setIsSearching(false)
                    return arr;
                }
            }

        } catch (error) {
            console.log("error", error)
        }
    }

    const keywordTextFieldChange = (e) => {
        setKeywordCurrentValue(e.target.value)
        let arr = [];
        arr.push(e.target.value)
        setKeywordOptions(arr)
    }

    const keywordValuechangeHandler = (e, value) => {
        setSelectedKeywords(value)
        setKeywordOptions([])

    }
    const handleAdditionalSkillsField = (props) => {
        let skillDataString = props.dataItem.allSkill
        return (<td>
            {skillDataString}{skillDataString.length === 100 ? <span onClick={(e) => handleMoreClick(props)} className='expandtext_kendo'>...show more</span> :
                skillDataString.length > 100 ? <span onClick={(e) => handlelessClick(props)} className='expandtext_kendo'>...show less</span> : ''
            }
        </td>)
    }

    const handleSkillMerge = (skills) => {
        let allSkillData_fetch = skills
        let allSkillString = ''
        for (let j = 0; j < allSkillData_fetch.length; j++) {
            if (j != allSkillData_fetch.length - 1) {
                allSkillString += allSkillData_fetch[j] + ", "
            }
            else {
                allSkillString += allSkillData_fetch[j]
            }
        }
        return (allSkillString)
    }

    const handleMoreClick = (props) => {
        let index = allCandidateSearchResponse.findIndex((item) => item._id === props.dataItem._id)
        let oldcandidateresults = allCandidateSearchResponse
        let skillsData = handleSkillMerge(props.dataItem.skills)
        oldcandidateresults[index]['allSkill'] = skillsData
        setAllCandidateResponse(oldcandidateresults)
        setDataResult1(process(oldcandidateresults, dataState1));
    }

    const handlelessClick = (props) => {
        let index = allCandidateSearchResponse.findIndex((item) => item._id === props.dataItem._id)
        let oldcandidateresults = allCandidateSearchResponse
        let skillsData = handleSkillMerge(props.dataItem.skills)
        oldcandidateresults[index]['allSkill'] = skillsData.substring(0, 100)
        setAllCandidateResponse(oldcandidateresults)
        setDataResult1(process(oldcandidateresults, dataState1));
    }

    const primarySkillTextFieldHandler = (e) => {
        setCurrentPrimarySkillValue(e.target.value)
        let arr = [];
        arr.push(e.target.value)
        setPrimarySkillOptions(arr)
    }

    const primaryskillchangehandler = (e, value) => {
        setPrimarySkillValues(value)
        setPrimarySkillOptions([])
    }

 

    const additionalSkillTextFieldHandler = (e) => {
        setCurrentAdditionalSkillValue(e.target.value)
        let arr = [];
        arr.push(e.target.value)
        setAdditionalSkillOptions(arr)
    }

    const additionalSkillChangeHandler = (e, value) => {
        setAdditionalSkillValues(value)
        setAdditionalSkillOptions([])
    }

    const excelExport = async () => {

        setIsExporting(true)

        try {
            let page = 1
            let size = openedTab === "Search Candidates" ? totalCandidateCount : totalCandidateCount_RecommendedCandidates;
            let searchPayloadData = candidateSearchPayload;
            let exportArr = [];
            let allCandidateResponse;
            if (isCandidateSearch || dataState1.filter.filters.length > 0) {
                let searchDecision = typeOfSearch;
                searchPayloadData.page = page
                searchPayloadData.size = size;
                searchPayloadData.sortingField = sortingField;
                searchPayloadData.sortingOrder = sortingOrder;
                allCandidateResponse = await getSearchResult(searchDecision, searchPayloadData, true)
            }
            else {
                let payload = {
                    page: page,
                    size: size,
                    sortingField: searchPayloadData.sortingField,
                    sortingOrder: searchPayloadData.sortingOrder,
                }
                allCandidateResponse = await getAllCandidateProfileResponse(recruitmentpartnerId, payload, true, openedTab)

            }

            if (allCandidateResponse.length === 0) {
                setIsExporting(false)
                NotificationManager.error("No candidates found", "Error", 4500);

            } else {
                allCandidateResponse.forEach(element => {
                    let data = {
                        "Candidate Name": element.name,
                        "Email": element.emailID,
                        "Primary Skill": element.primarySkill,
                        "Additional Skills": element.allAdditionalSkill,
                        "Country": element.country,
                        "State": element.state,
                        "City": element.city,
                        "Educational Degree": element.degreeField,
                        "Years of Experience": element.yearsofexp,
                    }
                    if (scorePercentage !== 0) {
                        data["Test Names"] = element.testnames
                        data["Test Skills"] = element.testskills
                        data["Test Scores"] = element.testScores
                    }
                    exportArr.push(data)
                });


                const MAX_ROWS_PER_SHEET = 500;

                const wb = XLSX.utils.book_new();

                // Calculate the number of sheets needed
                const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

                // Loop to create sheets
                for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
                    // Get a subset of data for each sheet
                    const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
                    const endRow = Math.min((sheetIndex + 1) * MAX_ROWS_PER_SHEET, exportArr.length);
                    const subsetData = exportArr.slice(startRow, endRow);

                    // Convert JSON array to worksheet
                    const ws = XLSX.utils.json_to_sheet(subsetData);

                    // Append the sheet to the workbook
                    XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
                }

                // Save the workbook to an Excel file
                XLSX.writeFile(wb, `CandidateData.xlsx`, { bookType: 'xlsx', type: 'file' });
                setIsExporting(false)
            }

        } catch (error) {
            console.log("error", error)
        }
    };

    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: {
                userType: userType,
                recruitmentPartnerId: userRecruitmentpartnerId,
                id: id,
                userRecords: userRecrodData,
                roleName: userRole
            }
        });
    };

    const handleYearsOfExpField = (props) => {
        if (!props.dataItem.yr_of_exp) {
            return <td>N/A</td>
        }
        else return <td>{props.dataItem.yearsofexp}</td>
    }


    const handleCandidateSearchStateChange = async (searchDecision, searchPayloadData, page, take) => {
        try {
            setDataResult1([])
            searchPayloadData.page = page
            searchPayloadData.size = take;

            setIsSearching(true)
            let arr = await getSearchResult(searchDecision, searchPayloadData, false)
            setIsSearching(false)
            setDataResult1(arr);
            setAllCandidateSearchResponse(arr)
        } catch (error) {
            console.log("error", error)
        }

    }

    const dataStateChange_recommendedCandidates = async (event) => {
        setDataState(event.dataState);
    }


    const dataStateChange = async (event) => {
        if (event.dataState?.filter?.filters?.length > 0) {
            setIsSearchingThroughKendoFilter(true);
            let take = event?.dataState?.take;
            let skip = event?.dataState?.skip;
            let page = Math.floor(skip / take) + 1;

            let tobeSortFieldName = "";
            let tobeSortOrder = "";

            let sortingField_kendo = event?.dataState?.sort?.[0]?.field;
            let sortingOrder_kendo = event?.dataState?.sort?.[0]?.dir;

            if ((sortingField_kendo && sortingOrder_kendo) && (sortingField_kendo !== sortingField || sortingOrder_kendo !== sortingOrder)) {
                tobeSortFieldName = sortingField_kendo;
                tobeSortOrder = sortingOrder_kendo;
                setSortingField(sortingField_kendo);
                setSortingOrder(sortingOrder_kendo);
            }
            else {
                if (sortingField_kendo && sortingOrder_kendo) {
                    tobeSortFieldName = sortingField;
                    tobeSortOrder = sortingOrder;
                }
                else {
                    tobeSortFieldName = "created_at";
                    tobeSortOrder = "desc";
                    setSortingField("created_at");
                    setSortingOrder("desc");
                }
            }


            if (page !== pageNumber || take !== takeNumberOfResult) {
                setPageNumber(page);
                setTakeNumberOfResult(take);
                setSkipNumberOfResult(skip);
                setDataState1(event.dataState)
                let searchPayloadData = candidateSearchPayload;
                searchPayloadData.sortingField = tobeSortFieldName;
                searchPayloadData.sortingOrder = tobeSortOrder;
                await handleCandidateSearchStateChange(typeOfSearch, searchPayloadData, page, take)
            }
            else {
                let tempDataState = initialDataState
                tempDataState.filter = event.dataState.filter
                tempDataState.sort = [
                    {
                        dir: tobeSortOrder,
                        field: tobeSortFieldName
                    }
                ]
                setKendoFilterArray(event.dataState.filter.filters)
                setIsSearching(true)
                setDataState1(tempDataState);
                setAllCandidateSearchResponse([])
                setDataResult1([]);
            }
        }
        else {
            setIsSearchingThroughKendoFilter(false);
            setKendoFilterArray([])
            let take;
            let page;
            let skip;
            let searchDecision;
            let searchPayloadData;
            if (handlingBothSearch) {
                setHandlingBothSearch(false)
                setDataState1({
                    skip: 0,
                    take: 20,
                    filter: {
                        logic: "and",
                        filters: [],
                    },
                })
                setCandidateSearchPayload(oldSearchPayload)
                setTypeOfSearch(oldCandidateSearchDecision)
                setPageNumber(1)
                setSkipNumberOfResult(0)
                setTakeNumberOfResult(initialDataState.take)
                take = initialDataState.take;
                page = 1;
                searchDecision = oldCandidateSearchDecision
                searchPayloadData = oldSearchPayload;
                searchPayloadData.sortingField = "created_at";
                searchPayloadData.sortingOrder = "desc";
                setSortingField("created_at");
                setSortingOrder("desc");
            }
            else {
                take = event?.dataState?.take;
                skip = event?.dataState?.skip;
                page = Math.floor(skip / take) + 1;
                setTakeNumberOfResult(take);
                setSkipNumberOfResult(skip);
                setPageNumber(page);
                setDataState1(event.dataState)
                searchDecision = typeOfSearch;
                searchPayloadData = candidateSearchPayload;
                let sortingField_kendo = event?.dataState?.sort?.[0]?.field;
                let sortingOrder_kendo = event?.dataState?.sort?.[0]?.dir;
                if ((sortingField_kendo && sortingOrder_kendo) && (sortingField_kendo !== sortingField || sortingOrder_kendo !== sortingOrder)) {
                    searchPayloadData.sortingField = sortingField_kendo;
                    searchPayloadData.sortingOrder = sortingOrder_kendo;
                    setSortingField(sortingField_kendo);
                    setSortingOrder(sortingOrder_kendo);
                }
                else {
                    if (sortingField_kendo && sortingOrder_kendo) {
                        searchPayloadData.sortingField = sortingField;
                        searchPayloadData.sortingOrder = sortingOrder;
                    }
                    else {
                        searchPayloadData.sortingField = "created_at";
                        searchPayloadData.sortingOrder = "desc";
                        setSortingField("created_at");
                        setSortingOrder("desc");
                    }
                }
            }
            if (isCandidateSearch) {
                await handleCandidateSearchStateChange(searchDecision, searchPayloadData, page, take)
            }
            else {
                await handleCandidateSearchByOrgId(page, take, searchPayloadData)
            }
        }
    }

    const tabChange = async (val) => {
        setOpenedTab(val);
        if (tabSwitchCount === 0) {
            setAllCandidateSearchResponse([]);
            setDataResult1([]);
            let payload = {
                page: 1,
                size: 20,
                sortingField: "created_at",
                sortingOrder: 'desc',
            }
            let _recruitmentpartnerId = userRecruitmentpartnerId;
            setRecruimentPartnerId(_recruitmentpartnerId)
            let allCandidateResponse = await getAllCandidateProfileResponse(_recruitmentpartnerId, payload, false, val)
            setAllCandidateSearchResponse(allCandidateResponse)
            setDataResult1(allCandidateResponse);
            setIsSearching(false)
        }
        setTabSwitchCount(tabSwitchCount + 1)

    };



    return (
        <>
            {(userType === "Candidate") ?
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                        <div className='mytalentBottomContainer'>
                            <Footer />
                        </div>
                    </div>
                </>
                : <>
                    <div className='mainContainer_advanceSearch'>
                        <div topContainer_advanceSearch>
                            <Header />
                            <div className='mainDiv_advanceSearch'>
                                <div className='topSection_advanceSearch'>
                                    <div>
                                        <div className='titlesofCommunity'> Candidate Search</div>
                                        <div className="goBackToRecruiterDashboard" onClick={(e) => goToDashboard(e)}>
                                            <ArrowBackIcon />&nbsp; Go Back to Dashboard
                                        </div>


                                    </div>

                                    <div>
                                        <div className='info-cards-topbar-ats-feed'>



                                            <div className='ats-dashFeed-card'>
                                                <div className='ats-dashFeed-cardlogo'>
                                                    <DataUsageIcon style={{ color: "#2896FE", height: 'auto', width: "40px" }} />
                                                </div>
                                                <div className='ats-dashFeed-cardInfo'>
                                                    <div style={{
                                                        fontWeight: "300",
                                                        color: 'grey'
                                                    }}>Total Community Size</div>
                                                    {!communityStatsLoader ? (

                                                        <div className='communityStatsText'>
                                                            <Skeleton variant="text" width={30} />
                                                        </div>
                                                    ) : (
                                                        <div className='communityStatsText'>{communityStats.numberOfCandidates}</div>
                                                    )}

                                                </div>
                                            </div>


                                            <div className='ats-dashFeed-card'>
                                                <div className='ats-dashFeed-cardlogo'>
                                                    <DateRangeIcon style={{ color: "#2896FE", height: 'auto', width: "40px" }} />
                                                </div>
                                                <div className='ats-dashFeed-cardInfo'>
                                                    <div style={{
                                                        fontWeight: "300",
                                                        color: 'grey'
                                                    }}>Added in last 30 days</div>
                                                    {!communityStatsLoader ? (

                                                        <div className='communityStatsText'>
                                                            <Skeleton variant="text" width={30} />
                                                        </div>
                                                    ) : (
                                                        <div className='communityStatsText'>{communityStats.lastthirtydayescandidates}</div>
                                                    )}


                                                </div>
                                            </div>


                                            <div className='ats-dashFeed-card'>
                                                <div className='ats-dashFeed-cardlogo'>
                                                    <ViewWeekIcon style={{ color: "#2896FE", height: 'auto', width: "40px" }} />
                                                </div>
                                                <div className='ats-dashFeed-cardInfo'>
                                                    <div style={{
                                                        fontWeight: "300",
                                                        color: 'grey'
                                                    }}>Added in last week</div>
                                                    {!communityStatsLoader ? (

                                                        <div className='communityStatsText'>
                                                            <Skeleton variant="text" width={30} />
                                                        </div>
                                                    ) : (
                                                        <div className='communityStatsText'>{communityStats.lastSevenDays}</div>
                                                    )}


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='mainContentContainer_advanceSearch'>
                                    {(openedTab === "Search Candidates") && (
                                        <div className="filterContainer_advanceSearch_candidate">
                                            <div className='topSection_searchFilter'>
                                                <div>
                                                    <span className='showingRF'>Showing results for</span>

                                                    <div className='showMyCandidateContainer'>
                                                        <span className='booleanText'>Candidate Profile Search</span>
                                                    </div>
                                                </div>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="searchButton"
                                                    onClick={(e) => candidateSearchHandler()}
                                                >
                                                    Search
                                                </Button>

                                            </div>

                                            <div className='filterSection_advanceSearch'>
                                                <div className='inputItemsContainer keywordSearchContainer'>
                                                    <div className='keywordSearchTitleContainer'>
                                                        <span className='filterItemTitle'>Search Keywords</span>
                                                        <div className='searchtoggleContainer'>
                                                            <Switch
                                                                checked={booleanFilterOn}
                                                                onChange={(e) => setBooleanFilterOn(!booleanFilterOn)}
                                                                color="primary"
                                                                name="checkedB"
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                            <span className='booleanText'>
                                                                Boolean {booleanFilterOn ? "OR" : "AND"}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <FormControl>
                                                        <Autocomplete
                                                            id="tags-filled"
                                                            freeSolo
                                                            multiple
                                                            filterSelectedOptions
                                                            className='keywordSelectAutocomplete'
                                                            options={keywordOptions}
                                                            value={selectedKeywords}
                                                            onChange={keywordValuechangeHandler}
                                                            getOptionLabel={(option) => option}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    variant='outlined'
                                                                    value={keywordCurrentValue}
                                                                    onChange={(e) => keywordTextFieldChange(e)}
                                                                    placeholder="Enter keywords" />
                                                            )}
                                                        />
                                                        <div className='divider_input'></div>
                                                    </FormControl>
                                                </div>

                                                <div className='skillContainer_advanceSearch'>
                                                    <span className='filterItemTitle'>Primary Skill</span>
                                                    <Autocomplete
                                                        id="free-solo-2-demo"
                                                        multiple
                                                        freeSolo
                                                        filterSelectedOptions
                                                        className='skillSelectAutocomplete'
                                                        options={primarySkillOptions}
                                                        value={primarySkillValues}
                                                        getOptionLabel={(option) => option}
                                                        onChange={primaryskillchangehandler}
                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                variant='outlined'
                                                                value={currentPrimarySkillValue}
                                                                onChange={(e) => primarySkillTextFieldHandler(e)}
                                                                placeholder="Enter Skills" />
                                                        )}
                                                    />
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='inputItemsContainer additionalSkillSelectContainer'>
                                                    <span className='filterItemTitle'>Additional Skills</span>
                                                    <FormControl>
                                                        <Autocomplete
                                                            id="tags-outlined"
                                                            multiple
                                                            freeSolo
                                                            filterSelectedOptions
                                                            className='skillSelectAutocomplete'
                                                            options={additionalSkillOptions}
                                                            value={additionalSkillValues}
                                                            getOptionLabel={(option) => option}
                                                            onChange={additionalSkillChangeHandler}
                                                            renderInput={(params) => (
                                                                <TextField {...params}
                                                                    variant='outlined'
                                                                    value={currentAdditionalSkillValue}
                                                                    onChange={(e) => additionalSkillTextFieldHandler(e)}
                                                                    placeholder="Enter Skills" />
                                                            )}
                                                        />
                                                    </FormControl>
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='inputItemsContainer'>
                                                    <span className='filterItemTitle'>Years of Experience</span>
                                                    <div className='yearsOfExpMinMaxContainer'>
                                                        <TextField
                                                            type='number'
                                                            variant='outlined'
                                                            placeholder='Min'
                                                            className='yoeMinMaxField'
                                                            value={minExperience}
                                                            onChange={minExperienceHandler}
                                                        />
                                                        <span className='yoeto'>to</span>
                                                        <TextField
                                                            type='number'
                                                            variant='outlined'
                                                            placeholder='Max'
                                                            className='yoeMinMaxField'
                                                            value={maxExperience}
                                                            onChange={maxExperienceHandler}
                                                        />
                                                    </div>
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='inputItemsContainer'>
                                                    <span className='filterItemTitle'>Locations</span>
                                                    <div className='locationContainer'>
                                                        <div className='locationItemsContainer'>
                                                            <div className='locationItemTitle'>Country</div>
                                                            <Autocomplete
                                                                id="free-solo-2-demo"
                                                                options={countryList}
                                                                className='locationAutoComplete'
                                                                filterSelectedOptions
                                                                value={userCountry_candidateSearch}
                                                                closeIcon={null}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                onChange={countryHandler_candidateSearch}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper
                                                                        style={{ fontSize: "12px", fontFamily: 'Segoe UI' }}
                                                                    >
                                                                        {children}
                                                                    </Paper>
                                                                )}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder='Country'
                                                                        variant="outlined"
                                                                    />}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div className='locationItemTitle'>State</div>
                                                            <Autocomplete
                                                                id="free-solo-2-demo"
                                                                options={stateList_candidateSearch}
                                                                className="locationAutoComplete"
                                                                value={userState_candidateSearch}
                                                                closeIcon={null}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                onChange={stateHandler_candidateSearch}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper
                                                                        style={{ fontSize: "12px", fontFamily: 'Segoe UI' }}
                                                                    >
                                                                        {children}
                                                                    </Paper>
                                                                )}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder='State'
                                                                        variant="outlined"
                                                                    />}
                                                            />
                                                        </div>

                                                        <div>
                                                            <div className='locationItemTitle'>City</div>
                                                            <Autocomplete
                                                                id="free-solo-2-demo"
                                                                options={cityList_candidateSearch}
                                                                closeIcon={null}
                                                                className='locationAutoComplete'
                                                                value={userCity_candidateSearch}
                                                                onChange={cityHandler_candidateSearch}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper
                                                                        style={{ fontSize: "12px", fontFamily: 'Segoe UI', textWrap: "wrap" }}
                                                                    >
                                                                        {children}
                                                                    </Paper>
                                                                )}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder='City'
                                                                        variant="outlined"
                                                                    />}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='CurrencyContainer'>
                                                    <div className='otherCompanyNameContainer'>
                                                        <div className='locationItemTitle'>Select Currency</div>
                                                        <Autocomplete
                                                            id="free-solo-2-demo"
                                                            options={currencyCodes}
                                                            value={selectedCurrency}
                                                            onChange={currencyChangeHandler}
                                                            getOptionLabel={(option) => option || ""}
                                                            PaperComponent={({ children }) => (
                                                                <Paper
                                                                    style={{ fontSize: "13px", fontFamily: 'Segoe UI', textWrap: "wrap" }}
                                                                >
                                                                    {children}
                                                                </Paper>
                                                            )}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    placeholder='Currency'
                                                                    variant="outlined"
                                                                />}
                                                        />
                                                    </div>
                                                    <div className='divider_input'></div>

                                                    <div className='employementContainer'>
                                                        <div className='employmentItems'>
                                                            <div className='locationItemTitle'>Current Salary</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="0"
                                                                    className="admin_textbox"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    value={currentSalaryValue}
                                                                    onChange={currentSalaryHandler}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className='employmentItems'>
                                                            <div className='locationItemTitle'>Expected Salary</div>
                                                            <FormControl fullWidth variant="outlined">
                                                                <OutlinedInput
                                                                    placeholder="No limit"
                                                                    className="admin_textbox"
                                                                    type="number"
                                                                    inputProps={{ min: 0 }}
                                                                    value={expectedSalaryValue}
                                                                    onChange={expectedSalaryHandler}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='inputItemsContainer'>
                                                    <span className='filterItemTitle'>Employment</span>
                                                    <div className='employementContainer'>
                                                        <div className='employmentItems'>
                                                            <div className='locationItemTitle'>Industry</div>
                                                            <Autocomplete
                                                                id="free-solo-2-demo"
                                                                options={allIndustries_candidateSearch}
                                                                className='employementAutocomplete'
                                                                closeIcon={null}
                                                                value={selectedIndustry}
                                                                onChange={industryChangeHandler}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper
                                                                        style={{ fontSize: "13px", fontFamily: 'Segoe UI', textWrap: "wrap" }}
                                                                    >
                                                                        {children}
                                                                    </Paper>
                                                                )}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder='Industry'
                                                                        variant="outlined"
                                                                    />}
                                                            />
                                                        </div>
                                                        <div className='employmentItems'>
                                                            <div className='locationItemTitle'>Company</div>
                                                            <Autocomplete
                                                                id="free-solo-2-demo"
                                                                options={allCompanies}
                                                                closeIcon={null}
                                                                className='employementAutocomplete'
                                                                value={selectedCompany}
                                                                onChange={companyChangeHandler}
                                                                getOptionLabel={(option) => option.name || ""}
                                                                PaperComponent={({ children }) => (
                                                                    <Paper
                                                                        style={{ fontSize: "13px", fontFamily: 'Segoe UI', textWrap: "wrap" }}
                                                                    >
                                                                        {children}
                                                                    </Paper>
                                                                )}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder='Company'
                                                                        variant="outlined"
                                                                    />}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        companyNotListed ?
                                                            <div className='otherCompanyNameContainer'>
                                                                <div className='locationItemTitle'>Company Name</div>
                                                                <TextField
                                                                    variant='outlined'
                                                                    placeholder='Enter company name'
                                                                    className='otherCompanyNameTextField'
                                                                    value={selectedUnlistedCompany}
                                                                    onChange={unlistedCompanyValuechangeHandler}
                                                                />
                                                            </div>
                                                            : null
                                                    }
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='inputItemsContainer'>
                                                    <span className='filterItemTitle'>Education</span>
                                                    <Autocomplete
                                                        id="free-solo-2-demo"
                                                        options={degrees}
                                                        multiple
                                                        className='educationAutocomplete'
                                                        filterSelectedOptions
                                                        value={selectedEducation}
                                                        getOptionLabel={(option) => option.degreename || ""}
                                                        onChange={educationHandler}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                placeholder='Select educations'
                                                                variant="outlined"
                                                            />}
                                                    />
                                                    <div className='divider_input'></div>
                                                </div>

                                                <div className='inputItemsContainer othersContainer'>
                                                    <div className='locationItemTitle'>Notice Period (Days)</div>
                                                    <Autocomplete
                                                        id="free-solo-2-demo"
                                                        options={dataForNoticePeriod}
                                                        className='daysAutoComplete'
                                                        value={noticeperiod}
                                                        onChange={noticeperiodHandler}
                                                        getOptionLabel={(option) => option.label || ""}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder='Notice period time'
                                                            />}
                                                    />
                                                </div>

                                                <div className='inputItemsContainer'>
                                                    <span className='filterItemTitle'>Percentage Score</span>
                                                    <div className='sliderContainer'>
                                                        <Slider
                                                            className="sliderInputFeild"
                                                            aria-labelledby="continuous-slider"
                                                            max={100}
                                                            min={0}
                                                            step={1}
                                                            value={scorePercentage}
                                                            onChange={percentageScoreHandler}
                                                        />
                                                        <span className='sliderValue'>{scorePercentage < 100 ? `${scorePercentage} - 100%` : '100%'}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}
                                    <div className={openedTab === 'Search Candidates' ? 'KendoContainer_CandidateSearch' : 'KendoContainer_RecommendedCandidate'}>
                                        <div className='kendoTabsContainer'>
                                            <div className={`kendoTab ${openedTab === 'Search Candidates' ? "kendoTabOpen" : ""}`} onClick={(e) => openedTab === 'Search Candidates' ? null : tabChange('Search Candidates')}>Search Candidates</div>
                                            {(props.requisitionIdVal) && (
                                                <div className={`kendoTab ${openedTab === 'Recommended Candidates' ? "kendoTabOpen" : ""}`} onClick={(e) => openedTab === 'Recommended Candidates' ? null : tabChange('Recommended Candidates')}>Recommended Candidates</div>
                                            )}
                                        </div>
                                        <div className='kendo_contains'>
                                            <div className='searchresultsText_div'>
                                                <span>Search Results...</span>
                                                <button onClick={excelExport} className='excelButton'><img src={ExcelImg} className='excelImg_advanceSearch' /><span>Export</span></button>
                                            </div>
                                            {props.requisitionIdVal && openedTab === 'Recommended Candidates' ?

                                                <GridDiv
                                                    filterable={false}
                                                    filter={dataState.filter}
                                                    sort={dataState.sort}
                                                    filterOperators={filterOperators}
                                                    sortable={true}
                                                    resizable={true}
                                                    reorderable={true}
                                                    pageable={{
                                                        pageSizes: [10, 20, 50, 100],
                                                        info: true,
                                                        previousNext: true,
                                                        buttonCount: 10
                                                    }}
                                                    total={totalCandidateCount_RecommendedCandidates}
                                                    skip={dataState.skip}
                                                    take={dataState.take}
                                                    onDataStateChange={(e) => dataStateChange_recommendedCandidates(e)}
                                                    {...dataState}
                                                    data={dataResult}
                                                    className='kendo_candidateSearch'
                                                >
                                                    <GridNoRecords>
                                                        {isSearching_recommended ? getSkeleton_candidateSearch() : "No results found !"}
                                                    </GridNoRecords>
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        width= {((userType === "Admin" && userRole==="Recruiter") || userType === "Recruiter" )? "220px": "100px"} 
                                                        filterable={false}
                                                        title=""
                                                        cell={handleCandidateLink_candidatesearch}
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="name"
                                                        title="Candidate Name"
                                                        width="150px"
                                                    />

                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="emailID"
                                                        title="Email"
                                                        width="250px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="primarySkill"
                                                        title="Primary Skill"
                                                        width="200px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        title="Additional Skills"
                                                        width="450px"
                                                        filterable={false}
                                                        cell={handleAdditionalSkillsField}
                                                        sortable={false}
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="country"
                                                        title="Country"
                                                        width="150px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="state"
                                                        title="State"
                                                        width="150px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="city"
                                                        title="City"
                                                        width="150px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        title="Educational Degree"
                                                        field="degreeField"
                                                        filterable={true}
                                                        width="400px"
                                                        sortable={false}
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="yr_of_exp"
                                                        title="Years of Experience"
                                                        filter="numeric"
                                                        format="{0:c}"
                                                        width="170px"
                                                        cell={handleYearsOfExpField}
                                                    />
                                                </GridDiv>

                                                :

                                                <GridDiv
                                                    filterable={true}
                                                    filter={dataState1.filter}
                                                    sort={dataState1.sort}
                                                    filterOperators={filterOperators}
                                                    sortable={true}
                                                    resizable={true}
                                                    reorderable={true}
                                                    pageable={{
                                                        pageSizes: [10, 20, 50, 100],
                                                        info: true,
                                                        previousNext: true,
                                                        buttonCount: 10
                                                    }}
                                                    total={totalCandidateCount}
                                                    skip={dataState1.skip}
                                                    take={dataState1.take}
                                                    onDataStateChange={dataStateChange}
                                                    {...dataState1}
                                                    data={dataResult1}
                                                    className='kendo_candidateSearch'
                                                >
                                                    <GridNoRecords>
                                                        {isSearching ? getSkeleton_candidateSearch() : "No results found !"}
                                                    </GridNoRecords>
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        width={((userType === "Admin" && userRole==="Recruiter") || userType === "Recruiter" )? "220px": "100px"} 
                                                        filterable={false}
                                                        title=""
                                                        cell={handleCandidateLink_candidatesearch}
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="name"
                                                        title="Candidate Name"
                                                        width="150px"
                                                    />

                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="emailID"
                                                        title="Email"
                                                        width="250px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="primarySkill"
                                                        title="Primary Skill"
                                                        width="200px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        title="Additional Skills"
                                                        width="450px"
                                                        filterable={false}
                                                        cell={handleAdditionalSkillsField}
                                                        sortable={false}
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="country"
                                                        title="Country"
                                                        width="150px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="state"
                                                        title="State"
                                                        width="150px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="city"
                                                        title="City"
                                                        width="150px"
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        title="Educational Degree"
                                                        field="degreeField"
                                                        filterable={true}
                                                        width="400px"
                                                        sortable={false}
                                                    />
                                                    <GridColumn
                                                        className={classes.fontRoboto}
                                                        field="yr_of_exp"
                                                        title="Years of Experience"
                                                        filter="numeric"
                                                        format="{0:c}"
                                                        width="170px"
                                                        cell={handleYearsOfExpField}
                                                    />
                                                    {serachingThroughTestScore ?
                                                        <GridColumn
                                                            className={classes.fontRoboto}
                                                            field="testnames"
                                                            title="Test Name"
                                                            width="250px"
                                                            sortable={false}
                                                        />
                                                        : null}
                                                    {serachingThroughTestScore ?
                                                        <GridColumn
                                                            className={classes.fontRoboto}
                                                            field="testskills"
                                                            title="Test Skills"
                                                            width="250px"
                                                            sortable={false}
                                                        />
                                                        : null}
                                                    {serachingThroughTestScore ?
                                                        <GridColumn
                                                            className={classes.fontRoboto}
                                                            field="testScores"
                                                            title="Test Scores"
                                                            width="170px"
                                                            filterable={false}
                                                        />
                                                        : null}
                                                </GridDiv>
                                            }
                                            <ExcelExport data={dataResult1} ref={_export}>
                                                <ExcelExportColumn field="name" title="Candidate Name" width="150px" />
                                                <ExcelExportColumn field="emailID" title="Email" width="250px" />
                                                <ExcelExportColumn field="primarySkill" title="Primary Skill" width="200px" />
                                                <ExcelExportColumn title="Additional Skills" width="450px" field='allAdditionalSkill' />
                                                <ExcelExportColumn field="fulladdress" title="Address" width="300px" />
                                                <ExcelExportColumn title="Educational Degree" field="degreeField" width="400px" />
                                                <ExcelExportColumn field="yearsofexp" title="Years of Experience" width="170px" />
                                                {serachingThroughTestScore ?
                                                    <ExcelExportColumn field="testnames" title="Test Name" width="250px" />
                                                    : null}
                                                {serachingThroughTestScore ?
                                                    <ExcelExportColumn field="testskills" title="Test Skills" width="250px" />
                                                    : null}
                                                {serachingThroughTestScore ?
                                                    <ExcelExportColumn field="testScores" title="Test Scores" width="170px" />
                                                    : null}
                                            </ExcelExport>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='footerContainer_advanceSearch'>
                            <Footer />
                        </div>
                    </div>





                </>
            }
            <Backdrop
                className={classes.backdrop}
                open={isExporting}
            >
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}> Please wait ...</Typography>
            </Backdrop>

            {/* Action Dialog */}


            {isActivity && <ActivityModal isActivity={isActivity}
                configJSON={configJSON}
                setisActivity={setisActivity}
                row_DataItem={row_DataItem}
                props={props}
                setEdit={setEdit}
                reqId={reqId}
                email={email}
                classes={classes}
                setCount={() => { }}
                setReqId={setReqId}
                userRecruitmentpartnerId={userRecruitmentpartnerId}
                setAllRequisitionData={setAllRequisitionData}
            />}
            <EditActivity edit={edit} classes={classes} EditIcon={EditIcon} setEdit={setEdit} setisActivity={setisActivity}
                reqId={reqId}
                email={email}
                props={props}

                moveCandidate={()=>{}}
                allRequisitionData={allRequisitionData}
            />
        </>
    )
}

export default AdvanceSearchTalentCommunity