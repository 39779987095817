import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateisAbortedUserTest = async (testId, payload) => {
  let allResponse = {};
  try {
    const getallresponse = await axios.put(
      jsonConfig.apiUrl + "updateAbortedTest/" + testId,
      payload,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = getallresponse;
    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("Error updateing test result", error);
    return Promise.reject(error);
  }
}

export default updateisAbortedUserTest;