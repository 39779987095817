import axios from "axios";
const jsonConfig = require("../../Config.json");
const fflate = require('fflate');

const pendingResumeById = async (id,searchTerms,pageNumber,pageSize,userType) => {
    try {
        let payload_data = {
            searchdata: [],
            userType: userType
        };
        searchTerms?.forEach((term) => {
            const { field, value, operator } = term;
    
        
            payload_data.searchdata.push({
                [field]: value,
                operator: operator,
            });
        });
        
        const getallresponse = await axios.post(
            jsonConfig.apiUrl + `pendingResume/${id}?page=${pageNumber}&size=${pageSize}`,payload_data,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        let decompressedObject;
        if (getallresponse?.data && getallresponse?.data?.compressedbase64string) {
            const strData = atob(getallresponse.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Failed to fetch candidate resumes: Response data is empty or missing.");
        }

        return Promise.resolve(decompressedObject);
    } catch (error) {
        console.error("Error fetching candidate resumes:", error);
        return Promise.reject(error);
    }
};
export default pendingResumeById;