import axios from "axios";
const jsonConfig = require("../../Config.json");

const globalConfigurationResponse = async () => {
  let allResponse = {}
  try {
    const response = await axios.get(
      jsonConfig.apiUrl + "GlobalConfiguration",
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
      
      allResponse = response;
      return Promise.resolve(allResponse)
  }
  catch (error) {
      console.log("Error Invite Candidate",error)
      return Promise.reject(error)
  }
}

export default globalConfigurationResponse;