import axios from "axios";
const jsonConfig = require("../Config.json");
const fflate = require('fflate');

const searchCandidateAdminAPI = async (time, searchTerms,pageNumber,pageSize) => {
    let allResponse = {}
    try {
        let payload_data = {
            lasttesttaken : time,
            searchdata: []
        };
        searchTerms?.forEach((term) => {
            const { field, value, operator } = term;
    
        
            payload_data.searchdata.push({
                [field]: value,
                operator: operator,
            });
        });
        
        console.log('payload_data', payload_data)
        const getallresponse = await axios.post(
            jsonConfig.apiUrl + `searchnewprofiledata?page=${pageNumber}&size=${pageSize}`,payload_data,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
       
        let decompressedObject;
        if (getallresponse?.data && getallresponse?.data?.compressedbase64string) {
            const strData = atob(getallresponse.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } 
        return Promise.resolve(decompressedObject)
    }
    catch (error) {
      console.log("Error", error);
        return Promise.reject(error)
    }
};
export default searchCandidateAdminAPI;