import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlusIcon from "./../../resources/images/plus_icon.png";
import './addPrimarySkill.css';
//initialising kendo grid
import {
  Grid as GridDiv,
  GridColumn,
  GridToolbar,
  GridNoRecords
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query"
import EditIcon from "@material-ui/icons/Edit";
/*** Components */
import Header from "../../components/adminHeader/adminHeader";
import Modal from "../../components/modal/";
/*** Components */
/*** Forms */
import AddSkillForm from "../../forms/addSkillForm";
/*** Forms */
/*** Tables */
import AddSkillAdminTable from "../../tables/addSkillAdminTable";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
/*** Tables */
/*** Apis */
import getAllDomains from "../../apis/getAllDomains";
import getAllSkills from "../../apis/getAllSkills";
import getAllClients from "../../apis/getAllClients";
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';
/*** Apis */
const AddPrimarySkill = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const {  userRecords } = useContext(UserTypeContext);
const userRecruitmentpartnerId =  userRecords?.recruitmentpartnerId;

  const [allSkills, setAllSkills] = useState([]);
  const [allDomains, setAllDomains] = useState([]);
  const [skillId, setSkillId] = useState("");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [allPublicSkill, setallPublicSkill] = useState([]);
  const [allPrivetSkill, setallPrivetSkill] = useState([]);
  const [clientData, setClientData] = React.useState([]);
  const [editSkill, setEditSkill] = React.useState(null);
  const [searchKey, setSearchKey] = React.useState("");
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const useStyles = makeStyles({
    fontRoboto: {
      fontFamily: "Roboto,sans-serif",
    },
    button: {
      marginBottom: "10px",
    },
  });
  const filteredPublicSkills = allPublicSkill.filter((val) => {
    if (searchKey === "") {
      return val;
    } else if (val.name.toLowerCase().includes(searchKey.toLowerCase())) {
      return val;
    }
  });
  const classes = useStyles();

  const filteredPrivateSkills = allPrivetSkill.filter((val) => {
    if (searchKey === "") {
      return val;
    } else if (val.name.toLowerCase().includes(searchKey.toLowerCase())) {
      return val;
    }
  });
  //initialising result for kendo filter
  const [result, setResult] = React.useState(process(filteredPublicSkills, dataState));
  //updating the change
  useEffect(() => {
    setResult(process(filteredPublicSkills, dataState));
  }, [allPublicSkill])
  useEffect(() => {
    const getData = async () => {
      try {
        const allSkillsResponse = await getSkills();
        const allDomainResponse = await getAllDomains();
        if (allDomainResponse.status === 200) {

          setisDataLoaded(true);
          setAllDomains(
            allDomainResponse.data ? allDomainResponse.data.data.filter((item) => item.isActive === true) : []
          );
          setisDataLoaded(true);
        }

      } catch (e) {
        console.log(e);
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    };
    if (userType === "Admin") {
      getData();
    } else {
      setisDataLoaded(true);
    }

  }, []);
  const onDataStateChange = (event) => {
    setResult(process(filteredPublicSkills, event.dataState));
    setDataState(event.dataState);
  }
  const openModal = (id) => {
    setmodalIsOpen(true);
  };
  const handleEdit = (skill) => {
    if (skill) {
      setEditSkill(skill);
      setmodalIsOpen(true);
    }
  };
  const closeModal = () => {
    setmodalIsOpen(false);
    //Would like to keep from seeing flashing "Add" on close of Edit
    setSkillId("");
    setEditSkill(null);
  };
  const getSkills = async () => {

    const allSkillResponse = await getAllSkills();
    setAllSkills(allSkillResponse ? allSkillResponse.data.data : []);
    setallPrivetSkill(allSkillResponse.data.data.filter((i) => i.isPrivate));
    setallPublicSkill(allSkillResponse.data.data.filter((i) => !i.isPrivate));

  };
  const getClients = async () => {
    try {
      const allResponseClient = await getAllClients(userRecruitmentpartnerId);
      setClientData(allResponseClient ? allResponseClient.data.data : []);
    } catch (err) {
      console.log(err);
    }
  };
  const refreshSkills = async () => {
    const r = await getSkills();
    // setAllSkills(r.data.data);
    // setallPrivetSkill(r.data.data.filter((i) => i.isPrivate));
    // setallPublicSkill(r.data.data.filter((i) => !i.isPrivate));
    // setLoader(false);
  };
  const closeSaveModal = async () => {
    closeModal();
    refreshSkills();
  };


  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
    float: "right",
    marginTop: "20px",
  };
  const isActiveStyle = {
    textTransform: "capitalize",
  };
  const cellStyle = {
    textAlign: "left",
  };
  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt_100">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <div className="card talent_community">
                    <div>
                      <p className="fs-semi-bold fs-30 f-color mb_12">Skills</p>
                    </div>
                    <div className="admin_skill_topsection">
                      <div className="admin_skill_leftsection">
                      </div>
                      <div className="admin_skill_rightsection">
                        <Button variant="outlined" style={btnGreen} onClick={openModal} >
                          Add Skill
                        </Button>
                      </div>
                    </div>
                    {/* <hr class="my_talent_title_line"></hr> */}
                    <div className="my_talent_search_container Skill_serch_right_wrapper">
                    </div>

                    <div>
                      <div className="tabs">
                        <div className="tab my_talent_table_design"></div>
                        {/* start of kendoGrid */}
                        <GridDiv
                          data={result}
                          filter={dataState.filter}
                          sortable={true}
                          reorderable={true}
                          filterable={true}
                          pageable={{
                            buttonCount: 10,
                            info: true,
                            previousNext: true,
                            pageSizes: true,
                            serverPaging: true,
                            serverFiltering: true,
                          }}
                          style={{
                            maxHeight: "70vh",
                            width: "100%",
                            overflow: 'hidden',
                          }}
                          onDataStateChange={onDataStateChange}
                          {...dataState}
                          resizable={true}
                        >
                          {!isDataLoaded && (
                            <GridNoRecords>
                              <div className='col-4'>
                                <br />
                                <Loader size='large' themeColor="info" type="converging-spinner" />
                              </div>
                            </GridNoRecords>
                          )}
                          <GridColumn
                            className={classes.fontRoboto}
                            title="Edit"
                            width="100px"
                            filterable={false}
                            cell={(event) => {
                              return (
                                <td className='editCenter'>
                                  <Button onClick={() => handleEdit(event.dataItem)}
                                    variant="contained"
                                    color="primary"
                                  >Edit</Button>
                                </td>
                              );
                            }} />
                          <GridColumn
                            className={classes.fontRoboto}
                            field="name"
                            title="Skill"
                            width="200px"
                            filterable={true}
                            sortable={true}
                          />
                          <GridColumn
                            className={classes.fontRoboto}
                            field="domain.name"
                            title="Domain"
                            width="400px"
                          />
                          <GridColumn
                            className={classes.fontRoboto}
                            title="Is Active"
                            field="isActive"
                            width="150px"
                            filterable={true}
                            filter={"boolean"}
                            cell={(event) => {
                              return (
                                <td>
                                  {event.dataItem?.isActive ? "True" : "False"}
                                </td>
                              );
                            }}
                          />

                        </GridDiv>
                        {/* end of kendoGrid */}
                      </div>
                    </div>
                  </div>
                ) : userType === "" ? (<div className="row padding_80 text-center">
                  <p className="fs-semi-bold fs-40 f-color">
                    Loading...
                  </p>
                </div>) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal open={modalIsOpen}>
        <AddSkillForm
          userIdType={userIdType}
          domainOptions={allDomains}
          onCancel={closeModal}
          onSave={closeSaveModal}
          skillId={skillId}
          clientData={clientData}
          editSkill={editSkill}
        />
      </Modal>
    </>
  );
};
export default AddPrimarySkill;