import React, { useState, useEffect, useRef } from "react";
import './primarySkills.css';

import pencil from './images/pencil.png';
import Skeleton from '@material-ui/lab/Skeleton';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import outlineSvg from './images/outline.svg';
import fillSvg from './images/fill.svg';
import cross from "./images/cross.svg"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';

import getTotalYearsExperience from "../../../apis/experience/getTotalYearsOfExp";
import pimaryMySkillByProfileIdResponse from "../../../apis/profile/primaryMySkillByProfileId";
import companyBySkillId from "../../../apis/company/companyBySkillId";
import subSkillBySkillIdResponse from "../../../apis/skills/SubSkillBySkillId";
import companyMySkill from "../../../apis/company/companyMySkill";
import addNewSkillResponse from "../../../apis/postNewSkill";
import updateMySkillResponse from "../../../apis/skills/updateMySkills";
import createMySkill from "../../../apis/skills/createMyskill";

import getAllActiveSkill from '../../../apis/skills/getActiveSkills'
const useStyles = makeStyles((theme) => ({
   buttonAlign: {
      display: 'flex',
      alignItems: 'center',
      padding: '24px 42px 24px 42px !important'
   },
   bodyForOtherSkill: {
      // padding: "0px 42px 0px 42px!important",
      marginTop: "70px"
   },
   buttonAlignForRemove: {
      padding: "32px!important"
   },
   bodyForRemove: {
      padding: "26px 24px 0px 24px!important",
      marginTop: "0px!important"
   }

}));

const filterOptions = createFilterOptions({
   matchFrom: 'start',
   stringify: (option) => option.name,
});

const styles = (theme) => ({
   root: {
      margin: 0,
      padding: theme.spacing(1)
   },
   closeButton: {
      position: 'absolute',
      right: theme.spacing(3.5),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
   }
});
const DialogTitle = withStyles(styles)((props) => {
   const { children, classes, onClose, ...other } = props;
   return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
         <Typography variant="h6">{children}</Typography>
         {onClose ? (
            <IconButton
               aria-label="close"
               className={classes.closeButton}
               onClick={onClose}
            >
               <CloseIcon />
            </IconButton>
         ) : null}
      </MuiDialogTitle>
   );
});


const DialogContent = withStyles((theme) => ({
   root: {
   }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
   root: {
      margin: 0,
      padding: theme.spacing(2)
   }
}))(MuiDialogActions);
const DialogContentForDelete = withStyles((theme) => ({
   root: {
      padding: "0px 42px 0px 42px!important"
   }
}))(MuiDialogContent);

const PrimarySkills = ({ setLastUpdate, userId, parentCallback }) => {
   const classes = useStyles();
   const [editableSkillId, seteditableSkillId] = useState();
   const [primaryskillName, setprimaryskillName] = useState();
   const [primaryskillData, setprimaryskillData] = useState({});
   const [PrimarySkillRateing, setPrimarySkillRateing] = useState(1);
   const [skillId, setskillId] = useState("");
   const [displaySkillField, setdisplaySkillField] = useState(false);
   const [otherSkillName, setotherSkillName] = useState("");
   const [editprimaryskillModal, seteditprimaryskillModal] = useState(false);
   const [AllSkillsarray, getAllSkillsarray] = useState([]);
   const [experienceYears, setexperienceYears] = useState(0)
   const [experienceMonths, setexperienceMonths] = useState(0)
   const [modalPrimarySkillRating, setmodalPrimarySkillRating] = useState(0);
   const [primarySkillProfile, SetPrimarySkillProfile] = useState({});
   const [modalCompanyArray, setModalCompanyArray] = useState([]);
   const [modalCompanySelected, setModalCompanySelected] = useState([]);
   const [subSkill, setSubSkill] = useState([]);
   const [allTotalExpYear, setAllTotalExpYear] = useState([]);
   const [totalExpYearSelected, setTotalExpYearSelected] = useState("");
   const [totalExpYearSelectedObject, setTotalExpYearSelectedObject] = useState({});
   const [openAddPrimarySkillModal, setOpenAddPrimarySkillModal] = useState(false);
   const [addSkillRating, setAddSkillRating] = useState(0);
   const [companySelectedArrayForAdd, setCompanySelectedArrayForAdd] = useState([]);
   const [loadingController, setLoadingController] = useState(false);
   const [EditPrimarySkillDescription, setEditPrimarySkillDescription] = useState('');
   const [star, setStar] = useState('');
   const [starToggle, setStarToggle] = useState(true);
   const [discriptionforadd, setdiscriptionforadd] = useState("")
   const [handleSkillarr, setHandleSkillarr] = useState([]);
   const [btnClicked, setBtnClicked] = useState(false);
   const [skillJson, setSkillJson] = useState(null);
   const [skillChanging, setSkillChanging] = useState(false);


   const starRatingCaluculation = (starRating) => {
      const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
      if (starRating) {
         const arr = starArr.fill(fillSvg, 0, starRating);
         return arr.map((item, i) => (
            <img src={item} alt='item' key={i}/>
         ))
      } else {
         return starArr.map((item, i) => (
            <img src={item} alt='item' key={i}/>
         ))
      }
   }

   const fnstarRatingCaluculation = (modalPrimarySkillRating) => {
      const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
      if (modalPrimarySkillRating) {
         const arr = starArr.fill(fillSvg, 0, modalPrimarySkillRating);
         setStar(arr);
      } else {
         setStar(starArr);
      }
   }

   const starClicked = (clickIndex, index) => {
      setStarToggle(prev => !prev);
      const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
      if (starToggle && clickIndex) {
         const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
         setStar(arr);
         setmodalPrimarySkillRating(clickIndex + 1);

      } else {
         const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
         setStar(arr);
         setmodalPrimarySkillRating(clickIndex + 1);
      }
   }

   const getTotalExpYear = async () => {
      try {
         const TotalExpYearresponse = await getTotalYearsExperience();
         setAllTotalExpYear(TotalExpYearresponse);
      } catch (error) {
         console.error(error?.response?.data);
      }
   };

   const getSkillResponse = async () => {
      try {
         const getallskillresponse = await getAllActiveSkill();
         let allSkillsList = getallskillresponse;
         getAllSkillsarray(allSkillsList)
         setotherSkillName("");
         setexperienceYears(0);
         setexperienceMonths(0);
      }
      catch (error) {
         console.error(error);
      }
   }
   // useEffect(() => {
   //    getSkillResponse();
   // }, [])

   const getPrimarySkill = async () => {
      try {
         const primaryskillResponse = await pimaryMySkillByProfileIdResponse(userId);
         SetPrimarySkillProfile(primaryskillResponse.data.data[0].skill);
         seteditableSkillId(primaryskillResponse.data.data[0].id);
         setEditPrimarySkillDescription(primaryskillResponse.data.data[0].description);
         setprimaryskillData(primaryskillResponse?.data?.data[0]);
         setprimaryskillName(primaryskillResponse.data.data[0].skill.name);
         setLastUpdate(primaryskillResponse.data.data[0].profile.lastUpdated);
         setPrimarySkillRateing(primaryskillResponse.data.data[0].selfRating);
         setexperienceYears(primaryskillResponse.data.data[0].experienceYears);
         setexperienceMonths(primaryskillResponse.data.data[0].experienceMonths);
         setTotalExpYearSelected(primaryskillResponse.data.data[0].totalexperienceid)
         setSkillJson(JSON.parse(primaryskillResponse.data.data[0].subSkillsJson));
         setLoadingController(true);
         parentCallback(primaryskillResponse.data.data[0].skill.name)
         try {
            const id = primaryskillResponse.data.data[0].id
            const getCompanyByMySkillResponse = await companyBySkillId(id);
            let v = getCompanyByMySkillResponse?.data?.data[0].company
            if (v.length > 0) {
               setModalCompanySelected(getCompanyByMySkillResponse?.data?.data[0].company);
            }
            else { setModalCompanySelected([{ name: '' }]) }


         } catch (error) { }
         if (primaryskillResponse.data.data[0].skill.isActive === false) {
            setskillId("other");
            setdisplaySkillField(true);
            setotherSkillName(primaryskillResponse.data.data[0].skill.name)
         }
         else {
            setskillId(primaryskillResponse.data.data[0].skillId);
            setdisplaySkillField(false);
            setotherSkillName("");
            try {
               const id = primaryskillResponse.data.data[0].skillId;
               const getAllSubSkillsByPrimarySkill = await subSkillBySkillIdResponse(id);
               setSubSkill(getAllSubSkillsByPrimarySkill.data.data);
            } catch (error) { console.error("subskillerror", error.response.data) }
         }
      } catch (error) {
         console.error(error)
         setLoadingController(true);
      }
   };

   const selectTotalExpHandler = (event, value) => {
      setTotalExpYearSelected("");
      if (value === null) {
         setTotalExpYearSelected('')
      }
      else if (value) {
         setTotalExpYearSelected(value.id);
      }

   }

   const starClickedForEdit = (clickIndex) => {
      setStarToggle(prev => !prev);
      const starArr = [outlineSvg, outlineSvg, outlineSvg, outlineSvg, outlineSvg]
      if (starToggle && clickIndex) {
         const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
         setStar(arr);
         setmodalPrimarySkillRating(clickIndex + 1);
      }
      else {
         const arr = starArr.fill(fillSvg, 0, clickIndex + 1);
         setStar(arr);
         setmodalPrimarySkillRating(clickIndex + 1);
      }
   }
   const openprimaryskilleditmodal = async () => {

      setSkillChanging(false);
      fnstarRatingCaluculation(primaryskillData?.selfRating);
      await getTotalExpYear();
      await getPrimarySkill();
      getSkillResponse();
      setmodalPrimarySkillRating(primaryskillData?.selfRating);
      setTotalExpYearSelectedObject({ name: primaryskillData?.totalexperiencename, id: primaryskillData?.totalexperienceid });
      seteditprimaryskillModal(true);

      let arrayDataYears = [];
      for (let i = 0; i <= 20; i++) {
         arrayDataYears.push(i);
      }
      let arrayDataMonths = [];
      for (let i = 0; i <= 11; i++) {
         arrayDataMonths.push(i);
      }
      setexperienceYears(primaryskillData.experienceYears);
      setexperienceMonths(primaryskillData.experienceMonths);
   }
   const levelCreation = (starval) => {
      var level = '';
      if (starval === 1) {
         level = 'Novice';
      }
      else if (starval === 2) {
         level = 'Beginner';
      }
      else if (starval === 3) {
         level = 'Competent';
      }
      else if (starval === 4) {
         level = 'Proficient';
      }
      else if (starval === 5) {
         level = 'Expert';
      }
      else {
         level = ''
      }
      return (
         <p className="fs-semi-bold fs-18 f-color mb_3">{level}</p>
      )
   };

   const skillChangeAutoComplete = async (event, value) => {
      setSkillChanging(true);
      setskillId("");
      if (value) {
         if (value.id === "other") {
            setdisplaySkillField(true);
            setskillId(value.id);
         }
         else {
            setdisplaySkillField(false);
            setskillId(value.id);
         }
         try {
            const id = value.id;
            const getAllSubSkills = await subSkillBySkillIdResponse(id);
            setSubSkill(getAllSubSkills.data.data);
         } catch (error) {
            console.error("subskillerror", error.response.data);
            setSubSkill([])
         }
      }
      else {
         setSubSkill([]);
      }
   };
   const extraSkillHandler = (event) => {

      setotherSkillName(event.target.value)
   };

   const updatePrimarySkill = async () => {     
      if (skillId === "" || totalExpYearSelected === "" || totalExpYearSelected === null || modalPrimarySkillRating === 0 || modalPrimarySkillRating === null) {
         NotificationManager.error("Select the required fields", "Error");
      }
      else if (skillId === "other" && otherSkillName === "" || totalExpYearSelected === "") {
         NotificationManager.error("Select the required fields", "Error");
      }
      else {
         if (modalCompanyArray.length === 0) {
            const deleteCompany = [{
               mySkillId: editableSkillId,
               companyId: ""
            }]
            try {
               await companyMySkill(deleteCompany);
            } catch (error) { console.error("companiesdeleteresponse", error) }

         }
         else if (modalCompanyArray.length > 0) {
            try {
               await companyMySkill(modalCompanyArray);
            } catch (error) { console.error("companiesupdateresponse", error) }
         }

         if (skillId === 'other') {
            var addSkill = {
               "name": otherSkillName,
               "isPrimary": false,
               "isActive": false,
               "createdBy": userId,
               "updatedBy": userId
            }
            const subJsonArr = subSkill.map(item => {
               const subJson = {
                  id: item.subSkillId,
                  name: item.name
               }
               return subJson
            });
            try {
               const response = await addNewSkillResponse(addSkill);
               var skillData = {
                  "experienceYears": experienceYears,
                  "experienceMonths": experienceMonths,
                  "skillId": response.data.data.id,
                  "profileId": userId,
                  "description": EditPrimarySkillDescription,
                  "selfRating": modalPrimarySkillRating,
                  "isPrimary": true,
                  "createdBy": userId,
                  "updatedBy": userId,
                  "totalExperienceId": totalExpYearSelected,
                  "subSkillsJson": skillChanging ? subJsonArr : (skillChanging && btnClicked) ? handleSkillarr : handleSkillarr
               };
               try {
                  const payload = {
                     id: editableSkillId,
                     data: skillData,
                  }
                  await updateMySkillResponse(payload);
                  seteditprimaryskillModal(false)
                  setmodalPrimarySkillRating(0)
                  NotificationManager.success('Skill updated', 'Success');
                  getPrimarySkill();
               } catch (err) {
                  NotificationManager.error('Skill updation failed', 'Error');
               }
            } catch (err) {
               console.log(err);
            }
         }
         else {
            const subJsonArr = subSkill.map(item => {
               const subJson = {
                  id: item.subSkillId,
                  name: item.name
               }
               return subJson
            })
         
            var skillData = {
               "experienceYears": experienceYears,
               "experienceMonths": experienceMonths,
               "skillId": skillId,
               "profileId": userId,
               "description": EditPrimarySkillDescription,
               "selfRating": modalPrimarySkillRating,
               "isPrimary": true,
               "createdBy": userId,
               "updatedBy": userId,
               "totalExperienceId": totalExpYearSelected,
               "subSkillsJson": skillChanging ? subJsonArr : (skillChanging && btnClicked) ? handleSkillarr : skillJson
            }
            try {
               const payload = {
                  id: editableSkillId,
                  data: skillData,
               }
               await updateMySkillResponse(payload);
               seteditprimaryskillModal(false)
               setmodalPrimarySkillRating(0)
               NotificationManager.success('Skill updated', 'Success');
               getPrimarySkill();
            } catch (err) {
               NotificationManager.error('Skill updation failed', 'Error');
            }
         }

      }
   }
   const closeEditModal = () => {
      seteditprimaryskillModal(false);
      getPrimarySkill();
   };

   const closeAddModal = () => {
      setOpenAddPrimarySkillModal(false);
   }
   const openAddModal = async () => {
      setSkillChanging(false);
      setOpenAddPrimarySkillModal(true);
      await getPrimarySkill();
      await getTotalExpYear();
      fnstarRatingCaluculation();
   }

   const skillChangeAutoCompleteForAdd = async (event, value) => {
      setSkillChanging(true);
      if (value) {
         if (value.id === "other") {
            setdisplaySkillField(true);
            setskillId(value.id);
         }
         else {
            setdisplaySkillField(false);
            setskillId(value.id);
         }
         try {
            const getAllSubSkills = await subSkillBySkillIdResponse(value.id);
            setSubSkill(getAllSubSkills.data.data);
         } catch (error) {
            setSubSkill([])
         }
      }
      else {
         setSubSkill([]);
         setdisplaySkillField(false);
         setskillId("");
      }
   };

   const extraSkillHandlerAdd = (event) => {

      setotherSkillName(event.target.value)
   };

   const selectTotalExpHandlerForAdd = (event, value) => {
      if (value) {
         setTotalExpYearSelected(value.id);
      }

   }

   const AddPrimarySkill = async () => {
      if (skillId === "" || totalExpYearSelected === "" || addSkillRating === 0) {
         NotificationManager.error("Select The required Fields", "Error");
      }
      else {

         if (skillId === 'other') {
            var addSkill = {
               "name": otherSkillName,
               "isPrimary": false,
               "isActive": false,
               "createdBy": userId,
               "updatedBy": userId
            }
            const subJsonArr = subSkill.map(item => {
               const subJson = {
                  id: item.subSkillId,
                  name: item.name
               }
               return subJson
            });
            try {
               const response = await addNewSkillResponse(addSkill);
               var skillData = {
                  "experienceYears": experienceYears,
                  "experienceMonths": experienceMonths,
                  "skillId": response.data.data.id,
                  "profileId": userId,
                  "description": discriptionforadd,
                  "selfRating": addSkillRating,
                  "isPrimary": true,
                  "totalExperienceId": totalExpYearSelected,
                  "createdBy": userId,
                  "updatedBy": userId,
                  "subSkillsJson": skillChanging ? subJsonArr : (skillChanging && btnClicked) ? handleSkillarr : handleSkillarr
               }
               try {
                  await createMySkill(skillData);
                  setOpenAddPrimarySkillModal(false)
                  NotificationManager.success('Skill updated', 'Success');
                  getPrimarySkill();
               } catch (err) {
                  console.error(err);
                  NotificationManager.error('Skill Add failed', 'Error');
               }
            } catch (err) {
               console.log(err);
            }
         }
         else {
            const subJsonArr = subSkill.map(item => {
               const subJson = {
                  id: item.subSkillId,
                  name: item.name
               }
               return subJson
            });

            var skillData = {
               "experienceYears": experienceYears,
               "experienceMonths": experienceMonths,
               "skillId": skillId,
               "profileId": userId,
               "description": discriptionforadd,
               "selfRating": addSkillRating,
               "isPrimary": true,
               "createdBy": userId,
               "updatedBy": userId,
               "totalExperienceId": totalExpYearSelected,
               "subSkillsJson": skillChanging ? subJsonArr : (skillChanging && btnClicked) ? handleSkillarr : handleSkillarr
            }
            try {
               const response = await createMySkill(skillData);            
               let companyarray = [];
               for (let i = 0; i <= companySelectedArrayForAdd.length - 1; i++) {
                  var obj = {};
                  obj['mySkillId'] = response.data.data.id;
                  obj['companyId'] = companySelectedArrayForAdd[i].id;
                  companyarray.push(obj);
               }
               setOpenAddPrimarySkillModal(false)
               NotificationManager.success('Skill updated', 'Success');
               getPrimarySkill();
            } catch (err) {
               NotificationManager.error('Skill updation failed', 'Error');
            }
         }
      }
   }

   useEffect(() => {
      getPrimarySkill();

   }, [userId]);


   return (
      <>
         {
            loadingController ?
               <>

                  <p className="mn_normal fs-25 f-color">Primary Skill</p>
                  {Object.entries(primaryskillData).length === 0 ?
                     (
                        <>
                           <p className="mn_normal fs-16 mt_12">Add your Primary Skill</p>

                           <div className="row myskill-right mt_9">
                              <button
                                 type="button"
                                 className="btn btn-skill cp"
                                 onClick={openAddModal}
                              >
                                 Add Primary Skill
                              </button>
                           </div>
                        </>) :
                     (
                     <div className="individual-skills ">
                        {/* <div className="df totalexp"> */}
                           <div>
                              <p className="fs-semi-bold fs-18  f-color mb_3 text-upper">{primaryskillName}
                                 <span className="ml_12 cp edit_left">
                                    <img src={pencil} alt="" onClick={openprimaryskilleditmodal} />
                                 </span>
                              </p>
                              <p className="fs-semi-bold fs-12 f-color mb_3">
                                 {
                                    primaryskillData.totalexperiencename ?
                                       (primaryskillData.totalexperiencename) :
                                       null
                                 }
                              </p>
                           </div>
                        {/* </div> */}
                        {
                           PrimarySkillRateing ?
                              <div className="df">
                                 <div className="myskill-right">
                                    <p className="fs-regular fs-18 f-color mb_3">Self Assessment </p>
                                    <div className="rating">
                                       {starRatingCaluculation(parseInt(PrimarySkillRateing))}
                                    </div>

                                    {levelCreation(parseInt(PrimarySkillRateing))}

                                 </div>
                              </div>
                              :
                              <div className="df">
                                 <div className="myskill-right">
                                    <p className="fs-regular fs-18 f-color mb_3">Self Assessment </p>
                                    <div className="rating">
                                       {starRatingCaluculation(parseInt("0"))}
                                    </div>

                                    {levelCreation(parseInt(0))}

                                 </div>
                              </div>
                        }
                     </div>
                     )}

                  <Dialog
                     open={editprimaryskillModal}
                     onClose={closeEditModal}
                     aria-labelledby="customized-dialog-title"
                     maxWidth="sm"
                     fullWidth={true}
                     className='record-video-intro-responsive'
                  >
                     <div className="crossFormodal" onClick={closeEditModal}>< img className="closeicon_set cp" src={cross} alt=""></img></div>
                     <DialogContent className={`${classes.bodyForOtherSkill} responsive-body-primaryskill`}>
                        <div className="select customized-select mt_19">
                           <legend>Primary Skill*</legend>
                           <Autocomplete
                              id="combo-box-select-primary-skill"
                              options={AllSkillsarray}
                              defaultValue={primarySkillProfile}
                              filterOptions={filterOptions}
                              getOptionLabel={(option) => option.name || ""}
                              getOptionSelected={(option, value) => value === "" || value.id === null? true : option.name === value.name}
                              onChange={skillChangeAutoComplete}
                              renderInput={(params) => <TextField {...params} placeholder="Select Primary Skill" variant="outlined" />}
                           />

                        </div>
                        {displaySkillField === true ? (
                           <div className="mt_12">
                              <input
                                 type="text"
                                 className="text_box_cleartalent"
                                 placeholder="Please enter skill name"
                                 value={otherSkillName}
                                 onChange={extraSkillHandler}
                              />
                           </div>
                        ) : (
                           ''
                        )}

                        <div className="select customized-select mt_12">
                           <legend>Primary Skill Experience*</legend>
                           <Autocomplete
                              id="combo-box-demo"
                              options={allTotalExpYear}
                              filterOptions={filterOptions}
                              defaultValue={totalExpYearSelectedObject}
                              getOptionLabel={(option) => option.name || ""}
                              getOptionSelected={(option, value) => value === "" || value.id === null ? true : option.name === value.name}
                              onChange={selectTotalExpHandler}
                              renderInput={(params) => <TextField {...params} placeholder="Select Skill Experience" variant="outlined" />}
                           />
                        </div>

                        <div className="mt_12">
                           <legend>Self Assessment*</legend>
                           <div className="rating_skill">
                              {star && star.map((image, index) => (
                                 <img src={image} alt="starImage" key={index} onClick={() => starClickedForEdit(index)} />
                              ))
                              }
                           </div>
                        </div>

                     </DialogContent>
                     <div className="divider_adjust">
                        <hr className="new3 mt_20 mb_0" />
                     </div>
                     <DialogActions className={`${classes.buttonAlign} responsive-dialog`}>
                        <button type="button" className="btn-big btn-skill cp" onClick={closeEditModal}>Cancel</button>
                        <button type="button" className="btn-big btn_powres ml_5 cp" onClick={updatePrimarySkill} >Save</button>
                     </DialogActions>
                  </Dialog>


                  {/* for add Primary Skill */}



                  <Dialog
                     open={openAddPrimarySkillModal}
                     onClose={closeAddModal}
                     aria-labelledby="customized-dialog-title"
                     maxWidth="sm"
                     fullWidth={true}
                  >
                     <div className="crossFormodal" onClick={closeAddModal}><img className="closeicon_set cp" src={cross} alt=""></img></div>
                     <DialogContent className={`${classes.bodyForOtherSkill} responsive-body-primaryskill`}>
                        <div className="select customized-select mt_19">
                           <legend>Primary Skill*</legend>
                           <Autocomplete
                              // id="combo-box-demo"
                              id="combo-box-select-primary-skill"
                              options={AllSkillsarray}
                              filterOptions={filterOptions}

                              getOptionLabel={(option) => option.name}
                              onChange={skillChangeAutoCompleteForAdd}
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                           />

                        </div>
                        {displaySkillField === true ? (
                           <div className="mt_12">
                              <input
                                 type="text"
                                 className="text_box_cleartalent"
                                 placeholder="Please enter skill name"
                                 value={otherSkillName}
                                 onChange={extraSkillHandlerAdd}
                              />
                           </div>
                        ) : (
                           ''
                        )}
                        <div className="select customized-select mt_12">
                           <legend>Primary Skill Experience*</legend>
                           <Autocomplete
                              id="combo-box-demo"
                              options={allTotalExpYear}
                              filterOptions={filterOptions}
                              getOptionLabel={(option) => option.name}
                              onChange={selectTotalExpHandlerForAdd}
                              placeholder="Select Skill Experience"
                              renderInput={(params) => <TextField {...params} variant="outlined" />}
                           />
                        </div>

                        <div className="mt_12">
                           <legend>Self Assessment*</legend>
                           <div className="rating_skill">
                              {star && star.map((image, index) => (
                                 <img src={image} alt="starImage" key={index} onClick={() => starClicked(index)} />
                              ))
                              }
                           </div>
                        </div>

                     </DialogContent>
                     <div className="divider_adjust">
                        <hr className="new3 mt_20 mb_0" />
                     </div>
                     <DialogActions className={`${classes.buttonAlign} responsive-dialog`}>
                        <button type="button" className="btn-big btn-skill cp" onClick={closeAddModal}>Cancel</button>
                        <button type="button" className="btn-big btn_powres ml_5 cp" onClick={AddPrimarySkill} >Save</button>
                     </DialogActions>
                  </Dialog>
                  <NotificationContainer />
               </>
               :
               <>
                  <div className="powres_prim">
                     <Skeleton variant="text" animation="wave" />
                     <Skeleton variant="rect" animation="wave" height={100} className='mt_16' />
                  </div>
               </>
         }
      </>
   );
};
export default PrimarySkills
