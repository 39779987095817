import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import "./header.css";
import Logo from "./images/logo.png";
import { useHistory } from "react-router";
import Logout from "./../../resources/images/logout.png";
import support from "./images/support.png";
import Share from "./images/share.svg";
import Hamberger from "./images/hamberger.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import validateEmail from '../../utilities/validateEmail';
import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import cancelemailicon from "./images/cancelemail.svg";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import LoggedInSupportModal from "../../components/loggedInSupportModal/loggedInSupportModal";
import sharePoweResumeResponse from "../../apis/sharePoweResume/sharePoweResume";
import profileResponse from "../../apis/profile/profileById";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
}));
const Header = ({ userId, PrimarySkills }) => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const jsonConfig = require("../../Config.json");
  const [navDisplay, setnavDisplay] = useState(false);
  const [mailValidate, setMailValidate] = useState(false);
  const [modalForShare, setmodalForShare] = useState(false);
  const [mailData, setMailData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  });
  const [errorEmailMessage, setErrorEmailMessage] = useState(false);
  const [allmailforshare, setallmailforshare] = useState([]);
  const inputemail = useRef(null);
  const shareMessageRef = useRef(null);
  const getProfileData = async () => {
    try {
      const response = await profileResponse(userId);
      const user = response.data.data[0].user;
      setMailData((prevState) => ({
        ...prevState,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const openSupport = () => {
    setOpen(true);
    getProfileData();
  };

  const setshareMessage = async (e, value) => {
    shareMessageRef.current = e;
    const regex= /^\s*$/g
    const textValue = shareMessageRef.current
    if(regex.test(textValue)){
      setErrorEmailMessage(true)
    }else if(textValue === ''){
      setErrorEmailMessage(false)
    }
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMailValidate(false);
  };

  const sharePoweResumeEmail = async () => {
    const candidateComments = shareMessageRef.current;
    if(inputemail.current && allmailforshare.length === 0){
      NotificationManager.error(`Please enter valid corporate email address and click on "${"Add"}"`, "Error",2500)
      return;
    }
    if(candidateComments === "" || candidateComments === null){
      NotificationManager.error("Enter your message to recruiters/hiring managers", "Error")
      return;
    }
    if (shareMessageRef.current && allmailforshare.length > 0) {
      if (jsonConfig.sendEmail) {
        try {
          let payload = {
            "ProfileId": userId,
            "EmailIDs": allmailforshare,
            "comments":candidateComments,
            "createdBy": userId,
            "updatedBy": userId
          } 
          
          const shareResumeResponse = await sharePoweResumeResponse(payload)
          setmodalForShare(false);
          setallmailforshare([]);
          if(shareResumeResponse.data?.message?.includes("already exist")){
            return(
              NotificationManager.error(
                ` You have already shared your Poweresume `,
                 "Error"
               )
            )
            
           
          }
          else{
            NotificationManager.success(
              "Your PoweResume has been shared",
              "Success"
            );
          }
         
        } catch (err) {
          console.log(err);
          NotificationManager.error(
            "Error sending email. Please try again after some time.",
            "Error"
          );
        }
      } else {
        NotificationManager.error(
          "Enter atleast one email address and a message",
          "Error"
        );
      }
    }
  };
  const showhambergermenu = (e) => {
    if (navDisplay === true) {
      setnavDisplay(false);
    } else {
      setnavDisplay(true);
    }
  };

  const handelcloseshare = () => {
    setmodalForShare(false);
    setallmailforshare([]);
    setErrorEmailMessage(false)
  };

  const openShare = async () => {
    setmodalForShare(true);
    allmailforshare.current = [];
    shareMessageRef.current = null;
    await getProfileData();
  };
  const emailHandler = (e) => {
    inputemail.current = e;
  };
  useEffect(()=>{
    emailHandler()
  }, [modalForShare])
  const addEmailHandler = async () => {
    const mailAddress = inputemail.current;
    const local_email = validateEmail(inputemail.current);
    if (
      local_email &&
      (mailAddress.includes("gmail") ||
        mailAddress.includes("outlook") ||
        mailAddress.includes("yahoo") ||
        mailAddress.includes("hotmail"))
    ) {
      NotificationManager.error("Please Use A Corporate Email", "Error");
    } else if (local_email) {
      await setallmailforshare((prev) => [...prev, inputemail.current]);
    } else {
      NotificationManager.error("Please Enter Correct Email", "Error");
    }
  };
  const deleteEmailHandler = (i) => {
    if (i > -1) {
      allmailforshare.splice(i, 1);
    }
    setallmailforshare((prev) => [...prev]);
  };
  const DialogContent = withStyles((theme) => ({
    root: {
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
  }))(MuiDialogActions);

  const signOut = () => {
    localStorage.removeItem("userID");
    localStorage.removeItem("role");
    localStorage.removeItem("token")
    window.open("/", "_self");
  };

  const _modalClose = () => {
    setOpen(false);
  };

  return (
    
    <div className="nav_bg header_fixed">
      <div className="container w1004 nav_menu_adjust">
        <div className="nav_bg_left">
          <img
            src={Logo}
            className="nav_logo cp"
            alt=""
            onClick={(e) => history.push("/landing")}
          />
        </div>
        <div className="hamberger_icon" onClick={(e) => showhambergermenu(e)}>
          <img src={Hamberger} alt="" />
        </div>
        {navDisplay ? (
          <div id="navid" className="hamberger_nav">
            <ul className="navbar_mobile">
              <li className="LinkForHeader cp df" onClick={openShare}>
                <div className="mobilemenuleft_adjust">
                  <img src={Share} className="nav_menu_icon" alt="Share" />
                </div>
                <div className="pt_2 pl_3">Share</div>
              </li>
              <li className="LinkForHeader cp df" onClick={openSupport}>
                <div className="mobilemenuleft_adjust">
                  <img src={support} className="nav_menu_icon" alt="Support" />
                </div>
                <div className="pt_2 pl_3">Support</div>
              </li>
              <li onClick={signOut} className="LinkForHeader cp df">
                <div className="mobilemenuleft_adjust">
                  <img src={Logout} className="nav_menu_icon" alt="Sign Out" />
                </div>
                <div className="pt_1 pl_3">Sign Out</div>
              </li>
            </ul>
          </div>
        ) : null}
        <div className="nav_bg_right">
          <ul className="navbar">
            <li className="LinkForHeader cp df" onClick={openShare}>
              <div>
                <img src={Share} className="nav_menu_icon" alt="Share" />
              </div>
              <div className="pt_2 pl_3">Share</div>
            </li>
            <li className="LinkForHeader cp df" onClick={openSupport}>
              <div>
                <img src={support} className="nav_menu_icon" alt="Support" />
              </div>
              <div className="pt_2 pl_3">Support</div>
            </li>
            <li onClick={signOut} className="LinkForHeader cp df">
              <div>
                <img src={Logout} className="nav_menu_icon" alt="Sign Out" />
              </div>
              <div className="pt_1 pl_3">Sign Out</div>
            </li>
          </ul>
        </div>
      </div>

      {/* dailog component for logged in support */}

      <LoggedInSupportModal
        modalOpen={open}
        modalClose={_modalClose}
        mailData={mailData}
      />

      {/* dailog for share */}

      <Dialog
        open={modalForShare}
        onClose={handelcloseshare}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          Share your{" "}
          <span class="fs-Bold-Italic">
            <span> PoweResume</span>
            <span class="PowerResume_Copy fs-bold">™ </span>
          </span>
        </DialogTitle>

        <DialogContent className={`${classes.bodyForShare} responsive_Support`}>
          <legend>Add Email Id*</legend>
          <div className="add_email_box mt_33">
            <input
              type="text"
              className="search-input"
              placeholder="Enter Mail Id"
              ref={inputemail}
              onChange={(e) => emailHandler(e.target.value)}
            />

            <button className="search-button" onClick={addEmailHandler}>
              Add
            </button>
          </div>
          {allmailforshare.map((value, i) =>
            i === 0 ? (
              <span className="mail_bg">
                <span className="mail_text">{value}</span>
                <span className="mail_close_icon">
                  <img
                    src={cancelemailicon}
                    onClick={(e) => deleteEmailHandler(i)}
                  />
                </span>
              </span>
            ) : (
              <span className="mail_bg ml_5">
                <span className="mail_text">{value}</span>
                <span className="mail_close_icon">
                  <img
                    src={cancelemailicon}
                    onClick={(e) => deleteEmailHandler(i)}
                  />
                </span>
              </span>
            )
          )}
            <legend style={{marginTop : '10px'}}>Your Message to Recruiters/Hiring Managers*</legend>
          <div>
            <textarea
              type="text"
              className="text_area_cleartalent_support"
              rows={4}
              placeholder="Enter your message to Recruiters/Hiring Managers"
              onChange={(e) => setshareMessage(e.target.value)}
            />
          </div>
            {errorEmailMessage ? <p className='form-error fs-regular'>Please enter a message</p> : ''}
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn-big btn-skill cp"
            onClick={handelcloseshare}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-big btn-green cp"
            onClick={sharePoweResumeEmail}
          >
            Share
          </button>
        </DialogActions>
      </Dialog>
      <NotificationContainer />
      <Snackbar
        open={mailValidate}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          className="remove-snackbar-alert"
        >
          Please enter your question.
        </Alert>
      </Snackbar>
    </div>
  
  );
};
export default Header;
