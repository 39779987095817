import axios from "axios";
const jsonConfig = require("../../Config.json");


const questionBySubSkill = async (payload) => {
    try {
        const getAllResponse = await axios.post(jsonConfig.apiUrl + "questionbysubskill",
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        return Promise.resolve(getAllResponse);
    } catch (error) {
        return Promise.reject(error);
    }
}

export default questionBySubSkill;