import axios from "axios";
const jsonConfig = require("../../Config.json");

const OrganizationConfigDelete = async (id) => {
  let allResponse = {}
  try {
    const response = await axios.delete(
        jsonConfig.apiUrl + "organizationconfig/" + id,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
      
      allResponse = response;
      return Promise.resolve(allResponse)
  }
  catch (error) {
    console.log("Error Invite Candidate", error);
      return Promise.reject(error)
  }
}

export default OrganizationConfigDelete;