import axios from "axios";
const jsonConfig = require("../../Config.json");

const resolveQuestionReportResponse = async (id, resolveData) => {
    let allResponse = {};
    try {
        const Response = await axios.put(
            jsonConfig.apiUrl + "questionReports/" + id, resolveData,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );

        allResponse = Response;

        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr", error);      
        return Promise.reject(error);
    }
}

export default resolveQuestionReportResponse;