import { useEffect, useState } from 'react'
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import editIcon from "../../pages/organisationAdmin/icons/editIcon.png";
import view from "../../pages/organisationAdmin/icons/view.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import './spocTable.css';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
}))

const ManageSpocTable = ({ spocs, isloading, onEdit, userType }) => {
    const classes = useStyles()
    const btnEdit = {
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "#1885e6",
    };
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 25,
        filter: {
            logic: "and",
            filters: [
                {
                    field: "isActive",
                    operator: "eq",
                    value: true,
                },
            ],
        },
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const [result, setResult] = useState();

    const dataStateChange = (event) => {
        let arr = [];
        for (let i = 0; i < spocs.length; i++) {
            spocs[i].clientName = spocs[i].client[0].clientName;
            arr.push(spocs[i]);
        }
        setResult(process(arr, event.dataState));
        setDataState(event.dataState);
    };
    const handleEditClick = (rowData) => {
        onEdit(rowData);
    };
    useEffect(() => {
        let arr = [];
        for (let i = 0; i < spocs.length; i++) {
            spocs[i].clientName = spocs[i]?.client[0]?.clientName;
            arr.push(spocs[i]);
        }
        setResult(process(arr, dataState));
    }, [spocs])


    const getSkeleton_kendo = () => {
        let arr = []
        for (let i = 0; i < 21; i++) {
            arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="40px" />)
        }
        return arr;
    }

    return (
        <>
            <div className='kendoGrid_manageSpoc'>
                    <GridDiv
                        filterable={true}
                        filter={dataState.filter}
                        sort={dataState.sort}
                        sortable={true}
                        resizable={true}
                        reorderable={true}
                        pageable={{
                            pageSizes: [10, 20, 50, 100],
                            info: true,
                            previousNext: true,
                            buttonCount: 10
                        }}
                        skip={dataState.skip}
                        take={dataState.take}
                        onDataStateChange={(e) => dataStateChange(e)}
                        data={result}
                    >
                    <GridNoRecords>
                        {isloading ? getSkeleton_kendo() : "No results found !"}
                    </GridNoRecords>

                        <Column
                            field="spocName"
                            title="Name"
                            width="180px"
                            filterable={true}
                        />
                        <Column
                            field="clientName"
                            title="Client"
                            width="180px"
                            filterable={true}
                        />
                        <Column
                            field="spocEmail"
                            title="Email"
                            width="180px"
                            filterable={true}
                        />
                        <Column
                            field="address"
                            title="Address"
                            width="180px"
                            filterable={true}
                        />
                        <Column
                            field="isActive"
                            title="Is Active"
                            width="180px"
                            filterable={true}
                            filter={"boolean"}
                            cell={(props) => {
                                const active = props.dataItem.isActive ? "True" : "False";
                                return (
                                    <td>
                                        {active}
                                    </td>
                                );
                            }}
                        />
                        <Column
                            title="Action"
                            filterable={false}
                            width="250px"
                            cell={(props) => {
                                return (

                                    <td className='btn-text'>
                                        <button
                                            className='editBtn'
                                            disabled={userType === 'Admin' ? true : false}
                                            onClick={() => handleEditClick(props.dataItem)}
                                        >
                                            Edit
                                        </button>

                                    </td>
                                );
                            }}
                        />
                    </GridDiv>
            </div>
        </>
    )
}

export default ManageSpocTable;