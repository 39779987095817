import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Container from "@material-ui/core/Container";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import Header from "../../components/adminHeader/adminHeader";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./addQuestion.css";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import testQuestionDescription from "../../utilities/questionSyntaxHighlight";
import Prism from "prismjs";

/**APIS */
import getDistinctSMES from "../../apis/getDistinctSMEs";
import getAllTests from "../../apis/Tests/getAllTests";
import getAllTestSubskills from "../../apis/Tests/getTestSubskills";
import getSubSkillsResponse from "../../apis/skills/getSubSkills";
import getSubSkillByTestId from "../../apis/Tests/getSubSkillByTestId";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import { UserTypeContext } from "../../AppRoute";
/**APIS */
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["code-block"],
    [{ 'color': [] }, { 'background': [] }],
  ],
  clipboard: {
    matchVisual: false,
  }
};
const answerModules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ 'color': [] }],
  ],
  clipboard: {
    matchVisual: false,
  }
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "indent",
  "image",
  "code-block",
  "color",
  "background"
];
const filter = createFilterOptions();
const AddQuestions = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const history = useHistory();
  const jsonConfig = require("../../Config.json");
  const [allPrimarySkill, setallPrimarySkill] = useState([]);
  const [allSubSkill, setAllSubSkill] = useState([]);
  const [rows, setRows] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [addNewchoiceNo, setAddNewchoiceNo] = useState(0);
  const [allAnsType, setallAnsType] = useState([]);
  const [quesDescription, setquesDescription] = useState("");
  const [subSkillId, setsubSkillId] = useState("");
  const [ansTypeId, setansTypeId] = useState("");
  const [time, settime] = useState(null);
  const [sec, setsec] = useState(null);
  const [questionComplexity, setquestionComplexity] = useState([]);
  const [questionComplexityId, setquestionComplexityId] = useState("");
  const [textBoxarr, settextBoxarr] = useState([]);
  const [chkboxarr, setchkboxarr] = useState([]);
  const [choiceOneTxtBox, setchoiceOneTxtBox] = useState("");
  const [choiceOneChkBox, setchoiceOneChkBox] = useState(false);
  const [choiceTwoTxtBox, setchoiceTwoTxtBox] = useState("");
  const [choiceTwoChkBox, setchoiceTwoChkBox] = useState(false);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  // const [userType, setuserType] = useState({});
  const [ansTypeName, setansTypeName] = useState("");
  const [reasonDisable, setReasonDisable] = useState(false);
  const [questionIsActive, setquestionIsActive] = useState("");
  const reasonDisableData = ["Retired", "Replaced", "Other"];
  const [reasonDropDown, setReasonDropDown] = useState("");
  const [reasonComment, setReasonComment] = useState("");
  const [questionSme, setQuestionSme] = useState("");
  const [smes, setSmes] = useState([]);
  const [orders, setOrders] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [allTestSubSkills, setAllTestSubSkills] = useState([]);
  const [subSkillTestId, setSubskillTestId] = useState("");
  const [allSubSkills, setallSubSkills] = useState([]);
  const [selectedSubskill, setSelectedSubskill] = useState([]);
  const [atvBtn, setAtvBtn] = useState(false);

  const getAllSubSkills = async () => {

    let subSkill = await getSubSkillsResponse()

    setallSubSkills(subSkill.data.data)

  }




  const getSmeOpts = async () => {

    const res = await getDistinctSMES();
    const dataFormatted = res.data.data.filter(
      (val) => val.subjectMatterExpert !== null
    );

    setSmes(dataFormatted);

  };

  const getQuestionComplexity = async () => {

    try {
      const questionComplexity = await axios.get(
        jsonConfig.apiUrl + "questioncomplexity",
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      setquestionComplexity(questionComplexity.data.data);
    } catch (error) {
      // do nothing
      console.log(error);
    }

  };

  const questionSMEHandler = (val) => {
    setQuestionSme(val);
  };

  const getAllAnsType = async () => {

    try {
      const allAnsType = await axios.get(
        jsonConfig.apiUrl + "getActiveAnswerTypes",
        {
          headers: {
            "x-functions-key": jsonConfig.key,
          },
        }
      );

      setallAnsType(allAnsType.data.data);
    } catch (error) {

      setAllSubSkill([]);
    }

  };
  const onTestSubskillChange = (e, value) => {
    if (value) {
      setSelectedSubskill(value);
      setSubskillTestId(value.id);
    }
  };

  const anstypeChangeHandler = (e, value) => {
    if (value) {
      setansTypeId(value.id);
      setansTypeName(value.name);
      let anstypechk =
        value.code === "MultipleChoice" || value.code === "SingleChoice";
      if (anstypechk) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(false);
    }
  };
  const timeHandler = (e) => {
    if (e.target.value === "") {
      settime(null);
    } else {
      settime(e.target.value);
    }
  };
  const secHandler = (e) => {
    if (e.target.value === "") {
      setsec(null);
    } else {
      setsec(e.target.value);
    }
  };
  const questionHandler = (value) => {
    const regex = /(<([^>]+)>)/ig; // //Remove Html Tags
    const textValue = value;
    const hasText = !!textValue.replace(regex, "").trim().length;
    if (hasText) {
      setquesDescription(textValue);
    } else {
      setquesDescription("");
    };
  };
  const isActiveHandler = (e, value) => {
    if (value) {
      setquestionIsActive(value.isActive);
      if (value.isActive === "False") {
        setReasonDisable(true);
      } else {
        setReasonDisable(false);
      }
    } else {
      setquestionIsActive("");
      setReasonDisable(false);
    }
  };
  const reasonChangeHandler = (e, value) => {
    setReasonDropDown(value);
  };
  const reasonCommentHandler = (e, value) => {
    setReasonComment(e.target.value);
  };

  const questionComplexityHandler = (e, value) => {
    if (value) {
      setquestionComplexityId(value.id);
    }
  };
  const ChoiceOneHandler = (value) => {
    const regex = /(<([^>]+)>)/ig; // //Remove Html Tags
    const textValue = value;
    const hasText = !!textValue.replace(regex, "").trim().length;
    if (hasText) {
      setchoiceOneTxtBox(textValue);
    } else {
      setchoiceOneTxtBox("");
    };
  };
  const choiceOneChkHandler = (e) => {
    setchoiceOneChkBox(e.target.checked);
  };
  const ChoiceTwoHandler = (value) => {
    const regex = /(<([^>]+)>)/ig; // //Remove Html Tags
    const textValue = value;
    const hasText = !!textValue.replace(regex, "").trim().length;
    if (hasText) {
      setchoiceTwoTxtBox(textValue);
    } else {
      setchoiceTwoTxtBox("");
    };
  };

  const choiceTwoChkHandler = (e) => {
    setchoiceTwoChkBox(e.target.checked);
  };

  const textboxhandler = (event, index) => {
    const regex = /(<([^>]+)>)/ig; // //Remove Html Tags
    const textValue = event;
    const hasText = !!textValue.replace(regex, "").trim().length;
    let tempp = textBoxarr;
    if (hasText) {
      tempp[index] = textValue;
    } else {
      tempp[index] = "";
    }
    settextBoxarr(tempp);
  };
  const chkboxHandlerForLoop = (e) => {
    var a = e.target.name.split("_")[1];
    var a = e.target.name;
    var b = a.split("_");
    var c = b[1];
    var tempp = chkboxarr;
    tempp[c] = e.target.checked;
    setchkboxarr(tempp);
  };
  const addchoiceHandler = () => {
    setAddNewchoiceNo(addNewchoiceNo + 1);
    var temparr = rows;
    temparr.push(addNewchoiceNo);
    setRows(temparr);
  };
  const submitHandler = async () => {
    setAtvBtn(true);
    var commonAnsArray = [
      {
        answerText: choiceOneTxtBox,
        isCorrect: choiceOneChkBox,
        order: orders[0]
      },
      {
        answerText: choiceTwoTxtBox,
        isCorrect: choiceTwoChkBox,
        order: orders[1]
      },
    ];
    // let otherAnsArray = [];

    for (let i = 0; i < textBoxarr.length; i++) {
      var obj = {};
      if (chkboxarr[i] === undefined) {
        obj["answerText"] = textBoxarr[i];
        obj["isCorrect"] = false;
        obj.order = orders[i + 2]
        commonAnsArray.push(obj);

      } else {

        obj["answerText"] = textBoxarr[i];
        obj["order"] = orders[2 + i];
        obj["isCorrect"] = chkboxarr[i];
        commonAnsArray.push(obj);

      }
    }


    // const yourArray = [1, 1, 2, 3, 4, 5, 5]
    var v = commonAnsArray.map((a) => a.isCorrect);
    var count = {};
    v.forEach(function (i) {
      count[i] = (count[i] || 0) + 1;
    });
    if (count.true > 1 && ansTypeName === "Single Select") {
      setAtvBtn(false);
      return NotificationManager.error(
        "Single Select Question Cannot Have More Than One Correct Answer",
        "Error",
        2500
      );
    }

    if (
      questionComplexityId === "" ||
      quesDescription === "" ||
      quesDescription === "" ||
      subSkillTestId === "" ||
      ansTypeId === "" ||
      questionIsActive === "" ||
      questionSme === "" ||
      time === null ||
      sec === null
    ) {
      setAtvBtn(false);
      return NotificationManager.error(
        "Please fill all the mandatory fields",
        "Error",
        2500
      );
    }
    let min = parseInt(time);
    let second = parseInt(sec);
    if ((min === 0 && second === 0) || (min === 0 && second < 30)) {
      setAtvBtn(false);
      NotificationManager.error(
        "Minimum Time limit must be equal to or greater than 30 second",
        "Error",
        2500
      );
    }
    else {
      var addQuestionJson = {
        question: {
          questionDesc: quesDescription,
          subskill: subSkillTestId,
          industry: jsonConfig.userIndustryId,
          questionComplexity: questionComplexityId,
          questionType: jsonConfig.questionType,
          answerType: ansTypeId,
          ReasonForDisabling: reasonDropDown,
          Comments: reasonComment,
          timeLimit: parseInt(time * 60) + parseInt(sec),
          answers: commonAnsArray,
          createdBy: userIdType,
          updatedBy: userIdType,
          updatedAt: new Date(),
          createdAt: new Date(),
          isActive: questionIsActive === "True" ? true : false,
          subjectMatterExpert: questionSme.subjectMatterExpert,
        },
      };
      const atleastOneChecked = commonAnsArray.some((item) => item.isCorrect);
      if (!atleastOneChecked) {
        setAtvBtn(false);
        return NotificationManager.error(
          "Please mark at least one choice as correct ",
          "Error",
          2500
        );
      }
      const isCorrectArr = commonAnsArray.filter((item) => item.isCorrect);
      const everyIsCorrect = isCorrectArr.every((item) => item.answerText);
      if (!everyIsCorrect) {
        setAtvBtn(false);
        return NotificationManager.error(
          "Please enter correct answer field ",
          "Error",
          2500
        );
      }
      try {
        const addQuestion = await axios.post(
          jsonConfig.apiUrl + "questions",
          addQuestionJson,
          {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
          }
        );
        await NotificationManager.success("Question added", "Success", 1500);
        setTimeout(async () => {
          await history.push("/questions");
        }, 3000);
      } catch (error) {
        console.log("subskillerror", error);
        NotificationManager.error("Question Addition Failed", "Error", 2500);
      }
    }
    setAtvBtn(false);
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
    }
  };
  const cancelHandler = (e) => {
    history.push("/questions");
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getAllSubSkills()
        await getAllAnsType();
        await getQuestionComplexity();
        await getSmeOpts();
      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }

    if (userType === "Admin") {
      fetchAllData();
    } else {
      setisDataLoaded(true);
    }



  }, []);
  const handleOrders = (index, value) => {

    let newOrderData = [...orders];
    newOrderData[index] = Number(value);
    setOrders(newOrderData);
  }


  const btnGreen = {
    color: "#fff",
    backgroundColor: "#1885e6",
    border: "none",
    padding: "7px 23px 7px 23px",
  };
  const btnSubmit = {

    marginTop: "15px",
    marginBottom: "10px",
  };
  const btnCancel = {
    color: "#3f51b5",
    backgroundColor: "#fff",
    border: "none",
    padding: "7px 24px 7px 23px",
    /*float: 'right',*/
    marginTop: "15px",
    marginBottom: "10px",
    marginRight: "7px",
    border: "1 px solid #3f51b5",
  };
  const chkboxstyle = {
    float: "right",
  };
  const buttonHolder = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  };

  const v = [{ isActive: "True" }, { isActive: "False" }];
  setTimeout(() => Prism.highlightAll(), 0);
  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt-110">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {userType === "Admin" ? (
                  <Container component={Paper} className="allignmentFix">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <h1> Add Question </h1>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          component="h2"
                          className="pb_15"
                        >
                          Sub Skill*
                        </Typography>
                        <Autocomplete
                          id="combo-box-demo"
                          options={allSubSkills}
                          value={selectedSubskill}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select sub-skill"
                            />
                          )}
                          onChange={onTestSubskillChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {/* <p>Maximum Time</p> */}
                        <Typography variant="body1" component="h2">
                          Maximum Time*
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <p>Status</p> */}
                        <Typography variant="body1" component="h2">
                          Complexity*
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <OutlinedInput
                          placeholder="Minute"
                          className="admin_textbox widthFix"
                          type="number"
                          inputProps={{ min: 0 }}
                          onChange={timeHandler}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <OutlinedInput
                          placeholder="Second"
                          className="admin_textbox widthFix"
                          type="number"
                          inputProps={{ min: 0, max: 59 }}
                          onChange={secHandler}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={questionComplexity}
                          getOptionLabel={(option) => option.name || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Select complexity"
                            />
                          )}
                          onChange={questionComplexityHandler}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} className="choice_textarea">
                        <Typography
                          variant="body1"
                          component="h2"
                          className="pb_15"
                        >
                          Question*
                        </Typography>
                        {/* <FormControl fullWidth variant="outlined"> */}
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={quesDescription}
                          onChange={questionHandler}
                        />
                      </Grid>
                      {/* {quesDescription && (
                        <Grid item xs={12}>
                          <Typography variant="body1" component="h2">
                            Question Render
                          </Typography>
                          <p className="fs-semi-bold f-color question-title">
                            {testQuestionDescription(quesDescription)}
                          </p>
                        </Grid>
                      )} */}

                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="body1"
                          component="h2"
                          className="pb_15"
                        >
                          Answer Type*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={allAnsType}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select answer type"
                              />
                            )}
                            onChange={anstypeChangeHandler}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant="body1"
                          component="h2"
                          className="pb_15"
                        >
                          Is Active*
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                          <Autocomplete
                            options={v}
                            getOptionLabel={(option) =>
                              option.isActive.charAt(0).toUpperCase() +
                              option.isActive.toString().slice(1) || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Is Active"
                              />
                            )}
                            onChange={isActiveHandler}
                          />
                        </FormControl>
                      </Grid>
                      {smes && (
                        <Grid item xs={12} md={4}>
                          <Typography
                            style={{ marginBottom: 15 }}
                            variant="body1"
                            component="h2"
                          >
                            Subject Matter Expert *
                          </Typography>
                          <Autocomplete
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.subjectMatterExpert;
                            }}
                            // onInputChange ={onSkillChange}
                            onChange={(event, newValue) =>
                              questionSMEHandler(newValue)
                            }
                            filterSelectedOptions
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (params.inputValue !== "") {
                                filtered.push({
                                  subjectMatterExpert: params.inputValue,
                                  title: `Add "${params.inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            id="free-solo-with-text-demo"
                            options={smes}
                            renderOption={(option) =>
                              option.subjectMatterExpert
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select subject matter expert"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      )}

                      {reasonDisable && (
                        <>
                          <Grid item xs={12} md={12}>
                            <Typography
                              variant="body1"
                              component="h2"
                              className="pb_15"
                            >
                              Reason For Disabling
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                              <Autocomplete
                                options={reasonDisableData}
                                getOptionLabel={(option) => option || ""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Reason for disabling"
                                  />
                                )}
                                onChange={reasonChangeHandler}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Typography
                              variant="body1"
                              component="h2"
                              className="pb_15"
                            >
                              Comments
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                              <TextareaAutosize
                                rowsMin={3}
                                className="admin_textbox"
                                placeholder="Enter comments"
                                onChange={reasonCommentHandler}
                              // onKeyPress={onEnter}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      {disabled ? (
                        <>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              style={btnGreen}
                              color="primary"
                              onClick={addchoiceHandler}
                            >
                              Add choice
                            </Button>
                          </Grid>
                          <Grid item xs={12} className="choice_textarea">
                            <div className="choice_order_section">
                              <div className="mt_30">
                                <Typography variant="body1" component="h2">
                                  Choice 1
                                </Typography>
                              </div>
                              <div className="choice_order_section_width">
                                <Typography variant="body1" component="h2">
                                  Order
                                </Typography>
                                <FormControl fullWidth variant="outlined">
                                  <OutlinedInput
                                    className="admin_textbox"
                                    placeholder="Enter Order"
                                    type="number"
                                    onChange={(e) => handleOrders(0, e.target.value)}

                                  />
                                </FormControl>
                              </div>
                            </div>

                            <FormControlLabel
                              value="start"
                              control={
                                <Checkbox
                                  color="primary"
                                  fontSize="small"
                                  onChange={choiceOneChkHandler}
                                />
                              }
                              label="Tick if it is correct"
                              labelPlacement="start"
                              style={chkboxstyle}
                            />

                            <FormControl fullWidth variant="outlined">
                              <ReactQuill
                                theme="snow"
                                modules={answerModules}
                                formats={formats}
                                value={choiceOneTxtBox}
                                onChange={ChoiceOneHandler}
                              />

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="choice_textarea">
                            <div className="choice_order_section">
                              <div className="mt_30">
                                <Typography variant="body1" component="h2">
                                  Choice 2
                                </Typography>
                              </div>
                              <div className="choice_order_section_width">
                                <Typography variant="body1" component="h2">
                                  Order
                                </Typography>
                                <FormControl fullWidth variant="outlined">
                                  <OutlinedInput
                                    className="admin_textbox"
                                    type="number"
                                    placeholder="Enter Order" onChange={(e) => handleOrders(1, e.target.value)}

                                  />
                                </FormControl>
                              </div>
                            </div>

                            <FormControlLabel
                              value="start"
                              control={
                                <Checkbox
                                  color="primary"
                                  fontSize="small"
                                  onChange={choiceTwoChkHandler}
                                />
                              }
                              label="Tick if it is correct"
                              labelPlacement="start"
                              style={chkboxstyle}
                            />
                            <FormControl fullWidth variant="outlined">
                              <ReactQuill
                                theme="snow"
                                modules={answerModules}
                                formats={formats}
                                value={choiceTwoTxtBox}
                                onChange={ChoiceTwoHandler}
                              />
                            </FormControl>
                          </Grid>
                          {rows.map((value, key) => (
                            <Grid item xs={12} key={key}>
                              <div className="choice_order_section">
                                <div className="mt_30">
                                  <Typography variant="body1" component="h2">
                                    Choice {key + 3}
                                  </Typography>
                                </div>
                                <div className="choice_order_section_width">
                                  <Typography variant="body1" component="h2">
                                    Order
                                  </Typography>
                                  <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                      className="admin_textbox"
                                      type="number"
                                      placeholder="Enter Order"
                                      onChange={(e) => handleOrders(2 + key, e.target.value)}

                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <FormControlLabel
                                name={"choice_" + key}
                                control={
                                  <Checkbox
                                    color="primary"
                                    fontSize="small"
                                    onChange={chkboxHandlerForLoop}
                                  />
                                }
                                label="Tick if it is correct"
                                labelPlacement="start"
                                style={chkboxstyle}
                              />
                              <FormControl fullWidth variant="outlined">
                                <ReactQuill
                                  theme="snow"
                                  modules={answerModules}
                                  formats={formats}
                                  value={textBoxarr[key]}
                                  onChange={(event) => textboxhandler(event, key)}
                                />
                              </FormControl>
                            </Grid>
                          ))}
                        </>
                      ) : null}
                    </Grid>
                    <div className="add_question_button">
                      <Button
                        variant="outlined"
                        style={btnCancel}
                        color="primary"
                        onClick={cancelHandler}
                      >
                        Cancel
                      </Button>
                      <button
                        type="button"
                        className="btn3 btn_primary cp"
                        style={btnSubmit}

                        onClick={submitHandler}
                        disabled={atvBtn}
                      >
                        <div className="btn_loader">
                          Save
                          &nbsp;
                          {atvBtn &&
                            <div>
                              <CircularProgress size={13} />
                            </div>
                          }
                        </div>
                      </button>
                    </div>
                  </Container>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>
                ) : (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      You Are Not Authorized To Visit This Page
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default AddQuestions;
