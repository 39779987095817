import axios from "axios";
const jsonConfig = require("../../Config.json");

const inviteCandidateDetailsResponse = async (payload) => {
    let allResponse = {}
    try {
        const getallresponse = await axios.post(
            jsonConfig.apiUrl + "GetInviteDetails",
            payload,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
        allResponse = getallresponse;
        return Promise.resolve(allResponse)
    }
    catch (error) {
      console.log("Error Invite Candidate", error);
        return Promise.reject(error)
    }
}

export default inviteCandidateDetailsResponse;