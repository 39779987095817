import axios from "axios";
const jsonConfig = require("../../Config.json");

const resetPasswordResponse = async (payload) => {
    let allResponse = {}
    try {
        const resetPasswordReqData = await axios.put(
            jsonConfig.apiUrl + "ResetPassword",
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        
        allResponse = resetPasswordReqData;

        return Promise.resolve(allResponse)
    }
    catch (error) {
        console.log("errrrrr",error);    
        return Promise.reject(error);
    }
}

export default resetPasswordResponse;