import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";

const Modal = (props) => {

  const {
    children,
    open
  } = props;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          width: "600px",
        },
      }}
    >
      {children}
    </Dialog>
  );
}

export default Modal;
