import axios from "axios";
const jsonConfig = require("../../Config.json");

const checkRequisitionExists = async (payload) => {
    try {
        const getAllResponse = await axios.post(jsonConfig.apiUrl + "checkRequisitionExists",payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },

            });

        return Promise.resolve(getAllResponse)

    } catch (error) {
        return Promise.reject(error)
    }
}
export default checkRequisitionExists;
