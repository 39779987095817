import axios from "axios";
const jsonConfig = require("../../Config.json");

const additionalTestDetails = async (payload) => {
    let allResponse = {};
    try {
        const updateUsertestsResponse = axios.put(
            jsonConfig.apiUrl + "additionalTestDetails/" + payload.userId,
            payload.data,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            }
        );
        allResponse = updateUsertestsResponse;

        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr", error);
        return Promise.reject(error);
    }
}

export default additionalTestDetails;