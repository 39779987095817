import axios from "axios";
const jsonConfig = require("../../Config.json");

const getSubSkillsBySkillIdResponse = async (skillid) => {
    let allSkillsList = {}
    try {
        const getallskillresponse = await axios.get(
            jsonConfig.apiUrl + "subskillbyskillidadmin/" + skillid,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        allSkillsList = getallskillresponse;
        return Promise.resolve(allSkillsList)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default getSubSkillsBySkillIdResponse;