import React, { useState, useCallback, useEffect } from "react";
import { process } from "@progress/kendo-data-query";
import {
  Grid as GridDiv,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import debounce from "lodash/debounce";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import workReports from "../../apis/requisitionReports/workReport";
import { UserTypeContext } from "../../AppRoute";
import { DateTime } from "luxon";
import "./requisitionReports.css";
import * as XLSX from "xlsx";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  odd: {
    background: "#F6F6F6 !important",
  },
  even: {
    background: "#fffff !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  waitingText: {
    marginLeft: "5px",
  },
}));

const WorkReport = (props) => {
  const {
    userType,
    userIdType,
    userRecruitmentpartnerId,
    userRecords,
    dateRange,
    triggerAction,
    resetTrigger,
    startSelectedDate,
    endSelectedDate,
    additionalFilterData,
    setIsfilterSearch,
    isfilterSearch
  } = props;
  const [isSearching, setIsSearching] = useState(true);
  const [searchTerms, setSearchTerms] = useState([]);
  const [takeNumberOfResult, setTakeNumberOfResult] = useState(10);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const classes = useStyles();
  const [isExporting, setIsExporting] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
  });

  const [dataResult, setDataResult] = useState([]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    setIsSearching(true);
    setDataResult([]);
    const terms =
      filters && filters.length > 0
        ? filters
          .filter(({ value }) => value && value !== "") // Filter out objects where value is empty, null, or white spaces
          .map(({ field, value, operator }) => ({ field, value, operator }))
        : [];

    setSearchTerms(terms);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    setIsSearching(true);
  };



  useEffect(() => {
    if (triggerAction) {
      callApiToGenerateExcel(); // Perform the action
      resetTrigger(); // Reset the trigger to avoid repeated execution
    }
  }, [triggerAction, resetTrigger]);
  useEffect(() => {
    let setDefaultValue = {
      skip: 0,
      take: 10,
      filter: {
        logic: "and",
        filters: [],
      },
    };
    setDataState(setDefaultValue);
  }, []);

  function formatDateOnly(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Get only the YYYY-MM-DD part
  }

  const callApiToGenerateExcel = async () => {
    setIsExporting(true);


    let maxSize = 100;
    let end = Math.ceil(totalResult / maxSize);
    let pageStart = 1;
    let pageEnd = maxSize;
    let exportArr = [];
    for (let start = 0; start < end; start++) {
      let payload = {
        orgId: userRecruitmentpartnerId,
        isExport: true,
        page: pageStart,
        size: maxSize,
        formattedDatestart: formatDateOnly(startSelectedDate),
        formattedDateend: formatDateOnly(endSelectedDate),
        userId: userIdType,
        role: userType,
        additionalFilter: {
          clientName: additionalFilterData.clientName,
          divisionId: additionalFilterData.divisionId,
          interview_date: additionalFilterData.interview_date,
          start_date: additionalFilterData.start_date,
          offer_date: additionalFilterData.offer_release_date
        }
      };
      const allDetails = await workReports(payload);
      allDetails.data.forEach((element) => {
        let data = {
          "Candidate Name": element.name,
          Email: element.email,
          "Reference ID": element.reqreferenceId,
          "Primary Skill": element.primarySkill,
          Division: element.division,
          "Job Priority": element.jobpriority,
          Client: element.client,
          Recruiter: element.recruiter,
          "Recruiter Leader": element.recruiterLeader,
          "Country": element.country[0],
          "State": element.state[0],
          "City": element.city[0],
          Status: element.status,
          "Display Date": element.displayDate,
          "Minimum Bill Rate": element.minimumBillRate,
          "Maximum Bill Rate": element.maximumBillRate,
          Currency: element.currency,
          Unit: element.unit,
          Fee: element.fee,
          "Previously Hired": element.previouslyHired,
          "Placement Type": element.placementType,
          Source: element.source,
        };
        exportArr.push(data);
      });
      pageStart = pageStart + maxSize;
    }

    if (exportArr.length === 0) {
      NotificationManager.error("No data present", "Error", 2500);
      setIsExporting(false);
    } else {
      const MAX_ROWS_PER_SHEET = 500;

      const wb = XLSX.utils.book_new();

      // Calculate the number of sheets needed
      const sheetCount = Math.ceil(exportArr.length / MAX_ROWS_PER_SHEET);

      // Loop to create sheets
      for (let sheetIndex = 0; sheetIndex < sheetCount; sheetIndex++) {
        // Get a subset of data for each sheet
        const startRow = sheetIndex * MAX_ROWS_PER_SHEET;
        const endRow = Math.min(
          (sheetIndex + 1) * MAX_ROWS_PER_SHEET,
          exportArr.length
        );
        const subsetData = exportArr.slice(startRow, endRow);

        // Convert JSON array to worksheet
        const ws = XLSX.utils.json_to_sheet(subsetData);

        // Append the sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, `Sheet${sheetIndex + 1}`);
      }

      // Save the workbook to an Excel file
      XLSX.writeFile(wb, `WorkReport.xlsx`, { bookType: "xlsx", type: "file" });
    }
    setIsExporting(false);

    // Call the API to generate the Excel file
  };

  const debouncedSearch = useCallback(
    debounce(
      async (
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userId,
        additionalFilterData
      ) => {
        setLoading(true);
        let payload = {
          orgId: userRecruitmentpartnerId,
          page: pageNumber,
          size: takeNumberOfResult,
          formattedDatestart: formatDateOnly(startSelectedDate),
          formattedDateend: formatDateOnly(endSelectedDate),
          isExport: false,
          userId: userId,
          role: userType,
          additionalFilter: {
            clientName: additionalFilterData.clientName,
            divisionId: additionalFilterData.divisionId,
            interview_date: additionalFilterData.interview_date,
            start_date: additionalFilterData.start_date,
            offer_date: additionalFilterData.offer_release_date
          }
        };
        const search = await workReports(payload);

        if (search.data.length <= 0) {
          setLoading(false);
          setDataResult(search.data);
          setTotalResult(0);
          setIsSearching(false);

        }
        if (search.data.length > 0) {
          setDataResult(search.data);
          setIsfilterSearch(false)
          if (search.totalCount[0].total)
            setTotalResult(search.totalCount[0].total);
          setIsSearching(false);
        }
      },
      1000
    ),
    []
  );

  useEffect(() => {
    if (!startSelectedDate || !endSelectedDate) {
      NotificationManager.error("Please enter a valid date", "Error", 2500);
    } else {
      setIsSearching(true);
      setDataResult([]);


      debouncedSearch(
        userRecruitmentpartnerId,
        pageNumber,
        takeNumberOfResult,
        startSelectedDate,
        endSelectedDate,
        userIdType,
        additionalFilterData
      );
      setIsfilterSearch(false)
    }
  }, [
    searchTerms,
    pageNumber,
    takeNumberOfResult,
    startSelectedDate,
    endSelectedDate,
    isfilterSearch
  ]);

  const jobReportSkeleton = () => {
    let arr = [];
    for (let i = 0; i <= 10; i++) {
      arr.push(
        <Skeleton
          className={i % 2 === 0 ? classes.odd : classes.even}
          variant="rect"
          width="100%"
          height="50px"
        />
      );
    }
    return arr;
  };
  return (
    <>
      <Backdrop className={classes.backdrop} open={isExporting}>
        <CircularProgress color="inherit" />
        <Typography className={classes.waitingText}>
          {" "}
          Please wait ...
        </Typography>
      </Backdrop>

      <GridDiv
        filterable={false}
        filter={dataState.filter}
        ortable={false}
        resizable={false}
        reorderable={false}
        pageable={{
          pageSizes: [10, 20, 50, 100],
          info: true,
          previousNext: true,
          buttonCount: 10,
        }}
        skip={dataState.skip}
        take={dataState.take}
        //    filterOperators={filterOperators}
        onDataStateChange={dataStateChange}
        data={dataResult}
        total={totalResult}
        className="kendo_reports"
      >
        <GridNoRecords>
          {isSearching ? jobReportSkeleton() : "No results found !"}
        </GridNoRecords>
        <GridColumn field="name" title="Candidate Name" width="180px" />

        <GridColumn field="email" title="Email" width="180px" />
        <GridColumn field="reqreferenceId" title="Reference ID" width="180px" />
        <GridColumn field="primarySkill" title="Primary Skill" width="180px" />
        <GridColumn field="division" title="Division" width="180px" />
        <GridColumn field="jobpriority" title="Job Priority" width="180px" />
        <GridColumn field="client" title="Client" width="180px" />

        <GridColumn field="recruiter" title="Recruiter" width="180px" />
        <GridColumn
          field="recruiterLeader"
          title="Recruiter Leader"
          width="180px"
        />

        <GridColumn field="city" title="City" width="180px" />
        <GridColumn field="state" title="State" width="180px" />
        <GridColumn field="country" title="Country" width="180px" />
        <GridColumn field="status" title="Status" width="180px" />
        <GridColumn field="displayDate" title="Display Date" width="180px" />

        <GridColumn
          field="minimumBillRate"
          title="Minimum Bill Rate"
          width="180px"
        />
        <GridColumn
          field="maximumBillRate"
          title="Maximum Bill Rate"
          width="180px"
        />
        <GridColumn field="currency" title="Currency" width="180px" />
        {/* <GridColumn field="unit" title="Unit" width="180px" /> */}
        <GridColumn field="fee" title="Fee" width="180px" />
        <GridColumn
          field="previouslyHired"
          title="Previously Hired"
          width="180px"
        />
        <GridColumn
          field="placementType"
          title="Placement Type"
          width="180px"
        />
        <GridColumn field="source" title="Source" width="180px" />
      </GridDiv>
    </>
  );
};

export default WorkReport;
