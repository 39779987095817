import axios from "axios";
const jsonConfig = require("../../Config.json");

const getMySkillsByIdResponse = async (id) => {
    let allSkillsList = {}
    try {
        const response = await axios
        .get(jsonConfig.apiUrl + "mySkills/" + id,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        allSkillsList = response;

        return Promise.resolve(allSkillsList)
    }
    catch (error) {
        //    console.error(error);
        return Promise.reject(error)
    }
}

export default getMySkillsByIdResponse;