import React, { useState, useEffect, useRef } from "react";
import Banner2 from "../images/Banner-2.png";
import Logo from "../images/logo.png";
import Arrow from "../images/Arrow.png";
import Header from "../homeHeader/Header/Header";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import validateEmail from "../../../utilities/validateEmail";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import axios from "axios";
import '../home.css';
import { Tooltip } from 'react-bootstrap';
import { Facebook, Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import emailConfigurationByCodeResponse from "../../../apis/email/emailConfigurationByCode";
import sendEmailResponse from "../../../apis/email/sendEmail";
import circleBlue from "../../../components/basicInfoForm/images/circle_blue.svg"
import { Button, Grid, OutlinedInput } from "@material-ui/core";
import "./employer.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";


const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: '0px 30px!important'
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 42px 30px!important"
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: '0px 30px!important'
  },
  btnCancel: {
    width: "149px",
    height: "37px",
    backgroundColor: "#FFFFFF",
    color: "#1885E6",
    border: "1px solid #1885E6",
    borderRadius: "5px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#D1E8FF"
    }
  },

  btnSave: {
    width: "149px",
    height: "37px",
    backgroundColor: "#1885E6",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "5px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#1885E6",
    }
  },
  demoCalender: {
    width: "100%",
    height: "45px",
  },
  datepicker: {
    width: "100%",
  }

}));

const EmployerPage = () => {
  const classes = useStyles();
  const todayDate = new Date();
  const jsonConfig = require('../../../Config.json');
  const [demoOpen, setDemoOpen] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [today, setToday] = useState("");
  const [modalAvailabilityDate, setModalAvailabilityDate] = useState("");
  const [contactFormFirstName, setContactFormFirstName] = useState('');
  const [contactFormLastName, setContactFormLastName] = useState('');
  const [contactFormEmail, setContactFormEmail] = useState('');
  const [roleHandlerValue, setRoleHandlerValue] = useState('');
  const [contactFormPhone, setContactFormPhone] = useState('');
  const [allSupportMail, setallSupportMail] = useState([]);
  const [companyValue, setCompanyValue] = useState('');
  const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const topButton = useRef(0);
  const goToTerms = (e) => {
    window.open("/terms-of-use", "_self");
  }
  const goToPrivacyPolicy = () => {
    window.open("/privacy-policy", "_self");
  }

  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  const scrollFunction = () => {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      topButton.current.classList.add('show-button')
    } else {
      topButton.current.classList.remove('show-button')
    }
  }
  const requestDemo = () => {
    const roleListArr = jsonConfig.demoRoleList;
    const today = new Date().toISOString().slice(0, 10);
    setModalAvailabilityDate(today);
    setToday(today);
    setDemoOpen(true);
    setRoleList(roleListArr);
    setContactFormFirstName('');
    setContactFormLastName('');
    setContactFormEmail('');
    setContactFormPhone('');
    setCompanyValue('');
  }
  const roleHandler = (event, value) => {
    setRoleHandlerValue(value);
  }



  const getAllMail = async () => {
    try {
      var codeForMail = {
        "code": "CTDemoEmail"
      }
      const getAllMailResponse = await emailConfigurationByCodeResponse(codeForMail);
      setallSupportMail(getAllMailResponse.data.data[0].emails)

    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getAllMail();
  }, [demoOpen])

  const submitContactForm = async () => {
    const mailValidationHandle = validateEmail(contactFormEmail);
    if(phoneError){
      NotificationManager.error("Please enter valid Phone Number", "Error");
    }
    else if (contactFormFirstName === "" || contactFormLastName === "" || roleHandlerValue === "" || modalAvailabilityDate === "" || contactFormEmail === '' || (!phoneNoIntnl)) {
      NotificationManager.error("Enter Required Fields.", "Error");
    } else {
      if (!mailValidationHandle) {
        NotificationManager.error("Please Enter Correct Email", "Error");
      } else {

        try {

          const modalAvailabilityDateFormat = new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(modalAvailabilityDate))
            + "-" +
            new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(modalAvailabilityDate))
            + "-" +
            new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(modalAvailabilityDate))

          let htmlMessage = `<p>FirstName: ${contactFormFirstName}</p>
             <p>LastName: ${contactFormLastName}</p>
             <p>Email: ${contactFormEmail}</p>
            <p>Phone Number: ${phoneNoIntnl}<p/>
             <p>Company: ${companyValue}</p> 
             <p>Role: ${roleHandlerValue}<p/>`

          const message = {
            to: allSupportMail,
            from: jsonConfig.sendGridFromEmail,
            subject: `ClearedTalent Demo Request`,
            html: htmlMessage
          }
          if (jsonConfig.sendEmail) {
            const supportEmailResponse = await sendEmailResponse(message);
            NotificationManager.success('Thank you for submitting the details. We will get back to you shortly', 'Success');
          }
          setPhoneNoIntnl("")
          setDemoOpen(false);
          setContactFormFirstName('');
          setContactFormLastName('');
          setContactFormEmail('');
          setContactFormPhone('');
          setCompanyValue('');
        } catch (err) {
          console.log(err)
        }
      }


    }
  }
  const contactFormClose = () => {
    setDemoOpen(false);
    setPhoneNoIntnl("");
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", scrollFunction);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  })

  const keypressHandler = (e) => {
    var invalidChars = [
      "-",
      "+",
      "e",
    ];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }



  const phoneNumberChangeHandler = (val) => {
    setPhoneNoIntnl(val);
    if (val) {
      if (val && isPossiblePhoneNumber(val)) {
        setPhoneError(false)
      }
      else {
        setPhoneError(true)
      }
    }
    else {
      setPhoneError(false)
    }

  };

  return (
    <>
      <Header />
      <div className="banner_section cardcontainer banner-section-image-responsive">
        <img src={Banner2} alt='imagebanner' />
        <div className="banner_text_section">
          <div className="container w1200 ">
            <p className="banner_text_top">Find talent at <span className="banner_text_top_other_color">lightning speed</span></p>
            <p className="banner_text_bottom">Build confidence with internal and external customers with a vetted technology talent pipeline</p>
            <div className="banner_button">
              <button type="button" className="btn1-home btn-start_big mt_15" onClick={requestDemo}>REQUEST DEMO</button>
            </div>
          </div>
        </div>
      </div>

      <div className="banner_bottom_section banner_bottom-responsive-employer">
        <div className="container w1200">
          <div className="banner_bottom_section_wrapper">
            <div className="banner_left_text_section_employers">

              <p className="employer_banner_bottom_section_text_left_bottom_top">Enhance candidate experience by avoiding multiple rounds of screening
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="parallax_employers">
        <div className="home_step_section">
          <div className="container w1200 ">
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-delay="500" data-aos-once="true"></div>
                <div className='bullet-line er-first-l' data-aos="reveal-top" data-aos-once="true"></div>
              </div>
              <div className='bullet-text' id="trigger-bullets" data-aos="zoom-in" data-aos-delay="500" data-aos-once="true">Crisp & objective assessments – Candidates get screened in minutes</div>
            </div>
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-anchor="#trigger-bullets" data-aos-delay="2200" data-aos-once="true"></div>
                <div className='bullet-line er-second-l' data-aos="reveal-top" data-aos-anchor="#trigger-bullets" data-aos-delay="2500" data-aos-once="true"></div>
              </div>
              <div className='bullet-text' data-aos="zoom-in" data-aos-anchor="#trigger-bullets" data-aos-delay="2200" data-aos-once="true">Easy scorecards with video for client managers</div>
            </div>
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-anchor="#trigger-bullets" data-aos-delay="3200" data-aos-once="true"></div>
                <div className='bullet-line er-third-l' data-aos="reveal-top" data-aos-anchor="#trigger-bullets" data-aos-delay="3500" data-aos-once="true"></div>
              </div>
              <div className='bullet-text bullet_text_break' data-aos="zoom-in" data-aos-anchor="#trigger-bullets" data-aos-delay="3200" data-aos-once="true">ClearedTalent is building a community - you can access our talent community and the skill tests
                for current and future opportunities
              </div>
            </div>
            <div className='buller-wrapper'>
              <div className='circle-text'>
                <div className='bullet-circle' data-aos="zoom-out" data-aos-anchor="#trigger-bullets" data-aos-delay="4200" data-aos-once="true"></div>
                <div className='bullet-line'></div>
              </div>
              <div className='bullet-text bullet_text_break' data-aos="zoom-in" data-aos-anchor="#trigger-bullets" data-aos-delay="4200" data-aos-once="true">Our Subject Matter Experts are constantly navigating technology changes and upgrading
                the assessments
              </div>


            </div>
          </div>
        </div>
        <div className="banner_bottom_section-1-employers">
          <div className="container w1200">
            <div className="request_demo_sec">
              <p className="banner_bottom_section_employers_left">According to Society for Human Resource Management,<br /> the average time-to-fill a position is 33.28 days
              </p>


              <p className="banner_bottom_section_employers_right"><span className="banner_bottom_section_employers_right_other_color">ClearedTalent’s</span> clients are benefiting from faster time-to-fill than industry average
              </p>



            </div>
          </div>
        </div>
        <div className="banner_bottom_section-2">
          <div className="container w1200">
            <div className="request_button" data-aos="fade-up">
              <button type="button" className="btn1-home btn-start_big request-demo" onClick={requestDemo} >REQUEST DEMO</button>
            </div>
          </div>
        </div>
        <div className="footer_bg_employerpage footer_bg_candidates" data-aos="fade-up">
          <div className="container w1200">
            <div className="footer_adjust footer-adjust-home">
              <div className='footer_left_logo homepage-footer-left-logo'>
                <img src={Logo} className="mt_40 w-100" alt='imagelogo' />
              </div>
              <div className="f_height">
                <div className='social_links'>
                  <a href='https://www.linkedin.com/company/clearedtalent/'><Tooltip title="LinkedIn"><LinkedIn className='iconColor' /></Tooltip></a>
                  <a href='https://www.facebook.com/Cleared-Talent'><Tooltip title="Facebook"><Facebook className='iconColor' /></Tooltip></a>
                  <a href='https://twitter.com/Cleared_Talent'><Tooltip title="Twitter"><Twitter className='iconColor' /></Tooltip></a>
                  <a href='https://www.instagram.com/clearedtalent/'><Tooltip title="Instagram"><Instagram className='iconColor' /></Tooltip></a>
                </div>
                <ul className="footer_links">
                  <li><a>ClearedTalent © {todayDate.getFullYear()}.&nbsp; All Rights Reserved.</a></li>
                  <li><span className="home-footer-terms cp" onClick={goToTerms}> Terms of Use </span>&nbsp;&nbsp;<span className="home-footer-terms cp" onClick={goToPrivacyPolicy}>Privacy Policy </span></li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <button id="myBtn" title="Go to top" onClick={goToTop} ref={topButton}>
        <img src={Arrow} alt='arrow' />
      </button>
      <Dialog
        open={demoOpen}
        onClose={contactFormClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        className="demoDialog"
        fullWidth={true}
      >
        <DialogTitle>
          <div className='support_line'> Request Demo</div>
          <img
            src={circleBlue}
            alt='img'
            className='blue'
          />
        </DialogTitle>
        <DialogContent className='support_con'>
          <div className='support_header'>
            <Grid container spacing={2} className='support_grid'>
              <Grid item xs={6}><div className='demoNameTitle'>First Name*</div>
                <OutlinedInput
                  placeholder="Enter your First Name"
                  className="demoTextBox"
                  value={contactFormFirstName}
                  onChange={(event) => setContactFormFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={6}><div className='demoNameTitle'>Last Name*</div>
                <OutlinedInput
                  margin='dense'
                  placeholder="Enter your Last Name"
                  className="demoTextBox"
                  value={contactFormLastName}
                  onChange={(event) => setContactFormLastName(event.target.value)}
                />
              </Grid>

              <Grid item xs={6}><div className='demoNameTitle'>Email*</div>
                <OutlinedInput
                  placeholder="Enter your Email ID"
                  className="demoTextBox"
                  value={contactFormEmail}
                  onChange={(event) => setContactFormEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={6}><div className='demoNameTitle'>Phone Number*</div>
                <PhoneInput
                  international
                  className="textbox_phone_input_demo"
                  placeholder="Enter your phone number"
                  value={phoneNoIntnl}
                  onChange={(e) => phoneNumberChangeHandler(e)}
                  onKeyPress={(e) => keypressHandler(e)}
                />
                {(phoneError) ? (
                  <p className="form-error fs-regular">
                    Please enter a valid phone number
                  </p>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={6}><div className='demoNameTitle'>Your Role *</div>
                <Autocomplete
                  id="combo-box-demo"
                  options={roleList}
                  getOptionLabel={(option) => option}
                  onChange={roleHandler}
                  renderInput={(params) => <TextField {...params} variant="outlined" placeholder='Please Select' />}
                />
              </Grid>
              <Grid item xs={6}><div className='demoNameTitle'>Your company</div>
                <OutlinedInput
                  placeholder="Enter your Company Name"
                  className="demoTextBox"
                  value={companyValue}
                  onChange={(event) => setCompanyValue(event.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <Button className={classes.btnCancel} id="buton" onClick={contactFormClose}>Cancel</Button>
          <Button className={classes.btnSave} id="buton" onClick={submitContactForm}
          >Submit</Button>
        </DialogActions>
      </Dialog>
      <NotificationContainer />
    </>
  );
};
export default EmployerPage;
