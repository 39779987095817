import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import Footer from "../../components/footer/footer";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import './emailConfiguration.css';
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Container from "@material-ui/core/Container";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import { process } from "@progress/kendo-data-query";
import { GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Grid as KendoGrid } from "@progress/kendo-react-grid";
import Button from "@material-ui/core/Button";
/*** components */

import AdminHeader from "../../components/adminHeader/adminHeader";

/*** components */

/*** apis */

import putUpdateSkillPopularity from "../../apis/putUpdateSkillPopularity";
import DefaultOrganization from "./DefaultOrganization";
import TestTakingInterval from "./TestTakingInterval";
import getAllRecruitersResponse from "../../apis/recruiters/getAllRecruiters";
import globalConfigurationResponse from "../../apis/globalConfiguration/globalConfiguration";
import updateConfigurationResponse from "../../apis/globalConfiguration/updateGlobalConfiguration";
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';
/*** api */

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
});

const SkillTestConfigaration = ({ idContextTest }) => {
  const { userType, userIdType } = useContext(UserTypeContext);
  const classes = useStyles();
  const jsonConfig = require("../../Config.json");
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [globalConfiguration, setglobalConfiguration] = useState([]);
  const [openEditModal, setopenEditModal] = useState(false);
  const [openEditModale, setopenEditModale] = useState(false);
  const [dataForEdit, setdataForEdit] = useState({});
  const [popularitySwitchState, setPopularitySwitchState] = useState(false);
  const [defaultOrganization, setDefaultOrganization] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [isDataUpdaed, setisDataUpdaed] = useState(false);
  const [atvBtn, setAtvBtn] = useState(false);



  const getAllOrganizations = async () => {

    try {
      const res = await getAllRecruitmentPartnersAndClients();

      setAllOrg(res.data.data);
    } catch (err) {
      console.log(err);
    }

  };

  const getGlobalConfiguration = async () => {

    try {
      const globalConfigResponse = await globalConfigurationResponse();

      setglobalConfiguration(
        globalConfigResponse.data.data.filter((e) => !e.name.includes("Default"))
      );
      const filteredData = globalConfigResponse.data.data.filter((org) =>
        org.name.includes("Default")
      );

      setDefaultOrganization(filteredData);
      setisDataUpdaed(false);

    } catch (error) {
      console.log(error);
    }

  };
  const openEditModalHandler = (list) => {
    if (list.name == "Skill Test Limit") {
      setopenEditModale(true);
      setdataForEdit(list);
    }
    else {
      setopenEditModal(true);
      setdataForEdit(list);

    }
  };
  const closeEditModalHandler = () => {
    setopenEditModal(false);
    setopenEditModale(false);
  };
  const valueChangeHandler = (value) => {
    setdataForEdit((prev) => ({
      ...prev,
      value: value,
    }));
  };
  const updateConfigarationHandler = async () => {
    setAtvBtn(true);
    try {
      const payload = {
        code: dataForEdit.code,
        data: dataForEdit,
      }

      if (payload.data.value > -1) {
        const updateConfigResponse = await updateConfigurationResponse(payload);
        getGlobalConfiguration();
        NotificationManager.success(
          `${dataForEdit.name} updated`,
          "Success",
          2500
        );
        setopenEditModal(false);
        setopenEditModale(false);
        setAtvBtn(false);

      }
      else {
        NotificationManager.error('Value can not be in negative', 'Error', 2500)
        setAtvBtn(false);
      }


    } catch (error) {
      console.log(error);
      setAtvBtn(false);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getGlobalConfiguration();
        await getAllOrganizations();
      } catch (error) {
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }
    fetchAllData();

  }, []);


  // creating filter for kendoGrid

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  // fetching data(allSubSkillForCard) from database

  const [result, setResult] = useState(globalConfiguration);
  const dataStateChange = (event) => {
    setResult(process(globalConfiguration, event.dataState));
    setDataState(event.dataState);
  };
  useEffect(() => {


    setResult(process(globalConfiguration, dataState));

  }, [globalConfiguration]);

  // editing button on click
  const handleEdit = (props) => {
    return (
      <td className="actionContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openEditModalHandler(props.dataItem)}
        >
          Edit
        </Button>
      </td>
    );
  };
  return (
    <div>
      <AdminHeader uid={idContextTest} isDataLoaded={isDataLoaded} />
      <div className="section-wrapper">
        <div className="container w1200 flex-to-footer">
          <div className="height_adjust mt_100">
            {!isDataLoaded ? (
              <div className="row padding_80 text-center">
                <CircularProgress />
              </div>
            ) : (
              <>
                {" "}
                {userType === "Admin" ? (
                  <>
                    <div className="section-wrapper email-configuration-container">
                      <section className="email-configuration-wrapper">
                        <div className="test-result-list">
                          <DefaultOrganization
                            data={defaultOrganization}
                            org={allOrg}
                            setisDataUpdaed={setisDataUpdaed}
                            isDataLoaded={isDataLoaded}
                          />
                          <h3 className="fs-semi-bold fs-30 f-color mt-15">
                            Global Configuration
                          </h3>
                          {/*KENDO GRID */}
                          <KendoGrid
                            className="kw"
                            filter={dataState.filter}
                            resizable={true}
                            filterable={true}
                            sort={dataState.sort}
                            pageable={{
                              buttonCount: 10,
                              info: true,
                              previousNext: true,
                              pageSizes: true,
                            }}
                            skip={dataState.skip}
                            take={dataState.take}
                            data={result}
                            onDataStateChange={(e) => dataStateChange(e)}
                          > {!isDataLoaded && (
                            <GridNoRecords>
                              <div className='col-4'>
                                <br />
                                <Loader size='large' themeColor="info" type="converging-spinner" />
                              </div>
                            </GridNoRecords>
                          )}
                            <GridColumn
                              field="name"
                              title="Name"
                              width="200px"
                              resizable={true}
                              filterable={true}
                            />
                            <GridColumn
                              field="value"
                              title="Values"
                              width="200px"
                              resizable={true}
                              filterable={true}
                            />
                            <GridColumn
                              title="Edit"
                              width="200px"
                              resizable={true}
                              filterable={false}
                              cell={handleEdit}
                            />
                          </KendoGrid>
                          <TestTakingInterval
                            org={allOrg}
                            userType={userType}
                            setisDataUpdaed={setisDataUpdaed}
                          />
                        </div>
                      </section>
                    </div>
                  </>
                ) : userType === "" ? (
                  <div className="row padding_80 text-center">
                    <p className="fs-semi-bold fs-40 f-color">
                      Loading...
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="row padding_80 text-center">
                      <p className="fs-semi-bold fs-40 f-color">
                        You Are Not Authorized To Visit This Page
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={openEditModal}
        onClose={closeEditModalHandler}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          {dataForEdit.name}
        </DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="dropdown_width_outer mt_12">
            <div className="select customized-select">
              <legend>Value(Days)</legend>
              <input
                type="number"
                className="text_box_cleartalent custom-text-width-demo-email configaration_input"
                defaultValue={dataForEdit.value}
                min='0'
                onChange={(e) => valueChangeHandler(e.target.value)}

              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn5 btn-skill cp"
            onClick={closeEditModalHandler}
          >
            Cancel
          </button>
          <button variant="outlined" className="btn5 btn_primary cp" disabled={atvBtn} onClick={updateConfigarationHandler}>
            <div className="btn_loader">
              Submit
              &nbsp;
              {atvBtn &&
                <div >
                  <CircularProgress size={15} />
                </div>
              }
            </div>
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditModale}
        onClose={closeEditModalHandler}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          className={`${classes.title} responsive_title`}
        >
          {dataForEdit.name}
        </DialogTitle>

        <DialogContent
          className={`${classes.bodyForSupport} responsive_Support`}
        >
          <div className="dropdown_width_outer mt_12">
            <div className="select customized-select">
              <legend>Value</legend>
              <input
                type="number"
                className="text_box_cleartalent custom-text-width-demo-email configaration_input"
                defaultValue={dataForEdit.value}
                min='0'
                onChange={(e) => valueChangeHandler(e.target.value)}

              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <button
            type="button"
            className="btn5 btn-skill cp"
            onClick={closeEditModalHandler}
          >
            Cancel
          </button>
          {/* <button
            type="button"
            className="btn-big btn_primary cp"
            onClick={updateConfigarationHandler}
          >
            Submit
          </button> */}

          <button variant="outlined" className="btn5 btn_primary cp" disabled={atvBtn} onClick={updateConfigarationHandler}>
            <div className="btn_loader">
              Submit
              &nbsp;
              {atvBtn &&
                <div >
                  <CircularProgress size={15} />
                </div>
              }
            </div>
          </button>
        </DialogActions>
      </Dialog>





      <NotificationContainer />
    </div>
  );
};

export default SkillTestConfigaration;
