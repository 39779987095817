import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from '@material-ui/core/styles';
import { NotificationManager } from 'react-notifications';
import emailConfigurationByCodeResponse from '../../apis/email/emailConfigurationByCode';
import sendEmailResponse from '../../apis/email/sendEmail';
import { Grid, OutlinedInput } from "@material-ui/core";
import { Button } from 'react-bootstrap'
import Ellipse from "../poweResumeHeader/images/Ellipse.png";
import validateEmail from '../../utilities/validateEmail';
const useStyles = makeStyles((theme) => ({
  bodyForSupport: {
    padding: '0px 30px!important'
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "25px 30px 30px!important"
  },
  title: {
    color: 'red',
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: '0px 30px!important'
  },
  bodyForSupport: {
    padding: "0px 30px!important",
  },
  buttonForSupport: {
    justifyContent: "flex-end !important",
    padding: "5px 42px 20px !important",
  },
  title: {
    padding: "16px 30px!important",
  },
  bodyForShare: {
    padding: "0px 30px!important",
  },
  dialog: {
    position: "absolute",
    right: "0",
    top: "6%",
    borderRadius: "10px",
  },
  heading: {
    fontFamily: "Source Sans Pro",
    fontSize: "45px",
    fontWeight: 600
  },
  write: {
    fontFamily: "Source Sans Pro",
    fontSize: "24px",
    color: "#519AF8",
    cursor: "pointer",
  },
  btnCancel: {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFFFFF",
    color: "#1885E6",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    fontSize: '15px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#D1E8FF"
    }
  },
  btnSave: {
    width: "120px",
    height: "32px",
    backgroundColor: "#1885E6",
    fontSize: '15px',
    color: "#FFFFFF",
    border: "1px solid #1885E6",
    paddingTop: '5px',
    borderRadius: "3px",
    "&:hover": {
      opacity: "0.8"
    }
  }
}
));
const LoggedInSupportModal = ({ modalOpen, mailData, modalClose }) => {
  const classes = useStyles();
  const jsonConfig = require("../../Config.json");
  const [userName, setuserName] = useState('');
  const [userMail, setuserMail] = useState('');
  const [mailQuestion, setmailQuestion] = useState('');
  const [allSupportMail, setallSupportMail] = useState([])
  const [verifyEmailBtn, setVerifyEmailBtn] = useState(true);
  const handleClose = () => { 
    modalClose()
    setVerifyEmailBtn(true);
  }
  const getAllMail = async () => {
    try {
      var codeForMail = {
      "code": "CTsupportemail"
      }
      const getAllMailResponse = await emailConfigurationByCodeResponse(codeForMail);
      setallSupportMail(getAllMailResponse.data.data[0].emails)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    let user_name = mailData.firstName + ' ' + mailData.lastName;
    setuserName(user_name)
    setuserMail(mailData.email)
    getAllMail();
    setmailQuestion("")
  }, [modalOpen])
  const mailSend = async () => {
    const mailBody = mailQuestion;
    if (mailBody != '' && userName != '' && userMail != '') {
        try {
          const message = {
            to: allSupportMail,
            from: jsonConfig.sendGridFromEmail,
            subject: `Message from ${mailData.firstName} ${mailData.lastName}`,
            html: `Candidate Name: ${userName}<br />
            Candidate Email: ${userMail} <br/>
            Candidate Phone number: ${mailData.phoneNumber} <br />
            Question: ${mailBody}`
          }
          if (jsonConfig.sendEmail) {
          const supportEmailResponse = await sendEmailResponse(message);
        } 
          modalClose();
          setmailQuestion("")
          NotificationManager.success('Done! Your concern has been noted. Someone from our support team will reach out to you shortly.', 'Success');
        } catch (err) {
          console.log(err)
          NotificationManager.error("Error sending email. Please try again after some time.", "Error");
        }
    }
    else {
      NotificationManager.error("Please enter all field.", "Error");
    }
  }
  const NameChangeHandler = (e) => {
   
    setuserName((e.target.value));
  }
  var emailValue;
  const emailChangeHander = (e) => {
    if(emailValue != mailData.email)
    {
    emailValue = e.target.value;
    }
    if (validateEmail(emailValue)) {
      setuserMail(emailValue);
      setVerifyEmailBtn(true);
    }
    else {
      setVerifyEmailBtn(false);
      setuserMail('');
    }
  }


  return (
    <> 
    <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          <div className='support_line'>Contact Us</div>
          <img
            src={Ellipse}
            alt='img'
            className='blue'
          />
        </DialogTitle>
        <DialogContent className='support_con'>
          <div className='support_header'>
            <Grid container spacing={2} className='support_grid'>
              <Grid item xs={6}><div className='support_form_leb'>Name</div></Grid>
              <Grid item xs={6}><div className='support_form_leb'>Email</div></Grid>
              <Grid item xs={6}>
                <OutlinedInput
                  placeholder="Please enter your full name"
                  className="Support_textbox"
                  defaultValue={`${mailData.firstName} ${mailData.lastName}`}
                  onChange={NameChangeHandler}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingRight: '0px' }}>
                <OutlinedInput
                  margin='dense'
                  placeholder="Please enter your email"
                  className="Support_textbox"
                  defaultValue={mailData.email}
                  onChange={emailChangeHander}
                />
                <>
                  {verifyEmailBtn ?
                    ''
                    :
                    <>
                      <p className='form-error fs-regular'>Please enter valid email address</p>
                    </>
                  }
                </>
              </Grid>
              <Grid item xs={12} className="support_grid_message">
                <div id="mes_lable">Your Message</div>
              </Grid>
              <Grid item xs={12}>
                <textarea
                  type="text"
                  className="support_message"
                  onChange={(event) => setmailQuestion(event.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={`${classes.buttonForSupport} `}>
          <Button className={classes.btnCancel} id="buton" onClick={handleClose}>Cancel</Button>
          <Button className={classes.btnSave} id="buton" onClick={mailSend}
          >Send</Button>
        </DialogActions>
      </Dialog>
      </>
  )
}

export default LoggedInSupportModal
