import React, { useState, useEffect, useCallback, useRef } from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import "./profileCompletion.css";
import pencil from "./images/pencil.png";
import noProfilePictureImage from "./images/no-user.png";
import location from "./images/location.png";
import mobile from "./images/mobile.png";
import { makeStyles } from '@material-ui/core/styles';
import cross from "./images/cross.svg"
import camera from "./images/camera.png";
import "date-fns";
import "react-dropdown/style.css";
import {
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import { FilePicker } from "react-file-picker";
import "react-circular-progressbar/dist/styles.css";
import csc from "../../../utilities/country-state-city/dist"
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import getCroppedImg from './cropImage';
import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import 'react-phone-number-input/style.css'
import 'react-html5-camera-photo/build/css/index.css';
import Tooltip from '@material-ui/core/Tooltip';
import updatelastlogedin from "../../../apis/profile/updatelastlogedin";
import { DateTime } from "luxon";
import profilePictureUpdateResponse from "../../../apis/profile/profilePictureUpdate";
import phoneNumberExistResponse from "../../../apis/users/getUserDataByPhoneNumber";
import ProfileById from '../../../apis/profile/profileById'
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumber, isValidPhoneNumber, isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import user_register from "../../../components/basicInfoForm/images/avatar.png";

const useStyles = makeStyles((theme) => ({
  buttonAlign: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 42px 24px 42px !important'
  },
  bodyForEditProfile: {
    marginTop: "70px",
  },
  changeProfiletitle: {
    padding: "16px 42px!important"
  },
  changeprofilebody: {
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  },
  closeButton: {
    position: "absolute",
    right: "45px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonForProfilePic: {
    position: "absolute",
    right: "8px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});


const DialogTitleForProfilePic = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" >{children}</Typography>
      {onClose ?
        <div className="crossFormodal cp" onClick={onClose}><img className="closeicon_set" src={cross} alt=""></img></div>
        : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {

  }
}))(MuiDialogContent);
const DialogContentForprofilepic = withStyles((theme) => ({
  root: {
    marginTop: "0px!important"
  }
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),


  }
}))(MuiDialogActions);
// for tooltip
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#1A8FFF",
  },
  tooltip: {
    backgroundColor: "#1A8FFF",
    padding: "4px 13px",
    fontFamily: "Segoe-Semibold",
    fontSize: "13px"

  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const video = document.getElementById('video');
const canvas = document.getElementById('canvas');
const snap = document.getElementById("snap");
const errorMsgElement = document.querySelector('span#errorMsg');

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.name,
});

const ProfileCompletion = ({ setLastUpdate, userId, mailData, setMailData,profileData}) => {
  const classes = useStyles();
  const jsonConfig = require("../../../Config.json");
  // const [profileData, setProfileData] = useState({});
  const [userEmail, setUserEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState(noProfilePictureImage);
  const [modalProfilePictureUrl, setModalProfilePictureUrl] = useState(null);
  const [profilePictureEditModal, setProfilePictureEditModal] = useState(false);
  const [editprofileModal, setEditprofileModal] = useState(false);
  const [loadingController, setLoadingController] = useState(false);
  const [locationCountry, setLocationCountry] = useState("");
  const [locationState, setLocationState] = useState("");
  const [locationCity, setLocationCity] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  // const [modalFirstName, setModalFirstName] = useState("");
  const [modalLastName, setModalLastName] = useState("");
  const [modalMiddleInitial, setmodalMiddleInitial] = useState("");
  const [totalExpYearSelected, setTotalExpYearSelected] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [availablephnno, setavailablephnno] = useState("");
  const [email, setemail] = useState("")
  const [previousImageURL, setPreviousImageURL] = useState('')
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneNoIntnl, setPhoneNoIntnl] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneNumberEditModal, setPhoneNumberEditModal] = useState("");
  const [countryCodeEditModal, setCountryCodeEditModal] = useState("");
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false)
  const [defaultCountryCode, setDefaultCountryCode] = useState("")



  const [modalCountryObject, setModalCountryObject] = useState({});
  const [stateList, setStateList] = useState(Array);
  const [modalStateObject, setModalStateObject] = useState({});
  const [cityList, setCityList] = useState(Array);
  const [countryCode, setcountryCode] = useState("")
  const [modalCityObject, setModalCityObject] = useState({});
  const [userCountry, setUserCountry] = useState({});
  const [userState, setUserState] = useState({});
  const [userCity, setUserCity] = useState({});
  const [display, setdisplay] = useState(false);
  const [isEmailCopied, setisEmailCopied] = useState(false)
  const userCameraStreamRef = useRef(null);
  const [isDefaultProfile, setisDefaultProfile] = useState(false)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        modalProfilePictureUrl,
        croppedAreaPixels,
        rotation
      )
      var block = croppedImage.split(";");

      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      await b64toBlob(realData, contentType,true);
      setdisplay(false);
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  // for delete profile img

  const removeImage = useCallback(async (defaultProfilePicture) => {
    let cropArea = {
      height: 250,
      width: 250,
      x: 0,
      y: 0
    }
    try {
      const croppedImage = await getCroppedImg(
        user_register,
        cropArea,
        0

      );
      var block = croppedImage.split(";");

      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      await b64toBlob(realData, contentType, false, defaultProfilePicture);
      setdisplay(false);
      closeProfilePicModal();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const deleteCroppedImg = () => {
    const defaultProfilePicture = true;
    removeImage(defaultProfilePicture);
  }



  const getProfileData = () => {
    try {
      const user = profileData.user;
      setUserEmail(user.email)
      // setMailData(() => ({    
      //   email: user.email,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   phoneNumber: user.phoneNumber
      // }));
    }
    catch (error) {
      console.log(error);
    }

    try {
      profileData?.profilePicUrl == null ? setPreviousImageURL('') : setPreviousImageURL(profileData?.profilePicUrl) 
      if (
        profileData?.profilePicUrl != "" &&
        profileData?.profilePicUrl != null
      ) {
        setProfilePicture(profileData?.profilePicUrl);
      }
      if(userEmail)
      setLoadingController(true);
    } catch (error) {
    }
  };
  const getProfileDatas = async()=>{

    try {
      const profileResponse = await ProfileById(userId);
      var profileDataParsed = profileResponse.data.data[0];
     
      setLastUpdate(profileDataParsed.lastUpdated); 
      if (
        profileResponse.data?.data[0]?.profilePicUrl != "" &&
        profileResponse.data?.data[0]?.profilePicUrl != null
      ) {
        setProfilePicture(profileResponse.data?.data[0]?.profilePicUrl);
      }
    } catch (error) {
      // setLoadingController(true);
    }
  }
  const getupdatelastlogedin = async () => {
    try {
      const payload = { userid: userId }
      const res = await updatelastlogedin(payload);
    } catch (err) {
      console.log(err);
    }
  };
  const b64toBlob = async (b64Data, contentType,status,defaultProfilePicture, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType }, { name: 'profile.png' });
    await uploadToBlobStorage(blob,status,defaultProfilePicture);
  }
  const b64toBlobForCamera = async (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType }, { name: 'profile.png' });
    const url = URL.createObjectURL(blob)
    setModalProfilePictureUrl(url)
  }

  const filePickerOnChange = async (file) => {
    const downloadedUrl = URL.createObjectURL(file);
    setModalProfilePictureUrl(downloadedUrl);
  };


  const uploadToBlobStorage = async (blob,status,defaultProfilePicture) => {
    const sas = jsonConfig.sasToken;
    let decodedToken = decodeURI(sas);
    const bsClient = new BlobServiceClient(
      `${jsonConfig.ctResourcesCdn + sas}`
    );
    const containerClient = bsClient.getContainerClient("profilepicture");
    let timeStamp = new Date().getTime();
    const profileFileName=defaultProfilePicture?"defaultProfile_":"profile_";
    const blobName =  userId + "/" + profileFileName+ timeStamp + ".png";
    const url = `${jsonConfig.ctResourcesCdn}/profilepicture/${blobName}`
    const blobClient = containerClient.getBlockBlobClient(blobName);
    const response = await blobClient.uploadBrowserData(blob);
    var dt = new Date();
    var profileimagedata = {
      profilePicUrl: url,
      lastUpdated: dt,
      previousProfielPicUrl: previousImageURL
    };
    try {
      const payload = {
        userId: userId,
        profileimagedata: profileimagedata,
      }

      const response = await profilePictureUpdateResponse(payload);
      // NotificationManager.success("Profile picture uploaded", "Success");
      if (status === true) {
        NotificationManager.success("Profile picture uploaded", "Success", 2500);
    }
    else {
        NotificationManager.success("Profile picture removed", "Success", 2500);
    }
      getProfileDatas();
      setProfilePictureEditModal(false);
    }
    catch (error) {
      console.error(error);
    }
  }

  const countryHandler = (event, value) => {
    if (value === null) {
      setUserCountry('')
    }
    setModalStateObject("");
    setModalCityObject("");
    if (value) {
      setUserCountry(value);
      setStateList(csc.getStatesOfCountry(value.isoCode));
      setUserState('')
      setUserCity('')
    }
  };
  const stateHandler = async (event, value) => {
    setModalCityObject("");
    if (value === null) {
      setUserState('')
    }
    else if (value) {
      setUserState(value);
      setUserCity('')
      setCityList(csc.getCitiesOfState(value.countryCode, value.isoCode));
      setModalStateObject(value);
    }
    else {
      setUserState({})
      setUserCity({})
    }
  };
  const cityHandler = async (event, value) => {
    setUserCity({})
    if (value) {
      setUserCity(value);
      setModalCityObject(value);
    }
  };

  const openProfilePictureModal = async () => {
    getProfileData();
    setModalProfilePictureUrl(profilePicture)
    !profileData.profilePicUrl?<>{setisDefaultProfile(true)}</>: profilePicture.includes("default")?setisDefaultProfile(true):setisDefaultProfile(false)
    setProfilePictureEditModal(true);
  };
  const closeProfilePicModal = async () => {
    setdisplay(false);
    if (userCameraStreamRef.current) {
      userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    setProfilePictureEditModal(false)
  }

 
  // phone no validation chk api call
  const checkForDuplicatePhoneNumber = async (phoneNumber) => {
    var phnnoinput = {
      "phoneNumber": phoneNumber
    }
    try {
      const phoneNochkResponse = await phoneNumberExistResponse(phnnoinput);
      if (phoneNochkResponse.data.data[0].isUnique === true) {
        return (
          phoneNochkResponse.data.data[0].isUnique
        )
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const tooltipstyle = {
    backgroundColour: "red"
  }

  // for camera
  const constraints = {
    audio: false,
    video: {
      width: 640, height: 420
    }
  };

  const opencameraModal = async () => {
    setdisplay(true)

    const video = document.getElementById('video');

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      window.stream = stream;
      video.srcObject = stream;
      userCameraStreamRef.current = stream;
    } catch (e) {
    }

  }
  const captureImage = async () => {
    const video = document.getElementById('video');
    const canvas = document.getElementById('canvas');
    const context = canvas.getContext('2d');
    var a = context.drawImage(video, 0, 0, 640, 480);
    var b = context.getImageData(0, 0, 640, 480)
    const imageUrl = canvas.toDataURL('image/jpeg');
    var block = imageUrl.split(";");

    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    await b64toBlobForCamera(realData, contentType);
    setdisplay(false);
    userCameraStreamRef.current.getTracks().forEach((track) => track.stop());
  }
  const copyEmail = (e, useremail) => {
    setisEmailCopied(true)
    navigator.clipboard.writeText(useremail);
    setTimeout(
      changeCopyText
      , 3000
    )
  }
  const changeCopyText = () => {
    setisEmailCopied(false)
  }
  
  const getCustomizedFirstName = (fullName) => {
    const names = fullName.split(" ");
    let firstName = names[0];
  
    if (firstName.length > 15) {
      firstName = `${firstName.slice(0, 15)}...`;
    }
    return firstName;
  };

  useEffect(() => {
    getProfileData();

  }, [profileData]);
  const keypressHandler = (e) => {
    var invalidChars = [
      "-",
      "+",
      "e",
    ];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  }
  const phoneNumberChangeHandler = (val) => {
    setPhoneNoIntnl(val)
    setPhoneNumberChanged(true);
    if (val) {
      if (val && isPossiblePhoneNumber(val)) {
        setPhoneError(false)
        setCountryCodeEditModal(`+${parsePhoneNumber(val).countryCallingCode}`);
        let countryCode_PhoneNumber = `+${parsePhoneNumber(val).countryCallingCode}`
        setPhoneNumberEditModal(val.replace(countryCode_PhoneNumber, ""));
      }
      else {
        setPhoneError(true)
      }
    }
    else {
      setPhoneError(false)
      setCountryCodeEditModal("")
      setPhoneNumberEditModal("")
    }
  };
  const closeEditProfileModal = async()=>{
    getProfileData();
    setPhoneError(false)
    setEditprofileModal(false)
  }

  return (
    <>
      {
        loadingController ?
          <>
            <div className="card_top profile_card pb_5 ">
              <div className="row">
                <div className="mt_22">
                  <div className="fle">
                    <div className="recruiter_profile_image_contain mt-10">
                      <img
                        src={profilePicture}
                        alt=""
                        className="powerProfileImage"
                      />
                      <img src={camera}
                        className="camera_icon_profile  cp"
                        onClick={openProfilePictureModal}
                      />
                    </div>
                    <div>
                  
                  <p className="fs-bold fs-12 f-color resume_name">
                    {profileData.user ? getCustomizedFirstName(profileData.user.firstName) : ""} {profileData.user ? profileData.user.MiddleInitial : ""} {profileData.user ? profileData.user.lastName : ""}

                  </p>
                  <BootstrapTooltip title={isEmailCopied ? "Copied" : "Click to copy Email"}>
                    <p className="fs-regular fs-14 f-color br-word email_hidde ml_12" onClick={(e) => copyEmail(e, userEmail)} >{userEmail ? userEmail : ""}</p>
                  </BootstrapTooltip>
                  </div>
                  </div>
                </div>
                <div >
                </div>
              </div>
            </div>             
            <Dialog
              open={profilePictureEditModal}
              onClose={closeProfilePicModal}
              aria-labelledby="customized-dialog-title"
              maxWidth="sm"
              fullWidth={true}
              className='record-video-intro-responsive'
            >
              <DialogTitleForProfilePic className={`${classes.changeProfiletitle} responsive-profilepic_title`} onClose={closeProfilePicModal}>Change Profile Picture</DialogTitleForProfilePic>

              <DialogContentForprofilepic className={`${classes.changeprofilebody} responsive-profilepic_body`}>
                {display ? null :
                  <div className="text-center">
                    <div className="crop-container" id="circle_ProfilePic">
                      <Cropper
                        image={modalProfilePictureUrl}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        // aspect={4 / 3}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </div>
                    <div className="controls">
                      <div className="w-100">
                        <p className="fs-semi-bold fs-15 mt_19 f-color">Zoom</p>
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e, zoom) =>
                            setZoom(zoom)}
                        />
                      </div>
                      <div className="w-100">
                        <p className="fs-semi-bold fs-15 mt_19 f-color">Rotation</p>
                        <Slider
                          value={rotation}
                          min={0}
                          max={360}
                          step={1}
                          aria-labelledby="Rotation"
                          onChange={(e, rotation) =>
                            setRotation(rotation)}
                        />
                      </div>
                    </div>
                    <div className="profilecamera_upload">
                      <FilePicker
                        extensions={['jpg', 'jpeg', 'png']}
                        onChange={filePickerOnChange}
                        onError={errMsg => {
                          NotificationManager.error(errMsg, 'Error');
                        }}
                      >
                        <button type="button" className="btn change_profile_btn cp ">Upload Picture</button>
                      </FilePicker>
                      <span><button type="button" className="btn change_profile_btn ml_5 cp " onClick={opencameraModal}>Use My Camera</button></span>
                    </div>
                  </div>
                }
                <div className="camera_section">
                  <video id="video" playsInline autoPlay style={display ? { display: "block", width: "100%" } : { display: "none" }}></video>
                  <button id="snap" className="btn change_profile_btn cp " onClick={captureImage} style={display ? { marginTop: "26px", marginBottom: "26px" } : { display: "none" }}>Capture</button>
                  <canvas id="canvas" width="640" height="480" style={{ display: "none" }}></canvas>
                </div>

              </DialogContentForprofilepic>
              {display ? null :
                <>
                  <div className="divider_adjust">
                    <hr className="new3 mt_20 mb_0" />
                  </div>
                  <DialogActions className={`${classes.buttonAlign} responsive-dialog`}>
                    <button type="button" className="btn-big btn-skill cp " onClick={closeProfilePicModal}>Cancel</button>
                    {isDefaultProfile ?
                                <>
                                </> :
                                <>
                            <button
                                type="button"
                                className="btn-big btn-skill ml_5 cp "
                                onClick={deleteCroppedImg}
                            >Remove
                            </button>
                            </>}
                    <button type="button" className="btn-big btn-green ml_5 cp "
                      onClick={showCroppedImage}
                    >Save</button>
                  </DialogActions>
                </>
              }
            </Dialog>
          </>
          :
          <>

<div className="card_top profile_card pb_5 " style={{width:'30vh'}} >
              <div className="row">
                <div className="mt_22">
                  <div className="fle">
                    <div className="recruiter_profile_image_contain mt-10">

                    <Skeleton variant="circle" width={40} height={40} animation="wave" />
                      
                    </div>
                    <div>               
                  <p className="fs-bold fs-12 f-color resume_name" style={{width:'20vh'}}>
                    <Skeleton variant="text" animation="wave" />
                  </p>
                  <p className="fs-bold fs-12 f-color">
                    <Skeleton variant="text" animation="wave" />
                  </p>
               
                  </div>
                  </div>
                </div>
                <div >
                </div>
              </div>
            </div>
          </>
      }
    </>

  );
};
export default ProfileCompletion
