import axios from "axios";
const jsonConfig = require("../../Config.json");

const deleteCandidateActivityEventById = async (payload) => {
    try {
        const resp = await axios.post(jsonConfig.apiUrl + `deleteRequisitionActivity`, payload, {
            headers: {
              "x-functions-key": jsonConfig.key,
            },
        });
        return Promise.resolve(resp);
    }
    catch(err) {
        return Promise.reject(err);
    }
}

export default deleteCandidateActivityEventById;