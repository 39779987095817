import { Button, makeStyles } from "@material-ui/core";
import { GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState, useRef, useContext } from "react";
import Header from "../../components/adminHeader/adminHeader";
import { Grid as KendoGrid, GridNoRecords } from "@progress/kendo-react-grid";
import getAllTests from "../../apis/Tests/getAllTests";
import { process } from "@progress/kendo-data-query";
import "./AllTests.css";
import TestModal from "../../components/addTestModal/TestModal";
import EditTestModal from "../../components/editTestModal/EditTestModal";
import getTestTypes from "../../apis/Tests/getTestTypes";
import getSkills from "../../apis/Tests/getSkills";
import getSubskills from "../../apis/Tests/getSubskills";
import getAllRecruitmentPartnersAndClients from "../../apis/getAllRecruitmentPartnersAndClients";
import { NotificationContainer } from "react-notifications";
import getSkillResponse from "../../apis/skills/getActiveSkills";
import questionBySubSkill from "../../apis/Tests/questionBySubskill";
import { UserTypeContext } from "../../AppRoute";
import { Loader } from '@progress/kendo-react-indicators';
const useStyles = makeStyles({
  fontRoboto: {
    fontFamily: "Roboto,sans-serif",
  },
  button: {
    marginBottom: "10px",
  },

  // hK: {
  //   height:"78vh",
  // },

});

const AllTests = ({ idContextTest }) => {
  const classes = useStyles();
  const [testData, setTestData] = useState({});
  const [testTypes, setTestTypes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [subskills, setSubskills] = useState([]);
  const [AllActiveRecruitmentpartner, setAllActiveRecruitmentpartner] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allTestData, setAllTestData] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true
        }
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [questionBySkill, setQuestionBySkill] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const { userType, userIdType } = useContext(UserTypeContext)

  const btnBlue = {
    color: '#fff',
    border: 'none',
    padding: '7px 23px 7px 23px',
    float: 'right',
    marginTop: '25px',
    marginBottom: '1em'
  };

  const [result, setResult] = React.useState(allTestData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await getAllTest();
        await allTestTypes();
        await allSkills();
        await allSubskills();
        await allRecruitmentpartner();
      } catch (error){
        setisDataLoaded(true);
      } finally {
        setisDataLoaded(true);
      }
    }
    fetchAllData();
  }, []);
  useEffect(() => {
    setResult(process(allTestData, dataState));
  }, [allTestData]);
  useEffect(() => {
    const getData = async () => {
      let data = testData;
      let arr = [];
      let total = 0;
      for (let i = 0; i < data.testsubskillsmappings.length; i++) {
        if (data.testsubskillsmappings[i].noOfQuestion) {
          total += data.testsubskillsmappings[i].noOfQuestion;
        }
        if (data.testsubskillsmappings[i].subskillId) {
          const payload = {
            subSkillsId: data.testsubskillsmappings[i].subskillId ? data.testsubskillsmappings[i].subskillId : "",
          };
          const res = await questionBySubSkill(payload);
          if (res.status === 200) {
            arr.push(res.data.data.length);
          }
        }
      }
      setTotalQuestion(total);
      setQuestionBySkill(arr)
    }
    if (isEdit)
      getData();
  }, [isEdit])
  const dataStateChange = (event) => {
    setResult(process(allTestData, event.dataState));
    setDataState(event.dataState);
  };

  const getAllTest = async () => {
    const response = await getAllTests();
    setAllTestData(response.data.data);
    setisDataLoaded(true);

  };

  const allTestTypes = async () => {
    await getTestTypes()
      .then((response) => { setTestTypes(response.data.data) })
      .catch((error) => console.log(error));
  };

  const allSkills = async () => {
    await getSkillResponse()
      .then((response) => { setSkills(response) })
      .catch((error) => console.log(error));
  };

  const allSubskills = async () => {
    await getSubskills()
      .then((response) => { setSubskills(response.data.data) })
      .catch((error) => console.log(error));
  };

  const allRecruitmentpartner = async () => {
    await getAllRecruitmentPartnersAndClients()
      .then((response) => { setAllActiveRecruitmentpartner(response.data.data) })
      .catch((error) => console.log(error));
  }

  // useEffect(() => {
  //   getAllTest();
  //   allTestTypes();
  //   allSkills();
  //   allSubskills();
  //   allRecruitmentpartner();
  // }, []);

  useEffect(() => {
    setResult(process(allTestData, dataState));
  }, [allTestData]);

  const editHandler = (props) => {
    if (Object.keys(props.dataItem).length > 0) {
      let data = JSON.parse(JSON.stringify(props.dataItem));
      setTestData(data);
      setIsEdit(true);
    } else {
      setTestData("");
    }
  };

  const handleEdit = (props) => {
    return (
      <td className="actionContainer">
        <Button
          variant="contained"
          color="primary"
          onClick={() => editHandler(props)}
        >
          Edit
        </Button>
      </td>
    );
  };

  return (
    <>
      <Header uid={idContextTest} isDataLoaded={isDataLoaded} />
      {userType === "Admin" ?
        (
          <><TestModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            getTests={getAllTest}
            testTypes={testTypes}
            skills={skills}
            subskills={subskills}
            customers={allCustomers}
            recruitmentPartner={AllActiveRecruitmentpartner}
            setQuestionBySkill={setQuestionBySkill}
            questionBySkill={questionBySkill}
            totalQuestion={totalQuestion}
            setTotalQuestion={setTotalQuestion}
          />
            <EditTestModal
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              getTests={getAllTest}
              testData={testData}
              setTestData={setTestData}
              testTypes={testTypes}
              skills={skills}
              subskills={subskills}
              customers={allCustomers}
              recruitmentPartner={AllActiveRecruitmentpartner}
              setQuestionBySkill={setQuestionBySkill}
              questionBySkill={questionBySkill}
              totalQuestion={totalQuestion}
              setTotalQuestion={setTotalQuestion}
            />
            <div className="section-wrapper">
              <div className="container w1200 flex-to-footer mt_100">
                <div className="table_wrapper alltest_table_bg">
                  <div className="table_container alltest_width">
                    <div className="button_container">
                      <p className="fs-semi-bold fs-30 f-color">Tests</p>
                      <Button
                        className={classes.button}
                        variant="contained"
                        style={btnBlue}
                        color="primary"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Add New Test
                      </Button>
                    </div>
                    <KendoGrid
                      className={classes.fontRoboto}
                      filter={dataState.filter}
                      filterable={true}
                      sort={dataState.sort}
                      style={{
                        maxHeight: "70vh",
                        width: "100%",
                        overflow: "hidden"
                      }}
                      pageable={{
                        buttonCount: 10,
                        info: true,
                        previousNext: true,
                        pageSizes: true,
                      }}
                      resizable={true}
                      skip={dataState.skip}
                      take={dataState.take}
                      data={result}
                      onDataStateChange={(e) => dataStateChange(e)}
                    >{
                        !isDataLoaded && (
                          <GridNoRecords>
                            <div className='col-4'>
                              <br />
                              <Loader size='large' themeColor="info" type="converging-spinner" />
                            </div>
                          </GridNoRecords>
                        )}
                      <GridColumn
                        title="Action"
                        cell={handleEdit}
                        filterable={false}
                        width='80px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="testName"
                        title="Test Name"
                        filterable={true}
                        width='110px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="testCode"
                        title="Test Code"
                        filterable={true}
                        width='110px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="testtype.name"
                        title="Test Type"
                        filterable={true}
                        width='110px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="recruitmentpartner.name"
                        title="Recruitment Organization"
                        filterable={true}
                        width='150px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="customer.name"
                        title="Customer"
                        filterable={true}
                        width='110px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="skill.name"
                        title="Skill"
                        filterable={true}
                        width='110px'
                      />
                      <GridColumn
                        className={classes.fontRoboto}
                        field="isActive"
                        title="Is Active"
                        filterable={true}
                        filter="boolean"
                        width='110px'
                      />
                    </KendoGrid>
                  </div>
                  <NotificationContainer />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row padding_80 text-center ">
              <p className="fs-semi-bold fs-40 f-color authorize">
                You Are Not Authorized To Visit This Page
              </p>
            </div>
          </>
        )}
    </>
  );
};

export default AllTests;
