import axios from "axios";
const jsonConfig = require("../../Config.json");

const profilePictureUpdateResponse = async (payload) => {
  let allResponse = {};
  try {
    const response = await axios.put(
      jsonConfig.apiUrl + "profile/" + payload.userId,
      payload.profileimagedata,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allResponse = response;
    return Promise.resolve(allResponse)
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default profilePictureUpdateResponse;