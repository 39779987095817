import axios from "axios";
const jsonConfig = require("../../Config.json");

const pendingInvitesByOrgId = async (oId) => {
  let allResponse = {};
  try {
    const getallresponse = await axios.get(
      jsonConfig.apiUrl + "pendingInvitesByOrganizationId/" + oId,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = getallresponse;
    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("Error Invite Candidate", error);
    return Promise.reject(error);
  }
}

export default pendingInvitesByOrgId;