import axios from "axios";
const jsonConfig = require("../../Config.json");

const createProfile = async (profileCreatedPayload) => {
    let allResponse = {};
    try {
        const getallresponse =await axios.post(
            jsonConfig.apiUrl + "profile",
            profileCreatedPayload,
            {
              headers: {
                "x-functions-key": jsonConfig.key,
              },
            }
          );
        
        allResponse = getallresponse;
        return Promise.resolve(allResponse);
    }
    catch (error) {
        console.log("errrrrr",error); 
        return Promise.reject(error);
    }
}

export default createProfile;