import React, { useEffect, useState } from 'react'
import { process } from "@progress/kendo-data-query";
import { Grid as GridDiv, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { makeStyles } from "@material-ui/core/styles";
import "./clienttable.css";
import { Skeleton } from '@material-ui/lab';

const ManageClientTable = (props) => {
  const useStyles = makeStyles((theme) => ({
    odd: {
      background: '#F6F6F6 !important'
    },
    even: {
      background: '#fffff !important'
    },
  }))

  const { clientData, isDataLoaded, onEdit, userType } = props;

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
          value: true,
        },
      ],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [result, setResult] = useState();
  const classes = useStyles();


  const dataStateChange = (event) => {
    setResult(process(clientData, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setResult(process(clientData, dataState));
  }, [clientData])

  const handleEditClick = (rowData) => {
    onEdit(rowData);
  };

  const getSkeleton_kendo = () => {
    let arr = []
    for (let i = 0; i < 21; i++) {
      arr.push(<Skeleton className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="40px" />)
    }
    return arr;
  }
  return (
    <>
      <div className='tableGrid kendoGrid_manageDivision'>
        <GridDiv
          style={{
            maxHeight: "75Vh",
            width: "100%",
            overflow: "hidden"
          }}
          sort={dataState.sort}
          sortable={true}
          filter={dataState.filter}
          filterable={true}
          pageable={{
            buttonCount: 10,
            info: true,
            previousNext: true,
            pageSizes: true,
          }}
          resizable={false}
          skip={dataState.skip}
          take={dataState.take}
          data={result}
          reorderable={true}
          onDataStateChange={(e) => dataStateChange(e)}
        >
          <GridNoRecords>
            {!isDataLoaded ? getSkeleton_kendo() : "No results found !"}
          </GridNoRecords>

          <Column
            field="clientName"
            title="Client Name"
            width="180px"
            filterable={true}
          />
          <Column
            field="selectedDivisionNames"
            title="Division Name"
            width="180px"
            filterable={true}
          />
          <Column
            field="recruiterLeaderEmails"
            title="Recruitment Leader"
            width="330px"
            filterable={true}
          />
          <Column
            field="address"
            title="Address"
            width="180px"
            filterable={true}
          />
          <Column
            field="isActive"
            title="Is Active"
            width="150px"
            filter={"boolean"}
            cell={(props) => {
              const active = props.dataItem.isActive ? "True" : "False";
              return (
                <td>
                  {active}
                </td>
              );
            }}
            filterable={true}
          />
          <Column
            title="Action"
            filterable={false}
            width="100px"
            cell={(props) => {
              return (

                <td className='btn-text'>
                  <button
                    className='editBtn'
                    disabled={userType === 'Admin' ? true : false}
                    onClick={() => handleEditClick(props.dataItem)}
                  >
                    Edit
                  </button>

                </td>
              );
            }}
          />
        </GridDiv>
      </div>
    </>
  )
}

export default ManageClientTable;