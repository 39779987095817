import axios from "axios";
const jsonConfig = require("../Config.json");

export default async () => {
  return axios.get(jsonConfig.apiUrl + "domains", {
    headers: {
      "x-functions-key": jsonConfig.key,
    },
  });
};
