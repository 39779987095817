import React, { useState, useEffect, useContext } from "react";
import Tooltip from '@material-ui/core/Tooltip';
import { Collapse, Table, TableBody, TableCell, TableHead, TableRow, Grid, TableContainer, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Button, makeStyles } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { Skeleton } from "@material-ui/lab";
import boardwithlist from "../../pages/myTalentCommunity/icons/boardwithlist.png";
import { useDebounce } from "./hook.js";
import { useHistory } from "react-router";

import "react-datepicker/dist/react-datepicker.css";
import './requisitionCountTable.css';
import getAllRequisitionStatus from "../../apis/getallRequisitionStatus/getAllRequisitionStatus.js";
import Slide from '@material-ui/core/Slide';
import getAllRecruiterLeaderRequisitionStatusAndCounts from "../../apis/requisitionDetails/getAllRecruiterLeaderRequisitionStatusAndCounts.js";
import getAllRecruiterRequisitionStatusAndCounts from "../../apis/requisitionDetails/getAllRecruiterRequisitionStatusAndCounts.js";
import getRecruiterLeaderRequisitionsDetails from "../../apis/requisitionDetails/getRecruiterLeaderRequisitionsDetails.js";
import getAllRecruiterRequisitions from "../../apis/requisitionDetails/getAllRecruiterRequisitions.js";
import getMisOperatorByOrgId from "../../apis/getMisOperatorByOrgId/getMisOperatorByOrgId.js";
import getAllRequisitionStatusAndCounts from "../../apis/requisitionDetails/getAllRequisitionStatusAndCounts.js";
import getRequisitionsDetails from "../../apis/requisitionDetails/getRequisitionsDetails.js";
import getAllTeamLeaderRequisitionStatusAndCounts from "../../apis/requisitionDetails/getAllTeamLeaderRequisitionStatusAndCounts";
import getTeamLeaderRequisitionsDetails from "../../apis/requisitionDetails/getTeamLeaderRequisitionsDetails";
import { UserTypeContext } from "../../AppRoute";
import { closestTagName } from "@progress/kendo-react-data-tools";
import { filterFunc } from "../GlobalAssistingFunctions/GlobalAssistingFunctions";



const jsonConfig = require("../../Config.json");
const useStyles = makeStyles({
    fontRoboto: {
        fontFamily: "Roboto,sans-serif",
    },
    button: {
        marginBottom: "10px",
    },
    btnSave: {
        width: "120px",
        height: "32px",
        backgroundColor: "#1885E6",
        fontSize: '15px',
        color: "#FFFFFF",
        border: "1px solid #1885E6",
        paddingTop: '5px',
        borderRadius: "3px",
        "&:hover": {
            backgroundColor: "#1885E6",
            opacity: "0.8"
        }
    },
    openHeaderItems: {
        padding: "10px 15px",
        fontFamily: 'Segoe-Semibold',
        fontSize: '14px',
    },
    openDataItems: {
        padding: "10px 15px",
        fontFamily: 'Segoe-Regular',
        fontSize: '14px',
        paddingLeft: "22px"

    },
    headerRowOpen: {
        borderBottom: "1px solid #E8E8E8",
    },
    itemRowOpen: {
        borderBottom: "1px solid #E8E8E8",
        cursor: "pointer",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#f0f0f0",
        }
    },
    headerRowCovered: {
        borderBottom: "1px solid #E8E8E8",
        backgroundColor: "#d4efff"
    },
    itemRowCovered: {
        borderBottom: "1px solid #E8E8E8",
        backgroundColor: "aliceblue",
        cursor: "pointer",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#e3e6e8",
        }
    },
    headerRowUncovered: {
        background: 'linear-gradient(90deg, rgba(242, 235, 189, 1) 0%, rgba(254, 191, 189, 1) 100%)',
        borderBottom: '1px solid rgba(128, 128, 128, 0.226)'
    },
    itemRowUncovered: {
        background: 'linear-gradient(90deg, rgba(242, 235, 189, 0.7) 0%, rgba(254, 191, 189, 0.7) 100%)',
        borderBottom: '1px solid rgba(128, 128, 128, 0.226)',
        cursor: "pointer",
        "&:hover": {
            opacity: "0.8"
        }
    },
    OpenContainer: {
        maxHeight: "190px",
    }
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RequisitionCountTable = (props) => {
    const {
        id,
        userRecruitmentpartnerId,
        userRole,
        userType,
        userRecords
    } = props;

    // const [idContextTest, setIdContextTest] = useState(id);

    const [open, setOpen] = useState(false);
    const [covered, setcovered] = useState(false);
    const [uncovered, setuncovered] = useState(false);
    const [unassigned, setunassigned] = useState(false);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [isRequisitionDialogOpen, setIsRequisitionDialogOpen] = useState(false);
    const [allRequisitionStatusData, setAllRequisitionStatusData] = useState([]);
    const [openRequisitionsDataResponse, setOpenRequisitionsDataResponse] = useState([]);
    // const { userRecords } = useContext(UserTypeContext);
    // const id = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
    // const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
    // const userRole = userType === 'Admin' ? props?.location?.state?.userType : userRecords?.role?.roleName;
    const userRecrodData = userType === 'Admin' ? props?.location?.state?.userRecords : userRecords;
    const [requisitionCount, setRequisitionCount] = useState({
        OPEN: 0,
        COVERED: 0,
        UNCOVERED: 0,
        UNASSIGNED: 0
    });

    const [tableDataLoadingOpen, setTableDataLoadingOpen] = useState(false);
    const [tableDataLoadingCovered, setTableDataLoadingCovered] = useState(false);
    const [tableDataLoadingUncovered, setTableDataLoadingUncovered] = useState(false);
    const [tableDataLoadingUnassigned, setTableDataLoadingUnassigned] = useState(false);
    const [misOperatorData, setMisOperatorData] = useState({})

    const debouncedSearchStartDate = useDebounce(startDate, 300);
    const debouncedSearchEndtDate = useDebounce(endDate, 300);

    const [requisitionObj, setRequisitionObj] = useState({
        "OPEN": { offset: 0, limit: 5, data: [] },
        "COVERED": { offset: 0, limit: 5, data: [] },
        "UNCOVERED": { offset: 0, limit: 5, data: [] },
        "UNASSIGNED": { offset: 0, limit: 5, data: [] }
    });

    const history = useHistory();

    const classes = useStyles();

    function convertDate(str) {
        if (str === "") {
            return "";
        }
        else {
            let date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
        }
    }

    const startDateChange = (e) => {
        setStartDate(e)
        if (endDate === "") {
            setEndDate(e)
        }
    }

    const EndDateChange = (e) => {
        setEndDate(e)
        if (startDate === "") {
            setStartDate(e)
        }
    }

    let payload = {
        "fromdate": convertDate(startDate),
        "todate": convertDate(endDate),
        "isDashBoardCount": true,
        "isOrgAdmin": false,
        recruitmentpartnerId: userRecruitmentpartnerId
    }

    const setCoveredChange = () => {
        setOpen(false)
        setcovered(true)
        setuncovered(false)
        setunassigned(false)
    }
    const setUnCoveredChange = () => {
        setOpen(false)
        setcovered(false)
        setuncovered(true)
        setunassigned(false)
    }
    const setOpenChange = () => {
        setOpen(true)
        setcovered(false)
        setuncovered(false)
        setunassigned(false)
    }
    const setUnassignedChange = () => {
        setOpen(false)
        setcovered(false)
        setuncovered(false)
        setunassigned(true)
    }

    const setRequisitionCountData = (data) => {

        let coveredDataCount = data.filter((item) => item.name === 'COVERED')[0]?.totalQuantity || 0;
        let uncoveredDataCount = data.filter((item) => item.name === 'UNCOVERED')[0]?.totalQuantity || 0;
        let unassignedDataCount = data.filter((item) => item.name === 'UNASSIGNED')[0]?.totalQuantity || 0;

        setRequisitionCount({
            OPEN: coveredDataCount + unassignedDataCount + uncoveredDataCount,
            COVERED: coveredDataCount,
            UNCOVERED: uncoveredDataCount,
            UNASSIGNED: unassignedDataCount
        })
    }

    const getAllrequistionStatusResponse = async () => {
        try {
            let response = await getAllRequisitionStatus();
            setAllRequisitionStatusData(response.data.data);
            let openRequisitionsData = response.data.data.filter((item) => item.name === 'OPEN')[0]
            setOpenRequisitionsDataResponse(openRequisitionsData);
        } catch (error) {
            console.log("Error", error);
        }
    }
    const getRequisitionData = async (requisitionStatus, isScroll) => {
        try {

            let statusCode = openRequisitionsDataResponse?._id;
            let subStatusCode = "";
            let selectedFilter = { "covered": "", "priority": "", "latestDate": true, uncovered: "", unassigned: "", "fromdate": convertDate(startDate), "todate": convertDate(endDate) }

            if (!isScroll) setIsRequisitionDialogOpen(true)

            if (requisitionStatus === "OPEN") {
                if (!isScroll) setOpenChange();
                setTableDataLoadingOpen(true);
            }
            else if (requisitionStatus === "COVERED") {
                subStatusCode = openRequisitionsDataResponse?.subList.filter((item) => item.subName === "COVERED")[0]?._id;
                selectedFilter.covered = subStatusCode;
                if (!isScroll) setCoveredChange();
                setTableDataLoadingCovered(true);
            }
            else if (requisitionStatus === "UNCOVERED") {
                subStatusCode = openRequisitionsDataResponse?.subList.filter((item) => item.subName === "UNCOVERED")[0]?._id;
                selectedFilter.uncovered = subStatusCode;
                if (!isScroll) setUnCoveredChange();
                setTableDataLoadingUncovered(true);
            }
            else if (requisitionStatus === "UNASSIGNED") {
                subStatusCode = openRequisitionsDataResponse?.subList.filter((item) => item.subName === "UNASSIGNED")[0]?._id;
                selectedFilter.unassigned = subStatusCode;
                if (!isScroll) setUnassignedChange();
                setTableDataLoadingUnassigned(true);
            }


            let targetStatus = requisitionObj[requisitionStatus];
            let offset = isScroll ? targetStatus.offset : 0;
            let limit = isScroll ? targetStatus.limit : 5;
            let response;
            if (userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) {
                response = await getAllRecruiterRequisitions(offset, limit, statusCode, selectedFilter, id, "", userRecruitmentpartnerId)
            }
            else if (userType === "Recruitment Leader" || (userType === "Admin" && userRole === "Recruitment Leader")) {
                response = await getRecruiterLeaderRequisitionsDetails(offset, limit, statusCode, selectedFilter, id, "", userRecruitmentpartnerId)
            }
            else if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
                let isOrgAdmin = true
                response = await getRequisitionsDetails(offset, limit, statusCode, selectedFilter, userRecruitmentpartnerId, isOrgAdmin,"",userRecords.id)
            }
            else if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
                let isOrgAdmin = true
                response = await getRequisitionsDetails(offset, limit, statusCode, selectedFilter, userRecruitmentpartnerId, isOrgAdmin,"",userRecords?.id)
            }
            else if (userType === "Team Leader" || (userType === "Admin" && userRole === "Team Leader")) {
                response = await getTeamLeaderRequisitionsDetails(offset, limit, statusCode, selectedFilter, id, "", userRecruitmentpartnerId)
            }


            let obj = requisitionObj;

            let prevData = isScroll ? obj[requisitionStatus].data : [];

            response?.data?.data.requisitions.map((item) => {
                let resultObj = {}

                resultObj.requisitionId = item.requisitionId;
                resultObj.teamLeaderData = [];
                resultObj.reqreferenceId = item?.requisitionDetails?.reqreferenceId || item?.reqreferenceId;
                resultObj.primarySkill = item?.requisitionDetails?.primarySkill || item.primarySkill;
                if (item?.teamLeaderRequisitions?.length > 0) {
                    const data = item?.teamLeaderRequisitions
                    for (let element of data) {
                        let statusObj = jsonConfig?.requisitionStatusCodes?.OPENSUBSTATUS
                        let status = filterFunc(Object.keys(statusObj), (val) => statusObj[val] === element?.status?.subCode, element)?.at(0)
                        resultObj.teamLeaderData.push({ teamLeaderName: element?.teamLeaderData?.displayName, status: status.toLowerCase() } || null)
                    }
                }
                else {
                    resultObj.isteamleader = false;
                }
                // resultObj.clientName = (userType === "Organization Admin" || userType === "Admin" && userRole === "Organization Admin") ? item.clientDetails?.clientName : item.requisitionDetails?.clientDetails?.clientName;
                if (
                    (userType === "Organization Admin" && userRole === "Organization Admin") ||
                    (userType === "Admin" && userRole === "Organization Admin") ||
                    (userType === "MIS Operator" && userRole === "MIS Operator") ||
                    (userType === "Admin" && userRole === "MIS Operator")
                ) {
                    resultObj.clientName = item.clientDetails?.clientName;
                } else {
                    resultObj.clientName = item.requisitionDetails?.clientDetails?.clientName;
                }
                resultObj.openings = (((userType !== "Recruiter" && userType !== "Admin") || (userType === "Admin" && userRole !== "Recruiter")) && (userType !== "Team Leader" || (userType === "Admin" && userRole !== "Team Leader"))) ? item.openings : item.no_of_positions;
                resultObj.recruiterName = item?.recruiterData ? item.recruiterData.map((item) => item?.displayName).join(", ") : "";
                resultObj.numberOfCandidateRequisitions = item.candidateRequisitions ? item.candidateRequisitions.length : "";
                // Add divisionDetails if userType is "Organization Admin" or "MIS Operator"
                if (
                    (userType === "Organization Admin" && userRole === "Organization Admin") ||
                    (userType === "MIS Operator" && userRole === "MIS Operator") ||
                    (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                ) {
                    resultObj.divisionDetails = item.divisionDetails;
                }
                prevData.push(resultObj)
            })
            obj[requisitionStatus] = { offset: offset + limit, limit: 5, data: prevData }
            if (requisitionStatus === "OPEN") {
                setTableDataLoadingOpen(false);
            }
            else if (requisitionStatus === "COVERED") {
                setTableDataLoadingCovered(false);
            }
            else if (requisitionStatus === "UNCOVERED") {
                setTableDataLoadingUncovered(false);
            }
            else if (requisitionStatus === "UNASSIGNED") {
                setTableDataLoadingUnassigned(false);
            }
            setRequisitionObj(obj)


        } catch (error) {
            console.log("Error", error);
        }

    }
    const getTableSkeleton = (status) => {
        let skeletonArray = [1, 2, 3, 4, 5]
        return (
            skeletonArray.map((item) => {
                if (status !== "UNASSIGNED") {
                    return (
                        <Grid item xs={12} container className={status === "OPEN" ? classes.itemRowOpen : status === "COVERED" ? classes.itemRowCovered : classes.itemRowUncovered}>
                            <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                            <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                            <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                            <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                            <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                            <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                        </Grid>

                    )
                }
                else {
                    return (
                        userType === "Organization Admin" || userType === "MIS Operator" ||
                            (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                            ?
                            <Grid item xs={12} container className={classes.itemRowUncovered}>
                                <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={4} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>

                            </Grid>
                            :
                            <Grid item xs={12} container className={classes.itemRowUncovered}>
                                <Grid item xs={2} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={3} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={3} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>
                                <Grid item xs={3} className={classes.openDataItems}><Skeleton animation="wave" /></Grid>

                            </Grid>
                    )
                }
            }))
    }

    const closeHandler = () => {
        setIsRequisitionDialogOpen(false);
        setOpen(false);
        setcovered(false);
        setuncovered(false);
        setunassigned(false);
        setRequisitionObj({
            "OPEN": { offset: 0, limit: 5, data: [] },
            "COVERED": { offset: 0, limit: 5, data: [] },
            "UNCOVERED": { offset: 0, limit: 5, data: [] },
            "UNASSIGNED": { offset: 0, limit: 5, data: [] }
        });
    }

    const goToRequisition = (row) => {
        history.push({
            pathname: `/requisition-details/${row.requisitionId}`,
            state: { id: id, userRecruitmentpartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecords, userRole: userRole },
        });
    }

    useEffect(() => {
        getAllrequistionStatusResponse();
    }, [])

    useEffect(() => {
        const loadUsers = async () => {
            try {
                setLoading(true);
                let misData = misOperatorData;
                if (userType === "Organization Admin" || (userType === "Admin" && userRole === "Organization Admin")) {
                    payload.isOrgAdmin = true;
                    const requisitionResponse = await getAllRequisitionStatusAndCounts(userRecruitmentpartnerId, payload)
                    setRequisitionCountData(requisitionResponse.data.data);
                    setLoading(false);
                }


                else if (userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) {
                    const requisitionResponse = await getAllRecruiterRequisitionStatusAndCounts(id, payload);
                    setRequisitionCountData(requisitionResponse.data.data);
                    setLoading(false);
                }
                else if (userType === "Recruitment Leader" || (userType === "Admin" && userRole === "Recruitment Leader")) {
                    const requisitionResponse = await getAllRecruiterLeaderRequisitionStatusAndCounts(id, payload);
                    setRequisitionCountData(requisitionResponse.data.data);
                    setLoading(false);
                }
                else if (userType === "MIS Operator" || (userType === "Admin" && userRole === "MIS Operator")) {
                    payload.isMISOperator = true;
                    const requisitionResponse = await getAllRequisitionStatusAndCounts(userRecruitmentpartnerId, payload);
                    setRequisitionCountData(requisitionResponse.data.data);
                    setLoading(false);
                } else if (userType === "Team Leader" || (userType === "Admin" && userRole === "Team Leader")) {
                    const requisitionResponse = await getAllTeamLeaderRequisitionStatusAndCounts(id, payload);
                    setRequisitionCountData(requisitionResponse.data.data);
                    setLoading(false);
                }
                if (isRequisitionDialogOpen) {

                    setRequisitionObj({
                        "OPEN": { offset: 0, limit: 5, data: [] },
                        "COVERED": { offset: 0, limit: 5, data: [] },
                        "UNCOVERED": { offset: 0, limit: 5, data: [] },
                        "UNASSIGNED": { offset: 0, limit: 5, data: [] }
                    })

                    if (open) {
                        getRequisitionData("OPEN", false)
                    }
                    else if (covered) {
                        getRequisitionData("COVERED", false)
                    }
                    else if (uncovered) {
                        getRequisitionData("UNCOVERED", false)
                    }
                    else if (unassigned) {
                        getRequisitionData("UNASSIGNED", false)
                    }
                }
            } catch (error) {
                console.log("Error", error);
            }

        };
        if (userRecruitmentpartnerId)
            loadUsers();
    }, [debouncedSearchStartDate, debouncedSearchEndtDate]);

    return (
        <>
            <div className="box-shadow">
                <div className="table-header-recruitment-leader">
                    <div className="recruitment-leader-left">
                        <img src={boardwithlist} />
                        <p>Requisition</p>
                    </div>
                    <div className="datePickerContainer_recruitment-leader-left">
                        <DatePicker
                            selected={startDate}
                            onChange={startDateChange}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText={'Select Date'}
                            showIcon={true}
                        />
                        <p className="date-inner-text">to</p>
                        <DatePicker
                            selected={endDate}
                            onChange={EndDateChange}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText={'Select Date'}
                            minDate={startDate ? startDate : new Date()}
                            showIcon={true}
                        />
                    </div>
                </div>

                <div className="requsitionStatusDataContainer">
                    <div className="requsitionStatusInfoContainer_header" onClick={() => loading ? null : getRequisitionData("OPEN", false)}>
                        <div className="requsitionStatusInfo_title">Open</div>
                        <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.OPEN}</span>
                    </div>
                    <div className="requsitionStatusInfoContainer_header_covered" onClick={() => loading ? null : getRequisitionData("COVERED", false)}>
                        <div className="requsitionStatusInfo_title">Covered</div>
                        <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.COVERED}</span>
                    </div>

                    <div className="requsitionStatusInfoContainer_header_uncovered" onClick={() => loading ? null : getRequisitionData("UNCOVERED", false)}>
                        <div className="requsitionStatusInfo_title">Uncovered</div>
                        <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.UNCOVERED}</span>
                    </div>
                    {(userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) ? "" : <>
                        <div className="requsitionStatusInfoContainer_header_uncovered" onClick={() => loading ? null : getRequisitionData("UNASSIGNED", false)}>
                            <div className="requsitionStatusInfo_title">Unassigned</div>
                            <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.UNASSIGNED}</span>
                        </div>

                    </>}
                </div>
            </div>
            <Dialog
                open={isRequisitionDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={(e) => closeHandler()}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className="requsitionDialog"
                maxWidth="md"
                fullWidth
                PaperProps={{
                    style: {
                        borderRadius: "8px 8px 0px 0px"
                    },
                }}
            >
                <div className="table-header-recruitment-leader">
                    <div className="recruitment-leader-left">
                        <img src={boardwithlist} />
                        <p>Requisition</p>
                    </div>
                    <div className="datePickerContainer_recruitment-leader-left">
                        <DatePicker
                            selected={startDate}
                            onChange={startDateChange}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText={'Select Date'}
                            showIcon={true}
                        />
                        <p className="date-inner-text">to</p>
                        <DatePicker
                            selected={endDate}
                            onChange={EndDateChange}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText={'Select Date'}
                            minDate={startDate ? startDate : new Date()}
                            showIcon={true}
                        />
                    </div>
                </div>

                <div className="requsitionStatusInfoContainer_header_dialog" onClick={(e) => loading ? null : open ? setOpen(false) : getRequisitionData("OPEN", false)}>
                    <div className="requsitionStatusInfo_title">Open</div>
                    <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.OPEN}</span>
                </div>
                <div className="gridCollapseContainer">
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <Grid container >
                            <Grid item xs={12} container className={classes.headerRowOpen}>
                                <Grid item xs={2} className={classes.openHeaderItems}>Reference ID</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Client Name</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>
                                    {(userType === "Organization Admin" || userType === "MIS Operator" ||
                                        (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator")))
                                        ? 'Division Name'
                                        : 'Recruiter Name'}
                                </Grid>

                                <Grid item xs={2} className={classes.openHeaderItems}>Primary Skill</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Openings</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Selected</Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={classes.OpenContainer}
                            onScroll={(e) => {
                                const bottomThreshold = 50;
                                const { scrollTop, clientHeight, scrollHeight } = e.target;

                                if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
                                    if (requisitionCount.OPEN > requisitionObj["OPEN"]?.data.length && !tableDataLoadingOpen)
                                        getRequisitionData("OPEN", true);
                                }
                            }}
                        >
                            {requisitionObj["OPEN"]?.data.map((row) => {
                                return (
                                    <Grid item xs={12} container className={classes.itemRowOpen} onClick={(e) => goToRequisition(row)}>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.reqreferenceId}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.clientName}</Grid>
                                        {
                                            userType === "Organization Admin" || userType === "MIS Operator" ||
                                                (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                                ?
                                                <Grid item xs={2} className={classes.openDataItems}>{row?.divisionDetails?.divisionName}</Grid>
                                                : row.recruiterName.length > 55 ?
                                                    <Tooltip title={< React.Fragment >
                                                        <h3 >{`${row.recruiterName || ""}`}</h3>
                                                    </React.Fragment>}>
                                                        <Grid item xs={2} className={classes.openDataItems}>{row.recruiterName.slice(0, 55) + "..."}</Grid>
                                                    </Tooltip> :
                                                    <Grid item xs={2} className={classes.openDataItems}>{row.recruiterName}</Grid>

                                        }
                                        <Grid item xs={2} className={classes.openDataItems}>{row.primarySkill}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.openings}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.numberOfCandidateRequisitions}</Grid>
                                    </Grid>
                                );
                            })}
                            {tableDataLoadingOpen ? getTableSkeleton("OPEN") : ""}
                        </Grid>
                    </Collapse>
                </div>

                <div className="requsitionStatusInfoContainer_header_covered_dialog" onClick={(e) => loading ? null : covered ? setcovered(false) : getRequisitionData("COVERED", false)}>
                    <div className="requsitionStatusInfo_title">Covered</div>
                    <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.COVERED}</span>
                </div>
                <div className="gridCollapseContainer">
                    <Collapse in={covered} timeout="auto" unmountOnExit>
                        <Grid container>
                            <Grid item xs={12} container className={classes.headerRowCovered}>
                                <Grid item xs={2} className={classes.openHeaderItems}>Reference ID</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Client Name</Grid>
                                {userType === "Organization Admin" || userType === "MIS Operator" ||
                                    (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                    ?
                                    <Grid item xs={2} className={classes.openHeaderItems}>Division Name</Grid>
                                    :
                                    <Grid item xs={2} className={classes.openHeaderItems}>Recruiter Name</Grid>
                                }
                                <Grid item xs={2} className={classes.openHeaderItems}>Primary Skill</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Openings</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Selected</Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={classes.OpenContainer}
                            onScroll={(e) => {
                                const bottomThreshold = 50;
                                const { scrollTop, clientHeight, scrollHeight } = e.target;

                                if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
                                    if (requisitionCount.COVERED > requisitionObj["COVERED"]?.data.length && !tableDataLoadingCovered)
                                        getRequisitionData("COVERED", true);
                                }
                            }}
                        >
                            {requisitionObj["COVERED"]?.data.map((row) => {
                                return (
                                    <Grid item xs={12} container className={classes.itemRowCovered} onClick={(e) => goToRequisition(row)}>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.reqreferenceId}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.clientName}</Grid>
                                        {userType === "Organization Admin" || userType === "MIS Operator" ||
                                            (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                            ?
                                            <Grid item xs={2} className={classes.openDataItems}>{row?.divisionDetails?.divisionName}</Grid>
                                            : row.recruiterName.length > 55 ?
                                                <Tooltip title={< React.Fragment >
                                                    <h3 >{`${row.recruiterName || ""}`}</h3>
                                                </React.Fragment>}>
                                                    <Grid item xs={2} className={classes.openDataItems}>{row.recruiterName.slice(0, 55) + "..."}</Grid>
                                                </Tooltip> :
                                                <Grid item xs={2} className={classes.openDataItems}>{row.recruiterName}</Grid>

                                        }
                                        <Grid item xs={2} className={classes.openDataItems}>{row.primarySkill}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.openings}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.numberOfCandidateRequisitions}</Grid>
                                    </Grid>
                                );
                            })}
                            {tableDataLoadingCovered ? getTableSkeleton("COVERED") : ""}
                        </Grid>
                    </Collapse>
                </div>

                <div className="requsitionStatusInfoContainer_header_uncovered_dialog" onClick={() => loading ? null : uncovered ? setuncovered(false) : getRequisitionData("UNCOVERED", false)}>
                    <div className="requsitionStatusInfo_title">Uncovered</div>
                    <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.UNCOVERED}</span>
                </div>
                <div className="gridCollapseContainer">
                    <Collapse in={uncovered} timeout="auto" unmountOnExit>
                        <Grid container>
                            <Grid item xs={12} container className={classes.headerRowUncovered}>
                                <Grid item xs={2} className={classes.openHeaderItems}>Reference ID</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Client Name</Grid>
                                {userType === "Organization Admin" || userType === "MIS Operator" ||
                                    (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                    ?
                                    <Grid item xs={2} className={classes.openHeaderItems}>Division Name</Grid>
                                    :
                                    <Grid item xs={2} className={classes.openHeaderItems}>Recruiter Name</Grid>
                                }
                                <Grid item xs={2} className={classes.openHeaderItems}>Primary Skill</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Openings</Grid>
                                <Grid item xs={2} className={classes.openHeaderItems}>Selected</Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={classes.OpenContainer}
                            onScroll={(e) => {
                                const bottomThreshold = 50;
                                const { scrollTop, clientHeight, scrollHeight } = e.target;

                                if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
                                    if (requisitionCount.UNCOVERED > requisitionObj["UNCOVERED"]?.data.length && !tableDataLoadingUncovered)
                                        getRequisitionData("UNCOVERED", true);
                                }
                            }}
                        >
                            {requisitionObj["UNCOVERED"]?.data.map((row) => {
                                return (
                                    <Grid item xs={12} container className={classes.itemRowUncovered} onClick={(e) => goToRequisition(row)}>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.reqreferenceId}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.clientName}</Grid>
                                        {userType === "Organization Admin" || userType === "MIS Operator" ||
                                            (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                            ?
                                            <Grid item xs={2} className={classes.openDataItems}>{row?.divisionDetails?.divisionName}</Grid>
                                            : row.recruiterName.length > 55 ?
                                                <Tooltip title={< React.Fragment >
                                                    <h3 >{`${row.recruiterName || ""}`}</h3>
                                                </React.Fragment>}>
                                                    <Grid item xs={2} className={classes.openDataItems}>{row.recruiterName.slice(0, 55) + "..."}</Grid>
                                                </Tooltip> :
                                                <Grid item xs={2} className={classes.openDataItems}>{row.recruiterName}</Grid>

                                        }
                                        <Grid item xs={2} className={classes.openDataItems}>{row.primarySkill}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.openings}</Grid>
                                        <Grid item xs={2} className={classes.openDataItems}>{row.numberOfCandidateRequisitions}</Grid>
                                    </Grid>
                                );
                            })}
                            {tableDataLoadingUncovered ? getTableSkeleton("UNCOVERED") : ""}
                        </Grid>
                    </Collapse>
                </div>

                {(userType === "Recruiter" || (userType === "Admin" && userRole === "Recruiter")) ? "" : <>
                    <div className="requsitionStatusInfoContainer_header_uncovered_dialog" onClick={() => loading ? null : unassigned ? setunassigned(false) : getRequisitionData("UNASSIGNED", false)}>
                        <div className="requsitionStatusInfo_title">Unassigned</div>
                        <span> {loading ? <CircularProgress className="circularProgress_requisitionCount" /> : requisitionCount.UNASSIGNED}</span>
                    </div>
                    <div className="gridCollapseContainer">
                        <Collapse in={unassigned} timeout="auto" unmountOnExit>
                            <Grid container>

                                {userType === "Organization Admin" || userType === "MIS Operator" ||
                                    (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                    ?
                                    <Grid item xs={12} container className={classes.headerRowUncovered}>
                                        <Grid item xs={3} className={classes.openHeaderItems}>Reference ID</Grid>
                                        <Grid item xs={3} className={classes.openHeaderItems}>Client Name</Grid>
                                        <Grid item xs={2} className={classes.openHeaderItems}>Division Name</Grid>
                                        <Grid item xs={2} className={classes.openHeaderItems}>Primary Skill</Grid>
                                        <Grid item xs={2} className={classes.openHeaderItems}>Openings</Grid>
                                    </Grid>
                                    :
                                    <Grid item xs={12} container className={classes.headerRowUncovered}>
                                        <Grid item xs={3} className={classes.openHeaderItems}>Reference ID</Grid>
                                        <Grid item xs={3} className={classes.openHeaderItems}>Client Name</Grid>
                                        <Grid item xs={3} className={classes.openHeaderItems}>Primary Skill</Grid>
                                        <Grid item xs={3} className={classes.openHeaderItems}>Openings</Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid
                                container
                                className={classes.OpenContainer}
                                onScroll={(e) => {
                                    const bottomThreshold = 50;
                                    const { scrollTop, clientHeight, scrollHeight } = e.target;

                                    if (scrollHeight - scrollTop - clientHeight < bottomThreshold) {
                                        if (requisitionCount.UNASSIGNED > requisitionObj["UNASSIGNED"]?.data.length && !tableDataLoadingUnassigned)
                                            getRequisitionData("UNASSIGNED", true);
                                    }
                                }}
                            >
                                {requisitionObj["UNASSIGNED"]?.data.map((row) => {
                                    return (
                                        userType === "Organization Admin" || userType === "MIS Operator" ||
                                            (userType === "Admin" && (userRole === "Organization Admin" || userRole === "MIS Operator"))
                                            ?
                                            <Tooltip
                                                title={ userType != "Team Leader"?row?.isteamleader === false ? "Team Leader not Assign" : row?.teamLeaderData.map(name => "Assign to " + name.teamLeaderName + ", Status : " + name.status).join(", "):""}>
                                                <Grid item xs={12} container className={classes.itemRowUncovered} onClick={(e) => goToRequisition(row)}>
                                                    <Grid item xs={3} className={classes.openDataItems}>{row.reqreferenceId}</Grid>
                                                    <Grid item xs={3} className={classes.openDataItems}>{row.clientName}</Grid>
                                                    <Grid item xs={2} className={classes.openDataItems}>{row?.divisionDetails?.divisionName}</Grid>
                                                    <Grid item xs={2} className={classes.openDataItems}>{row.primarySkill}</Grid>
                                                    <Grid item xs={2} className={classes.openDataItems}>{row.openings}</Grid>
                                                </Grid>
                                            </Tooltip>
                                            :
                                            <Tooltip
                                                title={userType != "Team Leader"?row?.isteamleader === false ? "Team Leader not Assign" : row?.teamLeaderData.map(name => "Assign to " + name.teamLeaderName + ", Status : " + name.status).join(" ; "):""}>
                                                <Grid item xs={12} container className={classes.itemRowUncovered} onClick={(e) => goToRequisition(row)}>
                                                    <Grid item xs={3} className={classes.openDataItems}>{row.reqreferenceId}</Grid>
                                                    <Grid item xs={3} className={classes.openDataItems}>{row.clientName}</Grid>
                                                    <Grid item xs={3} className={classes.openDataItems}>{row.primarySkill}</Grid>
                                                    <Grid item xs={3} className={classes.openDataItems}>{row.openings}</Grid>
                                                </Grid>
                                            </Tooltip>
                                    );
                                })}
                                {tableDataLoadingUnassigned ? getTableSkeleton("UNASSIGNED") : ""}
                            </Grid>
                        </Collapse>
                    </div>
                </>}

                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.btnSave}
                        onClick={(e) => closeHandler()}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RequisitionCountTable;