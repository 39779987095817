import axios from 'axios';
const jsonConfig = require("../Config.json");


export default async (setPopularity) => {
    // setPopularity determines if the skills are ordered by popularity or alphabetized    
    return axios.put(jsonConfig.apiUrl + 'UpdateSkillPopularityHTTP/' + (setPopularity.toString() || ''), {},
        {
            headers: {
                "x-functions-key": jsonConfig.key
            }
        }
    );
}
