import axios from "axios";
const jsonConfig = require("../../Config.json");

const interviewDataUpdateResponse = async (payload) => {
  let allResponse = {};
  try {
    let response = await axios.put(jsonConfig.apiUrl + "profile/" + payload.userId, payload.data,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    allResponse = response;
    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
}

export default interviewDataUpdateResponse;