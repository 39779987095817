import React, { useEffect, useState, useRef } from 'react'
import './RecLead.css';
import InterviewTable from '../../components/recruitmentLeaderInterviewTable/recruitmentLeaderInterviewTable';
import UpcomingStarts from '../../components/UpcomingStarts/UpcomingStarts';
import LineChart from './Charts/LineChart';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RequisitionCountTable from '../../components/recruitmentLeader/RequisitionCountTable';
import recentInvitations from '../../apis/recentInvitationsAPI/recentInvitations';
import { CircularProgress } from '@material-ui/core';
import UploadResumeModal from '../../components/UploadResumeModal/UploadResumeModal';
import getTrackCandidateInfo from '../../apis/getTrackCandidateInfo/getTrackCandidateInfo';

const jsonConfig = require("../../Config.json");



function RecLeadFeed(props) {
    const { id, userType, userRecruitmentpartnerId, userRecords, userRole } = props;



    const [recruiterPartnerLogo, setrecruiterPartnerLogo] = React.useState("");
    const [recruiterPartnerLogoLoader, setrecruiterPartnerLogoLoader] = React.useState(false);
    const [RecentInvitationData, setRecentInvitationData] = React.useState([]);
    const [RecentInvitationDataLoader, setRecentInvitationDataLoader] = React.useState(false);

    // Dialog modal useStates
    const { showResumeModal, setShowResumeModal } = props



    const goToCandidateProfile = async (itemValue) => {
        const response = await getTrackCandidateInfo({
            candidateEmail: itemValue.candidateEmail,
            recruitmentPartnerId: userRecruitmentpartnerId
        });
        const userData = response?.data?.data?.userDetails[0]
        let url = '';
        if (userData?.powerResumeId) {
            url = 'candidate/profile/' + userData?.powerResumeId + '/' + userData.recruitmentOrgId
        }
        else {
            url = 'only-candidate-profile/' + userData?.candidateId
        }
        window.open(url, "_blank")
    }

    useEffect(() => {

        const getCommunityStaticsData = async () => {
            try {
                if (userRecords.recruitmentpartner) {
                    setrecruiterPartnerLogo(
                        userRecords.recruitmentpartner.recruitmentpartnerLogoUrl
                    );
                    setrecruiterPartnerLogoLoader(true)
                }
            } catch (error) {
                console.log("error", error);
            } finally {
                setrecruiterPartnerLogoLoader(false)
            }
        }

        const getRecentInvitations = async () => {
            try {
                let response = await recentInvitations({ id: id, userType: userType === 'Admin' ? userRole : userType,recruitmentpartnerId:userRecruitmentpartnerId });
                setRecentInvitationData(response?.data?.data)
                setRecentInvitationDataLoader(false)


            } catch (error) {
                console.log("error", error);
            }

        }
        (async () => {
            setRecentInvitationDataLoader(true)

            await getCommunityStaticsData();
            await getRecentInvitations();
        })()
        return () => {
            setrecruiterPartnerLogo("")
            setrecruiterPartnerLogoLoader(true)

        }
    }, [id, userRecords.recruitmentpartner])


    return (
        <div>
            {recruiterPartnerLogo || recruiterPartnerLogoLoader ? (
                <div className='topBar-Ats-Feed'>
                    <div className='ats-feed-orgLogoArea'>
                        {
                            recruiterPartnerLogoLoader ? (
                                <div className='ats-feed-orgLogoLoader'>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className='recruiterLogoContainerMain'>
                                    <img
                                        className='recruiterLogoContainer'
                                        src={recruiterPartnerLogo}
                                        alt="Recruiter Partner Logo"
                                    />
                                </div>
                            )
                        }
                    </div>
                </div>
            ) : ""}



            <div className='ats-dashFeed-MainGrid'>
                <div className='ats-dashFeedWrapper'>

                    <div >
                        <RequisitionCountTable
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />

                    </div>
                    <div >
                        <InterviewTable
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />
                    </div>
                    <div className='wasi-lineChart'>
                        <div className='ats-dashFeed-RightBar-Title'>
                            <div>Recent Activities</div>
                            <sub className='subtile_recLeadeFeed'>(Last 30 Days)</sub>

                        </div>
                        <LineChart
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />

                    </div>
                    <div >
                        <UpcomingStarts
                            id={id}
                            userType={userType}
                            userRole={userRole}
                            userRecruitmentpartnerId={userRecruitmentpartnerId}
                            userRecords={userRecords}
                        />
                    </div>

                </div>
                <div className='ats-dashFeed-RightBar'>
                    <div className='ats-dashFeed-RightBar-Title'>
                        <div>Recent Test Taken</div>



                    </div>


                    <div className='ats-dash-rightBar-infoContainer'>

                        {
                            RecentInvitationDataLoader ? (
                                <div className='ats-dash-rightBar-loader' >
                                    <CircularProgress />
                                </div>

                            ) : (

                                RecentInvitationData.length > 0 ? (
                                    RecentInvitationData.map((item, index) => {
                                        return (
                                            <div key={index} className='ats-dashFeed-RightBar-InfoCard' onClick={(e) => goToCandidateProfile(item)}>
                                                <VerifiedUserIcon style={{ color: "#31B3F8" }} />

                                                <div>
                                                    <div style={{ fontWeight: "600" }}>{item?.candidateName} completed the {item?.testName}</div>
                                                    <div style={{ fontWeight: "300" }}>Invited By: {item?.InvitedBy}</div>
                                                </div>

                                            </div>
                                        )
                                    }
                                    ))
                                    :
                                    <div className='ats-dashFeed-RightBar-InfoCard' >
                                        <div style={{ fontWeight: "600" }}>No Invitations Found</div>
                                    </div>
                            )
                        }

                    </div>


                </div>
            </div>

            {/* Resume modal */}
            <UploadResumeModal
                showResumeModal={showResumeModal}
                setShowResumeModal={setShowResumeModal}
                id={id}
                userRecruitmentpartnerId={userRecruitmentpartnerId}
            />
        </div>
    )
}

export default RecLeadFeed