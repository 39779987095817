import axios from "axios";
const jsonConfig = require("../../Config.json");

const phoneNumberExistResponse = async (phoneNumberInput) => {
  let allResponse = {};
  try {
    const response = await axios.post(
      jsonConfig.apiUrl + "getuserdataByphonenumber", phoneNumberInput,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = response;
    return Promise.resolve(allResponse);
  }
  catch (error) {
    console.log("Error user id", error);
    return Promise.reject(error);
  }
}

export default phoneNumberExistResponse;