import axios from "axios";
const jsonConfig = require("../../Config.json");

const updateProfile = async (payload) => {
    let responsedata = {}
    try {
        const profileDataResponse = await axios.put(
            jsonConfig.apiUrl + "profile/" + payload.id,
            payload.data,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
            responsedata = profileDataResponse;

        return Promise.resolve(responsedata)
    }
    catch (error) {
        return Promise.reject(error)
    }
}

export default updateProfile;