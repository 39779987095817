import React, { useState, useEffect, useRef, useNavigate } from 'react';
import { useHistory } from "react-router";
import recruiter_header_logo from "../../components/basicInfoForm/images/logo_recruiter_header.png";
import HomeContactUsModal from "../../pages/home/homeHeader/homeContactUsModal/homeContactUs";

const BasicHeader = () => {
    const [open, setOpen] = useState(false);
    const [hamburgerIsClicked, setHamburgerIsClicked] = useState(false)
    const history = useHistory();
    const hamburger = useRef(0);

    const gotoHomePage = (e) => {
        history.push("/")
      }
      const openSupportModal = () => {
        setOpen(true);
      }

      const _modalClose = () => {
        setOpen(false);
      }
    
      const hamburgerClicked = () => {
        setHamburgerIsClicked(!hamburgerIsClicked)
      }

    return (
        <>
        <div className="nav_bg_home home-specific-header">
            <div className="container w1200 nav_bg_home_section">
                <div className="nav_bg_left">

                    <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
                        <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                    </li>

                </div>
                <div className="nav_bg_right">
                    <ul className="navbar_home">
                        <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>

      {/*-------> Nav Header Mobile <------*/ }
    <div className='nav_bg_home_responsive'>
        <div className="nav_bg_home_responsive-wrapper">
            <div className="home-top-nav">
                <li className='cp headerListStyleNone' onClick={() => gotoHomePage()}>
                    <a><img src={recruiter_header_logo} className="nav_logo_home" alt='img' /></a>
                </li>
                <div className={hamburgerIsClicked ? "hamburgerIsClicked" : "hamburgerIsNotClicked"} ref={hamburger}>
                    <ul className="navbar_home-responsive">
                        <li className="special_hover cp" onClick={openSupportModal}><a>Contact Us</a></li>
                    </ul>
                </div>
                <a className="icon" onClick={hamburgerClicked}>
                    <i className="fa fa-bars"></i>
                </a>
            </div>
        </div>
    </div>
    <HomeContactUsModal
        modalOpen={open}
        modalClose={_modalClose}
      />
    </>
     
  )
}

export default BasicHeader